import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";
import {
  COMMISSION_REPORT_ACTION,
  DETAIL_COMMISSION_REPORT_ACTION,
} from "../../redux/constants";
export const getCommissionSchemeByFilterAction = (
  request,
  callback
) => {
  request.offset -= 1;
  callAPI(
    "getCommissionSchemeByFilter",
    API.getCommissionSchemeByFilter,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const payCommissionAmountAction = (request, callback) => {
  callAPI(
    "payCommissionAmountAction",
    API.payCommissionAmount,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const gotoCommissionReportAction =
  (comission, callback) => (dispatch) => {
    dispatch({
      type: COMMISSION_REPORT_ACTION,
      comission: comission,
    });
  };

export const saveDateForCommissionAction =
  (dateData, callback) => (dispatch) => {
    dispatch({
      type: DETAIL_COMMISSION_REPORT_ACTION,
      comissionDetails: dateData,
    });
  };

export const getSecondLevelCommissionHeaderAction = (
  request,
  callback
) => {
  callAPI(
    "getSecondLevelCommissionHeader",
    API.getSecondLevelCommissionHeader,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getSecondLevelCommissionReportAction = (
  request,
  callback
) => {
  request.offset -= 1;
  callAPI(
    "getSecondLevelCommissionReport",
    API.getSecondLevelCommissionReport,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getThirdLevelCommissionHeaderAction = (
  request,
  callback
) => {
  callAPI(
    "getThirdLevelCommissionHeader",
    API.getThirdLevelCommissionHeader,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getThirdLevelCommissionReportAction = (
  request,
  callback
) => {
  callAPI(
    "getThirdLevelCommissionReport",
    API.getThirdLevelCommissionReport,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getCommissionReportDownloadAction = (
  downloadUrl,
  request,
  callback
) => {
  let requestUrl = downloadUrl;
  callAPI(
    "getCommissionReport",
    API.getReports.concat(requestUrl),
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    },
    true
  );
};

import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// eslint-disable-next-line
import { Route, Switch, withRouter } from "react-router-dom";

//Component
import SalesPersonComissionContainer from "../SalesPersonComission/SalesPersonComissionContainer";

//Action

//Constants
import { ROUTES } from "../../constants/routeConstants";
import { COMISSION_TABS, COMMISSION_ROUTE } from "./Constants";
import {
  SEARCH_FILTER_PAGES,
  COMMISSION_FILTER,
} from "../../constants/appConstants";

//Strings

//Utility

//Styles
import "./Comission.scss";

//Assets

class Comission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Tabs: COMISSION_TABS[this.props.loggedInUserRole],
      activeTab: COMISSION_TABS[this.props.loggedInUserRole][0][0],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let derivedState = {};
    if (
      nextProps.history.location.pathname ===
        ROUTES.COMMISSION_SCHEME ||
      nextProps.history.location.pathname === ROUTES.SALES_COMISSION
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[0],
      };
    }
    if (
      nextProps.history.location.pathname ===
      ROUTES.MESSENGER_COMISSION
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[1],
      };
    }
    if (
      nextProps.history.location.pathname ===
      ROUTES.SALES_MODERATOR_COMISSION
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[2],
      };
    }
    if (
      nextProps.history.location.pathname ===
      ROUTES.MESSENGER_MODERATOR_COMISSION
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[3],
      };
    }

    return derivedState;
  }
  handleTabs = (index) => {
    this.props.setSearchFilterAction(SEARCH_FILTER_PAGES.COMMISSION, {
      ...COMMISSION_FILTER,
    });
    this.setState({ activeTab: this.state.Tabs[index] }, () => {
      this.props.history.push(
        ROUTES[`${this.state.Tabs[index].value}${COMMISSION_ROUTE}`]
      );
    });
  };

  renderSalesPersonCommisionContainer = () => {
    return (
      <Switch>
        <Route
          exact
          path={[
            ROUTES.COMMISSION_SCHEME,
            ROUTES[
              `${this.state.activeTab.value}${COMMISSION_ROUTE}`
            ],
          ]}
          render={() => (
            <SalesPersonComissionContainer
              user={this.state.activeTab.value}
              userRole={this.state.activeTab.role}
            />
          )}
        />
      </Switch>
    );
  };

  render() {
    return (
      <div className='comission'>
        <Tabs
          selectedIndex={this.state.activeTab.key}
          onSelect={this.handleTabs}
        >
          <TabList>
            {this.state.Tabs.map((item) => (
              <Tab key={item.key}>{item.label} </Tab>
            ))}
          </TabList>

          {this.state.Tabs.map((item) => (
            <TabPanel key={item.key} className='WorkHistoryContainer'>
              {this.renderSalesPersonCommisionContainer()}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    );
  }
}

export default withRouter(Comission);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import RewardApproval from "./RewardApproval";

//Action
import {
  getApprovalRewardListAction,
  setRewardDetailsAction,
} from "./Actions";
import {
  setSearchFilterAction,
  getAllUserListAction,
} from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import {
  SEARCH_FILTER_PAGES,
  REWARD_APPROVAL_FILTER,
  USER_ROLES,
} from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

class RewardApprovalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: {},
      rewards: [],
      numberOfPages: 1,
      showLoadingModal: false,
      userList: [],
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    if (
      this.props.loggedInUser.role === USER_ROLES.operationsModerator
    ) {
      this.getUserList({
        rl: USER_ROLES.operationsOfficer,
      });
    }
  }
  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.REWARD_APPROVAL,
        { ...REWARD_APPROVAL_FILTER }
      );
    }
  }

  handleOnClickSearchFilter = (request) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        ...request,
      },
    });
    getApprovalRewardListAction({ ...request }, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          rewards: response.data.data.rr,
          numberOfPages: response.data.data.np,
          showLoader: false,
        });
      } else {
        this.setState({
          minimartList: [],
          numberOfPages: 0,
          showLoader: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  /**
   * Get active user list API handling
   * @param {*} request
   */
  getUserList = (request) => {
    this.setState({ showLoader: true });
    getAllUserListAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let userList = response.data.data.map((user) => {
          return { label: user.un, value: user.uid };
        });
        userList.unshift({
          value: "",
          label: Strings("username"),
        });
        this.setState({
          showLoader: false,
          userList,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
          userList: [],
        });
      }
    });
  };

  setRewardDetailsID = (reward) => {
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.REWARD_APPROVAL,
      { ...this.state.searchFilter },
      SEARCH_FILTER_PAGES.REWARD_APPROVAL
    );
    this.props.setRewardDetailsAction(reward);
    this.props.history.push(ROUTES.REWARD_APPROVAL_DETAILS);
  };
  render() {
    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        <RewardApproval
          rewards={this.state.rewards}
          numberOfPages={this.state.numberOfPages}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          setRewardDetailsID={this.setRewardDetailsID}
          searchFilter={this.props.searchFilter}
          isOperationsModerator={
            this.props.loggedInUser.role ===
            USER_ROLES.operationsModerator
          }
          userList={this.state.userList}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  searchFilter: state.searchFilterReducer.rewardApprovalFilter,
  loggedInUser: state.loginReducer.loggedInUserInfo,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setSearchFilterAction, setRewardDetailsAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardApprovalContainer);

//Libraries
import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fileDownload from "js-file-download";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import SingleSalesPerson from "./SingleSalesPerson";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

//Actions
import { setLoanDetailsIDAction } from "../LoanDetails/LoanDetailsAction";
import { getSingleSalesPerosnLoansAction } from "./SingleSalesPersonAction";
import {
  dowloadedAction,
  setSearchFilterAction,
} from "../../constants/Action";
import {
  setSalesPersonFiltersAction,
  setSalesPersonDataAction,
} from "../EfficiencyDashboard/EfficiencyDashboardActions";

//Constants
import {
  SEARCH_FILTER_PAGES,
  SPED_L1_FILTER,
  SPED_L2_FILTER,
} from "../../constants/appConstants";
import { SALES_PERSON_DATA_KEYS } from "./Constants";
import { ROUTES } from "../../constants/routeConstants";
import { OPERATION_STATUS } from "../../config/axios.init";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

class SingleSalesPersonContainer extends React.Component {
  constructor(props) {
    super(props);
    this._columns = SALES_PERSON_DATA_KEYS;
    this.state = {
      showLoader: false,
      records: [],
      errorMessage: "",
      isError: false,
      loansRow: [],
      user: null,
      pieChartStats: {
        totalAssignedMinimarts: 2138,
        visitedMinimarts: 0,
        totalLoans: 2064,
        generatedLoans: 0,
        pendingLoans: 2059,
        deniedLoans: 0,
        createdLoanAmount: 105145,
        disbursedLoanAmount: 0,
        totalLoanAmount: 0,
        pendingLoanAmount: 14904107,
        createdLoanCount: 5,
        disbursedLoanCount: 0,
      },
    };
    this.isCardClick = false;
  }

  componentWillUnmount() {
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.SPED_L1 &&
      this.props.history.location.pathname !==
        ROUTES.SALES_EFFICIENCY_DASHBOARD &&
      this.props.history.location.pathname !==
        ROUTES.SINGLE_SALES_PERSON &&
      this.props.history.location.pathname !==
        ROUTES.LOAN_APPLICATION_DETAILS
    ) {
      this.props.setSearchFilterAction(SEARCH_FILTER_PAGES.SPED_L1, {
        ...SPED_L1_FILTER,
      });
    }
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SPED_L2,
        {
          ...SPED_L2_FILTER,
        },
        SEARCH_FILTER_PAGES.SPED_L1
      );
    }
  }

  handleOnClickSearchFilter = (searchFilter) => {
    let loanSearchFilter = {
      search: { ...searchFilter.search },
      offset: searchFilter.pagination.offset,
      limit: searchFilter.pagination.limit,
    };
    this.setState({
      showLoader: true,
      searchFilter: {
        fd: searchFilter.search.fromDate,
        td: searchFilter.search.toDate,
        mid: searchFilter.search.matricesId,
        code: searchFilter.search.minimartCode,
        appid: searchFilter.search.applicationId,
        mn: searchFilter.search.minimartName,
        st: searchFilter.search.status,
        ty: searchFilter.search.loanType,
        limit: searchFilter.pagination.limit,
        offset: searchFilter.pagination.offset,
      },
    });
    getSingleSalesPerosnLoansAction(loanSearchFilter, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let salesPersonDetails = {
          ...this.props.selectedSalespersonDetails,
        };
        salesPersonDetails.crat = response.data.data.crat
          ? response.data.data.crat
          : 0;
        salesPersonDetails.ple = response.data.data.ple
          ? response.data.data.ple
          : 0;
        salesPersonDetails.amtd = response.data.data.amtd
          ? response.data.data.amtd
          : 0;
        salesPersonDetails.pa = response.data.data.pa
          ? response.data.data.pa
          : 0;
        this.props.setSalesPersonDataAction(salesPersonDetails);
        this.setState({
          showLoader: false,
          loansRow: response.data.data.efficiencyStats,
          numberOfPages: response.data.data.noOfPages,
          pieChartStats:
            response.data.data.assignedMinimartStatsResponse,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoader: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  handleDownloadFile = (url, searchFilterObject, fileType) => {
    this.setState({ showLoader: true, isError: false });
    searchFilterObject.search.limit > 0
      ? dowloadedAction(
          url,
          searchFilterObject,
          (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
              if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
                fileDownload(
                  response.data,
                  Strings(
                    this.props.selectedSalespersonDetails?.fn +
                      " " +
                      this.props.selectedSalespersonDetails?.ln
                  ) +
                    "-" +
                    Utility.getReportFormattedDateString(
                      Utility.convertDateIntoMiliSeconds(new Date())
                    ) +
                    Utility.getFileType(fileType)
                );
                this.setState({
                  errorMessage: "",
                  showLoader: false,
                  isError: false,
                });
              } else {
                this.setState({
                  showLoader: false,
                  isError: true,
                  errorMessage: Strings("NoRecordsFound"),
                });
              }
            } else {
              this.setState({
                showLoader: false,
                isError: true,
                errorMessage: response.error.message,
              });
            }
          },
          "SingleSalesPerson"
        )
      : this.setState({
          showLoader: false,
          isError: true,
          errorMessage: Strings("NoData"),
        });
  };

  handleReportRowClick = (cell, row) => {
    if (row !== undefined) {
      let loanDetails = {
        applicationId: row.appid,
        loanType: row.ty,
        loanId: row.lid ? row.lid : null,
      };
      this.isCardClick = true;
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SPED_L2,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.SPED_L2
      );
      this.props.setLoanDetailsIDAction(loanDetails);
      this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isError && (
          <UserNotification
            key='Error'
            userNotificationObj={{
              message: Strings(this.state.errorMessage),
              level: "error",
            }}
          />
        )}
        <Row className='heading'>
          {Strings("SalespersonEfficiencyDashboard")}
        </Row>
        <SingleSalesPerson
          piechartStats={this.state.pieChartStats}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          rows={this.state.loansRow}
          onRowClick={this.handleReportRowClick}
          columns={this._columns}
          rowsCount={this.state.loansRow.length}
          rowHeight={60}
          headerRowHeight={55}
          numberOfPages={this.state.numberOfPages}
          handleDownloadFile={this.handleDownloadFile}
          searchFilter={this.props.searchFilter}
          selectedSalespersonDetails={
            this.props.selectedSalespersonDetails
          }
          setSalesPersonFiltersAction={
            this.props.setSalesPersonFiltersAction
          }
          goBack={this.goBack}
          searchFilters={this.props.searchFilters}
          edfSearchFilter={this.props.edfSearchFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  edfSearchFilter: state.efficiencyDashboardReducer.searchFilter,
  selectedSalespersonDetails:
    state.efficiencyDashboardReducer.selectedSalespersonData,
  branches: state.branchListReducer.branchDetails.branches,
  searchFilters: state.searchFilterReducer.spedL2SearchFilter,
  searchPage: state.searchFilterReducer.searchPage,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoanDetailsIDAction,
      setSalesPersonFiltersAction,
      setSalesPersonDataAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleSalesPersonContainer);

// Constants
import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getSignUpMinimartRequestAction = (
  signUpId,
  isSignup,
  callback
) => {
  callAPI(
    "getSignUpMinimartRequest",
    (isSignup
      ? API.getSignUpMinimartRequest
      : API.getCIMinimartDetails) + signUpId,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const saveSignUpRequestAction = (
  request,
  isSignup,
  callback
) => {
  callAPI(
    "saveSignUpRequest",
    isSignup ? API.saveSignUpRequest : API.setCIMinimartApproval,
    isSignup ? "POST" : "PATCH",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const stopAssignmentAction = (request, callback) => {
  callAPI(
    "stopAssignment",
    API.stopAssignment + request,
    "PATCH",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

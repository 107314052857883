// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { API } from "../../constants/APIConstants";
import {
  SET_BRANCH_DETAILS_ACTION,
  CLEAR_BRANCH_DETAILS_ACTION,
  SET_ZONES_ACTION,
} from "../../redux/constants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

import { callAPI } from "../../config/AxiosTemplate";

/*
 * Actions not using dispatch
 * This will be called directly inside container without mapDispatchToProps
 */

/**
 *  Action to get user details
 */
export const getBranchDetailsAction = (
  callback,
  isBranchList = false
) => {
  let requestData = {
    filter: {},
    search: {},
  };
  if (isBranchList) {
    requestData.limit = DEFAULT_PAGINATION_RECORDS_COUNT;
    requestData.offset = DEFAULT_PAGINATION_ACTIVE_PAGE - 1;
  }
  return (dispatch) =>
    callAPI(
      "GetBranchDetails",
      API.getBranchList,
      "POST",
      requestData,
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          let branchDetails = apiResponse.data.data;

          if (!isBranchList) {
            dispatch({
              type: SET_BRANCH_DETAILS_ACTION,
              branchDetails: branchDetails,
            });
          }
        }
        callback(apiResponse);
      }
    );
};

/*
 * Actions using dispatch
 * This will be called inside container through props with mapDispatchToProps
 */

export const getBranchBySearchFilterAction = (
  searchFilterObject,
  callback,
  isBranchList = false
) => {
  let requestData = {
    filter: {
      active: searchFilterObject.active
        ? searchFilterObject.active
        : undefined,
    },
    search: {
      branchName: searchFilterObject.searchBranchName
        ? searchFilterObject.searchBranchName
        : undefined,
      zone: searchFilterObject.zone
        ? searchFilterObject.zone
        : undefined,
      messengerZone: searchFilterObject.messengerZone
        ? searchFilterObject.messengerZone
        : undefined,
    },
    limit: searchFilterObject.limit,
    offset: searchFilterObject.offset - 1,
  };
  return (dispatch) =>
    callAPI(
      "GetBranchBySearchFilter",
      API.getBranchBySearchFilter,
      "POST",
      requestData,
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          let branchDetails = apiResponse.data.data;

          if (!isBranchList) {
            dispatch({
              type: SET_BRANCH_DETAILS_ACTION,
              branchDetails: branchDetails,
            });
          }
        }
        callback(apiResponse);
      }
    );
};

export const setBranchDetailsAction =
  (branchDetails, callback) => (dispatch) => {
    let branchInfo = branchDetails;

    dispatch({
      type: SET_BRANCH_DETAILS_ACTION,
      branchDetails: branchInfo,
    });
    callback();
  };

export const clearBranchDetailsAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_BRANCH_DETAILS_ACTION,
  });
};

/**
 *  Action to get branch
 */
export const getBranchAction = (callback) => (dispatch) => {
  let requestData = {
    filter: {},
    search: {},
    limit: DEFAULT_PAGINATION_RECORDS_COUNT,
    offset: DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
  };
  callAPI(
    "GetBranch",
    API.getBranch,
    "POST",
    requestData,
    (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        dispatch({
          type: SET_BRANCH_DETAILS_ACTION,
          branchDetails: apiResponse.data.data.branches,
        });
      }
      callback(apiResponse);
    }
  );
};

//Handle create Branch
export const createBranchAction =
  (branchDetails, callback) => (dispatch) => {
    callAPI(
      "CreateBranch",
      API.createBranch,
      "POST",
      branchDetails,
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          dispatch({
            type: SET_BRANCH_DETAILS_ACTION,
            branchDetails: apiResponse.data.data,
          });
        }
        callback(apiResponse);
      }
    );
  };

//Handle update Branch
export const updateBranchAction =
  (branchDetails, callback) => (dispatch) => {
    callAPI(
      "UpdateBranch",
      API.updateBranch.replace("id", branchDetails.id),
      "PUT",
      branchDetails,
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          dispatch({
            type: SET_BRANCH_DETAILS_ACTION,
            branchDetails: apiResponse.data.data,
          });
        }
        callback(apiResponse);
      }
    );
  };

//Handle Deactivate Branch
export const deactivateBranchAction =
  (branchDetails, callback) => (dispatch) => {
    let data = {
      branchId: branchDetails.id,
      active: 0,
    };
    callAPI(
      "DeactivateBranch",
      API.updateBranchStatus,
      "PUT",
      data,
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          dispatch({
            type: SET_BRANCH_DETAILS_ACTION,
            branchDetails: apiResponse.data.data,
          });
        }
        callback(apiResponse);
      }
    );
  };

export const getAllZonesAction = (role, callback) => (dispatch) => {
  callAPI(
    "getAllZones",
    API.getAllZones + role,
    "GET",
    {},
    (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        dispatch({
          type: SET_ZONES_ACTION,
          zones: apiResponse.data.data,
        });
      }
      callback(apiResponse);
    }
  );
};

export const getZonesUnderSelectedBranchAction =
  (request, callback, userId = null) =>
    (dispatch) => {
      callAPI(
        "getAllZonesUnderSelectedBranch",
        API.getSelectedBranchZones,
        "GET",
        { branchIds: request, userId: userId ? userId : undefined },
        (apiResponse) => {
          callback(apiResponse);
        }
      );
    };

import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fileDownload from "js-file-download";

//Component
import DetailCommissionReport from "./DetailCommissionReport";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

//Action
import {
  getThirdLevelCommissionHeaderAction,
  getThirdLevelCommissionReportAction,
} from "../SalesPersonComission/CommissionsAction";
import {
  dowloadedAction,
  setSearchFilterAction,
} from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { SINGLE_USER_COMMISSION_COLUMN } from "../CommissionReport/Constants";
import { PDF_NAME } from "../Comission/Constants";
import {
  SEARCH_FILTER_PAGES,
  COMMISSION_FILTER,
  COMMISSION_REPORT_FILTER,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./DetailCommissionReport.scss";

//Assets

class DetailCommissionReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      numberOfPages: 1,
      header: null,
    };
  }

  componentWillUnmount() {
    if (
      this.props.searchPage ===
        SEARCH_FILTER_PAGES.COMMISSION_REPORT &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.COMMISSION,
        { ...COMMISSION_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.COMMISSION_REPORT,
        { ...COMMISSION_REPORT_FILTER }
      );
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  getCommissionsHeader = (searchFilterObject) => {
    this.setState({ showLoader: true });
    getThirdLevelCommissionHeaderAction(
      searchFilterObject,
      (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            header: Response.data.data,
            showLoader: false,
            errorMessage: "",
          });
        } else {
          this.setState({
            showLoader: false,
            errorMessage: Response.error.message,
            header: null,
          });
        }
      }
    );
  };

  getThirdLevelCommissionReport = (searchFilter) => {
    this.setState({ showLoader: true });
    getThirdLevelCommissionReportAction(searchFilter, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          rows: Response.data.data.dc,
          numberOfPages: Response.data.data.np,
          showLoader: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoader: false,
          errorMessage: Response.error.message,
        });
      }
    });
  };

  /**
   * Method to download Detail Sales or Messenger commission reports
   * @param {*} url
   * @param {*} requestData
   * @param {*} filetype
   */
  downloadReports = (url, requestData, filetype) => {
    this.setState({ showLoader: true, isError: false });
    dowloadedAction(
      url,
      requestData,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
            fileDownload(
              response.data,
              PDF_NAME[this.props.user.userRole] +
                Utility.getFileType(filetype)
            );
            this.setState({
              errorMessage: "",
              showLoader: false,
              isError: false,
            });
          } else {
            this.setState({
              showLoader: false,
              isError: true,
              errorMessage: Strings("NoRecordsFound"),
            });
          }
        } else {
          this.setState({
            showLoader: false,
            errorMessage: response.error.message,
          });
        }
      },
      "ComissionReports"
    );
  };

  render() {
    return (
      <div className='detailCommissionReport'>
        <Row className='heading'>
          {Strings("SingleUserCommissionDetails")}
        </Row>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <DetailCommissionReport
          user={this.props.user}
          data={this.state.rows}
          columns={
            SINGLE_USER_COMMISSION_COLUMN[this.props.user.userRole]
          }
          rowsCount={this.state.rows.length}
          onRowClick={() => {
            /*This is intentional*/
          }}
          rowHeight={60}
          headerRowHeight={50}
          getCommissionsHeader={this.getCommissionsHeader}
          handleSearchFilter={this.getThirdLevelCommissionReport}
          handleBackButtonClick={this.goBack}
          header={this.state.header}
          dateData={this.props.data}
          numberOfPages={this.state.numberOfPages}
          errorMessage={this.state.errorMessage}
          userRole={this.props.user.userRole}
          downloadReports={this.downloadReports}
          rows={this.state.rows}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.CommissionReportReducer.comissionDetails,
  user: state.CommissionReportReducer.commissionUser,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setSearchFilterAction }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailCommissionReportContainer);

// Constants
import {
  COMMISSION_REPORT_ACTION,
  DETAIL_COMMISSION_REPORT_ACTION,
} from "../../redux/constants";

const INITIAL_STATE = {
  commissionUser: null,
  comissionDetails: null,
};

export default function CommissionReportReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMMISSION_REPORT_ACTION:
      return {
        ...state,
        commissionUser: action.comission,
      };

    case DETAIL_COMMISSION_REPORT_ACTION:
      return {
        ...state,
        comissionDetails: action.comissionDetails,
      };

    default:
      return state;
  }
}

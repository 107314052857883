import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { SET_MINIMART_DETAILS_ACTIVE_TAB } from "../../redux/constants";

//Utility
import Utility from "../../utils/Utility";

export const getInvoicesBySearchFilterAction = (
  searchFilterObject,
  callback
) => {
  let requestData = {
    search: {
      matricesId: searchFilterObject.search.matricesId
        ? searchFilterObject.search.matricesId
        : undefined,
      fromDate: searchFilterObject.search.fromDate
        ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.fromDate
        )
        : undefined,
      toDate: searchFilterObject.search.toDate
        ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.toDate
        )
        : undefined,
      status: searchFilterObject.search.status
        ? [searchFilterObject.search.status]
        : undefined,
      it: searchFilterObject.search.invoiceType,
    },
    limit: searchFilterObject.limit
      ? searchFilterObject.limit
      : DEFAULT_PAGINATION_RECORDS_COUNT,
    offset: searchFilterObject.offset
      ? searchFilterObject.offset - 1
      : DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
  };

  callAPI(
    "getInvoicesBySearchFilter",
    API.getInvoicesBySearchFilter,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getSingleMinimartDataAction = (
  matricesId,
  isNewMinimart,
  callback
) => {
  const url = isNewMinimart
    ? API.getNewSingleMinimartData
    : API.getSingleMinimartData;
  callAPI(
    "getSingleMinimartData",
    url.replace("matricesId", matricesId),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getLoansBySearchFilterAction = (
  searchFilterObject,
  callback
) => {
  // NOSONAR
  let requestData = {
    loanSearchCriteria: {
      matricesId: searchFilterObject.search.matricesId
        ? searchFilterObject.search.matricesId
        : undefined,
      code: searchFilterObject.search.minimartCode
        ? searchFilterObject.search.minimartCode
        : undefined,
      fromDate: searchFilterObject.search.fromDate
        ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.fromDate
        )
        : undefined,
      toDate: searchFilterObject.search.toDate
        ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.toDate
        )
        : undefined,
      status: searchFilterObject.search.status
        ? searchFilterObject.search.status
        : undefined,
      applicationId: searchFilterObject.search.applicationId
        ? searchFilterObject.search.applicationId
        : undefined,
      loanId: searchFilterObject.search.loanId
        ? searchFilterObject.search.loanId
        : undefined,
      minimartName: searchFilterObject.search.minimartName
        ? searchFilterObject.search.minimartName
        : undefined,
      type: searchFilterObject.search.loanType
        ? searchFilterObject.search.loanType
        : undefined,
      provision: searchFilterObject.search.provision
        ? searchFilterObject.search.provision
        : undefined,
      category:
        searchFilterObject.search.category !== ""
          ? searchFilterObject.search.category
          : undefined,
      branch:
        searchFilterObject.search.branch !== ""
          ? searchFilterObject.search.branch
          : undefined,
      salesPerson:
        searchFilterObject.search.salesPerson !== ""
          ? searchFilterObject.search.salesPerson
          : undefined,
      conciliationType:
        searchFilterObject.search.conciliationType !== ""
          ? searchFilterObject.search.conciliationType
          : undefined,
      approvalType:
        searchFilterObject.search.approvalType !== ""
          ? searchFilterObject.search.approvalType
          : undefined,
      clientState:
        searchFilterObject.search.clientState !== "selectClientState"
          ? searchFilterObject.search.clientState
          : undefined,
      initiatedFrom:
        searchFilterObject.search.loanFrom !== "loanFrom"
          ? searchFilterObject.search.loanFrom
          : undefined,
    },
    limit: searchFilterObject.limit
      ? searchFilterObject.limit
      : DEFAULT_PAGINATION_RECORDS_COUNT,
    offset: searchFilterObject.offset
      ? searchFilterObject.offset - 1
      : DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
  };

  callAPI(
    "getLoansBySearchFilter",
    API.getLoansBySearchFilter,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getPerticularCreditLimitRequestAction = (
  ticketId,
  callback
) => {
  callAPI(
    "getPerticularCreditLimitRequest",
    API.getPerticularCreditLimitRequest.replace(
      "<ticketId>",
      ticketId
    ),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const creditLimitRequestAction = (requestData, callback) => {
  callAPI(
    "creditLimitRequest",
    API.creditLimitRequestAction,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getCreditAgreementListAction = (request, callback) => {
  let requestString = request;
  callAPI(
    "getCreditAgreementList",
    API.getCreditAgreementList.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const downloadCreditAgreementAction = (
  documentId,
  callback
) => {
  callAPI(
    "downloadCreditAgreement",
    API.downloadCreditAgreement.replace("<documentId>", documentId),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAllPaymentReceiptsAction = (data, callback) => {
  callAPI(
    "getAllPaymentReceipts",
    API.getPaymentReceipts,
    "POST",
    data,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const generatePaymentReceiptAction = (
  receiptID,
  downloadFile,
  callback
) => {
  callAPI(
    "generatePaymentReceipt",
    API.generatePaymentReceipt.concat(`${receiptID}/download`),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    },
    downloadFile
  );
};

export const addInconsistentPayerNote = (request, callback) => {
  callAPI(
    "addInconsistentPayerNote",
    API.addInconsistentPayerNote,
    "PATCH",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getSingleMinimartNotesAction = (
  matricesId,
  callback
) => {
  callAPI(
    "getSingleMinimartNotes",
    `${API.getSingleMinimartNotes}/${matricesId}`,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const resetMinimartGradeAction = (gradeObject, callback) => {
  callAPI(
    "resetMinimartGrade",
    API.resetMinimartGrade,
    "POST",
    gradeObject,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const assignSalesPersonAction = (requestData, callback) => {
  callAPI(
    "assignSalesPerson",
    `${API.assignSalesPerson}`,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const assignMessengerAction = (mid, callback) => {
  callAPI(
    "assignMessenger",
    API.assignMessenger + mid,
    "PATCH",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setMinimartDetailsActiveTab =
  (activeTab) => (dispatch) => {
    dispatch({
      type: SET_MINIMART_DETAILS_ACTIVE_TAB,
      activeTab,
    });
  };

export const assignedMinimartRouteAction = (requestDta, callback) => {
  callAPI(
    "assignedMinimartRoute",
    API.assignedMinimartRoute,
    "PATCH",
    requestDta,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getRewardHistoryAction = (request, callback) => {
  request.oft -= 1;
  request.fd = Utility.convertDateIntoMiliSeconds(request.fd);
  request.td = Utility.convertDateIntoMiliSeconds(request.td);
  callAPI(
    "getRewardHistory",
    API.getRewardHistory,
    "GET",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateMinimartLevelAction = (request, callback) => {
  callAPI(
    "updateMinimartLevel",
    API.updateMinimartLevel,
    "PATCH",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const mergeMinimartAction = (request, callback) => {
  callAPI(
    "mergeMinimart",
    API.mergeMinimart,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getMinimartDetailsAction = (matricesId, callback) => {
  callAPI(
    "getMinimartDetails",
    API.getMinimartDetails + matricesId,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const paymentReverseAction = (mid, callback) => {
  callAPI(
    "paymentReverse",
    API.paymentReverse + mid,
    "PATCH",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const addInvoiceAction = (request, callback) => {
  callAPI(
    "addInvoice",
    API.addInvoice,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getSupplierAction = (callback) => {
  callAPI(
    "getSupplier",
    API.getSupplier,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setExceptionalMinimartAction = (mid, callback) => {
  callAPI(
    "setExceptionalMinimartAction",
    API.setExceptionalMinimart + mid,
    "PATCH",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};
export const downpaymnetReverseAction = (mid, note, callback) => {
  callAPI(
    "downpaymentReverse",
    API.downpaymentReverse + mid + "&note=" + note,
    "PATCH",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};
export const getMergedMinimartsAction = (mid, callback) => {
  callAPI(
    "getMergedMinimartsAction",
    API.getMergedMinimart + mid,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  )
}
//Components
import React, { Component } from "react";
import ComplaintPortal from "./ComplaintPortal.js";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import withHOC from "../../HOC/HOC";

//actions
import {
  getComplaintPortalAction,
  resolveComplaintAction,
} from "./ComplaintManagementActions";

// String
import { Strings } from "../../resources/i18n/i18n";

//Constant
import { OPERATION_STATUS } from "../../config/axios.init";
class ComplaintPortalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      errorMessage: "",
      showLoadingModal: false,
      numberOfPages: 0,
    };
  }

  // on click of GO button
  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
    });
    getComplaintPortalAction(searchFilterObject, (responsedata) => {
      if (responsedata.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          numberOfPages: responsedata.data.data.np,
          rows: responsedata.data.data.rp,
          errorMessage: "",
          showLoadingModal: false,
        });
      } else {
        this.setState({
          errorMessage: responsedata.error.message,
          rows: [],
          showLoadingModal: false,
        });
      }
    });
  };

  // to resolve a complaint
  addNote = (noteId, note, successCallback) => {
    this.setState({
      showLoadingModal: true,
    });
    let reqObj = { id: noteId, nt: note };
    resolveComplaintAction(reqObj, (responsedata) => {
      if (responsedata.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            isSuccess: true,
            successMessage: Strings("resolvedSuccesfully"),
            level: "success",
            showLoadingModal: false,
            errorMessage: "",
          },
          successCallback
        );
      } else {
        this.setState(
          {
            errorMessage: responsedata.error.message,
            showLoadingModal: false,
          },
          successCallback
        );
      }
    });
  };

  render() {
    return (
      <>
        {/* spinner */}
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        {this.state.isSuccess && (
          <UserNotification
            key='Success'
            userNotificationObj={{
              message: Strings(this.state.successMessage),
              level: this.state.level,
            }}
          />
        )}
        {/* Child component */}
        <ComplaintPortal
          rows={this.state.rows}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          addNote={this.addNote}
          showLoadingModal={this.state.showLoadingModal}
          errorMessage={this.state.errorMessage}
          //Pagination
          numberOfPages={this.state.numberOfPages}
          loggedInUser={this.props.loggedInUser}
        />
      </>
    );
  }
}

const ComplaintPortalWarapper = withHOC(ComplaintPortalContainer);

export default ComplaintPortalWarapper;

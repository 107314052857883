import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Component
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import FileContent from "./FileContent";

//Action
import { getInvoiceBySearchFilterAction } from "../FileContent/FileContentAction";

//Constants

import { OPERATION_STATUS } from "../../config/axios.init";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility

//Styles
import "./FileContent.scss";

//Assets

class FileContentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      numberOfPages: 0,
    };
  }

  handleOnClickSearchFilter = (SearchFilterObject) => {
    this.setState({ showLoader: true });

    getInvoiceBySearchFilterAction(
      SearchFilterObject,
      (responsedata) => {
        if (responsedata.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            numberOfPages: responsedata.data.data.numberOfPages,
            showLoader: false,
            errorMessage: "",
            invoices: responsedata.data.data.invoices,
          });
        } else {
          this.setState({
            errorMessage: responsedata.error.message,
            showLoader: false,
          });
        }
      }
    );
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <Row className='heading'> {Strings("InvoiceList")}</Row>
        <FileContent
          invoices={this.state.invoices}
          fileContent={this.props.fileContent}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleBackButtonClick={this.handleBackButtonClick}
          numberOfPages={this.state.numberOfPages}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fileContent: state.ciReducer.fileData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getInvoiceBySearchFilterAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileContentContainer);

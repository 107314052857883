// Constants
import { API } from "../../constants/APIConstants";

import { callAPI } from "../../config/AxiosTemplate";
import {
  SET_SALES__PERSON_ACTION,
  SET_MESSENGER_ACTION,
  SET_DATE_FOR_SALES__PERSON_ACTION,
  SET_DATE_FOR_MESSENGER_ACTION,
  CLEAR_DAILYSTATUS_DATE,
} from "../../redux/constants";
// Utils
import Utility from "../../utils/Utility";
/*
 * Actions not using dispatch
 * This will be called directly inside container without mapDispatchToProps
 */

/**
 *  Action to get dailyStatus Stats for all salesPersons
 */

/********** SalesPerson API calls ************/

export const getPiechartDataForAllSalesPersonsAction = (
  requestData,
  callback
) => {
  let requestString = "";
  if (requestData.date !== "") {
    requestString =
      "?date=" + Utility.convertDateIntoMiliSeconds(requestData.date);
  }
  callAPI(
    "getPiechartDataForAllSalesPersons",
    API.getPiechartDataForAllSalesPersons.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAssignedSalesPersonListAction = (
  requestData,
  callback
) => {
  let requestString =
    "?limit=" +
    requestData.limit +
    "&offset=" +
    (requestData.offset - 1);
  if (requestData.branchId !== "") {
    requestString += "&branchId=" + requestData.branchId;
  }
  if (requestData.zone !== "") {
    requestString += "&zone=" + requestData.zone;
  }
  if (requestData.date !== "") {
    requestString +=
      "&date=" + Utility.convertDateIntoMiliSeconds(requestData.date);
  }
  if (requestData.rating !== "") {
    requestString += "&rating=" + requestData.rating;
  }
  if (requestData.name !== "") {
    requestString += "&name=" + requestData.name;
  }

  callAPI(
    "getAssignedSalesPersonList",
    API.getAssignedSalesPersonList.replace(
      "/salesPersons",
      requestString
    ),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getSalesPersonDailyAsignmentAction = (
  requestData,
  callback
) => {
  let requestString = "";
  if (requestData.salesPersonId !== "") {
    requestString =
      requestData.salesPersonId +
      "&date=" +
      Utility.convertDateIntoMiliSeconds(requestData.date) +
      "&limit=" +
      requestData.limit +
      "&offset=" +
      (requestData.offset - 1);
  }
  if (requestData.name !== "" && requestData.name !== undefined) {
    requestString += "&name=" + requestData.name;
  }
  if (
    requestData.visitStatus !== "" &&
    requestData.visitStatus !== undefined
  ) {
    requestString += "&visitStatus=" + requestData.visitStatus;
  }
  if (requestData.type !== "" && requestData.type !== undefined) {
    requestString += "&type=" + requestData.type;
  }
  if (
    requestData.category !== "" &&
    requestData.category !== undefined
  ) {
    requestString += "&category=" + requestData.category;
  }
  if (requestData.level !== "" && requestData.level !== undefined) {
    requestString += "&level=" + requestData.level;
  }

  callAPI(
    "getSalesPersonDailyAsignment",
    API.getSalesPersonDailyAsignment.concat("=" + requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getPieChartDataForSingleSalesPersonAction = (
  salesPersonId,
  date,
  callback
) => {
  let requestString =
    salesPersonId +
    "&date=" +
    Utility.convertDateIntoMiliSeconds(date);
  callAPI(
    "getPieChartDataForSingleSalesPerson",
    API.getPiechartDataForSingleSalesPerson.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

/********** Messenger API calls ******************/

export const getPiechartDataForAllMessengersAction = (
  requestData,

  callback
) => {
  let requestString = "";
  if (requestData.date !== "") {
    requestString =
      "?date=" + Utility.convertDateIntoMiliSeconds(requestData.date);
  }
  callAPI(
    "getPiechartDataForAllMessengers",
    API.getPiechartDataForAllMessengers.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getPiechartDataForSingleMessengerAction = (
  messengerId,
  date,
  callback
) => {
  let requestString =
    messengerId + "&date=" + Utility.convertDateIntoMiliSeconds(date);
  callAPI(
    "getPiechartDataForSingleMessenger",
    API.getPiechartDataForSingleMessenger.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAssignedMessengersListAction = (
  requestData,
  callback
) => {
  let requestString =
    "limit=" +
    requestData.limit +
    "&offset=" +
    (requestData.offset - 1);
  if (requestData.branchId !== "") {
    requestString += "&branchId=" + requestData.branchId;
  }
  if (requestData.zone) {
    requestString += "&zone=" + requestData.zone;
  }
  if (requestData.date !== "") {
    requestString +=
      "&date=" + Utility.convertDateIntoMiliSeconds(requestData.date);
  }
  if (requestData.rating !== "") {
    requestString += "&rating=" + requestData.rating;
  }
  if (requestData.name !== "") {
    requestString += "&name=" + requestData.name;
  }

  callAPI(
    "getAssignedMessengersList",
    API.getAssignedMessengersList.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getMessengerDailyAsignmentAction = (
  requestData,
  callback
) => {
  let requestString = "";
  if (requestData.messengerId !== "") {
    requestString =
      requestData.messengerId +
      "&date=" +
      Utility.convertDateIntoMiliSeconds(requestData.date) +
      "&limit=" +
      requestData.limit +
      "&offset=" +
      (requestData.offset - 1);
  }
  if (requestData.name !== "" && requestData.name !== undefined) {
    requestString += "&name=" + requestData.name;
  }
  if (
    requestData.visitStatus !== "" &&
    requestData.visitStatus !== undefined
  ) {
    requestString += "&visitStatus=" + requestData.visitStatus;
  }
  if (requestData.type !== "" && requestData.type !== undefined) {
    requestString += "&type=" + requestData.type;
  }
  if (
    requestData.category !== "" &&
    requestData.category !== undefined
  ) {
    requestString += "&category=" + requestData.category;
  }

  if (requestData.level !== "" && requestData.level !== undefined) {
    requestString += "&level=" + requestData.level;
  }

  callAPI(
    "getMessengersDailyAssignment",
    API.getMessengersDailyAssignment.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

//action with dispatch for storing data in reducer

export const setSalesPersonIdAction = (salesPerson) => (dispatch) => {
  dispatch({
    type: SET_SALES__PERSON_ACTION,
    salesPerson: salesPerson,
  });
};

export const setDateForSalesPersonAction =
  (salesPersonDate) => (dispatch) => {
    dispatch({
      type: SET_DATE_FOR_SALES__PERSON_ACTION,
      salesPersonDate: salesPersonDate,
    });
  };

export const setMessengerIdAction = (messenger) => (dispatch) => {
  dispatch({
    type: SET_MESSENGER_ACTION,
    messenger: messenger,
  });
};

export const setDateForMessengerAction =
  (messengerDate) => (dispatch) => {
    dispatch({
      type: SET_DATE_FOR_MESSENGER_ACTION,
      messengerDate: messengerDate,
    });
  };

export const clearDailyStatusDateAction =
  (date = null) =>
  (dispatch) => {
    dispatch({
      type: CLEAR_DAILYSTATUS_DATE,
      date: date,
    });
  };

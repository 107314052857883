import React from "react";

//Style Components
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

//Constants
import { PIE_CHART_COLORS } from "../../../constants/appConstants";

//Strings
import { Strings } from "../../../resources/i18n/i18n";

//Styles
import "./PieChartComponent.scss";

class PieChartComponent extends React.Component {
  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }) => {
    let label;
    const radius = outerRadius + 7;
    const RADIAN = Math.PI / 180;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    //Don't show label if value is 0
    label =
      this.props.data[index]?.count === 0
        ? ""
        : this.props.data[index]?.count;
    return (
      <text
        x={x}
        y={y}
        fill='#5f7278'
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline='central'
        className='BoldText textPiechart'
      >
        {label}
      </text>
    );
  };
  CustomLabel = ({ cx, cy }) => {
    return (
      <text
        x={cx}
        y={cy}
        fill='#3d405c'
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
      >
        <tspan alignmentBaseline='middle' fontSize='26'>
          {this.props.total}
        </tspan>
      </text>
    );
  };

  renderLegendText = (value, entry) => {
    return <span>{Strings(value)}</span>;
  };

  render() {
    return (
      <div className='pieChartComponent'>
        {this.props.data && this.props.data.length > 0 && (
          <ResponsiveContainer
            width={this.props.moreWidth ? "120%" : "100%"}
            height={this.props.height ? this.props.height : 125}
          >
            <PieChart
              height={this.props.height ? this.props.height : 125}
              margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
            >
              <text
                x={"50%"}
                y={"50%"}
                textAnchor='middle'
                dominantBaseline='middle'
                className='middleText'
              >
                {this.props.total}
              </text>
              <Pie
                data={this.props.data}
                labelLine={false}
                innerRadius={
                  this.props.innerRadius
                    ? this.props.innerRadius
                    : "60%"
                }
                outerRadius={
                  this.props.outerRadius
                    ? this.props.outerRadius
                    : "80%"
                }
                paddingAngle={1}
                // label={
                //   this.props.showLabel === true
                //     ? this.renderCustomizedLabel
                //     : this.props.label
                // }
                dataKey={this.props.datakey}
              >
                {this.props.data.map((entry, index) => {
                  return (
                    <Cell
                      key={Math.random() + index}
                      fill={
                        PIE_CHART_COLORS[this.props.colors][
                          index %
                            PIE_CHART_COLORS[this.props.colors].length
                        ]
                      }
                    />
                  );
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}

export default PieChartComponent;

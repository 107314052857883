import React from "react";
import fileDownload from "js-file-download";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import MinimartDetails from "./MinimartDetails";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import withHOC from "../../HOC/HOC";

// Action
import {
  getInvoicesBySearchFilterAction,
  getSingleMinimartDataAction,
  getLoansBySearchFilterAction,
  getPerticularCreditLimitRequestAction,
  creditLimitRequestAction,
  getCreditAgreementListAction,
  downloadCreditAgreementAction,
  getAllPaymentReceiptsAction,
  generatePaymentReceiptAction,
  addInconsistentPayerNote,
  getSingleMinimartNotesAction,
  resetMinimartGradeAction,
  setMinimartDetailsActiveTab,
  assignSalesPersonAction,
  assignedMinimartRouteAction,
  getRewardHistoryAction,
  updateMinimartLevelAction,
  mergeMinimartAction,
  getMinimartDetailsAction,
  paymentReverseAction,
  assignMessengerAction,
  addInvoiceAction,
  getSupplierAction,
  setExceptionalMinimartAction,
  getMergedMinimartsAction,
  downpaymnetReverseAction,

} from "./MiniMartDetailsAction";
import {
  setLoanDetailsIDAction,
  sendUpdatedMinimartDetailsAction,
} from "../LoanDetails/LoanDetailsAction";
import { downloadDownpaymentReceiptAction } from "../AnalystApproval/AnalystApprovalAction";
import { setSearchFilterAction } from "../../constants/Action";
import { getAssignedMinimartUserListAction } from "../MinimartApproval/MinimartApprovalAction";

// Constant
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  SEARCH_FILTER_PAGES,
  MINIMART_LIST_FILTER,
  NEW_MINIMART_LIST_FILTER,
  APPROVAL_DASHBOARD_FILTER,
  LOAN_DETAIL_FILTER,
  WORKLOAD_MANAGEMENT_FILTER,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";

// String
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./MinimartDetails.scss";

//Assets

class MinimartDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      loans: [],
      numberOfPages: 0,
      minimart: props.singleMiniMart,
      creditRequestData: null,
      creditAgreementRecords: [],
      paymentReceipts: [],
      totalPaymentReceiptPages: 0,
      errorMessage: "",
      rewardHistory: [],
      mergeMinimart: null,
      mergedMinimartsData: [],
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.props.getBranchList();
    this.onPageLoad();
  }

  onPageLoad = () => {
    this.getSingleMinimartData();
    this.getSingleMinimartNotes(this.props.singleMiniMart?.id);
    this.getMergedMinimarts(this.props.singleMiniMart?.id)
  };

  componentWillUnmount() {
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.MINIMART_LIST &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.MINIMART_LIST,
        {
          ...MINIMART_LIST_FILTER,
        }
      );
    }

    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.NEW_MINIMART_LIST &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.NEW_MINIMART_LIST,
        {
          ...NEW_MINIMART_LIST_FILTER,
        }
      );
    }

    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD,
        {
          ...APPROVAL_DASHBOARD_FILTER,
        }
      );
    }
    if (
      this.props.searchPage ===
      SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT,
        { ...WORKLOAD_MANAGEMENT_FILTER }
      );
    }

    if (!this.isCardClick) {
      this.props.setMinimartDetailsActiveTab(null);
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_DETAIL,
        { ...LOAN_DETAIL_FILTER }
      );
    }
  }

  getSingleMinimartData = () => {
    if (!this.props.singleMiniMart.id) {
      return;
    }
    this.setState({ showLoadingModal: true, errorMessage: "" });
    getSingleMinimartDataAction(
      this.props.singleMiniMart?.id,
      this.props.isNewMinimart,
      (responseData) => {
        if (responseData.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            minimart: responseData.data.data,
            showLoadingModal: false,
          });
        } else {
          this.setState({
            errorMessage: responseData.error.message,
            showLoadingModal: false,
          });
        }
      }
    );
  };

  handleInvoiceSearchFilter = (searchFilterObject) => {
    this.setState({ showLoadingModal: true, errorMessage: "" });
    getInvoicesBySearchFilterAction(
      {
        search: searchFilterObject.search,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (response.data.data.invoices.length === 0) {
            this.setState({
              invoices: [],
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
            });
          } else {
            this.setState({
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
              invoices: response.data.data.invoices,
            });
          }
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoadingModal: false,
          });
        }
      }
    );
  };

  handleLoanSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
      errorMessage: "",
      searchFilter: {
        st: searchFilterObject.search.status,
        ct: searchFilterObject.search.category,
        fd: searchFilterObject.search.fromDate,
        td: searchFilterObject.search.toDate,
        //mid: searchFilterObject.search.matricesId,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    getLoansBySearchFilterAction(
      {
        search: searchFilterObject.search,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (response.data.data.loans.length === 0) {
            this.setState({
              loans: [],
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
            });
          } else {
            this.setState({
              loans: response.data.data.loans,
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
            });
          }
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoadingModal: false,
          });
        }
      }
    );
  };

  setLoanDetailsID = (loanDetails, activeTab) => {
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.LOAN_DETAIL,
      this.state.searchFilter,
      SEARCH_FILTER_PAGES.LOAN_DETAIL
    );
    this.props.setMinimartDetailsActiveTab(activeTab);
    this.props.setLoanDetailsIDAction(loanDetails);
    this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  getPerticularCreditLimitRequest = (request) => {
    this.setState({ showLoadingModal: true });
    getPerticularCreditLimitRequestAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          creditRequestData: response.data.data,
          showLoadingModal: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
        });
      }
    });
  };

  creditLimitRequest = (request) => {
    this.setState({
      showLoadingModal: true,
      showToast: false,
    });
    creditLimitRequestAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoadingModal: false,
          errorMessage: "",
          showToast: true,
          message:
            response.data.data.sts === "APPROVED"
              ? "requestApprovedSuccessFully"
              : "requestRejectedSuccessFully",
          level: "success",
        });
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
          showToast: false,
        });
      }
    });
  };

  getCreditAgreementList = (matricesId) => {
    this.setState({ showLoadingModal: true, showToast: false });
    getCreditAgreementListAction(matricesId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          creditAgreementRecords: response.data.data,
          showLoadingModal: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  downloadCreditAgreement = (documentId) => {
    this.setState({ showLoadingModal: true, showToast: false });
    downloadCreditAgreementAction(documentId, (response) => {
      if (
        response.status === OPERATION_STATUS.SUCCESS &&
        response.data.data.url
      ) {
        if (response.data.data.url) {
          const s3Url = Utility.getDecryptedData(
            response.data.data.url
          );
          window.open(s3Url);
        }
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
        });
      } else {
        this.setState({
          showLoader: false,
          errorMessage: response.error.message,
        });
      }
    });
  };
  fetchAllPaymentReceipts = (data = {}) => {
    this.setState({ showLoadingModal: true, showToast: false });
    getAllPaymentReceiptsAction(data, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoadingModal: false,
          errorMessage: "",
          paymentReceipts: response.data.data.rp,
          totalPaymentReceiptPages: response.data.data.np,
        });
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };
  downloadPaymentReceipt = (receiptId) => {
    this.setState({ showLoadingModal: true, showToast: false });
    generatePaymentReceiptAction(receiptId, true, (response) => {
      if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
        this.setState({
          errorMessage: "",
          showLoadingModal: false,
        });
        const url = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = url;
        const filename = `${Strings(
          "PaymentReceiptFileName"
        )}${Utility.getReportFormattedDateString(
          Utility.convertDateIntoMiliSeconds(new Date())
        )}.pdf`; //or any other file extension
        link.setAttribute("download", filename);
        document.body.appendChild(link);

        link.click();
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: Strings("NoRecordsFound"),
        });
      }
    });
  };

  downloadDownpaymentReceipt = (receiptId, applicationId) => {
    this.setState({ showLoadingModal: true, showToast: false });
    downloadDownpaymentReceiptAction(receiptId, true, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
          fileDownload(
            response.data,
            `Recibo de Anticipo-${applicationId} ${Utility.getFileType(
              "pdf"
            )}`
          );

          this.setState({
            errorMessage: "",
            showLoadingModal: false,
          });
        } else {
          this.setState({
            showLoadingModal: false,
            errorMessage: Strings("NoRecordsFound"),
          });
        }
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  changeMinimartStatus = (noteRequest) => {
    this.setState({
      showLoadingModal: true, errorMessage: "",
      showToast: false,
      message: "",
      level: "",
    });
    addInconsistentPayerNote(noteRequest, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            errorMessage: "",
            showLoadingModal: false,
            showToast: true,
            message: Strings("minimartStatusUpdatedSuccessfully"),
            level: "success",
          },
          () => {
            this.onPageLoad();
          }
        );
      } else {
        this.setState({
          showToast: true,
          message: response.error.message,
          level: "error",
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  getSingleMinimartNotes = (matricesId) => {
    this.setState({ showLoadingModal: true, errorMessage: "" });
    getSingleMinimartNotesAction(matricesId, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          minimartNotes: responseData.data.data,
          showLoadingModal: false,
        });
      } else {
        this.setState({
          errorMessage: responseData.error.message,
          showLoadingModal: false,
        });
      }
    });
  };

  handleResetGrade = (gradeObject, closeModal) => {
    this.setState({ showLoadingModal: true, errorMessage: "" });
    resetMinimartGradeAction(gradeObject, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            showToast: true,
            message: Strings("resetGradeSuccess"),
            level: "success",
          },
          () => {
            this.onPageLoad();
            closeModal();
          }
        );
      } else {
        this.setState({
          showToast: true,
          message: responseData.error.message,
          level: "error",
          errorMessage: responseData.error.message,
          showLoadingModal: false,
        });
      }
    });
  };

  assignSalesPerson = (
    minimartId,
    date,
    successCallback,
    errorCallback
  ) => {
    this.setState({
      showLoadingModal: true,
      errorMessage: "",
      showToast: false,
    });
    const requestData = {
      mid: minimartId,
      ad: Utility.convertDateIntoMiliSeconds(date),
    };
    assignSalesPersonAction(requestData, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showToast: true,
            message: Strings("salesPersonAssignedSuccess"),
            level: "success",
            showLoadingModal: false,
            errorMessage: "",
          },
          () => {
            this.onPageLoad();
            successCallback();
          }
        );
      } else {
        this.setState(
          {
            showToast: true,
            message: responseData.error.message,
            level: "error",
            errorMessage: responseData.error.message,
            showLoadingModal: false,
          },
          () => {
            errorCallback(responseData.error.message);
          }
        );
      }
    });
  };

  assignMessenger = (minimartId, successCallback, errorCallback) => {
    this.setState({
      showLoadingModal: true,
      errorMessage: "",
      showToast: false,
    });
    assignMessengerAction(minimartId, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showToast: true,
            message: Strings("messengerAssignedSuccess"),
            level: "success",
            showLoadingModal: false,
            errorMessage: "",
          },
          () => {
            this.onPageLoad();
            successCallback();
          }
        );
      } else {
        this.setState(
          {
            showToast: true,
            message: responseData.error.message,
            level: "error",
            errorMessage: responseData.error.message,
            showLoadingModal: false,
          },
          () => {
            errorCallback(responseData.error.message);
          }
        );
      }
    });
  };

  handleRouteChange = (
    requestData,
    successCallback,
    errorCallback
  ) => {
    this.setState({ showLoadingModal: true });
    assignedMinimartRouteAction(requestData, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            showToast: true,
            message: Strings("minimartDetailsUpdate"),
            level: "success",
          },
          () => {
            this.onPageLoad();
            successCallback();
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
          },
          () => {
            errorCallback(response.error.message);
          }
        );
      }
    });
  };

  getAssignedMinimartUserList = (
    requestData,
    successCallback,
    errorCallback
  ) => {
    this.setState({
      showLoadingModal: true,
    });
    getAssignedMinimartUserListAction(requestData, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
          },
          () => {
            successCallback(response.data.data);
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
          },
          () => {
            errorCallback(response.error);
          }
        );
      }
    });
  };

  handleRewardHistorySearchFilter = (request) => {
    this.setState({
      showLoadingModal: true,
    });
    getRewardHistoryAction({ ...request }, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          rewardHistory: response.data.data.rh,
          numberOfPages: response.data.data.np,
          showLoadingModal: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
          rewardHistory: [],
          numberOfPages: 1,
        });
      }
    });
  };

  updateMinimartLevel = (request, successCallback, errorCallback) => {
    this.setState({
      showLoadingModal: true,
    });
    updateMinimartLevelAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            message: Strings("minimartLevelUpdateSuccess"),
            level: "success",
            showToast: true,
          },
          () => {
            successCallback();
            this.onPageLoad();
          }
        );
      } else {
        this.setState(
          {
            errorMessage: response.error.message,
            showLoadingModal: false,
            level: "error",
            message: response.error.message,
            showToast: true,
          },
          () => {
            errorCallback(response.error.message);
          }
        );
      }
    });
  };

  getMergeMinimartData = (matricesId) => {
    this.setState({ showLoadingModal: true, errorMessage: "" });
    getMinimartDetailsAction(matricesId, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          mergeMinimart: responseData.data.data,
          showLoadingModal: false,
        });
      } else {
        this.setState({
          errorMessage: responseData.error.message,
          showLoadingModal: false,
          mergeMinimart: null,
        });
      }
    });
  };

  handleMergeMinimart = (request, successCallback, errorCallback) => {
    this.setState({ showLoadingModal: true, errorMessage: "" });
    mergeMinimartAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            message: Strings("minimartMergedSuccessfully"),
            level: "success",
            showToast: true,
          },
          () => {
            successCallback();
            this.onPageLoad();
          }
        );
      } else {
        this.setState(
          {
            errorMessage: response.error.message,
            showLoadingModal: false,
            level: "error",
            showToast: true,
            message: response.error.message,
          },
          () => {
            errorCallback(response.error.message);
          }
        );
      }
    });
  };

  handlePaymentReverse = (mid, successCallback, errorCallback) => {
    this.setState({ showLoadingModal: true, errorMessage: "" });
    paymentReverseAction(mid, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            message: Strings("paymentReverseSuccessfully"),
            level: "success",
            showToast: true,
          },
          () => {
            successCallback();
            this.onPageLoad();
          }
        );
      } else {
        this.setState(
          {
            errorMessage: response.error.message,
            showLoadingModal: false,
            level: "error",
            showToast: true,
            message: response.error.message,
          },
          () => {
            errorCallback(response.error.message);
          }
        );
      }
    });
  };

  clearMergeMinimart = () => {
    this.setState({
      mergeMinimart: null,
    });
  };

  sendUpdatedMinimartDetails = (request, callback) => {
    this.setState({
      showLoadingModal: true,
      showToast: false,
    });
    sendUpdatedMinimartDetailsAction(request, (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            level: "success",
            showToast: true,
            message: Strings("DataUpdatedSuccessfully"),
          },
          () => {
            callback(true);
            this.onPageLoad();
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          showToast: true,
          level: "error",
          errorMessage: apiResponse.error.message,
          message: apiResponse.error.message,
        });
      }
    });
  };

  addInvoice = (request, callback) => {
    this.setState({
      showLoadingModal: true,
      showToast: false,
    });
    addInvoiceAction({ ...request }, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            level: "success",
            showToast: true,
            message: Strings("addInvoiceSuccess"),
          },
          callback
        );
      } else {
        this.setState({
          showLoadingModal: false,
          showToast: true,
          level: "error",
          errorMessage: response.error.message,
          message: response.error.message,
        });
      }
    });
  };

  getSupplierList = (callback) => {
    this.setState({ showLoadingModal: true });
    getSupplierAction((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
          },
          () => {
            callback(response.data.data);
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  setExceptionalMinimart = (mid, callback) => {
    this.setState({ showLoadingModal: true });
    setExceptionalMinimartAction(mid, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: "",
            level: "success",
            showToast: true,
            message: Strings("DataUpdatedSuccessfully"),
          },
          () => {
            callback();
            this.onPageLoad();
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
          message: response.error.message,
          showToast: true,
          level: "error",
        });
      }
    });
  };

  getMergedMinimarts = (mid, callback) => {
    getMergedMinimartsAction(mid, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({ mergedMinimartsData: response.data.data })
      }
    })
  }

  handleDownpaymentReverse = (mid, note, successCallback, errorCallback) => {
    this.setState({ showLoadingModal: true, errorMessage: "" });
    downpaymnetReverseAction(mid, note, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoadingModal: false,
          errorMessage: "",
          message: Strings("paymentReverseSuccessfully"),
          level: "success",
          showToast: true,
        },
          () => {
            successCallback();
            this.onPageLoad();
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
          level: "error",
          showToast: true,
          message: response.error.message,
        },
          () => {
            errorCallback(response.error.message);
          })
      }
    })
  }
  loadSpinner = () => {
    this.setState({
      showLoadingModal: true
    })
  }

  unloadSpinner = () => {
    this.setState({
      showLoadingModal: false
    })
  }

  render() {
    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        {this.state.showToast && (
          <UserNotification
            key='Success'
            userNotificationObj={{
              message: Strings(this.state.message),
              level: this.state.level,
            }}
          />
        )}
        <Row className='heading'>
          {Strings("minimartManagement")}-{Strings("details")}
        </Row>{" "}
        {this.state?.minimart?.minimartDetails && (
          <MinimartDetails
            matricesId={this.props.singleMiniMart?.id}
            invoices={this.state.invoices}
            loans={this.state.loans}
            numberOfPages={this.state.numberOfPages}
            handleInvoiceSearchFilter={this.handleInvoiceSearchFilter}
            handleLoanSearchFilter={this.handleLoanSearchFilter}
            minimart={this.state.minimart}
            singleMiniMart={this.props.singleMiniMart}
            setLoanDetailsID={this.setLoanDetailsID}
            goBack={this.goBack}
            loggedInUser={this.props.loggedInUser}
            creditRequestData={this.state.creditRequestData}
            getPerticularCreditLimitRequest={
              this.getPerticularCreditLimitRequest
            }
            creditLimitRequest={this.creditLimitRequest}
            getCreditAgreementList={this.getCreditAgreementList}
            creditAgreementRecords={this.state.creditAgreementRecords}
            downloadCreditAgreement={this.downloadCreditAgreement}
            errorMessage={this.state.errorMessage}
            getAllPaymentReceipts={this.fetchAllPaymentReceipts}
            paymentReceiptsPages={this.state.totalPaymentReceiptPages}
            paymentReceipts={this.state.paymentReceipts}
            downloadPaymentReceipt={this.downloadPaymentReceipt}
            downloadDownpaymentReceipt={this.downloadDownpaymentReceipt}
            changeMinimartStatus={this.changeMinimartStatus}
            minimartNotes={this.state.minimartNotes}
            handleResetGrade={this.handleResetGrade}
            searchFilter={this.props.searchFilter}
            activeTab={this.props.activeTab}
            assignSalesPerson={this.assignSalesPerson}
            assignMessenger={this.assignMessenger}
            isNewMinimart={this.props.isNewMinimart}
            // Following method and data objects added for change minimart route.
            getBranchList={this.props.getBranchList}
            getZonesByBranch={this.props.getZonesByBranch}
            handleRouteChange={this.handleRouteChange}
            getAssignedMinimartUserList={
              this.getAssignedMinimartUserList
            }
            branchList={this.props.branches}
            zones={this.props.zones}
            mZones={this.props.mZones}
            handleRewardHistorySearchFilter={
              this.handleRewardHistorySearchFilter
            }
            rewardHistory={this.state.rewardHistory}
            updateMinimartLevel={this.updateMinimartLevel}
            getMergeMinimartData={this.getMergeMinimartData}
            mergeMinimart={this.state.mergeMinimart}
            handleMergeMinimart={this.handleMergeMinimart}
            clearMergeMinimart={this.clearMergeMinimart}
            handlePaymentReverse={this.handlePaymentReverse}
            sendUpdatedMinimartDetails={
              this.sendUpdatedMinimartDetails
            }
            addInvoice={this.addInvoice}
            getSupplierList={this.getSupplierList}
            setExceptionalMinimart={this.setExceptionalMinimart}
            loadSpinner={this.loadSpinner}
            unloadSpinner={this.unloadSpinner}
            getSingleMinimartData={this.getSingleMinimartData}
            onMergedMinimartClick={() => {
              const functionKey = 'setLoanDetailsID';
              window[functionKey] = this.setLoanDetailsID;
              this.props.history.push(ROUTES.MERGED_MINIMART_DETAILS,
                {
                  mergedMinimarts: this.state.mergedMinimartsData,
                  isNewMinimart: this.props.isNewMinimart,
                  loggedInUser: this.props.loggedInUser,
                  matricesId: this.props.singleMiniMart?.id,
                  setLoanDetailsFunctionKey: functionKey,
                });
            }}
            handleDownpaymentReverse={this.handleDownpaymentReverse}

          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  singleMiniMart: state.minimartListReducer.singleMiniMart,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
  searchFilter: state.searchFilterReducer.loanDetailFilter,
  activeTab: state.minimartListReducer.activeTab,
  isNewMinimart: state.minimartListReducer.isNewMinimart,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoanDetailsIDAction,
      setSearchFilterAction,
      setMinimartDetailsActiveTab,
      getAssignedMinimartUserListAction,
      assignedMinimartRouteAction,
    },
    dispatch
  );
};

const MinimartDetailsWrapper = withHOC(MinimartDetailsContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinimartDetailsWrapper);

import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getRewardListAction = (request, callback) => {
  request.oft -= 1;
  callAPI(
    "getRewardListAction",
    API.getRewardList,
    "GET",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateRewardAction = (requestData, callback) => {
  let requestInfo = new FormData();

  requestData.rpid && requestInfo.append("id", requestData.rpid);
  requestInfo.append("name", requestData.rpn.trim());
  requestInfo.append("model", requestData.rpm.trim());
  requestData.rpd &&
    requestInfo.append("description", requestData.rpd);
  requestInfo.append("rewardPoints", requestData.rp);
  requestInfo.append("status", requestData.rps);
  requestData.rpi && requestInfo.append("image", requestData.rpi);

  callAPI(
    "updateRewardAction",
    API.updateReward,
    "POST",
    requestInfo,
    (apiResponse) => {
      callback(apiResponse);
    }, false, true
  );
};

import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Chips from '../ProductCatalog/chip';
import TextFieldComponent from '../CustomUIComponents/TextFieldComponent/TextFieldComponent';
import green_tick from "../../resources/images/Group_427.svg";
import { Strings } from "../../resources/i18n/i18n";
import InformativeModal from '../CustomUIComponents/InformativeModal/InformativeModal';

class BankConf extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newAccountType: "",
            addAccountType: false,
            accountType: {}
        }
    }

    handleChange = (event) => {
        this.setState({
            newAccountType: event.target.value.toUpperCase(),
            errorMessage: ""
        })
    }

    onChipClick = (accountType) => {
        if (accountType.ia) {
            this.props.getBankListAssociatedToAccount(accountType.ai, () => {
                this.setState({
                    addAccountType: false,
                    newAccountType: "",
                    accountType,
                    showModal: true
                })
            })
        } else {
            this.setState({
                addAccountType: false,
                newAccountType: "",
                accountType,
                showModal: true
            })
        }
    }

    handleAdd = () => {
        this.setState({
            addAccountType: true,
            newAccountType: ""
        })
    }

    onLoad = () => {
        this.setState({
            addAccountType: false,
            newAccountType: "",
            accountType: {},
            errorMessage: ""
        })
    }

    closeConfirmationModal = () => {
        this.setState({
            showModal: false,
            accountType: {},
        })
    }

    renderModal = () => {
        let accountInfo = "";
        let commonAccountInfo1 = this.state.accountType.ia === true
            ? Strings("disableWarningOfAssociatedBanks")
            : Strings("enableWarningOfAssociatedBanks");
        let commonAccountInfo2 = this.state.accountType.ia === true
            ? Strings("confimationWarningToDisableAccountType")
            : Strings("confimationWarningToEnableAccountType");
        if (this.state.accountType.ia === true &&
            this.props.banksToBeDisabled.length !== 0) {
            let accountInfoPart = Strings("warningForBanklist");
            accountInfo = `${commonAccountInfo1}\n${accountInfoPart}\n${this.props.banksToBeDisabled.toString().replaceAll(",", "\n")}\n${commonAccountInfo2}`
        } else {
            accountInfo = `${commonAccountInfo1}\n${commonAccountInfo2}`
        }

        return <InformativeModal
            openModal={this.state.showModal}
            modalHeader={Strings("bankInfo")}
            data={accountInfo}
            handleOkClick={() =>
                this.props.editAccountTypeState(this.state.accountType, this.closeConfirmationModal)}
            closeModal={this.closeConfirmationModal}
        />
    }

    handleAddAccountType = () => {
        let existingAccountTypes = this.props.allAccountTypes.map(accountType => accountType.at)
        let errorMessage = "";
        if (this.state.newAccountType === "" || this.state.newAccountType.length < 3) {
            errorMessage = Strings("EmptyFieldsError");
        } else if (this.state.newAccountType.length < 3) {
            errorMessage = Strings("minimumThreeCharacterLength");
        } else if (existingAccountTypes.includes(this.state.newAccountType)) {
            errorMessage = Strings("REQUEST_ALREADY_EXISTS")
        } else {
            this.props.handleAddAccountType({ at: this.state.newAccountType }, this.onLoad)
        }
        this.setState({ errorMessage })
    }



    render() {
        return <div className='accountTypeSection'>
            {this.state.showModal && this.renderModal()}
            <div className="errors mb-0">{this.state.errorMessage}</div>
            <Row>
                {this.props.allAccountTypes.map((accountType) => {
                    return <Col md={4} key={accountType.ai} className='mt-2 d-flex justify-content-between'>
                        <Chips
                            key={accountType.ai}
                            content={accountType.at}
                            chipId={accountType}
                            className={'catChips w-100'}
                            toggle={true}
                            onChipClick={this.onChipClick}
                            title={accountType.ia === true ? Strings("activeAccount") : Strings("inActiveAccount")}
                            disabled={this.props.readOnly}
                        />

                    </Col>
                })}
                {this.state.addAccountType && !this.props.readOnly &&
                    <Col md={4} className='mt-3 d-flex'>
                        <TextFieldComponent
                            placeholder={Strings("accountType")}
                            handleChange={this.handleChange}
                            value={this.state.newAccountType}
                            maxLength={45}
                        />
                        <img
                            className='cursorPointer ml-2'
                            src={green_tick}
                            alt='X'
                            onClick={this.handleAddAccountType} />
                    </Col>
                }
            </Row>
            <Row className='py-2'>
                {(!this.state.addAccountType && !this.props.readOnly) &&
                    <Col>
                        <Button
                            className='buttonBackground mx-1 pull-right'
                            onClick={this.handleAdd}
                        >
                            {Strings("addAccountType")}
                        </Button>
                    </Col>}
            </Row>
        </div>

    }
}

export default BankConf;

import { Strings } from "../../resources/i18n/i18n";

export const DEFAULT_FILE_STATUS = "selectFileStatus";

export const CSV_FILE = {
  TYPE: "CI_INPUT",
  SUBTYPE: "CI_INPUT",
  FILETYPE: "CSV",
};

export const INVOICE_CARD = {
  InvoiceImage: "InvoiceImage",
  status: "status",
  it: "it",

  minimartCode: "minimartCode",
  number: "number",
  balance: "balance",
  amount: "amount",
  date: "date",
  expiryDate: "expiryDate",
  tvd: "tvd",
};

export const CI_FILE_STATUS = [
  {
    key: 0,
    label: Strings("selectFileStatus"),
    value: DEFAULT_FILE_STATUS,
  },
  {
    key: 1,
    label: Strings("New"),
    value: "NEW",
  },
  {
    key: 2,
    label: Strings("PROCESSING"),
    value: "PROCESSING",
  },
  {
    key: 3,
    label: Strings("PROCESSED"),
    value: "PROCESSED",
  },
  {
    key: 4,
    label: Strings("FAILED"),
    value: "FAILED",
  },
];

export const GET_CI_FILE_COLUMNS = () => [
  {
    key: "name",
    name: Strings("fileName"),
  },
  {
    key: "status",
    name: Strings("status"),
  },
  {
    key: "uploadedAt",
    name: Strings("Uploaded"),
  },
  {
    key: "processedAt",
    name: Strings("Processed"),
  },
];

const defaultLanguageItem = {
  code: "en",
  name: "EN",
};

export default function LanguageLookup(languageCode) {
  let languageItem = {};

  languageItem.code = "en";
  languageItem.name = "EN";

  switch (languageCode) {
    case "en":
      languageItem.code = "en";
      languageItem.name = "EN";
      break;

    case "es":
      languageItem.code = "es";
      languageItem.name = "ES";
      break;

    default:
      languageItem = defaultLanguageItem;
  }

  return languageItem;
}

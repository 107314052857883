import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Strings } from "../../resources/i18n/i18n";
import moment from "moment";

//components
import ConfigurationModal from "./ConfigurationModal";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//constants
import "./ConfigurationDashboard.scss";
import {
  INVOICE_DATE_FORMAT,
  SORT_TYPE,
  SEARCH_FILTER_TYPE,
} from "../../constants/appConstants";
import { GET_HOLIDAY_COLUMNS, WEEKDAYS } from "./Constants";

//Utility
import Utility from "../../utils/Utility";

//Assets
import addUser from "../../resources/images/ic_add_user.svg";

class HolidayConfiguration extends Component {
  constructor(props) {
    super(props);
    this._columns = GET_HOLIDAY_COLUMNS();
    this.state = {
      addHoliday: false,
      editHoliday: false,
      selectedRow: { date: new moment().add(1, "day"), info: "" },
      errorMessage: "",
      successMessage: "",
      notificationSuccess: false,
      notificationFailure: false,
      openModal: false,
    };
  }

  //handle delete holiday from the list
  handleDeleteRow = (deleteRowIndex) => {
    let rows = [
      ...this.props.configurationData.configurationObject.holidayList,
    ];
    rows.splice(deleteRowIndex, 1);
    this.setState({
      rows: rows,
    });
    this.saveEditedDataInTheState(rows);
  };

  closeModal = () => {
    this.setState({
      addHoliday: false,
      editHoliday: false,
      selectedRow: { date: new moment().add(1, "day"), info: "" },
      errorMessage: "",
    });
  };

  renderConfigurationModal = () => {
    return (
      <ConfigurationModal
        open={
          this.state.addHoliday
            ? this.state.addHoliday
            : this.state.editHoliday
        }
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={
          this.state.addHoliday
            ? Strings("addHoliday")
            : Strings("editHoliday")
        }
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.closeModal,
        }}
        modalButtonApprove={{
          onClick: this.handleAddAndEditApprove,
          text: Strings("Approve"),
        }}
        handleChange={this.handleChange}
        errorMessage={this.state.errorMessage}
        selectedRow={this.state.selectedRow}
        handleCalenderChange={this.handleCalenderChange}
        dateFormat={INVOICE_DATE_FORMAT}
        editHoliday={this.state.editHoliday}
      />
    );
  };

  renderCustomDataGrid = () => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    return (
      <div className='w-75'>
        <TableContainer component={Paper} className='w-100'>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                {this._columns.map((column, colIndex) => (
                  <StyledTableCell
                    key={colIndex}
                    className={
                      column.key === "date"
                        ? "text-left date header"
                        : "text-center header"
                    }
                  >
                    {column.name}
                  </StyledTableCell>
                ))}
                {!this.props.readOnly && (
                  <StyledTableCell></StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.configurationData.configurationObject.holidayList.map(
                (row, rowIndex) => {
                  //NOSONAR
                  let isBefore = new moment(row.date).isBefore(
                    new moment().add(1, "day")
                  );
                  let classNameString = isBefore
                    ? "pl-2 disabled fa fa-trash"
                    : "pl-2 fa fa-trash";
                  return (
                    <StyledTableRow
                      key={rowIndex}
                      hover
                      style={{ cursor: "pointer" }}
                    >
                      {this._columns.map((column, colIndex) => (
                        <StyledTableCell
                          key={rowIndex + "" + colIndex}
                          className={
                            column.key === "date"
                              ? "text-left date"
                              : "text-center"
                          }
                          component='th'
                          scope='row'
                          onClick={() =>
                            !this.props.readOnly
                              ? this.onRowClick(row)
                              : () => {
                                // This intentional
                              }
                          }
                        >
                          {column.key === "date"
                            ? new moment(row[column.key]).format(
                              INVOICE_DATE_FORMAT
                            )
                            : Strings(row[column.key])}
                        </StyledTableCell>
                      ))}
                      {!this.props.readOnly ? (
                        <TableCell className='p-0 border-0 align-items-center'>
                          {
                            <i
                              className={classNameString}
                              aria-hidden='true'
                              title='Eliminar Subtipo'
                              onClick={() =>
                                !this.props.readOnly && !isBefore
                                  ? this.handleDeleteRow(rowIndex)
                                  : () => {
                                    //this is intensional
                                  }
                              }
                            />
                          }
                        </TableCell>
                      ) : null}
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  renderTable = () => {
    return this.props.configurationData.configurationObject
      .holidayList &&
      this.props.configurationData.configurationObject.holidayList
        ?.length !== 0 ? (
      <div className='d-flex justify-content-center'>
        {this.renderCustomDataGrid()}
      </div>
    ) : (
      <div className='noRecordsFound'>
        {Strings("NoRecordsFound")}
      </div>
    );
  };

  addHoliday = () => {
    this.setState({
      addHoliday: true,
      editHoliday: false,
      holiday: {
        date: new moment().add(1, "day"),
        day: "",
        info: "",
      },
    });
  };

  //handle edit or add approve buttons on modal
  handleAddAndEditApprove = () => {
    const index =
      this.props.configurationData.configurationObject.holidayList.findIndex(
        (holiday) => {
          return (
            new moment(holiday.date).format(INVOICE_DATE_FORMAT) ===
            new moment(this.state.selectedRow.date).format(
              INVOICE_DATE_FORMAT
            )
          );
        }
      );
    if (this.state.editHoliday) {
      const rows =
        this.props.configurationData.configurationObject.holidayList;
      rows[index] = this.state.selectedRow;
      this.setState({
        addHoliday: false,
        editHoliday: false,
        rows,
        selectedRow: { date: new moment().add(1, "day"), info: "", day: "" },
      });
      this.saveEditedDataInTheState(rows);
    } else if (this.state.addHoliday) {
      if (index === -1) {
        const date = new Date(this.state.selectedRow.date);
        const day = date.getDay();
        let selectedRow = this.state.selectedRow;
        selectedRow.day = WEEKDAYS[day];
        let rows = [
          ...this.props.configurationData.configurationObject
            .holidayList,
        ];
        rows.push(selectedRow);
        this.setState({
          addHoliday: false,
          editHoliday: false,
          rows,
          selectedRow: { date: new moment().add(1, "day"), info: "", day: "" },
        });

        this.saveEditedDataInTheState(rows);
      } else {
        this.setState({
          errorMessage: Strings("dateAlreadyExist"),
        });
      }
    }
  };

  saveEditedDataInTheState = (rows) => {
    //sorting the holiday list by date
    Utility.sortRows(
      SEARCH_FILTER_TYPE.SINGLE_DATE,
      SORT_TYPE.ASC,
      rows
    );
    this.props.saveEditedDataInTheState(rows, false);
  };

  //if the date is today's date or befor today's date it cannot be edited
  onRowClick = (row) => {
    let isBefore = new moment(row.date).isBefore(new moment().add(1, "day"));
    let selectedRow = { date: new moment().add(1, "day"), info: "", day: "" };

    if (!isBefore) {
      selectedRow.date = new Date(row.date);
      selectedRow.info = row.info;
      selectedRow.day = row.day;
    }
    this.setState({
      editHoliday: !isBefore,
      selectedRow,
    });
  };

  //handle holiday info change on modal
  handleChange = (event) => {
    const info = event.target.value;
    let errorMessage = "";
    if (info === "") {
      errorMessage = Strings("pleaseEnterInformation");
    }
    this.setState({
      selectedRow: {
        ...this.state.selectedRow,
        info,
      },
      errorMessage: errorMessage,
    });
  };

  //handle calendar change on modal
  handleCalenderChange = (dateMoment) => {
    const date = new Date(dateMoment._d);
    this.setState({
      selectedRow: {
        ...this.state.selectedRow,
        date,
      },
      errorMessage: "",
    });
  };

  render() {
    return (
      <>
        {this.renderConfigurationModal()}
        <Row className='containerPadding'>
          {!this.props.readOnly && (
            <Button
              className='addOrUpdateUserTopButtons subHeaderApplication userListAddButtonChangeFont'
              onClick={() => this.addHoliday()}
              disabled={this.props.readOnly}
            >
              <img src={addUser} alt='' />
              <span className='addTypeButtonText'>
                {Strings("addHoliday").toUpperCase()}
              </span>
            </Button>
          )}
        </Row>
        <Col md={12} className=' errorText'>
          {this.state.errorMessage !== ""
            ? this.state.errorMessage
            : this.props.errorMessage}
        </Col>
        {/* render holiday list table */}
        {this.renderTable()}
      </>
    );
  }
}

export default HolidayConfiguration;

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
import { USER_ROLES } from "../../constants/appConstants";

export const TABLE_HEADER_LIST = [
  {
    key: "mid",
    name: Strings("MatricesID"),
    minWidth: 75,
  },
  {
    key: "mc",
    name: Strings("minimartCIId"),
  },
  {
    key: "mn",
    name: Strings("minimartName"),
    minWidth: 75,
  },
  { key: "selection", name: "selection" },
  { key: "action", name: "action" },
];

export const SECOND_ROW = [
  {
    key: "brn",
    name: Strings("associatedBranch"),
  },
  {
    key: "zn",
    name: Strings("associatedZone"),
  },
  {
    key: "ct",
    name: Strings("City"),
  },
  {
    key: "scr",
    name: Strings("Sector"),
  },
  {
    key: "srt",
    name: Strings("Street"),
  },
  {
    key: "no",
    name: Strings("number"),
  },
  {
    key: "ref",
    name: Strings("Reference"),
  },
  {
    key: "mty",
    name: Strings("matchType"),
  },
];

export const STATUS_LIST = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  EXISTING: "EXISTING",
  PENDING: "PENDING",
};

export const SIGN_UP_REQUEST_APPROVED_USERS = [
  "GENERAL_MANAGER",
  "ADMIN",
  "OPERATIONS_MODERATOR",
  "OPERATIONS_OFFICER"
];

export const CI_MINIMART_APPROVED_USERS = [
  USER_ROLES.admin,
  USER_ROLES.operationsModerator,
  USER_ROLES.operationsManager,
  USER_ROLES.generalManager,
];

export const MINIMART_DETAILS = [
  {
    key: "mn",
    label: "MiniMarketName",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "on",
    label: "fullName",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "mc",
    label: "code",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "cdid",
    label: "ownerCedulaNumber",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: true,
  },
  {
    key: "tp",
    label: "PhoneNumber",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: true,
  },
  {
    key: "ct",
    label: "City",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "scr",
    label: "Sector",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "srt",
    label: "Street",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "no",
    label: "number",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: true,
  },
  {
    key: "ref",
    label: "Reference",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
];

//libraries
import React, { Component } from "react";
import { connect } from "react-redux";
//components
import { Row, Col } from "react-bootstrap";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
//actions NOSONAR

//constants
import { Strings } from "../../resources/i18n/i18n";
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../constants/appConstants";

//utilities NOSONAR
//stylesheets NOSONAR
import "./Products.scss";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        pn: "",
      },
      selectedProduct: null,
      //editProduct: null,
      editedData: {
        pn: "",
        pu: null,
        pd: "",
      },
      //errorMessage: "",
    };
  }

  // #region select product record
  toggleDetails = (id) => {
    let selectedProduct = id
      ? this.props.productList?.find((product) => product.id === id)
      : null;
    this.setState({
      selectedProduct,
    });
  };

  // #endregion

  // #region render selected details

  renderProductDetails = (product) => {
    return (
      <div className='product' key={product.id}>
        <Row
          className='cursorPointer'
          onClick={() =>
            this.state.selectedProduct?.id === product.id
              ? this.toggleDetails()
              : this.toggleDetails(product.id)
          }
        >
          <Col md={5} lg={5} title={Strings("productName")}>
            {Strings(product.pn)}
          </Col>
          <Col md={5} lg={5} title={Strings("uptoAmount")}>
            <span className='detailText'>
              {Strings("uptoAmount")}:&nbsp;
            </span>
            {product.pu}
          </Col>
          <Col md={2} lg={2}>
            <i
              className={`fa pull-right ${
                this.state.selectedProduct !== null &&
                this.state.selectedProduct.id === product.id
                  ? "fa-chevron-up"
                  : "fa-chevron-down"
              }`}
              title={Strings("View")}
            />
          </Col>
        </Row>
        <Row
          className={
            this.state.selectedProduct !== null &&
            this.state.selectedProduct.id === product.id
              ? "visible mt-2"
              : "hidden"
          }
        >
          <Col md={10} lg={10} title={Strings("Description")}>
            <span className='detailText'>
              {Strings("Description")}:&nbsp;
            </span>
            {product.pd ? product.pd : Strings("NA")}
          </Col>
        </Row>
      </div>
    );
  };

  // #endregion

  /* This code is currently inaccessible as edit feature is on hold
  renderEditableProductDetails = () => {
    return (
      <Row className='product' key={this.state.editedData?.pn}>
        <div className='errors col-md-12 col-lg-12'>
          {this.state.errorMessage
            ? this.state.errorMessage
            : "     "}
        </div>
        <Col md={3} lg={3}>
          <Row className='mb-5' title={Strings("productName")}>
            {this.state.editedData.pn}
          </Row>
          <TextFieldComponent
            required
            id='uptoAmount'
            type='text'
            placeholderTop={Strings("uptoAmount")}
            className='mt-4'
            handleChange={(e) => this.handleProductLimitChange(e)}
            value={this.state.editedData.pu}
            onKeyDown={Utility.allowOnlyNumber}
            disabled={this.props.readOnly}
          />
        </Col>
        <Col md={6} lg={6}>
          <textarea
            required
            id='description'
            placeholder={Strings("Description")}
            className='form-control'
            rows={4}
            value={this.state.editedData.pd}
            onChange={(e) => this.handleProductDescChange(e)}
          />
        </Col>
        <Col md={3} lg={3}>
          <Button
            className='buttonBorder ml-3 pull-right'
            onClick={() => this.toggleEdit()}
          >
            {Strings("Cancel")}
          </Button>

          <Button
            className='buttonBackground pull-right'
            onClick={() => this.updateProduct()}
          >
            {Strings("Submit")}
          </Button>
        </Col>
      </Row>
    );
  };

  toggleEdit = (id) => {
    let editProduct = id
      ? this.props.productList?.find((product) => product.id === id)
      : null;
    let editedData = id
      ? this.props.productList?.find((product) => product.id === id)
      : {
          pn: "",
          pu: null,
          pd: "",
        };
    this.setState({
      editProduct,
      editedData: {
        ...editedData,
        peb: this.props.loggedInUser.username,
      },
      errorMessage: "",
    });
  };

  updateProduct = () => {
    this.props.updateProduct(this.state.editedData);
    this.setState(
      {
        selectedProduct: null,
      },
      () => {
        this.toggleEdit();
      }
    );
  };
  
  // #region handle field value changes

  handleProductLimitChange = (e) => {
    this.setState({
      editedData: {
        ...this.state.editedData,
        pu: e.target.value !== "" ? parseInt(e.target.value) : 0,
      },
      errorMessage:
        e.target.value.length > 0 ? "" : Strings("ErrorEmptyValue"),
    });
  };

  handleProductDescChange = (e) => {
    e.target.textLength === 250
      ? this.setState({
          editedData: {
            ...this.state.editedData,
            pd: e.target.value,
          },
          errorMessage: `250 ${Strings("charactersAllowed")}`,
        })
      : this.setState({
          editedData: {
            ...this.state.editedData,
            pd: e.target.value,
          },
          errorMessage:
            e.target.value.length > 0
              ? ""
              : Strings("ErrorEmptyValue"),
        });
  };
  // #endregion

  */

  // #region search

  handleSearchNameChange = (e) => {
    let pn =
      e.target.value === Strings("productName") ? "" : e.target.value;
    this.setState({
      filters: {
        pn,
      },
    });
  };

  resetSearchValues = () => {
    this.setState(
      {
        filters: {
          pn: "",
        },
      },
      () => {
        this.props.handleOnClickSearchFilter(this.state.filters);
      }
    );
  };

  renderSearchFilters = () => {
    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("productName"),
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.filters.pn,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        filterDropdownList: this.props.allProductsList,
      },
    ];
    return (
      <Row className='filters mt-3 mb-3 greyBack'>
        <SearchFilter
          filters={searchFilters}
          handleOnClickSearchFilter={() =>
            this.props.handleOnClickSearchFilter(this.state.filters)
          }
          handleOnClickResetFilter={this.resetSearchValues}
          paddingLeft={2}
        />
      </Row>
    );
  };

  // #endregion

  render() {
    return (
      <div className='paper greyBack'>
        {this.renderSearchFilters()}
        <div className='productListContainer'>
          {this.props.productList.length > 0 ? (
            this.props.productList.map((product) => {
              return this.renderProductDetails(product);
            })
          ) : (
            <div className='noRecordsFound noBranchRecords'>
              {Strings("NoRecordsFound")}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
});

export default connect(mapStateToProps)(Products);

// Constants
import {
  SET_BRANCH_DETAILS_ACTION,
  CLEAR_BRANCH_DETAILS_ACTION,
  SET_ZONES_ACTION,
} from "../../redux/constants";

const INITIAL_STATE = {
  branchDetails: {
    id: 0,
    active: "",
    name: "",
    address: "",
    phone: "",
    zone: "",
  },
  branches: null,
  zones: [],
};

export default function BranchListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_BRANCH_DETAILS_ACTION:
      return {
        ...state,
        branchDetails: action.branchDetails,
      };

    case SET_ZONES_ACTION:
      return {
        ...state,
        zones: action.zones,
      };

    case CLEAR_BRANCH_DETAILS_ACTION:
      return {
        ...state,
        branchDetails: INITIAL_STATE.branchDetails,
      };
    default:
      return state;
  }
}

import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import { SET_APPROVE_MINIMART_DETAILS_ACTION } from "../../redux/constants";

export const setApproveMiniMartDetailsAction =
  (minimart, isHistory) => (dispatch) => {
    dispatch({
      type: SET_APPROVE_MINIMART_DETAILS_ACTION,
      singleMiniMart: minimart,
      isHistory: isHistory,
    });
  };

export const getApproveMinimartDataAction = (
  requestData,
  callback
) => {
  callAPI(
    "getApproveMinimartDetails",
    API.getApproveMinimartDetails,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getApproveMinimartNotesAction = (
  requestData,
  callback
) => {
  callAPI(
    "getApproveMinimartNotesAction",
    API.getApproveMinimartNotes,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateMinimartDetailsAction = (
  requestData,
  callback
) => {
  callAPI(
    "updateMinimartDetailsAction",
    API.updateMinimartDetails,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const approveMinimartAction = (requestData, callback) => {
  callAPI(
    "approveMinimart",
    API.approveMinimart,
    "PUT",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const operationsApproveMinimartAction = (
  requestData,
  callback
) => {
  callAPI(
    "operationsApproveMinimart",
    API.operationsApproveMinimart,
    "PUT",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import React from "react";

export const MatricesPrivacyPolicy = () => {
  return (
    <div className='p-5'>
      <h5 className='text-center'>
        <strong>
          POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS MATRICES
          TRANSACTIONS, SRL
        </strong>
      </h5>

      <p>
        La presente política de privacidad establece los términos en
        que <strong>MATRICES TRANSACTIONS SRL</strong> (en lo adelante
        la empresa) usa y protege la información que es proporcionada
        por sus usuarios y clientes al momento de utilizar su
        aplicación. De conformidad con lo establecido en la
        legislación dominicana, la empresa se compromete a adoptar
        todas las medidas necesarias para asegurar la privacidad de
        los datos personales recabados de forma que se garantice su
        privacidad, se evite su alteración, pérdida o tratamiento no
        autorizado.
      </p>

      <p>
        Todo dato personal que sea recabado a través del sitio web o
        la aplicación, será tratado de conformidad con los principios
        de licitud, calidad, finalidad, lealtad, y responsabilidad.
        Todo tratamiento de datos personales quedará sujeto al
        consentimiento de su titular. En todo caso, la utilización de
        datos financieros o patrimoniales, requerirán de autorización
        expresa de sus titulares, no obstante, esta podrá darse a
        través de la propia aplicación utilizando los mecanismos
        habilitados para tal efecto, y en todo caso se dará la mayor
        diligencia y cuidado a este tipo de datos. Lo mismo ocurrirá
        en el caso de datos personales sensibles, considerando por
        estos aquellos que debido a una utilización indebida puedan
        dar origen a discriminación o su divulgación conlleve un
        riesgo para el usuario.
      </p>

      <p>
        En todo momento se procurará que los datos personales
        contenidos en las bases de datos o archivos que en su caso se
        utilicen, sean pertinentes, correctos y actualizados para los
        fines para los cuales fueron recabados. La empresa se reserva
        el derecho a modificar sus políticas de Privacidad, de acuerdo
        con sus necesidades o derivado de algún cambio en la
        legislación por lo que le recomendamos y enfatizamos revisar
        continuamente esta página para asegurarse que está de acuerdo
        con dichos cambios. El acceso o utilización del sitio web o la
        aplicación después de dichos cambios, implicará la aceptación
        de estos.
      </p>
      <p>
        Por otra parte, el acceso al sitio web o la aplicación puede
        implicar la utilización de cookies, las cuales, son pequeñas
        cantidades de información que se almacenan en el navegador
        utilizado por el usuario. Las cookies facilitan la navegación,
        la hacen más amigable, y no dañan el dispositivo de
        navegación, para ello, pueden recabar información para
        ingresar al sitio web, almacenar las preferencias del usuario,
        así como la interacción que este tenga con el sitio web, como
        por ejemplo: la fecha y hora en la que se accede al sitio web,
        el tiempo que se ha hecho uso de este, los sitios visitados
        antes y después del mismo, el número de páginas visitadas, la
        dirección IP de la cual accede el usuario, la frecuencia de
        visitas, etc. Este tipo de información será utilizada para
        mejorar el sitio web, detectar errores, y posibles necesidades
        que el usuario pueda tener, lo anterior a efecto de ofrecer a
        los usuarios, servicios y contenidos de mejor calidad. En todo
        caso, la información que se recopile será anónima y no se
        identificará a usuarios individuales.
      </p>
      <p>
        En caso de que el usuario no desee que se recopile este tipo
        de información deberá deshabilitar, rechazar, restringir y/o
        eliminar el uso de cookies en su navegador de internet. Los
        procedimientos para realizar estas acciones pueden diferir de
        un navegador a otro; en consecuencia, se sugiere revisar las
        instrucciones facilitadas por el desarrollador del navegador.
        En el supuesto de que rechace el uso de cookies (total o
        parcialmente) el usuario podrá continuar haciendo uso del
        sitio web, aunque podrían quedar deshabilitadas algunas de las
        funciones de este.
      </p>
      <p>
        Es posible que en el futuro estas políticas respecto a las
        cookies cambien o se actualicen, por ello es recomendable
        revisar las actualizaciones que se realicen a los presentes
        términos y condiciones, con objetivo de estar adecuadamente
        informado sobre cómo y para qué utilizamos las cookies que se
        generan al ingresar o hacer uso del sitio web.
      </p>

      <p>
        La aplicación podrá incluir hipervínculos o enlaces que
        permitan acceder a páginas web de terceros distintos de la
        empresa. Los titulares de dichos sitios web dispondrán de sus
        propias políticas de privacidad y protección de datos, por lo
        cual la empresa no asume ningún tipo de responsabilidad por
        los datos que sean facilitados por el usuario a través de
        cualquier sitio web o aplicación distinta. La empresa en
        ningún caso revisará o controlará el contenido de los sitios
        externos, de igual forma, no hace propios los productos,
        servicios, contenidos, y cualquier otro material existente en
        los referidos sitios enlazados; por lo cual, tampoco se
        garantizará la disponibilidad, exactitud, veracidad, validez o
        legalidad de los sitios externos a los que se pueda tener
        acceso a través del sitio web.
      </p>
    </div>
  );
};

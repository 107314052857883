import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Strings } from "../../resources/i18n/i18n";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import fileDownload from "js-file-download";
import "./CallNotes.scss";

import CallNotes from "./CallNotes";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

import { dowloadedAction } from "../../constants/Action";
import { OPERATION_STATUS } from "../../config/axios.init";
import Utility from "../../utils/Utility";

import { DOWNLOAD_FILE_TYPE } from "../../constants/appConstants";

class CallNotesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isError: false,
      errorMessage: "",
      notificationMessage: "",
    };
  }
  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({ showLoader: true, isError: false });
    dowloadedAction(
      "",
      searchFilterObject,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
            fileDownload(
              response.data,
              `${Strings(
                "CallNotesReport"
              )}${Utility.getReportFormattedDateString(
                Utility.convertDateIntoMiliSeconds(new Date())
              )}${Utility.getFileType(DOWNLOAD_FILE_TYPE.CSV)}`
            );
            this.setState({
              showLoader: false,
              isError: false,
              errorMessage: "",
              notificationMessage: "",
            });
          } else {
            this.setState({
              showLoader: false,
              isError: true,
              notificationMessage: Strings("NoRecordsFound"),
            });
          }
        } else {
          this.setState({
            showLoader: false,
            isError: true,
            errorMessage: response.error.message,
            notificationMessage: Strings("NoRecordsFound"),
          });
        }
      },
      "CallNotesReports"
    );
  };

  handleResetClick = () => {
    this.setState({
      isError: false,
      notificationMessage: "",
    });
  };

  render() {
    return (
      <div className='callNotes'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isError && (
          <UserNotification
            key='Error'
            userNotificationObj={{
              message: Strings(this.state.notificationMessage),
              level: "error",
            }}
          />
        )}
        <Row className='heading'>{Strings("ExportCallNotes")}</Row>
        <CallNotes
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          errorMessage={this.state.errorMessage}
          handleResetClick={this.handleResetClick}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); //NOSONAR

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallNotesContainer);

import React from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";

//Component
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";
import EditableModal from "../LoanDetails/EditableModal";

//Constants
import {
  BASIC_ADMIN_INFO_DETAILS_ARRAY,
  OTHER_INFO_DETAILS_ARRAY,
  REFERENCES,
  ADDRESS,
  NOTES_TYPE,
  VIEW_LOAN_APP_TYPES,
  EMI_CALCULATION,
  ainfo,
  sd,
  loanApplicationEdit,
  loanUpdateMandetoryCheck,
} from "./Constants";
import {
  EVIDENCE,
  CONFIGURATION_DATE_TIME_FORMAT,
  INVOICE_DATE_FORMAT,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utils
import Utility from "../../utils/Utility";

// Styles
import "../ViewLoanApplication/ViewLoanApplication.scss";

//Assets
import addNotes from "../../resources/images/ic_add_note.svg";
import editNote from "../../resources/images/ic_edit.svg";
import deleteNote from "../../resources/images/ic_cross_blue.svg";
import loanImage from "../../resources/images/ic_loan_portfolio_details.svg";

class ViewLoanApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notesModal: false,
      isEditNote: false,
      note: "",
      noteId: null,
      showModal: false,
      editableData: {},
      sectionToEdit: undefined
    };
  }

  renderHeader = (headerText) => {
    return (
      <span className='fullWidth subHeaderText'>{headerText}</span>
    );
  };

  renderApplicationDetails = (info, props, invalidValues = []) => {

    return (
      <Col
        md={3}
        className={'infoViewContainer paddingSeparation pl-0'}
        key={props.key}
      >
        <div
          className={invalidValues.includes(props.key)
            ? 'errors m-0 p-0'
            : 'infoHeader'}>
          {props.title}
        </div>
        {info ? props.formatter(info[props.key]) : null}
      </Col>
    );
  };

  //Toggle update loan information modal
  showEditLoanFormModal = (section = undefined, invalidValues = []) => {
    this.setState({
      showModal: !this.state.showModal,
      errorMessage: "",
      invalidValues: invalidValues
    }, () => {
      if (this.state.showModal) {
        this.editLoanApplicationForm(section)
      }
    })
  }


  editLoanApplicationForm = (sectionToEdit) => {
    let dropdowns = [];
    this.props.dropdownValues.forEach((value) => {
      if (sectionToEdit === "basicInfo" && value.ft === "BASIC_INFO") {
        dropdowns = value.ff
      } else if (sectionToEdit === "income" && value.ft === "INCOME") {
        dropdowns = value.ff
      } else if (sectionToEdit === "expenses" && value.ft === "EXPENSES") {
        dropdowns = value.ff
      } else if (sectionToEdit === "siteVisit" && value.ft === "SITE_VISIT") {
        dropdowns = value.ff
      }
    })
    let editableData = {};
    if (sectionToEdit === "comercialRef1" ||
      sectionToEdit === "comercialRef2" ||
      sectionToEdit === "personalRef1" ||
      sectionToEdit === "personalRef2" ||
      sectionToEdit === "neighbourhoodRef1" ||
      sectionToEdit === "neighbourhoodRef2"
    ) {
      editableData["additionalInfo"] = {
        ...this.props.additionalInfo,
        [sectionToEdit]: this.props.additionalInfo[sectionToEdit]
          ? this.props.additionalInfo[sectionToEdit]
          : {}
      };
    } else if (sectionToEdit === "supplierInfo") {
      editableData["loanInfo"] = {
        ...this.props.loanInfo,
        accountInfo: this.props.loanInfo.accountInfo
          ? this.props.loanInfo.accountInfo
          : {}
      };
    } else if (sectionToEdit === "basicInfo") {
      editableData["basicInfo"] = {
        ...this.props.basicInfo,
        bankDetail: this.props.basicInfo.bankDetail
          ? this.props.basicInfo.bankDetail
          : [],
        socialMediaAccountDetails: this.props.basicInfo.socialMediaAccountDetails
          ? this.props.basicInfo.socialMediaAccountDetails
          : [],
      };
    } else {
      editableData = { [sectionToEdit]: this.props[sectionToEdit] };
    }
    this.setState({
      sectionToEdit,
      editableData,
      dropdowns
    })
  }

  //Update admin date of birth
  handleAdminDOBChange = (value) => {
    let editableData = this.state.editableData;
    editableData["basicInfo"] = {
      ...editableData["basicInfo"],
      adminDateOfBirth: Utility.convertDateIntoMiliSeconds(value)
    };
    this.setState({
      editableData
    })
  };

  //Update owner date of birth
  handleOwnerDOBChange = (value) => {
    let editableData = this.state.editableData;
    editableData["basicInfo"] = {
      ...editableData["basicInfo"],
      ownerDateOfBirth: Utility.convertDateIntoMiliSeconds(value)
    };
    this.setState({
      editableData
    })
  };

  //handle input fields change
  handleInputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value === Strings("select") ? "" : event.target.value;
    let editableData = {}
    if (this.state.sectionToEdit === "comercialRef1" ||
      this.state.sectionToEdit === "comercialRef2" ||
      this.state.sectionToEdit === "personalRef1" ||
      this.state.sectionToEdit === "personalRef2" ||
      this.state.sectionToEdit === "neighbourhoodRef1" ||
      this.state.sectionToEdit === "neighbourhoodRef2") {
      editableData = { additionalInfo: { ...this.state.editableData.additionalInfo, [this.state.sectionToEdit]: this.state.editableData.additionalInfo[this.state.sectionToEdit] } };
      editableData.additionalInfo[this.state.sectionToEdit] = {
        ...editableData.additionalInfo[this.state.sectionToEdit],
        [name]: value
      }
    } else if (this.state.sectionToEdit === "supplierInfo") {
      if (name === "selectedDisbursementType" || name === "selectedTransferAccountType") {
        if (value === "DELIVERY" || value === "SUPPLIER_ACCOUNT") {
          editableData = { loanInfo: { ...this.state.editableData.loanInfo } };
          editableData.loanInfo = {
            ...editableData.loanInfo,
            [name]: value,
            accountInfo: null,
            selectedTransferAccountType: value === "DELIVERY"
              ? null
              : value,
          }
        } else {
          editableData = { loanInfo: { ...this.state.editableData.loanInfo } };
          editableData.loanInfo = {
            ...editableData.loanInfo,
            accountInfo: this.props.loanInfo.accountInfo
              ? this.props.loanInfo.accountInfo
              : {},
            [name]: value,
          }
        }
      } else if (name === "supplierId") {
        editableData = { loanInfo: { ...this.state.editableData.loanInfo } };
        editableData.loanInfo = {
          ...editableData.loanInfo,
          [name]: value
        }
      } else if (name === "bankName" || name === "accountNumber") {
        editableData = { loanInfo: { ...this.state.editableData.loanInfo } };
        editableData.loanInfo["accountInfo"] = {
          ...editableData.loanInfo["accountInfo"],
          [name]: value,
          accountType: name === "bankName"
            ? []
            : editableData.loanInfo["accountInfo"]["accountType"]
        }
      } else if (name === "accountType") {
        editableData = { loanInfo: { ...this.state.editableData.loanInfo } };
        editableData.loanInfo["accountInfo"] = {
          ...editableData.loanInfo["accountInfo"],
          [name]: value,
        }
      }
    } else {
      editableData = {
        [this.state.sectionToEdit]: this.state.editableData !== undefined
          ? this.state.editableData[this.state.sectionToEdit]
          : this.props[this.state.sectionToEdit]
      };
      if (name === "holder") {
        editableData[this.state.sectionToEdit] = {
          ...editableData[this.state.sectionToEdit],
          [name]: value,
          adminDateOfBirth: null,
          adminName: null,
          adminId: "",
          adminNumber: "",
          adminSince: "",
          adminAddress: null,
          adminSalary: ""
        }
      } else if (name === "socialMediaAccountDetails") {
        editableData[this.state.sectionToEdit] = {
          ...editableData[this.state.sectionToEdit],
          [name]: value,
          socialMediaAccount: value.length !== 0 ? true : false
        }
      } else if (name === "remittanceDetails") {
        editableData[this.state.sectionToEdit] = {
          ...editableData[this.state.sectionToEdit],
          [name]: value,
          receivesRemittances: value ? true : false
        }
      } else if (name === "bankDetail") {
        editableData[this.state.sectionToEdit] = {
          ...editableData[this.state.sectionToEdit],
          [name]: value,
          bankAccount: value.length !== 0 ? true : false
        }
      } else if (this.state.sectionToEdit === "income") {
        editableData[this.state.sectionToEdit] = {
          ...editableData[this.state.sectionToEdit],
          [name]: value,
          monthlyIncome: null
        }
      }
      else {
        editableData[this.state.sectionToEdit] = {
          ...editableData[this.state.sectionToEdit],
          [name]: value
        }
      }
    }
    this.setState({
      editableData,
      errorMessage: ""
    }
    )
  }

  updateLoanApplicationForm = () => {
    let newSection, oldSection;
    if (this.state.sectionToEdit === "supplierInfo") {
      //Supplier Info is stored in loanInfo of editableData.
      newSection = this.state.editableData.loanInfo;
      oldSection = this.props.loanInfo;
    } else {
      //References are stored in additionalInfo of editableData.
      newSection = this.state.editableData[this.state.sectionToEdit]
        ? this.state.editableData[this.state.sectionToEdit]
        : this.state.editableData.additionalInfo[this.state.sectionToEdit];
      oldSection = this.props[this.state.sectionToEdit]
        ? this.props[this.state.sectionToEdit]
        : this.props.additionalInfo[this.state.sectionToEdit];
    }
    //Validations to update loan application form
    let errorMessage = "";
    let dropDownList = {};
    this.state.dropdowns.forEach((dropdownValue) => {
      dropDownList[dropdownValue.nm] = dropdownValue.opt;
    })
    if (_.isEqual(newSection, oldSection)) {
      errorMessage = Strings("sameInput");
    }
    //Mandetory Input Fields cannot be empty and character length validations. 
    loanUpdateMandetoryCheck[this.state.sectionToEdit].forEach((field) => {
      let index;
      if (field !== "bankDetail" && field !== "socialMediaAccountDetails") {
        index = Object.keys(dropDownList).includes(field) && dropDownList[field].findIndex(element => {
          return element.toLowerCase() === newSection[field].toLowerCase();
        });
      } else if (field === "bankDetail") {
        if ((newSection["bankAccount"] && newSection["bankDetail"].length === 0)) {
          errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings(field)}`
        }
      } else if (field === "socialMediaAccount") {
        if ((newSection["socialMediaAccount"] && newSection["socialMediaAccountDetails"].length === 0)) {
          errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings(field)}`
        }
      }
      if (index === -1) {
        errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings(field)}`;
      } else if (this.state.sectionToEdit === "supplierInfo") {
        if (newSection["selectedDisbursementType"] === "TRANSFER"
          && newSection["selectedTransferAccountType"] === "PERSONAL_ACCOUNT") {
          if (!newSection["selectedTransferAccountType"]
            || !newSection["supplierId"]
            || !newSection.accountInfo["bankName"]
            || !newSection.accountInfo["accountType"]
            || !newSection.accountInfo["accountNumber"]
          ) {
            errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings(field)}`;
          } else if (field === "accountNumber"
            && newSection.accountInfo["accountNumber"].length < 6) {
            errorMessage = Strings("accountNumberNotValid")
          }
        } else {
          if (newSection["selectedDisbursementType"] === "DELIVERY") {
            if (!newSection["selectedDisbursementType"]
              || !newSection["supplierId"]) {
              errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings(field)}`;
            }
          } else {
            if (!newSection["selectedDisbursementType"]
              || !newSection["supplierId"]
              || !newSection["selectedTransferAccountType"]
            ) {
              errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings(field)}`;
            }
          }
        }
      } else if (!newSection[field]) {
        errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings(field)}`;
      } else if (field === "holder") {
        if (newSection["holder"] !== "OWNER") {
          if (!newSection["adminDateOfBirth"]) {
            errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings("adminDateOfBirth")}`;
          } else if (!newSection["adminName"]) {
            errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings("adminName")}`;
          } else if (!newSection["adminId"]) {
            errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings("adminId")}`;
          } else if (!newSection["adminNumber"]) {
            errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings("adminNumber")}`;
          } else if (!newSection["adminSince"]) {
            errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings("adminSince")}`;
          } else if (!newSection["adminAddress"]) {
            errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings("adminAddress")}`;
          } else if (!newSection["adminSalary"]) {
            errorMessage = `${Strings("pleaseSelectRequiredFields")} - ${Strings("adminSalary")}`;
          }
        }
        if (newSection["adminDateOfBirth"] && moment().diff(moment(newSection["adminDateOfBirth"]), 'year') < 21) {
          errorMessage = `${Strings("ageMustBeInBetweenTwentyOneToSixtyFive")} - ${Strings("adminDateOfBirth")}`
        } else if (newSection["adminNumber"] && newSection["adminNumber"].length !== 10) {
          errorMessage = `${Strings("MobileNumberMustBeTenDigits")} - ${Strings("adminNumber")}`
        }
        else if (newSection["adminId"] && newSection["adminId"].length !== 11) {
          errorMessage = `${Strings("CedulaIdMustBeElevenDigits")} - ${Strings("adminId")}`
        }
        else {
          if (newSection["ownerDateOfBirth"] && moment().diff(moment(newSection["ownerDateOfBirth"]), 'year') < 21) {
            errorMessage = `${Strings("ageMustBeInBetweenTwentyOneToSixtyFive")} - ${Strings("ownerDateOfBirth")}`;
          }
        }
      } else if (this.state.sectionToEdit !== "expenses"
        && ((field === "telephone" && newSection["telephone"].length !== 10)
          || (field === "ownerNumber" && newSection["ownerNumber"].length !== 10)
          || (field === "phone" && newSection["phone"].length !== 10))
      ) {
        errorMessage = `${Strings("MobileNumberMustBeTenDigits")} - ${Strings(field)}`
      }
    })

    if (errorMessage) {
      this.setState({ errorMessage })
    } else {
      if (this.state.sectionToEdit === "basicInfo") {
        let editableData = this.state.editableData;
        loanApplicationEdit.basicInfo.forEach((obj) => {
          editableData["basicInfo"][obj.name] = editableData.basicInfo[obj.name] === ""
            ? null
            : editableData.basicInfo[obj.name]
        })
        this.props.updateLoanApplicationForm(editableData, this.showEditLoanFormModal)
      } else if (this.state.sectionToEdit === "income") {
        let editableData = this.state.editableData;
        editableData["expenses"] = this.props.expenses;
        this.props.updateLoanApplicationForm(editableData, this.showEditLoanFormModal)
      } else {
        this.props.updateLoanApplicationForm(this.state.editableData, this.showEditLoanFormModal)
      }
    }
  }

  // Editable modal to update loan application form fields
  renderEditableModal = () => {
    let editableData = {
      [this.state.sectionToEdit]: loanApplicationEdit[this.state.sectionToEdit].map((obj1) => {
        let dropDownList = [];
        let isSelection = false;
        let isVisible = this.state.sectionToEdit !== "supplierInfo";
        let isMandatoryField = obj1.isMandatoryField;

        //If application holder is admin then only admin details are mandetory
        if (obj1.name === "adminDateOfBirth" || obj1.name === "adminName"
          || obj1.name === "adminId" || obj1.name === "adminNumber"
          || obj1.name === "adminSince" || obj1.name === "adminAddress"
          || obj1.name === "adminSalary") {
          if (this.state.editableData[this.state.sectionToEdit]["holder"] === "OWNER") {
            isVisible = true;
          } else {
            isMandatoryField = true;
          }
        }
        //Drowdown list from backend
        if (obj1.type === "select") {
          if (this.state.sectionToEdit !== "supplierInfo") {
            this.state.dropdowns.forEach((dropdownValue) => {
              if (dropdownValue.nm === obj1.name) {
                dropDownList = [];
                dropDownList = dropdownValue.opt.map((dropdown) => {
                  return obj1.name === "homeLocation" ? dropdown : dropdown.toUpperCase();
                });
                isSelection = true;
              }
            })
          } else {
            this.props.supplierDropdownValues.forEach((supplierDropdownValue) => {
              if (obj1.name === "selectedDisbursementType") {
                dropDownList.push(supplierDropdownValue.disbursementType);
                isSelection = true;

              } else if (obj1.name === "selectedTransferAccountType") {
                dropDownList = supplierDropdownValue.accountTypes;
                isSelection = true;

              } else {
                dropDownList = []
              }
            })
            this.props.suppliersList.forEach((supplier) => {
              if (obj1.name === "supplierId") {
                dropDownList.push({ name: supplier["businessName"], value: supplier["supplierId"] });
                isSelection = true;
              }
            })
            if (this.state.editableData.loanInfo["selectedDisbursementType"] === "TRANSFER"
              && this.state.editableData.loanInfo["selectedTransferAccountType"] === "PERSONAL_ACCOUNT") {
              this.props.bankDetails.forEach((bank) => {
                if (obj1.name === "bankName") {
                  dropDownList.push(bank["bn"]);
                  isSelection = true;
                  isVisible = true;
                  isMandatoryField = true;
                } else if (this.state.editableData?.loanInfo?.accountInfo?.bankName
                  && obj1.name === "accountType") {
                  if (bank.bn === this.state.editableData.loanInfo.accountInfo.bankName) {
                    bank.ad.forEach((acc) => {
                      dropDownList.push(acc.at);
                      isSelection = true;
                      isVisible = true;
                      isMandatoryField = true;

                    })
                  }
                }

              })
            }
          }
        } else if (obj1.isSelect === "multiSelect") {
          this.state.dropdowns.forEach((dropdownValue) => {
            if (dropdownValue.nm === obj1.name) {
              dropDownList = dropdownValue.opt;
              isMandatoryField = false;
            }
          })
        } else {
          dropDownList = []
        }
        if (dropDownList.length !== 0
          && dropDownList[0] !== Strings("select")
          && obj1.name !== "socialMediaAccountDetails"
          && obj1.name !== "bankDetail") {
          dropDownList.unshift(Strings("select"))
        }
        let value;
        if (this.state.sectionToEdit === "comercialRef1" ||
          this.state.sectionToEdit === "comercialRef2" ||
          this.state.sectionToEdit === "personalRef1" ||
          this.state.sectionToEdit === "personalRef2" ||
          this.state.sectionToEdit === "neighbourhoodRef1" ||
          this.state.sectionToEdit === "neighbourhoodRef2") {
          value = !_.isEmpty(this.state.editableData)
            ? this.state.editableData.additionalInfo[this.state.sectionToEdit][obj1.name]
            : this.props.additionalInfo[this.state.sectionToEdit][obj1.name]
        }
        else if (this.state.sectionToEdit === "supplierInfo") {
          if (obj1.name === "selectedDisbursementType") {
            value = !_.isEmpty(this.state.editableData)
              ? this.state.editableData.loanInfo[obj1.name]
              : this.props.loanInfo.supplierDetails[obj1.name]
            isVisible = true;
            isMandatoryField = true;

          } if (obj1.name === "supplierId") {
            value = !_.isEmpty(this.state.editableData)
              ? this.state.editableData.loanInfo["supplierId"]
              : this.props.loanInfo["supplierId"]
            isVisible = true;
            isMandatoryField = true;
          }
          if (this.state.editableData.loanInfo["selectedDisbursementType"] === "TRANSFER") {
            if (this.state.editableData.loanInfo["selectedTransferAccountType"] === "PERSONAL_ACCOUNT"
              && (obj1.name === "bankName" || obj1.name === "accountType" || obj1.name === "accountNumber")) {
              value = !_.isEmpty(this.state.editableData)
                && this.state.editableData.loanInfo.accountInfo
                ? this.state.editableData.loanInfo.accountInfo[obj1.name]
                : this.props.loanInfo.accountInfo[obj1.name]
              isVisible = true;
              isMandatoryField = true;
            }
            if (obj1.name === "selectedTransferAccountType") {
              value = !_.isEmpty(this.state.editableData)
                ? this.state.editableData.loanInfo[obj1.name]
                : this.props.loanInfo[obj1.name]
              isVisible = true;
              isMandatoryField = true;
            }
          }
        }
        else {
          value = !_.isEmpty(this.state.editableData)
            ? this.state.editableData[this.state.sectionToEdit][obj1.name]
            : this.props[this.state.sectionToEdit][obj1.name]
        }
        if (obj1.isDate) {
          value = value ? moment(value).format(INVOICE_DATE_FORMAT) : "";
          if (obj1.name === "ownerDateOfBirth") {
            obj1["handleDateChange"] = this.handleOwnerDOBChange
          } else if (obj1.name === "adminDateOfBirth") {
            obj1["handleDateChange"] = this.handleAdminDOBChange
          }
        }

        let finalValue = value;
        if (!_.isEmpty(this.state.editableData)) {
          if (obj1.isSelect === "multiSelect") {
            finalValue.forEach((ele) => {
              if (!dropDownList.includes(ele)) {
                finalValue.splice(ele, 1)
              }
            })
          }
        } else {
          finalValue = this.props[this.state.sectionToEdit][obj1.name]
        }
        return {
          ...obj1, value: finalValue,
          dropDownList: dropDownList,
          isSelection: isSelection,
          isVisible: isVisible,
          isMandatoryField: isMandatoryField,
          md: 4,
          className: this.state.invalidValues.includes(obj1.name) ? 'errors m-0 p-0' : ''
        }
      }
      )
    }
    //If isVisible is false, then the fields will not be visible on UI
    let filteredEditableData = editableData[this.state.sectionToEdit].filter(
      (obj) => obj.isVisible === true
    );
    return (
      <EditableModal
        open={this.state.showModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("EditLoanDetails")}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.showEditLoanFormModal,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.updateLoanApplicationForm,
        }}
        SubmitMinimartDetails={this.updateLoanApplicationForm}
        onChange={this.handleInputChange}
        errorMessage={this.state.errorMessage}
        editableData={filteredEditableData}
        className={"loanApplicationModal"}
        invalidValues={this.state.invalidValues}
      />
    );
  };

  renderInformation = (details) => {
    let invalidFormFieldValues = {};
    let invalidFormFieldKeys = [];
    let invalidValues = [];

    if (this.props?.invalidFormField?.length > 0) {
      invalidFormFieldKeys = this.props.invalidFormField.map((field) => {
        return field.ft;
      })
      this.props.invalidFormField.forEach((field) => {
        if (invalidFormFieldKeys.includes(field.ft)) {
          invalidFormFieldValues = { ...invalidFormFieldValues, [field.ft]: field.ff };
        }
      })
      invalidFormFieldKeys.forEach((invalidFormFieldKeys) => {
        invalidValues = [...invalidValues, ...invalidFormFieldValues[invalidFormFieldKeys]]
      })
    }
    return (
      <div key={details.header}>
        {this.renderHeader(details.header)}
        {this.props.isAnalyst && details.header !== Strings("AdministrationInformation")
          && details.header !== Strings("SiteVisit")
          && <span className='pencil mx-1 px-1'>
            <img //NOSONAR
              src={editNote}
              className={"editIcon m-0"}
              alt={"edit"}
              title={Strings("EditLoanDetails")}
              onClick={() => this.showEditLoanFormModal(details.propObjectKey, invalidValues)}
            />
          </span>}
        <div className='errors p-0 m-0'>
          {invalidFormFieldValues["BASIC_INFO"]
            && details.propObjectKey === "basicInfo"
            && details.header !== Strings("AdministrationInformation")
            && <div>{Strings("errorInvalidValue")}</div>}
          {invalidFormFieldValues["INCOME"]
            && details.propObjectKey === "income"
            && <div>{Strings("errorInvalidValue")}</div>}
          {invalidFormFieldValues["EXPENSES"]
            && details.propObjectKey === "expenses"
            && <div>{Strings("errorInvalidValue")}</div>
          }
          {invalidFormFieldValues["SITE_VISIT"]
            && details.propObjectKey === "siteVisit"
            && <div>{Strings("errorInvalidValue")}</div>}
        </div>
        <Row>
          {" "}
          {details.keysArray.map((detail) =>
            this.renderApplicationDetails(
              this.props[details.propObjectKey],
              detail,
              invalidValues
            )
          )}
        </Row>
        <hr className='fullWidth' />
      </div >
    );
  };

  renderEvidenceDocuments = () => {
    return (
      <>
        <Row className='fullWidth '>
          <Col md={10} className='p-0 subHeaderText'>
            {Strings("Evidence")}
          </Col>
          {this.props.isAnalyst && (
            <Col md={2} className='px-0'>
              <div className='pull-right'>
                <img
                  src={addNotes}
                  alt={"addNotes"}
                  className={"addNotesIcon cursorPointer"}
                  onClick={() =>
                    this.toggleModal(null, NOTES_TYPE.EVALUATION_NOTE)
                  }
                />
                <span className='noteText'>{Strings("Notes")}</span>
              </div>
            </Col>
          )}
        </Row>
        {this.props.documents && this.props.documents.length > 0 && (
          <Row>
            {EVIDENCE.map((evidenceDoc) => {
              let documentId = null;
              const doc = this.props.documents.find(
                (document) => document.docType === evidenceDoc.type
              );
              if (doc) {
                documentId = doc.documentId;
              }
              return (
                <Col
                  md={3}
                  className='infoViewContainer paddingSeparation pl-0'
                  key={evidenceDoc.title}
                >
                  <div className='infoHeader'>
                    {evidenceDoc.title}
                  </div>
                  <div className='infoContent'>
                    {documentId ? (
                      <div>
                        <span
                          className='infoContent cursorPointer url'
                          onClick={() =>
                            this.props.viewDocument(documentId)
                          }
                        >
                          {evidenceDoc.title + " " + documentId}
                        </span>
                        {this.props.initiatedFrom !== "TPRESTA" && this.props.isAnalyst && (
                          <i
                            className='pl-2 fa fa-trash cursorPointer'
                            aria-hidden='true'
                            title='Eliminar Subtipo'
                            onClick={() => {
                              this.props.handleDeleteEvidenceClick(
                                documentId
                              );
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      Strings("NA")
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
      </>
    );
  };

  renderNotes = (noteData) => {
    return (
      <div className='noteSection' key={noteData.id}>
        <span className='subHeading'>{Strings("Notes")}</span>
        <span className='paddingLeft subText'>
          {moment(noteData.ca).format(CONFIGURATION_DATE_TIME_FORMAT)}
        </span>
        <div className='spaceForIcons'>
          {this.props.isAnalyst && !this.props.isHistory && (
            <img
              src={deleteNote}
              className='NotesIcon cursorPointer  pull-right'
              alt={"delete"}
              onClick={() => {
                this.props.deleteNote(
                  noteData.id,
                  NOTES_TYPE.EVALUATION_NOTE
                );
              }}
            />
          )}
          {this.props.isAnalyst && !this.props.isHistory && (
            <img
              src={editNote}
              className={
                this.props.isAnalyst
                  ? "NotesIcon cursorPointer  pull-right"
                  : "NotesIcon pull-right"
              }
              alt={"edit"}
              onClick={() => {
                this.setState({ isEditNote: true }, () => {
                  this.toggleModal(
                    noteData.note,
                    NOTES_TYPE.EVALUATION_NOTE,
                    noteData.id
                  );
                });
              }}
            />
          )}
        </div>
        <div className='subText scrollAuto'>{noteData.note}</div>
      </div>
    );
  };

  renderCard = (key, cardKey) => {
    return (
      <div
        className={
          cardKey === ADDRESS &&
            this.props.additionalInfo[key] &&
            this.props.additionalInfo[key][cardKey]
            ? "infoContent scrollContent"
            : "infoContent"
        }
        key={cardKey}
        title={Strings(cardKey)}
      >
        {this.props.isAnalyst && cardKey === "typeOfReference"
          ? <>
            <span>{Strings(key)}</span><span className='pencil mx-1 px-1'>
              <img
                src={editNote}
                className={"editIcon m-0"}
                alt={"edit"}
                title={Strings("EditLoanDetails")}
                onClick={() => this.showEditLoanFormModal(key)}
              />
            </span>
          </>
          : this.props.additionalInfo[key] //NOSONAR
            ? this.props.additionalInfo[key][cardKey] //NOSONAR
              ? this.props.additionalInfo[key][cardKey]
              : Strings("NA")
            : null}
      </div>
    );
  };

  renderReferences = () => {
    return (
      <>
        {this.renderHeader("References")}

        <Row className='fullWidth'>
          {this.props.additionalInfo
            ? Object.keys(this.props.additionalInfo).map((key) => {
              return (
                <div
                  md={3}
                  className='paddingLeftZero paddingSeparation pt-3'
                  key={key}
                >
                  <Row className='flex-column borderCard'>
                    {REFERENCES.map((cardKey) => {
                      return this.renderCard(key, cardKey);
                    })}
                  </Row>
                </div>
              );
            })
            : null}
        </Row>
      </>
    );
  };

  toggleModal = (note, noteType, noteId = null) => {
    if (this.state.isEditNote) {
      if (noteId) {
        this.setState({
          note: note,
          noteId: noteId,
          notesModal: true,
          isEditNote: true,
        });
      } else {
        const tempNoteId = this.state.noteId;
        this.setState(
          {
            notesModal: false,
            isEditNote: false,
            note: "",
            noteId: null,
          },
          () => {
            this.props.editNote(note, tempNoteId, noteType);
          }
        );
      }
    } else {
      if (note) {
        this.setState(
          {
            notesModal: false,
          },
          () => {
            this.props.addNote({
              note: note,
              type: noteType,
            });
          }
        );
      } else {
        this.setState({
          notesModal: true,
        });
      }
    }
  };

  onCloseClick = () => {
    this.setState({
      notesModal: false,
      isEditNote: false,
      note: "",
      noteId: null,
    });
  };

  renderLoanInformation = () => {
    return (
      <>
        {this.renderHeader(Strings("LoanApplicationDetails"))}
        <Row>
          <Col md={1} className='pt-2'>
            <div>
              {" "}
              <img src={loanImage} alt='' />
              <div
                className='text-center infoHeader'
                title={Strings("applicationId")}
              >
                {" "}
                {this.props.applicationId}
              </div>
            </div>
          </Col>
          <Col md={11}>
            <Row className='loanInfoWrapper'>
              {this.props.loanInfo &&
                VIEW_LOAN_APP_TYPES[this.props.loanInfo.type].map(
                  (_loanKey, index) =>
                    //replaced the first parameter "loanKey" to "_loanKey" to remove sonarLint warning
                    this.renderApplicationDetails(
                      this.props.loanInfo,
                      VIEW_LOAN_APP_TYPES[this.props.loanInfo.type][
                      index
                      ]
                    )
                )}
            </Row>
          </Col>
        </Row>
        <hr className='fullWidth' />
      </>
    );
  };

  renderEMI = () => {
    return (
      <>
        {this.renderHeader(Strings("EMICalculation"))}
        <Row>
          {" "}
          {EMI_CALCULATION.map((detail) =>
            this.renderApplicationDetails(this.props.loanInfo, detail)
          )}
        </Row>
        <hr className='fullWidth' />
      </>
    );
  };

  renderSupplierInfo = () => {
    return <>
      {this.renderHeader(Strings("supplierInfo"))}
      {this.props.isAnalyst && <span className='pencil mx-1 px-1'>
        <img
          src={editNote}
          className={"editIcon m-0"}
          alt={"edit"}
          title={Strings("EditLoanDetails")}
          onClick={() => this.showEditLoanFormModal("supplierInfo")}
        />
      </span>}
      <Row>
        <Col
          md={3}
          className='infoViewContainer paddingSeparation pl-0'
        ><div className='infoHeader'>{Strings("sdt")}</div>
          <div className="infoContent" title={this.props.supplierInfo.sdt ? Strings(this.props.supplierInfo.sdt) : null}>{this.props.supplierInfo.sdt ? Strings(this.props.supplierInfo.sdt) : null}</div>
        </Col>
        {this.props.supplierInfo.stat && <Col
          md={3}
          className='infoViewContainer paddingSeparation pl-0'
        ><div className='infoHeader'>{Strings("stat")}</div>
          <div className="infoContent" title={this.props.supplierInfo.stat ? Strings(this.props.supplierInfo.stat) : null}>{this.props.supplierInfo.stat ? Strings(this.props.supplierInfo.stat) : null}</div>
        </Col>}
      </Row>
      <Row>
        {sd.map((key) => {
          return <Col
            md={3}
            className='infoViewContainer paddingSeparation pl-0'
            key={key}
          >
            <div className='infoHeader'>{Strings(key)}</div>
            <div className="infoContent text-truncate" title={this.props.supplierInfo?.sd[key] ? this.props.supplierInfo?.sd[key] : null}>{this.props.supplierInfo?.sd[key] ? this.props.supplierInfo?.sd[key] : null}
              {key === "rnc" && !this.props.supplierInfo.sd.isVerified && <span className="RNCInActive">{" (" + Strings("UnVerified") + ")"}</span>}
            </div>
          </Col>
        })}
      </Row>
    </>
  }

  renderBankInfo = () => {
    return (
      <>
        {this.props.loanInfo?.accountInfo && this.renderHeader(Strings("bankDetails"))}
        {this.props.loanInfo?.accountInfo && <Row>
          {ainfo.map((key) => {
            return <Col
              md={3}
              className='infoViewContainer paddingSeparation pl-0'
              key={key}
            >
              <div className='infoHeader'>{Strings(key)}</div>
              <div className="infoContent" title={this.props.loanInfo?.accountInfo[key] ? this.props.loanInfo?.accountInfo[key] : null}>{this.props.loanInfo?.accountInfo[key] ? this.props.loanInfo?.accountInfo[key] : null}</div>
            </Col>
          })}

        </Row>}
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.showModal && this.state.sectionToEdit !== undefined && this.renderEditableModal()}

        {this.state.notesModal && (
          <NotesModal
            open={this.state.notesModal}
            modalHeader={Strings("Notes")}
            toggleModal={this.onCloseClick}
            noteType={NOTES_TYPE.EVALUATION_NOTE}
            note={this.state.note}
            isEditNote={this.state.isEditNote}
            editNote={this.toggleModal}
            addNote={this.toggleModal}
            isNotesCompulsory={true}
            maxLength={500}
            errorMessage=''
          />
        )}
        <div className='viewLoanApplication'>
          <div
            className={
              this.props.isLoanPortfolio
                ? "paper viewLoanApplicationContainer"
                : ""
            }
          >
            {this.props.isLoanPortfolio &&
              this.renderLoanInformation()}
            {this.props.supplierInfo && this.renderSupplierInfo()}
            {this.props.loanInfo?.accountInfo && this.renderBankInfo()}
            {BASIC_ADMIN_INFO_DETAILS_ARRAY.map((details) =>
              this.renderInformation(details)
            )}
            {this.renderReferences()}
            <hr className='fullWidth' />
            {this.props.isLoanPortfolio && this.renderEMI()}
            {OTHER_INFO_DETAILS_ARRAY.map((details) =>
              this.renderInformation(details)
            )}
            {this.renderEvidenceDocuments()}
            {this.props.evaluationNotes?.length > 0 && (
              <Row className='fullWidth subHeaderText'>
                {Strings("EvidencesNotes")}
              </Row>
            )}
            {this.props.evaluationNotes?.length > 0 &&
              this.props.evaluationNotes.map((Note) => {
                return this.renderNotes(Note);
              })}
          </div>
        </div>
      </>
    );
  }
}

export default ViewLoanApplication;

//Components
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import SignupRequest from "./SignupRequest";

//Actions

import { setSearchFilterAction } from "../../constants/Action";
import {
  getSignUpRequestListAction,
  setMiniMartDetailsAction,
} from "./Actions";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  SEARCH_FILTER_PAGES,
  SIGNUP_REQUEST_FILTER,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";

//Utility

class SignupRequestContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: {},
      minimartList: [],
      numberOfPages: 0,
      errorMessage: "",
    };
    this.isCardClick = false;
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SIGNUP_REQUEST,
        { ...SIGNUP_REQUEST_FILTER }
      );
    }
  }

  setMiniMartDetails = (minimart) => {
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.SIGNUP_REQUEST,
      { ...this.state.searchFilter },
      SEARCH_FILTER_PAGES.SIGNUP_REQUEST
    );
    this.props.setMiniMartDetailsAction(minimart);
    this.props.history.push(ROUTES.MTP_SIGNUP_REQUEST_DETAILS);
  };

  handleOnClickSearchFilter = (searchFilter) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        st: searchFilter.st,
        fd: searchFilter.fd,
        td: searchFilter.td,
        limit: searchFilter.lmt,
        offset: searchFilter.oft,
      },
    });
    getSignUpRequestListAction(searchFilter, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          minimartList: response.data.data.rp,
          numberOfPages: response.data.data.np,
          showLoader: false,
        });
      } else {
        this.setState({
          minimartList: [],
          numberOfPages: 0,
          showLoader: false,
          errorMessage: response.error.message,
        });
      }
    });
  };
  render() {
    return (
      <>
        {this.state.showLoader && (
          <LoadingModal showLoadingModal={this.state.showLoader} />
        )}
        <SignupRequest
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          setMiniMartDetails={this.setMiniMartDetails}
          searchFilter={this.props.searchFilter}
          minimartList={this.state.minimartList}
          numberOfPages={this.state.numberOfPages}
          errorMessage={this.state.errorMessage}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  searchFilter: state.searchFilterReducer.signupRequestSearchFilter,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setMiniMartDetailsAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupRequestContainer);

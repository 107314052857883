import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button } from "react-bootstrap";

//Components
import "./ProductCatalog.scss";
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
//Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
import { STATUS_SELECTION } from "./Constants";
import Utility from "../../utils/Utility";
import MultipleSelectCheckmarks from "../CustomUIComponents/MultiSelectDropDown/MultipleSelectCheckmarks";

class ProductModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessage: props.errorMessage,
      productdata: props.productdata,
      categories: props.categories,
      readOnly: props.readOnly,
      branchIds: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let derivedState = { ...prevState };
    if (nextProps.open !== prevState.open) {
      derivedState = {
        ...derivedState,
        open: nextProps.open,
        errorMessage: nextProps.errorMessage,
        productData: nextProps.productData,
        readOnly: nextProps.readOnly,
      };
    }
    if (
      nextProps.categories.length > 0 &&
      nextProps.categories[0].id !== ""
    ) {
      let tempCategories = nextProps.categories;
      derivedState = {
        ...derivedState,
        categories: tempCategories,
      };
    }

    return derivedState;
  }

  //to close modal
  closeModal = () => {
    this.props.modalButtonCancel.onClick();
  };

  //render
  renderTextFields = () => {
    return (
      <Row className='p-3'>
        <TextFieldComponent
          placeholder={Strings("ProductName") + "*"}
          className='topMargin'
          handleChange={(event) =>
            this.props.handleChange("product", "productName", event)
          }
          value={
            this.props.product ? this.props.product.productName : ""
          }
          disabled={this.props.readOnly}
          // maxLength={30}
        />
        <textarea
          placeholder={Strings("Description")}
          className='topMargin form-control'
          onChange={(event) =>
            this.props.handleChange("product", "description", event)
          }
          value={
            this.props.product ? this.props.product.description : " "
          }
          cols={100}
          rows={3}
          disabled={this.props.readOnly}
        />
        <TextFieldComponent
          placeholder={Strings("category")}
          className='topMargin'
          handleChange={(event) =>
            this.props.handleChange("product", "category", event)
          }
          type='select'
          value={
            this.props.product ? this.props.product.category : ""
          }
          disabled={this.props.readOnly}
        >
          {this.props.categories.length > 0 &&
            this.props.categories.map((category) => {
              return (
                <option value={category.value} key={category.value}>
                  {category.label}
                </option>
              );
            })}
        </TextFieldComponent>
        <div className='pt-2 w-100'>
          <MultipleSelectCheckmarks
            selectedValues={this.props.product?.branchIds}
            itemList={this.props.branches}
            handleChange={this.props.handleChange}
            multiple={true}
            width={350}
            disabled={this.props.readOnly}
            placeHolder={"selectBranch"}
            tag={"branch"}
            PRODUCT={true}
          />
        </div>
        <TextFieldComponent
          placeholder={Strings("model") + " *"}
          className='topMargin'
          handleChange={(event) =>
            this.props.handleChange("product", "model", event)
          }
          value={this.props.product ? this.props.product.model : ""}
          disabled={this.props.readOnly}
        />

        <Col md={10} className='pl-0'>
          <TextFieldComponent
            placeholder={Strings("cost") + " *"}
            className='topMargin'
            handleChange={(event) =>
              this.props.handleChange("product", "cost", event)
            }
            value={this.props.product ? this.props.product.cost : ""}
            // maxLength={30}
            type='number'
            onKeyDown={Utility.allowOnlyNumber}
            disabled={this.props.readOnly}
          />
        </Col>
        <Col md={2} className='alignRD'>
          RD ($)
        </Col>
        <TextFieldComponent
          placeholder={Strings("status")}
          className='topMargin mb-2'
          handleChange={(event) =>
            this.props.handleChange("product", "status", event)
          }
          type='select'
          childern={this.props.categories}
          value={this.props.product ? this.props.product.status : ""}
          disabled={this.props.readOnly}
        >
          {STATUS_SELECTION.map((status) => {
            return (
              <option value={status.value} key={status.value}>
                {status.label}
              </option>
            );
          })}
        </TextFieldComponent>
      </Row>
    );
  };

  render() {
    return (
      <div className='productCatalog'>
        <Modal
          // Change visibility of modal based on props.
          open={this.state.open}
          onClose={this.closeModal}
          showCloseIcon={this.props.modalShowCloseIcon}
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          classNames={{
            modal: "productModal",
          }}
          center
        >
          <Row className='productModal'>
            <Col md={12} className='loanDetailModalHeader pl-3'>
              {this.props.headerText}
            </Col>

            <Col md={12} className='modalContent pt-3'>
              {this.props.errorMessage && (
                <Row className='errors noPadding mb-0'>
                  {this.props.errorMessage}
                </Row>
              )}
              {this.renderTextFields()}
              <hr className='newHr' />
            </Col>

            <Col md={12} className='buttonAlign'>
              <Button
                className='primaryModalButton marginCancelButton buttonBorder buttonMinWidth'
                onClick={this.props.modalButtonCancel.onClick}
              >
                {this.props.modalButtonCancel.text}
              </Button>

              {!this.props.readOnly && (
                <Button
                  className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
                  onClick={this.props.modalButtonApprove.onClick}
                >
                  {this.props.modalButtonApprove.text}
                </Button>
              )}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default ProductModal;

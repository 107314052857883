import { SET_REWARD } from "../../redux/constants";

const INITIAL_STATE = {
  selectedReward: {},
};

export default function RewardApprovalReducer(state = INITIAL_STATE, action) {
  //NOSONAR -default parameter is required first in this case
  switch (
    action.type //NOSONAR
  ) {
    case SET_REWARD:
      return {
        ...state,
        selectedReward: action.reward,
      };
    default:
      return state;
  }
}

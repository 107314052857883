import React from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Form } from "react-bootstrap";
import "react-rater/lib/react-rater.css";

//UIComponents
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Rater from "react-rater";
import CheckboxComponent from "../CustomUIComponents/CheckboxComponent/CheckboxComponent";
import MultipleSelectCheckmarks from "../CustomUIComponents/MultiSelectDropDown/MultipleSelectCheckmarks";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Images
import ProfileImage from "../../resources/images/ic_user.svg";
import cameraImage from "../../resources/images/ic_update_profile_pic.svg";

//Components
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import MultiCheckBoxAreaComponent from "../CustomUIComponents/MultiCheckBoxAreaComponent/MultiCheckBoxAreaComponent";
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import WorkloadDistributionModal from "./WorkloadDistributionModal";

//Utility
import Utility from "../../utils/Utility";

// Constants
import {
  PROFILE_PHOTO_FILE_SIZE,
  GET_DEFAULT_DROPDOWN_SELECTION,
  DEFAULT_USER_DETAILS,
  DEFAULT_USER_SELECTION,
  SELECT_DEFAULT_REPORTING_USER,
  DEFAULT_REPORTING_USER,
  DEFAULT_COLLECTION_OFFICER_DROPDOWN,
  SELECT_COLLECTION_OFFICER_USER,
  DEFAULT_POSITION_DROPDOWN,
  SELECT_POSITION_USER,
  POSITION_TYPE,
  POSITION_TYPES,
} from "./Constants";
import {
  USER_ROLES_WITH_RATINGS,
  USER_ROLES_WITH_BRANCH,
  USER_ROLES_WITH_REPORTING,
  USER_ROLES_TO_SHOW_BRANCH,
  USER_ROLES_WITH_SINGLE_BRANCH,
  USER_ROLES,
  SPECIAL,
  USER_ROLES_NOT_ALLOWED_TO_CHANGE_BRANCHES,
  REPORTING_USER_ROLES,
  USER_ROLES_TO_SHOW_ZONES,
  MINIMART_RATING,
  FILE_CONFIGURATIONS,
  USER_ROLES_WITH_COLLECTION_OFFICER,
  USER_ROLES_WITH_POSITION,
  POSITIONS_USER_ROLES,
  DEFAULT_VALUES,
  SPECIAL_USER_ROLES
} from "../../constants/appConstants";
import { OPERATION_STATUS } from "../../config/axios.init";

// Styles
import "./ProfileDetails.scss";

class ProfileDetails extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this.state = {
      user: props.userProfile,
      role: props.userRole,
      editedData: props.userProfile,
      isAddUser: props.isAddUser,
      disableInput: false,
      profilePicUrl: props.profilePicUrl,
      fileToUpload: null,
      disableSave: true,
      validFile: true,
      userModal: false,
      errorMessage: "",
      userRolesList: props.userRolesList,
      branches: props.branches,
      workLoadModal: false,
      workloadSelectedUserIds: "",
      action: "",
      availability: props.userProfile.available,
      currentAvailability: props.userProfile.available,
      changeRole: false
    };
    this.errorRef = React.createRef();
  }

  componentDidMount() {
    //NOSONAR
    if (this.props.userProfile.username !== "") {
      this.setState({
        editedData: {
          ...this.state.editedData,
          branch: this.props.userProfile.branchIds,
        },
      });
    }

    if (this.state.user?.username) {
      this.props.getProfilePic(this.state.user?.username);
    }
    if (
      this.props.loggedInUserInfo.role ===
      USER_ROLES.operationsModerator
    ) {
      let allbranches = [];
      let i;
      for (i = 0; i < this.state.branches.length; ++i) {
        if (
          this.state.branches[i].id !== "" &&
          this.state.branches[i].id !== null
        ) {
          if (!DEFAULT_VALUES.includes(this.state.branches[i].id)) {
            allbranches.push(this.state.branches[i].id);
          }
        }
      }
      this.setState({
        editedData: {
          ...this.state.editedData,
          role: "OPERATIONS_OFFICER",
          branch: allbranches,
        },
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.userProfile !== state.user) {
      return {
        user: props.userProfile,
        editedData: props.userProfile,

        isAddUser: props.isAddUser,
      };
    }
    if (props.profilePicUrl !== state.profilePicUrl) {
      return { profilePicUrl: props.profilePicUrl };
    }
    if (props.userRole !== state.role) {
      return { role: props.userRole };
    }
    if (
      JSON.stringify(props.userProfile) ===
      JSON.stringify(DEFAULT_USER_DETAILS)
    ) {
      // While adding a new user
      return { disableInput: false };
    }
    return null;
  }

  /* #endregion */

  /* #region  OnChange Events */

  handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    let editedData = this.state.editedData;
    if (name === "firstName" || name === "lastName") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    } else if (name === "conciliationAccess") {
      value = event.target.checked
    } else if (name === "aub") {
      editedData = {
        ...editedData,
        zones: [],
        position: "",
      };
    } else if (name === "position") {
      editedData = {
        ...editedData,
        zones: [],
      };
    }
    this.setState(
      {
        editedData: {
          ...editedData,
          [name]: value,
        },
      },
      () => {
        this.checkForDisableSave();
        if (name === "reportingToUserId" && this.checkRequiredRole()) {
          this.getEmailIdOfReportingUser(
            this.state.editedData.reportingToUserId
          );
        }
        if (name === "position") {
          USER_ROLES_WITH_SINGLE_BRANCH.includes(
            this.state.editedData.role
          ) &&
            this.state.editedData.position !==
            DEFAULT_POSITION_DROPDOWN &&
            this.props.getZonesByBranch(
              this.state.editedData.branch[0]
            );
          this.getZonesForPosition(this.state.editedData.position);
        }

      });
  }

  handleZoneChange = (event) => {
    let zones = [...this.state.editedData.zones];
    if (zones.includes(event)) {
      let index = zones.indexOf(event);
      zones.splice(index, 1);
    } else {
      zones.push(event);
    }
    this.setState(
      {
        editedData: {
          ...this.state.editedData,
          zones,
        },
      },
      this.checkForDisableSave
    );
  };


  onChangeRole = (event) => {
    let role = event.target.value;
    if (USER_ROLES_WITH_BRANCH.includes(role)) {
      let branch = [];
      let reportingToUserId = "";
      this.setState(
        {
          editedData: {
            ...this.state.editedData,
            role,
            branch,
            reportingToUserId,
            email: "",
            type: null,
            aub: undefined,
            position: "",
            collectionOfficer: "",
            conciliationAccess: false,
          },
        },
        () => {
          this.handleEditedRole(role);
        }
      );
    } else {
      let allbranches = [];
      let i;
      for (i = 0; i < this.state.branches.length; ++i) {
        if (
          this.state.branches[i].id !== "" &&
          this.state.branches[i].id !== null
        ) {
          if (!DEFAULT_VALUES.includes(this.state.branches[i].id)) {
            allbranches.push(this.state.branches[i].id);
          }
        }
      }
      this.setState(
        {
          editedData: {
            ...this.state.editedData,
            role,
            branch: allbranches,
            position: "",
            collectionOfficer: "",
          },
        },
        () => {
          this.handleEditedRole(role);
        }
      );
    }
  };

  //Added check for if fields are empty then disable save
  checkForDisableSave = () => {
    let disableSave = true;
    if (
      this.state.editedData &&
      this.state.editedData.firstName.trim() !== "" &&
      this.state.editedData.lastName.trim() !== "" &&
      this.state.editedData.username.trim() !== "" &&
      this.state.editedData.email.trim() !== "" &&
      (this.state.editedData.role === USER_ROLES.messenger
        ? this.state.editedData.zones.length !== 0 &&
        this.state.editedData.position !== ""
        : true) &&
      (USER_ROLES_TO_SHOW_BRANCH.includes(this.state.editedData.role)
        ? this.state.editedData.branch.length > 0
        : true) &&
      (this.state.editedData.role !== USER_ROLES.salesPerson &&
        this.state.editedData.type !== SPECIAL &&
        USER_ROLES_WITH_REPORTING.includes(this.state.editedData.role)
        ? this.state.editedData.reportingToUserId !==
        DEFAULT_REPORTING_USER &&
        this.state.editedData.reportingToUserId !== ""
        : true) &&
      (USER_ROLES_WITH_COLLECTION_OFFICER.includes(
        this.state.editedData.role
      )
        ? this.state.editedData.collectionOfficer &&
        this.state.editedData.collectionOfficer !==
        DEFAULT_COLLECTION_OFFICER_DROPDOWN &&
        this.state.editedData.collectionOfficer !== ""
        : true) &&
      (USER_ROLES_WITH_COLLECTION_OFFICER.includes(
        this.state.editedData.role
      )
        ? this.state.editedData.collectionOfficer &&
        this.state.editedData.collectionOfficer !==
        DEFAULT_COLLECTION_OFFICER_DROPDOWN &&
        this.state.editedData.collectionOfficer !== ""
        : true) &&
      this.state.editedData.role !==
      Strings(DEFAULT_USER_SELECTION) &&
      this.state.editedData.role !== DEFAULT_USER_SELECTION &&
      this.state.editedData.role !== "" &&
      this.state.validFile
    ) {
      disableSave = false;
    }
    this.setState({
      disableSave: disableSave,
      errorMessage: "",
    });
  };

  handleEditedRole = (role) => {
    this.checkForDisableSave();
    if (
      this.state.changeRole && (this.state.editedData.role === USER_ROLES.salesPerson
        || this.state.editedData.role === USER_ROLES.messenger)
    ) {
      this.setState({ errorMessage: Strings("notAllowedToChangeToThisRole") });
    }
    USER_ROLES_WITH_REPORTING.includes(this.state.editedData.role) &&
      this.state.editedData.branch.length > 0 &&
      this.props.getReportingUsersUnderSelectedBranch({
        branches: this.state.editedData.branch,
        Role: REPORTING_USER_ROLES[role],
      });

    USER_ROLES_WITH_COLLECTION_OFFICER.includes(
      this.state.editedData.role
    ) &&
      (this.state.editedData.branch.length > 0 ||
        this.state.user.branch.length > 0) &&
      this.props.getCollectionOfficersUnderSelectedBranch({
        branches: this.state.editedData.branch,
      });

    USER_ROLES_WITH_POSITION.includes(this.state.editedData.role) &&
      this.state.editedData.branch.length > 0 &&
      this.state.editedData?.type !== SPECIAL &&
      this.props.getPositionsUnderSelectedBranch({
        branches: this.state.editedData.branch,
        Role: POSITIONS_USER_ROLES[role],
      });
  };

  handleBranchChange = (event) => {
    let checkboxValue = event.target.value;
    let selectedOptions = this.state.editedData.branch;
    let indexOfOption = selectedOptions.indexOf(checkboxValue);
    if (checkboxValue !== "selectBranch") {
      if (
        USER_ROLES_WITH_SINGLE_BRANCH.includes(
          this.state.editedData.role
        )
      ) {
        selectedOptions = [];
        selectedOptions[0] = checkboxValue;
      }
      if (
        indexOfOption === -1 &&
        !USER_ROLES_WITH_SINGLE_BRANCH.includes(
          this.state.editedData.role
        )
      ) {
        selectedOptions = checkboxValue;
      }
      this.setState(
        {
          editedData: {
            ...this.state.editedData,
            branch: selectedOptions,
            reportingToUserId: "",
            position: "",
            collectionOfficer: "",
            zones: []
          },
        },
        () => {
          this.checkForDisableSave();
          USER_ROLES_WITH_REPORTING.includes(
            this.state.editedData.role
          ) &&
            this.state.editedData.branch.length > 0 &&
            this.props.getReportingUsersUnderSelectedBranch({
              branches: this.state.editedData.branch,

              Role: REPORTING_USER_ROLES[this.state.editedData.role],
            });

          USER_ROLES_WITH_COLLECTION_OFFICER.includes(
            this.state.editedData.role
          ) &&
            this.state.editedData.branch.length > 0 &&
            this.props.getCollectionOfficersUnderSelectedBranch({
              branches: this.state.editedData.branch,
            });

          USER_ROLES_WITH_POSITION.includes(
            this.state.editedData.role
          ) &&
            this.state.editedData.branch.length > 0 &&
            this.state.editedData?.type !== SPECIAL &&
            this.props.getPositionsUnderSelectedBranch({
              branches: this.state.editedData.branch,
              Role: POSITIONS_USER_ROLES[this.state.editedData.role],
            });

          this.checkForDisableSave();
          this.props.getZonesByBranch(
            this.state.editedData.branch[0]
          );
        }
      );
    }
  };

  userTypeChange = (checkboxValue) => {//NOSONAR
    let value =
      checkboxValue === this.state.editedData.type
        ? null
        : checkboxValue;

    if (
      this.state.editedData.role === USER_ROLES.salesPerson &&
      value === SPECIAL
    ) {
      let allbranches = [];
      let i;
      for (i = 0; i < this.state.branches.length; ++i) {
        if (
          this.state.branches[i].id !== "" &&
          this.state.branches[i].id !== null
        ) {
          if (!DEFAULT_VALUES.includes(this.state.branches[i].id)) {
            allbranches.push(this.state.branches[i].id);
          }
        }
      }
      this.setState({
        editedData: {
          ...this.state.editedData,
          branch: allbranches,
          position: "",
          collectionOfficer: "",
          type: value,
          email: "",
        },
      });
    } else {
      if (this.state.editedData.role === USER_ROLES.salesPerson) {
        this.onChangeRole({
          target: { value: this.state.editedData.role },
        });
      } else {
        this.setState({
          editedData: { ...this.state.editedData, type: value },
        });
      }
    }
  };

  handleUploadPhoto = (event) => {
    let refThis = this;
    let file = event.target.files[0];
    let validFile = true;

    if (file) {
      let fileExtension = Utility.getFileExtension(file);
      if (
        !_.includes(
          FILE_CONFIGURATIONS.allowedImageExtensions,
          fileExtension
        )
      ) {
        validFile = false;
      }
      if (file.size > PROFILE_PHOTO_FILE_SIZE.size) {
        validFile = false;
      }
      if (validFile) {
        let reader = new FileReader();
        reader.onloadend = () => {
          refThis.setState({
            avatar: reader.result,
          });
        };
        reader.readAsDataURL(file);
        this.setState(
          { fileToUpload: file },
          this.checkForDisableSave
        );
      }
    }
    this.setState({
      editedData: {
        ...this.state.editedData,
      },
    });
  };

  /* #endregion */

  /* #region  AutoFill Methods */

  /**
   * Set username on basis of firstname and lastname
   */
  setUsernameToDefault = () => {
    let uname;
    //Check if username is not exist and  firstname and lastname is not exist
    //then user firstname.lastname as username
    if (this.state.editedData.username.trim().length === 0) {
      if (
        this.state.editedData &&
        this.state.editedData.firstName.trim().length !== 0 &&
        this.state.editedData.lastName.trim().length !== 0
      )
        uname =
          this.state.editedData.firstName.trim().toLowerCase() +
          "." +
          this.state.editedData.lastName.trim().toLowerCase();
      else uname = this.state.editedData.username;
    } else uname = this.state.editedData.username;
    this.setState(
      {
        editedData: {
          ...this.state.editedData,
          username: uname,
        },
      },
      this.checkForDisableSave
    );
  };

  /**
   *Set Reporting To mail id in case of SalesPerson/Messenger */
  getEmailIdOfReportingUser = (reportingUserId) => {
    let reportingUsers = this.props.reportingUsers;
    let emailId = "";
    let i;
    for (i = 0; i < reportingUsers.length; ++i) {
      if (
        parseInt(reportingUsers[i].id) === parseInt(reportingUserId)
      ) {
        emailId = reportingUsers[i].email;
      }
      this.setState(
        {
          editedData: {
            ...this.state.editedData,
            email: emailId,
          },
        },
        this.checkForDisableSave
      );
    }
  };

  getZonesForPosition = (positionId) => {
    let positionList = this.props.positions;
    let zones = "";
    let i;
    for (i = 0; i < positionList?.length; ++i) {
      if (positionList[i].value === positionId) {
        zones = positionList[i].zones;
      }
      this.setState(
        {
          editedData: {
            ...this.state.editedData,
            zones,
          },
        },
        this.checkForDisableSave
      );
    }
  };

  validateString = (string) => {
    let errorMessage = null;
    if (string.trim().length === 0)
      errorMessage = Strings("pleaseSelectRequiredFields");
    else if (string.trim().length < 5 || string.trim().length > 7)
      errorMessage = Strings("positionNameLengthError");
    else if (Utility.containsSpecialChars(string))
      errorMessage = Strings("specialCharError");
    return errorMessage;
  };

  onSaveClick = () => {
    let {
      firstName,
      lastName,
      phone,
      email,
      photo,
      username,
      branch,
      reportingToUserId,
      aub,
      type,
      zones,
      collectionOfficer,
      position,
      conciliationAccess,
    } = this.state.editedData;

    // Only 0th element could be a placeholder value such as "Select branch". Other branch values
    // are always going to be branch ids. Thus, check if branch[0] if non-branchid
    // and if it is, then remove it.
    if (
      branch.length > 0 &&
      !this.props.branches.some((el) => el.id === branch[0])
    ) {
      branch.shift();
    }
    if (this.state.editedData.phone.length !== 10) {
      this.setState({ errorMessage: Strings("MobileNumberMustBeTenDigits") });
      this.errorRef.current.scrollIntoView();
    }
    else if (!Utility.checkIfValidEmail(this.state.editedData.email)) {
      this.setState({ errorMessage: Strings("ErrorInvalidEmail") });
      this.errorRef.current.scrollIntoView();
    } else if (
      this.state.editedData.role === USER_ROLES.messenger &&
      this.validateString(position) !== null
    ) {
      this.setState({ errorMessage: this.validateString(position) });
      this.errorRef.current.scrollIntoView();
    } else if (
      this.state.changeRole
      && (this.state.editedData.role === USER_ROLES.salesPerson
        || this.state.editedData.role === USER_ROLES.messenger)
    ) {
      this.setState({ errorMessage: Strings("notAllowedToChangeToThisRole") });
    } else if (
      this.state.changeRole
      && this.state.editedData.role === this.props.userProfile.role
    ) {
      this.setState({ errorMessage: Strings("sameRoleSelected") });
    } else {
      if (this.state.changeRole) {
        if (this.props.workloadCount !== 0) {
          this.setState({
            workLoadModal: !this.state.workLoadModal,
            workloadSelectedUserIds: "",
            action: "CHANGE_ROLE"
          })
        } else {
          this.setState({
            workLoadModal: false,
            userModal: true,
            action: "CHANGE_ROLE"
          })
        }
      }
      else {
        if (this.state.user.username !== "") {
          this.props.updateUserProfile(
            {
              firstName: Utility.toTitleCase(firstName),
              lastName: Utility.toTitleCase(lastName),
              phone,
              email,
              photo,
              aub,
              username,
              branch,
              reportingToUserId,
              type,
              zones,
              collectionOfficer,
              position,
              conciliationAccess,
            },
            (response) => {
              this.disableInputOnResponse(response);
            }
          );

          if (this.state.fileToUpload) {
            this.props.uploadProfilePic(
              this.state.user.username,
              this.state.fileToUpload
            );
          }
        } else {
          this.props.handleCreateUser(
            this.state.editedData,
            this.state.fileToUpload,
            (response) => {
              this.disableInputOnResponse(response);
            }
          );
        }
      }
    }
  };

  disableInputOnResponse = (response) => {
    if (response.status === OPERATION_STATUS.SUCCESS) {
      this.setState({
        disableInput: true,
        errorMessage: "",
      });
    }
  };
  /* #endregion */

  /* #region  Deactive Methods */

  adminRights = () => {
    let loggedInUserInfo = this.props.loggedInUserInfo;
    let selectedUser = this.props.userProfile;
    let isAdmin =
      loggedInUserInfo?.role === USER_ROLES.admin ||
      loggedInUserInfo?.role === USER_ROLES.generalManager ||
      loggedInUserInfo?.role === USER_ROLES.operationsModerator;
    let isSelfProfile =
      selectedUser &&
      loggedInUserInfo?.username === selectedUser.username;

    return isAdmin && !isSelfProfile;
  };

  onDeactivateClick = () => {
    if (this.props.workloadCount !== 0) {
      this.setState({
        workLoadModal: !this.state.workLoadModal,
        workloadSelectedUserIds: "",
        action: "DEACTIVATION"
      })
    } else {
      this.setState({
        workLoadModal: false,
        userModal: true,
        action: "DEACTIVATION"
      });
    }
  };

  onChangeRoleClick = () => {
    this.setState({
      changeRole: true,
    })
  }

  handleAvailabilityCheck = (event) => {
    if (event.target.checked === false) {
      this.setState({
        availability: event.target.checked,
        workLoadModal: this.props.workloadCount !== 0,
        workloadSelectedUserIds: "",
        action: "AVAILABILITY",
        userModal: this.props.workloadCount === 0,
      })
    } else {
      this.setState({
        workLoadModal: false,
        availability: event.target.checked,
        userModal: true,
        action: "AVAILABILITY"
      });
    }
  }



  handleDeactivateUser = () => {
    this.hideUserModal();
    this.props.handleDeactivateUser(this.state.user);
  };

  handleWorkLoad = () => {
    this.setState({
      workLoadModal: false,
      errorMessage: ""
    })
  }

  hideUserModal = () => {
    this.setState({
      userModal: false,
    });
  };

  /* #endregion */

  /* #region  Validations */

  validateNumberField = (event) => {
    if (!Utility.checkIfNumber(event) || event.shiftKey) {
      event.preventDefault();
    }
  };

  checkRequiredRole = () => {
    return (
      (this.state.editedData.role === USER_ROLES.salesPerson &&
        (this.state.editedData.type === null ||
          this.state.editedData.type === false)) ||
      this.state.editedData.role === USER_ROLES.messenger
    );
  };
  /* #endregion */

  /* #region Loading Modal */
  isLoaded = () => {
    this.setState({ showLoadingModal: false });
  };
  /* #endregion */

  getProfileImage = () => {
    let profileImg = this.state.avatar
      ? this.state.avatar
      : ProfileImage;
    return this.state.profilePicUrl === null || this.state.avatar
      ? profileImg
      : this.props.profilePicUrl;
  };

  reportingUsersMapping = (ReportingUsers) => {
    if (ReportingUsers && ReportingUsers.length > 1) {
      return ReportingUsers.map((singleReportingUser) => {
        return (
          <option
            key={singleReportingUser.id}
            value={singleReportingUser.id}
          >
            {singleReportingUser.id === DEFAULT_REPORTING_USER
              ? SELECT_DEFAULT_REPORTING_USER().role
              : singleReportingUser.username}
          </option>
        );
      });
    } else {
      return null;
    }
  };

  collectionOfficerUsersMapping = (collectionOfficersUsers) => {
    if (
      collectionOfficersUsers &&
      collectionOfficersUsers.length > 1
    ) {
      return collectionOfficersUsers.map(
        (singleCollectionOfficerUser) => {
          return (
            <option
              key={singleCollectionOfficerUser.id}
              value={singleCollectionOfficerUser.id}
            >
              {singleCollectionOfficerUser.id ===
                DEFAULT_COLLECTION_OFFICER_DROPDOWN
                ? SELECT_COLLECTION_OFFICER_USER().role
                : singleCollectionOfficerUser.username}
            </option>
          );
        }
      );
    } else {
      return null;
    }
  };

  positionsMapping = (positionsList) => {
    if (positionsList && positionsList.length > 1) {
      return positionsList.map((singleposition) => {
        return (
          <option
            key={
              singleposition.position
                ? singleposition.position
                : singleposition.id
            }
            value={singleposition.position}
          >
            {singleposition.id === DEFAULT_POSITION_DROPDOWN
              ? this.props.userProfile.position ||
              SELECT_POSITION_USER().role
              : singleposition.position}
          </option>
        );
      });
    } else {
      if (this.props.userProfile.position) {
        return (
          <option
            key={this.props.userProfile.position.id}
            value={this.props.userProfile.position}
          >
            {this.props.userProfile.position}
          </option>
        );
      }
      return null;
    }
  };

  renderHeading = () => {
    let heading;
    if (this.state.isAddUser) {
      heading = Strings("UserManagement") + " - " + Strings("AddUser");
    } else if (!this.state.isAddUser) {
      if (this.state.changeRole) {
        heading = Strings("UserManagement") + " - " + Strings("changeRole");
      } else {
        heading = Strings("UserManagement") + " - " + Strings("UserDetails");
      }
    }
    return <Row className='heading'>
      {heading}
    </Row>
  };

  renderReportTo = () => {
    let ReportingUsers = this.props.reportingUsers;
    ReportingUsers = ReportingUsers?.map((user) => {
      return { name: user.username, value: user.id }
    })
    ReportingUsers &&
      ReportingUsers.length > 0
      && ReportingUsers[0].name !== SELECT_DEFAULT_REPORTING_USER().role
      && ReportingUsers.unshift({ name: SELECT_DEFAULT_REPORTING_USER().role, value: "" });
    if (USER_ROLES_WITH_REPORTING.includes(
      this.state.editedData.role
    )) {
      return this.state.editedData.branch?.length > 0 &&
        this.props.reportingUsers &&
        this.props.reportingUsers.length > 0 ? (
        <React.Fragment>
          {Strings("ReportTo")}
          <span className='mandatoryFields'>*</span>
          <div>
            <Row className='addOrUpdateUserExtraMargin'>
              <Col md={6} className='noPadding'>
                {this.renderSelectTextfield(
                  "reportingToUserId",
                  ReportingUsers,
                  this.props.userProfile.id && !this.props.userProfile.active
                )}
              </Col>
            </Row>
          </div>
        </React.Fragment>
      ) : (
        !this.props.userProfile.id
        && <Col md={12}>
          <div className="warningText w-50">{Strings("NO_REPORTING_USER_FOUND")}</div>
        </Col>
      );
    }
  };

  renderCollectionOfficers = () => {
    let collectionOfficersList = this.props.collectionOfficers;
    collectionOfficersList &&
      collectionOfficersList.length > 0
      && collectionOfficersList[0].name !== SELECT_COLLECTION_OFFICER_USER().name
      && collectionOfficersList.unshift(SELECT_COLLECTION_OFFICER_USER());

    return USER_ROLES_WITH_COLLECTION_OFFICER.includes(
      this.state.editedData.role
    ) &&
      this.state.editedData.branch?.length > 0 &&
      this.props.collectionOfficers &&
      this.props.collectionOfficers.length > 0 ? (
      <React.Fragment>
        {Strings("COLLECTIONS_OFFICER")}
        <span className='mandatoryFields'>*</span>
        <div>
          <Row className='addOrUpdateUserExtraMargin'>
            <Col md={12} className='noPadding'>
              {this.renderSelectTextfield(
                "collectionOfficer",
                collectionOfficersList,
                this.state.disableInput || this.state.editedData.branch.length === 0
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    ) : (
      <Col md={12} className='pl-0'>
        {USER_ROLES_WITH_COLLECTION_OFFICER.includes(
          this.state.editedData.role
        ) &&
          this.state.editedData.branch.length > 0 && (
            <div ref={this.errorRef} className='noPadding errorText'>
              {Strings("CollectionOfficersNotAvailableError")}
            </div>
          )}
      </Col>
    );
  };

  renderPositions = () => {
    let positionsList = this.props.positions;
    positionsList &&
      positionsList.length > 0 &&
      positionsList[0].name !== SELECT_POSITION_USER().name &&
      positionsList.unshift(SELECT_POSITION_USER());

    return USER_ROLES_WITH_POSITION.includes(
      this.state.editedData.role
    ) &&
      ((this.state.editedData.branch?.length > 0 &&
        this.props.positions &&
        this.props.positions.length > 0) ||
        this.props.userProfile.position) ? (
      <React.Fragment>
        {Strings("position")}
        <span className='mandatoryFields'>*</span>
        <div>
          <Row className='addOrUpdateUserExtraMargin'>
            <Col md={6} className='noPadding'>
              {this.renderSelectTextfield(
                "position",
                positionsList,
                this.state.disableInput ||
                this.state.editedData.branch.length === 0)}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    ) : (
      <Col md={6} className='pl-0'>
        {USER_ROLES_WITH_POSITION.includes(
          this.state.editedData.role
        ) &&
          this.state.editedData.branch.length > 0 && (
            <div ref={this.errorRef} className='noPadding errorText'>
              {Strings("PositionsNotAvailableError")}
            </div>
          )}
      </Col>
    );
  };

  renderZonesContainer = () => {
    let zones = [];
    if (this.state.editedData.aub === POSITION_TYPE.NEW_POSITION) {
      if (this.state.editedData.role === USER_ROLES.salesPerson) {
        this.props.zones.forEach((zone) => {
          if (!DEFAULT_VALUES.includes(zone.name)) {
            zones.push(zone.name);
          }
        });
      } else {
        this.props.mZones.forEach((zone) => {
          if (!DEFAULT_VALUES.includes(zone.name)) {
            zones.push(zone.name);
          }
        });
      }
    } else {
      zones = this.state.editedData.zones;
    }
    return (
      USER_ROLES_TO_SHOW_ZONES.includes(this.state.editedData.role) &&
      this.state.editedData.position &&
      this.state.editedData.position !==
      SELECT_POSITION_USER().role && (
        <React.Fragment>
          {Strings("selectZones")}
          <span className='mandatoryFields'>*</span>
          <div>
            <Row className='reportingToMargin'>
              <Col md={6} className='noPadding'>
                <MultiCheckBoxAreaComponent
                  branches={zones}
                  selectedValues={this.state.editedData.zones}
                  selectedField={"zone"}
                  className='unsetJustifyContent'
                  handleCheckboxChange={
                    this.state.editedData.aub ===
                      POSITION_TYPE.NEW_POSITION
                      ? this.handleZoneChange
                      : () => { }
                  }
                  disabled={
                    this.state.editedData.aub !==
                    POSITION_TYPE.NEW_POSITION
                  }
                />
              </Col>
            </Row>
          </div>
        </React.Fragment>
      )
    );
  };

  handleAddZoneChange = (event) => {
    let value = [...this.state.editedData.zones]
    value = event.target.value ? event.target.value.trim().split(",") : []
    this.setState(
      {
        editedData: {
          ...this.state.editedData,
          zones: value,
        },
      },
      this.checkForDisableSave
    );
  };


  renderConciliationAccess = () => {
    return (
      <div className='pb-2'>
        <div>
          <CheckboxComponent
            label={Strings("conciliationAccess")}
            name={"conciliationAccess"}
            onChange={() => this.handleChange({ target: { name: "conciliationAccess", checked: !this.state.editedData.conciliationAccess } })}
            checked={this.state.editedData.conciliationAccess}
          />
        </div>
      </div>
    );
  };

  renderUserCategory = () => {
    return (
      <div className='pb-2'>
        <div className='pb-2'>{Strings("userCategory")}</div>
        <div
          className={
            this.state.editedData.role ===
              USER_ROLES.collectionsOfficer || //NOSONAR
              this.state.user.username !== ""
              ? "cursorNotAllowed"
              : ""
          }
        >
          <CheckboxComponent
            label={Strings("Special")}
            onChange={
              this.state.editedData.role ===
                USER_ROLES.collectionsOfficer || //NOSONAR
                this.state.user.username !== ""
                ? () => {
                  /*This is intentional*/
                }
                : () => this.userTypeChange(SPECIAL)
            }
            checked={this.state.editedData.type === SPECIAL}
            disabled={
              this.state.editedData.role ===
              USER_ROLES.collectionsOfficer ||
              this.state.user.username !== ""
            }
          />
        </div>
      </div>
    );
  };

  renderBranchContainer = () => {
    let multiple =
      this.state.editedData.role === USER_ROLES.collectionOfficer
        ? true
        : false;
    return (
      USER_ROLES_TO_SHOW_BRANCH.includes(
        this.state.editedData.role
      ) && (
        <React.Fragment>
          {Strings("Branch")}
          <span className='mandatoryFields'>*</span>
          <div>
            <Row className='addOrUpdateUserExtraMargin'>
              <Col md={12} className='noPadding'>
                <MultipleSelectCheckmarks
                  itemList={this.props.branches}
                  placeHolder={"selectBranch"}
                  multiple={multiple}
                  selectedValues={this.state.editedData.branch}
                  handleChange={this.handleBranchChange}
                  disabled={
                    this.state.disableInput ||
                    (!this.state.changeRole && this.state.user.username !== "") ||
                    USER_ROLES_NOT_ALLOWED_TO_CHANGE_BRANCHES.includes(
                      this.state.editedData.role
                    ) ||
                    (this.state.editedData.role ===
                      USER_ROLES.salesPerson &&
                      this.state.editedData.type === SPECIAL)
                  }
                  tag={"branch"}
                  NO_TAG_FIELDS={true}
                />
              </Col>
            </Row>
          </div>
        </React.Fragment>
      )
    );
  };

  renderEditedRole = () => {
    let userRolesList = this.props.userRolesList;
    userRolesList &&
      userRolesList.length > 0 &&
      userRolesList[0].name !== GET_DEFAULT_DROPDOWN_SELECTION().name &&
      userRolesList.unshift(GET_DEFAULT_DROPDOWN_SELECTION());
    return (
      <Col md={12} className='noPadding'>
        {this.renderSelectTextfield(
          "role",
          userRolesList,
          this.state.disableInput ||
          (!this.state.changeRole && this.state.user.username !== "") ||
          this.props.loggedInUserInfo.role === USER_ROLES.operationsModerator,
        )}
        <Row className='pt-4'>
          {SPECIAL_USER_ROLES.includes(
            this.state.editedData.role
          )
            ? this.renderUserCategory()
            : this.state.editedData.role === //NOSONAR
              USER_ROLES.operationsOfficer
              ? this.renderConciliationAccess()
              : null}
        </Row>
      </Col>
    );
  };

  toggleAvailabilitySwitch = () => {
    this.setState({
      currentAvailability: this.state.availability,
      workLoadModal: false,
      userModal: false,
      errorMessage: ""
    })
  }

  renderConfirmationModal = () => {
    let modalText, headerText, okButtonClick;
    if (this.state.action === "DEACTIVATION") {
      headerText = Strings("DeactivateUser");
      okButtonClick = this.handleDeactivateUser;
      if (this.props.userProfile.role === USER_ROLES.salesModerator
        || this.props.userProfile.role === USER_ROLES.messengerModerator
        || this.props.userProfile.role === USER_ROLES.collectionsSupervisor
        || this.props.userProfile.role === USER_ROLES.collectionsManager
        || this.props.userProfile.role === USER_ROLES.administrativeManager
      ) {
        modalText = Strings("canDeactivateModerator");
      } else if (this.props.userProfile.role === USER_ROLES.operationsOfficer
        || this.props.userProfile.role === USER_ROLES.accountingOfficer
        || this.props.userProfile.role === USER_ROLES.analyst) {
        modalText = Strings("canDeactivateUser");
      } else {
        modalText = Strings("ConfirmDeactivateUser");
      }
    } else if (this.state.action === "AVAILABILITY") {
      headerText = Strings("Availability");
      okButtonClick = () => this.props.changeAvailabilityStatus(this.state.availability, this.toggleAvailabilitySwitch);
      if (this.props.userProfile.role === USER_ROLES.salesModerator
        || this.props.userProfile.role === USER_ROLES.messengerModerator
        || this.props.userProfile.role === USER_ROLES.collectionsSupervisor
        || this.props.userProfile.role === USER_ROLES.collectionsManager
        || this.props.userProfile.role === USER_ROLES.administrativeManager) {
        modalText = this.state.availability
          ? Strings("canAvailableUser")
          : Strings("canUnavailableModerator");
      } else if (this.props.userProfile.role === USER_ROLES.operationsOfficer
        || this.props.userProfile.role === USER_ROLES.accountingOfficer
        || this.props.userProfile.role === USER_ROLES.analyst) {
        modalText = this.state.availability
          ? Strings("canAvailableUser")
          : Strings("canUnavailableUser");
      } else {
        if (this.state.availability === false) {
          modalText = Strings("ConfirmUnavailableUser");
        } else {
          modalText = Strings("ConfirmAvailableUser");
        }
      }
    } else if (this.state.action === "CHANGE_ROLE") {
      headerText = Strings("changeRole");
      okButtonClick = () => this.props.changeRole(this.state.editedData, this.hideUserModal);
      modalText = Strings("confirmChangeRole");
    }

    return (
      <UserModal
        open={this.state.userModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={headerText}
        modalText1={modalText}
        showHr={true}
        modalButtonOk={{
          text: Strings("Yes"),
          onClick: okButtonClick,
        }}
        modalButtonCancel={{
          text: Strings("No"),
          onClick: this.hideUserModal,
        }}
      />
    )
  }

  handleRadioButtonChange = (event) => {
    this.setState({
      workloadSelectedUserIds: event.target.value,
      errorMessage: ""
    })
  }

  onClickWorkloadDistribution = () => {
    let workloadSelectedUserIds = [];
    this.state.workloadSelectedUserIds && workloadSelectedUserIds.push(this.state.workloadSelectedUserIds);
    if (this.state.workloadSelectedUserIds === "all") {
      workloadSelectedUserIds = this.props.sameRoleUserList.map((user) => user.uid)
    }
    if (workloadSelectedUserIds.length !== 0) {
      if (this.state.action === "DEACTIVATION") {
        this.props.workloadDistribution(workloadSelectedUserIds, this.toggleAvailabilitySwitch);
      } else if (this.state.action === "AVAILABILITY") {
        this.props.workloadDistributionForUnavailableUsers(
          workloadSelectedUserIds,
          this.toggleAvailabilitySwitch
        );
      } else if (this.state.action === "CHANGE_ROLE") {
        this.props.workloadDistributionForChangeRole(
          workloadSelectedUserIds,
          this.state.editedData,
          this.toggleAvailabilitySwitch
        );
      }
    }
    else {
      this.setState({
        errorMessage: Strings("pleaseSelectRequiredFields")
      })
    }
  }

  renderWorkloadModal = () => {
    return (
      <WorkloadDistributionModal
        open={this.state.workLoadModal}
        closeModal={this.handleWorkLoad}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("WorkloadDistributionModal")}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.handleWorkLoad,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.onClickWorkloadDistribution,
        }}
        data={this.props.sameRoleUserList}
        handleRadioButtonChange={this.handleRadioButtonChange}
        workloadSelectedUserIds={this.state.workloadSelectedUserIds}
        errorMessage={this.state.errorMessage}
        workloadCount={this.props.workloadCount}
      />
    )
  }

  renderErrorMessages = () => {
    return <div ref={this.errorRef} className='noPadding errorText'>
      {this.state.errorMessage || this.props.errorMessage}
    </div>
  }

  renderProfilePic = () => {
    return <div className='parentOfImages'>
      <img
        src={this.getProfileImage()}
        onLoad={this.isLoaded}
        className='addOrUpdateUserImage'
        alt=''
        onError={(error) => {
          error.target.onError = null;
          error.target.src = ProfileImage;
        }}
      />
      <label
        htmlFor='uploadPhoto'
        className='browse-button '
      >
        <img
          src={cameraImage}
          className={
            "addOrUpdateUserImageCamera " +
            (this.state.disableInput
              ? "disabled"
              : "enabled")
          }
          alt=''
        />
      </label>
      <input
        type='file'
        id='uploadPhoto'
        onChange={this.handleUploadPhoto}
        accept='image/png,image/jpeg,image/jpg'
        style={{ display: "none" }}
        disabled={this.state.disableInput}
      />
    </div>
  }

  renderWarnings = (warning) => {
    return <div className="d-flex justify-content-center my-3">
      <div className="warningText w-50">{warning}</div>
    </div>
  }

  //Availability switch or warning message
  renderAvailabilitySwitch = () => {
    let availabilityTitle = "";
    let availabilityWarning = "";
    if (this.props.cannotUnavailableReason) {
      availabilityWarning = Strings(this.props.cannotUnavailableReason);
    } else if (this.props.userProfile.active && this.state.currentAvailability === true) {
      availabilityTitle = Strings("available");
    } else if (!this.props.userProfile.active) {
      availabilityWarning = Strings("deactivated");
    } else if (this.state.currentAvailability === false) {
      availabilityTitle = Strings("unavailable");
    }

    return availabilityWarning === ""
      ? this.adminRights()
      && this.props.userProfile?.id
      && this.state.editedData.role !== USER_ROLES.messenger
      && this.state.editedData.role !== USER_ROLES.salesPerson
      && <div className="d-flex justify-content-center my-3" title={availabilityTitle}>
        <div className="addOrUpdateUserFontSize">{Strings("userAvailability")} &nbsp;</div>
        <Form.Check
          checked={this.state.currentAvailability}
          type="switch"
          id={this.props.userProfile.id}
          onChange={this.handleAvailabilityCheck}
          title={availabilityTitle}
        />
      </div>
      : this.props.userProfile?.id
      && this.state.editedData.role !== USER_ROLES.messenger
      && this.state.editedData.role !== USER_ROLES.salesPerson
      && <div className="d-flex justify-content-center my-3">
        <div className="warningText w-50">{`${Strings("unavailableReason")} ${availabilityWarning}`} &nbsp;</div>
      </div>
  }

  renderRatingSection = () => {
    return <Row>
      <Col md={{ span: 3, offset: 3 }} className='pl-0'>
        <div>
          {USER_ROLES_WITH_RATINGS.includes(
            this.state.user.role
          ) &&
            this.state.isAddUser === false && (
              <div>
                {" "}
                {Strings("userRating")}&nbsp;:&nbsp;
                <Rater
                  id='rating'
                  title={this.state.editedData.rating}
                  rating={this.state.editedData.rating}
                  total={5}
                  interactive={false}
                  style={{ fontSize: 15 + "px" }}
                />
              </div>
            )}
        </div>
      </Col>
      <Col md={6}>
        {this.state.user.role === USER_ROLES.salesPerson &&
          this.state.isAddUser === false && (
            <div
              title={
                MINIMART_RATING[this.state.editedData.mRat]
              }
            >
              {" "}
              {Strings("minimartUserRating")}
              &nbsp;:&nbsp;&nbsp;
              {MINIMART_RATING[this.state.editedData.mRat]}
            </div>
          )}
      </Col>
    </Row>
  }

  renderTextfield = (name, disabled, maxLength) => {
    return <TextFieldComponent
      id={name}
      name={name}
      type={name === "phone"
        ? "number"
        : "text"}
      className={name === "firstName" || name === "lastName"
        ? "noMargin caps"
        : "noMargin"}
      placeholder={Strings(name)}
      handleChange={this.handleChange}
      maxLength={maxLength}
      value={this.state.editedData[name]}
      disabled={disabled}
      onKeyDown={name === "phone"
        ? this.validateNumberField
        : () => { }}
      onFocus={name === "username"
        ? this.setUsernameToDefault
        : () => { }}

    />
  }

  renderSelectTextfield = (name, list, disabled) => {
    let handleChange = this.handleChange;
    if (name === "role") {
      handleChange = this.onChangeRole;
    }
    return <TextFieldComponent
      type='select'
      handleChange={handleChange}
      name={name}
      value={this.state.editedData[name]}
      disabled={disabled}
    >
      {list.map((singleElement) => {
        return <option key={singleElement.name} value={singleElement.value}>
          {name === "role"
            ? Strings(singleElement.name)
            : singleElement.name}
        </option>
      })}
    </TextFieldComponent>
  }


  renderInputForm = () => {
    let renderPositionDropdown = false;
    if (((this.state.editedData.role === USER_ROLES.messenger ||
      this.state.editedData.role === USER_ROLES.salesPerson) &&
      this.state.editedData.aub === POSITION_TYPE.EXISTING_POSITION)
    ) {
      renderPositionDropdown = true;
    }
    return <Row className='subHeaderApplication addOrUpdateUserFontSize'>
      {/* First name input */}
      <Col
        md={{ span: 3, offset: 3 }}
        className='pl-0 pr-3 pt-3'
      >
        {Strings("firstName")}
        <span className='mandatoryFields'>*</span>
        <Row className='addOrUpdateUserExtraMargin'>
          <Col md={12} className='noPadding'>
            {this.renderTextfield(
              "firstName",
              this.props.userProfile.id && !this.props.userProfile.active,
              20
            )}
          </Col>
        </Row>
      </Col>

      {/* Last name input */}
      <Col md={6} className='pr-0 pt-3'>
        {Strings("lastName")}
        <span className='mandatoryFields'>*</span>
        <Row className='addOrUpdateUserExtraMargin'>
          <Col md={6} className='noPadding'>
            {this.renderTextfield(
              "lastName",
              this.props.userProfile.id && !this.props.userProfile.active,
              20
            )}
          </Col>
        </Row>
      </Col>

      <Col
        md={{ span: 3, offset: 3 }}
        className='addOrUpdateUserPaddingLeft0'
      >

        {/* username input */}
        {Strings("username")}
        <span className='mandatoryFields'>*</span>
        <Row className='addOrUpdateUserExtraMargin'>
          <Col md={12} className='noPadding'>
            {this.renderTextfield(
              "username",
              this.state.disableInput || this.state.user.username !== "",
              20
            )}
          </Col>
        </Row>

        {/* Role input */}
        {Strings("Role")}
        <span className='mandatoryFields'>*</span>
        <Row className='addOrUpdateUserExtraMargin'>
          {this.renderEditedRole()}
        </Row>

        {/* Render multi select branches dropdown */}
        {this.renderBranchContainer()}

        {/* Render collection officer dropdown */}
        {this.props.collectionOfficers &&
          this.renderCollectionOfficers()}
      </Col>
      <Col md={6} className='addOrUpdateUserPaddingRight0'>

        {/* Phone number input */}
        {Strings("PhoneNumber")}
        <span className='mandatoryFields'>*</span>
        <Row className='addOrUpdateUserExtraMargin'>
          <Col md={6} className='noPadding'>
            {this.renderTextfield(
              "phone",
              this.props.userProfile.id && !this.props.userProfile.active,
              10
            )}
          </Col>
        </Row>

        {/* Email input */}
        {this.checkRequiredRole()
          ? Strings("email") +
          " " +
          Strings("of") +
          " " +
          Strings("ReportTo")
          : Strings("email")}
        <span className='mandatoryFields'>*</span>
        <Row className='addOrUpdateUserExtraMargin'>
          <Col md={6} className='noPadding'>
            {this.renderTextfield(
              "email",
              this.checkRequiredRole() ||
              (this.props.userProfile.id && !this.props.userProfile.active) ||
              this.state.editedData.role === ""
            )}
          </Col>
        </Row>

        {/* Existing or New Position dropdown */}
        {(this.state.editedData.role === USER_ROLES.messenger
          || this.state.editedData.role === USER_ROLES.salesPerson)
          && this.state.isAddUser
          && (
            <>
              {Strings("Assign")}
              <span className='mandatoryFields'>*</span>
              <Row className='addOrUpdateUserExtraMargin'>
                <Col md={6} className='noPadding'>
                  {this.renderSelectTextfield(
                    "aub",
                    POSITION_TYPES
                  )}
                </Col>
              </Row>
            </>
          )}

        {/* New Position input */}
        {(!this.state.isAddUser
          || ((this.state.editedData.role === USER_ROLES.messenger
            || this.state.editedData.role === USER_ROLES.salesPerson)
            && this.state.editedData.aub === POSITION_TYPE.NEW_POSITION))
          && (
            <>
              {Strings("positionName")}
              <span className='mandatoryFields'>*</span>
              <Row className='addOrUpdateUserExtraMargin'>
                <Col md={6} className='noPadding'>
                  {this.renderTextfield(
                    "position",
                    !this.state.isAddUser,
                    20
                  )}
                </Col>
              </Row>
            </>
          )}

        {/* Report to dropdown */}
        {(this.state.editedData.type !== SPECIAL ||
          this.state.editedData.role !==
          USER_ROLES.salesPerson) &&
          this.renderReportTo()}

        {/* Existing positions dropdown */}
        {renderPositionDropdown && this.renderPositions()}

        {/* For New postion, select multiple existing zones or create new zones*/}
        {USER_ROLES_TO_SHOW_ZONES.includes(this.state.editedData.role)
          && this.state.editedData.position
          && this.state.editedData.position !== SELECT_POSITION_USER().name
          && (this.state.editedData?.aub && this.state.editedData?.aub === POSITION_TYPE.NEW_POSITION
            ? <>
              {this.renderZonesContainer()}
              {Strings("Zones")}
              <span className='mandatoryFields'>*</span>
              <Row className='addOrUpdateUserExtraMargin'>
                <Col md={6} className='noPadding'>
                  {this.renderTextfield(
                    "zones",
                  )}
                </Col>
              </Row>
            </>
            :
            //  For Existing postion, select multiple existing zones
            this.renderZonesContainer())}
      </Col>

    </Row>
  }

  renderButtons = () => {
    return <Row className='addOrUpdateUserExtraMargin alignActionButtons'>
      {JSON.stringify(this.props.userProfile) !== JSON.stringify(DEFAULT_USER_DETAILS)
        && this.adminRights()
        && !this.state.isAddUser
        && !this.state.changeRole
        ? (
          <Col
            md={{ span: 3, offset: 3 }}
            className='noPadding alignDeactivate'
          >
            <Button
              title={Strings(this.props.cannotDeactivateReason)}
              className='buttonBackground pull-right'
              onClick={this.onDeactivateClick}
              disabled={!this.props.deactivationFlag}
            >
              {Strings("Deactivate")}
            </Button>
            {this.props.userProfile.role !== USER_ROLES.salesPerson
              && this.props.userProfile.role !== USER_ROLES.messenger
              && <Button
                title={Strings(this.props.cannotChangeRoleReason)}
                className='buttonBackground pull-right mx-2'
                onClick={this.onChangeRoleClick}
                disabled={!this.props.deactivationFlag}
              >
                {Strings("changeRole")}
              </Button>}
          </Col>
        )
        : (
          <Col md={6}></Col>
        )
      }
      <Col md={6}>
        <div className='saveOrCancelButton'>
          <Button
            className='buttonBorder pull-left mr-2'
            onClick={this.props.onBackClick}
          >
            {Strings("Cancel")}
          </Button>
          {!this.state.disableInput ? (
            <Button
              className='buttonBackground pull-right'
              onClick={this.onSaveClick}
              disabled={this.state.disableSave}
            >
              {Strings("Save")}
            </Button>
          ) : null}
        </div>
      </Col>
    </Row >
  }

  render() {
    let warning = "";
    if (this.state.changeRole && this.props.workloadCount !== 0) {
      warning = Strings("workloadWarning");
    }
    return (
      <Row className='addOrUpdateUserMainPanel'>
        {this.state.userModal && this.renderConfirmationModal()}
        {this.state.workLoadModal && this.renderWorkloadModal()}
        <Col
          md={12}
          className='mainHeaderApplication addOrUpdateUserHeader noPadding'
        >
          {this.renderHeading()}
          {this.renderErrorMessages()}
          <Row className='positionAddOrUpdateUserMainPanel'>
            <Col md={12} className={this.state.changeRole
              ? 'noPadding positionInnerPanel pt-5'
              : 'noPadding positionInnerPanel'}>
              {!this.state.changeRole && this.renderProfilePic()}
              {warning && this.renderWarnings(warning)}
              {!this.state.changeRole && this.renderAvailabilitySwitch()}
              {this.renderRatingSection()}
              {this.renderInputForm()}
              {this.renderButtons()}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
export default withRouter(ProfileDetails);

import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button } from "react-bootstrap";

import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import Chips from "./chip";
//Components

import "./ProductCatalog.scss";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants

class CategoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessage: props.errorMessage,
      categories: props.categories,
      readOnly: props.readOnly,
      editableCat: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let derivedState = { ...prevState };
    if (nextProps.open !== prevState.open) {
      derivedState = {
        ...derivedState,
        open: nextProps.open,
        errorMessage: nextProps.errorMessage,
        editableCat: null,
      };
    }

    if (
      nextProps.categories.length > 0 &&
      nextProps.categories[0].id === ""
    ) {
      let stateCats = nextProps.categories;
      stateCats.splice(0, 1);
      derivedState = { ...derivedState, categories: stateCats };
    }
    return derivedState;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  //to close modal
  closeModal = () => {
    this.props.modalButtonCancel.onClick();
  };

  onChipClick = (category) => {
    let newCategory = { category: category.cat, id: category.id };
    this.setState({ editableCat: newCategory, errorMessage: "" });
  };

  handleChange = (event) => {
    let value = event.target.value.trimStart();
    this.setState(
      {
        editableCat: {
          ...this.state.editableCat,
          category: value,
        },
        errorMessage: "",
      },
      () => {
        this.props.saveCategory(this.state.editableCat);
      }
    );
  };

  render() {
    return (
      <div>
        <Modal
          // Change visibility of modal based on props.
          open={this.state.open}
          onClose={this.closeModal}
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          showCloseIcon={this.props.modalShowCloseIcon}
          classNames={{
            modal: "productModal",
          }}
          center
        >
          <Row className='productModal'>
            <Col md={12} className='loanDetailModalHeader pl-3'>
              {this.props.headerText}
            </Col>

            <Col md={12} className='pt-3 '>
              {this.state.errorMessage && (
                <Row className='errors noPadding'>
                  {this.state.errorMessage}
                </Row>
              )}
              <Row className='justifySpaceEvenely'>
                {this.state.categories.length > 0 &&
                  this.state.categories.map((category) => {
                    return (
                      <Col md={4} key={category.id}>
                        <Chips
                          key={category.id}
                          content={category.cat}
                          chipId={category}
                          className='catChips'
                          onChipClick={this.onChipClick}
                        />
                      </Col>
                    );
                  })}
              </Row>
              <TextFieldComponent
                placeholder={Strings("nameOfCategory")}
                className='topMargin'
                handleChange={(event) => this.handleChange(event)}
                value={
                  this.state.editableCat
                    ? this.state.editableCat.category
                    : ""
                }
                maxLength={30}
              />
              <hr className='newHr' />
            </Col>
            <Col md={12} className='buttonAlign'>
              <Button
                className='primaryModalButton marginCancelButton buttonBorder buttonMinWidth'
                onClick={this.props.modalButtonCancel.onClick}
              >
                {this.props.modalButtonCancel.text}
              </Button>

              <Button
                className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
                onClick={() => {
                  this.props.modalButtonApprove.onClick(
                    this.state.editableCat
                  );
                }}
              >
                {this.props.modalButtonApprove.text}
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default CategoryModal;

import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

// String
import { Strings } from "../../resources/i18n/i18n";

// Component
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

// Constant
import {
  GENERAL_LOAN_STATUS_LEGEND,
  OPERATION_OFFICER_LOANS_STATUS_LEGENDS,
  DEFAULT_LOAN_STATUS,
  DEFAULT_LOAN_STATUS_SELECTION,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  STATUS_FOR_LOANS,
  LOAN_TYPE,
  CATEGORY,
  DEFAULT_LOAN_TYPE,
  DEFAULT_CATEGORY_TYPE,
  PROVISION_GRADES,
  DEFAULT_PROVISION_GRADE,
  LOAN_CATEGORY_LEGEND,
  USER_ROLES,
  DISBURSED,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  REMITTANCE_STATUS_FOR_LOANS,
  DEFAULT_REMITTANCE_STATUS,
  LOAN_REMINDER,
  DEFAULT_CONCILIATION_TYPE,
  CONCILIATION_TYPE_LIST,
} from "../../constants/appConstants";
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";

import {
  STATUSES_OF_LOAN_FOR_OPERATIONS_OFFICER,
  REMITTANCE_STATUSES_FOR_OPERATIONS_OFFICER,
} from "../LoanList/Constants";

// Style
import "../LoanList/LoanList.scss";

class LoanApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnalyst: props.loggedInUser.role === USER_ROLES.analyst,
      isOperationsOfficer:
        props.loggedInUser.role === USER_ROLES.operationsOfficer,
      isOperationsModerator:
        props.loggedInUser.role === USER_ROLES.operationsModerator,
      isAccountsOfficer:
        props.loggedInUser.role === USER_ROLES.accountsOfficer,
      isAnalystModerator:
        props.loggedInUser.role === USER_ROLES.analystModerator,
      isAuditor: props.loggedInUser.role === USER_ROLES.auditor,
      isGeneralManager:
        props.loggedInUser.role === USER_ROLES.generalManager,
      isAdmin: props.loggedInUser.role === USER_ROLES.admin,
      isOperationsManager: props.loggedInUser.role === USER_ROLES.operationsManager,

      LoanFilters: {
        minimartCode: props.searchFilter.code,
        matricesId: props.searchFilter.mid,
        applicationId: props.searchFilter.appid,
        minimartName: props.searchFilter.mn,
        userName: props.searchFilter.an ? props.searchFilter.an : "",
        provision: DEFAULT_PROVISION_GRADE,
        status: props.searchFilter.st
          ? props.searchFilter.st
          : STATUS_FOR_LOANS[0].value,
        category: DEFAULT_CATEGORY_TYPE,
        loanType: props.searchFilter.ty,
        fromDate: props.searchFilter.fd,
        toDate: props.searchFilter.td,
        remittanceStatus: props.searchFilter.rst
          ? props.searchFilter.rst
          : REMITTANCE_STATUS_FOR_LOANS[0].value,
        conciliationType: props.searchFilter.cct,
      },
      numberOfPages: props.numberOfPages,
      activePage: props.searchFilter.offset,

      recordsPerPage: props.searchFilter.limit,
      disabled: true,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.featureName !== this.props.featureName) {
      this.setState(
        {
          LoanFilters: {
            minimartCode: this.props.searchFilter.code,
            matricesId: this.props.searchFilter.mid,
            applicationId: this.props.searchFilter.appid,
            minimartName: this.props.searchFilter.mn,
            userName: this.props.searchFilter.an
              ? this.props.searchFilter.an
              : "",
            provision: DEFAULT_PROVISION_GRADE,
            status: this.props.searchFilter.st
              ? this.props.searchFilter.st
              : STATUS_FOR_LOANS[0].value,
            category: DEFAULT_CATEGORY_TYPE,
            loanType: this.props.searchFilter.ty,
            fromDate: this.props.searchFilter.fd,
            toDate: this.props.searchFilter.td,
            remittanceStatus: this.props.searchFilter.rst
              ? this.props.searchFilter.rst
              : REMITTANCE_STATUS_FOR_LOANS[0].value,
            conciliationType: this.props.searchFilter.cct,
          },
        },
        () => this.handleOnClickSearchFilter(false)
      );
    }
  }

  handleSearchCICodeChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        minimartCode: event.target.value,
      },
    });
  };

  handleSearchIdChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        applicationId: event.target.value,
      },
    });
  };

  handleSearchMatricesIdChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        matricesId: event.target.value,
      },
    });
  };

  handleSearchNameChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        minimartName: event.target.value,
      },
    });
  };

  handleSearchUserNameChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        userName:
          event.target.value === Strings("analystName") ||
            event.target.value === Strings("operationOfficerName")
            ? ""
            : event.target.value,
      },
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.LoanFilters.toDate)) {
          DateValue = this.state.LoanFilters.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.LoanFilters.fromDate)) {
          DateValue =
            moment(dateMoment).diff(
              this.state.LoanFilters.fromDate,
              "days"
            ) === 0
              ? this.state.LoanFilters.fromDate
              : this.state.LoanFilters.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({
      LoanFilters: { ...this.state.LoanFilters, [key]: DateValue },
    });
  };

  handleChangeStatusFilter = (event) => {
    if (
      event.target.value !==
      Strings(DEFAULT_LOAN_STATUS_SELECTION.value)
    ) {
      this.setState({
        LoanFilters: {
          ...this.state.LoanFilters,
          status: event.target.value,
          category:
            event.target.value !== DISBURSED
              ? DEFAULT_CATEGORY_TYPE
              : this.state.LoanFilters.category,
        },
        disabled: event.target.value === DISBURSED ? false : true,
      });
    } else {
      this.setState({
        LoanFilters: {
          ...this.state.LoanFilters,
          status: "",
          category: DEFAULT_CATEGORY_TYPE,
        },
        disabled: event.target.value === DISBURSED ? false : true,
      });
    }
  };

  handleChangeremittanceStatusFilter = (event) => {
    if (event.target.value !== Strings(DEFAULT_REMITTANCE_STATUS)) {
      this.setState({
        LoanFilters: {
          ...this.state.LoanFilters,
          remittanceStatus: event.target.value,
        },
      });
    } else {
      this.setState({
        LoanFilters: {
          ...this.state.LoanFilters,
          remittanceStatus: "",
        },
      });
    }
  };

  handleProvisionFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        provision: event.target.value,
      },
    });
  };

  handleChangeTypeFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        loanType: event.target.value,
      },
    });
  };

  handleChangeCategoryFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        category: event.target.value,
      },
    });
  };

  handleChangeConciliationFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        conciliationType: event.target.value,
      },
    });
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        LoanFilters: {
          minimartCode: "",
          applicationId: "",
          minimartName: "",
          userName: "",
          matricesId: "",
          status: DEFAULT_LOAN_STATUS.value,
          provision: DEFAULT_PROVISION_GRADE,
          loanType: DEFAULT_LOAN_TYPE,
          category: DEFAULT_CATEGORY_TYPE,
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
          remittanceStatus: DEFAULT_REMITTANCE_STATUS.value,
          conciliationType: "",
        },
      },
      () => {
        this.handleOnClickSearchFilter(true);
      }
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleOnClickSearchFilter({
      search: {
        minimartCode:
          this.state.LoanFilters.minimartCode !== ""
            ? this.state.LoanFilters.minimartCode
            : undefined,
        applicationId:
          this.state.LoanFilters.applicationId !== ""
            ? this.state.LoanFilters.applicationId
            : undefined,
        matricesId:
          this.state.LoanFilters.matricesId !== ""
            ? this.state.LoanFilters.matricesId
            : undefined,
        minimartName:
          this.state.LoanFilters.minimartName !== ""
            ? this.state.LoanFilters.minimartName
            : undefined,
        userName:
          this.state.LoanFilters.userName !== ""
            ? this.state.LoanFilters.userName
            : undefined,
        status:
          this.state.LoanFilters.status !== DEFAULT_LOAN_STATUS &&
            this.state.LoanFilters.status !== "Estado de Préstamo"
            ? this.state.LoanFilters.status
            : undefined,
        loanType:
          this.state.LoanFilters.loanType !== DEFAULT_LOAN_TYPE
            ? this.state.LoanFilters.loanType
            : undefined,
        category:
          this.state.LoanFilters.category !== DEFAULT_CATEGORY_TYPE
            ? this.state.LoanFilters.category
            : undefined,
        provision:
          this.state.LoanFilters.provision !== DEFAULT_PROVISION_GRADE
            ? this.state.LoanFilters.provision
            : undefined,
        remittanceStatus:
          this.state.LoanFilters.remittanceStatus !==
            DEFAULT_REMITTANCE_STATUS
            ? this.state.LoanFilters.remittanceStatus
            : undefined,
        fromDate: this.state.LoanFilters.fromDate,
        toDate: this.state.LoanFilters.toDate,
        conciliationType:
          this.state.LoanFilters.conciliationType !==
            DEFAULT_CONCILIATION_TYPE
            ? this.state.LoanFilters.conciliationType
            : undefined,
      },
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  //render
  renderSearchFilter = () => {
    let LoanFilters;
    if (
      this.state.isAnalyst ||
      this.state.isAccountsOfficer ||
      this.state.isAuditor ||
      (this.state.isOperationsModerator &&
        this.props.featureName === "ANALYST_DASHBOARD")
    ) {
      LoanFilters = [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          handleOnChange: this.handleChangefromDate,
          fromDate: this.state.LoanFilters.fromDate,
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.LoanFilters.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
          column: 4,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchMatricesIdChange,
          Value: this.state.LoanFilters.matricesId,
          fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
          column: 2,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartCode"),
          handleOnChange: this.handleSearchCICodeChange,
          Value: this.state.LoanFilters.minimartCode,
          fieldname: SEARCH_FILTER_FIELDS.CODE,
          column: 2,
        },

        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("applicationId"),
          handleOnChange: this.handleSearchIdChange,
          Value: this.state.LoanFilters.applicationId,
          fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID,
          column: 2,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MiniMartname"),
          handleOnChange: this.handleSearchNameChange,
          Value: this.state.LoanFilters.minimartName,
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("loanType"),
          handleOnChange: this.handleChangeTypeFilter,
          Value: this.state.LoanFilters.loanType,
          fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
          filterDropdownList: LOAN_TYPE,
          column: 2,
          paddingTop: 2,
        },
      ];
    } else if (
      this.state.isAnalystModerator ||
      this.state.isAdmin ||
      this.state.isGeneralManager
    ) {
      LoanFilters = [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          handleOnChange: this.handleChangefromDate,
          fromDate: this.state.LoanFilters.fromDate,
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.LoanFilters.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
          column: 4,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchMatricesIdChange,
          Value: this.state.LoanFilters.matricesId,
          fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartCode"),
          handleOnChange: this.handleSearchCICodeChange,
          Value: this.state.LoanFilters.minimartCode,
          fieldname: SEARCH_FILTER_FIELDS.CODE,
          column: 2,
          inputType: "number",
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("applicationId"),
          handleOnChange: this.handleSearchIdChange,
          Value: this.state.LoanFilters.applicationId,
          fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID,
          column: 2,
          inputType: "number",
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MiniMartname"),
          handleOnChange: this.handleSearchNameChange,
          Value: this.state.LoanFilters.minimartName,
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleSearchUserNameChange,
          Value: this.state.LoanFilters.userName,
          fieldname: SEARCH_FILTER_FIELDS.ANALYST_NAME,
          filterDropdownList: this.props.userList,
          column: 2,
          paddingTop: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("loanType"),
          handleOnChange: this.handleChangeTypeFilter,
          Value: this.state.LoanFilters.loanType,
          fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
          filterDropdownList: LOAN_TYPE,
          column: 2,
          paddingTop: 2,
        },
      ];
    } else if (
      this.state.isOperationsOfficer ||
      this.state.isOperationsModerator ||
      this.state.isOperationsManager
    ) {
      LoanFilters = [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          handleOnChange: this.handleChangefromDate,
          fromDate: this.state.LoanFilters.fromDate,
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.LoanFilters.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
          column: 4,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchMatricesIdChange,
          Value: this.state.LoanFilters.matricesId,
          fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartCode"),
          handleOnChange: this.handleSearchCICodeChange,
          Value: this.state.LoanFilters.minimartCode,
          fieldname: SEARCH_FILTER_FIELDS.CODE,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("applicationId"),
          handleOnChange: this.handleSearchIdChange,
          Value: this.state.LoanFilters.applicationId,
          fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID,
          column: 2,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MiniMartname"),
          handleOnChange: this.handleSearchNameChange,
          Value: this.state.LoanFilters.minimartName,
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("loanType"),
          handleOnChange: this.handleChangeTypeFilter,
          Value: this.state.LoanFilters.loanType,
          fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
          filterDropdownList: LOAN_TYPE,
          column: 2,
          paddingTop: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeStatusFilter,
          Value: this.state.LoanFilters.status,
          fieldname: SEARCH_FILTER_FIELDS.STATUS,
          filterDropdownList: STATUSES_OF_LOAN_FOR_OPERATIONS_OFFICER,
          paddingTop: true,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeremittanceStatusFilter,
          Value: this.state.LoanFilters.remittanceStatus,
          fieldname: SEARCH_FILTER_FIELDS.REMITTANCE_STATUS,
          filterDropdownList:
            REMITTANCE_STATUSES_FOR_OPERATIONS_OFFICER,
          paddingTop: true,
        },
      ];
      if (
        (this.state.isOperationsOfficer &&
          this.props.loggedInUser.conciliationAccess) ||
        this.state.isOperationsModerator ||
        this.state.isOperationsManager
      ) {
        LoanFilters.push({
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeConciliationFilter,
          Value: this.state.LoanFilters.conciliationType,
          fieldname: SEARCH_FILTER_FIELDS.CONCILIATION_TYPE,
          filterDropdownList: CONCILIATION_TYPE_LIST,
          paddingTop: true,
        });
      }
      (this.state.isOperationsModerator
        || this.state.isOperationsManager) &&
        LoanFilters.push({
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleSearchUserNameChange,
          Value: this.state.LoanFilters.userName,
          fieldname: SEARCH_FILTER_FIELDS.OPERATION_OFFICER_NAME,
          filterDropdownList: this.props.userList,
          column: 2,
          paddingTop: 2,
        });
    } else {
      LoanFilters = [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          handleOnChange: this.handleChangefromDate,
          fromDate: this.state.LoanFilters.fromDate,
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.LoanFilters.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
          column: 4,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchMatricesIdChange,
          Value: this.state.LoanFilters.matricesId,
          fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartCode"),
          handleOnChange: this.handleSearchCICodeChange,
          Value: this.state.LoanFilters.minimartCode,
          fieldname: SEARCH_FILTER_FIELDS.CODE,
          column: 2,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("applicationId"),
          handleOnChange: this.handleSearchIdChange,
          Value: this.state.LoanFilters.applicationId,
          fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID,
          column: 2,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MiniMartname"),
          handleOnChange: this.handleSearchNameChange,
          Value: this.state.LoanFilters.minimartName,
          fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
          column: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("loanType"),
          handleOnChange: this.handleChangeTypeFilter,
          Value: this.state.LoanFilters.loanType,
          fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
          filterDropdownList: LOAN_TYPE,
          column: 2,
          paddingTop: 2,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleChangeStatusFilter,
          Value: this.state.LoanFilters.status,
          fieldname: SEARCH_FILTER_FIELDS.STATUS,
          filterDropdownList: STATUS_FOR_LOANS,
          paddingTop: true,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          disabled: this.state.disabled,
          handleOnChange: this.handleChangeCategoryFilter,
          Value: this.state.LoanFilters.category,
          fieldname: SEARCH_FILTER_FIELDS.CATEGORY,
          column: 2,
          filterDropdownList: CATEGORY,
          paddingTop: true,
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleProvisionFilter,
          Value: this.state.LoanFilters.provision,
          fieldname: SEARCH_FILTER_FIELDS.PROVISION,
          filterDropdownList: PROVISION_GRADES,
          paddingTop: true,
        },
      ];
    }

    return (
      <SearchFilter
        filters={LoanFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingRight={0}
        paddingTop={2}
        removeLeftPadding={
          this.state.isAnalyst ||
          this.state.isAccountsOfficer ||
          this.state.isAnalystModerator ||
          this.state.isAdmin ||
          this.state.isGeneralManager ||
          this.state.isOperationsOfficer ||
          this.state.isOperationsModerator
        }
      />
    );
  };

  renderCustomerTypeLegend = (
    heading,
    types,
    paddingRight,
    isOperationsOfficerLegened = true
  ) => {
    return (
      <CustomerTypeLegend
        heading={Strings(heading)}
        arrayOfTypes={types}
        paddingRight={paddingRight}
        isOperationsOfficerLegened={isOperationsOfficerLegened}
      />
    );
  };

  render() {
    return (
      <div className='loanList container-fluid ListContainer'>
        {this.renderSearchFilter()}

        {!this.state.isAnalyst &&
          !this.state.isAnalystModerator &&
          !this.state.isOperationsOfficer &&
          !this.state.isOperationsModerator &&
          !this.state.isOperationsManager &&
          !this.state.isAccountsOfficer &&
          !this.state.isAdmin &&
          !this.state.isGeneralManager
          ? this.renderCustomerTypeLegend(
            "ApplicationStatuses",
            GENERAL_LOAN_STATUS_LEGEND,
            4,
            false
          )
          : null}

        {this.props.featureName === "OPERATIONS_OFFICER_DASHBOARD" &&
          this.renderCustomerTypeLegend(
            "ApplicationStatuses",
            OPERATION_OFFICER_LOANS_STATUS_LEGENDS,
            0
          )}

        {(this.state.isAnalyst ||
          this.state.isOperationsOfficer ||
          this.state.isOperationsModerator ||
          this.state.isOperationsManager) &&
          this.renderCustomerTypeLegend(
            "reminderStatus",
            LOAN_REMINDER,
            0
          )}

        {!this.state.isAnalyst &&
          !this.state.isAnalystModerator &&
          !this.state.isOperationsOfficer &&
          !this.state.isOperationsModerator &&
          !this.state.isOperationsManager &&
          !this.state.isAccountsOfficer &&
          !this.state.isAdmin &&
          !this.state.isGeneralManager &&
          this.renderCustomerTypeLegend(
            "LoanCategories",
            LOAN_CATEGORY_LEGEND,
            4,
            false
          )}

        {this.props.loans.length > 0 ? (
          <Row className='cardAlign'>
            <Col md={12} className='w-100 pr-0'>
              <Row className='w-100'>
                {this.props.loans.map((card) => {
                  return (
                    <CustomCard
                      card={card}
                      cardType='loan'
                      key={Math.random()}
                      handleCardClick={
                        !this.state.isGeneralManager &&
                          !this.state.isAdmin
                          ? this.props.setLoanDetailsID
                          : () => {
                            // This is intentional
                          }
                      }
                      isOperationsOfficer={
                        this.state.isOperationsOfficer
                      }
                      isHistory={false}
                      readOnly={
                        this.state.isGeneralManager ||
                        this.state.isAdmin
                      }
                      isAnalystModerator={
                        this.state.isAnalystModerator
                      }
                      isOperationsModerator={
                        this.state.isOperationsModerator
                      }
                      isAnalyst={this.state.isAnalyst}
                    />
                  );
                })}
              </Row>
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </Row>
        ) : (
          <div className='noRecordsFound noBranchRecords'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default LoanApproval;

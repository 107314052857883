import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getLoanRequestListAction = (reqObj, callback) => {
  callAPI(
    "getLoanRequestList",
    API.getLoanRequestList,
    "GET",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateLoanRequestAction = (reqObj, callback) => {
  callAPI(
    "updateLoanRequest",
    API.updateLoanRequest,
    "PATCH",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import {
  SET_LOAN_DETAILS_ACTION,
  SET_ANALYST_LOAN_DETAILS_ACTION,
  SET_EMI_CONFIGURATION_ACTION
} from "../../redux/constants";

const INITIAL_STATE = {
  loanDetails: null,
  isHistory: false,
};

export default function LoanDetailsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_LOAN_DETAILS_ACTION:
      return {
        ...state,
        loanDetails: action.loanDetails,
      };
    case SET_ANALYST_LOAN_DETAILS_ACTION:
      return {
        ...state,
        analystLoanDetails: action.loanDetails,
        isHistory: action.isHistory,
      };
    case SET_EMI_CONFIGURATION_ACTION:
      return {
        ...state,
        loanConfig: action.loanConfig,
        levelConfig: action.levelConfig,
      };

    default:
      return state;
  }
}

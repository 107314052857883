import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Utility
import Utility from "../../utils/Utility";

export const getApprovalLoansBySearchFilterAction = (
  searchFilterObject,
  callback,
  isAnalyst = false,
  isOperationsOfficer = false,
  isOperationsModerator = false,
  isAccountingOfficer = false,
  isAnalystModerator = false,
  isAuditor = false,
  isGeneralManager = false,
  isAdmin = false,
  isCustomerService = false,
  isOperationsManager = false,
  issalesManager = false,
  featureName = ""
) => {
  //NOSONAR
  let requestData = {
    loanSearchCriteria: {
      code: searchFilterObject.search.minimartCode
        ? searchFilterObject.search.minimartCode
        : undefined,
      fromDate: searchFilterObject.search.fromDate
        ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.fromDate
        )
        : undefined,
      toDate: searchFilterObject.search.toDate
        ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.toDate
        )
        : undefined,
      status: searchFilterObject.search.status
        ? searchFilterObject.search.status
        : undefined,
      applicationId: searchFilterObject.search.applicationId
        ? searchFilterObject.search.applicationId
        : undefined,
      matricesId: searchFilterObject.search.matricesId
        ? searchFilterObject.search.matricesId
        : undefined,
      minimartName: searchFilterObject.search.minimartName
        ? searchFilterObject.search.minimartName
        : undefined,
      userId: searchFilterObject.search.userName
        ? searchFilterObject.search.userName
        : undefined,
      type: searchFilterObject.search.loanType
        ? searchFilterObject.search.loanType
        : undefined,
      provision: searchFilterObject.search.provision
        ? searchFilterObject.search.provision
        : undefined,
      category:
        searchFilterObject.search.category !== ""
          ? searchFilterObject.search.category
          : undefined,
      remittanceStatus:
        searchFilterObject.search.remittanceStatus !== ""
          ? searchFilterObject.search.remittanceStatus
          : undefined,
      conciliationType:
        searchFilterObject.search.conciliationType !== ""
          ? searchFilterObject.search.conciliationType
          : undefined,
    },
    limit: searchFilterObject.limit
      ? searchFilterObject.limit
      : DEFAULT_PAGINATION_RECORDS_COUNT,
    offset: searchFilterObject.offset
      ? searchFilterObject.offset - 1
      : DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
  };

  let apiURL = "";
  if (
    isAnalyst ||
    isAnalystModerator ||
    isAdmin ||
    isGeneralManager
  ) {
    apiURL = API.getAnalystsLoansBySearchfilter;
  } else if (isOperationsOfficer) {
    apiURL = API.getOperationsOfficersLoanSearchFilter;
  }
  if (isOperationsModerator ||
    isCustomerService ||
    isOperationsManager ||
    issalesManager) {
    apiURL =
      featureName === "ANALYST_DASHBOARD"
        ? API.getAnalystsLoansBySearchfilter
        : API.getOperationsOfficersLoanSearchFilter;
  } else if (isAccountingOfficer || isAuditor) {
    apiURL = API.getAccountingOfficersLoanSearchFilter;
  }

  callAPI(
    "getApprovalLoansBySearchFilter",
    apiURL,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

// Constants
import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import { SET_SIGN_UP_MINIMART } from "../../redux/constants";

export const getSignUpRequestListAction = (request, callback) => {
  let requestData = { ...request };
  requestData.oft -= 1;
  callAPI(
    "getSignUpRequestList",
    API.getSignUpRequestList,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setMiniMartDetailsAction = (minimart) => (dispatch) => {
  dispatch({
    type: SET_SIGN_UP_MINIMART,
    minimart,
  });
};

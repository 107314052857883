import { Strings } from "../../resources/i18n/i18n";

export const TABLE_COLUMNS = {
  SALES_PERSON: [
    {
      key: "br",
      name: Strings("Branch"),
      minWidth: 0,
    },
    {
      key: "zone",
      name: Strings("Zone"),
      minWidth: 200,
    },
    {
      key: "salesPosition",
      name: Strings("salesPersonPostion"),
      minWidth: 200,
    },
  ],
  MESSENGER: [
    {
      key: "br",
      name: Strings("Branch"),
      minWidth: 0,
    },
    {
      key: "zone",
      name: Strings("mzone"),
      minWidth: 200,
    },
    {
      key: "messengerPosition",
      name: Strings("messengerPositionText"),
      minWidth: 200,
    },
  ],
};

export const ZONE_POSITION_USERS = [
  {
    key: 0,
    value: "",
    label: Strings("username") + " " + Strings("type"),
  },
  {
    key: 1,
    value: "SALES_PERSON", //Monday, Wednesday, Friday
    label: Strings("SALES_PERSON"),
  },
  {
    key: 2,
    value: "MESSENGER", //Tuesday, Thursday, Saturday
    label: Strings("MESSENGER"),
  },
];

export const ZONE_POSITION_USER_ROLES = [
  {
    key: "selectPosition",
    value: "",
  },
  {
    key: "salesPositions",
    value: "SALES_PERSON",
  },
  {
    key: "messengerPosition",
    value: "MESSENGER",
  },
];

export const DailyLoanArray = ["generatedLoans", "deniedLoans"];

export const MessengerPIeLoanArray = [
  "collectedLoans",
  "deniedLoans",
  "pendingLoans",
];

export const LEGEND_KEY_CONST = {
  AMOUNT: "amount",
  SALES_LOANS: "salesLoans",
  LOANS: "loans",
};

import React from "react";

import { TablePagination } from "../@trendmicro/react-paginations/lib";

// Strings
import { Strings } from "../../../resources/i18n/i18n";

import {
  DEFAULT_PAGINATION_RECORDS_ARRAY,
  ANALYST_MINIMART_LOANS_PAGINATION_RECORDS_ARRAY,
} from "./PaginationConstants";
// Be sure to include styles at some point, probably during your bootstraping
import "@trendmicro/react-paginations/dist/react-paginations.css";
import "./Pagination.scss";

import prev from "../../../resources/images/ic_previous.svg";
import next from "../../../resources/images/ic_next.svg";

class Pagination extends React.Component {
  pageLengthRenderer = ({ pageLength }) => {
    return <span>{pageLength + " " + Strings("PerPage")}</span>;
  };

  render() {
    let totalRecords =
      this.props.recordsPerPage &&
      this.props.numberOfPages &&
      this.props.recordsPerPage * this.props.numberOfPages;

    return (
      <div className='pagination'>
        <TablePagination
          type='full'
          page={this.props.activePage}
          pageLength={this.props.recordsPerPage}
          pageLengthMenu={
            this.props.isAnalyst
              ? ANALYST_MINIMART_LOANS_PAGINATION_RECORDS_ARRAY
              : DEFAULT_PAGINATION_RECORDS_ARRAY
          }
          totalRecords={totalRecords}
          onPageChange={({ page, pageLength }) => {
            this.props.onPageChange({ page, pageLength });
          }}
          dropup={this.props.dropup}
          prevPageRenderer={() => (
            <img src={prev} alt='' className='btn100' />
          )}
          nextPageRenderer={() => (
            <img src={next} alt='' className='btn100' />
          )}
          pageLengthRenderer={this.pageLengthRenderer}
        />
      </div>
    );
  }
}

export default Pagination;

import React from "react";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import InvoiceApproval from "./InvoiceApproval";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

//Action
import { getInvoiceAction, approvedInvoiceAction } from "./Actions";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";

//Strings
import { Strings } from "../../resources/i18n/i18n";

class InvoiceApprovalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceList: [],
      numberOfPages: 0,
      showLoadingModal: false,
      successMessage: "",
      level: "",
      showToast: false,
    };
  }

  handleOnClickSearchFilter = (request) => {
    this.setState({ showLoadingModal: true });
    getInvoiceAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          invoiceList: response.data.data.rp,
          numberOfPages: response.data.data.np,
          showLoadingModal: false,
        });
      } else {
        this.setState({
          invoiceList: [],
          numberOfPages: 0,
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  handleApprove = (request, callback) => {
    this.setState({ showLoadingModal: true, showToast: false });
    approvedInvoiceAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showToast: true,
            level: "success",
            successMessage: Strings("invoice" + request.st),
            showLoadingModal: false,
          },
          callback
        );
      } else {
        this.setState({
          showToast: true,
          level: "error",
          successMessage: response.error.message,
          showLoadingModal: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  render() {
    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        {this.state.showToast && (
          <UserNotification
            key='Success'
            userNotificationObj={{
              message: Strings(this.state.successMessage),
              level: this.state.level,
            }}
          />
        )}
        <InvoiceApproval
          invoiceList={this.state.invoiceList}
          numberOfPages={this.state.numberOfPages}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleApprove={this.handleApprove}
        />
      </div>
    );
  }
}

export default InvoiceApprovalContainer;

import React, { useState, useEffect } from "react";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import { Row, Col, Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

// Constants
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  DEFAULT_DATE_FORMAT,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../constants/appConstants";

//Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";

//Strings
import { Strings } from "../../resources/i18n/i18n";

import Utility from "../../utils/Utility";

const getDateValue = (key, dateMoment, date) => {
  let DateValue = "";
  switch (key) {
    case DATE_TYPE.FROM_DATE:
      if (dateMoment.isAfter(date.toDate)) {
        DateValue = date.fromDate;
      } else {
        dateMoment.set({ hour: 0, minute: 0, second: 0 });
        DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
      }
      break;
    case DATE_TYPE.TO_DATE:
      if (dateMoment.isBefore(date.fromDate)) {
        DateValue =
          moment(dateMoment).diff(date.fromDate, "days") === 0
            ? date.fromDate
            : date.toDate;
      } else {
        DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
      }
      break;
    default:
  }
  return DateValue;
};

const PaymentReceipts = (props) => {
  const {
    paymentReceipts, // array of payment receipt records
    paymentReceiptsPages, // total number of pages
    downloadPaymentReceipt, // download payment receipt function
    downloadDownpaymentReceipt, //download downpayment receipt
    getAllPaymentReceipts,
    matricesId, //matrices Id for fetching records
  } = props;
  /**
   * date represents object for
   * fromDate and toDate
   */
  const [date, setDate] = useState({
    fromDate: new Date(new Date().setDate(new Date().getDate() - 7)), //default today's date -7
    toDate: new Date(), // default today's date
  });
  /**
   * paymentReceiptsPerPage is records to be shown per page
   */
  const [paymentReceiptsPerPage, setPaymentReceiptsPerPage] =
    useState(3);
  /**
   * activePage is page on which user is present currently
   */
  const [activePage, setActivePage] = useState(
    DEFAULT_PAGINATION_ACTIVE_PAGE
  );
  /**
   * step is integer
   * when 1 shows only date filter
   * when 2 shows the records table
   */
  const [step, setStep] = useState(1);
  /**
   *
   * @param {int} key represents
   * key can be fromDate and toDate
   * handleDateChange changes fromDate and
   * toDate value
   */
  const handleDateChange = (key) => (dateMoment) => {
    setDate(() => {
      return { ...date, [key]: getDateValue(key, dateMoment, date) };
    });
  };
  /**
   *
   * @param {boolean} defaultOffset
   * when true shows first page of payment receipts.
   * when false shows new page of payment receipts.
   */
  const handleOnClickSearchFilter = (defaultOffset) => {
    setStep(2);
    defaultOffset && setActivePage(DEFAULT_PAGINATION_ACTIVE_PAGE);
    let data = {
      search: {
        mid: matricesId,
        fdt: Utility.convertDateIntoMiliSeconds(date.fromDate),
        tdt: Utility.convertDateIntoMiliSeconds(date.toDate),
      },
      limit: paymentReceiptsPerPage,
      offset: defaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
        : activePage - 1,
    };
    getAllPaymentReceipts(data);
  };
  /**
   * fetches payment receipts for
   * current minimart for the
   * previous seven days
   */
  //This method will be invoked only once when the component mounts
  useEffect(() => {
    handleOnClickSearchFilter(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * handleResetValues changes
   * fromDate and toDate to previous
   */
  const handleResetValues = () => {
    setDate({
      fromDate: new Date(
        new Date().setDate(new Date().getDate() - 7)
      ),
      toDate: new Date(),
    });
    setStep(1);
  };
  /**
   * PaymentFilters is array
   * for SearchFilter component
   */
  const PaymentFilters = [
    {
      type: SEARCH_FILTER_TYPE.DATE,
      fromDate: date.fromDate,
      handleCalenderChange: handleDateChange,
      toDate: date.toDate,
      fieldname: SEARCH_FILTER_FIELDS.DATE,
    },
  ];
  /**
   * onPageChange makes navigating
   * from previous to next page
   * and vice-versa
   */
  const onPageChange = ({ page, pageLength }) => {
    setActivePage(page);
    setPaymentReceiptsPerPage(pageLength);
    handleOnClickSearchFilter(false);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#d5d5d5",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <div className='paymentReceipts'>
      <SearchFilter
        filters={PaymentFilters}
        handleOnClickSearchFilter={handleOnClickSearchFilter}
        handleOnClickResetFilter={handleResetValues}
        paddingRight={0}
      />
      <Row>
        <Col md={12} className=' errorText'>
          {props.errorMessage}
        </Col>
      </Row>
      {paymentReceipts && paymentReceipts.length > 0 && step === 2 ? (
        <React.Fragment>
          <div className='mx-3 mb-3'>
            <TableContainer component={Paper} className='w-25'>
              <Table aria-label='customized table' stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      className={"text-center px-2 header"}
                    >
                      {Strings("date")}
                    </StyledTableCell>
                    <StyledTableCell
                      className={"text-center px-2 header"}
                    >
                      {Strings("action")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentReceipts &&
                    paymentReceipts.length > 0 &&
                    step === 2 &&
                    paymentReceipts.map((receipt, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            className={"text-center px-2"}
                          >
                            {Utility.getDateFromMiliSeconds(
                              Utility.convertDateIntoMiliSeconds(
                                receipt.dt
                              )
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              "d-flex justify-content-center px-2"
                            }
                          >
                            <Button
                              className='addOrUpdateUserTopButtons alignText'
                              onClick={() =>
                                receipt.rt &&
                                receipt.rt === "DOWNPAYMENT"
                                  ? downloadDownpaymentReceipt(
                                      receipt.rid,
                                      receipt.aid
                                    )
                                  : downloadPaymentReceipt(
                                      receipt.rid
                                    )
                              }
                            >
                              <div
                                title={Strings("download")}
                                className='addButtonText'
                              >
                                {Strings("download")}
                              </div>
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <Pagination
            activePage={activePage}
            recordsPerPage={paymentReceiptsPerPage}
            numberOfPages={paymentReceiptsPages}
            onPageChange={onPageChange}
            dropup={true}
          />
        </React.Fragment>
      ) : (
        <div className='noRecordsFound'>
          {Strings("NoRecordsFound")}
        </div>
      )}
    </div>
  );
};

export default PaymentReceipts;

import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import NumberFormat from "react-number-format";

//Components
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import Calender from "../CustomUIComponents/Calender/Calender";
import MultipleSelectCheckmarks from "../CustomUIComponents/MultiSelectDropDown/MultipleSelectCheckmarks";
import Utility from "../../utils/Utility";

//Strings
import { Strings } from "../../resources/i18n/i18n";
//Constants
import { INVOICE_DATE_FORMAT } from "../../constants/appConstants";

//Styles
import "./LoanDetails.scss";
class EditableModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessage: props.errorMessage,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      open: nextProps.open,
      errorMessage: nextProps.errorMessage,
    };
  }

  //to close modal
  closeModal = () => {
    this.props.headerText === Strings("editConciliationDeatails")
      ?
      this.props.modalButtonCancel.onClick(true)
      :
      this.props.modalButtonCancel.onClick()
  };

  renderSelectionDropDown = (prop) => {
    return (
      <TextFieldComponent
        title=''
        id={prop.name}
        type={prop.type}
        className='noMargin'
        placeholder={prop.title}
        handleChange={this.props.onChange}
        value={prop.value}
        multiple={false}
        halfWidth={false}
        disabled={prop.disabled}
        name={prop.name}
      >
        {prop.isSelection &&
          prop.dropDownList &&
          prop.dropDownList.map((version) => {
            return (
              <option
                key={
                  version.value ||
                  version.label ||
                  version.name ||
                  version.key ||
                  version.pstid ||
                  version
                }
                value={
                  version.value ||
                  version.label ||
                  version.id ||
                  version.pstid ||
                  version.name ||
                  version

                }
                disabled={
                  this.props.ZONE_POSITION &&
                  version.pstid === prop.value
                }
              >
                {version.label ||
                  version.name ||
                  version.pstn ||
                  Strings(version)}
              </option>
            );
          })}
      </TextFieldComponent>
    );
  };

  renderNumberTextFields = (prop) => {
    return (
      <>
        <NumberFormat
          name={prop.name}
          className='form-control w-100'
          onValueChange={(event) => {
            const target = {
              name: prop.name,
              value: event.value ? event.value : "",
            };
            if (prop.name === "upi") {
              target.aid = prop.aid;
            }
            this.props.onChange({ target });
          }}
          allowLeadingZeros
          value={prop.value}
          type='text'
          placeholder={Strings(prop.title)}
          allowNegative={false}
          disabled={prop.disabled}
          isAllowed={
            prop.isAllowed
              ? prop.isAllowed
              : () => {
                return true;
              }
          }
          maxLength={prop.maxLength}
        />
        {prop.name === "mobileNo" && (
          <>
            <Row className='pt-2 pb-1'>
              <span>{Strings("whatsAppNum")}</span>
            </Row>
            <input
              type='radio'
              checked={this.props.isWhatsAppNo}
              name='isWhatsAppNo'
              onChange={() =>
                this.props.onChange({
                  target: {
                    name: "isWhatsAppNo",
                    value: true,
                  },
                })
              }
              disabled={this.props.readOnly}
            />
            <label className='radioLabel px-2'>
              {Strings("Yes")}
            </label>
            <input
              type='radio'
              checked={!this.props.isWhatsAppNo}
              name='isWhatsAppNo'
              onChange={() =>
                this.props.onChange({
                  target: {
                    name: "isWhatsAppNo",
                    value: false,
                  },
                })
              }
              disabled={this.props.readOnly}
            />
            <label className='radioLabel px-2'>{Strings("No")}</label>
          </>
        )}
      </>
    );
  };

  renderDate = (prop) => {
    return (
      <Calender
        handleChange={prop.handleDateChange}
        value={prop.value}
        dateFormat={INVOICE_DATE_FORMAT}
        isBefore={prop.isBefore}
        isAfter={prop.isAfter}
      />
    );
  };

  renderMultiSelect = (prop) => {
    return (
      <MultipleSelectCheckmarks
        itemList={prop.dropDownList}
        placeHolder={Strings(prop.title)}
        multiple={true}
        selectedValues={prop.value}
        handleChange={this.props.onChange}
        name={prop.name}
        NO_TAG_FIELDS={true}
      />
    )
  }

  renderApproveMinimartDetails = (editableData) => {
    return (
      <Row className='subHeaderApplication addOrUpdateUserFontSize pl-3'>

        {" "}
        {editableData.map((prop) => {
          let renderTextField;
          if (prop.isDate) {
            renderTextField = this.renderDate(prop);
          } else if (prop.isSelection) {
            renderTextField = this.renderSelectionDropDown(prop);
          } else if (prop.type === "number") {
            renderTextField = this.renderNumberTextFields(prop)
          } else if (prop.isSelect === "multiSelect") {
            renderTextField = this.renderMultiSelect(prop)
          }
          else {
            renderTextField = <TextFieldComponent
              id={prop.name}
              type={prop.type}
              className='noMargin'
              placeholder={Strings(prop.title)}
              value={prop.value ? prop.value : ""} //NOSONAR
              handleChange={this.props.onChange}
              name={prop.name}
              disabled={prop.disabled}
              maxLength={prop.maxLength}
            />
          }
          return (
            <Col
              key={prop.name}
              md={{ span: prop.md ? prop.md : 6 }}
              className='pl-0 pr-3'
            >
              <Row>
                <Col md={{ span: 12 }} className='px-0 pt-3'>
                  {" "}
                  <span className={prop.className ? prop.className : ""}>{Strings(prop.title)}</span>
                  {prop.isMandatoryField && (
                    <span className='mandatoryFields'>*</span>
                  )}
                </Col>
                <Col
                  md={{ span: 12 }}
                  className={
                    prop.name === "aid" ? "px-0 pt-4" : "px-0 pt-2"
                  }
                >
                  {renderTextField}
                </Col>
              </Row>{" "}
            </Col>
          );
        })}
      </Row>
    );
  };

  renderRemittanceDelivery = (approvedAmount) => {
    return (
      <>
        <Row>
          <span>
            {Strings("remittanceDeliveryPopupMessage").replace(
              "#",
              approvedAmount
            ) +
              moment().format(INVOICE_DATE_FORMAT) +
              "."}
          </span>
        </Row>
        {this.renderApproveMinimartDetails(this.props.editableData)}
      </>
    );
  };

  renderConciliationDetails = (editableData) => {

    const safeSum = (sum, value) => sum + (Number.isNaN(value) ? 0 : value);

    const safeValue = (value) => (Number.isNaN(value) ? 0 : value);

    const totalUpc = editableData.reduce((sum, item) => safeSum(sum, item.upc), 0);
    const totalUplf = editableData.reduce((sum, item) => safeSum(sum, item.uplf), 0);
    const totalUpi = editableData.reduce((sum, item) => safeSum(sum, item.upi), 0);
    const totalApamt = editableData.reduce((sum, item) => safeSum(sum, item.apamt), 0) / editableData.length;
    const totalDebt = editableData.reduce((sum, item) => safeSum(sum, item.upc + item.uplf + safeValue(item.upi)), 0);

    const totalAtmAmount = totalApamt - totalDebt;


    const rows = [
      { label: Strings("unpaidCapital"), key: "upc", total: totalUpc },
      { label: Strings("unPaidInterest"), key: "upi", total: totalUpi },
      { label: Strings("unpaidLateFees"), key: "uplf", total: totalUplf },
      {
        label: Strings("totalDebt"),
        key: (item) => safeValue(item.upc) + (Number.isNaN(item.uplf) ? 0 : item.uplf) + (Number.isNaN(item.upi) ? 0 : item.upi),
        total: totalDebt
      }, { label: Strings("atmAmount"), key: "atm", total: totalAtmAmount },
    ];
    return (
      <div className="conciliationDetails pt-2 pl-3 pr-3">
        <Row className="justify-content-end pr-2 pb-3">
          {Strings("approvedAmount")} : {totalApamt}
        </Row>
        <Table className="conciliationDetailsTable" striped bordered hover responsive>
          <thead>
            <tr>
              <th ></th>
              {editableData.map((item, index) => (
                <th className="tableData" key={index} >
                  {Strings("applicationId")}: {item.aid}
                </th>
              ))}
              {editableData.length > 1 && <th className="tableData">{Strings("Summary")}</th>}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td >{row.label}</td>
                {editableData.map((item, index) => (
                  <td className="tableData" key={index}>
                    {row.key === 'upi' ? (
                      <NumberFormat
                        name='upi'
                        className='form-control w-100'
                        onValueChange={(event) => {
                          const target = {
                            name: 'upi',
                            value: event.value ? event.value : "",
                            aid: item.aid
                          };
                          this.props.onChange({ target });
                        }}
                        allowLeadingZeros
                        value={item.upi}
                        type='text'
                        placeholder={Strings("unPaidInterest")}
                        allowNegative={false}
                        disabled={false}
                        isAllowed={
                          ({ floatValue }) => item.apamt > item.upc + item.uplf + floatValue
                            ? ({ floatValue }) => item.apamt > item.upc + item.uplf + floatValue
                            : () => {
                              return true;
                            }
                        }
                        maxLength={5}
                      />

                    ) : (
                      typeof row.key === 'function'
                        ? row.key(item)
                        : row.key === "atm"
                          ? editableData.length > 1 ? '-' : row.total
                          : item[row.key]
                    )}
                  </td>
                ))}
                {editableData.length > 1 && <td className="tableData">{row.total}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

    )

  }


  render() {

    return (
      <div>
        <Modal
          // Change visibility of modal based on props.
          open={this.props.open}
          onClose={this.closeModal}
          //Modal opens with specified messages in props
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          showCloseIcon={this.props.modalShowCloseIcon}
          classNames={{
            modal: this.props.className
              ? `loanDetailModal ${this.props.className}`
              : "loanDetailModal modalBorder"
          }}
          center
          focusTrapped={false}
        >
          <Row className={"loanDetailModal"}>
            <Col md={12} className='loanDetailModalHeader pl-3'>
              {this.props.headerText}
            </Col>
            {/* Show only specified props of modal */}
            <Col md={12} className='modalContent pt-3'>
              {this.props.errorMessage && (
                <Row className='errors p-0'>
                  {Strings(this.props.errorMessage)}
                </Row>
              )}
              {this.props.currentCollectionCycle && <div className="warningText">
                {`${Strings("currentCollectionCycle")} : ${Utility.convertCollectionDaysToSpanish(this.props.currentCollectionCycle.split(","))}`}
              </div>}
              {this.props.isRemittanceDelivery
                ? this.renderRemittanceDelivery(this.props.remitanceAmount)
                : this.props.isConciliationEdit
                  ? this.renderConciliationDetails(this.props.editableData)
                  : this.renderApproveMinimartDetails(
                    this.props.editableData
                  )}
              <div className='loanDetailModalButton'>
                {this.props.modalButtonCancel && (
                  <Button
                    className={
                      "primaryModalButton buttonMinWidth marginCancelButton buttonBorder " +
                      this.props.modalButtonCancelStyle
                    }
                    onClick={this.closeModal}
                  >
                    {this.props.modalButtonCancel.text}
                  </Button>
                )}
                <Button
                  className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
                  onClick={
                    this.props.ZONE_POSITION
                      ? this.props.modalButtonOk.onClick
                      : this.props.SubmitMinimartDetails
                  }
                  disabled={
                    this.props.isActionButtonDisable ||
                      (this.props.ZONE_POSITION &&
                        this.props.errorMessage)
                      ? true
                      : false
                  }
                >
                  {this.props.modalButtonOk.text}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
EditableModal.propTypes = {
  isRemittanceDelivery: PropTypes.bool,
};

EditableModal.defaultProps = {
  isRemittanceDelivery: false,
};

export default EditableModal;

import { API } from "../../../constants/APIConstants";
import { callAPI } from "../../../config/AxiosTemplate";

export const getPaymentModesAction = (callback) => {
    callAPI(
        "getPaymentModes",
        API.getPaymentModes,
        "GET",
        {},
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const getMinimartCollectionDetailsAction = (minimartId, callback) => {
    callAPI(
        "getMinimartCollectionDetails",
        API.getMinimartCollectionDetails + minimartId,
        "GET",
        {},
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const getDownPaymentDetailsAction = (minimartId, callback) => {
    callAPI(
        "getDownPaymentDetails",
        API.getDownPaymentDetails + minimartId,
        "GET",
        {},
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const uploadTransferReceiptOfPaymentCollectionAction = (request, callback) => {
    let requestInfo = new FormData();
    requestInfo.append("minimartId", request.minimartId);
    requestInfo.append("file", request.file);
    requestInfo.append("collectionType", request.collectionType);
    callAPI(
        "uploadTransferReceiptOfPaymentCollection",
        API.uploadTransferReceiptOfPaymentCollection,
        "POST",
        requestInfo,
        (apiResponse) => {
            callback(apiResponse);
        }, false, true
    );
}

export const viewUploadedTranferReceipt = (transferReceiptId, callback) => {
    callAPI(
        "viewUploadedTranferReceipt",
        API.viewUploadedTranferReceipt + transferReceiptId,
        "GET",
        {},
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const collectPaymentAction = (request, callback) => {
    callAPI(
        "collectPayment",
        API.collectPayment,
        "POST",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const collectDownpaymentAction = (request, callback) => {
    callAPI(
        "collectDownpayment",
        API.collectDownpayment,
        "POST",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const assignSellerAfterPayOffAction = (request, callback) => {
    callAPI(
        "assignSellerAfterPayOff",
        API.assignSellerAfterPayOff,
        "POST",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};
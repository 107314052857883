//libraries
import React, { Component } from "react";

//components
import Promotions from "./Promotions.js";
import { Row } from "react-bootstrap";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

//actions
import {
  getPromotionsListAction,
  addNewPromotionAction,
  updatePromotionAction,
  deletePromotionAction,
  getProductListAction,
} from "./PromotionsActions";

//constants
import { Strings } from "../../resources/i18n/i18n";
import { OPERATION_STATUS } from "../../config/axios.init";

//utilities
import Utility from "../../utils/Utility";

//stylesheets NOSONAR

class PromotionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promotionList: [],
      productList: [],
      errorMessage: "",
      toShowToast: false,
      toastMessage: "",
      showLoader: false,
    };
  }

  componentDidMount() {
    this.getProductList();
  }

  getPromotionList = (requestData) => {
    this.setState({
      showLoader: true,
    });
    getPromotionsListAction({ ...requestData }, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let promotionList = [];
        for (const promo of response.data.data.rp) {
          let promotion = { ...promo };
          if (promotion.s3url) {
            // S3 URL decryption
            promotion.file = Utility.getDecryptedData(
              promotion.s3url
            );
          }
          promotionList.push(promotion);
        }
        this.setState({
          promotionList,
          errorMessage: "",
          numberOfPages: response.data.data.np,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.errorMessage,
          showLoader: false,
        });
      }
    });
  };

  addNewPromotion = (
    reqObj,
    searchFilter,
    togglePromotionCallback
  ) => {
    this.setState({
      showLoader: true,
    });
    addNewPromotionAction(reqObj, (response) => {
      this.setStateAndError(
        response,
        searchFilter,
        togglePromotionCallback
      );
    });
  };

  updatePromotion = (
    reqObj,
    searchFilter,
    togglePromotionCallback
  ) => {
    this.setState({
      showLoader: true,
    });
    updatePromotionAction(reqObj, (response) => {
      //NOSONAR
      this.setStateAndError(
        response,
        searchFilter,
        togglePromotionCallback
      );
    });
  };

  deletePromotion = (searchFilter, deletePromoId) => {
    this.setState({
      showLoader: true,
    });
    deletePromotionAction({ id: deletePromoId }, (response) => {
      this.setStateAndError(response, searchFilter);
    });
  };

  setStateAndError = (
    response,
    searchFilter,
    togglePromotionCallback = () => {
      /* this is intentional */
    }
  ) => {
    if (response.status === OPERATION_STATUS.SUCCESS) {
      this.setState({
        errorMessage: "",
        toShowToast: true,
        level: "success",
        toastMessage: Strings("DataUpdatedSuccessfully"),
        showLoader: false,
      });
      this.getPromotionList(searchFilter);
      togglePromotionCallback(true);
    } else {
      this.setState({
        errorMessage: response.error.errorMessage,
        toShowToast: true,
        level: "error",
        toastMessage: Strings("SorryAnErrorHasOccurred"),
        showLoader: false,
      });
      togglePromotionCallback(false);
    }
  };

  //get product list for edit promotions dropdown
  getProductList = () => {
    this.setState({
      showLoader: true,
    });
    getProductListAction({}, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          productList: response.data.data,
          errorMessage: "",
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.errorMessage,
          showLoader: false,
          productList: [],
        });
      }
    });
  };

  render() {
    return (
      <>
        <Row className='heading'>{Strings("Promotions")}</Row>

        {this.state.toShowToast && (
          <UserNotification
            key={this.state.toastMessage}
            userNotificationObj={{
              message: this.state.toastMessage,
              level: this.state.level,
            }}
          />
        )}
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <Promotions
          promotionList={this.state.promotionList}
          errorMessage={this.state.errorMessage}
          handleOnClickSearchFilter={this.getPromotionList}
          productList={this.state.productList}
          updatePromotion={this.updatePromotion}
          openDeleteModal={this.openDeleteModal}
          addNewPromotion={this.addNewPromotion}
          actionResponse={this.state.level}
          numberOfPages={this.state.numberOfPages}
          deletePromotion={this.deletePromotion}
        />
      </>
    );
  }
}

export default PromotionsContainer;

// Constants
import {
  SET_USER_DETAILS_ACTION,
  CLEAR_USER_DETAILS_ACTION,
  SET_USER_ROLES_ACTION,
  CLEAR_USER_ROLES_ACTION,
  SET_USERS_ACTION,
  CLEAR_USER_REDUCER_ACTION,
  SET_ADD_USER_FLAG,
} from "../../redux/constants";

const INITIAL_STATE = {
  userDetails: {
    active: false,
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    photo: "defaultImage",
    role: "",
    username: "",
    branch: [],
    reportingToUserId: "",
    type: null,
    zones: [],
    collectionOfficer: "",
    position: "",
  },
  userRoles: null,
  users: [],
  isAddUser: false,
};

export default function UserListReducer(state = INITIAL_STATE, action) {
  //NOSONAR -default parameters are required first
  switch (action.type) {
    case SET_USER_DETAILS_ACTION:
      return {
        ...state,
        userDetails: action.userDetails,
      };

    case SET_USERS_ACTION:
      return {
        ...state,
        users: action.users,
      };

    case SET_USER_ROLES_ACTION:
      return {
        ...state,
        userRoles: action.userRoles,
      };
    case SET_ADD_USER_FLAG:
      return {
        ...state,
        isAddUser: action.isAddUser,
      };

    case CLEAR_USER_DETAILS_ACTION:
      return {
        ...state,
        userDetails: INITIAL_STATE.userDetails,
      };

    case CLEAR_USER_ROLES_ACTION:
      return {
        ...state,
        userRoles: INITIAL_STATE.userRoles,
      };

    case CLEAR_USER_REDUCER_ACTION:
      return {
        ...state,
        users: [],
      };
    default:
      return state;
  }
}

import React, { Component } from "react";

import { Strings } from "../../resources/i18n/i18n";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "../CustomUIComponents/InputModal/InputModal.scss";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

class BankModal extends Component {
    renderModalButtons = () => {
        return (
            <Col md={12}>
                <Button
                    className='buttonBackground primaryModalButton buttonMinWidth'
                    onClick={this.props.handleOkClick}
                >
                    {Strings("Set")}
                </Button>

                <Button
                    className={
                        " primaryModalButton buttonMinWidth marginCancelButton buttonBorder"
                    }
                    onClick={this.props.closeModal}
                >
                    {Strings("Cancel")}
                </Button>
            </Col>
        );
    };


    render() {
        return (
            <div>
                {/* general modal */}
                <Modal
                    // Change visibility of modal based on props.
                    open={this.props.openModal}
                    onClose={this.props.closeModal}
                    closeOnEsc={false}
                    closeOnOverlayClick={false}
                    classNames={{ modal: "customModal" }}
                    showCloseIcon={false}
                    center
                >
                    <Row className='inputModal'>
                        <Col md={12} className='headerModal'>
                            {this.props.modalHeader}
                        </Col>
                        <Col md={12} className='modalContent mt-3'>
                            {this.props.data}

                            <Row className='modalButton'>
                                {this.renderModalButtons()}
                            </Row>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}


export default BankModal;

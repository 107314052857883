import { Strings } from "../../resources/i18n/i18n";

export const SALESPERSON_EFFICIENCY_SUMMARY_COLUMNS = [
  {
    key: "fn",
    name: Strings("Name"),
    minWidth: 150,
  },
  {
    key: "vm",
    name: `${Strings("visitedMinimarts")} / ${Strings(
      "assignedMinimarts"
    )}`,
    minWidth: 100,
  },
  {
    key: "glc",
    name: `${Strings("loanCount")} ${Strings("createdLoanCount")}`,
    minWidth: 200,
  },
  {
    key: "glm",
    name: Strings("loanAmount") + " ($)",
    minWidth: 150,
  },
  {
    key: "amtd",
    name: Strings("activeMinimart"),
    minWidth: 100,
  },
  {
    key: "crat",
    name: Strings("ConversionRate"),
    minWidth: 100,
  },
  {
    key: "ple",
    name: Strings("disbursementEfficiency"),
    minWidth: 100,
  },
  {
    key: "rat",
    name: Strings("userRating"),
    minWidth: 150,
  },
];

export const KEYS = {
  BRANCHID: "branchId",
};

export const SPED_LOAN_LEGEND = [
  "inProcess",
  "disbursedLoanCount",
  "deniedLoans",
  "pendingLoans",
];

// Constants
import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getLocationDataAction = (requestDataObj, callback) => {
  callAPI(
    "getMapData",
    API.getLocationData,
    "POST",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getMapDataAction = (requestDataObj, callback) => {
  callAPI(
    "getMapData",
    API.getMapData.concat(requestDataObj),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";
import {
  SET_REPORTS_FILTERS_ACTION,
  SET_REPORTS_ID_ACTION,
  SET_REPORTS_ACTION,
  CLEAR_REPORTS_FILTERS_ACTION,
  SET_TWO_SETP_ACTION,
} from "../../redux/constants";

import { DATE_KEYS } from "./Constants";

//Utility
import Utility from "../../utils/Utility";

//action with dispatch for storing data in reducer

export const setFirstLevelReportsFiltersAction =
  (filters) => (dispatch) => {
    dispatch({
      type: SET_REPORTS_FILTERS_ACTION,
      filters: filters,
    });
  };

export const setUserIdAction = (userId) => (dispatch) => {
  dispatch({
    type: SET_REPORTS_ID_ACTION,
    userId: userId,
  });
};

export const SetReportsDetailsAction = (report) => (dispatch) => {
  dispatch({
    type: SET_REPORTS_ACTION,
    reports: report,
  });
};

export const getFirstLevelReportsDataAction = (
  url,
  requestData,
  callback
) => {
  if (requestData.offset) {
    requestData.offset -= 1;
  }
  if (requestData.hasOwnProperty("search")) {
    let requestKeys = Object.keys(requestData.search);
    requestKeys.forEach((key) => {
      requestData.search[key] =
        DATE_KEYS.includes(key) &&
        requestData.search[key] !== undefined
          ? Utility.convertDateIntoMiliSeconds(
              requestData.search[key]
            )
          : requestData.search[key];
    });
  } else {
    let requestKeys = Object.keys(requestData);
    requestKeys.forEach((key) => {
      requestData[key] =
        DATE_KEYS.includes(key) && requestData[key] !== undefined
          ? Utility.convertDateIntoMiliSeconds(requestData[key])
          : requestData[key];
    });
  }
  callAPI(
    "getFirstLevelReportsDataAction",
    API.getReports.concat(url),
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getUserReportsProfileAction = (userName, callback) => {
  callAPI(
    "GetUserProfile" + userName,
    API.getUserProfile.replace("username", userName),
    "GET",
    null,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const ClearReportsReducerAction =
  (reports = null) =>
  (dispatch) => {
    dispatch({
      type: CLEAR_REPORTS_FILTERS_ACTION,
      reports: reports,
    });
  };

export const setTwoStepAction = (twoStepAction) => (dispatch) => {
  dispatch({
    type: SET_TWO_SETP_ACTION,
    twoStepAction,
  });
};

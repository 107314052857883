// Constants
import {
  SET_DATA_FOR_EDIT_CONFIGURATION,
  CLEAR_DATA_FOR_EDIT_CONFIGURATION,
} from "../../redux/constants";

const INITIAL_STATE = {
  EditConfigurationData: { version: "" },
};

export default function CustomConfigurationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DATA_FOR_EDIT_CONFIGURATION:
      return {
        ...state,
        EditConfigurationData: { ...action.EditConfigurationData },
      };

    case CLEAR_DATA_FOR_EDIT_CONFIGURATION:
      return {
        ...state,
        EditConfigurationData: {
          ...INITIAL_STATE.EditConfigurationData,
        },
      };
    default:
      return state;
  }
}

import React from "react";

//Styles
import "./Footer.scss";

//Components
import { Col } from "react-bootstrap";

//Strings
import { Strings } from "../../resources/i18n/i18n";

class Footer extends React.Component {
  render() {
    let copyrightText=Strings("LoginCopyrightText").replace('$',new Date().getFullYear());
    return (
      <Col md={12} className='copyright'>
        <div className='Copyright_text'>{Strings(copyrightText)}</div>
      </Col>
    );
  }
}
export default Footer;

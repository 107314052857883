import React, { Component } from "react";
import ConfigurationType from "./ConfigurationType";
import _ from "lodash";

class CreditScoreConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.configurationData.id,
      type: props.type,

      configurationData: null,
      errorMessage: "",
      successMessage: "",
      notificationSuccess: false,
      notificationFailure: false,
      openModal: false,
    };
    this.errorRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    let derivedState = state;
    if (
      props.configurationData &&
      state.configurationData !== props.configurationData
    ) {
      derivedState = {
        ...derivedState,
        configurationData: props.configurationData,
      };
    }
    if (
      props.notificationFailure !== state.notificationFailure ||
      props.notificationSuccess !== state.notificationSuccess
    ) {
      derivedState = {
        ...derivedState,
        notificaionFailure: props.notificaionFailure,
        notificationSuccess: props.notificationSuccess,
      };
    }
    return derivedState;
  }

  getFilteredmapping = () => {
    let assignedMappings = [];
    _.map(
      this.state.configurationData.configurationObject.type,
      (configType) => {
        _.map(configType.subType, (subtype) => {
          assignedMappings.push(subtype.mapping);
        });
      }
    );
    let allMappings = _.map(this.props.creditscoreMapping, "key");
    return _.difference(allMappings, assignedMappings);
  };

  render() {
    let filteredmappings =
      this.state.configurationData && this.getFilteredmapping();

    return (
      <div>
        <div className='errors' ref={this.props.errorRef}>
          <span>{this.props.errorMessage}</span>
        </div>
        {this.state.configurationData.configurationObject.type.map(
          (configurationType, index) => {
            return (
              <ConfigurationType
                configurationType={configurationType}
                key={index}
                index={index}
                Editable={!this.props.readOnly}
                handleChangeValue={this.props.handleChangeValue}
                handleOnClickAction={this.props.handleOnClickAction}
                mappings={this.props.creditscoreMapping}
                editSubtypeData={this.props.editSubtypeData}
                filteredmappings={filteredmappings}
                resetError={this.props.resetError}
              />
            );
          }
        )}
      </div>
    );
  }
}

export default CreditScoreConfiguration;

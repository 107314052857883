import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getClientStatesAction = (
    callback
) => {
    callAPI(
        "getClientStates",
        API.clientStates,
        "GET",
        {},
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};
export const getIdentificationTypesAction = (
    callback
) => {
    callAPI(
        "getIdentificationTypes",
        API.getIdentificationTypes,
        "GET",
        {},
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const getClientListAction = (
    searchFilterObject,
    callback,
) => {
    callAPI(
        "getClientList",
        API.getClientList,
        "POST",
        searchFilterObject,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const changeClientStatusAction = (
    searchFilterObject,
    callback,
) => {
    callAPI(
        "changeClientStatus",
        API.clientStates,
        "POST",
        searchFilterObject,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const getActiveLoansAction = (
    identificationNumber,
    callback
) => {
    callAPI(
        "getActiveLoans",
        API.getActiveLoans + identificationNumber,
        "GET",
        {},
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const updateCedulaAction = (
    request,
    callback,
) => {
    callAPI(
        "updateCedula",
        API.updateCedula,
        "PATCH",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};
import React from "react";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";

// Constants
import {
  DEFAULT_BRANCH_SELECTION,
  GET_BRANCH_STATUS_DROPDOWN,
  DEFAULT_ZONE,
  GET_DEFAULT_ZONE_SELECTION,
  DEFAULT_STATUS,
  DEFAULT_MESSENGER_ZONE,
  GET_DEFAULT_MESSENGER_ZONE_SELECTION,
} from "./BranchListConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./BranchList.scss";

class BranchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBranchName: props.searchFilter?.brnm || "",
      active: props.searchFilter?.br || DEFAULT_BRANCH_SELECTION,
      zone: props.searchFilter?.zn || DEFAULT_ZONE,
      messengerZone: props.searchFilter?.mzn || DEFAULT_MESSENGER_ZONE,
      zones: props.zones,
      mZones: props.mZones,
      recordsPerPage:
        props.searchFilter?.limit || DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage:
        props.searchFilter?.offset || DEFAULT_PAGINATION_ACTIVE_PAGE,
      errorMessage: props.errorMessage,
    };
  }

  componentDidMount() {
    if (
      this.state.zones &&
      this.state.zones.length > 0 &&
      this.state.zones[0].name !== DEFAULT_ZONE
    ) {
      let zonesDropdown = this.props.zones;
      zonesDropdown.unshift(GET_DEFAULT_ZONE_SELECTION());
      return { zones: zonesDropdown };
    }
    if (
      this.state.mZones &&
      this.state.mZones.length > 0 &&
      this.state.mZones[0].name !== DEFAULT_MESSENGER_ZONE
    ) {
      let zonesDropdown = this.props.mZones;
      zonesDropdown.unshift(GET_DEFAULT_MESSENGER_ZONE_SELECTION());
      return { mZones: zonesDropdown };
    }
    this.handleOnClickSearchFilter(false);
  }

  static getDerivedStateFromProps(state, props) {
    let derivedState = { ...state };
    if (
      state.zones &&
      state.zones.length > 0 &&
      state.zones[0].name !== DEFAULT_ZONE
    ) {
      let zonesDropdown = props.zones;
      zonesDropdown.unshift(GET_DEFAULT_ZONE_SELECTION());
      return { zones: zonesDropdown };
    }
    if (
      state.mZones &&
      state.mZones.length > 0 &&
      state.mZones[0].name !== DEFAULT_MESSENGER_ZONE
    ) {
      let zonesDropdown = props.mZones;
      zonesDropdown.unshift(GET_DEFAULT_MESSENGER_ZONE_SELECTION());
      return { mZones: zonesDropdown };
    }
    if (props.errorMessage !== state.errorMessage) {
      derivedState = {
        ...derivedState,
        errorMessage: props.errorMeesage,
      };
    }
    return derivedState;
  }

  /**
   * This function will be called on change of branch name in search
   */
  handleOnChangeSearchName = (event) => {
    this.setState({
      searchBranchName: event.target.value,
      errorMessage: "",
    });
  };

  /**
   * This function will be called on change of branch labels in filter
   */
  handleOnChangeFilterStatus = (event) => {
    if (event.target.value === DEFAULT_STATUS) {
      this.setState({
        active: "",
        errorMessage: "",
      });
    } else {
      this.setState({
        active: event.target.value,
        errorMessage: "",
      });
    }
  };

  handleOnChangeFilterZone = (event) => {
    if (event.target.value !== DEFAULT_ZONE || event.target.value !== DEFAULT_MESSENGER_ZONE) {
      this.setState({
        [event.target.name]: event.target.value,
        errorMessage: "",
      });
    } else {
      this.setState({
        zone: "",
        messengerZone: "",
        errorMessage: "",
      });
    }
  };

  /**
   * This function will be called on click of filter/search
   */
  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleOnClickSearchFilter({
      searchBranchName: this.state.searchBranchName
        ? this.state.searchBranchName
        : undefined,
      active:
        this.state.active !== DEFAULT_BRANCH_SELECTION
          ? this.state.active
          : undefined,
      zone:
        this.state.zone !== DEFAULT_ZONE
          ? this.state.zone
          : undefined,
      messengerZone:
        this.state.messengerZone !== DEFAULT_MESSENGER_ZONE
          ? this.state.messengerZone
          : undefined,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  /**
   * This function will be called on reset button for filter
  //  */
  handleOnClickResetFilter = () => {
    this.setState(
      {
        active: DEFAULT_BRANCH_SELECTION,
        searchBranchName: "",
        zone: "",
        messengerZone: "",
      },
      () => this.handleOnClickSearchFilter(true)
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  renderSearchFilter = () => {
    let DEFAULT_DROPDOWN_SELECTION = GET_BRANCH_STATUS_DROPDOWN();
    let zones = this.props.zones;
    let mZones = this.props.mZones;
    if (zones.length > 0 && zones[0].name !== DEFAULT_ZONE)
      zones.unshift(GET_DEFAULT_ZONE_SELECTION());
    zones &&
      zones.map((singlezone) => {
        return {
          label: singlezone.name,
          value: singlezone.name,
          active:
            singlezone.active === true ? singlezone.active : false,
        };
      });
    if (mZones.length > 0 && mZones[0].name !== DEFAULT_MESSENGER_ZONE)
      mZones.unshift(GET_DEFAULT_MESSENGER_ZONE_SELECTION());
    mZones &&
      mZones.map((singlezone) => {
        return {
          label: singlezone.name,
          value: singlezone.name,
          active:
            singlezone.active === true ? singlezone.active : false,
        };
      });
    let branchFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("name"),
        handleOnChange: this.handleOnChangeSearchName,
        Value: this.state.searchBranchName,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH_NAME,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeFilterZone,
        Value: this.state.zone,
        fieldname: SEARCH_FILTER_FIELDS.ZONE,
        name: "zone",
        filterDropdownList: zones,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeFilterZone,
        Value: this.state.messengerZone,
        fieldname: SEARCH_FILTER_FIELDS.MZONE,
        name: "messengerZone",
        filterDropdownList: mZones,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeFilterStatus,
        Value: this.state.active,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: DEFAULT_DROPDOWN_SELECTION,
      },
    ];
    return (
      <SearchFilter
        filters={branchFilters}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        buttonText={Strings("AddBranch")}
        buttonWidth={4}
        paddingLeft={true}
      />
    );
  };

  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer component={Paper} className='w-100'>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key === "name" ||
                          column.key === "address"
                          ? "text-left header px-4"
                          : "text-center header px-2"
                      }
                      title={column.name}
                    >
                      <div
                        className={
                          column.key === "address" ? "tableCell" : ""
                        }
                      >
                        {column.name}
                      </div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.id}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => callBack(row)}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key === "name" ||
                          column.key === "address"
                          ? "text-left px-4"
                          : "text-center px-2"
                      }
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ minWidth: column.minWidth }}
                    >
                      <div
                        className={
                          column.key === "name" ||
                            column.key === "address"
                            ? "tableCell"
                            : ""
                        }
                      >
                        {Strings(row[column.key])}
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    return (
      <div className='branchList'>
        <Row>
          <span className='heading'>
            {Strings("BranchManagement")}
          </span>
        </Row>
        <div className='ListContainer'>
          {this.renderSearchFilter()}

          <Col md={12} className='errorText'>
            {this.state.errorMessage}
          </Col>

          {/* Library component to display grid. */}

          {this.props.rowsCount !== 0 ? (
            <div className='branchListTable'>
              {this.renderCustomDataGrid(
                this.props.columns,
                this.props.rows,
                this.props.onRowClick
              )}
              <Col md={12} className='noPadding'>
                <Pagination
                  activePage={this.state.activePage}
                  recordsPerPage={this.state.recordsPerPage}
                  numberOfPages={this.props.numberOfPages}
                  onPageChange={this.onPageChange}
                  dropup={true}
                />
              </Col>
            </div>
          ) : (
            <div className='noRecordsFound noBranchRecords'>
              {Strings("NoRecordsFound")}
            </div>
          )}
        </div>
        {/* </Row> */}
      </div>
    );
  }
}

export default BranchList;

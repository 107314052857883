import React from "react";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Components
import CheckboxComponent from "../CustomUIComponents/CheckboxComponent/CheckboxComponent";

//Constants
import { FREQUENCY_LIST } from "./Constants";
//Strings
import { Strings } from "../../resources/i18n/i18n";

//Assets
import addUser from "../../resources/images/ic_add_user_blue.svg";
import deleteRange from "../../resources/images/ic_notes_minus_blue.svg";
import Utility from "../../utils/Utility";

const EMIConfiguration = (props) => {
  const loanConfig =
    props.configurationData.configurationObject.loanConfig;
  const levelConfig =
    props.configurationData.configurationObject.levelConfig;

  const emptyRateConfigurationData = props.emptyRateConfigurationData;
  const emptyTermConfigurationData = props.emptyTermConfigurationData;
  const emptyLowerAmountConfigurationData =
    props.emptyLowerAmountConfigurationData;
  const emptyUpperAmountConfigurationData =
    props.emptyUpperAmountConfigurationData;
  const emptyLevelConfigurationData =
    props.emptyLevelConfigurationData;

  const onRangeAmountChange = (loanTypeIndex, rangeIndex, event) => {
    let loan = loanConfig[loanTypeIndex];
    loan.amountRange[rangeIndex][event.target.name] = parseInt(
      event.target.value
    );
    props.handleEMILoanValueChange(loanConfig);
    if (event.target.name === "lower") {
      props.isAllConfigurationDataFilled({ loanConfig }, "lower");
    } else if (event.target.name === "upper") {
      props.isAllConfigurationDataFilled({ loanConfig }, "upper");
    }
  };

  const addRange = (loanTypeIndex) => {
    let loan = loanConfig[loanTypeIndex];
    let frequencies = [
      {
        freq: "WEEKLY_ONCE",
        terms: [
          {
            term: 1,
            rate: 1,
          },
        ],
      },
      {
        freq: "WEEKLY_TWICE",
        terms: [
          {
            term: 1,
            rate: 1,
          },
        ],
      },
      {
        freq: "WEEKLY_THRICE",
        terms: [
          {
            term: 1,
            rate: 1,
          },
        ],
      },
      {
        freq: "WEEKLY_SIX",
        terms: [
          {
            term: 1,
            rate: 1,
          },
        ],
      },
      {
        freq: "MONTHLY_ONCE",
        terms: [
          {
            term: 1,
            rate: 1,
          },
        ],
      },
      {
        freq: "MONTHLY_TWICE",
        terms: [
          {
            term: 1,
            rate: 1,
          },
        ],
      },
    ];

    let amountDetails = {
      lower:
        parseInt(
          loan.amountRange[loan.amountRange.length - 1].upper
        ) + 1,
      upper:
        parseInt(
          loan.amountRange[loan.amountRange.length - 1].upper
        ) + 2,
      frequencies: frequencies,
    };
    loan.amountRange.push(amountDetails);
    props.handleEMILoanValueChange(loanConfig);
  };
  const deleteAmountRangeData = (loanTypeIndex, rangeIndex) => {
    let loan = loanConfig[loanTypeIndex];
    loan.amountRange.pop();
    props.handleEMILoanValueChange(loanConfig);
  };

  const renderAmountAccordianSummy = (loanTypeIndex, rangeIndex) => {
    let loan = loanConfig[loanTypeIndex];
    return (
      <Row className={rangeIndex === 0 ? "pb-4" : ""}>
        <Col md={3}>
          <span>{Strings("Amount(Lower)")}</span>
          <NumberFormat
            name='lower'
            className={isCellWithError(
              loanTypeIndex,
              rangeIndex,
              0,
              0,
              "lower"
            )}
            onValueChange={(event) => {
              onRangeAmountChange(loanTypeIndex, rangeIndex, {
                target: {
                  name: "lower",
                  value: event.value ? event.value : "",
                },
              });
            }}
            allowLeadingZeros={false}
            value={loan.amountRange[rangeIndex].lower}
            type='text'
            placeholder={Strings("Amount(Lower)")}
            allowNegative={false}
          />
        </Col>
        <Col md={3}>
          <span>{Strings("Amount(Upper)")}</span>
          <NumberFormat
            name='upper'
            className={isCellWithError(
              loanTypeIndex,
              rangeIndex,
              0,
              0,
              "upper"
            )}
            onValueChange={(event) => {
              onRangeAmountChange(loanTypeIndex, rangeIndex, {
                target: {
                  name: "upper",
                  value: event.value ? event.value : "",
                },
              });
            }}
            allowLeadingZeros={false}
            value={loan.amountRange[rangeIndex].upper}
            type='text'
            placeholder={Strings("Amount(Upper)")}
            allowNegative={false}
          />
        </Col>

        {rangeIndex !== 0 &&
          loan.amountRange.length !== rangeIndex - 1 && (
            <Col
              md={6}
              className='d-flex justify-content-end m-auto py-3'
            >
              <img
                className='deleteIcon'
                src={deleteRange}
                onClick={() =>
                  deleteAmountRangeData(loanTypeIndex, rangeIndex)
                }
                alt='delete'
              />
            </Col>
          )}
      </Row>
    );
  };

  const deleteTerm = (
    loanTypeIndex,
    rangeIndex,
    termIndex,
    frequencyIndex
  ) => {
    let loan = loanConfig[loanTypeIndex];
    loan.amountRange[rangeIndex].frequencies[
      frequencyIndex
    ].terms.splice(termIndex, 1);
    props.handleEMILoanValueChange(loanConfig);
  };

  const stylesTableCell = () => {
    return styled(TableCell)(({ theme }) => {
      return {
        [`&.${tableCellClasses.head}`]: {
          fontWeight: "bold",
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      };
    });
  };

  const onInuputChange = (
    loanTypeIndex,
    rangeIndex,
    termIndex,
    frequencyIndex,
    event
  ) => {
    let loan = loanConfig[loanTypeIndex];
    loan.amountRange[rangeIndex].frequencies[termIndex].terms[
      frequencyIndex
    ][event.target.name] = event.target.value;
    props.handleEMILoanValueChange(loanConfig);
    if (event.target.name === "term") {
      props.isAllConfigurationDataFilled({ loanConfig }, "term");
    } else if (event.target.name === "rate") {
      props.isAllConfigurationDataFilled({ loanConfig }, "rate");
    }
  };

  const isCellWithError = (
    loanTypeIndex,
    rangeIndex,
    frequencyIndex,
    termIndex,
    checkType
  ) => {
    let className =
      checkType === "lower" ||
        checkType === "upper" ||
        checkType === "level"
        ? "form-control w-100"
        : "form-control w-25";
    let errorFields;
    if (checkType === "rate")
      errorFields = emptyRateConfigurationData;
    else if (checkType === "term")
      errorFields = emptyTermConfigurationData;
    else if (checkType === "lower")
      errorFields = emptyLowerAmountConfigurationData;
    else if (checkType === "upper")
      errorFields = emptyUpperAmountConfigurationData;
    else if (checkType === "level")
      errorFields = emptyLevelConfigurationData;
    for (let element of errorFields) {
      if (
        element.loanTypeIndex === loanTypeIndex &&
        element.rangeIndex === rangeIndex &&
        element.freqIndex === frequencyIndex &&
        element.termIndex === termIndex
      ) {
        className += " error-data";
      }
    }
    return className;
  };

  const getFrequencyTableValue = (
    loanTypeIndex,
    rangeIndex,
    frequencyIndex,
    terms,

    termIndex,

    name
  ) => {
    let value;
    if (terms[name] === -1) {
      value = "-";
    } else if (props.readOnly && terms[name] !== -1) {
      value = terms[name];
    } else {
      value = (
        <div className='textBox input d-flex justify-content-center'>
          <NumberFormat
            className={isCellWithError(
              loanTypeIndex,
              rangeIndex,
              frequencyIndex,
              termIndex,
              name
            )}
            onValueChange={(event) => {
              onInuputChange(
                loanTypeIndex,
                rangeIndex,
                frequencyIndex,
                termIndex,
                {
                  target: {
                    name: name,
                    value: event.value ? event.value : "",
                  },
                }
              );
            }}
            decimalScale='2'
            allowLeadingZeros={false}
            value={terms[name]}
            type='text'
            placeholder={Strings(name)}
            allowNegative={false}
          />
        </div>
      );
    }
    return value;
  };

  const renderFreqTable = (
    loanTypeIndex,
    rangeIndex,
    frequencyIndex
  ) => {
    let loan = loanConfig[loanTypeIndex];
    const StyledTableCell = stylesTableCell();
    return (
      <Typography component={"span"}>
        <Row className='justify-content-center newCommissionScheme pt-2'>
          <Col md={{ span: 8, offset: 0 }} className='px-4 d-flex '>
            <TableContainer component={Paper}>
              <Table aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {Strings("Term")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {Strings("rate")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loan.amountRange[rangeIndex]?.frequencies[
                    frequencyIndex
                  ]?.terms.map((terms, termIndex) => {
                    return (
                      <TableRow
                        key={
                          loan.loanType +
                          loanTypeIndex +
                          rangeIndex +
                          frequencyIndex +
                          "FREQ" +
                          termIndex
                        }
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          title={terms.term}
                          onKeyDown={Utility.allowOnlyNumber}
                        >
                          {getFrequencyTableValue(
                            loanTypeIndex,
                            rangeIndex,
                            frequencyIndex,
                            terms,
                            termIndex,
                            "term"
                          )}
                        </TableCell>
                        <TableCell
                          component='th'
                          scope='row'
                          title={terms.rate}
                        >
                          {getFrequencyTableValue(
                            loanTypeIndex,
                            rangeIndex,
                            frequencyIndex,
                            terms,
                            termIndex,
                            "rate"
                          )}
                        </TableCell>
                        {!props.readOnly &&
                          loan.amountRange[rangeIndex]?.frequencies[
                            frequencyIndex
                          ]?.terms.length !== 1 && (
                            <div className='d-flex justify-content-center m-auto py-3'>
                              <img
                                src={deleteRange}
                                onClick={() =>
                                  deleteTerm(
                                    loanTypeIndex,
                                    rangeIndex,
                                    termIndex,
                                    frequencyIndex
                                  )
                                }
                                alt='delete'
                              />
                            </div>
                          )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
        <Row>
          {!props.readOnly && (
            <Col
              md={{ span: 1, offset: 11 }}
              className='px-4 text-right '
            >
              <img
                src={addUser}
                alt='add'
                onClick={() => {
                  addTerm(loanTypeIndex, frequencyIndex, rangeIndex);
                }}
                className='addRange'
              />
            </Col>
          )}
        </Row>
      </Typography>
    );
  };

  const addTerm = (loanTypeIndex, frequencyIndex, rangeIndex) => {
    let loan = loanConfig[loanTypeIndex];
    let term = {
      term: 1,
      rate: 1,
    };

    //If empty term is present in the list then remove it and add new term. Else add new term
    let indexOfEmptyTerm = loan.amountRange[rangeIndex].frequencies[frequencyIndex].terms.findIndex((term) => term.term === -1);
    if (indexOfEmptyTerm !== -1) {
      loan.amountRange[rangeIndex].frequencies[frequencyIndex].terms.splice(indexOfEmptyTerm, 1);
      loan.amountRange[rangeIndex].frequencies[
        frequencyIndex
      ].terms.push(term);
    } else {
      loan.amountRange[rangeIndex].frequencies[
        frequencyIndex
      ].terms.push(term);
    }

    props.handleEMILoanValueChange(loanConfig);
  };

  const renderTermAccordian = (loanTypeIndex, rangeIndex) => {
    let loan = loanConfig[loanTypeIndex];
    return (
      <Row>
        {loan.amountRange[rangeIndex].frequencies.map(
          (frequencyInfo, frequencyIndex) => (
            <Col
              md={12}
              className='pb-3'
              key={
                loan.loanType +
                loanTypeIndex +
                rangeIndex +
                "TERM" +
                frequencyIndex
              }
            >
              <Accordion
                defaultExpanded={frequencyIndex === 0}
                className='emiAccordian'
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  className='emiAccordianSummary'
                >
                  <Typography component={"span"}>
                    {`${Strings("frequency")} : ${Strings(
                      frequencyInfo.freq
                    )}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {renderFreqTable(
                    loanTypeIndex,
                    rangeIndex,
                    frequencyIndex
                  )}
                </AccordionDetails>
              </Accordion>
            </Col>
          )
        )}
      </Row>
    );
  };

  const onCheckboxSelection = (levelIndex, value) => {
    let levelDetails = levelConfig[levelIndex];
    let isIncluded = levelDetails.frequencies.includes(value);
    if (!isIncluded) {
      levelDetails.frequencies.push(value);
    } else {
      levelDetails.frequencies.splice(
        levelDetails.frequencies.indexOf(value),
        1
      );
    }
    props.handleLevelConfigChange(levelConfig);
  };

  const onThresholdLoanAmount = (levelIndex, event) => {
    let levelDetails = levelConfig[levelIndex];
    levelDetails[event.target.name] = event.target.value;
    props.handleLevelConfigChange(levelConfig);
    if (event.target.name === "amount") {
      props.isAllConfigurationDataFilled(
        { levelConfig },
        "levelConfig",
        "levelConfig"
      );
    }
  };
  return (
    <div className='emiConfiguration'>
      {loanConfig.map((loan, loanTypeIndex) => (
        <Row key={loan.loanType}>
          <Col md={12} className='pb-3'>
            <Accordion defaultExpanded={loanTypeIndex === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className='emiAccordianSummary'
              >
                <Typography component={"span"}>
                  {Strings(loan.loanType)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loan.amountRange.map((range, rangeIndex) => (
                  <Row
                    key={
                      loan.loanType +
                      loanTypeIndex +
                      "AMT" +
                      rangeIndex
                    }
                  >
                    <Col md={12} className='pb-3'>
                      <Accordion
                        defaultExpanded={rangeIndex === 0}
                        className='emiAccordian'
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                        >
                          <Typography component={"span"}>
                            {`${range.lower} - ${range.upper
                              } (${Strings(
                                "Amount(Lower)"
                              )} - ${Strings("Amount(Upper)")})`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {!props.readOnly &&
                            loan.loanType !== "FAST_CASH" &&
                            renderAmountAccordianSummy(
                              loanTypeIndex,
                              rangeIndex
                            )}
                          {renderTermAccordian(
                            loanTypeIndex,
                            rangeIndex
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Col>
                  </Row>
                ))}
                <Row>
                  {!props.readOnly &&
                    loan.loanType !== "FAST_CASH" && (
                      <Col
                        md={{ span: 1, offset: 11 }}
                        className='px-4 text-right '
                      >
                        <img
                          src={addUser}
                          alt='add'
                          onClick={() => {
                            addRange(loanTypeIndex);
                          }}
                          className='addRange'
                        />
                      </Col>
                    )}
                </Row>
              </AccordionDetails>
            </Accordion>
          </Col>
        </Row>
      ))}
      {levelConfig.map((levelDetails, levelIndex) => (
        <Row key={levelDetails.level}>
          <Col md={12} className='pb-3'>
            <Accordion defaultExpanded={levelIndex === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className='emiAccordianSummary'
              >
                <Typography component={"span"}>
                  {Strings(levelDetails.level)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Row>
                  <Col md={12} className='px-0 py-3'>
                    <strong className='subText'>
                      {Strings("frequency")}
                    </strong>
                  </Col>
                  {FREQUENCY_LIST.map((frequency, index) => (
                    <Col
                      md={2}
                      key={
                        levelDetails.level +
                        levelIndex +
                        index +
                        frequency.value
                      }
                    >
                      <CheckboxComponent
                        onChange={() => {
                          if (
                            !props.readOnly &&
                            frequency.value !== "WEEKLY_THRICE"
                          ) {
                            onCheckboxSelection(
                              levelIndex,
                              frequency.value
                            );
                          }
                        }}
                        checked={levelConfig[
                          levelIndex
                        ].frequencies.includes(frequency.value)}
                        disabled={props.readOnly}
                        label={Strings(frequency.label)}
                        key={Strings(frequency.label)}
                      />
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col md={12} className='px-0 py-3'>
                    <strong className='subText'>
                      {Strings("thresholdLoanAmount")}
                    </strong>
                  </Col>
                  <Col md={3}>
                    <NumberFormat
                      name='term'
                      className={isCellWithError(
                        levelIndex,
                        0,
                        0,
                        0,
                        "level"
                      )}
                      onValueChange={(event) => {
                        onThresholdLoanAmount(levelIndex, {
                          target: {
                            name: "amount",
                            value: event.value ? event.value : "",
                          },
                        });
                      }}
                      allowLeadingZeros={false}
                      value={levelDetails.amount}
                      type='text'
                      placeholder={Strings("thresholdLoanAmount")}
                      allowNegative={false}
                    />
                  </Col>
                </Row>
              </AccordionDetails>
            </Accordion>
          </Col>
        </Row>
      ))}
    </div>
  );
};
export default EMIConfiguration;

import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Row, Col, Button } from "react-bootstrap";

//Components
import "./ConfigurationModal.scss";
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import Calender from "../CustomUIComponents/Calender/Calender";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants

class ConfiguartionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessage: props.errorMessage,
      productdata: props.productdata,
      readOnly: props.readOnly,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let derivedState = { ...prevState };
    if (nextProps.open !== prevState.open) {
      derivedState = {
        ...derivedState,
        open: nextProps.open,
      };
    }
    return derivedState;
  }

  //to close modal
  closeModal = () => {
    this.props.modalButtonCancel.onClick();
  };

  renderCalender = () => {
    return (
      <Calender
        placeholderTop={Strings("selectDate")}
        placeholder={Strings("selectDate") + "*"}
        handleChange={(event) =>
          this.props.handleCalenderChange(event)
        }
        value={this.props.selectedRow.date}
        dateFormat={this.props.dateFormat}
        isBefore={true}
        disableTodaysDate={true}
        disabled={this.props.editHoliday}
      />
    );
  };

  renderModalTextField = () => {
    return (
      <TextFieldComponent
        placeholder={Strings("HolidayInfo") + "*"}
        className='topMargin'
        handleChange={(event) => this.props.handleChange(event)}
        value={
          this.props.selectedRow?.info
            ? this.props.selectedRow.info
            : ""
        }
      // maxLength={30}
      />
    );
  };

  renderModalButtons = () => {
    return (
      <>
        <Button
          className='primaryModalButton marginCancelButton buttonBorder buttonMinWidth'
          onClick={this.props.modalButtonCancel.onClick}
        >
          {this.props.modalButtonCancel.text}
        </Button>

        <Button
          className='primaryModalButton marginCancelButton buttonBackground buttonMinWidth'
          onClick={this.props.modalButtonApprove.onClick}
          disabled={this.props.selectedRow.info === ""}
        >
          {this.props.modalButtonApprove.text}
        </Button>
      </>
    );
  };

  render() {
    return (
      <div>
        <Modal
          // Change visibility of modal based on props.
          open={this.state.open}
          onClose={this.closeModal}
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          showCloseIcon={this.props.modalShowCloseIcon}
          classNames={{
            modal: "configurationDashboardModal",
          }}
          focusTrapped={false}
          center
        >
          <Row className='configurationDashboardModal'>
            <Col md={12} className='configurationModal pl-3'>
              {this.props.headerText}
            </Col>

            <Col md={12} className='modalContent pt-3'>
              {this.props.errorMessage && (
                <Row className='errors noPadding pl-3'>
                  {this.props.errorMessage}
                </Row>
              )}
              <Col md={6} className='pl-3 pt-3' key={Math.random()}>
                <div className='datePickerPanel'>
                  {this.renderCalender()}
                </div>
              </Col>
              <Row className='p-3'>{this.renderModalTextField()}</Row>
            </Col>

            <Col md={12} className='buttonAlign pt-3'>
              {this.renderModalButtons()}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default ConfiguartionModal;

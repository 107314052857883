// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { API } from "../../constants/APIConstants";
import {
  SET_USER_DETAILS_ACTION,
  CLEAR_USER_DETAILS_ACTION,
  CLEAR_USER_REDUCER_ACTION,
  SET_USER_ROLES_ACTION,
  SET_USERS_ACTION,
  SET_ADD_USER_FLAG,
} from "../../redux/constants";
import { callAPI } from "../../config/AxiosTemplate";

import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

/*
 * Actions using dispatch
 * This will be called inside container through props with mapDispatchToProps
 */

const getUserDetails = (userDetails) => {
  userDetails.users.forEach((element) => {
    if (element.roleDto) {
      element.role = element.roleDto;
    }
  });
  return userDetails;
};

//Action to get user details
export const getUserDetailsAction = (callback) => (dispatch) => {
  let requestData = {
    filter: {},
    search: {},
    limit: DEFAULT_PAGINATION_RECORDS_COUNT,
    offset: DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
  };
  callAPI(
    "GetUserDetails",
    API.getUserList,
    "POST",
    requestData,
    (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        let userDetails = getUserDetails(apiResponse.data.data);

        dispatch({
          type: SET_USERS_ACTION,
          users: userDetails,
        });
      }
      callback(apiResponse);
    }
  );
};

export const getUsersBySearchFilterAction = (
  searchFilterObject,
  callback,
  setUserActionCall
) => {
  let requestData = {
    filter: {
      role: searchFilterObject.filterRole
        ? searchFilterObject.filterRole
        : undefined,
    },
    search: {
      username: searchFilterObject.searchUsername
        ? searchFilterObject.searchUsername
        : undefined,
      branchId: searchFilterObject.branchId
        ? searchFilterObject.branchId
        : undefined,
      zone: searchFilterObject.zone
        ? searchFilterObject.zone
        : undefined,
      messengerZone: searchFilterObject.messengerZone
        ? searchFilterObject.messengerZone
        : undefined,
      active: searchFilterObject.active,
      available: searchFilterObject.available
    },
    limit: searchFilterObject.limit
      ? searchFilterObject.limit
      : undefined,
    offset: searchFilterObject.offset
      ? searchFilterObject.offset - 1
      : undefined,
  };

  callAPI(
    "GetUsersBySearchFilter",
    API.getUsersBySearchFilter,
    "POST",
    requestData,
    (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        let userDetails = getUserDetails(apiResponse.data.data);
        setUserActionCall(userDetails);
      }
      callback(apiResponse);
    }
  );
};

export const setUserDetailsAction =
  (userDetails, callback) => (dispatch) => {
    let userInfo = userDetails;
    if (userDetails && userDetails.roleDto) {
      userInfo.role = userDetails.roleDto;
    }

    dispatch({
      type: SET_USER_DETAILS_ACTION,
      userDetails: userInfo,
    });
    callback();
  };

export const clearUserDetailsAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_USER_DETAILS_ACTION,
  });
};

export const ClearUserReducerAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_USER_REDUCER_ACTION,
  });
};

/**
 *  Action to get user roles
 */

export const getUserRolesAction =
  (isAddUser, callback) => (dispatch) => {
    let requestString = "/roles";
    if (isAddUser !== undefined && isAddUser === true) {
      requestString = requestString + "?type=1";
    }
    callAPI(
      "GetUserRoles",
      API.getUserRoles.replace("/roles", requestString),
      "GET",
      {},
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          dispatch({
            type: SET_USER_ROLES_ACTION,
            userRoles: apiResponse.data.data.roles,
          });
        }
        callback(apiResponse);
      }
    );
  };

export const getUserRolesForAddUserAction = (isAddUser, callback) => {
  let requestString = "/roles";
  if (isAddUser !== undefined && isAddUser === true) {
    requestString = requestString + "?type=0";
  }
  callAPI(
    "GetUserRoles",
    API.getUserRoles.replace("/roles", requestString),
    "GET",
    {},
    (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        callback(apiResponse);
      }
      callback(apiResponse);
    }
  );
};

export const getUserByBranch = (request, callback) => {
  callAPI(
    "getUserByBranch",
    API.getUserByBranch.concat(
      `?rl=${request.role}&br=${request.branchId}`
    ),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setIsAddUSerFlagAction = (isAddUser) => (dispatch) => {
  dispatch({
    type: SET_ADD_USER_FLAG,
    isAddUser: isAddUser.isAddUser,
  });
};

export const setUserAction = (userDetails) => (dispatch) => {
  dispatch({
    type: SET_USERS_ACTION,
    users: userDetails,
  });
};

import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";
import Utility from "../../utils/Utility";

export const getMessengerDetailsForDepositAction = (
  requestData,
  callback
) => {
  let requestString = "";
  if (requestData.messengerId !== "") {
    requestString += requestData.messengerId;
  }
  if (requestData.date !== "") {
    requestString +=
      "/" + Utility.convertDateIntoMiliSeconds(requestData.date);
  }
  callAPI(
    "getMessengerDetailsById",
    API.getMessengerDetailsForDeposit.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const ViewReceiptAction = (requestData, callback) => {
  let requestString = "";
  if (requestData && requestData !== null) {
    requestString = requestData;
  }
  callAPI(
    "ViewReceiptById",
    API.getMessegerDepositReceipt.replace("<id>", requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const verifyDepositAction = (requestData, callback) => {
  let request = { amt: requestData.amt };
  callAPI(
    "verifyDeposit",
    API.verifyDeposit.replace("<id>", requestData.id),
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getDepositedMinimartListForVerificationAction = (
  request,
  callback
) => {
  callAPI(
    " getDepositedMinimartListForVerification",
    API.getDepositedMinimartListForVerification,
    "GET",
    { id: request.id, dt: request.date },
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import React from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

// Components
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

// Constants
import {
  PROMOTION_STATUS,
  PROMOTION_STATUS_ANALYST,
  DEFAULT_LIST,
  PROMOTION_APPROVAL_USERS,
} from "./Constants";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  PROMOTION_REQUEST_TYPE_LEGEND,
} from "../../constants/appConstants";
import {
  PROMOTION_TYPE,
  BEENFIT_TYPE,
} from "../MTPPromotions/PromotionsConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

export default class PromotionsApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promotionFilters: {
        fromDate: props.searchFilter.fd,
        toDate: props.searchFilter.td,
        matricesId: !(PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role))
          ? props.minimartDetails.id
          : props.searchFilter.mid,
        minimartCode: !(PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role))
          ? props.minimartDetails.code
          : props.searchFilter.code,
        minimartName: !(PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role))
          ? props.minimartDetails.name
          : props.searchFilter.mn,
        promotionType: props.searchFilter.prty,
        productType: props.searchFilter.prlt,
        benefitType: props.searchFilter.prbty,
        status: props.searchFilter.pst,
        userName:
          PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
            ? this.props.loggedInUser.userId
            : "",
      },
      numberOfPages: props.numberOfPages,
      activePage: props.searchFilter.offset,
      recordsPerPage: props.searchFilter.limit,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.promotionFilters.toDate)) {
          DateValue = this.state.promotionFilters.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (
          dateMoment.isBefore(this.state.promotionFilters.fromDate)
        ) {
          DateValue =
            moment(dateMoment).diff(
              this.state.promotionFilters.fromDate,
              "days"
            ) === 0
              ? this.state.promotionFilters.fromDate
              : this.state.promotionFilters.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({
      promotionFilters: {
        ...this.state.promotionFilters,
        [key]: DateValue,
      },
    });
  };

  handleInputChnage = (event) => {
    let value = DEFAULT_LIST.includes(event.target.value)
      ? ""
      : event.target.value;
    this.setState({
      promotionFilters: {
        ...this.state.promotionFilters,
        [event.target.name]: value,
        productType:
          event.target.name === "promotionType"
            ? ""
            : event.target.name === "productType" //NOSONAR
              ? value
              : this.state.promotionFilters.productType,
      },
    });
  };

  handleOnClickSearchFilter = (isDefaultOffset = false) => {
    isDefaultOffset &&
      this.setState({
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      });
    this.props.handleOnClickSearchFilter({
      fd: Utility.convertDateIntoMiliSeconds(
        this.state.promotionFilters.fromDate
      ),
      td: Utility.convertDateIntoMiliSeconds(
        this.state.promotionFilters.toDate
      ),
      mid: !(PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role))
        ? this.props.minimartDetails.id
        : this.state.promotionFilters.matricesId,
      cid: !(PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role))
        ? this.props.minimartDetails.code
        : this.state.promotionFilters.minimartCode,
      mn: !(PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role))
        ? this.props.minimartDetails.name
        : this.state.promotionFilters.minimartName,
      prty: this.state.promotionFilters.promotionType,
      prlt: this.state.promotionFilters.productType,
      prbty: this.state.promotionFilters.benefitType,
      st: this.state.promotionFilters.status,
      lmt: this.state.recordsPerPage,
      oft: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
      uid:
        PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
          ? this.props.loggedInUser.userId
          : this.state.promotionFilters.userName,
    });
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        promotionFilters: {
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: DEFAULT_DURATION.toDate,
          matricesId: !(
            PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
          )
            ? this.props.minimartDetails.id
            : "",
          minimartCode: !(
            PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
          )
            ? this.props.minimartDetails.code
            : "",
          minimartName: !(
            PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
          )
            ? this.props.minimartDetails.name
            : "",
          promotionType: "",
          benefitType: "",
          productType: "",
          status: "PENDING",
          uid:
            PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
              ? this.props.loggedInUser.userId
              : "",
        },
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
      this.handleOnClickSearchFilter
    );
  };

  generateList = (promotionType) => {
    let productList = [
      {
        key: 0,
        value: "",
        label: Strings("productType"),
      },
    ];
    if (promotionType === "") {
      let list = Object.keys(this.props.productList);
      list.forEach((key) => {
        this.props.productList[key].forEach((product, index) => {
          productList.push({
            key: index + 1,
            value: product,
            label: Strings("P_" + product),
          });
        });
      });
    } else {
      this.props.productList[promotionType].forEach(
        (products, index) => {
          productList.push({
            key: index + 1,
            value: products,
            label: Strings("P_" + products),
          });
        }
      );
    }
    return productList;
  };

  renderSearchFilter = () => {
    let productList = this.generateList(
      this.state.promotionFilters.promotionType
    );
    let promotionFilters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.promotionFilters.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.promotionFilters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("promotionType"),
        handleOnChange: this.handleInputChnage,
        Value: this.state.promotionFilters.promotionType,
        filterDropdownList: PROMOTION_TYPE,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        name: "promotionType",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("productType"),
        handleOnChange: this.handleInputChnage,
        Value: this.state.promotionFilters.productType,
        filterDropdownList: productList,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        paddingTop:
          PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role),
        name: "productType",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("benefitType"),
        handleOnChange: this.handleInputChnage,
        Value: this.state.promotionFilters.benefitType,
        filterDropdownList: BEENFIT_TYPE,
        fieldname: SEARCH_FILTER_FIELDS.NAME,
        name: "benefitType",
        paddingTop:
          PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role),
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("status"),
        handleOnChange: this.handleInputChnage,
        Value: this.state.promotionFilters.status,
        filterDropdownList:
          PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
            ? PROMOTION_STATUS_ANALYST
            : PROMOTION_STATUS,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        name: "status",
        paddingTop:
          PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role),
      },
    ];

    if (PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)) {
      promotionFilters.splice(1, 0, {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("MatricesID"),
        handleOnChange: this.handleInputChnage,
        Value: this.state.promotionFilters.matricesId,
        fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
        column: 2,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
        name: "matricesId",
        disabled: !(
          PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
        ),
      });
      promotionFilters.splice(2, 0, {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("minimartCode"),
        handleOnChange: this.handleInputChnage,
        Value: this.state.promotionFilters.minimartCode,
        fieldname: SEARCH_FILTER_FIELDS.CODE,
        column: 2,
        name: "minimartCode",
        disabled: !(
          PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
        ),
      });
      promotionFilters.splice(3, 0, {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("name"),
        handleOnChange: this.handleInputChnage,
        Value: this.state.promotionFilters.minimartName,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
        column: 2,
        name: "minimartName",
        disabled: !(
          PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
        ),
      });
    }
    !(PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)) &&
      promotionFilters.push({
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleInputChnage,
        Value: this.state.promotionFilters.userName,
        fieldname: SEARCH_FILTER_FIELDS.ANALYST_NAME,
        filterDropdownList: this.props.userList,
        column: 2,
        paddingTop: 2,
        name: "userName",
      });
    return (
      <SearchFilter
        filters={promotionFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={2}
        paddingTop={2}
        removeLeftPadding={true}
      />
    );
  };

  renderLegends = () => {
    return (
      <Row className='d-flex justify-content-between pt-3'>
        <CustomerTypeLegend
          arrayOfTypes={PROMOTION_REQUEST_TYPE_LEGEND}
          paddingLeft={4}
        />
      </Row>
    );
  };

  renderPromotionCards = () => {
    return this.props.promotionList.length > 0 ? (
      <Row className='cardAlign'>
        <Col md={12} className='w-100 pr-0'>
          <Row className='w-100'>
            {this.props.promotionList.map((card) => {
              return (
                <CustomCard
                  card={card}
                  cardType='promotion'
                  key={Math.random()}
                  handleCardClick={this.props.promotionCardClick}
                  isAnalyst={
                    PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
                  }
                />
              );
            })}
          </Row>
        </Col>
        <Col md={12} className='pt-3'>
          <Pagination
            activePage={this.state.activePage}
            recordsPerPage={this.state.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.onPageChange}
            dropup={true}
          />
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoRecordsFound")}
      </div>
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      this.handleOnClickSearchFilter
    );
  };
  render() {
    return (
      <div>
        {(PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)) && (
          <Row className='heading'>
            {Strings("MTPPromotionsApproval")}
          </Row>
        )}
        <div
          className={
            PROMOTION_APPROVAL_USERS.includes(this.props.loggedInUser.role)
              ? "paper greyback customTableCard"
              : ""
          }
        >
          {this.renderSearchFilter()}
          {this.renderLegends()}
          <Row>
            <Col md={12} className=' errorText'>
              {this.props.errorMessage}
            </Col>
          </Row>
          {this.renderPromotionCards()}
        </div>
      </div>
    );
  }
}

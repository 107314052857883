import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// eslint-disable-next-line
import { Route, Switch, withRouter } from "react-router-dom";

//Component
import FirstLevelReportsContainer from "../FirstLevelReports/FirstLevelReportsContainer";
import SecondLevelReportsContainer from "../SecondLevelReports/SecondLevelReportsContainer";

//Action

//Constants
import { ROUTES } from "../../constants/routeConstants";
import { REPORTS_TABS } from "./Constants";
import {
  REPORTS_TAB_KEY_CONST,
  SEARCH_FILTER_PAGES,
  FLR_SEARCH_FILTER,
  SLR_SEARCH_FILTER,
} from "../../constants/appConstants";

//Strings

//Utility

//Styles
import "./GeneralReports.scss";

//Assets

class GeneralReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: this.props.loggedInUser,
      Tabs: REPORTS_TABS[this.props.loggedInUser.role],
      activeTab: REPORTS_TABS[this.props.loggedInUser.role][0],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //NOSONAR
    let derivedState = {};
    if (
      nextProps.history.location.pathname === ROUTES.REPORTS ||
      nextProps.history.location.pathname ===
      ROUTES.LOAN_PORTFOLIO_REPORTS ||
      nextProps.history.location.pathname ===
      ROUTES.REPORTS_FOR_SINGLE_LOAN_PORTFOLIO ||
      nextProps.history.location.pathname ===
      ROUTES.THIRD_LEVEL_LOAN_PORTFOLIO
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[0],
      };
    } else if (
      nextProps.history.location.pathname === ROUTES.SALES_REPORTS ||
      nextProps.history.location.pathname ===
      ROUTES.REPORTS_FOR_SINGLE_SALES
    ) {
      derivedState = {
        ...derivedState,
        activeTab:
          prevState.Tabs.length > 1
            ? prevState.Tabs[1]
            : prevState.Tabs[0],
      };
    } else if (
      nextProps.history.location.pathname ===
      ROUTES.SPECIAL_SALES_REPORTS ||
      nextProps.history.location.pathname ===
      ROUTES.REPORTS_FOR_SINGLE_SPECIAL_SALES
    ) {
      derivedState = {
        ...derivedState,
        activeTab:
          prevState.Tabs.length > 1
            ? prevState.Tabs[2]
            : prevState.Tabs[0],
      };
    } else if (
      nextProps.history.location.pathname ===
      ROUTES.COLLECTIONS_REPORTS ||
      nextProps.history.location.pathname ===
      ROUTES.REPORTS_FOR_SINGLE_COLLECTIONS
    ) {
      let tabValue;
      if (prevState.Tabs.length > 2) {
        if (
          prevState.Tabs[1].value ===
          REPORTS_TAB_KEY_CONST.COLLECTIONS
        ) {
          tabValue = prevState.Tabs[1];
        } else {
          tabValue = prevState.Tabs[3];
        }
      } else {
        tabValue = prevState.Tabs[0];
      }
      derivedState = {
        ...derivedState,
        activeTab: tabValue,
      };
    }
    else if (
      nextProps.history.location.pathname ===
      ROUTES.WEB_COLLECTIONS_REPORTS ||
      nextProps.history.location.pathname ===
      ROUTES.REPORTS_FOR_SINGLE_WEB_COLLECTIONS
    ) {
      let tabValue;
      if (prevState.Tabs.length > 2) {
        if (
          prevState.Tabs[2].value ===
          REPORTS_TAB_KEY_CONST.WEB_COLLECTIONS
        ) {
          tabValue = prevState.Tabs[2];
        } else {
          tabValue = prevState.Tabs[4];
        }
      } else {
        tabValue = prevState.Tabs[1];
      }
      derivedState = {
        ...derivedState,
        activeTab: tabValue,
      };
    } else if (
      nextProps.history.location.pathname === ROUTES.WALLET_REPORTS ||
      nextProps.history.location.pathname ===
      ROUTES.REPORTS_FOR_SINGLE_WALLET
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[5],
      };
    }
    return derivedState;
  }

  handleTabs = (index) => {
    this.props.ClearReportsReducerAction();
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT,
      { ...FLR_SEARCH_FILTER }
    );
    if (index === 0) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT,
        { ...SLR_SEARCH_FILTER }
      );
    }
    this.setState({ activeTab: this.state.Tabs[index] }, () => {
      this.props.history.push(
        ROUTES[`${this.state.Tabs[index].value}_REPORTS`]
      );
    });
  };

  callbackOnRowClick = (row) => {
    if (row !== undefined) {
      this.props.setTwoStepAction(true);
      this.props.history.push(
        ROUTES[`REPORTS_FOR_SINGLE_${this.state.activeTab.value}`]
      );
    }
  };

  callbackOnSecondReportsRowClick = (row) => {
    if (row !== undefined) {
      this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  goToSingleLoanReports = (isLoanPortfolio) => {
    this.props.ClearReportsReducerAction();
    this.props.history.push(
      isLoanPortfolio
        ? ROUTES.REPORTS_FOR_SINGLE_LOAN_PORTFOLIO
        : ROUTES.REPORTS_FOR_SINGLE_WALLET
    );
  };

  goToAllLoanReports = (isLoanPortfolio) => {
    this.props.ClearReportsReducerAction();
    this.props.history.push(
      isLoanPortfolio
        ? ROUTES.LOAN_PORTFOLIO_REPORTS
        : ROUTES.WALLET_REPORTS
    );
  };

  //render
  renderFirstLevelReport = () => {
    return (
      <FirstLevelReportsContainer
        user={this.state.activeTab.value}
        callbackOnRowClick={this.callbackOnRowClick}
      />
    );
  };
  renderFirstLevelAllReport = () => {
    return (
      <FirstLevelReportsContainer
        goToSingleLoanReports={this.goToSingleLoanReports}
        goToAllLoanReports={this.goToAllLoanReports}
        user={this.state.activeTab.value}
        callbackOnRowClick={this.callbackOnRowClick}
      />
    );
  };
  renderSecondLevelReport = () => {
    return (
      <SecondLevelReportsContainer
        user={this.state.activeTab.value}
        goBack={this.goBack}
        history={this.props.history}
      />
    );
  };
  renderSecondLevelAllReport = () => {
    return (
      <SecondLevelReportsContainer
        goToSingleLoanReports={this.goToSingleLoanReports}
        goToAllLoanReports={this.goToAllLoanReports}
        user={this.state.activeTab.value}
        goBack={this.goBack}
        callbackOnRowClick={this.callbackOnSecondReportsRowClick}
        history={this.props.history}
      />
    );
  };

  renderAllReports = () => {
    return (
      <TabPanel className='WorkHistoryContainer'>
        <Switch>
          <Route
            exact
            path={[
              ROUTES.REPORTS,
              ROUTES[`${this.state.activeTab.value}_REPORTS`],
            ]}
            render={() => this.renderFirstLevelAllReport()}
          />
          <Route
            exact
            path={
              ROUTES[
              `REPORTS_FOR_SINGLE_${this.state.activeTab.value}`
              ]
            }
            render={() => this.renderSecondLevelAllReport()}
          />
        </Switch>
      </TabPanel>
    );
  };

  renderReports = () => {
    return (
      <TabPanel className='WorkHistoryContainer'>
        <Switch>
          <Route
            exact
            path={[
              ROUTES.REPORTS,
              ROUTES[`${this.state.activeTab.value}_REPORTS`],
            ]}
            render={() => this.renderFirstLevelReport()}
          />
          <Route
            exact
            path={
              ROUTES[
              `REPORTS_FOR_SINGLE_${this.state.activeTab.value}`
              ]
            }
            render={() => this.renderSecondLevelReport()}
          />
        </Switch>
      </TabPanel>
    );
  };
  render() {
    return (
      <div className='generalReports'>
        <Tabs
          selectedIndex={this.state.activeTab.key}
          onSelect={this.handleTabs}
        >
          <TabList>
            {this.state.Tabs.map((item) => (
              <Tab
                key={item.key}
                className={
                  item.value === this.state.activeTab.value
                    ? "react-tabs__tab react-tabs__tab--selected"
                    : "react-tabs__tab "
                }
              >
                {item.label}{" "}
              </Tab>
            ))}
          </TabList>
          {this.state.Tabs.length > 1 ? (
            <div>
              {this.renderAllReports()}
              {this.renderReports()}
              {this.renderReports()}
              {this.renderReports()}
              {this.renderReports()}
              {this.renderAllReports()}
            </div>
          ) : (
            this.renderAllReports()
          )}
        </Tabs>
      </div>
    );
  }
}

export default withRouter(GeneralReports);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Button, Col, Form } from "react-bootstrap";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Actions
import {
  updateBranchAction,
  createBranchAction,
  deactivateBranchAction,
  getZonesUnderSelectedBranchAction,
} from "./BranchListAction";
import { setSearchFilterAction } from "../../constants/Action";

// Styles
import "./BranchList.scss";

//Components
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

//Constants
import {
  defaultObjForAddOrUpdateBranch,
  GET_BRANCH_STATUS_DROPDOWN,
  DEFAULT_STATUS,
  STATE_VARIABLES,
  DEFAULT_ZONE,
  GET_DEFAULT_ZONE_SELECTION,
} from "./BranchListConstants";
import Utility from "../../utils/Utility";
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import {
  USER_ROLES,
  SEARCH_FILTER_PAGES,
  BRANCH_LIST_FILTER,
} from "../../constants/appConstants";

class BranchDetails extends React.Component {
  constructor(props) {
    super(props);
    //Create Branch object base on values present in Branch reducer
    let Branch =
      JSON.stringify(this.props.selectedBranchDetails) !==
        JSON.stringify(defaultObjForAddOrUpdateBranch)
        ? this.props.selectedBranchDetails
        : defaultObjForAddOrUpdateBranch;
    this.state = {
      Branch: Branch,
      cachedBranch: Branch,
      disableInput: false,
      disableSave: true,
      deactivateBranchModal: false,
      validName: true,
      validNumber: true,
      showLoadingModal: false,
      errorMessage: "",
      zones: props.zones,
      sellerZones: [],
    };
  }

  componentDidMount() {
    JSON.stringify(this.props.selectedBranchDetails) !==
      JSON.stringify(defaultObjForAddOrUpdateBranch) &&
      this.getZonesUnderSelectedBranch(
        this.props.selectedBranchDetails.id
      );
    this.setState({ showLoadingModal: false });
  }

  componentWillUnmount() {
    if (
      this.state.zones &&
      this.state.zones.length > 0 &&
      this.state.zones[0].name !== DEFAULT_ZONE
    ) {
      let zonesDrodown = this.props.zones;
      zonesDrodown.unshift(GET_DEFAULT_ZONE_SELECTION());
      this.setState({ zones: zonesDrodown });
    }
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.BRANCH_LIST &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.BRANCH_LIST,
        { ...BRANCH_LIST_FILTER }
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //handled change in reducer
    if (
      prevState.disableInput &&
      nextProps.selectedBranchDetails !== prevState.Branch
    ) {
      return {
        Branch:
          JSON.stringify(nextProps.selectedBranchDetails) !==
            JSON.stringify(defaultObjForAddOrUpdateBranch)
            ? nextProps.selectedBranchDetails
            : defaultObjForAddOrUpdateBranch,
        disableInput:
          JSON.stringify(nextProps.selectedBranchDetails) !==
            JSON.stringify(defaultObjForAddOrUpdateBranch)
            ? true
            : false,
        cachedBranch:
          JSON.stringify(nextProps.selectedBranchDetails) !==
            JSON.stringify(defaultObjForAddOrUpdateBranch)
            ? nextProps.selectedBranchDetails
            : defaultObjForAddOrUpdateBranch,
      };
    }
    if (nextProps.zones !== prevState.zones) {
      if (
        prevState.zones &&
        prevState.zones[0].name !== DEFAULT_ZONE
      ) {
        let zonesDropdown = nextProps.zones;
        zonesDropdown.unshift(GET_DEFAULT_ZONE_SELECTION());
        return { zones: zonesDropdown };
      }
    }
    return null;
  }

  //Added check for if fields are empty then disable save
  checkForDisableSave = () => {
    let disableSave = true;
    if (
      this.state.cachedBranch.name.trim() !== "" &&
      this.state.cachedBranch.address.trim() !== "" &&
      this.state.cachedBranch.address.length <= 100 &&
      this.state.cachedBranch.phone.length === 10 &&
      this.state.cachedBranch.active !== DEFAULT_STATUS &&
      this.state.cachedBranch.active !== ""
    ) {
      disableSave = false;
    }
    this.setState({
      disableSave: disableSave,
    });
  };

  //Handle Changed  name of branch
  onChangeName = (e) => {
    this.setState(
      {
        cachedBranch: {
          ...this.state.cachedBranch,
          name: e.target.value,
        },
        validName: true,
        errorMessage: "",
      },
      this.checkForDisableSave
    );
  };

  //Handle Changed address of branch
  onChangeBranchAddress = (e) => {
    this.setState(
      {
        cachedBranch: {
          ...this.state.cachedBranch,
          address: e.target.value,
        },
        errorMessage: "",
      },
      this.checkForDisableSave
    );
  };
  //Handle Changed Status ofBranch
  onChangeBranchStatus = (e) => {
    this.setState(
      {
        cachedBranch: {
          ...this.state.cachedBranch,
          active: e.target.value,
        },
        errorMessage: "",
      },
      this.checkForDisableSave
    );
  };


  //Handle Changed phone no. of branch
  onChangePhone = (e) => {
    this.setState(
      {
        cachedBranch: {
          ...this.state.cachedBranch,
          phone: e.target.value,
        },
        errorMessage: "",
      },
      this.checkForDisableSave
    );
  };

  onSaveSucces = (key) => {
    this.setState({
      showLoadingModal: false,
      [key]: true,
      errorMessage: "",
      disableInput: true,
    });
    this.props.history.push(ROUTES.BRANCH_LIST);
  };

  onSaveFailed = (errorMessage) => {
    this.setState({
      errorMessage,
      showLoadingModal: false,
    });
  };

  //Handle onClcik of save button
  onSaveClick = (e) => {
    let validname = true;
    let name = this.state.cachedBranch.name.trim();
    if (!Utility.isValidName(name)) {
      validname = false;
    }
    this.setState({
      validName: validname,
    });
    if (
      name !== "" &&
      this.state.cachedBranch.name.trim() !== "" &&
      this.state.cachedBranch.phone.length === 10 &&
      this.state.cachedBranch.address.length <= 100 &&
      validname
    ) {
      this.setState({ showLoadingModal: true });
      let apiCall = this.props.createBranchAction;
      let key = STATE_VARIABLES.CREATE_SUCCESS;
      if (this.state.Branch.name !== "") {
        apiCall = this.props.updateBranchAction;
        key = STATE_VARIABLES.UPDATE_SUCCESS;
      }
      apiCall(this.state.cachedBranch, (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          this.onSaveSucces(key);
        } else {
          this.onSaveFailed(apiResponse.error.message);
        }
      });
    }
  };

  onBackClick = () => {
    this.props.history.push(ROUTES.BRANCH_LIST);
  };


  onYesClick = () => {
    this.setState({ showLoadingModal: true });
    this.props.deactivateBranchAction(
      this.state.cachedBranch,
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          this.props.history.push({
            pathname: ROUTES.BRANCH_LIST,
            state: { branchDeactivated: true },
          });
        } else {
          this.setState({
            errorMessage: apiResponse.error.message,
          });
        }
        this.setState({ showLoadingModal: false });
      }
    );
  };
  onNoClick = () => {
    this.setState({ deactivateBranchModal: false });
  };

  getZonesUnderSelectedBranch = (branchId) => {
    this.setState({ showLoadingModal: true });
    this.props.getZonesUnderSelectedBranchAction(
      branchId,
      (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          if (Response.data.data.length > 0) {
            this.setState({
              errorMessage: "",
              sellerZones: Response.data.data[0].zones,
              messengerZones: Response.data.data[0].mzones,
              showLoadingModal: false,
            });
          } else {
            this.setState({
              errorMessage: "",
              sellerZones: [],
              showLoadingModal: false,
            });
          }
        } else {
          this.setState({
            showLoadingModal: false,
            errorMessage: Response.error.message,
          });
        }
      }
    );
  };

  validateNumberField = (event) => {
    if (!Utility.checkIfNumber(event) || event.shiftKey) {
      event.preventDefault();
    }
  };

  render() {
    const BRANCH_STATUS_DROPDOWN = GET_BRANCH_STATUS_DROPDOWN();
    return (
      <div className='branchList'>
        {this.state.createSuccess && (
          <UserNotification
            key='branchCreatedSuccessfully'
            userNotificationObj={{
              message: Strings("BranchCreatedSuccessfully"),
              level: "success",
            }}
          />
        )}
        {this.state.updateSuccess && (
          <UserNotification
            key='branchUpdatedSuccessfully'
            userNotificationObj={{
              message: Strings("BranchUpdatedSuccessfully"),
              level: "success",
            }}
          />
        )}
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        <UserModal
          open={this.state.deactivateBranchModal}
          modalCloseOnEsc={false}
          modalCloseOnOverlayClick={false}
          modalShowCloseIcon={false}
          headerText={Strings("DeactivateBranch")}
          modalText1={Strings("DoYouWantToDeactivateBranch")}
          modalButtonOk={{
            text: Strings("Yes"),
            onClick: this.onYesClick,
          }}
          modalButtonCancel={{
            text: Strings("No"),
            onClick: this.onNoClick,
          }}
          showHr={true}
        />
        <Row className='addOrUpdateBranchMainPanel'>
          <Col
            md={12}
            className='mainHeaderApplication addOrUpdateBranchHeader noPadding'
          >
            {JSON.stringify(this.props.selectedBranchDetails) !==
              JSON.stringify(defaultObjForAddOrUpdateBranch) ? (
              <Row className='heading'>
                {Strings("BranchManagement")} -{" "}
                {Strings("BranchDetails")}
              </Row>
            ) : (
              <Row className=' heading'>
                {Strings("BranchManagement")} - {Strings("AddBranch")}
              </Row>
            )}

            {this.state.validName === false && (
              <div className='errors'>
                {Strings("ErrorInvalidBranchName")}
              </div>
            )}
            {this.state.errorMessage !== "" && (
              <div className='errors'>{this.state.errorMessage}</div>
            )}

            <Row className='positionAddOrUpdateBranchMainPanel ListContainer'>
              <Col md={6} className='noPadding'>
                <div className='parentOfImages' />
                <Row
                  md={12}
                  className='subHeaderApplication addOrUpdateBranchFontSize'
                >
                  <Col
                    md={6}
                    className='addOrUpdateBranchPaddingLeft0'
                  >
                    {Strings("BranchName")}
                    <span className='mandatoryFields'>*</span>
                    <Row className='addOrUpdateBranchExtraMargin'>
                      <Col
                        md={12}
                        className='addOrUpdateBranchFirstName'
                      >
                        <TextFieldComponent
                          id='name'
                          type='text'
                          className='noMargin'
                          placeholder={Strings("BranchName")}
                          handleChange={this.onChangeName}
                          value={this.state.cachedBranch.name}
                          disabled={this.state.disableInput}
                        />
                      </Col>

                      {Strings("Address")}
                      <span className='mandatoryFields'>*</span>
                      <Col
                        md={12}
                        className='noPadding addOrUpdateBranchExtraMargin'
                      >
                        <TextFieldComponent
                          id='BranchAddress'
                          type='text'
                          maxLength={100}
                          className='noMargin'
                          placeholder={Strings("Address")}
                          handleChange={this.onChangeBranchAddress}
                          value={this.state.cachedBranch.address}
                          disabled={this.state.disableInput}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={6}
                    className='addOrUpdateBranchPaddingLeft0'
                  >
                    {Strings("PhoneNumber")}
                    <span className='mandatoryFields'>*</span>
                    <Row className='addOrUpdateBranchExtraMargin'>
                      <Col
                        md={12}
                        className='addOrUpdateBranchFirstName'
                      >
                        <TextFieldComponent
                          id='phone'
                          type='number'
                          className='noMargin'
                          placeholder={Strings("PhoneNumber")}
                          maxLength={10}
                          handleChange={this.onChangePhone}
                          value={this.state.cachedBranch.phone}
                          disabled={this.state.disableInput}
                          onKeyDown={this.validateNumberField}
                        />
                      </Col>

                      {Strings("Status")}
                      <span className='mandatoryFields'>*</span>

                      <Col
                        md={12}
                        className='noPadding addOrUpdateBranchExtraMargin'
                      >
                        <TextFieldComponent
                          type='select'
                          handleChange={this.onChangeBranchStatus}
                          value={this.state.cachedBranch.active}
                          disabled={this.state.disableInput}
                        >
                          {BRANCH_STATUS_DROPDOWN.map((status) => {
                            return (
                              <option
                                key={status.key}
                                value={status.value}
                              >
                                {status.label}
                              </option>
                            );
                          })}
                        </TextFieldComponent>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={12}
                    className='noMargin addOrUpdateBranchPaddingLeft0'
                  >
                    {this.state.sellerZones &&
                      this.state.sellerZones.length > 0 &&
                      JSON.stringify(
                        this.props.selectedBranchDetails
                      ) !==
                      JSON.stringify(
                        defaultObjForAddOrUpdateBranch
                      ) ? (
                      <div>
                        <Form.Group controlId='exampleForm.ControlTextarea1'>
                          <Form.Label>{Strings("sellerZones")}</Form.Label>
                          <Form.Control
                            as='textarea'
                            className='maxArea disabled'
                            readOnly
                            rows={
                              this.state.sellerZones.length
                            }
                            value={this.state.sellerZones}   
                          />
                        </Form.Group>
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={12}
                    className='noMargin addOrUpdateBranchPaddingLeft0'
                  >
                    {this.state.messengerZones &&
                      this.state.messengerZones.length > 0 &&
                      JSON.stringify(
                        this.props.selectedBranchDetails
                      ) !==
                      JSON.stringify(
                        defaultObjForAddOrUpdateBranch
                      ) ? (
                      <div>
                        <Form.Group controlId='exampleForm.ControlTextarea1'>
                          <Form.Label>{Strings("messengerZones")}</Form.Label>
                          <Form.Control
                            as='textarea'
                            className='maxArea disabled'
                            readOnly
                            rows={
                              this.state.messengerZones.length
                            }
                            value={this.state.messengerZones}
                          />
                        </Form.Group>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
              <Row className='alignActionButtons'>
                {JSON.stringify(this.props.selectedBranchDetails) !==
                  JSON.stringify(defaultObjForAddOrUpdateBranch) &&
                  this.props.loggedInUserInfo &&
                  this.props.loggedInUserInfo.role ===
                  USER_ROLES.admin ? (
                  <Col
                    md={{ span: 3, offset: 3 }}
                    className='noPadding alignDeactivate'
                  ></Col>
                ) : (
                  <Col md={6}></Col>
                )}
                <Col md={6}>
                  <div className='saveOrCancelButton'>
                    <Button
                      className='buttonBorder pull-left mr-1'
                      onClick={this.onBackClick}
                    >
                      {Strings("Cancel")}
                    </Button>
                    {!this.state.disableInput ? (
                      <Button
                        className='buttonBackground pull-right'
                        onClick={this.onSaveClick}
                        disabled={this.state.disableSave}
                      >
                        {Strings("Save")}
                      </Button>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUserInfo: state.loginReducer.loggedInUserInfo,
  selectedBranchDetails: state.branchListReducer.branchDetails,
  zones: state.branchListReducer.zones,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateBranchAction,
      createBranchAction,
      deactivateBranchAction,
      getZonesUnderSelectedBranchAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchDetails);

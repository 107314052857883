import React from "react";

//UI Components
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./VerifyCollection.scss";

//Utility
import Utility from "../../utils/Utility";
class VerificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      { key: "mid", name: Strings("MatricesID"), minWidth: 80 },
      { key: "cd", name: Strings("code"), minWidth: 50 },
      { key: "nm", name: Strings("name"), minWidth: 50 },
      { key: "amt", name: Strings("amount"), minWidth: 50 },
      { key: "rs", name: Strings("receipt"), minWidth: 50 },
    ];
    this.state = {
      open: false,
      errorMessage: "",
      selectedOptions: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      open: nextProps.open,
    };
  }

  handleFilterCheckbox = (checkboxValue) => (event) => {
    let selectedOptions = this.state.selectedOptions;
    let indexOfOption = selectedOptions.indexOf(checkboxValue.id);
    if (indexOfOption === -1) {
      selectedOptions.push(checkboxValue.id);
    } else {
      selectedOptions.splice(indexOfOption, 1);
    }
    this.setState({ selectedOptions, errorMessage: "" });
  };

  //to close modal
  closeModal = () => {
    this.setState(
      {
        open: false,
      },
      () => {
        this.props.modalButtonCancel.onClick();
      }
    );
  };

  handleOnClickOk = () => {
    this.closeModal();
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "amt":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "rs":
        return row["rs"].map((receipt) => {
          return (
            <span
              key={receipt.rid}
              className='url cursorPointer textColor'
              onClick={() => {
                receipt.rt && receipt.rt === "DOWNPAYMENT"
                  ? this.props.downloadDownpaymentReceipt(
                      receipt.rid,
                      receipt.aid
                    )
                  : this.props.viewReceipt(receipt.rid);
              }}
            >
              {receipt.rid}
            </span>
          );
        });

      default:
        return row[key] ? Strings(row[key]) : Strings("NA");
    }
  };

  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d5d5d5",
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer
          component={Paper}
          className='d-flex justify-content-center'
          sx={{ maxHeight: 440 }}
        >
          <Table aria-label='customized table' stickyHeader>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key
                          ? "text-left px-2 header"
                          : "text-center px-2 header"
                      }
                      title={column.label}
                    >
                      {column.name}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.mid}
                  hover
                  style={{ cursor: "pointer" }}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key
                          ? "text-left px-2"
                          : "text-center px-2"
                      }
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ width: column.minWidth }}
                    >
                      {this.renderTableData(row, column.key)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    return (
      <div>
        {/* general modal */}
        <Modal
          // Change visibility of modal based on props.
          open={this.state.open}
          onClose={this.closeModal}
          //Modal opens with specified messages in props
          closeOnEsc={this.props.modalCloseOnEsc}
          closeOnOverlayClick={this.props.modalCloseOnOverlayClick}
          showCloseIcon={this.props.modalShowCloseIcon}
          classNames={{ modal: "verificationModal" }}
          center
        >
          <Row className='verificationModal'>
            <Col md={12} className='headerModal'>
              {this.props.headerText}
            </Col>
            {/* Show only specified props of modal */}
            <Col md={12} className='modalContent pt-3'>
              {this.state.errorMessage && (
                <Row className='errors centerAlign'>
                  {this.state.errorMessage}
                </Row>
              )}

              {this.props.minimartData.length > 0 &&
                this.renderCustomDataGrid(
                  this.columns,
                  this.props.minimartData
                )}
              {this.props.showHr && <hr />}

              <div className='FileModalButton'>
                {this.props.modalButtonCancel && (
                  <Button
                    className={
                      "primaryModalButton buttonMinWidth marginCancelButton buttonBorder pull-right" +
                      this.props.modalButtonCancelStyle
                    }
                    onClick={this.closeModal}
                  >
                    {this.props.modalButtonCancel.text}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default VerificationModal;

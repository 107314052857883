/* #region Login  */
export const SET_LOGIN_INFO_ACTION = "set_login_info_action";
export const CLEAR_LOGIN_INFO_ACTION = "clear_login_info_action";
export const SET_PROFILE_PIC_ACTION = "set_profile_pic_action";
export const UPDATE_PROFILE_INFO_ACTION =
  "update_profile_info_action";
/* #endregion */

/* #region Logout  */
export const LOGOUT_ACTION = "logout_action";
/* #endregion */

/* #region Profile  */
export const SET_USER_PROFILE_ACTION = "set_user_profile_action";
export const CLEAR_USER_PROFILE_ACTION = "clear_user_profile_action";
/* #endregion */

/* #region sidebar  */
export const SET_CURRENT_FEATURE_ACTION =
  "set_current_feature_action";
/* #endregion */

/* #region User Management  */
export const SET_USER_DETAILS_ACTION = "set_user_details_action";
export const CLEAR_USER_DETAILS_ACTION = "clear_user_details_action";
export const SET_USERS_ACTION = "ser_users_action";
export const SET_USER_ROLES_ACTION = "set_user_roles_action";
export const CLEAR_USER_ROLES_ACTION = "clear_user_roles_action";
export const CLEAR_USER_REDUCER_ACTION = "clear_user_reducer_action";
export const SET_ADD_USER_FLAG = "set_add_user_flag";
/* #endregion */

/* #region Loan Provision */
export const SET_ACTIVE_LOAN_PROVISION_DATA =
  "set_active_loan_provision_data";
export const CLEAR_ACTIVE_LOAN_PROVISION_DATA =
  "clear_active_loan_provision_data";
export const SET_LOAN_DETAILS_ACTION = "set_loan_details_action";
export const SET_ANALYST_LOAN_DETAILS_ACTION =
  "set_analyst_loan_details_action";
/* #endregion */

/* #region Loan Status  */
export const SET_ACTIVE_LOAN_STATUS_DATA =
  "set_active_loan_status_data";
export const CLEAR_ACTIVE_LOAN_STATUS_DATA =
  "clear_active_loan_status_data";
/* #endregion */

/* #region Late Fees  */
export const SET_ACTIVE_LATE_FEES_DATA = "set_active_late_fees_data";
export const CLEAR_ACTIVE_LATE_FEES_DATA =
  "clear_active_late_fees_data";
/* #endregion */

/* #region Branch Management  */
export const SET_BRANCHES_ACTION = "set_branches_action";
export const CLEAR_BRANCHES_ACTION = "clear_branches_action";
export const SET_BRANCH_DETAILS_ACTION = "set_branch_details_action";
export const SET_ZONES_ACTION = "set_zones_action";
export const SET_MZONES_ACTION = "set_mzones_action";
export const CLEAR_BRANCH_DETAILS_ACTION =
  "clear_branch_details_action";
/* #endregion */

/* #region Client Loan Status  */
export const SET_CLIENT_LOAN_STATUS = "set_client_loan_status";
export const CLEAR_CLIENT_LOAN_STATUS = "clear_client_loan_status";
/* #endregion */

/* #region CI_INTERFACE  */
export const SET_DATE_ACTION = "set_date_action";
export const SET_FILE_DETAILS_ACTION = "set_file_details_action";
export const CLEAR_CI_REDUCER_ACTION = "clear_ci_reducer_action";
/* #endregion */

/* #region MINIMART_MANAGEMENT  */
export const SET_MINIMART_DETAILS_ACTION =
  "set_minimart_details_action";
/* #endregion */

/* #region DAILY_STATUS  */

export const SET_SALES__PERSON_ACTION = "set_sales_person_action";
export const SET_DATE_FOR_SALES__PERSON_ACTION =
  "set_date_for_sales_person_action";
export const SET_MESSENGER_ACTION = "set_messenger_action";
export const SET_DATE_FOR_MESSENGER_ACTION =
  "set_date_for_messenger_action";
export const CLEAR_DAILYSTATUS_DATE = "clear_dailyStatus_date";
/* #endregion */

/* #region CONFIGURATION  */
export const SET_DATA_FOR_EDIT_CONFIGURATION =
  "set_data_for_edit_configuration";
export const CLEAR_DATA_FOR_EDIT_CONFIGURATION =
  "clear_data_for_configuration";
/* #endregion */

// Work History Action
export const SET_WORK_HISTORY_FILTERS_ACTION =
  "set_work_history_filters_action";
export const CLEAR_WORK_HISTORY_FILTERS_ACTION =
  "clear_work_history_action";
export const SET_USER_ID_ACTION = "set_user_id_action";
/* #region WORKLOAD MANAGEMENT  */

export const SET_USERS_FOR_WORKLOAD_ACTION =
  "set_users_for_workload_management";
export const CLEAR_WORKLOAD_USERS =
  "clear_users_of_workload_management";

//Reports
export const SET_REPORTS_FILTERS_ACTION =
  "set_reports_filters_action";
export const CLEAR_REPORTS_FILTERS_ACTION = "clear_reports_action";
export const SET_REPORTS_ID_ACTION = "set_reports_id_action";
export const SET_REPORTS_ACTION = "set_reports_action";

/* #endregion */

// ci wire transfer
export const SET_CI_WIRE_ACTION = "get_all_ci_transfer";
export const COMMISSION_REPORT_ACTION = "commission_report_action";
export const DETAIL_COMMISSION_REPORT_ACTION =
  "detail_commission_report_action";

// approve minimart
export const SET_APPROVE_MINIMART_DETAILS_ACTION =
  "set_approve_minimart_details_action";
export const SET_APPROVEMINIMART_TAB = "set_approve_minimart_tab";
export const SET_REMINDER_MINIMART_COUNT =
  "set_reminder_minimart_count";
export const CLEAR_MINIMART_ACTION = "clear_minimart_action";

export const SET_TWO_SETP_ACTION = "set_two_step_action";

// #region Salesperson efficiency dashboard
export const SET_SALESPERSON_EFFICIENCY_DATA =
  "set_salesperson_efficiency_data";
export const SET_SALESPERSON_SEARCHFILTER_DATA =
  "set_salesperson_searchfilter_data";
export const CLEAR_EFFICIENCY_ACTION = "clear_efficiency_action";

export const CLEAR_SEARCH_FILTER_ACTION =
  "clear_search_filter_action";
export const SET_SEARCH_FILTER_ACTION = {
  LOAN_APPROVAL: "set_loan_approval",
  OPERATIONS_LOAN_APPROVAL: "set_operations_loan_approval",
  MINIMART_LIST: "set_minimart_list",
  APPROVAL_DASHBOARD: "set_approval_dashboard",
  LOAN_HISTORY: "set_loan_history",
  SPED_L1: "set_sped_l1",
  SPED_L2: "set_sped_l2",
  LOAN_PORTFOLIO: "set_loan_portfolio",
  COMMISSION: "set_commission",
  COMMISSION_REPORT: "set_commission_report",
  USER_LIST: "set_user_list",
  BRANCH_LIST: "set_branch_list",
  CI_INTERFACE: "set_ci_interface",
  CI_LOAN_REQUEST_REPORT: "set_ci_loan_request_report",
  FIRST_LEVEL_REPORT: "set_first_level_report",
  SECOND_LEVEL_REPORT: "set_second_level_report",
  DAILY_STATUS: "set_daily_status",
  PERSON_DAILY_STATUS: "set_person_daily_status",
  COLLECTION_VERIFICATION: "set_collection_verification",
  WORK_HISTORY: "set_work_history",
  CONFIGURATION: "set_configuration",
  LOAN_DETAIL: "set_loan_detailt",
  MINIMART_HISTORY: "set_minimart_history",
  NEW_MINIMART_LIST: "set_new_minimart_list",
  WORKLOAD_MANAGEMENT: "set_workload_management",
  SIGNUP_REQUEST: "signup_request",
  ANALYST_LOAN_DETAIL: "set_analyst_loan_detail",
  PROMOTION_APPROVAL: "set_promition_approval",
  PROMOTION_LOAN_DETAIL: "set_promotion_loan_detail",
  REWARD_APPROVAL: "set_reward_approval",
  CI_MINIMART: "set_ci_minimart",
  CLIENT_PORTAL: "set_client_portal"
};

export const SET_ROW_CLICK = "set_row_click";

export const SET_MINIMART_DETAILS_ACTIVE_TAB =
  "set_minimart_details_active_tab";

export const SET_IS_NEW_MINIMART = "set_is_new_minimart";
export const SET_SIGN_UP_MINIMART = "set_sign_up_minimart";
export const SET_PROMOTION = "set_promotion";
export const SET_REWARD = "set_reward";
export const SET_EMI_CONFIGURATION_ACTION = "set_emi_configuration_action";
// #endregion

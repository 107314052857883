import React from "react";
import { Row, Col } from "react-bootstrap";
import EditableDataTable from "../CustomUIComponents/EditableDataTable/EditableDataTable";

import "./CustomConfiguration.scss";

import { COLUMNS_FOR_CONFIGURATION } from "../../constants/appConstants";

class SingleValuedConfiguration extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this.state = {
      dataForTable: props.dataForTable,
      showLoader: false,
    };
  }

  componentDidMount() {
    this.props.renderDataForDataTable();
  }

  /* #endregion */

  /* #region  Events */

  handleChangeValue = (key, value) => {
    this.props.handleChangeValue(key, value);
    this.props.resetNotification();
  };

  saveEditedDataInTheState = (rowData) => {
    if (this.props.dataForTable) {
      let dataRows = this.props.dataForTable.dataRows;
      let cellValue, dataType, configuration;
      configuration = COLUMNS_FOR_CONFIGURATION[this.props.type];
      dataType = configuration[0].dataType;
      let key = Object.keys(dataRows[0]);
      cellValue = dataRows[0][key];
      this.props.saveEditedDataInTheState(
        rowData,
        dataType,
        dataRows,
        cellValue
      );
    }
  };

  handleOnClickAction = (configAction) => {
    this.props.handleOnClickAction(configAction);
  };

  /* #endregion */

  render() {
    return (
      <div className='customConfiguration containerPadding d-flex justify-content-center'>
        {this.props.dataForTable && (
          <Row className='d-flex justify-content-center'>
            <Col md={3} className='pull-right'>
              <EditableDataTable
                readOnly={this.props.readOnly}
                resetData={
                  // this.state.dataForTable
                  // A condition for changing the Data in the component
                  // In case of Reset same data for both editedConfiguration and configuration is used
                  // So that is the condition which tells EditableDataTable to change Data
                  this.state.configuration !==
                  this.state.editedConfiguration
                }
                data={this.props.dataForTable}
                rowDelete={false}
                rowAdd={false}
                onRowDataChange={this.saveEditedDataInTheState}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default SingleValuedConfiguration;

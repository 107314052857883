import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getPromotionsListAction = (reqObj, callback) => {
  reqObj.search.ty =
    reqObj.search.ty === "" ? null : reqObj.search.ty;
  reqObj.search.lt =
    reqObj.search.lt === "" ? null : reqObj.search.lt;
  reqObj.search.bty =
    reqObj.search.bty === "" ? null : reqObj.search.bty;
  reqObj.search.st =
    reqObj.search.st === "" ? null : reqObj.search.st;
  reqObj.search.ttl =
    reqObj.search.ttl === "" ? null : reqObj.search.ttl;
  callAPI(
    "getMtpPromotions",
    API.getMtpPromotions,
    "POST",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const addNewPromotionAction = (reqObj, callback) => {
  let requestInfo = new FormData();

  //TO DO - write a utility to create formdata parameter
  requestInfo.append("title", reqObj.title);
  requestInfo.append("levelNeeded", reqObj.levelNeeded);
  requestInfo.append("gradeNeeded", reqObj.gradeNeeded);
  reqObj.rewardPoints &&
    requestInfo.append("rewardPoints", reqObj.rewardPoints);
  reqObj.discount && requestInfo.append("discount", reqObj.discount);
  reqObj.minLoanAmount &&
    requestInfo.append("minLoanAmount", reqObj.minLoanAmount);
  requestInfo.append("loanType", reqObj.productName);
  requestInfo.append("description", reqObj.description);
  requestInfo.append("editedBy", reqObj.editedBy);
  requestInfo.append("activationDate", reqObj.activationDate);
  requestInfo.append("expiryDate", reqObj.expiryDate);
  requestInfo.append("file", reqObj.file);
  requestInfo.append(
    "maxRewardPointsNeeded",
    reqObj.maxRewardPointsNeeded
  );
  requestInfo.append(
    "minRewardPointsNeeded",
    reqObj.minRewardPointsNeeded
  );
  requestInfo.append("type", reqObj.type);
  requestInfo.append("benefitType", reqObj.benefitType);

  callAPI(
    "addMtpPromotion",
    API.addMtpPromotion,
    "POST",
    requestInfo,
    (apiResponse) => {
      callback(apiResponse);
    }, false, true
  );
};

export const updatePromotionAction = (reqObj, callback) => {
  let requestInfo = new FormData();

  requestInfo.append("id", reqObj.id);
  requestInfo.append("title", reqObj.title);
  requestInfo.append("levelNeeded", reqObj.levelNeeded);
  requestInfo.append("gradeNeeded", reqObj.gradeNeeded);
  reqObj.rewardPoints &&
    requestInfo.append("rewardPoints", reqObj.rewardPoints);
  reqObj.discount && requestInfo.append("discount", reqObj.discount);
  reqObj.minLoanAmount &&
    requestInfo.append("minLoanAmount", reqObj.minLoanAmount);
  requestInfo.append("loanType", reqObj.productName);
  requestInfo.append("description", reqObj.description);
  requestInfo.append("eby", reqObj.eby);
  requestInfo.append("activationDate", reqObj.activationDate);
  requestInfo.append("expiryDate", reqObj.expiryDate);
  requestInfo.append(
    "maxRewardPointsNeeded",
    reqObj.maxRewardPointsNeeded
  );
  requestInfo.append(
    "minRewardPointsNeeded",
    reqObj.minRewardPointsNeeded
  );
  requestInfo.append("type", reqObj.type);
  requestInfo.append("benefitType", reqObj.benefitType);
  reqObj.file && requestInfo.append("file", reqObj.file || undefined);

  callAPI(
    "updateMtpPromotion",
    API.updateMtpPromotion,
    "PATCH",
    requestInfo,
    (apiResponse) => {
      callback(apiResponse);
    }, false, true
  );
};

export const deletePromotionAction = (reqObj, callback) => {
  callAPI(
    "deleteMtpPromotion",
    API.deleteMtpPromotion + "?id=" + reqObj.id,
    "DELETE",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getProductListAction = (request, callback) => {
  callAPI(
    "getMtpProducts",
    API.getMtpPromotionsProduct,
    "GET",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

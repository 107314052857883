// Constants
import {
  SET_REPORTS_FILTERS_ACTION,
  CLEAR_REPORTS_FILTERS_ACTION,
  SET_REPORTS_ID_ACTION,
  SET_REPORTS_ACTION,
  SET_TWO_SETP_ACTION,
} from "../../redux/constants";

const INITIAL_STATE = {
  filters: {},
  reports: null,
  userId: null,
  isTwoStepAction: false,
};

export default function ReportsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_REPORTS_FILTERS_ACTION:
      return {
        ...state,
        filters: action.filters,
      };
    case SET_REPORTS_ID_ACTION:
      return {
        ...state,
        userId: action.userId,
      };
    case SET_REPORTS_ACTION:
      return {
        ...state,
        reports: action.reports,
      };
    case CLEAR_REPORTS_FILTERS_ACTION:
      return { state: INITIAL_STATE };
    case SET_TWO_SETP_ACTION:
      return {
        ...state,
        isTwoStepAction: action.twoStepAction,
      };
    default:
      return state;
  }
}

import React from "react";
import * as _ from "lodash";
import moment from "moment";
import { Row, Col, Button } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Components
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import VerificationModal from "./VerificationModal";
import {
  MessengerProfile,
  CollectionView,
  Receipt,
} from "./VerifyCollectionComponents";

import { INVOICE_DATE_FORMAT } from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

// Assets
import green_tick from "../../resources/images/Group_427.svg";
import red_tick from "../../resources/images/Group_428.svg";

// Styles
import "./VerifyCollection.scss";

//Utils
import Utility from "../../utils/Utility";

class VerifyCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyModal: false,
      receiptUrl: props.receiptUrl,
      profileDetails: props.messengerDetails,
      collectionData: null,
      data: [],
      selectedIndex: null,
      status: 0,
      idOfReceipt: "",
      showTable: false,
    };
  }
  columns = [
    {
      key: "ts",
      name: Strings("DateAndTime"),
    },
    {
      key: "amt",
      name: Strings("amountDeposited"),
    },
    {
      key: "amtVrf",
      name: Strings("amountVerified"),
    },
    {
      key: "id",
      name: Strings("DepositId"),
    },
    {
      key: "st",
      name: Strings("actionStatus"),
    },
  ];

  static getDerivedStateFromProps(props, state) {
    let derivedState = {};
    if (props.receiptUrl !== state.receiptUrl) {
      derivedState = {
        ...derivedState,
        receiptUrl: props.receiptUrl,
        reEnteredAmount: "",
      };
    }
    if (
      !_.isEqual(props.data, state.data) ||
      !_.isEqual(props.collectionData, state.collectionData)
    ) {
      derivedState = {
        data: props.data,
        collectionData: props.collectionData,
        profileDetails: props.messengerDetails,
      };
    }

    return derivedState;
  }
  rowGetter = (i) => {
    return this.state.data[i];
  };

  handleReEnterAmount = (event) => {
    //Validation for number input only
    if (isNaN(event.target.value)) {
      return false;
    }
    this.setState({
      reEnteredAmount: event.target.value,
      verifyModal: false,
      errorMessage: "",
    });
  };

  onBackClick = () => {
    this.props.onBackClick();
  };

  onVerifyClick = () => {
    let status = 0;
    if (
      this.state.reEnteredAmount === "" ||
      Number(this.state.reEnteredAmount) >
      Number(this.state.depositedAmount)
    ) {
      this.setState({
        status: 0,
        errorMessage: Strings("verifyCollectionAmountError"),
      });
    } else if (
      Number(this.state.depositedAmount) ===
      Number(this.state.reEnteredAmount)
    ) {
      status = 1;
      this.setState({
        verifyModal: true,
        status: status,
      });
    } else if (
      Number(this.state.depositedAmount) >
      Number(this.state.reEnteredAmount)
    ) {
      status = 2;
      this.setState({
        verifyModal: true,
        status: status,
      });
    }
  };

  onCancelClick = () => {
    this.setState({ reEnteredAmount: "", errorMessage: "" });
  };

  onYesClick = () => {
    this.setState(
      {
        actualAmount: this.state.reEnteredAmount,
        verifyModal: false,
        reEnteredAmount: "",
      },
      () => {
        if (
          this.state.idOfReceipt !== "" &&
          this.state.actualAmount !== ""
        ) {
          this.props.verifyDeposit({
            id: this.state.idOfReceipt,
            amt: this.state.actualAmount,
          });
        } else {
          this.setState({
            errorMessage: Strings("PleaseSelectReceipt"),
          });
        }
      }
    );
  };

  onNoClick = () => {
    this.setState({
      verifyModal: false,
      reEnteredAmount: "",
      idOfReceipt: "",
      showTable: false,
    });
  };

  onViewReceiptClick = (row) => {
    this.setState({
      idOfReceipt: row.id,
      status: 0,
      depositedAmount: row.amt,
      errorMessage: "",
    });
    this.props.onViewReceiptClick(row.id);
  };

  onAmountClick = (row) => {
    this.props.getDepositedMinimartListForVerification(
      row.id,
      moment(row.ts).valueOf()
    );
    this.setState({ showTable: true, errorMessage: "" });
  };

  RowRenderer = ({ renderBaseRow, ...props }) => {
    const color =
      props.idx === this.state.selectedIndex ? "green" : "blue";
    props["color"] = color;
    return <div style={{ color }}>{renderBaseRow(props)}</div>;
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "ts":
        return moment(row[key]).format(INVOICE_DATE_FORMAT);
      case "amt":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "amtVrf":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "st":
        return row[key] === 1 ? (
          <img src={green_tick} title={Strings("verified")} alt='X' />
        ) : row[key] === 2 ? ( //NOSONAR
          <img
            src={red_tick}
            title={Strings("discrepency")}
            alt='X'
          />
        ) : (
          "--"
        );

      default:
        return row[key] ? Strings(row[key]) : Strings("NA");
    }
  };

  renderCustomDataGrid = (columns, rows) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer
          component={Paper}
          className='d-flex justify-content-center'
        >
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"text-center header px-0"}
                      title={column.label}
                    >
                      <div>{column.name}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.id}
                  hover
                  style={{ cursor: "pointer" }}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key === "amt" || column.key === "id"
                          ? "text-center px-0 viewReceiptUrl"
                          : "text-center px-0"
                      }
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ width: column.minWidth }}
                      onClick={
                        column.key === "amt"
                          ? () => this.onAmountClick(row)
                          : column.key === "id" //NOSONAR
                            ? () => this.onViewReceiptClick(row)
                            : () => {
                              //This is intensional
                            }
                      }
                    >
                      <div>
                        {this.renderTableData(row, column.key)}
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    return (
      <div className='verifyCollection'>
        <span className='heading'>
          {Strings("Messenger")}: {Strings("VerifyCollection")}
        </span>
        <div className='paper verifyCollectionContainer'>
          {this.state.reEnteredAmount !== "" &&
            this.state.verifyModal === true && (
              <UserModal
                open={this.state.verifyModal}
                modalCloseOnEsc={false}
                modalCloseOnOverlayClick={false}
                modalShowCloseIcon={false}
                headerText={
                  this.state.status === 1
                    ? Strings("VerifyAmount")
                    : Strings("SomeDiscrepency")
                }
                modalText1={
                  this.state.status === 1
                    ? Strings("IsAmountCorrect")
                    : Strings("doYouWantToContinue")
                }
                modalButtonOk={{
                  text: Strings("Yes"),
                  onClick: this.onYesClick,
                }}
                modalButtonCancel={{
                  text: Strings("No"),
                  onClick: this.onNoClick,
                }}
                showHr={true}
              />
            )}

          {this.props.minimartData && (
            <VerificationModal
              open={this.state.showTable}
              modalCloseOnEsc={false}
              modalCloseOnOverlayClick={false}
              modalShowCloseIcon={false}
              headerText={Strings("minimarts")}
              modalButtonCancel={{
                text: Strings("Close"),
                onClick: this.onNoClick,
              }}
              minimartData={this.props.minimartData}
              showHr={true}
              viewReceipt={this.props.viewReceipt}
              downloadDownpaymentReceipt={
                this.props.downloadDownpaymentReceipt
              }
            />
          )}

          <Row>
            <Col md={8} className='noPadding'>
              <MessengerProfile
                profileDetails={this.state.profileDetails}
                messengerProfilePic={this.props.messengerProfilePic}
              />
              <CollectionView
                collectionData={this.state.collectionData}
              />
              {this.state.data &&
                this.state.data.length > 0 &&
                this.renderCustomDataGrid(
                  this.columns,
                  this.state.data
                )}
              <div>
                <Button
                  className='buttonBorder'
                  onClick={this.onBackClick}
                >
                  {Strings("Back").toUpperCase()}
                </Button>
              </div>
            </Col>
            <Col md={4}>
              <Receipt
                receiptUrl={this.state.receiptUrl}
                reEnteredAmount={this.state.reEnteredAmount}
                handleReEnterAmount={this.handleReEnterAmount}
                onVerifyClick={this.onVerifyClick}
                onCancelClick={this.onCancelClick}
                status={this.state.status}
                idOfReceipt={this.state.idOfReceipt}
                errorMessage={this.state.errorMessage}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default VerifyCollection;

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
import { USER_ROLES } from "../../constants/appConstants";

export const MINIMART_DETAILS_KEY = [
  "code",
  "address",
  "branch",
  "city",
  "name",
  "type",
];

export const ASSOCIATION_KEYS = [];

export const BRANCH = "branch";

export const REWARD_TYPE = [
  {
    key: 0,
    value: "",
    label: Strings("rewardType"),
  },
  {
    key: 1,
    value: "SPENT",
    label: Strings("SPENT"),
  },
  {
    key: 2,
    value: "EARNED",
    label: Strings("EARNED"),
  },
];

export const MERGE_MINIMART_DETAILS_KEYS = [
  { key: "id", label: "MatricesID", className: "" },
  { key: "code", label: "code", className: "" },
  { key: "name", label: "name", className: "" },
  { key: "address", label: "address", className: "address" },
  { key: "eligibleLoanType", label: "EligibleFor", className: "" },
  { key: "branch", label: "Branch", className: "" },
  { key: "zone", label: "zone", className: "" },
  { key: "collectionDays", label: "collectionDays", className: "" }
];

export const MINIMART_DETAILS = [
  {
    key: "id",
    label: "MatricesID",
    className: "",
    isEdit: false,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "ownerName",
    label: "ownerName",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "name",
    label: "name",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "eligibleLoanType",
    label: "EligibleFor",
    className: "",
    isEdit: true,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
  {
    key: "businessType",
    label: "BusinessType",
    className: "",
    isEdit: false,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
  {
    key: "registrationStatus",
    label: "registrationStatus",
    className: "",
    isEdit: false,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
  {
    key: "code",
    label: "code",
    className: "",
    isEdit: false,
    type: "text",
    isSelection: false,
    isNumber: true,
  },
  {
    key: "city",
    label: "City",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "sector",
    label: "Sector",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "street",
    label: "Street",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "number",
    label: "number",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "reference",
    label: "Reference",
    className: "address",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
  {
    key: "telephone",
    label: "PhoneNumber",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: true,
  },
  {
    key: "ownerNumber",
    label: "MobileNumberOfOwner",
    className: "",
    isEdit: true,
    type: "text",
    isSelection: false,
    isNumber: true,
    width: 25,
  },
  {
    key: "cedulaId",
    label: "ownerCedulaNumber",
    className: "",
    isEdit: false,
    type: "text",
    isSelection: false,
    isNumber: true,
  },
  {
    key: "rewardPoint",
    label: "rewardPoints",
    className: "",
    isEdit: false,
    type: "text",
    isSelection: false,
    isNumber: true,
  },
  {
    key: "collectionDays",
    label: "collectionDays",
    className: "",
    isEdit: false,
    type: "text",
    isSelection: false,
    isNumber: false,
  },
];
export const MINIMART_ASSOCIATION_DETAILS = [
  {
    key: "branch",
    label: "associatedBranch",
    className: "",
    isEdit: true,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
  {
    key: "zone",
    label: "associatedZone",
    className: "",
    isEdit: true,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
  {
    key: "messengerZone",
    label: "associatedMessengerZone",
    className: "",
    isEdit: true,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
  {
    key: "visitDay",
    label: "visitDay",
    className: "",
    isEdit: true,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
];

export const NEW_MINIMART_ASSOCIATION_DETAILS = [
  {
    key: "branch",
    label: "associatedBranch",
    className: "",
    isEdit: true,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
  {
    key: "zone",
    label: "associatedZone",
    className: "",
    isEdit: true,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
  {
    key: "messengerZone",
    label: "associatedMessengerZone",
    className: "",
    isEdit: true,
    type: "select",
    isSelection: true,
    isNumber: false,
  },
];

export const MINIMART_DETAILS_EDIT_USERS = {
  GENERAL_MANAGER: [
    "ownerName",
    "eligibleLoanType",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
    "branch",
    "zone",
    "messengerZone",
    "visitDay",
    "latitude",
    "longitude",
  ],
  ADMIN: [
    "ownerName",
    "eligibleLoanType",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
    "branch",
    "zone",
    "messengerZone",
    "visitDay",
    "latitude",
    "longitude",
  ],
  COLLECTIONS_OFFICER: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
  ],
  OPERATIONS_MODERATOR: [
    "ownerName",
    "eligibleLoanType",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
  ],
  COLLECTIONS_MANAGER: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber"
  ],
  COLLECTIONS_SUPERVISOR: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
  ],
  ADMINISTRATIVE_MANAGER: [
    "ownerName",
    "eligibleLoanType",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
    /* Only admin and general manager can edit below commented fields */
    // "branch",
    // "zone",                 
    // "messengerZone",
    "visitDay",
  ],
  OPERATIONS_MANAGER: [
    "ownerName",
    "eligibleLoanType",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
    /* Only admin and general manager can edit below commented fields */
    // "branch",
    // "zone",                 
    // "messengerZone",
    "visitDay",
    "latitude",
    "longitude",
  ],
  SALES_MANAGER: [
    "ownerName",
    "eligibleLoanType",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber"
  ],
  TECHNOLOGY: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber"
  ]
};
export const NEW_MINIMART_DETAILS_EDIT_USERS = {
  GENERAL_MANAGER: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
    "branch",
    "zone",
    "messengerZone",
    "visitDay",
    "latitude",
    "longitude",
  ],
  ADMIN: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
    "branch",
    "zone",
    "messengerZone",
    "visitDay",
    "latitude",
    "longitude",
  ],

  COLLECTIONS_OFFICER: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
  ],
  COLLECTIONS_SUPERVISOR: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
  ],
  COLLECTIONS_MANAGER: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
    /* Only admin and general manager can edit below commented fields */
    // "branch",
    // "zone",                 
    // "messengerZone",
    "visitDay",
  ],
  OPERATIONS_MODERATOR: [
    "ownerName",
    "eligibleLoanType",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
  ],
  SALES_MANAGER: [
    "ownerName",
    "eligibleLoanType",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber"
  ],
  OPERATIONS_MANAGER: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
    /* Only admin and general manager can edit below commented fields */
    // "branch",
    // "zone",                 
    // "messengerZone",
    "visitDay",
    "latitude",
    "longitude",
  ],
  ADMINISTRATIVE_MANAGER: [
    "ownerName",
    "city",
    "sector",
    "street",
    "number",
    "reference",
    "telephone",
    "ownerNumber",
    /* Only admin and general manager can edit below commented fields */
    // "branch",
    // "zone",                
    // "messengerZone",
    "visitDay",
  ],
};

export const MINIMART_EDIT_ACCESS = [
  "GENERAL_MANAGER",
  "ADMIN",
  "OPERATIONS_MODERATOR",
  "COLLECTIONS_OFFICER",
  "COLLECTIONS_MANAGER",
  "COLLECTIONS_SUPERVISOR",
  "ADMINISTRATIVE_MANAGER",
  "OPERATIONS_MANAGER",
  "SALES_MANAGER",
  "TECHNOLOGY"
];

export const NEW_MINIMART_EDIT_ACCESS = ["GENERAL_MANAGER", "ADMIN"];

export const ASSOCIATION_USERS = [
  {
    key: "messengerModerator",
    label: "AssociatedModerator",
  },
  {
    key: "salesPerson",
    label: "AssociatedSalesPerson",
  },
  {
    key: "messenger",
    label: "AssociatedMessenger",
  },
  {
    key: "collectionsOfficer",
    label: "CollectionsOfficer",
  },
  {
    key: "collectionsSupervisor",
    label: "COLLECTIONS_SUPERVISOR",
  },
  {
    key: "salesModerator",
    label: "SALES_MODERATOR",
  },
];

export const EXCEPTIONAL_MINIMART_USER_ACCESS = [
  "ADMIN",
  "GENERAL_MANAGER",
  "COLLECTIONS_SUPERVISOR",
  "OPERATIONS_MANAGER",
  "TECHNOLOGY",
  "COLLECTIONS_MANAGER",
  "ADMINISTRATIVE_MANAGER",
];

export const EMI_COLLECTION_COLUMNS = [
  { key: "id", label: "loanId" },
  { key: "invNum", label: "invoiceNumber" },
  { key: "type", label: "loanType" },
  { key: "totalAmount", label: "totalAmount" },
  { key: "pae", label: "administrativeExpense" },
  { key: "todaysAmount", label: "todaysAmount" },
  { key: "dia", label: "daysInArrears" },
  { key: "pastDue", label: "pastDue" },
  { key: "status", label: "clientStatus" },
  { key: "amtPayOff", label: "amountToPayOff" }
];

export const SINGLE_LOAN_EMI_COLLECTION = [
  { key: "action", label: "action" },
  ...EMI_COLLECTION_COLUMNS,
];

export const DOWN_PAYMENT_COLLECTION_COLUMNS = [
  { key: "appId", label: "applicationId" },
  { key: "lt", label: "loanType" },
  { key: "mn", label: "motorcycleName" },
  { key: "mo", label: "model" },
  { key: "co", label: "cost" },
  { key: "lAmt", label: "loanAmount" },
  { key: "emi", label: "emi" },
  { key: "roi", label: "interestRate" },
  { key: "tm", label: "termRequested" },
  { key: "dAmt", label: "downpaymentCollection" },
];

export const PAYMENT_COLLECTION_TABS = [
  {
    key: 0,
    value: Strings("payForMinimart"),
    index: 0,
  },
  {
    key: 1,
    value: Strings("payForLoan"),
    index: 1,
  },
  {
    key: 2,
    value: Strings("downpaymentCollection"),
    index: 2,
  },
]

export const ACCESS_TO_BLOCK_MINIMARTS = [
  USER_ROLES.collectionsSupervisor,
  USER_ROLES.collectionsManager,
  USER_ROLES.administrativeManager,
  USER_ROLES.generalManager
]
import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import ProductCatalog from "./ProductCatalog";
import withHOC from "../../HOC/HOC";

//Actions
import {
  getProductCatalogAction,
  getAllCategoriesAction,
  updateProductAction,
  updateProductCategoryAction,
  addProductAction,
  addProductCategoryAction,
} from "./ProductCatalogActions";
//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { GET_PRODUCT_CATALOG_COLUMNS } from "./Constants";
import { USER_ROLES } from "../../constants/appConstants";

//Styles
import "./ProductCatalog.scss";

//Strings
import { Strings } from "../../resources/i18n/i18n";

class ProductCatalogContainer extends React.Component {
  constructor(props) {
    super(props);
    this._columns = GET_PRODUCT_CATALOG_COLUMNS();
    this.state = {
      rows: [],
      categories: [],
      numberOfPages: 1,
      showLoader: false,
      errorMessage: "",
      toShowToast: false,
      successToastMessage: "",
      readOnly:
        props.loggedInUser.role === USER_ROLES.analyst ||
        props.loggedInUser.role === USER_ROLES.operationsOfficer ||
        props.loggedInUser.role === USER_ROLES.operationsModerator ||
        props.loggedInUser.role === USER_ROLES.analystModerator ||
        props.loggedInUser.role === USER_ROLES.collectionsSupervisor ||
        props.loggedInUser.role === USER_ROLES.administrativeManager ||
        props.loggedInUser.role === USER_ROLES.customerService ||
        props.loggedInUser.role === USER_ROLES.salesManager ||
        props.loggedInUser.role === USER_ROLES.collectionsManager ||
        props.loggedInUser.role === USER_ROLES.technology,
    };
  }

  handleSearchFilter = (searchFilterObjject) => {
    this.setState({
      showLoader: true,
      // toShowToast: false,
      dummyFilters: searchFilterObjject,
      errorMessage: "",
    });

    getProductCatalogAction(searchFilterObjject, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          numberOfPages: Response.data.data.np,
          showLoader: false,
          errorMessage: "",
        });
        this.createRows(Response.data.data.li);
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
        });
      }
    });
  };

  getAllCategories = () => {
    this.setState({ showLoader: true });
    getAllCategoriesAction((Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          categories: Response.data.data,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
        });
      }
    });
  };

  createRows = (responseData) => {
    //Row create for each element in response data array
    let rows = [],
      productCatalog = responseData;
    _.forEach(productCatalog, (product) => {
      rows.push({
        category: product.cat,
        id: product.id,
        productNameAndDescription: {
          productName: product.pn,
          description: product.dc,
        },
        model: product.mo,
        cost: product.co,
        status: product.st,
        ProductPreservedData: product,
        branchIds: product.bid,
      });
    });
    this.setState({ rows: rows });
  };

  addCategory = (category) => {
    this.setState({ showLoader: true, toShowToast: false });
    addProductCategoryAction(category, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
            toShowToast: true,
            successToastMessage: Strings("categoryNotification"),
          },
          () => {
            this.getAllCategories();
          }
        );
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
        });
      }
    });
  };

  addProduct = (product) => {
    product.branchIds.filter((pro) => pro !== "selectBranch");
    this.setState({ showLoader: true, toShowToast: false });
    addProductAction(product, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
            toShowToast: true,
            successToastMessage: Strings("productNotification"),
          },
          () => {
            this.handleSearchFilter(this.state.dummyFilters);
          }
        );
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
          toShowToast: false,
        });
      }
    });
  };

  updateProductCategory = (category) => {
    this.setState({ showLoader: true, toShowToast: false });
    updateProductCategoryAction(category, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
            toShowToast: true,
            successToastMessage: Strings("categoryNotification"),
          },
          () => {
            this.getAllCategories();
            this.handleSearchFilter(this.state.dummyFilters);
          }
        );
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
        });
      }
    });
  };

  updateProduct = (product) => {
    this.setState({ showLoader: true, toShowToast: false });
    updateProductAction(product, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
            toShowToast: true,
            successToastMessage: Strings("productNotification"),
          },
          () => {
            this.handleSearchFilter(this.state.dummyFilters);
          }
        );
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
        });
      }
    });
  };

  render() {
    return (
      <div className='productCatalog'>
        <Row className='heading'>{Strings("ProductCatalog")}</Row>
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.successToastMessage}
            userNotificationObj={{
              message: this.state.successToastMessage,
              level: "success",
            }}
          />
        )}
        <LoadingModal showLoadingModal={this.state.showLoader} />

        <ProductCatalog
          rows={this.state.rows}
          errorMessage={this.state.errorMessage}
          handleSearchFilter={this.handleSearchFilter}
          numberOfPages={this.state.numberOfPages}
          columns={this._columns}
          rowsCount={this.state.rows.length}
          rowHeight={60}
          headerRowHeight={40}
          categories={this.state.categories}
          addCategory={this.addCategory}
          addProduct={this.addProduct}
          getAllCategories={this.getAllCategories}
          updateCategory={this.updateProductCategory}
          updateProduct={this.updateProduct}
          readOnly={this.state.readOnly}
          branches={this.props.branches}
          getBranchList={this.props.getBranchList}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

const ProductCatalogWrapper = withHOC(ProductCatalogContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCatalogWrapper);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import "./Header.scss";

// Components
import Header from "./Header";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";

// Actions
import { logoutAction } from "../Login/LoginAction";
import {
  getUserProfileAction,
  getUserProfilePicUrlAction,
} from "../ProfileDetails/ProfileDetailsActions";
import {
  setUserDetailsAction,
  setIsAddUSerFlagAction,
} from "../UserList/UserListAction";

class HeaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      profilePicUrl: null,
    };
  }

  componentDidMount() {
    this.getUserProfilePic();
  }

  handleLogout = () => {
    this.setState({ showLoader: true });

    this.props.logoutAction((response) => {
      this.setState({ showLoader: false });
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (!localStorage.getItem("state") || !JSON.parse(localStorage.getItem("state")).loginReducer.loggedInUserInfo.userId) {
          this.props.history.push(ROUTES.LOGIN);
        }
      }
    });
  };

  handleProfileClick = () => {
    this.setState({ showLoader: true });
    this.props.setIsAddUSerFlagAction({ isAddUser: false });
    this.props.loggedInUserInfo &&
      this.props.getUserProfileAction(
        this.props.loggedInUserInfo.username,
        (apiResponse) => {
          this.getUserProfilePic();
          this.setState({ showLoader: false });

          this.props.setUserDetailsAction(
            apiResponse.data.data,
            () => {
              this.props.history.push(ROUTES.USER_PROFILE);
            }
          );
        }
      );
  };

  getUserProfilePic = () => {
    this.props.loggedInUserInfo &&
      Object.keys(this.props.loggedInUserInfo.features).length > 0 &&
      this.props.getUserProfilePicUrlAction(
        this.props.loggedInUserInfo.username,
        () => { },
        true
      );
  };

  handleChangePasswordClick = () => {
    this.props.history.push(ROUTES.CHANGE_PASSWORD);
  };

  render() {
    return (
      <div>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <Header
          profilePic={this.props.profilePic}
          handleLogout={this.handleLogout}
          loggedInUserInfo={this.props.loggedInUserInfo}
          handleProfileClick={this.handleProfileClick}
          handleChangePasswordClick={this.handleChangePasswordClick}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUserInfo: state.loginReducer.loggedInUserInfo,
  profilePic: state.loginReducer.loggedInUserProfile.profilePic,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutAction,
      getUserProfileAction,
      getUserProfilePicUrlAction,
      setUserDetailsAction,
      setIsAddUSerFlagAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
);

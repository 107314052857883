import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col } from "react-bootstrap";

// Styles
import "./Login.scss";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Components
import Login from "./Login";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";

// Actions
import { forgotPasswordAction, loginAction } from "./LoginAction";

//Utility
import Utility from "../../utils/Utility";

class LoginContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      errorMessage: "",
      forgotPasswordSuccess: false,
      forgotPasswordFailure: false,
      showLoader: false,
    };
  }

  handleLogin = (username, password) => {
    this.setState(
      {
        forgotPasswordSuccess: false,
        forgotPasswordFailure: false,
        showLoader: true,
      },
      () => {
        let requestData =
          "username=" + username + "&password=" + password;
        this.props.loginAction(requestData, this.loginCallback);
      }
    );
  };

  initErrorStates = () => {
    this.setState({
      errorMessage: "",
    });
  };

  loginCallback = (response) => {
    this.setState({ showLoader: false });

    if (response.status === OPERATION_STATUS.SUCCESS) {
      this.initErrorStates();
      if (response.data.isSystemPassword) {
        this.props.history.push({
          pathname: ROUTES.CHANGE_PASSWORD,
          state: { isSystemPassword: response.data.isSystemPassword },
        });
      } else {
        // Check if logged in by configurator and route accordingly
        let routeAsPerUserRole = Utility.landingScreenAsPerLoggedInUserRole(response.data.role);
        this.props.history.push(routeAsPerUserRole);
      }
    } else {
      this.setState({
        errorMessage: response.error.message,
        showLoader: false,
      });
    }
  };

  handleForgotPassword = (username) => {
    this.setState({ showLoader: true, forgotPasswordFailure: false });
    forgotPasswordAction(username, this.forgotPasswordCallback);
  };

  forgotPasswordCallback = (response) => {
    if (response.status === OPERATION_STATUS.SUCCESS) {
      this.setState({
        forgotPasswordSuccess: true,
        forgotPasswordFailure: false,
        showLoader: false,
      });
    } else {
      this.setState({
        forgotPasswordFailure: true,
        forgotPasswordSuccess: false,
        showLoader: false,
      });
    }
  };

  render() {
    return (
      <Col sm={12} className='noPadding login'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.forgotPasswordSuccess && (
          <UserNotification
            userNotificationObj={{
              title: Strings("EmailHasBeenSent"),
              level: "success",
            }}
          />
        )}
        {this.state.forgotPasswordFailure && (
          <UserNotification
            userNotificationObj={{
              title: Strings("EmailNotSent"),
              level: "error",
            }}
          />
        )}
        <Login
          handleLogin={this.handleLogin}
          handleForgotPassword={this.handleForgotPassword}
          errorMessage={this.state.errorMessage}
        />
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  // loggedInUserInfo: state.loginReducer.loggedInUserInfo
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { loginAction, forgotPasswordAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);

import React from "react";

export const ScalaPrivacyPolicy = () => {
  return (
    <div className='p-5'>
      <h5 className='text-center'>
        <strong>
          Políticas de Privacidad
        </strong>
      </h5>
      <strong>¿Qué información se recopila?</strong>
      <p>
        Al utilizar nuestro servicio, en caso de error de la aplicación, recopilamos datos e
        información llamados Datos de Registro. Este registro de datos puede incluir
        información como la dirección del protocolo de internet (IP), el nombre del
        dispositivo, la versión del sistema operativo, la configuración de la aplicación al
        utilizar nuestro servicio, la hora y fecha de uso del servicio y otras estadísticas. Al
        registrarse en el sistema se solicitarán os datos personales necesarios para la
        solicitud de nuestro servicio.
      </p>
      <strong>Acceso al estado de wifi</strong>
      <p>
        Con este permiso se permite hacer un levantamiento de la toda la información del
        dispositivo móvil, se levanta datos como: a que red está conectado, nombre, modelo,
        serie, tamaño de pantalla, almacenamiento, marca, tipo de red, zona horaria,
        lenguaje del dispositivo, si esta rooteado o no, entre otros.
      </p>
      <strong>Consulta de todo el paquete</strong>
      <p>
        Con este permiso se accede a toda le información de las aplicaciones tales como:
        fecha de instalación, nombre de la app, si la app es de pago o no, categoría de la
        app, calificación dada por el usuario en Google Play.
      </p>
      <strong>¿Cómo se utiliza la información?</strong>
      <p>
        La información solicitada se utilizará con el único fin de mejorar nuestro servicio, y
        los datos personales recabados en el aplicativo se utilizarán con la finalidad de
        proporcionar una mejor experiencia al usuario. Además, estamos solicitando
        imágenes del usuario las cuales serán utilizadas de manera interna como prueba de
        vida o evidencia de la existencia del usuario que está requiriendo nuestros servicios.
      </p>
      <p>
        La información obtenida por medio de la aplicación no será compartida con ningún
        otro usuario/socio de la institución.
      </p>
      <p>
        El canal de transmisión de información entre Usted y la institución utiliza el protocolo
        de comunicación SSL (Secure Sockets Layer) basado en tecnología de encriptación.
        Este ambiente seguro, ayuda a proteger la confidencialidad de sus datos cuando
        realice operaciones online en nuestro sitio.
      </p>
      <strong>Encriptación de Datos</strong>
      <p>
        La encriptación de datos es un mecanismo por el cual, la información intercambiada
        entre el cliente y la Entidad se transforma en una codificación ilegible, con una
        secuencia de caracteres de lenguaje particular, que esconden el significado real de la
        información.
      </p>
      <strong>Información de contactos de los usuarios</strong>
      <p>La información de contactos del dispositivo del usuario únicamente se utiliza para
        mejorar las funcionalidades en las cuales el usuario debe ingresar un numero de un
        dispositivo móvil. Estas funcionalidades permiten el acceso rápido a los contactos
        para poder realizar pagos y transferencias de forma amigable y eficiente.
      </p>
      <p>
        Esta información no será compartida con ningún otro usuario/socio de la institución
        ni almacenada en ningún lugar para su posterior uso.
      </p>
      <strong>Otros Permisos</strong>
      <p>Leer contactos, leer almacenamiento externo, leer calendario, obtener cuenta,
        bluetooth, usar huella dactilar, leer SMS, obtener imágenes.
      </p>
      <p>
        Los datos recopilados se comparten con el sistema de terceros llamado Credolab. El
        uso de datos se puede describir de la siguiente manera: a. ¿Qué datos se recopilan?
        Recopilamos datos de su teléfono para proporcionar un perfil de riesgo segmentado
        y generar información estadística agregada. Para proteger su identidad, eliminamos
        todos los identificadores personales (si los hay) y seudonimizamos dicha información.
        Los metadatos procesados de esta manera no se pueden utilizar para reproducir los
        datos originales recopilados. Estos son ejemplos de cómo nuestro proveedor externo
        oficial, Credolab, recopila los metadatos: - La tecnología móvil de Credolab puede
        contar la cantidad de eventos de calendario programados y su marca de tiempo.
        Solo esta información se envía a los servidores de Credolab, NO los datos sin
        procesar subyacentes. Si bien la tecnología móvil de credo lab puede escanear y
        procesar los contactos de su directorio telefónico en su dispositivo móvil y puede
        tener la capacidad de enviar nombres y detalles de contacto a los servidores de
        Credolab, los nombres y detalles de contacto NO se envían a los servidores de
        Credolab.-Si bien la tecnología móvil de Credolab puede escanear y procesar
        información sobre la lista de aplicaciones instaladas en su dispositivo móvil, Credolab
        solo recopilará datos relacionados con la frecuencia de uso de dichas aplicaciones
        pero NO las actividades que realiza en dicha aplicación. La tecnología móvil de
        Credolab puede contar la cantidad de cuentas registradas, incluidas sus cuentas
        sociales. Sin embargo, Credolab solo NO accederá a los perfiles de redes sociales y
        Credolab NO analizará la información en las aplicaciones conectadas con ellos. La
        tecnología móvil de Credolab puede contar el tiempo total que pasó solicitando un
        préstamo, el tiempo que pasó en la misma posición, qué tan rápido se desplazó por
        la aplicación, etc. Si bien la tecnología de Credolab puede escanear y procesar dicha
        información, Credolab NO lee el contenido de lo que escribe en el formulario de
        solicitud.-b.¿Los datos recopilados se comparten con algún tercero?Los datos
        recopilados se comparten con proveedores de servicios de análisis de datos
      </p>
    </div>);


};

import { Strings } from "../../resources/i18n/i18n";

const SALES_TABLE_COLUMN = [
  {
    name: Strings("Date"),
    key: "dt",
    minWidth: 100,
  },
  {
    name: Strings("LoanGenerated"),
    key: "gl",
    minWidth: 100,
  },
  {
    name: Strings("Assigned"),
    key: "aw",
    minWidth: 100,
  },
  {
    name: Strings("generatedLoanCommission") + " ( $ )",
    key: "glc",
    minWidth: 100,
  },
  {
    name: Strings("generatedCollectionCommission") + " ( $ )",
    key: "gcc",
    minWidth: 100,
  },
  {
    name: Strings("paidCommission") + " ($)",
    key: "pc",
    minWidth: 100,
  },
];
const MESSENGER_TABLE_COLUMN = [
  {
    name: Strings("Date"),
    key: "dt",
    minWidth: 100,
  },

  {
    name: Strings("assignedAmount"),
    key: "atbc",
    minWidth: 100,
  },
  {
    name: Strings("collectedAmount") + " ( $ )",
    key: "ac",
    minWidth: 100,
  },
  {
    name: Strings("generatedCollectionCommission") + " ( $ )",
    key: "gcc",
    minWidth: 100,
  },
  {
    name: Strings("paidCommission") + " ($)",
    key: "pc",
    minWidth: 100,
  },
];
export const TABLE_COLUMN = {
  SALES: SALES_TABLE_COLUMN,
  MESSENGER: MESSENGER_TABLE_COLUMN,
  SALES_MODERATOR: SALES_TABLE_COLUMN,
  MESSENGER_MODERATOR: MESSENGER_TABLE_COLUMN,
};
const SALES_SINGLE_USER_COMMISSION_COLUMN = [
  { name: Strings("MatricesID"), key: "mid", minWidth: 50 },
  { name: Strings("ciId"), key: "cd", minWidth: 100 },
  {
    name: Strings("minimartName"),
    key: "mn",
    minWidth: 250,
  },

  {
    name: Strings("LoanId"),
    key: "lid",
    minWidth: 100,
  },
  {
    name: Strings("loanType"),
    key: "ltp",
    minWidth: 150,
  },
  {
    name: Strings("referenceNumber"),
    key: "rf",
    minWidth: 100,
  },
  {
    name: Strings("amount") + " ($)",
    key: "amt",
    minWidth: 100,
  },
  {
    name: Strings("commission") + " ($)",
    key: "cm",
    minWidth: 100,
  },
  {
    name: Strings("commissionType"),
    key: "ty",
    minWidth: 100,
  },
];
const SALES_MOD_SINGLE_USER_COMMISSION_COLUMN = [
  { name: Strings("MatricesID"), key: "mid", minWidth: 50 },
  { name: Strings("ciId"), key: "cd", minWidth: 100 },
  {
    name: Strings("minimartName"),
    key: "mn",
    minWidth: 250,
  },

  {
    name: Strings("LoanId"),
    key: "lid",
    minWidth: 100,
  },
  {
    name: Strings("loanType"),
    key: "ltp",
    minWidth: 150,
  },
  {
    name: Strings("referenceNumber"),
    key: "rf",
    minWidth: 100,
  },
  {
    name: Strings("amount") + " ($)",
    key: "amt",
    minWidth: 100,
  },
  {
    name: Strings("commissionType"),
    key: "ty",
    minWidth: 100,
  },
];
const MESSENGER_SINGLE_USER_COMMISSION_COLUMN = [
  { name: Strings("MatricesID"), key: "mid", minWidth: 50 },
  { name: Strings("ciId"), key: "cd", minWidth: 100 },
  {
    name: Strings("minimartName"),
    key: "mn",
    minWidth: 250,
  },
  {
    name: Strings("loanType"),
    key: "ltp",
    minWidth: 150,
  },
  {
    name: Strings("LoanId"),
    key: "lid",
    minWidth: 100,
  },
  {
    name: Strings("amount") + " ($)",
    key: "amt",
    minWidth: 100,
  },
  {
    name: Strings("commission") + " ($)",
    key: "cm",
    minWidth: 100,
  },
];
export const SINGLE_USER_COMMISSION_COLUMN = {
  SALES: SALES_SINGLE_USER_COMMISSION_COLUMN,
  MESSENGER: MESSENGER_SINGLE_USER_COMMISSION_COLUMN,
  SALES_MODERATOR: SALES_MOD_SINGLE_USER_COMMISSION_COLUMN,
  MESSENGER_MODERATOR: MESSENGER_SINGLE_USER_COMMISSION_COLUMN,
};

import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Utility
import Utility from "../../utils/Utility";

export const getLoansForLateFeesAction = (
  searchFilterObject,
  callback
) => {
  let requestData = {
    search: {
      loanId: searchFilterObject.search.loanId
        ? searchFilterObject.search.loanId
        : undefined,
      minimartName: searchFilterObject.search.minimartName
        ? searchFilterObject.search.minimartName
        : undefined,
      type: searchFilterObject.search.loanType
        ? searchFilterObject.search.loanType
        : undefined,
      category:
        searchFilterObject.search.category !== ""
          ? searchFilterObject.search.category
          : undefined,
      requester:
        searchFilterObject.search.requester !== ""
          ? searchFilterObject.search.requester
          : undefined,
      branchId:
        searchFilterObject.search.branchId !== ""
          ? searchFilterObject.search.branchId
          : undefined,
      zone:
        searchFilterObject.search.zone !== ""
          ? searchFilterObject.search.zone
          : undefined,
      messengerZone:
        searchFilterObject.search.mZone !== ""
          ? searchFilterObject.search.mZone
          : undefined,
    },
    fromDate: searchFilterObject.search.fromDate
      ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.fromDate
        )
      : undefined,
    toDate: searchFilterObject.search.toDate
      ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.toDate
        )
      : undefined,
    limit: searchFilterObject.limit
      ? searchFilterObject.limit
      : DEFAULT_PAGINATION_RECORDS_COUNT,
    offset: searchFilterObject.offset
      ? searchFilterObject.offset - 1
      : DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
  };

  callAPI(
    "getLoansBySearchFilter",
    API.getLoansBySearchFilterForLateFees,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getLoansForAssignRepaymentAction = (
  searchFilterObject,
  callback
) => {
  let requestData = {
    search: {
      loanId: searchFilterObject.search.loanId
        ? searchFilterObject.search.loanId
        : undefined,
      minimartName: searchFilterObject.search.minimartName
        ? searchFilterObject.search.minimartName
        : undefined,
      type: searchFilterObject.search.loanType
        ? searchFilterObject.search.loanType
        : undefined,
      category:
        searchFilterObject.search.category !== ""
          ? searchFilterObject.search.category
          : undefined,
      requester:
        searchFilterObject.search.requester !== ""
          ? searchFilterObject.search.requester
          : undefined,
      branchId:
        searchFilterObject.search.branchId !== ""
          ? searchFilterObject.search.branchId
          : undefined,
      zone:
        searchFilterObject.search.zone !== ""
          ? searchFilterObject.search.zone
          : undefined,
      messengerZone:
        searchFilterObject.search.mZone !== ""
          ? searchFilterObject.search.mZone
          : undefined,
    },
    fromDate: searchFilterObject.search.fromDate
      ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.fromDate
        )
      : undefined,
    toDate: searchFilterObject.search.toDate
      ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.toDate
        )
      : undefined,
    limit: searchFilterObject.limit
      ? searchFilterObject.limit
      : DEFAULT_PAGINATION_RECORDS_COUNT,
    offset: searchFilterObject.offset
      ? searchFilterObject.offset - 1
      : DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
  };

  callAPI(
    "getLoansBySearchFilter",
    API.getLoansBySearchFilterForAssignRepayment,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

// Constants
import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import { SET_DATA_FOR_EDIT_CONFIGURATION } from "../../redux/constants";

export const getConfigurationByIdAction = (requestData, callback) => {
  let requestString =
    "?type=" + requestData.type + "&id=" + requestData.id;
  callAPI(
    "getConfigurationById",
    API.getConfigurationById.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getActiveConfigurationAction = (
  requestData,
  callback
) => {
  callAPI(
    "getActiveConfiguration",
    API.getActiveConfiguration.concat("=" + requestData),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const createConfigurationAction = (
  configurationObject,
  callback
) => {
  let requestData = {
    version: configurationObject.version,
    description: configurationObject.description,
    configuration: JSON.stringify(configurationObject.configuration),
    type: configurationObject.type,
  };

  callAPI(
    "CreateConfiguration",
    API.CreateConfiguration,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateConfigurationAction = (
  configurationObject,
  callback
) => {
  let requestData = {
    version: configurationObject.version,
    description: configurationObject.description,
    configuration: JSON.stringify(configurationObject.configuration),
    type: configurationObject.type,
  };

  callAPI(
    "UpdateConfiguration",
    API.UpdateConfiguration,
    "PATCH",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getDraftedConfigurationAction = (
  requestData,
  callback
) => {
  callAPI(
    "getDraftedConfiguration",
    API.getDraftedConfiguration.concat("=" + requestData),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const activateConfigurationAction = (
  configurationId,
  callback
) => {
  let requestData = {
    id: configurationId,
  };

  callAPI(
    "activateConfiguration",
    API.activateConfiguration,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const deleteConfigurationAction = (requestData, callback) => {
  callAPI(
    "deleteConfiguration",
    API.deleteConfiguration.concat("=" + requestData),
    "DELETE",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setConfigurationDataForEdit =
  (EditConfigurationData) => (dispatch) => {
    dispatch({
      type: SET_DATA_FOR_EDIT_CONFIGURATION,
      EditConfigurationData: { ...EditConfigurationData },
    });
  };

export const getCreditScoreMappingAction = (callback) => {
  callAPI(
    "getCreditScoreMapping",
    API.getCreditScoreMappings,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import React from "react";
import { Strings } from "../../../resources/i18n/i18n";

const CustomToolTip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>{Strings(label)}</p>
        <p className='intro'>
          {Strings("Count") + ` : ${payload[0].value}`}
        </p>
      </div>
    );
  }

  return null;
};

export default CustomToolTip;

import React from "react";
import { Row, Col } from "react-bootstrap";

//Components
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import ProductModal from "./ProductModal";
import CategoryModal from "./CategoryModal";
import _ from "lodash";

//Constants
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { Strings } from "../../resources/i18n/i18n";
import {
  STATUS_SELECTION,
  DEFAULT_CATEGORY_SELECTION,
  DEFAULT_MODEL_SELECTION,
} from "./Constants";
import {
  SEARCH_FILTER_TYPE,
  DEFAULT_BRANCH_SELECTION,
} from "../../constants/appConstants";

//Utilities
import Utility from "../../utils/Utility";

//Styles
import "./ProductCatalog.scss";

class ProductCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      errorMessage: "",
      category: "",
      model: "",
      status: "",
      categories: props.categories.length > 0 ? props.categories : [],
      product: null,
      categoryObject: null,
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      openCategoryModel: false,
      addProduct: false,
      editProduct: false,
      branch: DEFAULT_BRANCH_SELECTION,
      branches: props.branches,
    };
  }

  componentDidMount() {
    this.props.getBranchList();
    this.props.getAllCategories();
    this.handleOnClickSearchFilter(false);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (prevProps.categories !== this.props.categories) {
      this.setState({ categories: this.props.categories });
    }
  }

  handleFiltersChange = (key, event) => {
    this.setState({ [key]: event.target.value });
  };

  resetSearchValues = () => {
    this.setState(
      {
        category: "",
        model: "",
        status: "",
        branch: "",
        errorMessage: "",
      },
      () => {
        this.handleOnClickSearchFilter(true);
      }
    );
  };

  handleOnClickSearchFilter = (isDeFaultOffset) => {
    isDeFaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleSearchFilter({
      search: {
        model: this.state.model !== "" ? this.state.model : undefined,
        category:
          this.state.category !== "" &&
            this.state.category !== Strings("selectCategory")
            ? this.state.category
            : undefined,
        status:
          this.state.status !== "" &&
            this.state.status !== Strings("selectStatus")
            ? this.state.status
            : undefined,
        branchId:
          this.state.branch !== "" &&
            this.state.branch !== "selectBranch"
            ? this.state.branch
            : undefined,
      },

      limit: this.state.recordsPerPage,
      offset: isDeFaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
        : this.state.activePage - 1,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  handleChange = (object, key, event) => {
    let value =
      key !== "branchIds"
        ? event.target.value.trimStart()
        : event.target.value;
    if (key === "category") {
      value = Number(value);
    }
    this.setState({
      [object]: { ...this.state[object], [key]: value },
      errorMessage: "",
    });
  };

  addProduct = () => {
    !this.props.readOnly &&
      this.setState({
        addProduct: true,
        editProduct: false,
        product: {
          productName: "",
          description: "",
          model: "",
          status: "",
          cost: "",
          category: "",
          branchIds: [],
        },
      });
  };

  addCategory = () => {
    !this.props.readOnly &&
      this.setState({
        openCategoryModel: true,
        categoryObject: null,
      });
  };

  closeModal = () => {
    this.setState({
      addProduct: false,
      editProduct: false,
      openCategoryModel: false,
      errorMessage: "",
    });
  };

  handleProductRequest = () => {
    let product = { ...this.state.product };
    product["branchIds"] = product.branchIds.filter((pro) => {
      return pro !== "selectBranch";
    });
    if (
      (product &&
        (product.productName === "" ||
          product.model === "" ||
          product.status === "" ||
          product.cost === "" ||
          product.category === undefined ||
          product.category === "" ||
          product.branchIds.length === 0)) ||
      product === null
    ) {
      this.setState({
        errorMessage: Strings("pleaseSelectRequiredFields"),
      });
    } else {
      this.closeModal();
      if (product.hasOwnProperty("ProductPreservedData")) {
        product["id"] = product.ProductPreservedData.id;
        delete product.ProductPreservedData;
        this.props.updateProduct(product);
      } else {
        this.props.addProduct(product);
      }
    }
  };

  saveCategory = (category) => {
    this.setState({
      categoryObject: category,
      errorMessage: "",
    });
  };

  handleCategoryRequest = (category) => {
    if (
      (category && category.category === "") ||
      (category && category.cat === "") ||
      category === null
    ) {
      this.setState({
        errorMessage: Strings("pleaseSelectRequiredFields"),
      });
    } else {
      this.closeModal();
      category.id
        ? this.props.updateCategory(category)
        : this.props.addCategory(category);
    }
  };

  onRowClick = (row) => {
    if (row !== undefined) {
      const productData = { ...row };
      let catId = productData.ProductPreservedData.cat.id;
      let productName =
        productData.productNameAndDescription.productName;
      let description =
        productData.productNameAndDescription.description;
      productData["category"] = Number(catId);
      productData["productName"] = productName;
      productData["description"] = description;
      this.setState({
        product: { ...productData },
        addProduct: true,
      });
    }
  };

  handleRowClick = (row) => {
    this.onRowClick(row);
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "cost":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);

      case "category":
        return row[key].category;
      case "ProductPreservedData":
        return row[key].pn;
      case "productNameAndDescription":
        return row[key].productName;

      default:
        return row[key] ? Strings(row[key]) : Strings("NA");
    }
  };

  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer
          component={Paper}
          className='d-flex justify-content-center'
        >
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"header"}
                      title={column.name}
                    >
                      <div
                        className={
                          column.key === "productNameAndDescription"
                            ? "tableCell"
                            : ""
                        }
                      >
                        {column.name}
                      </div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => callBack(row)}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      component='th'
                      scope='row'
                      title={
                        column.key === "productNameAndDescription" &&
                          row[column.key].description
                          ? row[column.key].productName +
                          " " +
                          row[column.key].description
                          : this.renderTableData(row, column.key)
                      }
                      style={{ minWidth: column.minWidth }}
                    >
                      <div
                        className={
                          column.key === "productNameAndDescription"
                            ? "tableCell"
                            : ""
                        }
                      >
                        {this.renderTableData(row, column.key)}
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    let filterCategories = this.state.categories;
    if (
      filterCategories.length > 0 &&
      filterCategories[0].id !== ""
    ) {
      filterCategories.unshift(DEFAULT_CATEGORY_SELECTION);
    }
    filterCategories =
      filterCategories.length > 0 &&
      filterCategories.map((category) => {
        return {
          label: category.cat,
          value: Number(category.id),
        };
      });
    let catalogFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleFiltersChange,
        Value: this.state.category,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
        filterDropdownList: filterCategories,

        key: "category",
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        handleOnChange: this.handleFiltersChange,
        Value: this.state.model,
        filterDropdownList: [DEFAULT_MODEL_SELECTION],
        key: "model",
        searchPlaceHolder: Strings("model"),
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleFiltersChange,
        Value: this.state.status,
        filterDropdownList: [...STATUS_SELECTION],
        key: "status",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleFiltersChange,
        Value: this.state.branch,
        filterDropdownList: this.props.branches,
        key: "branch",
      },
    ];
    return (
      <div className='productCatalog ListContainer'>
        {this.props.branches && (
          <ProductModal
            open={this.state.addProduct}
            modalCloseOnEsc={false}
            modalCloseOnOverlayClick={false}
            modalShowCloseIcon={false}
            headerText={
              this.state.product?.id
                ? Strings("EditProduct")
                : Strings("AddProduct")
            }
            modalButtonCancel={{
              text: Strings("Cancel"),
              onClick: this.closeModal,
            }}
            modalButtonApprove={{
              text: this.state.product?.id
                ? Strings("Update")
                : Strings("Add"),
              onClick: this.handleProductRequest,
            }}
            handleChange={this.handleChange}
            errorMessage={this.state.errorMessage}
            categories={filterCategories}
            product={this.state.product}
            branches={this.props.branches}
            readOnly={this.props.readOnly}
          />
        )}
        <CategoryModal
          open={this.state.openCategoryModel}
          modalCloseOnEsc={false}
          modalCloseOnOverlayClick={false}
          modalShowCloseIcon={false}
          headerText={Strings("CategoryModal")}
          modalButtonCancel={{
            text: Strings("Cancel"),
            onClick: this.closeModal,
          }}
          modalButtonApprove={{
            text: Strings("Add"),
            onClick: this.handleCategoryRequest,
          }}
          handleChange={this.handleChange}
          categories={this.state.categories}
          errorMessage={this.state.errorMessage}
          saveCategory={this.saveCategory}
        />
        <Row className='filters'>
          <SearchFilter
            filters={catalogFilters}
            handleOnClickSearchFilter={this.handleOnClickSearchFilter}
            handleOnClickResetFilter={this.resetSearchValues}
            addButton={!this.props.readOnly}
            addAnotherButton={!this.props.readOnly}
            buttonText={Strings("Category")}
            anotherButtonText={Strings("Product")}
            AnotherButtonClick={this.addProduct}
            handleAddUser={this.addCategory}
            spaceBetween={false}
            spaceInmd={1}
            paddingLeft={2}
          />
        </Row>
        <Row>
          <Col md={12} className=' errorText'>
            {this.state.errorMessage !== ""
              ? this.state.errorMessage
              : this.props.errorMessage}
          </Col>
        </Row>
        {this.props.rowsCount !== 0 ? (
          <div className='branchListTable noPadding'>
            <Col md={12}>
              {this.renderCustomDataGrid(
                this.props.columns,
                this.props.rows,
                this.handleRowClick
              )}
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </div>
        ) : (
          <div className='noRecordsFound'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default ProductCatalog;

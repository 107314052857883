import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getRewardDetailsAction = (rrid, callback) => {
  callAPI(
    "getSingleMinimartData",
    API.getApprovalRewardDetails.concat(rrid),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateRewardStatusAction = (request, callback) => {
  callAPI(
    "getSingleMinimartData",
    API.updateRewardStatus,
    "PUT",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import React from "react";
import { Col, Row } from "react-bootstrap";

// Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import CustomTableCard from "../CustomUIComponents/CustomTableCard/CustomTableCard.js";

import UserModal from "../CustomUIComponents/UserModal/UserModal";

// Constants
import {
  DEFAULT_PAGINATION_ACTIVE_PAGE,
  DEFAULT_PAGINATION_RECORDS_COUNT,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { TABLE_HEADER_LIST, USER_TYPE } from "./Constants";
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./CensusWorkloadManagement.scss";

export default class CensusWorkloadManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        branch: this.props.searchFilter.br,
        position: this.props.searchFilter.pid,
        zone: this.props.searchFilter.zn,
        mzone: this.props.searchFilter.mzn,
        role: this.props.searchFilter.rl,
        user: this.props.searchFilter.uid,
      },
      user: "",
      expanded: [],
      assignUserList: [],
      activePage: this.props.searchFilter.offset,
      recordsPerPage: this.props.searchFilter.limit,
      showUserModal: false,
      newUser: null,
      existingUser: null,
      errorMessage: props.errorMessage,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter();
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage !== prevProps.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  handleOnChangeBranch = (event) => {
    let name = event.target.name;
    let value =
      event.target.value === "selectBranch" ? "" : event.target.value;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
          position: "",
          zone: "",
          mzone: "",
          user: "",
          errorMessage: "",
        },
      },
      () => {
        this.props.getPositionList({
          branch: value,
          role: this.state.filters.role,
        });
        this.props.getZoneList({
          branch: value,
          positionId: undefined,
        });
        this.props.getUserList({
          branch: value,
          zone: this.state.filters.zone,
          mzone: this.state.filters.mzone,
          position: this.state.filters.position,
          role: this.state.filters.role,
        });
      }
    );
  };

  handleOnChangePosition = (event) => {
    let name = event.target.name;
    let value =
      event.target.value === Strings("SelectPosition")
        ? ""
        : event.target.value;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
          zone: "",
          mzone: "",
          user: "",
          errorMessage: "",
        },
      },
      () => {
        this.props.getZoneList({
          branch: this.state.filters.branch,
          positionId: value,
        });
        this.props.getUserList({
          branch: this.state.filters.branch,
          zone: this.state.filters.zone,
          mzone: this.state.filters.mzone,
          position: value,
          role: this.state.filters.role,
        });
      }
    );
  };

  handleOnChangeZone = (event) => {
    let name = event.target.name;
    let value =
      event.target.value === "Zona" ? "" : event.target.value;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
          user: "",
          errorMessage: "",
        },
      },
      () => {
        this.props.getUserList({
          branch: this.state.filters.branch,
          zone: value,
          mzone: value,
          position: this.state.filters.position,
          role: this.state.filters.role,
        });
      }
    );
  };

  handleOnChangeRole = (event) => {
    let name = event.target.name;
    let value =
      event.target.value ===
        Strings("username") + " " + Strings("type")
        ? ""
        : event.target.value;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
          position: "",
          user: "",
          errorMessage: "",
        },
      },
      () => {
        this.props.getPositionList({
          branch: this.state.filters.branch,
          role: value,
        });
        this.props.getUserList({
          branch: this.state.filters.branch,
          zone: this.state.filters.zone,
          mzone: this.state.filters.mzone,
          position: this.state.filters.position,
          role: value,
        });
      }
    );
  };
  handleOnChangeUser = (event) => {
    let name = event.target.name;
    let value =
      event.target.value === Strings("username")
        ? ""
        : event.target.value;
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
        errorMessage: "",
      },
    });
  };

  renderSearchFilter = () => {
    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeBranch,
        Value: this.state.filters.branch,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.props.branches,
        name: "branch",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeRole,
        Value: this.state.filters.role,
        fieldname: SEARCH_FILTER_FIELDS.ROLE,
        filterDropdownList: USER_TYPE,
        name: "role",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangePosition,
        Value: this.state.filters.position,
        fieldname: SEARCH_FILTER_FIELDS.POSITION,
        filterDropdownList: this.props.positionList,
        name: "position",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeZone,
        Value: this.state.filters.zone,
        fieldname: SEARCH_FILTER_FIELDS.ZONE,
        filterDropdownList: this.props.zoneList,
        name: "zone",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeZone,
        Value: this.state.filters.mzone,
        fieldname: SEARCH_FILTER_FIELDS.MZONE,
        filterDropdownList: this.props.mZoneList,
        name: "mzone",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeUser,
        Value: this.state.filters.user,
        fieldname: SEARCH_FILTER_FIELDS.USER,
        filterDropdownList: this.props.userList,
        name: "user",
      },
    ];
    return (
      <SearchFilter
        filters={searchFilters}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        buttonWidth={12}
        paddingLeft={3}
        paddingTop={2}
        removeLeftPadding={true}

      />
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        recordsPerPage: pageLength,
        activePage: page,
      },
      this.handleOnClickSearchFilter
    );
  };

  handleOnClickSearchFilter = (isDefault = false) => {
    this.props.handleSearchFilter({
      search: {
        bid: this.state.filters.branch
          ? parseInt(this.state.filters.branch)
          : undefined,
        zn: this.state.filters.zone
          ? this.state.filters.zone
          : undefined,
        mzn: this.state.filters.mzone ? this.state.filters.mzone : undefined,
        pstid: this.state.filters.position
          ? this.state.filters.position
          : undefined,
        uid: this.state.filters.user
          ? this.state.filters.user
          : undefined,
        rl: this.state.filters.role
          ? this.state.filters.role
          : undefined,
      },
      offset: this.state.activePage,
      limit: this.state.recordsPerPage,
    });
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        filters: {
          branch: "",
          position: "",
          zone: "",
          mzone: "",
          role: "",
          user: "",
        },
        expanded: [],
        user: "",
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
      () => {
        this.props.getBranchList();
        this.props.getPositionList("");
        this.props.getZoneList("");
        this.props.getUserList({
          branch: "",
          zone: "",
          mzone: "",
          position: "",
          role: "",
        });
        this.handleOnClickSearchFilter();
      }
    );
  };

  handleExpandClick = (row) => {
    let expandIndexId = this.state.expanded;
    if (!expandIndexId.includes(row.pstid)) {
      this.props.getAssignUserList(
        {
          branch: row.bid,
          role: row.rl,
          uid: row.uid ? row.uid : undefined,
        },
        (list) => {
          const index = expandIndexId.indexOf(row.pstid);
          expandIndexId.includes(row.pstid)
            ? expandIndexId.splice(index, 1)
            : (expandIndexId[0] = parseInt(row.pstid)); //NOSONAR
          this.setState({
            expanded: expandIndexId,
            assignUserList: [...list],
            user: "",
          });
        },
        (errorMessage) => {
          this.setState({ errorMessage });
        }
      );
    } else {
      //Close open section
      const index = expandIndexId.indexOf(row.pstid);
      expandIndexId.splice(index, 1);
      this.setState({
        expanded: expandIndexId,
        user: "",
      });
    }
  };

  onUserChange = (event) => {
    this.setState({
      user:
        event.target.value === Strings("username")
          ? ""
          : event.target.value,
      errorMessage: "",
    });
  };

  onSaveClick = (user) => {
    if (this.state.user === "") {
      this.setState({ errorMessage: Strings("pleaseSelectUser") });
    } else {
      this.setState({ existingUser: user, showUserModal: true });
    }
  };

  renderCustomDataGrid = () => {
    return (
      <CustomTableCard
        rows={this.props.rows}
        columns={TABLE_HEADER_LIST}
        isSummary={false}
        isExpandable={true}
        expanded={this.state.expanded}
        handleExpandClick={this.handleExpandClick}
        isWorkloadManagement={true}
        onUserChange={this.onUserChange}
        user={this.state.user}
        userList={this.state.assignUserList}
        callBack={this.props.onMinimartCountClick}
        onSaveClick={this.onSaveClick}
      />
    );
  };

  OnYesCLick = () => {
    let nuid = "";
    let newUser = null;
    if (this.state.user === "None") {
      nuid = undefined;
    } else {
      newUser = this.state.assignUserList.findIndex(
        (user) => parseInt(user.value) === parseInt(this.state.user)
      );
      nuid = this.state.assignUserList[newUser].value;
    }
    this.props.onSaveClick(
      {
        pstid: this.state.existingUser.pstid,
        nuid,
      },
      newUser && !this.state.assignUserList[newUser].ia
        ? {
          branch: this.state.filters.branch,
          zone: this.state.filters.zone,
          mzone: this.state.filters.mzone,
          position: this.state.filters.position,
          role: this.state.filters.role,
        }
        : null,
      () => {
        this.setState({
          existingUser: null,
          expanded: [],
          newUser: null,
          user: "",
          showUserModal: false,
          errorMessage: "",
        });
      }
    );
  };

  closeUserModal = () => {
    this.setState({
      showUserModal: false,
      existingUser: null,
      newUser: null,
      errorMessage: "",
    });
  };

  renderConfirmationModal = () => {//NOSONAR
    let modalText1 = "";
    if (this.state.user === "None") {
      modalText1 = Strings("salesPosition").replace(
        "$",
        this.state.existingUser.un
      );
    } else {
      let newUser = this.state.assignUserList.findIndex(
        (user) => parseInt(user.value) === parseInt(this.state.user)
      );
      if (this.state.assignUserList[newUser].ia) {
        modalText1 = Strings(
          this.state.existingUser.rl === "SALES_PERSON"
            ? "salesSwapPosition"
            : "messengerSwapPosition"
        )
          .replace("$", this.state.existingUser.un)
          .replace("$", this.state.assignUserList[newUser].label);
      } else {
        if (this.state.existingUser.uid) {
          modalText1 = Strings(
            this.state.existingUser.rl === "SALES_PERSON"
              ? "salesPositionAssign"
              : "messengerPositionAssign"
          )
            .replace("$", this.state.existingUser.un)
            .replace("$", this.state.assignUserList[newUser].label)
            .replace("$", this.state.existingUser.pstn);
        } else {
          modalText1 = Strings(
            this.state.existingUser.rl === "SALES_PERSON"
              ? "salesVacantPositionAssign"
              : "messengerVacantPositionAssign"
          )
            .replace("$", this.state.assignUserList[newUser].label)
            .replace("$", this.state.existingUser.pstn);
        }
      }
    }
    return (
      <UserModal
        open={this.state.showUserModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("ChangePosition")}
        modalText1={modalText1}
        modalButtonOk={{
          text: Strings("Yes"),
          onClick: this.OnYesCLick,
        }}
        modalButtonCancel={{
          text: Strings("No"),
          onClick: this.closeUserModal,
        }}
        showHr={true}
        errorMessage={this.state.errorMessage}
      />
    );
  };

  render() {
    return (
      <div className='censusWorkloadManagement'>
        {this.state.showUserModal && this.renderConfirmationModal()}
        <div className='ListContainer'>
          {this.renderSearchFilter()}

          <Row>
            <Col md={12} className=' errorText'>
              {this.state.errorMessage}
            </Col>
          </Row>
          {/* Library component to display grid. */}

          {this.props.rows && this.props.rows.length > 0 ? (
            <div className='userListTable px-3'>
              {this.renderCustomDataGrid()}
              <Col md={12} className='noPadding alignPagination'>
                <Pagination
                  activePage={this.state.activePage}
                  recordsPerPage={this.state.recordsPerPage}
                  numberOfPages={this.props.numberOfPages}
                  onPageChange={this.onPageChange}
                  dropup={true}
                />
              </Col>
            </div>
          ) : (
            <div className='noRecordsFound'>
              {Strings("NoRecordsFound")}
            </div>
          )}
        </div>
      </div>
    );
  }
}

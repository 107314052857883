import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

//custom components
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";

// Utils

//constants
import { Strings } from "../../resources/i18n/i18n";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
} from "../../constants/appConstants";

//styles
import "./MinimartApproval.scss";
import "../CustomUIComponents/InputModal/InputModal.scss";

class MinimartApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: props.searchFilter.oft,
      recordsPerPage: props.searchFilter.lmt,
      filters: {
        minimartName: props.searchFilter.mn,
        ciId: props.searchFilter.cd,
      },
      branch: "",
      zone: "",
      visitDay: "",
      openModal: false,
      sp: "",
      msg: "",
      errorMessage: props.errorMessage,
    };
  }

  componentDidMount() {
    this.handleOnClickCIFilter();
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage !== prevProps.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  //pagination handling
  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => {
        this.handleOnClickCIFilter(false);
      }
    );
  };

  // CI minimart card click
  handleCardClick = (card) => {
    this.props.setMiniMartDetails(card);
  };

  // #endregion

  //create request object and call search API on Operations Officer CI tab
  handleOnClickCIFilter = (isDefaultOffset = false) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleOnClickCIFilter({
      mn: this.state.filters.minimartName || undefined,
      cd: this.state.filters.ciId || undefined,
      lmt: this.state.recordsPerPage,
      oft: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  resetSearchValues = () => {
    this.setState(
      {
        filters: {
          minimartName: "",
          ciId: "",
        },
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        errorMessage: "",
      },
      this.handleOnClickCIFilter
    );
  };

  // #endregion

  // #region handle input field change
  handleSearchNameChange = (event) => {
    this.setState({
      filters: {
        ...this.state.filters,
        minimartName: event.target.value,
      },
    });
  };

  handleSearchCICodeChange = (event) => {
    this.setState({
      filters: {
        ...this.state.filters,
        ciId: event.target.value,
      },
    });
  };

  renderCISearchFilters = () => {
    let minimartFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("name"),
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.filters.minimartName,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("code"),
        handleOnChange: this.handleSearchCICodeChange,
        Value: this.state.filters.ciId,
        fieldname: SEARCH_FILTER_FIELDS.CI_CODE,
      },
    ];
    return (
      <Row className='pt-3 w-100'>
        <SearchFilter
          filters={minimartFilters}
          handleOnClickSearchFilter={this.handleOnClickCIFilter}
          removeLeftPadding={true}
          handleOnClickResetFilter={this.resetSearchValues}
          paddingLeft={3}
        />
      </Row>
    );
  };

  renderMinimartCards = () => {
    return (
      <Row className='px-3 w-100'>
        {this.props.minimarts.length >= 1 ? (
          <React.Fragment>
            {this.props.minimarts.map((minimart, index) => {
              return (
                <CustomCard
                  card={minimart}
                  key={index}
                  cardType={"CIApprovalMinimart"}
                  handleCardClick={this.handleCardClick}
                />
              );
            })}
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </React.Fragment>
        ) : (
          <div className='noRecordsFound'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </Row>
    );
  };

  render() {
    return (
      <div className='minimartApproval'>
        <div className='heading'>{Strings("MinimartApproval")}</div>
        <Row className='paper'>
          {this.renderCISearchFilters()}
          {this.renderMinimartCards()}
        </Row>
      </div>
    );
  }
}

export default MinimartApproval;

import React from "react";
import { Strings } from "../../resources/i18n/i18n";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import fileDownload from "js-file-download";

//Component
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import SalesPersonComission from "./SalesPersonComission";
import withHOC from "../../HOC/HOC";

//Actions
import {
  getCommissionSchemeByFilterAction,
  payCommissionAmountAction,
  gotoCommissionReportAction,
} from "./CommissionsAction";
import {
  dowloadedAction,
  setSearchFilterAction,
} from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import {
  SEARCH_FILTER_PAGES,
  COMMISSION_FILTER,
  COMMISSION_USER_ROLE,
} from "../../constants/appConstants";
import { PDF_NAME } from "../Comission/Constants";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./SalesPersonComission.scss";

class SalesPersonComissionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branches: [],
      zones: [],
      mZones: [],
      role: props.userRole,
      toShowToast: false,
      showLoader: false,
      numberOfPages: 1,
      data: [],
      errorMessage: props.errorMessage,
    };
    this.CardClick = false;
  }
  componentDidMount() {
    if (
      this.props.user === COMMISSION_USER_ROLE.SALES ||
      this.props.user === COMMISSION_USER_ROLE.MESSENGER
    ) {
      this.getBranchList();
      this.props.getZonesByBranch(
        this.props.commissionSearchFilter.br
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.COMMISSION,
        { ...COMMISSION_FILTER }
      );
    }
  }

  getBranchList = () => {
    this.setState({
      toShowToast: false,
    });
    this.props.getBranchList();
  };

  handleSearchFilter = (searchFilter) => {
    this.setState({
      showLoader: true,
      toShowToast: false,
      searchFilter: {
        br: searchFilter.branchId,
        zn: searchFilter.zone,
        un: searchFilter.name,
        limit: searchFilter.limit,
        offset: searchFilter.offset,
      },
    });
    searchFilter["role"] = this.state.role;
    getCommissionSchemeByFilterAction(searchFilter, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            data: response.data.data.uc,
            numberOfPages: response.data.data.np,
            showLoader: false,
            errorMessage: "",
          },
        );
      } else {
        this.setState(
          {
            errorMessage: response.error.message,
            showLoader: false,
          },
        );
      }
    });
  };

  payCommissionForSpecificUser = (requestBody, callback) => {
    this.setState({
      showLoader: true,
      toShowToast: false,
    });
    payCommissionAmountAction(requestBody, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            toShowToast: true,
            level: "success",
            message: Strings("paymentDoneSuccessFully"),
            showLoader: false,
            errorMessage: "",
          },
          () => {
            callback();
          }
        );
      } else {
        this.setState({
          toShowToast: true,
          message: response.error.message,
          level: "error",
          showLoader: false,
        });
      }
    });
  };

  gotoCommissionReport = (user) => {
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.COMMISSION,
      this.state.searchFilter,
      SEARCH_FILTER_PAGES.COMMISSION
    );
    this.props.gotoCommissionReportAction(user);
    this.props.history.push(ROUTES.COMMISSION_REPORT);
  };

  /**
   * Method to download sales person comission reports
   * @param {*} url
   * @param {*} requestData
   * @param {*} filetype
   */
  downloadReports = (url, requestData, filetype) => {
    requestData["role"] = this.state.role;
    this.setState({
      showLoader: true,
      toShowToast: false,
      level: "",
      message: ""
    });
    dowloadedAction(
      url,
      requestData,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
            fileDownload(
              response.data,
              PDF_NAME[this.props.user] +
              Utility.getFileType(filetype)
            );
            this.setState({
              errorMessage: "",
              showLoader: false,
            });
          } else {
            this.setState({
              toShowToast: true,
              showLoader: false,
              message: Strings("NoRecordsFound"),
              level: "error"
            });
          }
        } else {
          this.setState({
            showLoader: false,
            errorMessage: response.error.message,
          });
        }
      },
      "ComissionReports"
    );
  };

  render() {
    return (
      <div className='salesPersonComission'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.toShowToast && (
          <UserNotification
            key='Success'
            userNotificationObj={{
              message: Strings(this.state.message),
              level: this.state.level,
            }}
          />
        )}
        <SalesPersonComission
          user={this.props.user}
          branches={this.props.branches}
          errorMessage={this.state.errorMessage}
          data={this.state.data}
          handleSearchFilter={this.handleSearchFilter}
          numberOfPages={this.state.numberOfPages}
          payCommissionForSpecificUser={
            this.payCommissionForSpecificUser
          }
          gotoCommissionReport={this.gotoCommissionReport}
          loggedInUserRole={this.props.loggedInUser.role}
          zones={this.props.zones}
          mZones={this.props.mZones}
          getZonesByBranch={this.props.getZonesByBranch}
          downloadReports={this.downloadReports}
          searchFilter={this.props.commissionSearchFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); //NOSONAR

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      gotoCommissionReportAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

const SalesPersonComissionWrapper = withHOC(
  SalesPersonComissionContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SalesPersonComissionWrapper));

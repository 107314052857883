// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

/*
 * Actions not using dispatch
 * This will be called directly inside container without mapDispatchToProps
 */

export const changePasswordAction = (requestData, callback) => {
  callAPI(
    "ChangePassword",
    API.changePassword,
    "POST",
    requestData,
    (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        callback(apiResponse.status, apiResponse.data);
      } else {
        callback(apiResponse.status, null);
      }
    }
  );
};

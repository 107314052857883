import { Strings } from "../../resources/i18n/i18n";

export const STATUS_VALUES = [
    {
        key: 0,
        label: Strings("selectStatus"),
        value: "Estatus",
    },
    {
        key: 1,
        label: Strings("REQUEST_CREATED"),
        value: "REQUEST_CREATED",
    },
    {
        key: 2,
        label: Strings("PROCESSING"),
        value: "PROCESSING",
    },
    {
        key: 3,
        label: Strings("PROCESSED_WITH_FAILURE"),
        value: "PROCESSED_WITH_FAILURE",
    },
    {
        key: 4,
        label: Strings("PROCESSED"),
        value: "PROCESSED",
    },
];
export const DEFAULT_STATUS = Strings("Status")

export const COLUMNS = [
    {
        key: "cd",
        name: Strings("minimartCode"),
    },
    {
        key: "in",
        name: Strings("invoiceNumber"),
    },
    {
        key: "ia",
        name: Strings("CIInvoiceAmount"),
    },
    {
        key: "iba",
        name: Strings("CIInvoiceBalanceAmount"),
    },
    {
        key: "id",
        name: Strings("CIInvoiceDate"),
    },
    {
        key: "ied",
        name: Strings("CIInvoiceExpiryDate"),
    },
    {
        key: "appId",
        name: Strings("applicationId"),
    },
    {
        key: "st",
        name: Strings("Status"),
    },
    {
        key: "rd",
        name: Strings("CIInvoiceCreatedDate"),
    }
]
export const LOAN_REQUEST_REPORT = {
    CI_REPORT_DOWNLOAD: "/ci-app-loan/search-filter/download?fileType="

}
import { Strings } from "../../resources/i18n/i18n";
import React from "react";

export const CONFIGURATION_STATUS = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  drafted: "DRAFTED",
};

export const Mapdata_Save = "Mapdata_Save";

export const CONFIGURATION_LIST = [
  "EMI",
  "LOAN_STATUS",
  "EARLY_CANCELLATION",
  "GRADING_SCALE",
  "LATE_FEES",
  "LOAN_PICKUP",
  "GRACE_PERIOD",
  "PROVISION",
  "CIDATA_SCHEDULER",
];
export const WEEKDAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const GET_HOLIDAY_COLUMNS = () => [
  {
    key: "date",
    name: Strings("date"),
  },
  {
    key: "day",
    name: Strings("day"),
    formatter: ({ value }) => {
      return <div title={Strings(value)}>{Strings(value)}</div>;
    },
  },
  {
    key: "info",
    name: Strings("info"),
  },
];

export const GET_LOAN_DISCOUNT = () => [
  {
    key: "LoanType",
    name: Strings("loanType"),
  },
  {
    key: "Discount",
    name: Strings("discount"),
    formatter: ({ value }) => {
      return <div title={Strings(value)}>{Strings(value)}</div>;
    },
  },
];

export const TEMPLATE_MODAL_BUTTONS = {
  SET: "set",
  CANCEL: "cancel",
};

export const EMI_CONF_COL_NAME = {
  DURATION: "_DURATION",
  AMOUNT: "_AMOUNT",
};

export const TEMPLATES = {
  RANGE: "RANGE",
  MULTI_VALUE: "MULTI_VALUE",
  YES_NO: "YES_NO",
};

export const CONFIGURATION_EDIT_FIELD_KEYS = {
  WEIGHT: "weight",
  NAME: "name",
  START: "start",
  END: "end",
};

export const DATA_TYPE = {
  FLOAT: "float",
  INT: "int",
};

export const USER_COMMISSION = "USER_COMMISSION";
export const DAYS_ARREARS = "daysArrears";

export const MINIMART_LEVELS = [
  {
    key: 2,
    value: "LEVEL2",
    label: Strings("LEVEL2"),
  },
  {
    key: 3,
    value: "LEVEL3",
    label: Strings("LEVEL3"),
  },
  {
    key: 4,
    value: "LEVEL4",
    label: Strings("LEVEL4"),
  },
  {
    key: 5,
    value: "LEVEL5",
    label: Strings("LEVEL5"),
  },
  {
    key: 6,
    value: "LEVEL6",
    label: Strings("LEVEL6"),
  },
  {
    key: 7,
    value: "LEVEL7",
    label: Strings("LEVEL7"),
  },
];

export const FREQUENCY_LIST = [
  {
    label: "WEEKLY_ONCE",
    value: "WEEKLY_ONCE",
  },
  {
    label: "WEEKLY_TWICE",
    value: "WEEKLY_TWICE",
  },
  {
    label: "WEEKLY_THRICE",
    value: "WEEKLY_THRICE",
  },
  {
    label: "WEEKLY_SIX",
    value: "WEEKLY_SIX",
  },
  {
    label: "MONTHLY_ONCE",
    value: "MONTHLY_ONCE",
  },
  {
    label: "MONTHLY_TWICE",
    value: "MONTHLY_TWICE",
  },
];

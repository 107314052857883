import React from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OPERATION_STATUS } from "../../config/axios.init";

import { Strings } from "../../resources/i18n/i18n";
import BankConf from './BankConf';
import CommonPanel from '../CommonPanel/CommonPanel';
import {
    addNewAccountType,
    bankDetailsSearchFilter,
    getAllAccountTypes,
    addNewBank,
    editBank,
    editAccountTypeState,
    editBankState,
    getBankListAssociatedToAccount
} from './BankConfAction';
import LoadingModal from '../CustomUIComponents/LoadingModal/LoadingModal';
import UserNotification from '../CustomUIComponents/UserNotification/UserNotification';
import BankDetails from './BankDetails';
import { DEFAULT_VALUES, SEARCH_FILTER_FIELDS, SEARCH_FILTER_TYPE, USER_ROLES } from '../../constants/appConstants';
import { DEFAULT_PAGINATION_ACTIVE_PAGE, DEFAULT_PAGINATION_RECORDS_COUNT } from '../CustomUIComponents/Pagination/PaginationConstants';
import { BANK_STATUS } from './Constants';
import withHOC from '../../HOC/HOC';

class BankConfContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allAccountTypes: [],
            activeAccountTypes: [],
            bankDetails: [],
            filters: {},
            showModal: false,
            banksToBeDisabled: [],
            showLoader: false,
            showToast: false,
            level: "",
            message: "",
            readOnly:
                props.loggedInUser.role === USER_ROLES.analyst ||
                props.loggedInUser.role === USER_ROLES.operationsOfficer ||
                props.loggedInUser.role === USER_ROLES.operationsModerator ||
                props.loggedInUser.role === USER_ROLES.analystModerator ||
                props.loggedInUser.role === USER_ROLES.collectionsSupervisor ||
                props.loggedInUser.role === USER_ROLES.administrativeManager ||
                props.loggedInUser.role === USER_ROLES.customerService ||
                props.loggedInUser.role === USER_ROLES.salesManager ||
                props.loggedInUser.role === USER_ROLES.collectionsManager ||
                props.loggedInUser.role === USER_ROLES.technology,

            //Pagination
            numberOfPages: 0,
            recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
            activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        }
    }
    componentDidMount() {
        this.getAllAccountTypes();
        this.handleOnClickSearchFilter();
    }

    getAllAccountTypes = (ia = undefined) => {
        this.setState({
            showLoader: true,
        });

        let request = { ia: ia }
        getAllAccountTypes(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    allAccountTypes: request.ia ? this.state.allAccountTypes : response.data.data,
                    activeAccountTypes: request.ia ? response.data.data : this.state.activeAccountTypes,
                }, () => {
                    if (!request.ia) {
                        this.getAllAccountTypes(true);
                    }
                });
            } else {
                this.setState({
                    showLoader: false,
                    errorMessage: response.error.message,
                });
            }
        });
    }

    getBankListAssociatedToAccount = (ai, callBack) => {
        this.setState({
            showLoader: true,
        });

        getBankListAssociatedToAccount(ai, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    banksToBeDisabled: response.data.data,
                }, callBack);
            } else {
                this.setState({
                    showLoader: false,
                    errorMessage: response.error.message,
                }, callBack);
            }
        });
    }

    handleAddAccountType = (request, callBack) => {
        this.setState({
            showLoader: true,
            showToast: false,
            message: "",
            level: ""
        });
        addNewAccountType(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings("DataUpdatedSuccessfully"),
                    level: "success"
                }, () => {
                    callBack();
                    this.getAllAccountTypes();
                    this.handleOnClickSearchFilter();
                });
            } else {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings(response.error.message),
                    level: "error",
                }, () => {
                    callBack()
                });
            }
        });
    }

    handleAddBank = (request) => {
        this.setState({
            showLoader: true,
            showToast: false,
            message: "",
            level: ""
        });
        addNewBank(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings("DataUpdatedSuccessfully"),
                    level: "success"
                }, () => {
                    this.toggleModal();
                    this.handleOnClickSearchFilter();
                });
            } else {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings(response.error.message),
                    level: "error",
                }, () => {
                    this.toggleModal();
                });
            }
        });
    }

    handleEditBank = (request) => {
        this.setState({
            showLoader: true,
            showToast: false,
            message: "",
            level: ""
        });
        editBank(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings("DataUpdatedSuccessfully"),
                    level: "success"
                }, () => {
                    this.toggleModal();
                    this.handleOnClickSearchFilter();
                });
            } else {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings(response.error.message),
                    level: "error",
                });
            }
        });
    }

    editAccountTypeState = (accountType, callBack) => {
        this.setState({
            showLoader: true,
            showToast: false,
            message: "",
            level: ""
        });
        let request = {
            ai: accountType.ai,
            ia: accountType.ia !== true
        }
        editAccountTypeState(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings("DataUpdatedSuccessfully"),
                    level: "success"
                }, () => {
                    callBack();
                    this.getAllAccountTypes();
                    this.handleOnClickSearchFilter();

                });

            } else {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings(response.error.message),
                    level: "error",
                }, () => {
                    callBack();
                    this.getAllAccountTypes();
                    this.handleOnClickSearchFilter();
                });
            }
        });
    }

    editBankState = (bank, callBack) => {
        this.setState({
            showLoader: true,
            showToast: false,
            message: "",
            level: ""
        });
        let request = {
            bi: bank.bi,
            ia: bank.ia !== true
        }
        editBankState(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings("DataUpdatedSuccessfully"),
                    level: "success"
                }, () => {
                    callBack();
                    this.handleOnClickSearchFilter();
                });

            } else {
                this.setState({
                    showLoader: false,
                    showToast: true,
                    message: Strings(response.error.message),
                    level: "error",
                }, () => {
                    callBack();
                    this.handleOnClickSearchFilter();

                });
            }
        });
    }

    handleChangeAccountType = (event, id) => {
        let allAccountTypes = [...this.state.allAccountTypes];
        allAccountTypes.forEach((accountType) => {
            if (accountType.ai === id) {
                accountType.at = event.target.value.toUpperCase();
            }
        })
        this.setState({ allAccountTypes })
    }

    handleOnClickSearchFilter = () => {
        this.setState({
            showLoader: true,
        })
        let searchFilterObject = {
            bn: this.state.filters.bankName || undefined,
            ai: this.state.filters.accountType || undefined,
            ia: this.state.filters.bankState,
            limit: this.state.recordsPerPage,
            offset: this.state.activePage - 1
        }

        bankDetailsSearchFilter(searchFilterObject, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    numberOfPages: response.data.data.np,
                    bankDetails: response.data.data.li,
                });
            } else {
                this.setState({
                    showLoader: false,
                    errorMessage: response.error.message,
                });
            }
        });
    }

    handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        if (name === "bankName") {
            value = DEFAULT_VALUES.includes(event.target.value) ? "" : event.target.value.toUpperCase();
        } else {
            value = DEFAULT_VALUES.includes(event.target.value) ? "" : event.target.value
        }
        this.setState({
            filters: {
                ...this.state.filters,
                [event.target.name]: value
            }
        })
    }

    handleOnClickResetFilter = () => {
        this.setState({
            filters: {
                bankName: "",
                accountType: ""
            },

            //Pagination
            recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
            activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        }, this.handleOnClickSearchFilter)
    }


    onPageChange = ({ page, pageLength }) => {
        this.setState(
            {
                activePage: page,
                recordsPerPage: pageLength,
            },
            () => this.handleOnClickSearchFilter()
        );
    };

    renderBankDetails = () => {
        let activeAccountTypes = [...this.state.activeAccountTypes];
        if (this.state.activeAccountTypes.length !== 0 && activeAccountTypes[0].at !== Strings("accountTypes")) {
            activeAccountTypes.unshift({ ai: "", at: Strings("accountTypes") })
        }
        let searchFilters = [
            {
                type: SEARCH_FILTER_TYPE.INPUT,
                searchPlaceHolder: Strings("bankName"),
                handleOnChange: this.handleChange,
                Value: this.state.filters.bankName,
                fieldname: SEARCH_FILTER_FIELDS.BANK_NAME,
                inputType: SEARCH_FILTER_TYPE.INPUT,
                name: "bankName",
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleChange,
                Value: this.state.filters.accountType,
                fieldname: SEARCH_FILTER_FIELDS.ACCOUNT_TYPE,
                filterDropdownList: activeAccountTypes,
                name: "accountType",
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleChange,
                Value: this.state.filters.bankState,
                fieldname: SEARCH_FILTER_FIELDS.STATUS,
                filterDropdownList: BANK_STATUS,
                name: "bankState",
            },
        ]
        return <div className='mt-3'>
            <CommonPanel
                //variables
                header={Strings("bankDetails")}
                data={this.state.bankDetails}
                searchFilters={searchFilters}
                isPagination={true}
                numberOfPages={this.state.numberOfPages}
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}

                //methods
                handleOnClickSearchFilter={this.handleOnClickSearchFilter}
                handleOnClickResetFilter={this.handleOnClickResetFilter}
                onPageChange={this.onPageChange}

            >
                <BankDetails
                    //variables
                    activeAccountTypes={this.state.activeAccountTypes}
                    data={this.state.bankDetails}
                    showModal={this.state.showModal}
                    readOnly={this.state.readOnly}

                    //methods
                    handleAdd={this.handleAdd}
                    toggleModal={this.toggleModal}
                    handleAddBank={this.handleAddBank}
                    handleEditBank={this.handleEditBank}
                    editBankState={this.editBankState}
                />
            </CommonPanel>
        </div>
    }

    renderAccountTypes = () => {
        return <CommonPanel
            //variables
            header={Strings("accountTypes")}
        >
            <BankConf
                //variables
                allAccountTypes={this.state.allAccountTypes}
                banksToBeDisabled={this.state.banksToBeDisabled}
                readOnly={this.state.readOnly}

                //methods
                handleChangeAccountType={this.handleChangeAccountType}
                handleAddAccountType={this.handleAddAccountType}
                editAccountTypeState={this.editAccountTypeState}
                getBankListAssociatedToAccount={this.getBankListAssociatedToAccount}
            />
        </CommonPanel>
    }

    handleAdd = () => {
        this.setState({ showModal: true })
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    render() {
        return <>
            <LoadingModal
                showLoadingModal={this.state.showLoader}
            />
            {this.state.showToast && (
                <UserNotification
                    key={this.state.message}
                    userNotificationObj={{
                        message: this.state.message,
                        level: this.state.level,
                    }}
                />
            )}
            {this.renderAccountTypes()}
            {this.renderBankDetails()}


        </>
    }
}



const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllAccountTypes,
            addNewAccountType,
            editAccountTypeState,
            bankDetailsSearchFilter,
            editBank,
            addNewBank,
            editBankState,
            getBankListAssociatedToAccount
        }, dispatch
    );
};

const BankConfWrapper = withHOC(BankConfContainer);


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BankConfWrapper);


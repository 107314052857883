import React, { Component } from "react";
import { Form } from "react-bootstrap";

import "./CheckboxComponent.scss";

export default class CheckboxComponent extends Component {
  render() {

    return (
      <div
        className={
          this.props.className
            ? `checkboxComponent ${this.props.className}`
            : "checkboxComponent"
        }
      >
        {this.props.checked ? (
          <i
            onClick={this.props.onChange}
            className='fa fa-check-square selected cursorPointer'
            aria-hidden='true'
          />
        ) : (
          <i
            onClick={this.props.onChange}
            className='fa fa-square-o unselected cursorPointer'
            aria-hidden='true'
          />
        )}
        <Form.Check
          onChange={
            this.props.disabled === false
              ? (e) => {
                this.props.onChange(e);
              }
              : () => {
                //This is intensionalF
              }
          }
          className={this.props.labelClass}
          checked={this.props.checked}
          inline
          label={this.props.label}
          disabled={this.props.disabled}
          title={this.props.title}
        />
      </div>
    );
  }
}

import React from 'react';
import { withRouter, Route } from 'react-router-dom';

//components
import Layout from '../components/Layout/Layout';
import LoginContainer from '../components/Login/LoginContainer';

class AuthGuard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false,
            routes: {
                LOGIN: "/ingreso"
            }
        };
    }

    render() {
        const { path, component, exact, ...rest } = this.props;
        let file;

        if (path === "/privacyPolicy" || path === "/scalaPrivacyPolicy" || path === "/deactivateAccount") {
            file = <Route
                path={path}
                component={component}
                exact={exact}
                {...rest}
            />
        } else if (localStorage.getItem("state")
            && JSON.parse(localStorage.getItem("state")).loginReducer.loggedInUserInfo.userId) {
            file = <Layout>
                <Route
                    path={path}
                    component={component}
                    exact={exact}
                    {...rest}
                />
            </Layout>
        } else {
            file = <Route
                path={this.state.routes.LOGIN}
                component={LoginContainer}
                exact={exact}
                {...rest}
            />;
        }
        return file;
    }
}

export default withRouter(AuthGuard);
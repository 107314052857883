import React from "react";
import { Col, Row } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Switch } from "react-router-dom";

// Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import EditableModal from "../LoanDetails/EditableModal";

// Constants
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DEFAULT_VALUES,
  USER_ROLES
} from "../../constants/appConstants";

import {
  DEFAULT_PAGINATION_ACTIVE_PAGE,
  DEFAULT_PAGINATION_RECORDS_COUNT,
} from "../CustomUIComponents/Pagination/PaginationConstants";

import { TABLE_COLUMNS } from "./ZonePositionMappingConstants";
import {
  DEFAULT_MESSENGER_ZONE,
  DEFAULT_ZONE,
} from "../BranchList/BranchListConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./ZonePositionMapping.scss";

//Utility
import Utility from "../../utils/Utility";

//Assets
import editNote from "../../resources/images/ic_edit.svg";

class ZonePositionMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      filters: {
        zone: "",
        mZone: "",
        branch: "",
      },
      showModal: false,
      showZoneModal: false,
      branchId02: "",
      positionId02: "",
      zoneName02: "",
      positionId: "",
      positionName: "",
      errorMessage: this.props.errorMessage,
      zone: "",
      mZone: "",
      branchId: "",
      positionList: [{
        value: "",
        label: Strings("SelectPosition"),
      }],
      user: "",
      userList: [
        {
          value: "",
          label: Strings("username"),
        },
      ],
      isUserMandatory: false,
      isAddPosition: true,
      addPositionName: "",
      inValidCombination: false,
      position: "",
      activeTab: {
        key: 0,
        value: Strings("SALES_PERSON"),
      },
      Tabs: [
        {
          key: 0,
          value: Strings("SALES_PERSON"),
        },
        {
          key: 1,
          value: Strings("Messenger"),
        },
      ],
      readOnly: this.props.loggedInUser.role === USER_ROLES.customerService
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  /**
   * This function will be called on reset button for filter
   */
  handleOnClickResetFilter = () => {
    this.setState(
      {
        ...this.state,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        filters: {
          zone: "",
          mZone: "",
          branch: "",
        },
      },
      () => {
        this.props.getBranchList();
        this.props.getZonesByBranch();
        this.handleOnClickSearchFilter(false);
      }
    );
  };

  /**
   * This function will be called on clicked of filter/search
   */
  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    let zone = undefined;
    if (this.state.activeTab.key === 0) {
      zone = this.state.filters.zone
        ? this.state.filters.zone
        : undefined;
    } else if (this.state.activeTab.key === 1) {
      zone = this.state.filters.mZone
        ? this.state.filters.mZone
        : undefined;
    }
    this.props.handleOnClickSearchFilter({
      br: this.state.filters.branch
        ? this.state.filters.branch
        : undefined,
      zn: zone,
      rl:
        this.state.activeTab.key === 0 ? "SALES_PERSON" : "MESSENGER",
      lmt: this.state.recordsPerPage,
      oft: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  onFilterChange = (event) => {
    const name = event.target.name;
    const value = DEFAULT_VALUES.includes(event.target.value)
      ? ""
      : event.target.value;
    let stateData = {};
    if (name === "branch") {
      stateData.branch = value;
      stateData.zone = "";
      stateData.mZone = "";
    } else {
      stateData[name] = value;
    }
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...stateData,
        },
        errorMessage: "",
      },
      () => {
        if (name === "branch") {
          this.props.getZonesByBranch(this.state.filters.branch);
        }
      }
    );
  };

  renderSearchFilter = () => {
    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.onFilterChange,
        Value: this.state.filters.branch,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.props.branches,
        name: "branch",
      },
    ];

    if (this.state.activeTab.key === 0) {
      searchFilters.push({
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.onFilterChange,
        Value: this.state.filters.zone,
        fieldname: SEARCH_FILTER_FIELDS.ZONE,
        filterDropdownList: this.props.zones,
        name: "zone",
      });
    } else if (this.state.activeTab.key === 1) {
      searchFilters.push({
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.onFilterChange,
        Value: this.state.filters.mZone,
        fieldname: SEARCH_FILTER_FIELDS.MZONE,
        filterDropdownList: this.props.mZones,
        name: "mZone",
      });
    }
    return (
      <SearchFilter
        filters={searchFilters}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        paddingLeft={3}
        addAnotherButton={!this.state.readOnly && true}
        anotherButtonText={Strings("addZone")}
        AnotherButtonClick={this.toggleZoneModal}
      />
    );
  };

  getColumnData = (row, columnKey) => {
    switch (columnKey) {
      case "salesPosition":
        return (
          <div>
            {row?.salesPosition?.id
              ? row.salesPosition.name
              : Strings("NA")}
            {!this.state.readOnly && <span className='pencil mx-1 p-1'>
              <img
                src={editNote}
                className={"editIcon m-0 pl-1"}
                alt={"edit"}
                title={Strings("editPosition")}
                onClick={() =>
                  this.showChangePositionModal(
                    {
                      br: row.br,
                      zone: row.zone,
                      name: row.salesPosition.name,
                      id: row.salesPosition.id,
                    },
                    "SALES_PERSON"
                  )
                }
              />
            </span>}
          </div>
        );

      case "messengerPosition":
        return (
          <div>
            {row?.messengerPosition?.id
              ? row.messengerPosition.name
              : Strings("NA")}
            {!this.state.readOnly && <span className='pencil mx-1 p-1'>
              <img
                src={editNote}
                className={"editIcon m-0 pl-1"}
                alt={"edit"}
                title={Strings("editPosition")}
                onClick={() =>
                  this.showChangePositionModal(
                    {
                      br: row.br,
                      zone: row.zone,
                      name: row.messengerPosition.name,
                      id: row.messengerPosition.id,
                    },
                    "MESSENGER"
                  )
                }
              />
            </span>}
          </div>
        );
      default:
        return row[columnKey] ? row[columnKey] : Strings("NA");
    }
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        recordsPerPage: pageLength,
        activePage: page,
      },
      () => {
        this.handleOnClickSearchFilter(false);
      }
    );
  };

  showChangePositionModal = (row, role) => {
    let branch = this.props.branches.find(
      (obj) => obj.name === row.br
    );
    this.setState(
      {
        branchId: branch.id,
        role: role,
        zone: row.zone,
        positionId: row.id,
      },
      () => {
        this.props.getPositionsList(
          {
            branchId: this.state.branchId,
            role: this.state.role,
            positionId: this.state.positionId,
          },
          (list) => {
            this.setState(
              {
                positionList: list,
                errorMessage:
                  list.length > 1
                    ? ""
                    : Strings("PositionsNotAvailableError"),
              },
              () => {
                this.toggleModal("EDIT");
              }
            );
          }
        );
      }
    );
  };

  onChange = (event) => {
    let name = event.target.name;
    let value = DEFAULT_VALUES.includes(event.target.value)
      ? ""
      : event.target.value;

    this.setState(
      {
        [name]: name === "zoneName02" ? value.split(",") : value,
        errorMessage: "",
      },
      () => {
        if (name === "branchId") {
          this.props.getZonesByBranch(
            this.state.branchId === "" ? "" : this.state.branchId
          );
        }


        if (name === "branchId02") {
          this.props.getPositionsList(
            {
              branchId: this.state.branchId02,
              role: this.state.rl,
              positionId: 0,
            },
            (list) => {
              this.setState(
                {
                  positionList: list,
                  errorMessage:
                    list.length > 1
                      ? ""
                      : Strings("PositionsNotAvailableError"),
                }
              );
            }
          );
        }
      }
    );
  };

  toggleModal = (type = "") => {
    this.setState(
      {
        showModal: !this.state.showModal,
        type: type,
        rl:
          this.state.activeTab.key === 0
            ? "SALES_PERSON"
            : "MESSENGER",
        errorMessage: "",
        positionName: "",
        zone: type === "" ? "" : this.state.zone,
        branchId: type === "" ? "" : this.state.branchId,
        isUserMandatory: false,
        isAddPosition: true,
        addPositionName: "",
        inValidCombination: false,
        positionId: "",
        position: "",
        positionList: type === "EDIT" ? this.state.positionList : [{
          value: "",
          label: Strings("SelectPosition"),
        }],
      },
      () => {
        type === "ADD"
          ? this.props.getZonesByBranch()
          : type !== "EDIT" &&
          this.props.getZonesByBranch(this.state.filters.branch);
      }
    );
  };

  toggleZoneModal = () => {
    this.setState({
      showZoneModal: !this.state.showZoneModal,
      rl:
        this.state.activeTab.key === 0 ? "SALES_PERSON" : "MESSENGER",
      errorMessage: "",
      branchId02: "",
      positionId02: "",
      zoneName02: "",
      positionName: "",
      isUserMandatory: false,
      positionList: [{
        value: "",
        label: Strings("SelectPosition"),
      }],
    });
  };

  editPosition = () => {
    if (this.state.position === "") {
      this.setState({
        errorMessage: Strings("pleaseSelectRequiredFields"),
      });
    } else {
      this.props.editPosition(
        {
          zone: this.state.zone,
          role: this.state.role,
          positionId: this.state.position,
        },
        this.toggleModal
      );
    }
  };

  validateString = (string, minLength = 5, maxLength = 7) => {
    let errorMessage = null;
    if (string.trim().length === 0)
      errorMessage = Strings("pleaseSelectRequiredFields");
    else if (
      string.trim().length < minLength ||
      string.trim().length > maxLength
    )
      errorMessage = Strings("positionNameLengthError");
    else if (Utility.containsSpecialChars(string))
      errorMessage = Strings("specialCharError");
    return errorMessage;
  };

  isValidData = () => {
    if (
      this.state.rl === "" ||
      this.state.positionName === ""
    ) {
      return {
        isValid: false,
        message: "pleaseSelectRequiredFields",
      };
    }
    if (this.state.branchId !== "" && this.state.zone === "") {
      return {
        isValid: false,
        message: "pleaseSelectRequiredFields",
      };
    }
    let error = this.validateString(this.state.positionName);
    if (error) {
      return { isValid: false, message: error };
    }
    return { isValid: true };
  };

  addPosition = () => {
    let validData = this.isValidData();
    if (!validData.isValid) {
      this.setState({
        errorMessage: Strings(validData.message),
      });
    } else {
      this.setState(
        {
          errorMessage: "",
        },
        () => {
          this.props.addPosition(
            {
              rl: this.state.rl,
              pn: this.state.positionName,
              br: this.state.branchId,
              zn: this.state.zone,
              uid: this.state.user,
            },
            this.toggleModal
          );
        }
      );
    }
  };

  isValidZoneData = () => {
    if (
      this.state.branchId02 === "" ||
      this.state.zoneName02 === "" ||
      this.state.rl === "" ||
      this.state.positionId02 === ""
    ) {
      return {
        isValid: false,
        message: "pleaseSelectRequiredFields",
      };
    }
    return { isValid: true };
  };

  addZone = () => {
    let zt;
    if (this.state.rl === USER_ROLES.salesPerson) {
      zt = "SELLER"
    } else {
      zt = this.state.rl
    }
    let validData = this.isValidZoneData();
    if (!validData.isValid) {
      this.setState({
        errorMessage: Strings(validData.message),
      });
    } else {
      this.setState(
        {
          errorMessage: "",
        },
        () => {
          this.props.addZone(
            {
              zn: this.state.zoneName02,
              zt: zt,
              bi: this.state.branchId02,
              pid: this.state.positionId02,
            },
            this.toggleZoneModal
          );
        }
      );
    }
  };

  renderZoneModal = () => {
    let addZoneData = [
      {
        title: this.state.activeTab.key === 0
          ? Strings("zone")
          : Strings("mzone"),
        value: this.state.zoneName02,
        isMandatoryField: true,
        name: "zoneName02",
        type: "text",
        disabled: false,
      },
      {
        title: Strings("selectBranch"),
        value: this.state.branchId02,
        name: "branchId02",
        dropDownList: this.props.branches,
        type: "select",
        isSelection: true,
        isMandatoryField: true,
        disabled: this.state.type === "EDIT",
      },
      {
        title: Strings("SelectPosition"),
        value: this.state.positionId02,
        isMandatoryField: true,
        name: "positionId02",
        dropDownList: this.state.positionList,
        type: "select",
        isSelection: true,
        disabled:
          this.state.type === "EDIT" || this.state.branchId02 === "",
      },

    ];

    return (
      <EditableModal
        open={this.state.showZoneModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={
          this.state.activeTab.key === 0
            ? Strings("zone")
            : Strings("mzone")
        }
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.toggleZoneModal,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.addZone,
        }}
        onChange={this.onChange}
        editableData={addZoneData}
        errorMessage={this.state.errorMessage}
        ZONE_POSITION={true}
        type={this.state.type}
        isActionButtonDisable={!this.state.isAddPosition}
      />
    );
  };

  renderEditableModal = () => {
    let editableData = []
    if (this.state.type === "ADD") {
      editableData.push(
        {
          title: Strings("positionName"),
          value: this.state.positionName,
          isMandatoryField: true,
          name: "positionName",
          type: "text",
        }
      );
    }
    editableData.push(
      {
        title: Strings("selectBranch"),
        value: this.state.branchId,
        name: "branchId",
        dropDownList: this.props.branches,
        type: "select",
        isSelection: true,
        disabled: this.state.type === "EDIT",
      },
      {
        title:
          this.state.activeTab.key === 0
            ? Strings("selectZone")
            : Strings("mzone"),
        value: this.state.zone,
        name: "zone",
        dropDownList:
          this.state.activeTab.key === 0
            ? this.props.zones
            : this.props.mZones,
        type: "select",
        isSelection: true,
        disabled:
          this.state.type === "EDIT" || this.state.branchId === "",
      },
    );

    this.state.type === "EDIT" &&
      this.state.positionList.length > 0 &&
      editableData.push({
        title: Strings("SelectPosition"),
        value: this.state.position,
        isMandatoryField: true,
        name: "position",
        dropDownList: this.state.positionList,
        type: "select",
        isSelection: true,
        disabled: this.state.positionList.length < 2,
      });

    return (
      <EditableModal
        open={this.state.showModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={
          this.state.type === "EDIT"
            ? Strings("SelectPosition")
            : Strings("AddPosition")
        }
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.toggleModal,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick:
            this.state.type === "EDIT"
              ? this.editPosition
              : this.addPosition,
        }}
        onChange={this.onChange}
        editableData={editableData}
        errorMessage={this.state.errorMessage}
        ZONE_POSITION={true}
        type={this.state.type}
        isActionButtonDisable={!this.state.isAddPosition}
      />
    );
  };

  renderCustomDataGrid = () => {
    let tab =
      this.state.activeTab.key === 0 ? "SALES_PERSON" : "MESSENGER";
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer
          component={Paper}
          className='d-flex justify-content-center'
        >
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {TABLE_COLUMNS[tab].length > 0 &&
                  TABLE_COLUMNS[tab].map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"header"}
                      title={column.name}
                    >
                      <div
                        className={
                          column.key === "br" || column.key === "zone"
                            ? "tableCell"
                            : ""
                        }
                      >
                        {column.name}
                      </div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex /*+ row.userPreservedData.id*/}
                  hover
                  style={{ cursor: "pointer" }}
                >
                  {TABLE_COLUMNS[tab].map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"px-3"}
                      component='th'
                      scope='row'
                      title={column.name}
                      style={{ minWidth: column.minWidth }}
                    >
                      {this.getColumnData(row, column.key)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  renderTabsDetails = () => {
    return (
      <TabPanel>
        <Switch>
          <div>
            <div className='TabListContainer'>
              {this.renderSearchFilter()}

              <Row>
                <Col md={12} className=' errorText pt-2'>
                  {this.props.errorMessage}
                </Col>
              </Row>

              {this.props.rows.length !== 0 ? (
                <div className='userListTable pt-2'>
                  {this.renderCustomDataGrid()}
                  <Col md={12} className='noPadding alignPagination'>
                    <Pagination
                      activePage={this.state.activePage}
                      recordsPerPage={this.state.recordsPerPage}
                      numberOfPages={this.props.numberOfPages}
                      onPageChange={this.onPageChange}
                      dropup={true}
                    />
                  </Col>
                </div>
              ) : (
                <div className='noRecordsFound'>
                  {Strings("NoRecordsFound")}
                </div>
              )}
            </div>
          </div>
        </Switch>
      </TabPanel>
    );
  };

  handleTabs = (index) => {
    this.setState(
      {
        activeTab: this.state.Tabs[index],
        zone: DEFAULT_ZONE,
        mZone: DEFAULT_MESSENGER_ZONE,
        errorMessage: "",
      },
      this.handleOnClickSearchFilter
    );
  };

  render() {
    return (
      <div className='dailyStatus'>
        {this.state.showModal && this.renderEditableModal()}
        {this.state.showZoneModal && this.renderZoneModal()}
        <Row>
          <span className='heading'>
            {" "}
            {Strings("ZonePositionMapping")}
          </span>
        </Row>
        <Tabs
          className='pt-3'
          selectedIndex={this.state.activeTab.key}
          onSelect={this.handleTabs}
        >
          <TabList>
            {this.state.Tabs.map((item) => (
              <Tab key={item.key}>{item.value}</Tab>
            ))}
          </TabList>
          {this.renderTabsDetails()}
          {this.renderTabsDetails()}
        </Tabs>
      </div>
    );
  }
}

export default ZonePositionMapping;

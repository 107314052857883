//React components
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

//Components
import DailyStatus from "./DailyStatus";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import withHOC from "../../HOC/HOC";

// Actions
import {
  getPiechartDataForAllSalesPersonsAction,
  getAssignedSalesPersonListAction,
  setSalesPersonIdAction,
  getPiechartDataForAllMessengersAction,
  getAssignedMessengersListAction,
  setMessengerIdAction,
  setDateForSalesPersonAction,
  setDateForMessengerAction,
} from "./DailyStatusActions";
import { setMiniMartDetailsAction } from "../CiInterface/CIInterfaceActions";
import { getUserProfilePicUrlAction } from "../ProfileDetails/ProfileDetailsActions";
import { setSearchFilterAction } from "../../constants/Action";
import { setIsNewMinimartAction } from "../MinimartList/MinimartListActions";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import {
  PIE_CHART_MESSENGER_KEYS,
  OTHER_DATA_MESSENGER_KEYS,
  USER_ROLES,
  MESSENGER_LOAN_LEGENDS_CONST,
  DAILY_STATUS_MESSENGER_LEGENDS_CONST,
  SEARCH_FILTER_PAGES,
  DAILY_STATUS_FILTER,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Styles
import "./DailyStatus.scss";

//Assets
import ProfileImage from "../../resources/images/ic_user.svg";

// Utils
import Utility from "../../utils/Utility";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import moment from "moment";

class DailyStatusContainer extends React.Component {
  /* #region  LifeCycle Methods */
  constructor(props) {
    super(props);
    this.state = {
      piechartStats: [],
      messengerPiechartStats: [
        {
          key: DAILY_STATUS_MESSENGER_LEGENDS_CONST.ARREARS_AMOUNT,
          count: 0,
        },
        {
          key: DAILY_STATUS_MESSENGER_LEGENDS_CONST.PENDING_AMOUNT,
          count: 0,
        },
        {
          key: DAILY_STATUS_MESSENGER_LEGENDS_CONST.PAST_DUE_COLLECTION,
          count: 0,
        },
        {
          key: DAILY_STATUS_MESSENGER_LEGENDS_CONST.DUE_COLLECTION,
          count: 0,
        },
      ],
      MessengerLoanData: [
        {
          key: MESSENGER_LOAN_LEGENDS_CONST.COLLECTED_LOANS,
          count: 0,
        },
        { key: MESSENGER_LOAN_LEGENDS_CONST.PENDING_LOANS, count: 0 },
        { key: MESSENGER_LOAN_LEGENDS_CONST.DENIED_LOANS, count: 0 },
      ],
      otherData: {
        amountToBeCollected: 0,
        depositedAmount: 0,
        amountInstransit: 0,
        totalAmount: 0,
        totalAssignedMinimarts: 0,
        visitedMinimarts: 0,
      },
      errorMessage: props.errorMessage,
      showLoader: false,
      salesPersonInfos: [],
      messengerInfos: [],
      numberOfPages: DEFAULT_PAGINATION_ACTIVE_PAGE,
      messengerNumberOfPages: DEFAULT_PAGINATION_ACTIVE_PAGE,
      PersonFilters: {
        name: "",
        rating: "",
        branchId: "",
        date:
          this.props.salesPersonDate !== ""
            ? this.props.salesPersonDate
            : new Date(),
        limit: DEFAULT_PAGINATION_RECORDS_COUNT,
        offset: DEFAULT_PAGINATION_ACTIVE_PAGE,
      },
      activeTab:
        this.props.loggedInUser.role ===
          USER_ROLES.messengerModerator ||
          this.props.loggedInUser.role ===
          USER_ROLES.collectionOfficer ||
          this.props.loggedInUser.role ===
          USER_ROLES.accountingOfficer ||
          this.props.loggedInUser.role === USER_ROLES.auditor
          || this.props.loggedInUser.role === USER_ROLES.collectionsManager
          || this.props.loggedInUser.role === USER_ROLES.collectionsSupervisor
          || this.props.loggedInUser.role === USER_ROLES.administrativeManager
          ? 1
          : 0,
      showToast: false
    };
    this.APIProccessingCount = 0;
  }

  componentDidMount() {
    this.props.getBranchList();
    this.props.getZonesByBranch();
    if (
      this.state.activeTab === 0 &&
      this.props.loggedInUser.role !== USER_ROLES.messengerModerator
      && this.props.loggedInUser.role !== USER_ROLES.collectionOfficer
      && this.props.loggedInUser.role !== USER_ROLES.accountingOfficer
      && this.props.loggedInUser.role !== USER_ROLES.auditor
      && this.props.loggedInUser.role !== USER_ROLES.collectionsManager
      && this.props.loggedInUser.role !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUser.role !== USER_ROLES.administrativeManager
      && this.props.history.location.pathname !== ROUTES.DAILY_STATUS
    ) {
      this.handleOnClickSearchFilter(this.state.PersonFilters);
      this.getPiechartDataForAllSalesPersons({
        date: this.props.salesPersonDate
          ? this.props.salesPersonDate
          : new Date(),
      });
    }
    if (
      this.state.activeTab === 1 &&
      this.props.loggedInUser.role !== USER_ROLES.salesModerator &&
      (this.props.history.location.pathname ===
        ROUTES.DAILY_STATUS_MESSENGER ||
        this.props.history.location.pathname === ROUTES.DAILY_STATUS)
    ) {
      this.handleMessengerSearchFilter(this.state.PersonFilters);
      this.getPiechartDataForAllMesengers({
        date: this.props.messengerDate
          ? this.props.messengerDate
          : new Date(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  componentWillUnmount() {
    if (
      this.props.searchPage !== SEARCH_FILTER_PAGES.DAILY_STATUS &&
      this.props.searchPage !==
      SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.DAILY_STATUS,
        { ...DAILY_STATUS_FILTER }
      );
    }
  }

  /* #endregion */

  /* #region  Actions */
  getPiechartDataForAllSalesPersons = (searchFilterObject) => {
    getPiechartDataForAllSalesPersonsAction(
      searchFilterObject,
      (responseData) => {
        if (responseData.status === OPERATION_STATUS.SUCCESS) {
          let pieChartStats = { ...responseData.data.data };
          pieChartStats.tobeVisited =
            pieChartStats.totalAssignedMinimarts -
            pieChartStats.visitedMinimarts;
          this.setState({
            piechartStats: pieChartStats,
            errorMessage: "",
          });
        } else {
          this.setState({
            errorMessage: responseData.error.message,
          });
        }
      }
    );
  };

  getPiechartDataForAllMesengers = (searchFilterObject) => {
    if (searchFilterObject.date === undefined || !moment(searchFilterObject.date).isValid()) {
      this.setState({
        showToast: true
      });
    }
    else {
      getPiechartDataForAllMessengersAction(
        searchFilterObject,
        (responseData) => {
          if (responseData.status === OPERATION_STATUS.SUCCESS) {
            this.setState({
              errorMessage: "",
            });
            this.ConvertForMessegerPieChart(responseData.data.data);
          } else {
            this.setState({
              errorMessage: responseData.error.message,
            });
          }
        }
      );
    }
  };

  handleOnClickSearchFilter = (
    searchFilterObject
  ) => {
    //NOSONAR
    this.APIProccessingCount += 1;
    this.setState({
      showLoader: true,
    });

    let filterObject = searchFilterObject;
    filterObject = {
      name: searchFilterObject.name ? searchFilterObject.name : "",
      branchId: searchFilterObject.branchId
        ? searchFilterObject.branchId
        : "",
      zone: searchFilterObject.zone ? searchFilterObject.zone : "",
      date: searchFilterObject.date ? searchFilterObject.date : "",
      rating: searchFilterObject.rating
        ? searchFilterObject.rating
        : "",
      limit: searchFilterObject.limit,
      offset: searchFilterObject.offset,
    };

    getAssignedSalesPersonListAction(filterObject, (responseData) => {
      this.APIProccessingCount -= 1;
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        if (
          responseData.data.data &&
          responseData.data.data.salesPersonInfos === null
        ) {
          this.setState({
            salesPersonInfos: [],
            numberOfPages: responseData.data.data.numberOfPages,
            errorMessage: "",
            showLoader: this.APIProccessingCount !== 0,
          });
        } else {
          this.setState({
            numberOfPages: responseData.data.data.numberOfPages,
          });
          this.getAllUserListProfilePic(
            responseData.data.data.salesPersonInfos
          );
        }
      } else {
        this.setState({
          errorMessage: responseData.error.message,
          showLoader: this.APIProccessingCount !== 0,
        });
      }
    });
  };

  handleMessengerSearchFilter = (
    searchFilterObject
  ) => {
    //NOSONAR
    this.APIProccessingCount += 1;
    this.setState({
      showLoader: true,
    });
    let filterObject = searchFilterObject;

    filterObject = {
      name: searchFilterObject.name ? searchFilterObject.name : "",
      branchId: searchFilterObject.branchId
        ? searchFilterObject.branchId
        : "",
      zone: searchFilterObject.zone ? searchFilterObject.zone : "",
      date: searchFilterObject.date ? searchFilterObject.date : "",
      rating: searchFilterObject.rating
        ? searchFilterObject.rating
        : "",
      limit: searchFilterObject.limit,
      offset: searchFilterObject.offset,
    };
    getAssignedMessengersListAction(filterObject, (responseData) => {
      this.APIProccessingCount -= 1;
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        if (
          responseData.data.data &&
          responseData.data.data.messengersList.length === 0
        ) {
          this.setState({
            messengerInfos: [],
            messengerNumberOfPages:
              responseData.data.data.numberOfPages,
            errorMessage: "",
            showLoader: this.APIProccessingCount !== 0,
          });
        } else {
          this.setState({
            messengerNumberOfPages:
              responseData.data.data.numberOfPages,
          });
          this.getAllUserListProfilePic(
            responseData.data.data.messengersList
          );
        }
      } else {
        this.setState({
          errorMessage: responseData.error.message,
          showLoader: this.APIProccessingCount !== 0,
        });
      }
    });
  };

  getAllUserListProfilePic = (usersResponseData) => {
    if (usersResponseData) {
      let profilePic = [];
      usersResponseData.map((users) => {
        this.props.getUserProfilePicUrlAction(
          users.userName,
          (response) => {
            if (
              response.status === OPERATION_STATUS.SUCCESS &&
              response.data.data.s3Url
            ) {
              profilePic.push({
                userName: users.userName,
                image: Utility.getDecryptedData(
                  response.data.data.s3Url
                ),
              });
            } else {
              profilePic.push({
                userName: users.userName,
                image: ProfileImage,
              });
            }
            this.setState(
              {
                usersProfilePic: profilePic,
              },
              () => {
                this.callCreateUsers(usersResponseData);
              }
            );
          }
        );
        return null;
      });
    }
  };

  /* #endregion */

  /* #region  Class Methods */

  callCreateUsers = (usersResponseData) => {
    this.setState({ usersResponseData: usersResponseData }, () => {
      if (this.state.usersResponseData) {
        this.createRows(usersResponseData);
      }
    });
  };

  createRows = (responseData) => {
    //Row create for each element in response data array
    let rows = [],
      userData = responseData;
    _.forEach(userData, (userDetails) => {
      let userImage =
        this.getProfilePic(userDetails.userName) || ProfileImage;
      userDetails.profileUrl = userImage;
      rows.push(userDetails);
    });
    responseData[0].userRole === USER_ROLES.salesPerson &&
      this.setState({
        salesPersonInfos: rows,
        showLoader: this.APIProccessingCount !== 0,
      });

    responseData[0].userRole === USER_ROLES.messenger &&
      this.setState({
        messengerInfos: rows,
        showLoader: this.APIProccessingCount !== 0,
      });
  };

  getProfilePic = (username) => {
    let image = "";
    this.state.usersProfilePic.map((users) => {
      if (users.userName === username) {
        image = users.image;
      }
      return null;
    });
    return image;
  };

  ConvertForMessegerPieChart = (data) => {
    let keys = Object.keys(data);
    let pieData = [];
    let MessengerLoanData = [];
    let otherData = {};
    keys.forEach((key) => {
      if (PIE_CHART_MESSENGER_KEYS.amountArray.includes(key)) {
        return pieData.push({
          key: key,
          count: parseInt(Number(data[key])),
        });
      }
    });

    keys.forEach((key) => {
      if (PIE_CHART_MESSENGER_KEYS.loanArray.includes(key)) {
        return MessengerLoanData.push({ key: key, count: data[key] });
      }
    });

    let i;
    for (i = 0; i < keys.length; i++) {
      if (OTHER_DATA_MESSENGER_KEYS.includes(keys[i])) {
        Object.defineProperty(otherData, keys[i], {
          value: Utility.getDecimalNumberUptoDigit(data[keys[i]], 1),
          writable: true,
          enumerable: true,
          configurable: true,
        });
      }
    }
    this.setState({
      messengerPiechartStats: pieData,
      MessengerLoanData: MessengerLoanData,
      otherData: otherData,
    });
  };

  /* #endregion */

  /* #region  Setters */

  setSalesPersonID = (salesPerson) => {
    this.props.setSalesPersonIdAction(salesPerson);
  };

  setMessengerId = (Messenger) => {
    this.props.setMessengerIdAction(Messenger);
  };

  setDateForSalesPerson = (salesPersonDate) => {
    this.props.setDateForSalesPersonAction(salesPersonDate);
  };

  setDateForMessenger = (messengerDate) => {
    this.props.setDateForMessengerAction(messengerDate);
  };

  setMiniMartDetails = (minimart) => {
    this.props.setIsNewMinimartAction(minimart.type === "NEW");
    this.props.setMiniMartDetailsAction(minimart);
    this.props.history.push(ROUTES.MINIMART_DETAILS);
  };

  /* #endregion */

  render() {
    return (
      <div className='dailyStatus'>
        {this.state.showToast && (
          <UserNotification
            key={this.state.message}
            userNotificationObj={{
              message: Strings("warningForInvalidDate"),
              level: "error",
            }}
          />
        )}
        <span className='heading'>{Strings("DailyStatus")}</span>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <DailyStatus
          piechartStats={this.state.piechartStats}
          salesPersonInfos={this.state.salesPersonInfos}
          branches={this.props.branches}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          getPiechartDataForAllSalesPersons={
            this.getPiechartDataForAllSalesPersons
          }
          setSalesPersonID={this.setSalesPersonID}
          setDateForSalesPerson={this.setDateForSalesPerson}
          setMessengerId={this.setMessengerId}
          setDateForMessenger={this.setDateForMessenger}
          setMiniMartDetails={this.setMiniMartDetails}
          getPiechartDataForAllMesengers={
            this.getPiechartDataForAllMesengers
          }
          handleMessengerSearchFilter={
            this.handleMessengerSearchFilter
          }
          messengerNumberOfPages={this.state.messengerNumberOfPages}
          numberOfPages={this.state.numberOfPages}
          messengerInfos={this.state.messengerInfos}
          messengerPiechartStats={this.state.messengerPiechartStats}
          MessengerLoanData={this.state.MessengerLoanData}
          otherData={this.state.otherData}
          loggedInUser={this.props.loggedInUser}
          salesPersonDate={this.props.salesPersonDate}
          messengerDate={this.props.messengerDate}
          loggedInUserRole={this.props.loggedInUser.role}
          searchFilter={this.props.dailyStatusSearchFilter}
          setSearchFilterAction={this.props.setSearchFilterAction}
          getZonesByBranch={this.props.getZonesByBranch}
          zones={this.props.zones}
          mZones={this.props.mZones}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  salesPersonDate: state.DailyStatusReducer.salesPersonDate,
  messengerDate: state.DailyStatusReducer.messengerDate,
  searchPage: state.searchFilterReducer.searchPage,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSalesPersonIdAction,
      setMiniMartDetailsAction,
      setMessengerIdAction,
      setDateForSalesPersonAction,
      setDateForMessengerAction,
      getUserProfilePicUrlAction,
      // getBranchDetailsAction,
      setSearchFilterAction,
      // getZonesUnderSelectedBranchAction,
      setIsNewMinimartAction,
    },
    dispatch
  );
};

const DailyStatusWrapper = withHOC(DailyStatusContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyStatusWrapper);

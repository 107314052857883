import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Button } from "react-bootstrap";

//Components
import PieChartComponent from "../CustomUIComponents/PieChartComponent/PieChartComponent";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";
import Rater from "react-rater";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

//Actions
import {
  getSalesPersonDailyAsignmentAction,
  getPieChartDataForSingleSalesPersonAction,
  getPiechartDataForSingleMessengerAction,
  getMessengerDailyAsignmentAction,
} from "./DailyStatusActions";
import {
  setWorkHistoryFiltersAction,
  setUserIdAction,
} from "../WorkHistory/WorkHistoryAction";
import { setSearchFilterAction } from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  PIE_CHART_COLORS,
  DAILY_STATUS_SALES_PERSON_LEGENDS,
  DAILY_STATUS_MESSENGER_LEGENDS,
  DAILY_STATUS_SALES_PERSON_LOAN_LEGEND,
  MESSENGER_LOAN_LEGENDS,
  MINIMART_TYPE_LEGEND,
  VISIT_STATUS,
  MINIMART_GRADE,
  TYPE_OF_CUSTOMER,
  DEFAULT_TYPE_OF_CUSTOMER_SELECTION,
  PIE_CHART_MESSENGER_KEYS,
  OTHER_DATA_MESSENGER_KEYS,
  USER_ROLES,
  CURRENCY,
  MINIMART_RATING,
  SPECIAL_MESSENGER_PRESENTATION,
  SPECIAL,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  MESSENGER_LOAN_LEGENDS_CONST,
  DAILY_STATUS_MESSENGER_LEGENDS_CONST,
  PIE_CHART_COLORS_CONST,
  SEARCH_FILTER_PAGES,
  PERSON_DAILY_STATUS_FILTER,
  MINIMART_LEVELS,
} from "../../constants/appConstants";
import { LEGEND_KEY_CONST } from "./DailyStatusConstants";
import { ROUTES } from "../../constants/routeConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Assets
import ProfileImage from "../../resources/images/ic_user.svg";
import deposited from "../../resources/images/ic_deposited.svg";
import inTransit from "../../resources/images/ic_in_transit.svg";
import Utility from "../../utils/Utility";

//Styles
import "react-rater/lib/react-rater.css";

class PersonDailyDetails extends React.Component {
  /* #region  LifeCycle Methods */
  constructor(props) {
    super(props);
    this.state = {
      piechartStats: [],
      errorMessage: "",
      showLoader: false,
      minimarts: [],
      recordsPerPage: props.searchFilter.limit,
      activePage: props.searchFilter.offset,
      numberOfPages: DEFAULT_PAGINATION_ACTIVE_PAGE,
      minimartFilters: {
        name: props.searchFilter.mn,
        visitStatus: props.searchFilter.vs,
        grade: props.searchFilter.gr,
        typeOfCustomer: props.searchFilter.toc,
        level: props.searchFilter.lvl,
      },
      salesPersonId: props.salesPerson.userId,
      messengerId:
        props.messenger && props.messenger !== null
          ? props.messenger.userId
          : 0,
      date: props.date,
      messengerPiechartStats: [
        {
          key: DAILY_STATUS_MESSENGER_LEGENDS_CONST.ARREARS_AMOUNT,
          count: 0,
        },
        {
          key: DAILY_STATUS_MESSENGER_LEGENDS_CONST.PENDING_AMOUNT,
          count: 0,
        },
        {
          key: DAILY_STATUS_MESSENGER_LEGENDS_CONST.PAST_DUE_COLLECTION,
          count: 0,
        },
        {
          key: DAILY_STATUS_MESSENGER_LEGENDS_CONST.DUE_COLLECTION,
          count: 0,
        },
      ],
      loanData: [
        {
          key: MESSENGER_LOAN_LEGENDS_CONST.COLLECTED_LOANS,
          count: 0,
        },
        { key: MESSENGER_LOAN_LEGENDS_CONST.PENDING_LOANS, count: 0 },
        { key: MESSENGER_LOAN_LEGENDS_CONST.DENIED_LOANS, count: 0 },
      ],
      otherData: {
        amountToBeCollected: 0,
        depositedAmount: 0,
        amountInstransit: 0,
        totalAmount: 0,
        totalAssignedMinimarts: 0,
        visitedMinimarts: 0,
      },
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.props.salesPerson !== null &&
      this.getPieChartDataForSingleSalesPerson(
        this.props.salesPerson.userId,
        this.props.messenger &&
        this.props.messenger !== null &&
        this.props.messenger.userId,
        this.props.renderPerson === USER_ROLES.salesPerson
          ? this.props.salesPersonDate
          : this.props.messengerDate
      );
    this.handleClickSearchFilter(false);
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS,
        { ...PERSON_DAILY_STATUS_FILTER }
      );
    }
  }

  /* #endregion */

  static getDerivedStateFromProps(props, state) {
    //NOSONAR
    if (props.messengerDate !== "") {
      return {
        date: props.messengerDate,
      };
    }

    if (props.salesPersonDate !== "") {
      return {
        date: props.salesPersonDate,
      };
    }
    return null;
  } /* #region  Search Filter */

  handleClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.handleOnClickSearchFilter({
      salesPersonId: this.state.salesPersonId,
      messengerId: this.state.messengerId,
      name: this.state.minimartFilters.name,
      visitStatus: this.state.minimartFilters.visitStatus,
      date:
        this.props.renderPerson === USER_ROLES.salesPerson
          ? this.props.salesPersonDate
          : this.props.messengerDate,
      category: this.state.minimartFilters.grade,
      type: this.state.minimartFilters.typeOfCustomer,
      level: this.state.minimartFilters.level,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({ showLoader: true });
    this.props.renderPerson === USER_ROLES.salesPerson
      ? getSalesPersonDailyAsignmentAction(
        searchFilterObject,
        (responseData) => {
          if (responseData.status === OPERATION_STATUS.SUCCESS) {
            const minimarts =
              responseData.data.data
                .salesPersonAssignedMinimartsList;
            this.setState({
              minimarts,
              numberOfPages: responseData.data.data.numberOfPages,
              errorMessage: "",
              showLoader: false,
            });
          } else {
            this.setState({
              errorMessage: responseData.error.message,
              showLoader: false,
            });
          }
        }
      )
      : getMessengerDailyAsignmentAction(
        searchFilterObject,
        (responseData) => {
          if (responseData.status === OPERATION_STATUS.SUCCESS) {
            this.setState({
              minimarts: responseData.data.data.minimartList,
              numberOfPages: responseData.data.data.numberOfPages,
              errorMessage: "",
              showLoader: false,
            });
          } else {
            this.setState({
              errorMessage: responseData.error.message,
              showLoader: false,
            });
          }
        }
      );
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        minimartFilters: {
          name: "",
          visitStatus: "",
          grade: "",
          typeOfCustomer: "",
        },
      },
      () => {
        this.handleClickSearchFilter(true);
      }
    );
  };

  /* #endregion */

  /* #region  Events */
  handleSearchNameChange = (event) => {
    this.setState({
      minimartFilters: {
        ...this.state.minimartFilters,
        name: event.target.value,
      },
    });
  };

  handleVisitStatusChange = (event) => {
    if (event.target.value !== Strings("selectVisitStatus")) {
      this.setState({
        minimartFilters: {
          ...this.state.minimartFilters,
          visitStatus: event.target.value,
        },
      });
    } else {
      this.setState({
        minimartFilters: {
          ...this.state.minimartFilters,
          visitStatus: "",
        },
      });
    }
  };

  handleLevelChange = (event) => {
    this.setState({
      minimartFilters: {
        ...this.state.minimartFilters,
        level:
          event.target.value === Strings("minimartLevel")
            ? ""
            : event.target.value,
      },
    });
  };

  handleGradeChange = (event) => {
    if (event.target.value !== Strings("selectMiniMartGrade")) {
      this.setState({
        minimartFilters: {
          ...this.state.minimartFilters,
          grade: event.target.value,
        },
      });
    } else {
      this.setState({
        minimartFilters: {
          ...this.state.minimartFilters,
          grade: "",
        },
      });
    }
  };

  handleTypeOfCustomerChange = (event) => {
    if (
      event.target.value !== DEFAULT_TYPE_OF_CUSTOMER_SELECTION.label
    ) {
      this.setState({
        minimartFilters: {
          ...this.state.minimartFilters,
          typeOfCustomer: event.target.value,
        },
      });
    } else {
      this.setState({
        minimartFilters: {
          ...this.state.minimartFilters,
          typeOfCustomer: "",
        },
      });
    }
  };

  /* #endregion */

  /* #region  Pagination */

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleClickSearchFilter(false)
    );
  };

  /* #endregion */

  renderLegend = (color, grade, data, isMessenger, index, key) => {
    //NOSONAR
    let colorStyle = {
      backgroundColor: color,
      height: "1.5em",
      width: "1.5em",
    };
    return !isMessenger ? (
      <Row className='legend' key={Math.random()}>
        <div className='pl-0' style={colorStyle} />
        <div
          className='pl-2 branchNameInLegends textWrap'
          title={Strings(grade)}
        >
          {Strings(grade)} &nbsp;&nbsp;
        </div>
        <div className='branchNameInLegends font-weight-bold'>
          {data}
        </div>
      </Row>
    ) : (
      <Row className='legend' key={grade + data + index}>
        <div className='pl-0' style={colorStyle} />
        <Col md={6} className='pr-0'>
          <div className='branchNameInLegends' title={Strings(grade)}>
            {Strings(grade)} &nbsp;&nbsp;
          </div>
        </Col>
        <div className='branchNameInLegends font-weight-bold'>
          {data}
        </div>
      </Row>
    );
  };

  /* #region  Actions */
  setDateForSalesPerson = (salesPersonDate) => {
    this.props.setDateForSalesPerson(salesPersonDate);
  };

  setDateForMessenger = (messengerDate) => {
    this.props.setDateForMessenger(messengerDate);
  };

  setSalesPersonID = (salesPerson) => {
    this.props.setSalesPersonIdAction(salesPerson);
  };

  /* #endregion */

  /* #region  Work History Button Event */

  gotoWorkHistory = () => {
    this.isCardClick = true;
    let name =
      this.props.renderPerson === USER_ROLES.messenger
        ? this.props.messenger.firstName +
        " " +
        this.props.messenger.lastName
        : "";
    this.props.renderPerson === USER_ROLES.salesPerson
      ? this.props.setUserIdAction(this.props.salesPerson.userId)
      : this.props.renderPerson === USER_ROLES.messenger &&
      this.props.setUserIdAction(this.props.messenger.userId);
    this.props.setWorkHistoryFiltersAction({
      userName:
        this.props.renderPerson === USER_ROLES.salesPerson
          ? this.props.salesPerson.firstName +
          " " +
          this.props.salesPerson.lastName
          : name,
      fromDate: new Date(
        this.props.renderPerson === USER_ROLES.salesPerson
          ? this.props.salesPersonDate
          : this.props.messengerDate
      ).toISOString(),
      toDate: new Date(
        this.props.renderPerson === USER_ROLES.salesPerson
          ? this.props.salesPersonDate
          : this.props.messengerDate
      ).toISOString(),
    });
    this.props.history.push(
      ROUTES[`${this.props.renderPerson}_WORK_HISTORY`]
    );
  };

  /* #endregion */

  /* #region  Methods */

  getPieChartDataForSingleSalesPerson = (
    salesePersonId,
    messengerId,
    date
  ) => {
    this.setState({ showLoader: true });
    this.props.renderPerson === USER_ROLES.salesPerson
      ? getPieChartDataForSingleSalesPersonAction(
        salesePersonId,
        date,
        (responseData) => {
          if (responseData.status === OPERATION_STATUS.SUCCESS) {
            this.setState({
              piechartStats: responseData.data.data,
              errorMessage: "",
              showLoader: false,
            });
          } else {
            this.setState({
              errorMessage: responseData.error.message,
              showLoader: false,
            });
          }
        }
      )
      : getPiechartDataForSingleMessengerAction(
        messengerId,
        date,
        (responseData) => {
          if (responseData.status === OPERATION_STATUS.SUCCESS) {
            this.setState({
              errorMessage: "",
              showLoader: false,
            });
            this.ConvertForMessegerPieChart(responseData.data.data);
          } else {
            this.setState({
              errorMessage: responseData.error.message,
              showLoader: false,
            });
          }
        }
      );
  };

  ConvertForMessegerPieChart = (data) => {
    let keys = Object.keys(data);
    let pieData = [];
    let loanData = [];
    let otherData = {};
    let i;

    keys.forEach((key) => {
      if (PIE_CHART_MESSENGER_KEYS.amountArray.includes(key)) {
        return pieData.push({
          key: key,
          count: parseInt(Number(data[key])),
        });
      }
    });

    keys.map((key) => {
      if (PIE_CHART_MESSENGER_KEYS.loanArray.includes(key)) {
        return loanData.push({ key: key, count: Number(data[key]) });
      } else return null;
    });

    for (i = 0; i < keys.length; i++) {
      if (OTHER_DATA_MESSENGER_KEYS.includes(keys[i])) {
        Object.defineProperty(otherData, keys[i], {
          value: Utility.getDecimalNumberUptoDigit(data[keys[i]], 1),
          writable: true,
          enumerable: true,
          configurable: true,
        });
      }
    }

    this.setState({
      messengerPiechartStats: pieData,
      loanData: loanData,
      otherData: otherData,
    });
  };
  handleBackButtonClick = () => {
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS,
      { ...PERSON_DAILY_STATUS_FILTER },
      SEARCH_FILTER_PAGES.DAILY_STATUS
    );
    this.props.handleBackButtonClick();
  };
  setMiniMartDetails = (minimart) => {
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS,
      {
        mn: this.state.minimartFilters.name,
        vs: this.state.minimartFilters.visitStatus,
        gr: this.state.minimartFilters.grade,
        toc: this.state.minimartFilters.typeOfCustomer,
        lvl: this.state.minimartFilters.level,
        limit: this.state.recordsPerPage,
        offset: this.state.activePage,
      },
      SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS
    );
    this.props.setMiniMartDetails(minimart);
  };

  renderSalesPersonDetails = (
    data,
    dailyloanArray,
    minimartFilters
  ) => {
    return (
      <div>
        <Row className='pt-3'>
          <Col md={1}>
            <img
              className='UserImage'
              src={
                this.props.salesPerson.profileUrl === "0" ||
                  this.props.salesPerson.profileUrl === null
                  ? ProfileImage
                  : this.props.salesPerson.profileUrl
              }
              onError={(error) => {
                error.target.onError = null;
                error.target.src = ProfileImage;
              }}
              alt='ProfilePic'
              key={Math.random()}
            />
          </Col>
          <Col md={9} className='align-self-center'>
            <Row>
              <Col md={3} className='alignCenter noPadding'>
                <span className='colorText'>
                  {this.props.salesPerson.firstName}&nbsp;
                  {this.props.salesPerson.lastName}
                </span>
              </Col>
              <Col md={5} className='alignCenter'>
                <span className='titleText'>{Strings("Branch")}</span>
                &nbsp;:&nbsp;
                <span className='colorText'>
                  {this.props.salesPerson.branchName}
                </span>
              </Col>

              <Col
                md={4}
                className='alignCenter d-flex justify-content-center'
              >
                <div>
                  <span className='titleText'>
                    {" "}
                    {Strings("Date")}&nbsp;:
                  </span>
                  <span className='colorText'>
                    &nbsp;
                    {Utility.getDateFromMiliSeconds(
                      this.props.salesPersonDate
                    )}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3} className='alignCenter noPadding'>
                <Rater
                  id={this.props.salesPerson.id}
                  title={Strings("Rating")}
                  rating={this.props.salesPerson.rating}
                  total={5}
                  interactive={false}
                  style={{
                    fontSize: 15 + "px",
                    color: "#3ea853",
                  }}
                />
                &nbsp;
                <span
                  className='colorText'
                  title={Strings("minimartUserRating")}
                >
                  {`(${MINIMART_RATING[this.props.salesPerson.mRat]
                    })`}
                </span>
              </Col>
              <Col md={9} className='alignCenter'>
                <span className='titleText'>{Strings("Zones")}</span>
                &nbsp;:&nbsp;
                {this.props.salesPerson.zones &&
                  this.props.salesPerson.zones.length > 0 ? (
                  <span className='colorText'>
                    {this.getZones(this.props.salesPerson.zones)}
                  </span>
                ) : (
                  Strings("NA")
                )}
              </Col>
            </Row>
          </Col>
          {this.props.loggedInUserRole !==
            USER_ROLES.operationsOfficer &&
            this.props.loggedInUserRole !==
            USER_ROLES.operationsModerator &&
            this.props.loggedInUserRole !==
            USER_ROLES.accountingOfficer &&
            this.props.loggedInUserRole !==
            USER_ROLES.customerService ? (
            <Col md={2} className='alignCenter'>
              <Button
                className='buttonBackground'
                onClick={this.gotoWorkHistory}
              >
                {Strings("WorkHistory")}
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>

        <hr className='newhr' />
        <Row className=' pt-4  pb-2'>
          <Col md={4}>
            <span className='subHeading'>{Strings("minimarts")}</span>
          </Col>
          <Col md={6}>
            <span className='subHeading'>{Strings("Loans")}</span>
          </Col>
        </Row>
        <Row>
          <Col md={2} className=' pr-0'>
            <PieChartComponent
              data={data}
              colors='salesPersonDailyStatus'
              index={0}
              innerRadius='60%'
              outerRadius='90%'
              datakey='count'
              height={150}
              showLabel={true}
              total={
                this.state.piechartStats.totalAssignedMinimarts !==
                  undefined
                  ? this.state.piechartStats.totalAssignedMinimarts
                  : 0
              }
            // moreWidth={true}
            />
          </Col>
          <Col md={2} className='alignLegends pl-0'>
            {DAILY_STATUS_SALES_PERSON_LEGENDS.map(
              (legend, index) => {
                return this.renderLegend(
                  PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST.SALES_PERSON_DAILY_STATUS
                  ][index],
                  legend.key,
                  data[index].count,
                  false,
                  index
                );
              }
            )}
          </Col>
          <Col md={2} className=' pr-0'>
            <PieChartComponent
              data={dailyloanArray}
              colors='salesPersonDailyStatusForLoans'
              index={0}
              innerRadius='60%'
              outerRadius='90%'
              datakey='count'
              height={150}
              showLabel={true}
              total={
                this.state.piechartStats.totalLoans !== undefined
                  ? this.state.piechartStats.totalLoans
                  : 0
              }
            // moreWidth={true}
            />
          </Col>
          <Col md={2} className='alignLegends pl-0'>
            {DAILY_STATUS_SALES_PERSON_LOAN_LEGEND.map(
              (legend, index) => {
                return this.renderLegend(
                  PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST
                    .SALES_PERSON_DAILY_STATUS_FOR_LOANS
                  ][index],
                  legend,
                  dailyloanArray[index].count,
                  false,
                  index,
                  LEGEND_KEY_CONST.SALES_LOANS
                );
              }
            )}
          </Col>
          <Col md={3} className='alignLegends pl-5 pr-0'>
            <Row className='legend'>
              <div className='dailyStatusNameInLegends'>
                {Strings("created")}
                {CURRENCY}
                {this.state.piechartStats.createdLoanAmount
                  ? Utility.getCurrencyRepresentationOfAmount(
                    this.state.piechartStats.createdLoanAmount
                  )
                  : 0}
              </div>
            </Row>
            <Row className='legend'>
              <div className='dailyStatusNameInLegends'>
                {Strings("disbursed")}
                {CURRENCY}
                {this.state.piechartStats.disbursedLoanAmount
                  ? Utility.getCurrencyRepresentationOfAmount(
                    this.state.piechartStats.disbursedLoanAmount
                  )
                  : 0}
              </div>
            </Row>
          </Col>
        </Row>

        <Row>
          <div className='pl-4'>
            <span className='subHeading'>
              {Strings("assignedMinimarts")}
            </span>
          </div>
        </Row>

        <SearchFilter
          filters={minimartFilters}
          handleOnClickSearchFilter={this.handleClickSearchFilter}
          handleOnClickResetFilter={this.handleOnClickResetFilter}
          paddingLeft={true}
        />
        <CustomerTypeLegend
          arrayOfTypes={MINIMART_TYPE_LEGEND}
          paddingLeft={4}
        />

        <Row className='cardAlign'>
          {this.state.minimarts.length > 0 ? (
            <Col md={12} className='w-100 pr-0'>
              <Row className='w-100'>
                {this.state.minimarts.map((minimart, index) => {
                  return (
                    <CustomCard
                      isNewMinimart={minimart.type === "NEW"}
                      card={minimart}
                      key={index}
                      cardType='minimart'
                      isMessenger={false}
                      handleCardClick={this.setMiniMartDetails}
                    />
                  );
                })}
              </Row>
            </Col>
          ) : (
            <div className='noRecordsFound noBranchRecords'>
              {Strings("NoRecordsFound")}
            </div>
          )}

          <Col md={12} className='noPadding'>
            <div className='pull-left pt-3 pl-3'>
              <Button
                className='buttonBorder'
                onClick={this.handleBackButtonClick}
              >
                {Strings("Back").toUpperCase()}
              </Button>
            </div>
            {this.state.minimarts.length > 0 && (
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.state.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  };

  getZones = (zones) => {
    return zones.map((zone) => {
      return (
        <span className='px-1' key={zone}>
          {zone}
        </span>
      );
    });
  };

  renderMessengerDetails = (data, loanData, minimartFilters) => {
    //NOSONAR
    return (
      <div>
        <Row className='pt-3'>
          <Col md={1}>
            <img
              className='UserImage'
              src={
                this.props.messenger.profileUrl === "0" ||
                  this.props.messenger.profileUrl === null
                  ? ProfileImage
                  : this.props.messenger.profileUrl
              }
              onError={(error) => {
                error.target.onError = null;
                error.target.src = ProfileImage;
              }}
              alt='ProfilePic'
              key={Math.random()}
            />
          </Col>
          <Col md={9} className='align-self-center'>
            <Row>
              <Col md={3} className='alignCenter'>
                <span
                  className='colorText'
                  title={
                    this.props.messenger.type !== undefined &&
                      this.props.messenger.type === SPECIAL
                      ? this.props.messenger.firstName +
                      " " +
                      this.props.messenger.lastName +
                      " " +
                      SPECIAL_MESSENGER_PRESENTATION
                      : this.props.messenger.firstName +
                      " " +
                      this.props.messenger.lastName
                  }
                >
                  {this.props.messenger.type !== undefined &&
                    this.props.messenger.type === SPECIAL &&
                    SHORT_SPECIAL_MESSENGER_PRESENTATION}{" "}
                  {this.props.messenger.firstName}&nbsp;
                  {this.props.messenger.lastName}
                </span>
              </Col>
              <Col md={7} className='alignCenter'>
                <span className='titleText'>
                  {Strings("Branch")}:
                </span>
                &nbsp;&nbsp;
                <span className='colorText'>
                  {this.props.messenger.branchName}
                </span>
              </Col>

              <Col md={2} className='text-left px-0'>
                <div>
                  <span className='titleText'>
                    {" "}
                    {Strings("Date")}&nbsp;:
                  </span>
                  <span className='colorText'>
                    &nbsp;
                    {Utility.getDateFromMiliSeconds(
                      this.props.messengerDate
                    )}
                  </span>
                </div>
              </Col>

              <Col md={3}>
                <Rater
                  id={this.props.messenger.userId}
                  title={this.props.messenger.rating}
                  rating={this.props.messenger.rating}
                  total={5}
                  interactive={false}
                  style={{
                    fontSize: 15 + "px",
                    color: "#3ea853",
                  }}
                />
              </Col>

              <Col md={7}>
                <Row>
                  <Col md={2} className='px-0 labelText'>
                    {" "}
                    <span className='titleText'>
                      {Strings("Zones")}
                    </span>
                    &nbsp;:&nbsp;
                  </Col>
                  <Col md={10} className='px-0 text-wrap text-break'>
                    {this.props.messenger.zones &&
                      this.props.messenger.zones.length > 0 ? (
                      <span className='colorText'>
                        {this.getZones(this.props.messenger.zones)}
                      </span>
                    ) : (
                      Strings("NA")
                    )}
                  </Col>
                </Row>
              </Col>

              <Col md={2} className=' px-0 text-left'>
                <span className='titleText'>
                  {Strings("Effectiveness")}
                </span>
                &nbsp;:&nbsp;
                <span className='colorText'>
                  {Utility.getDecimalNumberUptoDigit(
                    this.props.messenger.ef,
                    2
                  )}
                </span>
              </Col>
            </Row>
          </Col>
          {this.props.loggedInUserRole !==
            USER_ROLES.operationsOfficer &&
            this.props.loggedInUserRole !==
            USER_ROLES.operationsModerator &&
            this.props.loggedInUserRole !==
            USER_ROLES.accountingOfficer &&
            this.props.loggedInUserRole !==
            USER_ROLES.customerService ? (
            <Col md={2} className='alignCenter'>
              <Button
                className='buttonBackground '
                onClick={this.gotoWorkHistory}
              >
                {Strings("WorkHistory")}
              </Button>
            </Col>
          ) : (
            ""
          )}
          {/* <Col md={1} className="alignCenter pl-0">
            <Button
              className="buttonBorder"
              onClick={this.handleBackButtonClick}
            >
              {Strings("Back")}
            </Button>
          </Col> */}
        </Row>
        <hr className='newhr' />
        <Row className=' pt-4  pb-2'>
          <Col md={{ span: 4 }} className='pl-4'>
            <span className='subHeading'>{Strings("Loans")}</span>
          </Col>
          <Col md={3}>
            <span className='subHeading'>{Strings("amount")}</span>
          </Col>
        </Row>
        <Row>
          <Col md={2} className='pr-0'>
            <PieChartComponent
              data={loanData}
              colors='messengerDailyStatusLoans'
              index={0}
              innerRadius='65%'
              outerRadius='90%'
              datakey='count'
              height={150}
              showLabel={true}
              total={
                this.state.loanData !== null &&
                this.state.otherData.totalAssignedMinimarts
              }
            // moreWidth={true}
            />
          </Col>
          <Col md={2} className='align-self-center pl-0'>
            {this.state.loanData.length > 0 &&
              MESSENGER_LOAN_LEGENDS.map((legend, index) => {
                return this.renderLegend(
                  PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST
                    .MESSENGER_DAILY_STATUS_LOANS
                  ][index],
                  legend,
                  this.state.loanData[index].count,
                  false,
                  index,
                  LEGEND_KEY_CONST.LOANS
                );
              })}
          </Col>
          <Col md={2} className='pr-0'>
            <PieChartComponent
              data={this.state.messengerPiechartStats}
              colors='messengerPiechartStats'
              index={0}
              innerRadius='65%'
              outerRadius='90%'
              datakey='count'
              height={150}
              showLabel={true}
              total={
                this.state.otherData !== null &&
                CURRENCY +
                Utility.getCurrencyRepresentationOfAmount(
                  this.state.otherData.totalAmount
                )
              }
            // moreWidth={true}
            />
          </Col>
          <Col md={3} className='alignLegends px-0'>
            {this.state.messengerPiechartStats.length > 0 &&
              DAILY_STATUS_MESSENGER_LEGENDS.map((legend, index) => {
                return this.renderLegend(
                  PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST.MESSENGER_PIECHART_STATS
                  ][index],
                  legend,
                  CURRENCY +
                  Utility.getCurrencyRepresentationOfAmount(
                    this.state.messengerPiechartStats[index].count
                  ),
                  true,
                  index,
                  LEGEND_KEY_CONST.AMOUNT
                );
              })}
            <hr className='divHr' />
            <Row className='pt-2'>
              <Col md={2} className='noPadding'>
                <span className='subHeading'>
                  {Strings("extraAmount")}
                </span>
              </Col>
              <Col md={{ span: 4 }}>
                <div className='subHeading paddingLeft px-0'>
                  {CURRENCY +
                    (this.state.otherData &&
                      this.state.otherData.extraAmount
                      ? parseInt(this.state.otherData.extraAmount)
                      : 0)}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={{ span: 3 }} className='subHeading pt-3'>
            <Row>
              <Col md={2}>
                <img src={deposited} alt='deposited' />
              </Col>
              <Col md={6} className='pt-2 pb-4'>
                <Row>{Strings("Deposited")}</Row>
                <Row>
                  {" "}
                  {this.state.otherData !== null &&
                    CURRENCY +
                    Utility.getCurrencyRepresentationOfAmount(
                      this.state.otherData.depositedAmount
                    )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <img src={inTransit} alt='inTransit' />
              </Col>
              <Col md={9} className='pt-2 pb-4'>
                <Row>{Strings("InTransit")}</Row>
                <Row>
                  {" "}
                  {this.state.otherData !== null &&
                    CURRENCY +
                    Utility.getCurrencyRepresentationOfAmount(
                      this.state.otherData.amountInstransit
                    )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <div className='pl-4'>
            <span className='subHeading'>
              {Strings("assignedMinimarts")}
            </span>
          </div>
        </Row>

        <SearchFilter
          filters={minimartFilters}
          handleOnClickSearchFilter={this.handleClickSearchFilter}
          handleOnClickResetFilter={this.handleOnClickResetFilter}
          paddingLeft={true}
        />
        <CustomerTypeLegend
          arrayOfTypes={MINIMART_TYPE_LEGEND}
          paddingLeft={4}
        />
        <Row className='cardAlign'>
          {this.state.minimarts.length > 0 ? (
            <Col md={12} className='w-100 pr-0'>
              <Row className='w-100'>
                {this.state.minimarts.map((minimart, index) => {
                  return (
                    <CustomCard
                      isNewMinimart={minimart.type === "NEW"}
                      card={minimart}
                      key={index}
                      cardType='minimart'
                      isMessenger={true}
                      handleCardClick={this.setMiniMartDetails}
                    />
                  );
                })}
              </Row>
            </Col>
          ) : (
            <div className='noRecordsFound noBranchRecords'>
              {Strings("NoRecordsFound")}
            </div>
          )}
          <Col md={12} className='noPadding'>
            <div className='pull-left pt-3 pl-3'>
              <Button
                className='buttonBorder'
                onClick={this.handleBackButtonClick}
              >
                {Strings("Back").toUpperCase()}
              </Button>
            </div>
            {this.state.minimarts.length > 0 && (
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.state.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  };

  /* #endregion */

  render() {
    TYPE_OF_CUSTOMER[0].value !== "" &&
      TYPE_OF_CUSTOMER.unshift(DEFAULT_TYPE_OF_CUSTOMER_SELECTION);
    let minimartFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("name"),
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.minimartFilters.name,
        fieldname: SEARCH_FILTER_FIELDS.SALES_PERSON_NAME,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleVisitStatusChange,
        Value: this.state.minimartFilters.visitStatus,
        fieldname: SEARCH_FILTER_FIELDS.VISIT_STATUS,
        filterDropdownList: VISIT_STATUS,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleLevelChange,
        Value: this.state.minimartFilters.level,
        fieldname: SEARCH_FILTER_FIELDS.LEVEL,
        filterDropdownList: MINIMART_LEVELS,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleGradeChange,
        Value: this.state.minimartFilters.grade,
        fieldname: SEARCH_FILTER_FIELDS.GRADE,
        filterDropdownList: MINIMART_GRADE,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleTypeOfCustomerChange,
        Value: this.state.minimartFilters.typeOfCustomer,
        fieldname: SEARCH_FILTER_FIELDS.TYPEOFCUSTOMER,
        filterDropdownList: TYPE_OF_CUSTOMER,
      },
    ];

    let tobevisitedminimarts =
      this.state.piechartStats.totalAssignedMinimarts -
      this.state.piechartStats.visitedMinimarts;

    let data = [
      { count: this.state.piechartStats.visitedMinimarts },
      { count: tobevisitedminimarts },
    ];

    let dailyloanArray = [
      { count: this.state.piechartStats.createdLoanCount },
      { count: this.state.piechartStats.disbursedLoanCount },
      { count: this.state.piechartStats.deniedLoans },
      { count: this.state.piechartStats.pendingLoans },
    ];

    return (
      <div className='salesPerson'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.props.renderPerson === USER_ROLES.salesPerson &&
          this.renderSalesPersonDetails(
            data,
            dailyloanArray,
            minimartFilters
          )}
        {this.props.renderPerson === USER_ROLES.messenger &&
          this.renderMessengerDetails(
            data,
            this.state.loanData,
            minimartFilters
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  branches: state.branchListReducer.branchDetails.branches,
  salesPerson: state.DailyStatusReducer.salesPerson,
  salesPersonDate: state.DailyStatusReducer.salesPersonDate,
  messengerDate: state.DailyStatusReducer.messengerDate,
  messenger: state.DailyStatusReducer.messenger,
  searchFilter:
    state.searchFilterReducer.personDailyStatusSearchFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUserIdAction,
      setWorkHistoryFiltersAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PersonDailyDetails));

import React from "react";

// String
import { Strings } from "../../resources/i18n/i18n";

// Style
import { Col, Row } from "react-bootstrap";

// Component
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

// Constant
import {
  GENERAL_LOAN_STATUS_LEGEND,
  LOAN_CATEGORY_LEGEND,
} from "../../constants/appConstants";

class Loans extends React.Component {
  render() {
    return (
      <div className='loans pt-3'>
        <SearchFilter
          filters={this.props.loanFilters}
          handleOnClickSearchFilter={
            this.props.handleOnClickSearchFilter
          }
          handleOnClickResetFilter={
            this.props.handleOnClickResetFilter
          }
          paddingRight={0}
        />
        <CustomerTypeLegend
          heading={Strings("ApplicationStatuses")}
          arrayOfTypes={GENERAL_LOAN_STATUS_LEGEND}
          paddingRight={4}
        />
        <CustomerTypeLegend
          heading={Strings("LoanCategories")}
          arrayOfTypes={LOAN_CATEGORY_LEGEND}
          paddingRight={4}
        />

        {this.props.loans.length > 0 ? (
          <Row className='cardAlign'>
            <Col md={12} className='w-100 pr-0'>
              <Row className='w-100'>
                {this.props.loans.map((card) => {
                  return (
                    <CustomCard
                      card={card}
                      cardType='loan'
                      key={card.applicationId}
                      handleCardClick={this.props.setLoanDetailsID}
                      isAnalyst={this.props.isAnalyst}
                    />
                  );
                })}
              </Row>
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.props.activePage}
                recordsPerPage={this.props.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.props.onPageChange}
                dropup={true}
                isAnalyst={this.props.isAnalyst}
              />
            </Col>
          </Row>
        ) : (
          <div className='noRecordsFound noBranchRecords'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default Loans;

import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";
import { DEFAULT_DURATION } from "../../constants/appConstants";
import { DEFAULT_PAGINATION_RECORDS_COUNT } from "../CustomUIComponents/Pagination/PaginationConstants";

export const getCiLoanRequestReportAction = (
    searchFilterObject,
    callback
) => {
    if (searchFilterObject.search.offset) {
        searchFilterObject.search.offset = searchFilterObject.search.offset - 1
    }
    // NOSONAR
    let requestData =
        Object.keys(searchFilterObject.search).length >= 1
            ?
            {
                search: {
                    cd:
                        searchFilterObject.search.minimartCode &&
                            searchFilterObject.search.minimartCode !== ""
                            ? searchFilterObject.search.minimartCode
                            : undefined,
                    ri:
                        searchFilterObject.search.referenceId &&
                            searchFilterObject.search.referenceId !== ""
                            ? searchFilterObject.search.referenceId
                            : undefined,
                    in:
                        searchFilterObject.search.invoiceNumber &&
                            searchFilterObject.search.invoiceNumber !== ""
                            ? searchFilterObject.search.invoiceNumber
                            : undefined,
                    st:
                        searchFilterObject.search.status &&
                            searchFilterObject.search.status !== ""
                            ? searchFilterObject.search.status
                            : undefined,
                    fd:
                        searchFilterObject.search.from &&
                            searchFilterObject.search.from !== ""
                            ? searchFilterObject.search.from
                            : DEFAULT_DURATION.fromDate,
                    td:
                        searchFilterObject.search.to &&
                            searchFilterObject.search.to !== ""
                            ? searchFilterObject.search.to
                            : DEFAULT_DURATION.toDate,
                },
                limit: searchFilterObject.search.limit
                    ? searchFilterObject.search.limit
                    : DEFAULT_PAGINATION_RECORDS_COUNT,
                offset:
                    searchFilterObject.search.offset
                        ? searchFilterObject.search.offset
                        : 0,
            }
            : {
                search: {},
                limit: searchFilterObject.pagination.limit
                    ? searchFilterObject.pagination.limit
                    : DEFAULT_PAGINATION_RECORDS_COUNT,
                offset:
                    searchFilterObject.search.offset
                        ? searchFilterObject.search.offset
                        : 0,
            };
    callAPI(
        "getCiLoanRequestReportAction",
        API.getCiLoanRequestReport,
        "POST",
        requestData,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};
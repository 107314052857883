import axios from "axios";

//Utils
import { API_ERROR_CODE, OPERATION_STATUS } from "./axios.init";
import { ROUTES } from "../constants/routeConstants";

let interceptorResponse;
export const initializeInterceptors = (history, logoutAction) => {
  interceptorResponse = axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        if (
          error.response.status === API_ERROR_CODE.UNAUTHORIZED &&
          !window.location.href.endsWith("ingreso")
        ) {
          logoutAction((apiResponse) => {
            if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
              if (!localStorage.getItem("state") || !JSON.parse(localStorage.getItem("state")).loginReducer.loggedInUserInfo.userId) {
                history.push(ROUTES.LOGIN);
              }
            }
          });
        } else {
          reject(error);
        }
      });
    }
  );
};

export const removeInterceptors = () => {
  interceptorResponse &&
    axios.interceptors.response.eject(interceptorResponse);
};

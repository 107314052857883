import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row } from "react-bootstrap";
import _ from "lodash";

// Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import ConsolidatedMapComponent from "../ConsolidatedMap/ConsolidatedMapComponent";

//Actions
import {
  getMapDataAction,
  getLocationDataAction,
} from "./ConsolidatedMapAction";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Assets
import minimartGradeC from "../../resources/images/minimartGradeC.svg";
import minimartGradeB from "../../resources/images/minimartGradeB.svg";
import minimartGradeA from "../../resources/images/minimartGradeA.svg";
import minimartGradeD from "../../resources/images/minimartGradeD.svg";
import minimartGradeE from "../../resources/images/minimartGradeE.svg";

class ConsolidatedMapContainer extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this.state = {
      miniMartData: [],
      singleMinimartData: null,
    };
  }

  componentDidMount() {
    this.props.clearDailyStatusDateAction();
    this.getLocationData();
  }

  /* #endregion */

  /* #region  Actions */

  getLocationData = (SearchFilterObject) => {
    this.setState({ showLoader: true });
    getLocationDataAction(SearchFilterObject, (response) => {
      this.setState({ showLoader: false });
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.createRows(response.data.data.minimartsLocationDetails);
      } else {
        this.setState({
          errorMessage: Strings("SorryAnErrorHasOccurred"),
        });
      }
    });
  };

  getMapData = (
    matricesId,
    callback = () => {
      // This is intensional
    }
  ) => {
    this.setState({ showLoader: true });

    getMapDataAction(matricesId, (response) => {
      this.setState({ showLoader: false });

      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({ singleMinimartData: response.data.data });
        callback();
      } else {
        this.setState({
          errorMessage: Strings("SorryAnErrorHasOccurred"),
        });
      }
    });
  };

  /* #endregion */

  /* #region  Methods */

  createRows = (responseData) => {
    //Row create for each element in response data array
    let rows = [],
      miniMarts = responseData;

    _.forEach(miniMarts, (singleMinimart) => {
      let grade = this.getMinimartGrade(singleMinimart.ct);
      rows.push({
        mid: singleMinimart.mid,
        cd: singleMinimart.cd,
        ct: grade,
        lt: singleMinimart.lt,
        lg: singleMinimart.lg,
      });
    });
    this.setState({ miniMartData: rows });
  };

  getMinimartGrade = (grade) => {
    let minimartGrade;
    switch (grade) {
      case "A":
        minimartGrade = minimartGradeA;
        break;
      case "B":
        minimartGrade = minimartGradeB;
        break;
      case "C":
        minimartGrade = minimartGradeC;
        break;
      case "D":
        minimartGrade = minimartGradeD;
        break;
      case "E":
        minimartGrade = minimartGradeE;
        break;
      default:
        break;
    }
    return minimartGrade;
  };

  /* #endregion */

  render() {
    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoader}
          title={Strings("ThisWillTakeSomeTime")}
        />
        <Row className='heading'>
          {Strings("MapWithMinimartGrade")}
        </Row>
        <ConsolidatedMapComponent
          miniMartData={this.state.miniMartData}
          singleMinimartData={this.state.singleMinimartData}
          getMapData={this.getMapData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); //NOSONAR
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearDailyStatusDateAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsolidatedMapContainer);

import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";

// Component
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

//Constants
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  PROMOTION_REQUEST_TYPE_LEGEND,
  DEFAULT_VALUES,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { PROMOTION_STATUS_ANALYST } from "../MTPPromotionsApproval/Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

export default class RewardApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rewardFilter: {
        ...props.searchFilter,
      },
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.searchFilter, this.props.searchFilter)) {
      this.setState(
        {
          rewardFilter: { ...this.props.searchFilter },
        },
        this.handleOnClickSearchFilter
      );
    }
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    let dateKey = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.rewardFilter.td)) {
          DateValue = this.state.rewardFilter.fd;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        dateKey = "fd";
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.rewardFilter.fd)) {
          DateValue =
            moment(dateMoment).diff(
              this.state.rewardFilter.fd,
              "days"
            ) === 0
              ? this.state.rewardFilter.fd
              : this.state.rewardFilter.td;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        dateKey = "td";
        break;
      default:
    }
    this.setState({
      rewardFilter: {
        ...this.state.rewardFilter,
        [dateKey]: DateValue,
      },
    });
  };
  handleInputChange = (event) => {
    let value = DEFAULT_VALUES.includes(event.target.value)
      ? ""
      : event.target.value;
    this.setState({
      errorMessage: "",
      rewardFilter: {
        ...this.state.rewardFilter,
        [event.target.name]: value,
      },
    });
  };

  handleOnClickSearchFilter = (isDefaultOffset = false) => {
    isDefaultOffset &&
      this.setState({
        oft: DEFAULT_PAGINATION_ACTIVE_PAGE,
      });
    this.props.handleOnClickSearchFilter({
      ...this.state.rewardFilter,
    });
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        rewardFilter: {
          mid: "",
          mn: "",
          rpn: "",
          rpm: "",
          rs: "PENDING",
          fd: DEFAULT_DURATION.fromDate,
          td: DEFAULT_DURATION.toDate,
          oft: 1,
          lmt: DEFAULT_PAGINATION_RECORDS_COUNT,
        },
      },
      this.handleOnClickSearchFilter
    );
  };

  renderSearchFilter = () => {
    let rewardFilter = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        fromDate: this.state.rewardFilter.fd,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.rewardFilter.td,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("MatricesID"),
        handleOnChange: this.handleInputChange,
        Value: this.state.rewardFilter.mid,
        fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
        name: "mid",
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("name"),
        handleOnChange: this.handleInputChange,
        Value: this.state.rewardFilter.mn,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
        name: "mn",
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("ProductName"),
        handleOnChange: this.handleInputChange,
        Value: this.state.rewardFilter.rpn,
        fieldname: SEARCH_FILTER_FIELDS.PRODUCT_NAME,
        name: "rpn",
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("model"),
        handleOnChange: this.handleInputChange,
        fieldname: SEARCH_FILTER_FIELDS.MODEL,
        Value: this.state.rewardFilter.rpm,
        name: "rpm",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        searchPlaceHolder: Strings("status"),
        handleOnChange: this.handleInputChange,
        Value: this.state.rewardFilter.rs,
        filterDropdownList: PROMOTION_STATUS_ANALYST,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        name: "rs",
        paddingTop: 2,
      },
    ];
    this.props.isOperationsModerator &&
      rewardFilter.push({
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleInputChange,
        Value: this.state.rewardFilter.oo,
        fieldname: SEARCH_FILTER_FIELDS.OPERATION_OFFICER_NAME,
        filterDropdownList: this.props.userList,
        name: "oo",
        column: 2,
        paddingTop: 2,
      });
    return (
      <SearchFilter
        filters={rewardFilter}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={2}
        paddingTop={2}
        removeLeftPadding={true}
      />
    );
  };

  renderLegends = () => {
    return (
      <Row className='d-flex justify-content-between pt-3'>
        <CustomerTypeLegend
          arrayOfTypes={PROMOTION_REQUEST_TYPE_LEGEND}
          paddingLeft={4}
        />
      </Row>
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        rewardFilter: {
          ...this.state.rewardFilter,
          oft: page,
          lmt: pageLength,
        },
      },
      this.handleOnClickSearchFilter
    );
  };

  renderRewardCard = () => {
    return this.props.rewards.length > 0 ? (
      <Row className='cardAlign'>
        <Col md={12} className='w-100 pr-0'>
          <Row className='w-100'>
            {this.props.rewards.map((card) => {
              return (
                <CustomCard
                  card={card}
                  cardType='rewardApproval'
                  key={card.rrid}
                  handleCardClick={this.props.setRewardDetailsID}
                />
              );
            })}
          </Row>
        </Col>
        <Col md={12} className='pt-3'>
          <Pagination
            activePage={this.state.rewardFilter.oft}
            recordsPerPage={this.state.rewardFilter.lmt}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.onPageChange}
            dropup={true}
          />
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoRecordsFound")}
      </div>
    );
  };
  render() {
    return (
      <>
        <Row className='heading'>
          {Strings("rewardApprovalPageTitle")}
        </Row>
        <div className='loanList container-fluid ListContainer'>
          <div>
            {this.renderSearchFilter()}
            {this.renderLegends()}
            <Row>
              <Col md={12} className=' errorText'>
                {this.state.errorMessage}
              </Col>
            </Row>
            {this.renderRewardCard()}
          </div>
        </div>
      </>
    );
  }
}

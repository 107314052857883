// Constants
import { API } from "../../constants/APIConstants";

import { callAPI } from "../../config/AxiosTemplate";

export const getZonePositionMappingAction = (request, callback) => {
  request.oft -= 1;
  callAPI(
    "GetZonePositionMapping",
    API.getZonePositionMapping,
    "GET",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getPositionListAction = (request, callback) => {
  let requestData = {
    bid: request.branchId ? request.branchId : undefined,
    rl: request.role ? request.role : undefined,
    pstid: request.positionId ? request.positionId : undefined,
  };

  callAPI(
    "GetPositionListMapping",
    API.getCensusPositionList,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const editPositionAction = (request, callback) => {
  let requestData = {
    zn: request.zone ? request.zone : undefined,
    rl: request.role ? request.role : undefined,
    pstid: request.positionId ? request.positionId : undefined,
  };

  callAPI(
    "editPosition",
    API.setPosition,
    "PATCH",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const addPositionAction = (request, callback) => {
  let requestData = {
    rl: request.rl ? request.rl : undefined,
    pn: request.pn ? request.pn : undefined,
    br: request.br ? request.br : undefined,
    zn: request.zn ? request.zn : undefined,
    uid: request.uid ? request.uid : undefined,
  };

  callAPI(
    "addPosition",
    API.addPosition,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const addZoneAction = (request, callback) => {
  let requestData = {
    zn: request.zn ? request.zn : undefined,
    zt: request.zt ? request.zt : undefined,
    bi: request.bi ? request.bi : undefined,
    pid: request.pid ? request.pid : undefined,
  };

  callAPI(
    "addZone",
    API.addZone,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

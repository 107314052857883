//libraries
import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//components
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import WorkHistoryContainer from "../WorkHistory/WorkHistoryContainer";
import SinglePersonWorkHistoryContainer from "../SinglePersonWorkHistory/SinglePersonWorkHistoryContainer";

//actions
import { setSearchFilterAction } from "../../constants/Action";

//utilities

//constants
import { ROUTES } from "../../constants/routeConstants";
import {
  WORK_HISTORY_TABS,
  SEARCH_FILTER_PAGES,
  WORK_HISTORY_FILTER,
} from "../../constants/appConstants";

//stylesheets
import "./UserWorkHistory.scss";

class UserWorkHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: this.props.loggedInUser,
      Tabs: WORK_HISTORY_TABS[this.props.loggedInUser.role],
      activeTab: WORK_HISTORY_TABS[this.props.loggedInUser.role][0],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let derivedState = {};
    if (
      nextProps.history.location.pathname ===
        ROUTES.SALES_PERSON_WORK_HISTORY ||
      nextProps.history.location.pathname === ROUTES.USER_WORK_HISTORY
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[0],
      };
    }
    if (
      nextProps.history.location.pathname ===
        ROUTES.MESSENGER_WORK_HISTORY ||
      nextProps.history.location.pathname ===
        ROUTES.WORK_HISTORY_MESSENGER
    ) {
      derivedState = {
        ...derivedState,
        activeTab:
          prevState.Tabs.length > 1
            ? prevState.Tabs[1]
            : prevState.Tabs[0],
      };
    }
    if (
      nextProps.history.location.pathname ===
        ROUTES.COLLECTIONS_OFFICER_WORK_HISTORY ||
      nextProps.history.location.pathname ===
        ROUTES.WORK_HISTORY_COLLECTIONS_OFFICER
    ) {
      derivedState = {
        ...derivedState,
        activeTab:
          prevState.Tabs.length > 1
            ? prevState.Tabs[2]
            : prevState.Tabs[0],
      };
    }
    return derivedState;
  }

  handleTabs = (index) => {
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.WORK_HISTORY,
      {
        ...WORK_HISTORY_FILTER,
      }
    );
    this.setState({ activeTab: this.state.Tabs[index] }, () => {
      this.props.history.push(
        ROUTES[`WORK_HISTORY_${this.state.Tabs[index].value}`]
      );
    });
  };

  callbackOnRowClick = (row) => {
    if (row !== undefined) {
      this.props.history.push(
        ROUTES[`${this.state.activeTab.value}_WORK_HISTORY`]
      );
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  renderTabPanel = () => {
    return (
      <TabPanel className='WorkHistoryContainer'>
        <Switch>
          <Route
            exact
            path={[
              ROUTES.USER_WORK_HISTORY,
              ROUTES[`WORK_HISTORY_${this.state.activeTab.value}`],
            ]}
            render={() => (
              <WorkHistoryContainer
                user={this.state.activeTab.value}
                callbackOnRowClick={this.callbackOnRowClick}
              />
            )}
          />
          <Route
            exact
            path={
              ROUTES[`${this.state.activeTab.value}_WORK_HISTORY`]
            }
            render={() => (
              <SinglePersonWorkHistoryContainer
                user={this.state.activeTab.value}
                goBack={this.goBack}
              />
            )}
          />
        </Switch>
      </TabPanel>
    );
  };

  render() {
    return (
      <div className='userWorkHistory'>
        <Tabs
          selectedIndex={this.state.activeTab.key}
          onSelect={this.handleTabs}
        >
          <TabList>
            {this.state.Tabs.map((item) => (
              <Tab key={item.key}>{item.label} </Tab>
            ))}
          </TabList>
          {this.state.Tabs.map((item) => (
            <React.Fragment key={item.key}>
              {this.renderTabPanel()}
            </React.Fragment>
          ))}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); //NOSONAR

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserWorkHistory));

//libraries
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";

// Components
import UserList from "./UserList";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import withHOC from "../../HOC/HOC";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import {
  USER_ROLES_WITH_RATINGS,
  USER_ROLES_TO_SHOW_BRANCH,
  USER_ROLES,
  MINIMART_RATING,
  SPECIAL,
  SPECIAL_MESSENGER_PRESENTATION,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  SEARCH_FILTER_PAGES,
  USER_LIST_FILTER,
} from "../../constants/appConstants";

// Actions
import {
  getUserDetailsAction,
  setUserDetailsAction,
  clearUserDetailsAction,
  getUserRolesAction,
  setIsAddUSerFlagAction,
  setUserAction,
} from "./UserListAction";
import { getUserProfilePicUrlAction } from "../ProfileDetails/ProfileDetailsActions";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import { setSearchFilterAction } from "../../constants/Action";

// Utils
import Utility from "../../utils/Utility";

// Styles
import "./UserList.scss";

//Assets
import ProfileImage from "../../resources/images/ic_header_default_user.svg";

class UserListContainer extends React.Component {
  constructor(props) {
    super(props);
    //Columns are created with specific names and key
    this._columns = [
      {
        key: "image",
        name: Strings("Image"),
        cellClass: "myCustomCellClass",
        minWidth: 50,
      },
      {
        key: "user",
        name: Strings("User"),
        minWidth: 80,
      },
      {
        key: "lastName",
        name: Strings("Name"),
        minWidth: 150,
      },
      {
        key: "role",
        name: Strings("Role"),
        minWidth: 150,
      },
      {
        key: "branch",
        name: Strings("Branch"),
        minWidth: 100,
      },
      {
        key: "zone",
        name: Strings("Zones"),
        minWidth: 100,
      },

      {
        key: "rating",
        name: Strings("Rating"),
        minWidth: 100,
      },
    ];
    //Initially no rows are presents
    this.state = {
      rows: [],
      usersProfilePic: [],
      usersResponseData: null,
      showLoader: false,
      errorMessage: props.errorMessage,
      userRoles: props.userRoles,
      branches: props.branches,
      zones: [],
      allZones: [],
    };
    this.isCardClick = false;
  }

  UNSAFE_componentWillMount() {
    this.getUserRoles();
  }

  componentDidMount() {
    this.props.clearDailyStatusDateAction();
    this.props.getBranchList();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }
  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.USER_LIST,
        { ...USER_LIST_FILTER }
      );
    }
  }

  /**
   * This function will call list API.
   */

  getBranchStringReprentation = (branches) => {
    let branchString = "";
    branches.forEach((branch, index) => {
      if (index < branches.length - 1) {
        branchString = branchString + branch + ", ";
      } else {
        branchString = branchString + branch;
      }
    });
    return branchString;
  };

  getUserList = (searchFilterObject) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        un: searchFilterObject.searchUsername,
        rl: searchFilterObject.filterRole,
        zn: searchFilterObject.zone,
        mzn: searchFilterObject.mZone,
        br: searchFilterObject.branchId,
        active: searchFilterObject.active,
        available: searchFilterObject.available,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    const stateObject = Utility.getUserList(
      searchFilterObject,
      this.props.setUserAction,
      this.getAllUserListProfilePic
    );
    if (!_.isEmpty(stateObject)) {
      this.setState(stateObject);
    }
  };

  //Get all users profile pics
  getAllUserListProfilePic = (usersResponseData) => {
    if (
      usersResponseData.users &&
      usersResponseData.users.length > 0
    ) {
      let profilePic = [];
      this.setState({
        numberOfPages: usersResponseData.numberOfPages,
      });
      usersResponseData.users.map((users) => {
        this.props.getUserProfilePicUrlAction(
          users.username,
          (response) => {
            if (
              response.status === OPERATION_STATUS.SUCCESS &&
              response.data.data.s3Url
            ) {
              profilePic.push({
                username: users.username,
                image: Utility.getDecryptedData(
                  response.data.data.s3Url
                ),
              });
            } else {
              profilePic.push({
                username: users.username,
                image: ProfileImage,
              });
            }
            this.setState(
              {
                usersProfilePic: profilePic,
              },
              this.callCreateUsers(usersResponseData.users)
            );
          }
        );
        return null;
      });
    } else {
      this.setState({ rows: [] });
    }
  };

  callCreateUsers = (usersResponseData) => {
    this.setState({ usersResponseData: usersResponseData }, () => {
      this.createRows(usersResponseData);
    });
  };

  getProfilePic = (username) => {
    let image = "";
    this.state.usersProfilePic &&
      this.state.usersProfilePic.map((users) => {
        if (users.username === username) {
          image = users.image;
        }
        return null;
      });
    return image;
  };

  handleType = (userRole) => {
    return Strings(userRole);
  };

  createRows = (responseData) => {
    //Row create for each element in response data array
    let rows = [],
      userData = responseData;
    _.forEach(userData, (userDetails) => {
      let userImage =
        this.getProfilePic(userDetails.username) || ProfileImage;
      let userRole = this.handleType(userDetails.role.role);
      const raterTitle =
        userDetails.role.role === USER_ROLES.salesPerson
          ? Strings("userRating") +
          " : " +
          userDetails.rating +
          "\n" +
          Strings("minimartUserRating") +
          " : " +
          MINIMART_RATING[userDetails.mRat]
          : Strings("userRating") + " : " + userDetails.rating;
      rows.push({
        image: (
          <img
            className='userAvatar'
            src={userImage}
            alt=''
            onError={(error) => {
              error.target.onError = null;
              error.target.src = ProfileImage;
            }}
            title={userDetails.username}
          />
        ),
        user: userDetails.username,
        firstName: userDetails.firstName,
        lastName:
          userDetails.type && userDetails.type === SPECIAL ? (
            <div
              title={
                userDetails.firstName +
                " " +
                userDetails.lastName +
                "\n" +
                SPECIAL_MESSENGER_PRESENTATION
              }
            >
              {SHORT_SPECIAL_MESSENGER_PRESENTATION +
                " " +
                userDetails.firstName +
                " " +
                userDetails.lastName}
            </div>
          ) : (
            userDetails.firstName + " " + userDetails.lastName
          ),
        role: userRole,
        branch: USER_ROLES_TO_SHOW_BRANCH.includes(
          userDetails.role.role
        ) ? (
          this.getBranchStringReprentation(userDetails.branch)
        ) : (
          <div title={Strings("NoBranch")}>
            &nbsp;&nbsp;&nbsp;&nbsp;{Strings("NA")}
          </div>
        ),
        zone:
          userDetails.zones && userDetails.zones.length > 0 ? (
            this.getBranchStringReprentation(userDetails.zones)
          ) : (
            <div title={Strings("NoZones")}>{Strings("NA")}</div>
          ),
        rating: USER_ROLES_WITH_RATINGS.includes(
          userDetails.role.role
        ) ? (
          <Rater
            id='rating'
            className='alignCenter'
            title={raterTitle}
            rating={userDetails.rating}
            total={5}
            interactive={false}
            style={{ fontSize: 20 + "px" }}
          />
        ) : (
          <div className='alignCenter' title={Strings("NoRating")}>
            {Strings("NA")}
          </div>
        ),
        userPreservedData: userDetails,
      });
    });
    this.setState({ rows });
  };

  getUserPreservedData = (userDetails) => {
    delete userDetails.roleDto;
    if (typeof userDetails.branch[0] === typeof "") {
      let branchname = userDetails.branch[0];
      let indexedbranch = this.props.branches.findIndex((branch) => {
        return branch.name === branchname;
      });
      let tempDetails = userDetails;
      if (
        typeof tempDetails.branch[0] === typeof "" &&
        tempDetails.branch[0].length > 1
      ) {
        tempDetails.branch.pop();
      }
      tempDetails.branch.push(
        this.props.branches && this.props.branches[indexedbranch].id
      );
      Object.assign(userDetails, tempDetails);
      return userDetails;
    } else {
      return userDetails;
    }
  };
  getSelectedUserDetails = (row) => {
    if (row !== undefined) {
      this.isCardClick = true;
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.USER_LIST,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.USER_LIST
      );

      let userDetails = USER_ROLES_TO_SHOW_BRANCH.includes(
        row.userPreservedData.role.role
      )
        ? this.getUserPreservedData(row.userPreservedData)
        : row.userPreservedData;
      this.props.setIsAddUSerFlagAction({ isAddUser: false });
      this.props.setUserDetailsAction(userDetails, () => {
        this.displayUserProfile();
      });
    }
  };

  displayUserProfile = () => {
    this.props.history.push({
      pathname: ROUTES.USER_PROFILE,
      state: { isDisplayUser: true },
    });
  };

  /**
   * This function will be called when user clicks on serach/filter/reset
   * if search and filter is empty, it will call list API else search filter API
   */

  handleOnClickSearchFilter = (
    searchFilterObject
  ) => {
    this.getUserList(searchFilterObject);
  };

  handleAddUser = () => {
    this.props.clearUserDetailsAction();
    this.props.setIsAddUSerFlagAction({ isAddUser: true });
    this.props.history.push({
      pathname: ROUTES.USER_PROFILE,
      state: { isAddUser: true },
    });
  };

  getUserRoles = (isAddUser = false) => {
    this.setState({ showLoader: true });
    this.props.getUserRolesAction(isAddUser, (response) => {
      this.setState({ showLoader: false });
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          userRoles: response.data.data.roles,
        });
      }
    });
  };

  render() {
    return (
      <div>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.userRoles && (
          <UserList
            columns={this._columns}
            rowsCount={this.state.rows.length}
            onRowClick={this.getSelectedUserDetails}
            rowHeight={60}
            headerRowHeight={40}
            userRoles={this.state.userRoles}
            branches={this.props.branches}
            errorMessage={this.state.errorMessage}
            handleAddUser={this.handleAddUser}
            handleOnClickSearchFilter={this.handleOnClickSearchFilter}
            getUserRoles={this.getUserRoles}
            numberOfPages={this.state.numberOfPages}
            getUserList={this.getUserList}
            getZonesByBranch={this.props.getZonesByBranch}
            zones={this.props.zones}
            mZones={this.props.mZones}
            searchFilter={this.props.userListSearchFilter}
            rows={this.state.rows}
            loggedInUser={this.props.loggedInUser}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userRoles: state.userListReducer.userRoles,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserProfilePicUrlAction,
      getUserDetailsAction,
      setUserDetailsAction,
      clearUserDetailsAction,
      getUserRolesAction,
      setIsAddUSerFlagAction,
      clearDailyStatusDateAction,
      setUserAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

const UserListWrapper = withHOC(UserListContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListWrapper);

//Libraries
import moment from "moment";
// Constants
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
} from "../../constants/appConstants";
import {
  SET_SALESPERSON_EFFICIENCY_DATA,
  SET_SALESPERSON_SEARCHFILTER_DATA,
  CLEAR_EFFICIENCY_ACTION,
} from "../../redux/constants";

const INITIAL_STATE = {
  searchFilter: {
    fd: DEFAULT_DURATION.fromDate,
    td: moment(new Date()).format(DEFAULT_DATE_FORMAT),
    brn: "",
    sn: "",
    gr: "",
  },
  selectedSalespersonData: {
    brn: "",
    zn: "",
    fn: "",
    ln: "",
    glm: 0,
    glc: 0,
    mRat: 0,
    crat: 0,
    ple: 0,
    rat: 0,
    tam: 0,
    uid: 0,
    un: "",
    role: "",
    vm: 0,
    amtd: 0,
    pa: 0,
  },
};

export default function EfficiencyDashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SALESPERSON_EFFICIENCY_DATA:
      return {
        ...state,
        selectedSalespersonData: action.data,
      };
    case SET_SALESPERSON_SEARCHFILTER_DATA:
      return {
        ...state,
        searchFilter: action.data,
      };
    case CLEAR_EFFICIENCY_ACTION:
      return {
        ...state,
        selectedSalespersonData:
          INITIAL_STATE.selectedSalespersonData,
        searchFilter: INITIAL_STATE.searchFilter,
      };
    default:
      return state;
  }
}

import {
  SET_SEARCH_FILTER_ACTION,
  CLEAR_SEARCH_FILTER_ACTION,
  SET_ROW_CLICK,
} from "../redux/constants";
import {
  LOAN_APPROVAL_FILTER,
  OPERATIONS_LOAN_APPROVAL_FILTER,
  MINIMART_LIST_FILTER,
  APPROVAL_DASHBOARD_FILTER,
  SPED_L1_FILTER,
  SPED_L2_FILTER,
  LOAN_PORTFOLIO_FILTER,
  COMMISSION_FILTER,
  COMMISSION_REPORT_FILTER,
  USER_LIST_FILTER,
  BRANCH_LIST_FILTER,
  LOAN_HISTORY_FILTER,
  CI_INTERFACE_FILTER,
  CI_LOAN_REQUEST_REPORT_FILTER,
  FLR_SEARCH_FILTER,
  SLR_SEARCH_FILTER,
  DAILY_STATUS_FILTER,
  PERSON_DAILY_STATUS_FILTER,
  COLLECTION_VERIFICATION_FILTER,
  WORK_HISTORY_FILTER,
  CONFIGURATION_FILTER,
  LOAN_DETAIL_FILTER,
  MINIMART_HISTORY_FILTER,
  NEW_MINIMART_LIST_FILTER,
  WORKLOAD_MANAGEMENT_FILTER,
  SIGNUP_REQUEST_FILTER,
  ANALYST_LOAN_DETAIL_FILTER,
  PROMOTION_APPROVAL_FILTER,
  PROMOTION_LOAN_DETAIL_FILTER,
  REWARD_APPROVAL_FILTER,
  CI_MINIMART_FILTER,
  CLIENT_PORTAL_FILTER,
} from "../constants/appConstants";

const INITIAL_STATE = {
  loanApprovalSearchFilter: {
    ...LOAN_APPROVAL_FILTER,
  },
  operationsLoanApprovalSearchFilter: {
    ...OPERATIONS_LOAN_APPROVAL_FILTER,
  },
  minimartListSearchFilter: {
    ...MINIMART_LIST_FILTER,
  },
  approvalDashboardSearchFilter: {
    ...APPROVAL_DASHBOARD_FILTER,
  },
  spedL1SearchFilter: {
    ...SPED_L1_FILTER,
  },
  spedL2SearchFilter: {
    ...SPED_L2_FILTER,
  },
  loanPortfolioSearchFilter: {
    ...LOAN_PORTFOLIO_FILTER,
  },
  loanHistorySearchFilter: {
    ...LOAN_HISTORY_FILTER,
  },
  userListSearchFilter: {
    ...USER_LIST_FILTER,
  },
  branchListSearchFilter: { ...BRANCH_LIST_FILTER },
  commissionSearchFilter: { ...COMMISSION_FILTER },
  ciSearchFilter: { ...CI_INTERFACE_FILTER },
  ciLoanRequestReportFilter: { ...CI_LOAN_REQUEST_REPORT_FILTER },
  commissionReportSearchFilter: { ...COMMISSION_REPORT_FILTER },
  flrSearchFilter: { ...FLR_SEARCH_FILTER },
  slrSearchFilter: { ...SLR_SEARCH_FILTER },
  dailyStatusSearchFilter: { ...DAILY_STATUS_FILTER },
  personDailyStatusSearchFilter: { ...PERSON_DAILY_STATUS_FILTER },
  collectionVerificationSearchFilter: {
    ...COLLECTION_VERIFICATION_FILTER,
  },
  workHistorySearchFilter: { ...WORK_HISTORY_FILTER },
  configurationSearchFilter: { ...CONFIGURATION_FILTER },
  loanDetailFilter: { ...LOAN_DETAIL_FILTER },
  minimartHistorySearchFilter: { ...MINIMART_HISTORY_FILTER },
  newMinimartListSearchFilter: { ...NEW_MINIMART_LIST_FILTER },
  workloadManagementSearchFilter: { ...WORKLOAD_MANAGEMENT_FILTER },
  signupRequestSearchFilter: { ...SIGNUP_REQUEST_FILTER },
  analystLoanDetailFilter: { ...ANALYST_LOAN_DETAIL_FILTER },
  promotionApprovalFilter: { ...PROMOTION_APPROVAL_FILTER },
  promotionLoanDetailFilter: { ...PROMOTION_LOAN_DETAIL_FILTER },
  rewardApprovalFilter: { ...REWARD_APPROVAL_FILTER },
  ciMinimartSearchFilter: { ...CI_MINIMART_FILTER },
  clientPortalSearchFilter: { ...CLIENT_PORTAL_FILTER },
  searchPage: "",
  loan: null,
};

export default function SearchFilterReducer(state = INITIAL_STATE, action) {
  // NOSONAR
  switch (action.type) {
    case SET_SEARCH_FILTER_ACTION.LOAN_APPROVAL:
      return {
        ...state,
        loanApprovalSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.OPERATIONS_LOAN_APPROVAL:
      return {
        ...state,
        operationsLoanApprovalSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.MINIMART_LIST:
      return {
        ...state,
        minimartListSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.APPROVAL_DASHBOARD:
      return {
        ...state,
        approvalDashboardSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.SPED_L1:
      return {
        ...state,
        spedL1SearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.SPED_L2:
      return {
        ...state,
        spedL2SearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.LOAN_HISTORY:
      return {
        ...state,
        loanHistorySearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.LOAN_PORTFOLIO:
      return {
        ...state,
        loanPortfolioSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.COMMISSION:
      return {
        ...state,
        commissionSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.COMMISSION_REPORT:
      return {
        ...state,
        commissionReportSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.USER_LIST:
      return {
        ...state,
        userListSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.BRANCH_LIST:
      return {
        ...state,
        branchListSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.CI_INTERFACE:
      return {
        ...state,
        ciSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.CI_LOAN_REQUEST_REPORT:
      return {
        ...state,
        ciLoanRequestReportFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.FIRST_LEVEL_REPORT:
      return {
        ...state,
        flrSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.SECOND_LEVEL_REPORT:
      return {
        ...state,
        slrSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.DAILY_STATUS:
      return {
        ...state,
        dailyStatusSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.PERSON_DAILY_STATUS:
      return {
        ...state,
        personDailyStatusSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.COLLECTION_VERIFICATION:
      return {
        ...state,
        collectionVerificationSearchFilter: action.searchFilter,
      };
    case SET_SEARCH_FILTER_ACTION.WORK_HISTORY:
      return {
        ...state,
        workHistorySearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.CONFIGURATION:
      return {
        ...state,
        configurationSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.LOAN_DETAIL:
      return {
        ...state,
        loanDetailFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.MINIMART_HISTORY:
      return {
        ...state,
        minimartHistorySearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };

    case SET_ROW_CLICK:
      return {
        ...state,
        loan: action.loan,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.NEW_MINIMART_LIST:
      return {
        ...state,
        newMinimartListSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };

    case SET_SEARCH_FILTER_ACTION.WORKLOAD_MANAGEMENT:
      return {
        ...state,
        workloadManagementSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.ANALYST_LOAN_DETAIL:
      return {
        ...state,
        analystLoanDetailFilter: action.searchFilter,
        searchPage: action.searchPage,
      };

    case SET_SEARCH_FILTER_ACTION.SIGNUP_REQUEST:
      return {
        ...state,
        signupRequestSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };

    case SET_SEARCH_FILTER_ACTION.PROMOTION_APPROVAL:
      return {
        ...state,
        promotionApprovalFilter: action.searchFilter,
        searchPage: action.searchPage,
      };

    case SET_SEARCH_FILTER_ACTION.PROMOTION_LOAN_DETAIL:
      return {
        ...state,
        promotionLoanDetailFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.REWARD_APPROVAL:
      return {
        ...state,
        rewardApprovalFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.CI_MINIMART:
      return {
        ...state,
        ciMinimartSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case SET_SEARCH_FILTER_ACTION.CLIENT_PORTAL:
      return {
        ...state,
        clientPortalSearchFilter: action.searchFilter,
        searchPage: action.searchPage,
      };
    case CLEAR_SEARCH_FILTER_ACTION:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export const REACT_APP_BASE_API_URL = process.env.REACT_APP_API_URL;
export const COMMON_API_URL = REACT_APP_BASE_API_URL;
export const TPRESTA_API_URL = COMMON_API_URL + "/tpresta-app";

export const API = {
  /* #region Authentication */
  login: COMMON_API_URL + "/login",
  logout: COMMON_API_URL + "/logout",
  forgotPassword: COMMON_API_URL + "/user/forgot-password",
  changePassword: COMMON_API_URL + "/user/change-password",
  /* #endregion */

  /* #region User Management */
  getUserList: COMMON_API_URL + "/user/list",
  getUsersBySearchFilter: COMMON_API_URL + "/user/search-filter",
  createUser: COMMON_API_URL + "/user/createV2",
  deactivateUser: COMMON_API_URL + "/user/delete/",
  getUserRoles: COMMON_API_URL + "/user/roles",
  getZonePositionMapping: COMMON_API_URL + "/zone/position",
  getPositionListMapping: COMMON_API_URL + "/position/role-position",
  setPosition: COMMON_API_URL + "/zone/position",
  addPosition: COMMON_API_URL + "/position/positionV2",
  addZone: COMMON_API_URL + "/zone/new-zone",
  deactivationAndChangeRoleStatus: COMMON_API_URL + "/user/deactivate/change-role?",
  availabilityStatus: COMMON_API_URL + "/user/unavailable",
  sameRoleUserList: COMMON_API_URL + "/user/users/list",
  workloadDistribution: COMMON_API_URL + "/user/redistribute-workload",
  changeAvailabilityStatus: COMMON_API_URL + "/user/mark-availability",
  changeRole: COMMON_API_URL + "/user/change-role",
  /* #endregion */

  /* #region Profile  */
  getUserProfile: COMMON_API_URL + "/user/get-profile/username",
  getProfilePicUrl: COMMON_API_URL + "/user/profile-pic/username",
  updateUserProfile: COMMON_API_URL + "/user/update/username",
  uploadProfilePic: COMMON_API_URL + "/user/profile-pic",
  getProfilePic: COMMON_API_URL + "/user/profile-pic/",
  getReportingUsers: COMMON_API_URL + "/user/get-reporting-to/role",
  getPositions: COMMON_API_URL + "/user/position",
  /* #endregion */

  /* #region Branch Management  */
  getBranchList: COMMON_API_URL + "/branch/list",
  getBranch: COMMON_API_URL + "/branch/id",
  getBranchBySearchFilter: COMMON_API_URL + "/branch/search-filter",
  createBranch: COMMON_API_URL + "/branch",
  updateBranch: COMMON_API_URL + "/branch/id",
  updateBranchStatus: COMMON_API_URL + "/branch",
  // getAllZones: COMMON_API_URL + "/zone",
  /* #endregion */

  /* #region CI_INTERFACE  */
  getMinimartData: COMMON_API_URL + "/minimart/search-filter",
  uploadCSV: COMMON_API_URL + "/ci-interface/upload",
  getAllMiniMarts: COMMON_API_URL + "/minimart/list",
  getCIApprovalMinimarts:
    COMMON_API_URL + "/operations/minimart/search-filter",
  getAllPreviousFiles: COMMON_API_URL + "/ci-interface/search-filter",
  getInvoicesFromFilesBySearchFilter:
    COMMON_API_URL + "/invoice/search-filter/file",
  deleteFiles: COMMON_API_URL + "/ci-interface/delete",
  pollFileProcessingStatus:
    COMMON_API_URL + "/ci-interface/schedule/status",
  /* #endregion */

  /* #region Dashboard  */
  getAllZones: COMMON_API_URL + "/zone/",
  getSelectedBranchZones: COMMON_API_URL + "/zone/branch",
  getminiMartAndUserChartData:
    COMMON_API_URL + "/dashboard/minimart-stats/?branchId=",
  getInvoiceChartData: COMMON_API_URL + "/dashboard/invoice-stats",
  getLoanChartData: COMMON_API_URL + "/dashboard/loan-status-stats",
  getLoanDataByCategory:
    COMMON_API_URL + "/dashboard/loan-category-stats",
  getProvisionData: COMMON_API_URL + "/dashboard/provision-graph",
  getAmountData:
    COMMON_API_URL + "/dashboard/disbursement-collection-stats",
  schedular: COMMON_API_URL + "/ci-interface/schedulenow",
  /* #endregion */

  /* #region Loan */
  getLoanDetailsById:
    COMMON_API_URL + "/loan/detailsv2?applicationId=",
  getAmortizationsTable: COMMON_API_URL + "/loan/amortizations",
  getLoanApplicationDetails: COMMON_API_URL + "/loan/get/",
  viewDocument: COMMON_API_URL + "/file/view?documentId=",
  requestForTakeOffLateFees:
    COMMON_API_URL + "/loan/negotiation/takeoff-latefees",
  requestForTakeOffLateFeesApply:
    COMMON_API_URL + "/loan/negotiation/takeoff-latefees/apply",
  requestForAssignNewPlanApply:
    COMMON_API_URL + "/loan/negotiation/new-plan/apply",
  getNegotiationRequest: COMMON_API_URL + "/loan/negotiation/",
  approveOrRejectRequestForTakeOffLateFees:
    COMMON_API_URL + "/loan/negotiation/takeoff-latefees/approve",
  requestForAssignRepayment:
    COMMON_API_URL + "/loan/negotiation/new-plan",
  approveOrRejectRequestForAssignRepayment:
    COMMON_API_URL + "/loan/negotiation/new-plan/approve",
  /* #endregion */

  /* #region MiniMart Management  */
  getInvoicesBySearchFilter:
    COMMON_API_URL + "/invoice/search-filter",
  getLoansBySearchFilter: COMMON_API_URL + "/loan/search-filterV2",
  getSingleMinimartData: COMMON_API_URL + "/minimart/matricesId",
  /* #endregion */

  /* #region SalesPerson  */
  getPiechartDataForAllSalesPersons:
    COMMON_API_URL + "/dailyStatus/getAssignedMinimartStats",
  getPiechartDataForSingleSalesPerson:
    COMMON_API_URL +
    "/dailyStatus/getAssignedMinimartStats?salesPersonId=",
  getAssignedSalesPersonList:
    COMMON_API_URL +
    "/dailyStatus/getAssignedSalesPersons/salesPersons",
  getSalesPersonDailyAsignment:
    COMMON_API_URL +
    "/salesPerson/minimart/getAssignments?salesPersonId",
  /* #endregion */

  /* #region Messenger  */
  getPiechartDataForAllMessengers:
    COMMON_API_URL +
    "/dailyStatus/getAssignedMessengersMinimartStats",
  getPiechartDataForSingleMessenger:
    COMMON_API_URL +
    "/dailyStatus/getAssignedMessengersMinimartStats?messengerId=",
  getAssignedMessengersList:
    COMMON_API_URL + "/dailyStatus/getAssignedMessengers?",
  getMessengersDailyAssignment:
    COMMON_API_URL + "/messenger/getAssignedMinimarts?messengerId=",
  /* #endregion */

  /* #region Configuration  */

  getConfigurationBySearchFilter:
    COMMON_API_URL + "/configuration/search-filter",
  getConfigurationById: COMMON_API_URL + "/configuration/",
  getActiveConfiguration:
    COMMON_API_URL + "/configuration/active?type",
  CreateConfiguration: COMMON_API_URL + "/configuration/create",
  UpdateConfiguration: COMMON_API_URL + "/configuration/update",
  getDraftedConfiguration:
    COMMON_API_URL + "/configuration/drafted?type",
  activateConfiguration: COMMON_API_URL + "/configuration/activate",
  deleteConfiguration: COMMON_API_URL + "/configuration/delete?type",

  /* #endregion */

  /* #region  Workload Management */

  getAssignedMinimarts:
    COMMON_API_URL + "/workload/getAssignedMinimarts",
  getSalesUsersForWorkload:
    COMMON_API_URL + "/workload/sales?branchId=",
  getMessengerUsersForWorkload:
    COMMON_API_URL + "/workload/messenger?branchId=",
  getCollectionsUsersForWorkload:
    COMMON_API_URL + "/workload/collections?branchId=",
  changeAssignedMinimarts: COMMON_API_URL + "/workload/assign",
  /* #endregion */

  /* #region Work-History  */

  getUserHistory: COMMON_API_URL + "/workHistory",

  /* #endregion */

  /* #region  Consolidated Map */

  getLocationData: COMMON_API_URL + "/minimart/location",
  getMapData: COMMON_API_URL + "/minimart/map-data/",

  // Accounting Officer Verify Collection
  getMessegerDepositReceipt: COMMON_API_URL + "/deposit/<id>/receipt",
  getMessengerDetailsForDeposit: COMMON_API_URL + "/deposit/",
  verifyDeposit: COMMON_API_URL + "/deposit/<id>/verify",

  //Reports
  getReports: COMMON_API_URL + "/reports",
  /* #endregion */

  /* #region  Approval Dashboard */
  getLoansBySearchFilterForLateFees:
    COMMON_API_URL +
    "/loan/negotiation/takeoff-latefees/search-filter",
  getLoansBySearchFilterForAssignRepayment:
    COMMON_API_URL + "/loan/negotiation/new-plan/search-filter",
  /* #endregion */
  getCommissionSchemeByFilter:
    COMMON_API_URL + "/commission/search-filter",
  payCommissionAmount: COMMON_API_URL + "/commission/pay",

  //Higher credit limit
  getHigherCreditLimitRequests:
    COMMON_API_URL +
    "/minimart/negotiation/credit-limit/update/search-filter",
  getPerticularCreditLimitRequest:
    COMMON_API_URL +
    "/minimart/negotiation/credit-limit/update/<ticketId>",
  creditLimitRequestAction:
    COMMON_API_URL +
    "/minimart/negotiation/credit-limit/update/approve",
  getCreditAgreementList:
    COMMON_API_URL + "/credit-agreement/minimart/",
  downloadCreditAgreement:
    COMMON_API_URL + "/credit-agreement/<documentId>/download",

  //analyst
  getAnalystsLoansBySearchfilter:
    COMMON_API_URL + "/analysis/loan/search-filter",
  //credit score mappings
  getCreditScoreMappings:
    COMMON_API_URL + "/configuration/credit-score/mappings",

  //analyst get loan details
  getAnalystloanDetails: COMMON_API_URL + "/analysis/loan/",
  getCreditScoreForAnalyst:
    COMMON_API_URL + "/analysis/credit-score/<minimartId>",
  getCredoCreditScore:
    COMMON_API_URL + "/credolab/credit-score/loanId",
  updateLoanApplicationStatusForAnalyst:
    COMMON_API_URL + "/analysis/update/status",
  calculateCreditScore: COMMON_API_URL + "/analysis/loan",
  addNote: COMMON_API_URL + "/notes",
  deleteNote: COMMON_API_URL + "/notes/<noteId>",
  getNoteWithId: COMMON_API_URL + "/notes/<id>",
  updateNote: COMMON_API_URL + "/notes/<id>",
  getAnalystDraftedInput:
    COMMON_API_URL + "/analysis/loan?applicationId=",
  getDraftedNegotiation:
    COMMON_API_URL + "/analysis/loan/negotiation?applicationId=",
  getEmiDuration:
    COMMON_API_URL + "/analysis/emi-calculator?minimartId=",
  updateLoanNegotiationAndTerms:
    COMMON_API_URL + "/analysis/loan/negotiation",
  getDropdownValuesForLoanApplicationForm: COMMON_API_URL + "/salesPerson/minimart/configuration?id=",
  updateLoanApplication: COMMON_API_URL + "/loan/update",
  getSupplierConfigurationValues: COMMON_API_URL + "/supplier/form/configuration",
  getLoanDetailsForConciliation: COMMON_API_URL + "/analysis/conciliation?mId=",

  //OPerations_Officer
  getOperationsOfficersLoanSearchFilter:
    COMMON_API_URL + "/operations/loan/search-filter",
  getLoanDetailsForOperationOfficer:
    COMMON_API_URL + "/operations/loan/",
  updateOperationsOfficerInputs: COMMON_API_URL + "/operations/loan",
  updateLoanStatusForOperationsOfficer:
    COMMON_API_URL + "/operations/update/status",
  getDraftedInputsForOperationsOfficer:
    COMMON_API_URL + "/operations/loan?applicationId=",
  updateOfficerInput: COMMON_API_URL + "/operations/order-info",
  uploadTransferReciept: COMMON_API_URL + "/supplier/upload",


  //Accounting Officer
  getLoanDetailsForAccountingOfficer:
    COMMON_API_URL + "/accounting/loan/",
  updateLoanApplicationStatusForAccountsOfficer:
    COMMON_API_URL + "/accounting/update/status",
  updateAccountingOfficerInput: COMMON_API_URL + "/accounting/loan",
  getAccountingDraftedInputs:
    COMMON_API_URL + "/accounting/loan?applicationId=",
  updateLoanStatusForDisburment:
    COMMON_API_URL + "/loan/update/status",
  getAccountingOfficersLoanSearchFilter:
    COMMON_API_URL + "/accounting/loan/search-filter",
  getOperationsLoanHistory:
    COMMON_API_URL + "/operations/loan-history",
  getAnalystLoanHistory: COMMON_API_URL + "/analysis/loan-history",
  getAccountingOfficerHistory:
    COMMON_API_URL + "/accounting/loan-history",
  // ci wire transfer
  getAllCITransferRecords:
    COMMON_API_URL + "/ci-wire-transfer/search-filter",

  markInvoicesAsPaid: COMMON_API_URL + "/ci-wire-transfer",
  getTotalPaidAndUnpaidAmounts:
    COMMON_API_URL + "/ci-wire-transfer/amount-stats?fd=",
  generateReport: COMMON_API_URL + "/ci-wire-transfer/download",

  //Product Catalog
  getProductCatalog: COMMON_API_URL + "/catalog/search-filter",
  getAllCategories: COMMON_API_URL + "/catalog/category/list",
  addProductCategory: COMMON_API_URL + "/catalog/category",
  addProduct: COMMON_API_URL + "/catalog/product",
  updateProductCategory: COMMON_API_URL + "/catalog/category/",
  updateProduct: COMMON_API_URL + "/catalog/product/",
  getProductById: COMMON_API_URL + "/catalog/product/",
  getCategoryById: COMMON_API_URL + "/catalog/category/",

  //Commission Report
  getSecondLevelCommissionHeader:
    COMMON_API_URL + "/commission/daily",
  getSecondLevelCommissionReport:
    COMMON_API_URL + "/commission/daily/search-filter",
  getThirdLevelCommissionHeader:
    COMMON_API_URL + "/commission/detailed",
  getThirdLevelCommissionReport:
    COMMON_API_URL + "/commission/detailed/search-filter",

  // payment receipts
  getPaymentReceipts:
    COMMON_API_URL + "/receipt/collection/search-filter",
  generatePaymentReceipt: COMMON_API_URL + "/messenger/receipt/",
  getDenialReasons:
    COMMON_API_URL + "/loan/payment-denial-reasons?lid=",

  //denial reasons on dashboard
  getDenialReasonsData:
    COMMON_API_URL + "/dashboard/denial-reasons-graph",
  //download apstra report
  exportNotes: COMMON_API_URL + "/apstra/download",
  getApprovalNotes: COMMON_API_URL + "/notes/approval/",
  getDepositedMinimartListForVerification:
    COMMON_API_URL + "/deposit",
  sendUpdatedMinimartDetails: COMMON_API_URL + "/minimart",
  changeCollectionDays: COMMON_API_URL + "/loan/collection-cycle",
  getPossibleCollectionCycle: COMMON_API_URL + "/loan/frequency/collection-cycle?",

  getLoanAmountPaidDivision: COMMON_API_URL + "/loan/payment-splitup",
  closeLoanApplication: COMMON_API_URL + "/loan/cancel",

  deleteEvidenceDocument: COMMON_API_URL + "/file/delete",

  //download minimart list report
  downloadMinimartListReport:
    COMMON_API_URL + "/reports/minimart/download?fileType=",

  //minimart approval panel
  getMinimartApprovalRequests:
    COMMON_API_URL + "/analysis/minimart/search-filter",
  getMinimartApprovalReminders:
    COMMON_API_URL + "/analysis/reminder/minimart/list",

  //Minimart History
  getMinimartHistoryData:
    COMMON_API_URL + "/analysis/minimart/search-filter",
  getApproveMinimartDetails: COMMON_API_URL + "/minimart",
  getApproveMinimartNotes: COMMON_API_URL + "/analysis/minimart",
  updateMinimartDetails: COMMON_API_URL + "/minimart",
  approveMinimart: COMMON_API_URL + "/analysis/minimart",
  setCIMinimartApproval: COMMON_API_URL + "/minimart/ci",
  operationsApproveMinimart: COMMON_API_URL + "/operations/minimart",
  //analyst approval
  getConfigTermEmi: COMMON_API_URL + "/configuration/active",

  //dashboard - loan - finances - bar graph
  getFinanceProfitData:
    COMMON_API_URL + "/dashboard/profit-stats-graph",

  // #region SPED

  //level 1
  getSalespersonEfficiencyPiechartData:
    COMMON_API_URL +
    "/salesPerson/efficiency-dashboard/getAllSalesPersons",
  getSalespersonEfficiencyTableData:
    COMMON_API_URL +
    "/salesPerson/efficiency-dashboard/getAssignedSalesPersons",
  downloadSalespersonEfficiencyTableData:
    "/getAllSalesPersons/download?fileType=",

  //level 2
  getDeatailedSalesPersonStats:
    COMMON_API_URL +
    "/salesPerson/efficiency-dashboard/getDeatailedSalesPersonStats?",
  // #endregion

  //motorcycle loans
  addNewMotorcycle: COMMON_API_URL + "/motorcycle/product",
  getMotorcycleCatalogue:
    COMMON_API_URL + "/motorcycle/category/list",
  getMotorcycleCatalog: COMMON_API_URL + "/motorcycle/search-filter",
  addMotorcycleCategory: COMMON_API_URL + "/motorcycle/category",
  updateMotorcycleCategory: COMMON_API_URL + "/motorcycle/category/",
  downloadDownPaymentRecepit:
    COMMON_API_URL + "/messenger/downpayment/",

  addInconsistentPayerNote:
    COMMON_API_URL + "/minimart/inconsistent-payer",
  getSingleMinimartNotes: COMMON_API_URL + "/notes/minimart",

  addEMINote: COMMON_API_URL + "/notes/emi-note",

  getUserByBranch: COMMON_API_URL + "/branch/user",
  resetMinimartGrade: COMMON_API_URL + "/minimart/reset-grade",

  getMinimartBusinessTypes:
    COMMON_API_URL + "/minimart/business-type",

  // #region T Presta (MTP)

  // #region Products
  getMtpProducts: TPRESTA_API_URL + "/tpresta-products",
  updateMtpProduct: TPRESTA_API_URL + "/update-tpresta-products",
  editCreditLimit: TPRESTA_API_URL + "/tpresta-products",
  // #endregion Products

  // #region Ladder
  getMtpLadder: TPRESTA_API_URL + "/web/ladder-info",
  // #endregion Ladder

  // #region Promotions
  getMtpPromotions: TPRESTA_API_URL + "/tpresta-promotions",
  addMtpPromotion: TPRESTA_API_URL + "/create-tpresta-promotions",
  updateMtpPromotion: TPRESTA_API_URL + "/update-tpresta-promotions",
  deleteMtpPromotion: TPRESTA_API_URL + "/delete-tpresta-promotions",
  getMtpPromotionsProduct:
    TPRESTA_API_URL + "/tpresta-promotions-products",
  geMTPPromotionInterest:
    TPRESTA_API_URL + "/analysis/promotion/search-filter",
  getPromotionDetails: TPRESTA_API_URL + "/promotion-interest/",
  updateAnalysisPromotionStatus:
    TPRESTA_API_URL + "/analysis/update/promotion/status",
  promotionStats: COMMON_API_URL + "/dashboard/promotion-stats",
  // #endregion Promotions

  //#region Complaint Management
  getComplaintPortal: TPRESTA_API_URL + "/complaint-portal",
  resolveComplaint: TPRESTA_API_URL + "/resolve-complaint",

  //#endregion Complaint Management
  //#region Edit Minimart Request
  getEditMinimartRequest: TPRESTA_API_URL + "/minimart/edit-requests",
  updateMinimartRequest: TPRESTA_API_URL + "/minimart/update-request",
  editScalaMinimartRequest: TPRESTA_API_URL + "/sign-up/update-request",
  downloadPhotos: TPRESTA_API_URL + "/file?su=",
  cedulaHistory: TPRESTA_API_URL + "/minimart/cedula-history?mid=",

  //#region Loan requests
  getLoanRequestList: TPRESTA_API_URL + "/loan-requests",
  updateLoanRequest:
    TPRESTA_API_URL + "/update-loan-request",
  //#endregion Loan requests

  // #endregion T Presta (MTP)
  assignSalesPerson:
    COMMON_API_URL + "/salesPerson/minimart/assignment",
  assignMessenger:
    COMMON_API_URL + "/messenger/minimart/assignment?mid=",

  getRemittancePayment:
    COMMON_API_URL + "/operations/remittance/payment/list",
  markPaidRemittance:
    COMMON_API_URL + "/operations/remittance/payment/paid-list",

  //New minimart Management
  getNewMinimartData: COMMON_API_URL + "/minimart/new/search-filter",
  getNewSingleMinimartData:
    COMMON_API_URL + "/minimart/new/matricesId",
  getAssignedMinimartUserList: COMMON_API_URL + "/user/route?",
  getNewMiniMartAndUserChartData:
    COMMON_API_URL + "/dashboard/new/minimart-stats/?branchId=",
  assignedMinimartRoute: COMMON_API_URL + "/minimart/route",
  getCensusUserList: COMMON_API_URL + "/user",
  getCensusWorkloadList: COMMON_API_URL + "/workload",
  getCensusPositionList: COMMON_API_URL + "/position/V2",
  getCensusZoneList: COMMON_API_URL + "/zone/branch",
  getCensusAssignedUserList: COMMON_API_URL + "/workload/users",
  saveAssignment: COMMON_API_URL + "/workload/user",

  //MTP SIGNUP
  getSignUpRequestList: TPRESTA_API_URL + "/sign-up",
  getSignUpMinimartRequest: TPRESTA_API_URL + "/sign-up/",
  saveSignUpRequest: TPRESTA_API_URL + "/sign-up/minimart",
  stopAssignment: TPRESTA_API_URL + "/sign-up/assignment/",
  saveConciliation: COMMON_API_URL + "/operations/conciliation",

  //Rewards
  getRewardList: COMMON_API_URL + "/reward/product/search-filter/",
  updateReward: COMMON_API_URL + "/reward/product",

  //Rewards Approval
  getApprovalRewardList:
    COMMON_API_URL + "/reward/product/redeem/approval/search-filter",
  getApprovalRewardDetails:
    COMMON_API_URL + "/reward/product/redeem/details/",
  updateRewardStatus:
    COMMON_API_URL + "/reward/product/redeem/approval",
  getRewardHistory: COMMON_API_URL + "/reward/product/history",
  getAllUserList: COMMON_API_URL + "/user/list",

  updateMinimartLevel: COMMON_API_URL + "/minimart/level",

  mergeMinimart: COMMON_API_URL + "/minimart/merge",
  getMinimartDetails: COMMON_API_URL + "/minimart/get/",
  paymentReverse: COMMON_API_URL + "/minimart/revert-payment?mid=",
  getCIMinimartDetails: COMMON_API_URL + "/operations/minimart/",
  loanCategoriesDetails: COMMON_API_URL + "/loan/state",
  downpaymentReverse: COMMON_API_URL + "/minimart/downpayment/revert-payment?mid=",

  addInvoice: COMMON_API_URL + "/invoice",
  getSupplier: COMMON_API_URL + "/invoice/supplier",
  getInvoice: COMMON_API_URL + "/invoice/approval/search-filter",
  approveInvoice: COMMON_API_URL + "/invoice/approval",

  setExceptionalMinimart:
    COMMON_API_URL + "/minimart/exceptional?mid=",
  getAllowedFrequency: COMMON_API_URL + "/minimart/frequency?lvl=",

  //Blacklist client
  clientStates: COMMON_API_URL + "/client-minimart/client-state",
  getIdentificationTypes: COMMON_API_URL + "/client-minimart/identification",
  getClientList: COMMON_API_URL + "/client-minimart/search-filter",
  getActiveLoans: COMMON_API_URL + "/minimart/minimart-loan?identificationNumber=",
  updateCedula: COMMON_API_URL + "/client-minimart/update-cedula",

  //Supplier configuration
  getAccountTypes: COMMON_API_URL + "/supplier/account/list",
  accountType: COMMON_API_URL + "/supplier/account",
  accountTypeState: COMMON_API_URL + "/supplier/account/account-type-state",
  bankDetails: COMMON_API_URL + "/supplier/bank/account",
  bankState: COMMON_API_URL + "/supplier/bank/bank-state",
  bankListAssociatedToAccount: COMMON_API_URL + "/supplier/bank/list",
  supplierSearchFilter: COMMON_API_URL + "/supplier/search-filter",
  supplierSearchFilterForParticularMinimart: TPRESTA_API_URL + "/supplier/search-filter",
  supplier: COMMON_API_URL + "/supplier",

  //Payment collection from Web UI
  getMinimartCollectionDetails: COMMON_API_URL + "/messenger/minimart?minimartId=",
  getPaymentModes: COMMON_API_URL + "/minimart/get-payment-mode-source",
  getDownPaymentDetails: COMMON_API_URL + "/loan/downpayment/details?mid=",
  uploadTransferReceiptOfPaymentCollection: COMMON_API_URL + "/minimart/transfer-receipt/upload",
  viewUploadedTranferReceipt: COMMON_API_URL + "/minimart/transfer-receipt/view?id=",
  collectPayment: COMMON_API_URL + "/minimart/emiCollection",
  collectDownpayment: COMMON_API_URL + "/minimart/downpayment-collection",
  assignSellerAfterPayOff: COMMON_API_URL + "/messenger/minimart/assign-sales",


  //Public apis
  getFileData: TPRESTA_API_URL + "/file/terms-view/",

  //merged minimart
  getMergedMinimart: COMMON_API_URL + "/minimart/merged-minimart-details?id=",

  // conciliation
  getDisbursementStatus: COMMON_API_URL + "/loan/conciliation/disbursement-status/",
  // Loan Initiated from 
  initiatedFrom: COMMON_API_URL + "/loan/initiated-from",

  // Ci Loan Request Report
  getCiLoanRequestReport: COMMON_API_URL + "/ci-app-loan/search-filter",
  downloadCiLoanRequestreport: COMMON_API_URL + "/ci-app-loan/search-filter/download?fileType="
};

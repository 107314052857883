import React from "react";

//Utility
import Utility from "../../utils/Utility";

// Constants

class LandingPage extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("state")
      && JSON.parse(localStorage.getItem("state")).loginReducer.loggedInUserInfo.userId) {
      let routeAsPerUserRole = Utility.landingScreenAsPerLoggedInUserRole(JSON.parse(localStorage.getItem("state")).loginReducer.loggedInUserInfo.role);
      this.props.history.push(routeAsPerUserRole);
    }
    else {
      this.props.history.push("/ingreso");
    }
  }

  render() {
    return <></>;
  }
}

export default LandingPage;

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import MinimartHistory from "./MinimartHistory";

// Action
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import { getMinimartDataAction } from "./MinimartHistoryAction";
import { setUserAction } from "../UserList/UserListAction";
import { setApproveMiniMartDetailsAction } from "../ApprovalMinimart/ApprovalMinimartAction";
import { setSearchFilterAction } from "../../constants/Action";
import { OPERATION_STATUS } from "../../config/axios.init";
import { getNoteAction } from "../AnalystApproval/AnalystApprovalAction";

// Constants
import {
  USER_ROLES,
  SEARCH_FILTER_PAGES,
  MINIMART_HISTORY_FILTER,
} from "../../constants/appConstants";

class MinimartHistoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minimarts: [],
      errorMessage: "",
      showLoader: false,
      isOperationsOfficer:
        props.loggedInUser.role === USER_ROLES.operationsOfficer,
      isOperationsModerator:
        props.loggedInUser.role === USER_ROLES.operationsModerator,
    };
    this.isCardClick = false;
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.MINIMART_HISTORY,
        {
          ...MINIMART_HISTORY_FILTER,
        }
      );
    }
  }

  initErrorStates = () => {
    this.setState({ errorMessage: "" });
  };

  getNote = (noteId, callback) => {
    this.setState({ showLoadingModal: true });
    getNoteAction(noteId, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            note: Response.data.data,
            errorMessage: "",
          },
          () => {
            callback(Response.data.data.note);
          }
        );
      } else {
        this.setState(
          {
            showLoadingModal: false,
            errorMessage: Response.error.message,
          },
          callback
        );
      }
    });
  };

  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        fd: searchFilterObject.fd,
        td: searchFilterObject.td,
        mn: searchFilterObject.mn,
        st: searchFilterObject.st,
        cd: searchFilterObject.cd,
        limit: searchFilterObject.lmt,
        offset: searchFilterObject.oft,
      },
    });
    getMinimartDataAction(searchFilterObject, (responsedata) => {
      if (responsedata.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          minimarts: responsedata.data.data.mns,
          numberOfPages: responsedata.data.data.nop,
          showLoader: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: responsedata.error.message,
          showLoader: false,
        });
      }
    });
  };

  render() {
    return (
      <div>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <MinimartHistory
          isOperationsOfficer={this.state.isOperationsOfficer}
          isOperationsModerator={this.state.isOperationsModerator}
          minimarts={this.state.minimarts}
          numberOfPages={this.state.numberOfPages}
          errorMessage={this.state.errorMessage}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          searchFilter={this.props.searchFilter}
          getNote={this.getNote}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
  users: state.userListReducer.users.users,
  searchFilter: state.searchFilterReducer.minimartHistorySearchFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUserAction,
      clearDailyStatusDateAction,
      setApproveMiniMartDetailsAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinimartHistoryContainer);

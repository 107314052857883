import React from "react";
import moment from "moment";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

const renderFormatter = (info, data) => {
  return (
    <>
      <span className='titleText'>
        {Strings(info.label)} &nbsp;:&nbsp;
      </span>
      <span className={`detailText ${info.className}`}>
        {data[info.key] ? Strings(data[info.key]) : Strings("NA")}
      </span>
    </>
  );
};

const renderDateFormatter = (info, data) => {
  return (
    <>
      <span className='titleText'>
        {Strings(info.label)} &nbsp;:&nbsp;
      </span>
      <span className={`detailText ${info.className}`}>
        {data[info.key]
          ? moment(data[info.key]).format("DD MMM YYYY")
          : Strings("NA")}
      </span>
    </>
  );
};

const renderCurrencyFormatter = (info, data) => {
  return (
    <>
      <span className='titleText'>
        {Strings(info.label)} &nbsp;:&nbsp;
      </span>
      <span className={`detailText ${info.className}`}>
        {data[info.key]
          ? Utility.getCurrencyRepresentationOfAmount(data[info.key])
          : Strings("NA")}
      </span>
    </>
  );
};

const renderBenefit = (_info, data) => {
  if (data.benefitType === "DISCOUNT") {
    return renderCurrencyFormatter(
      { key: "discount", label: Strings("discount") + " ($) " },
      data
    );
  } else if (data.benefitType === "INSTALLMENT") {
    return renderFormatter(
      { key: "loanId", label: Strings("LoanId") },
      data
    );
  }
  return null;
};

export const MINIMART_DETAILS_KEYS = [
  {
    key: "mid",
    label: "MatricesID",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "cd",
    label: "code",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "bty",
    label: "BusinessType",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "rs",
    label: "registrationStatus",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "mn",
    label: "name",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "on",
    label: "ownerName",
    className: "address",
    formmater: renderFormatter,
  },
  {
    key: "add",
    label: "address",
    className: "address",
    formmater: renderFormatter,
  },
  {
    key: "tp",
    label: "PhoneNumber",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "otp",
    label: "MobileNumberOfOwner",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "lvl",
    label: "minimartLevel",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "gd",
    label: "Grade",
    className: "",
    formmater: renderFormatter,
  },
];

export const PROMOTION_DETAILS_KEYS = [
  {
    key: "title",
    label: "promotionName",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "description",
    label: "Description",
    className: "address",
    formmater: renderFormatter,
  },
  {
    key: "activationDate",
    label: "activationDate",
    className: "",
    formmater: renderDateFormatter,
  },
  {
    key: "expiryDate",
    label: "ExpiryDate",
    className: "",
    formmater: renderDateFormatter,
  },
  {
    key: "gradeNeeded",
    label: "requiredGrade",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "levelNeeded",
    label: "requiredLevel",
    className: "",
    formmater: renderFormatter,
  },
  // {
  //   key: "minRewardPointsNeeded",
  //   label: "min",
  //   className: "",
  //   formmater: renderFormatter,
  // },
  // {
  //   key: "maxRewardPointsNeeded",
  //   label: "max",
  //   className: "",
  //   formmater: renderFormatter,
  // },
  {
    key: "type",
    label: "promotionType",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "loanType",
    label: "product",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "benefitType",
    label: "benefitType",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "benefitTypeKey",
    label: "",
    className: "",
    formmater: renderBenefit,
  },
];

export const FILTER_DEFAULT_LIST = ["selectLoanStatus", "Categoría"];

export const STATUS_LIST = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
};

import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import RewardCatalog from "./RewardCatalog";

//Actions
import { getRewardListAction, updateRewardAction } from "./Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { USER_ROLES } from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility

//Stylesheets
import "./RewardCatalog.scss";
import withHOC from "../../HOC/HOC";

class RewardCatalogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      successToastMessage: "",
      level: "",
      toShowToast: false,
      rewardsList: [],
      numberOfPages: 1,
      errorMessage: "",
      readOnly:
        props.loggedInUser.role === USER_ROLES.analyst ||
        props.loggedInUser.role === USER_ROLES.operationsOfficer ||
        props.loggedInUser.role === USER_ROLES.operationsModerator ||
        props.loggedInUser.role === USER_ROLES.analystModerator ||
        props.loggedInUser.role === USER_ROLES.collectionsSupervisor ||
        props.loggedInUser.role === USER_ROLES.administrativeManager ||
        props.loggedInUser.role === USER_ROLES.customerService ||
        props.loggedInUser.role === USER_ROLES.salesManager ||
        props.loggedInUser.role === USER_ROLES.collectionsManager ||
        props.loggedInUser.role === USER_ROLES.technology,
    };
  }

  handleOnClickSearchFilter = (request) => {
    this.setState({
      showLoader: true,
    });
    getRewardListAction({ ...request }, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          rewardsList: response.data.data.rpls,
          errorMessage: "",
          numberOfPages: response.data.data.np,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.errorMessage,
          showLoader: false,
          rewardsList: [],
          numberOfPages: 0,
        });
      }
    });
  };

  saveReward = (request, successCallback) => {
    this.setState({
      showLoader: true,
      toShowToast: false,
      level: "",
      successToastMessage: "",
    });
    updateRewardAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            errorMessage: "",
            showLoader: false,
            toShowToast: true,
            successToastMessage: request.rpid
              ? Strings("rewardUpdated")
              : Strings("rewardAdded"),
            level: "success",
          },
          successCallback
        );
      } else {
        this.setState({
          errorMessage: response.error.errorMessage,
          showLoader: false,
        });
      }
    });
  };
  render() {
    return (
      <div>
        <Row className='heading'>{Strings("RewardCatalog")}</Row>
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.successToastMessage}
            userNotificationObj={{
              message: this.state.successToastMessage,
              level: this.state.level,
            }}
          />
        )}
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <RewardCatalog
          rewardsList={this.state.rewardsList}
          numberOfPages={this.state.numberOfPages}
          errorMessage={this.state.errorMessage}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          saveReward={this.saveReward}
          readOnly={this.state.readOnly}
        />
      </div>
    );
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

const RewardCatalogWrapper = withHOC(RewardCatalogContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardCatalogWrapper);

import React from "react";
import MinimartInfo from "./MinimartInfo";
import { Strings } from "../../resources/i18n/i18n";
import { Row } from "react-bootstrap";

export default class MergedMinimartsDetails extends React.Component {
    constructor(props) {
        super(props);
        const { setLoanDetailsFunctionKey } = props.location.state;
        this.setLoanDetailsID = window[setLoanDetailsFunctionKey];
        this.state = {
            mergedMinimarts: props.location.state.mergedMinimarts,
            matricesId: props.location.state.matricesId,
        }
    }

    renderMergedMinimarts = () => {
        const { setLoanDetailsID } = this;
        return this.state.mergedMinimarts.length > 0 ? (
            <>
                {this.state.mergedMinimarts.map((minimart) => {
                    return (
                        <>
                            <MinimartInfo
                                minimart={minimart}
                                setLoanDetailsID={setLoanDetailsID}
                            />
                            <hr className='newhr' />
                        </>
                    )
                })}
            </>
        ) : (
            <div className='noRecordsFound noBranchRecords'>
                {Strings("NoRecordsFound")}
            </div>
        )
    }

    render() {
        return (
            <>
                <Row className='heading'>
                    {Strings("mergedMinimartHistory")} - {this.state.matricesId}
                </Row>
                <div className='minimartDetails container-fluid ListContainer'>
                    {this.renderMergedMinimarts()}
                </div>
            </>
        )
    }
}
import React, { Component } from 'react';

//Custom Components
import PaymentCollection from './PaymentCollection';
import UserNotification from '../../CustomUIComponents/UserNotification/UserNotification';

//Actions
import {
    assignSellerAfterPayOffAction,
    collectDownpaymentAction,
    collectPaymentAction,
    getDownPaymentDetailsAction,
    getMinimartCollectionDetailsAction,
    getPaymentModesAction,
    uploadTransferReceiptOfPaymentCollectionAction,
    viewUploadedTranferReceipt
} from '../PaymentCollectionTab/PaymentCollectionAction';

//Constants
import { OPERATION_STATUS } from '../../../config/axios.init';

//Strings
import { Strings } from '../../../resources/i18n/i18n';

//Utils
import Utility from '../../../utils/Utility';

class PaymentCollectionContainer extends Component {
    constructor(props) {
        super(props);
        // Initialize state if needed
        this.state = {
            collectionData: {},
            possiblePaymentModes: [],
            errorMessage: "",
            showLoadingModal: true,
            showToast: false,
            message: "",
            level: "",
        };
    }

    componentDidMount() {
        //Dropdown data for payment modes
        this.getPaymentModes();

        //Get collection details for first tab
        this.getMinimartCollectionDetails();
    }

    getPaymentModes = () => {
        this.setState({ showLoadingModal: true, errorMessage: "" });
        getPaymentModesAction((response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    possiblePaymentModes: response.data.data.paymentModes,
                    showLoadingModal: false,
                });
            } else {
                this.setState({
                    errorMessage: response.error.message,
                    showLoadingModal: false,
                });
            }
        });
    }

    getMinimartCollectionDetails = (callback = () => { }) => {
        this.props.loadSpinner();
        this.setState({ errorMessage: "" });
        getMinimartCollectionDetailsAction(this.props.minimartId, (responseData) => {
            this.props.unloadSpinner();
            if (responseData.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    collectionData: responseData.data.data,
                }, callback);
            } else {
                this.setState({
                    collectionData: {},
                    errorMessage: responseData.error.message,
                    showLoadingModal: false,
                }, callback);
            }
        });
    }

    getDownPaymentDetails = (callback = () => { }) => {
        this.props.loadSpinner();
        this.setState({ errorMessage: "" });
        getDownPaymentDetailsAction(this.props.minimartId, (responseData) => {
            this.props.unloadSpinner();
            if (responseData.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    collectionData: responseData.data.data,
                }, callback);
            } else {
                this.setState({
                    collectionData: {},
                    errorMessage: responseData.error.message,
                }, callback);
            }
        });
    }

    uploadTransferReceiptOfPaymentCollection = (request) => {
        this.props.loadSpinner();
        this.setState({
            errorMessage: "",
            showToast: false,
            message: "",
            level: "",
        });
        let requestObject = {
            file: request.transferReceipt,
            minimartId: this.props.minimartId,
            collectionType: "EMI",
        }
        uploadTransferReceiptOfPaymentCollectionAction(requestObject, (responseData) => {
            this.props.unloadSpinner();
            if (responseData.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    uploadedTransferReceiptId: responseData.data.data.transferReceiptId,
                    showLoadingModal: false,
                    showToast: true,
                    message: Strings("DataUpdatedSuccessfully"),
                    level: "success",
                    errorMessage: "",
                });
            } else {
                this.setState({
                    errorMessage: responseData.error.message,
                    showLoadingModal: false,
                });
            }
        });
    }

    viewUploadedTranferReceipt = () => {
        this.props.loadSpinner();
        this.setState({ errorMessage: "" });
        viewUploadedTranferReceipt(this.state.uploadedTransferReceiptId, (responseData) => {
            this.props.unloadSpinner();
            if (responseData.status === OPERATION_STATUS.SUCCESS) {
                const s3Url = Utility.getDecryptedData(responseData.data.data.transferReceiptUrl)
                window.open(s3Url);
            } else {
                this.setState({
                    errorMessage: responseData.error.message,
                });
            }
        });
    }

    collectPayment = (request, callbackToClearData, callBackToAssignSeller) => {
        this.props.loadSpinner();
        this.setState({
            errorMessage: "",
            showToast: false,
            message: "",
            level: "",
        });
        let requestObject = {
            minimartId: this.props.minimartId,
            amountCollected: request.amountToPay,
            paymentMode: request.paymentMode,
            loanId: request.loanId,
            isPayOff: request.amountToPay.toString() === request.amountPayOff.toString(),
            paymentType: this.state.collectionData.paymentType,
            paymentSource: "WEB",
            transferReceiptId: this.state.uploadedTransferReceiptId,
            note: request.notes
        }
        collectPaymentAction(requestObject, (responseData) => {
            this.props.unloadSpinner();
            if (responseData.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showToast: true,
                    message: Strings("paymentCollectedSuccessfully"),
                    level: "success",
                    errorMessage: "",
                }, () => {
                    setTimeout(() => {
                        callbackToClearData();
                        responseData.data.data.forEach((singleResponse) => {
                            this.props.downloadPaymentReceipt(singleResponse.receiptData.receiptId);
                        })
                        if (requestObject.isPayOff) {
                            callBackToAssignSeller();
                        }
                        this.getMinimartCollectionDetails();
                        this.props.getSingleMinimartData();
                    }, 2000);
                });
            } else {
                this.setState({
                    errorMessage: responseData.error.message,
                });
            }
        });
    }

    collectDownPayment = (request, callback) => {
        this.props.loadSpinner();
        this.setState({
            errorMessage: "",
            showToast: false,
            message: "",
            level: "",
        });
        let requestObject = {
            minimartId: this.props.minimartId,
            amountCollected: request.amountToPay,
            paymentMode: request.paymentMode,
            paymentSource: "WEB",
            note: request.notes,
            transferReceiptId: this.state.uploadedTransferReceiptId
        }
        collectDownpaymentAction(requestObject, (responseData) => {
            this.props.unloadSpinner();
            if (responseData.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showToast: true,
                    message: Strings("downPaymentCollectedSuccessfully"),
                    level: "success",
                    errorMessage: "",
                }, () => {
                    setTimeout(() => {
                        callback();
                        this.props.downloadDownpaymentReceipt(
                            responseData.data.data.receiptId,
                            responseData.data.data.applicationId);
                        this.getDownPaymentDetails();
                    }, 2000);
                });
            } else {
                this.setState({
                    errorMessage: responseData.error.message,
                });
            }
        });
    }

    assignSellerAfterPayOff = (callback) => {
        this.props.loadSpinner();
        this.setState({
            errorMessage: "",
            showToast: false,
            message: "",
            level: "",
        });
        let requestObject = {
            mid: this.props.minimartId,
        }
        assignSellerAfterPayOffAction(requestObject, (responseData) => {
            this.props.unloadSpinner();
            if (responseData.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    errorMessage: "",
                    showToast: true,
                    message: Strings("salesPersonAssignedSuccess"),
                    level: "success",
                }, callback);
            } else {
                this.setState({
                    errorMessage: responseData.error.message,
                });
            }
        });
    }


    render() {
        return (
            <div>

                {this.state.showToast && (
                    <UserNotification
                        key='Success'
                        userNotificationObj={{
                            message: Strings(this.state.message),
                            level: this.state.level,
                        }}
                    />
                )}
                <PaymentCollection
                    collectionData={this.state.collectionData}
                    possiblePaymentModes={this.state.possiblePaymentModes}
                    errorMessage={this.state.errorMessage}
                    uploadedTransferReceiptId={this.state.uploadedTransferReceiptId}
                    getDownPaymentDetails={this.getDownPaymentDetails}
                    getMinimartCollectionDetails={this.getMinimartCollectionDetails}
                    uploadTransferReceiptOfPaymentCollection={this.uploadTransferReceiptOfPaymentCollection}
                    viewUploadedTranferReceipt={this.viewUploadedTranferReceipt}
                    collectPayment={this.collectPayment}
                    collectDownPayment={this.collectDownPayment}
                    assignSellerAfterPayOff={this.assignSellerAfterPayOff}
                />
            </div>
        );
    }
}

export default PaymentCollectionContainer;
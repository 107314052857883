import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

// Component
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

// Utility
import Utility from "../../utils/Utility";

// Styles
import "../MinimartList/MinimartList.scss";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Constants
import {
  DEFAULT_DURATION,
  DEFAULT_DATE_FORMAT,
  HISTORY_MINIMART_LEGEND,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DATE_TYPE,
  STATUS_FOR_HISTORY_MINIMART,
  DEFAULT_HISTORY_MINIMART_STATUS,
  INVOICE_DATE_FORMAT,
  CI_MINIMART_STATUS,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

class MinimartHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      minimartName: props.searchFilter.mn,
      status: props.searchFilter.st,
      recordsPerPage: props.searchFilter.limit,
      activePage: props.searchFilter.offset,
      fromDate: props.searchFilter.fd,
      toDate: props.searchFilter.td,
      openModal: false,
      ciID: props.searchFilter.cd,
      notes: "",
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  handleSearchNameChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeStatusFilter = (event) => {
    this.setState({
      status:
        event.target.value !== DEFAULT_HISTORY_MINIMART_STATUS
          ? event.target.value
          : "",
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.fromDate)) {
          DateValue =
            moment(dateMoment).diff(this.state.fromDate, "days") === 0
              ? this.state.fromDate
              : this.state.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({ [key]: DateValue });
  };

  resetSearchValues = () => {
    this.setState(
      {
        status: "",
        minimartName: "",
        ciID: "",
        fromDate: DEFAULT_DURATION.fromDate,
        toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      },
      () => {
        this.handleOnClickSearchFilter(false);
      }
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleOnClickSearchFilter({
      ih: true,
      mn: this.state.minimartName
        ? this.state.minimartName
        : undefined,
      st: this.state.status ? this.state.status : undefined,
      fd: Utility.convertDateIntoMiliSeconds(this.state.fromDate),

      td: Utility.convertDateIntoMiliSeconds(this.state.toDate),
      lmt: this.state.recordsPerPage,
      oft: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
      cd: this.state.ciID ? this.state.ciID : undefined,
    });
  };

  renderSearchFilter = () => {
    let minimartFilters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("name"),
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.minimartName,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
        name: "minimartName",
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("code"),
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.ciID,
        fieldname: SEARCH_FILTER_FIELDS.CI_CODE,
        name: "ciID",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeStatusFilter,
        Value: this.state.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: STATUS_FOR_HISTORY_MINIMART,
      },
    ];
    return (
      <Row className='filters pt-3'>
        <SearchFilter
          filters={minimartFilters}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleOnClickResetFilter={this.resetSearchValues}
          paddingRight={0}
          paddingLeft={3}
        />
      </Row>
    );
  };

  renderMinimartCards = () => {
    return (
      <Row className='px-3'>
        {this.props.minimarts.length >= 1 ? (
          <React.Fragment>
            {this.props.minimarts.map((minimart, index) => {
              return (
                <CustomCard
                  card={minimart}
                  key={index}
                  cardType='CIApprovalMinimart'
                  handleCardClick={this.handleCardClick}
                  isHistory={true}
                />
              );
            })}
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </React.Fragment>
        ) : (
          <div className='noRecordsFound'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </Row>
    );
  };

  renderCIMinimartModal = () => {
    return (
      <Modal
        open={this.state.openModal}
        onClose={() =>
          this.setState({ openModal: false, modalData: null })
        }
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{ modal: "customModal" }}
        showCloseIcon={false}
        center
      >
        <Row className='inputModal'>
          <Col md={12} className='headerModal'>
            {Strings("minimartDetailsText")}
          </Col>
          <Col md={12} className='modalContent'>
            <Row className='userInput '>
              {this.state.errorMessage && (
                <Col md={12} className='errors px-0'>
                  {this.state.errorMessage}
                </Col>
              )}
              <Row className='w-100 m-1 pt-1'>
                <Col lg={4} className='titleText'>
                  {Strings("Name")} :&nbsp;
                </Col>
                <Col
                  lg={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.mn || Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("CIID")} :&nbsp;
                </Col>
                <Col
                  md={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.cd || Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("Address")} :&nbsp;
                </Col>
                <Col
                  md={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.add || Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("City")} :&nbsp;
                </Col>
                <Col
                  md={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.ct || Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("Branch")} :&nbsp;
                </Col>
                <Col
                  md={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.br || Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("Zone")} :&nbsp;
                </Col>
                <Col
                  md={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.zn || Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("mzone")} :&nbsp;
                </Col>
                <Col
                  md={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.mzn || Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("visitDay")} :&nbsp;
                </Col>
                <Col md={8} className=' breakWord addressOrName'>
                  {this.state.modalData?.vd
                    ? Strings(this.state.modalData?.vd)
                    : Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("status")} :&nbsp;
                </Col>
                <Col
                  md={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.st
                    ? Strings(this.state.modalData?.st)
                    : Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("actionTakenBy")} :&nbsp;
                </Col>
                <Col
                  md={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.aby
                    ? Strings(this.state.modalData?.aby)
                    : Strings("NA")}
                </Col>
              </Row>
              <Row className='w-100 m-1'>
                <Col md={4} className='titleText'>
                  {Strings("actionTakenOn")} :&nbsp;
                </Col>
                <Col
                  md={8}
                  className='noMargin breakWord addressOrName'
                >
                  {this.state.modalData?.ao
                    ? moment(this.state.modalData?.ao).format(
                      INVOICE_DATE_FORMAT
                    )
                    : Strings("NA")}
                </Col>
              </Row>
              {this.state.modalData.st ===
                CI_MINIMART_STATUS.REJECTED && (
                <>
                  <Row className='w-100 m-1'>
                    <Col md={4} className='titleText'>
                      <span>{Strings("Notes")} :&nbsp;</span>
                    </Col>
                    {!this.state.modalData.nt && <Col md={8} className=" breakWord addressOrName">
                      {Strings("NA")}
                    </Col>}

                  </Row>
                  {this.state.modalData.nt && <Row className='w-100'>
                    <Col md={12}>
                      <textarea
                        className={"w-100"}
                        id='Notes'
                        type='text'
                        placeholder={Strings("Notes")}
                        value={this.state.notes}
                        cols={45}
                        rows={5}
                        readOnly
                        disabled
                      />
                    </Col>
                  </Row>}
                </>
              )}
            </Row>

            <Row className='py-3'>
              <Col md={{ span: 4, offset: 8 }}>
                <Button
                  className={
                    "primaryModalButton buttonMinWidth buttonBorder w-100"
                  }
                  onClick={() => {
                    this.setState({
                      openModal: false,
                    });
                  }}
                >
                  {Strings("Cancel")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    );
  };

  handleCardClick = (card) => {
    card.st === CI_MINIMART_STATUS.REJECTED && card.nt
      ? this.props.getNote(card.nt, (notes = "") => {
        this.setState({
          openModal: true,
          modalData: card,
          notes: notes,
        });
      })
      : this.setState({
        openModal: true,
        modalData: card,
      });
  };

  render() {
    return (
      <div>
        <Row className='heading'>{Strings("minimartHistory")} </Row>
        {this.state.openModal && this.renderCIMinimartModal()}
        <div className='minimartList'>
          <div className='cards'>
            {this.renderSearchFilter()}
            {this.props.errorMessage !== "" ? (
              <Row className='errors mb-1'>
                {Strings(this.props.errorMessage)}
              </Row>
            ) : null}

            <Row className={`d-flex justify-content-between`}>
              <CustomerTypeLegend
                // heading={Strings("ApplicationStatuses")}
                arrayOfTypes={HISTORY_MINIMART_LEGEND}
                paddingLeft={4}
              />
            </Row>
            {this.renderMinimartCards()}
          </div>
        </div>
      </div>
    );
  }
}

export default MinimartHistory;

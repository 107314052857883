import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Component
import GeneralReports from "./GeneralReports";

//Action
import {
  ClearReportsReducerAction,
  setTwoStepAction,
} from "./GeneralReportsAction";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import { setSearchFilterAction } from "../../constants/Action";

//Constants

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility

//Styles
import "./GeneralReports.scss";

//Assets

class GeneralReportsContainer extends React.Component {
  componentDidMount() {
    this.props.clearDailyStatusDateAction();
  }
  render() {
    return (
      <div className='generalReports'>
        <Row className='heading'>{Strings("Reports")}</Row>
        <GeneralReports
          loggedInUser={this.props.loggedInUser}
          ClearReportsReducerAction={
            this.props.ClearReportsReducerAction
          }
          setTwoStepAction={this.props.setTwoStepAction}
          setSearchFilterAction={this.props.setSearchFilterAction}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ClearReportsReducerAction,
      clearDailyStatusDateAction,
      setTwoStepAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralReportsContainer);

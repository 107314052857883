import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getMinimartDataAction = (requestData, callback) => {
  requestData.oft -= 1;
  callAPI(
    "getMinimartDataAction",
    API.getMinimartHistoryData,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import React from "react";
import CiLoanRequest from "./CiLoanRequest";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setSearchFilterAction, dowloadedAction } from "../../constants/Action";
import { getCiLoanRequestReportAction } from "./CiLoanRequestAction";
import { OPERATION_STATUS } from "../../config/axios.init";
import { SEARCH_FILTER_PAGES, CI_LOAN_REQUEST_REPORT_FILTER, CONFIGURATION_DATE_TIME_FORMAT, INVOICE_DATE_FORMAT } from "../../constants/appConstants";
import { Strings } from "../../resources/i18n/i18n";
import moment from "moment";
import _ from "lodash";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import { COLUMNS } from "./CiLoanRequestReportConstants";
import Utility from "../../utils/Utility";
import fileDownload from "js-file-download";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

class CiLoanRequestContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showValidationResult: false,
            searchFilter: {},
            rows: [],
            showLoader: false,
            toShowToast: false,
            message: ""
        };

    }
    componentWillUnmount() {
        this.props.setSearchFilterAction(
            SEARCH_FILTER_PAGES.CI_LOAN_REQUEST_REPORT,
            { ...CI_LOAN_REQUEST_REPORT_FILTER }
        );

    }
    componentDidMount() {
        this.props.setSearchFilterAction(
            SEARCH_FILTER_PAGES.CI_LOAN_REQUEST_REPORT,
            { ...CI_LOAN_REQUEST_REPORT_FILTER }
        );
    }
    checkValidations = (inputData) => {
        const invoiceNumberRegex = /^[A-Za-z0-9-]+$/;
        const minimartCodeRegex = /^[A-Za-z0-9]+$/;
        let isValid = true;


        if (inputData.search.referenceId) {
            if (invoiceNumberRegex.test(inputData.search.referenceId.trim()) && inputData.search.referenceId.trim().length <= 36) {
            } else {
                isValid = false;
            }
        }

        if (inputData.search.minimartCode) {
            if (minimartCodeRegex.test(inputData.search.minimartCode.trim())) {
            } else {
                isValid = false;
            }
        }
        if (inputData.search.invoiceNumber) {
            if (invoiceNumberRegex.test(inputData.search.invoiceNumber.trim()) && inputData.search.invoiceNumber.trim().length <= 100) {
            } else {
                isValid = false;
            }
        }
        this.setState({
            showValidationResult: isValid
        })
        return isValid;
    };
    handleOnClickSearchFilter = (searchFilterObject) => {
        this.setState({
            showValidationResult: false
        })
        let trimmedSearchFilterObject = {
            search: {
                referenceId: searchFilterObject.search.referenceId ? searchFilterObject.search.referenceId.trim() : undefined,
                status: searchFilterObject.search.status || undefined,
                limit: searchFilterObject.search.limit || 10,
                offset: searchFilterObject.search.offset || 0,
                minimartCode: searchFilterObject.search.minimartCode ? searchFilterObject.search.minimartCode.trim() : undefined,
                invoiceNumber: searchFilterObject.search.invoiceNumber ? searchFilterObject.search.invoiceNumber.trim() : undefined,
                from: searchFilterObject.search.from || undefined,
                to: searchFilterObject.search.to || undefined,
            }
        }
        let isInputCorrect = this.checkValidations(searchFilterObject);
        isInputCorrect && (this.setState({
            showLoader: true,
            searchFilter: {
                ri: searchFilterObject.search.referenceId || undefined,
                st: searchFilterObject.search.status || undefined,
                limit: searchFilterObject.search.limit || 10,
                offset: searchFilterObject.search.offset || 0,
                cd: searchFilterObject.search.minimartCode,
                in: searchFilterObject.search.invoiceNumber,
                fd: searchFilterObject.search.from || undefined,
                td: searchFilterObject.search.to || undefined,
            },
        }))
        isInputCorrect && (getCiLoanRequestReportAction(
            trimmedSearchFilterObject,
            (responsedata) => {
                if (responsedata.status === OPERATION_STATUS.SUCCESS) {
                    this.setState({
                        numberOfPages: responsedata.data.data.np,
                        showLoader: false,
                        errorMessage: "",
                        rows: responsedata.data.data.rp
                    });
                    this.createRows(responsedata.data.data.rp);
                } else {
                    this.setState({
                        errorMessage: responsedata.error.message,
                        showLoader: false,
                    });

                }
            }
        ))
    };
    downloadReports = (url, requestData, fileType) => {
        this.setState({
            showLoader: true,
            isError: false,
            toShowToast: false,
            message: "",
            level: ""
        });
        dowloadedAction(
            url,
            requestData,
            (response) => {
                if (response.status === OPERATION_STATUS.SUCCESS) {
                    if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
                        fileDownload(
                            response.data,
                            Strings("CI_LOAN_REQUEST_REPORT") +
                            "-" +
                            Utility.getReportFormattedDateString(
                                Utility.convertDateIntoMiliSeconds(new Date())
                            ) +
                            Utility.getFileType(fileType)
                        );
                        this.setState({
                            errorMessage: "",
                            showLoader: false,
                            isError: false,
                        });
                    } else {
                        this.setState({
                            showLoader: false,
                            isError: true,
                            toShowToast: true,
                            message: Strings("NoRecordsFound"),
                            level: "error",
                        });
                    }
                } else {
                    this.setState({
                        showLoader: false,
                        errorMessage: response.error.message,
                    });
                }
            },
            "CILoanRequestReport"
        );
    };
    resetErrorState = (state) => {
        this.setState({
            showValidationResult: state
        })
    }
    createRows = (rowDetails) => {
        let rowsData = [];

        _.forEach(rowDetails, (fileDetails) => {
            rowsData.push({
                cd: fileDetails.cd ? fileDetails.cd : Strings("NA"),
                in: fileDetails.in ? fileDetails.in : Strings("NA"),
                ia: fileDetails.ia ? fileDetails.ia : Strings("NA"),
                iba: fileDetails.iba ? fileDetails.iba : Strings("NA"),
                id: fileDetails.id ? moment(fileDetails.id).format(INVOICE_DATE_FORMAT) : Strings("NA"),
                ied: fileDetails.ied ? moment(fileDetails.ied).format(INVOICE_DATE_FORMAT) : Strings("NA"),
                appId: fileDetails.appId ? fileDetails.appId : Strings("NA"),
                st: Strings(fileDetails.st),
                rd: moment(fileDetails.rd).format(CONFIGURATION_DATE_TIME_FORMAT),
            });
        });
        this.setState({ rows: rowsData });
    };
    render() {
        return (
            <div>
                {this.state.toShowToast && (
                    <UserNotification
                        key={this.state.message}
                        userNotificationObj={{
                            message: this.state.message,
                            level: "error",
                        }}
                    />
                )}
                <LoadingModal showLoadingModal={this.state.showLoader} />

                <CiLoanRequest
                    validation={this.state.showValidationResult}
                    resetErrorState={this.resetErrorState}
                    handleOnClickSearchFilter={this.handleOnClickSearchFilter}
                    searchFilter={this.props.searchFilter}
                    rows={this.state.rows}
                    columns={COLUMNS}
                    numberOfPages={this.state.numberOfPages}
                    errorMessage={this.state.errorMessage}
                    downloadReports={this.downloadReports}
                />

            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    searchFilter: state.searchFilterReducer.ciLoanRequestReportFilter,
});
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setSearchFilterAction,
        },
        dispatch
    );
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CiLoanRequestContainer);
import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./App.scss";

import { ChangeLanguage } from "../../resources/i18n/i18n";
import { APP_LANGUAGES, URL_FILTER } from "../../constants/appConstants";

import { initializeInterceptors } from "../../config/AxiosInterceptor";

import { logoutAction } from "../Login/LoginAction";
import { setSearchFilterAction } from "../../constants/Action";
import { ROUTES } from "../../constants/routeConstants";

class App extends React.Component {
  constructor(props) {
    super(props);
    if (window.performance) {
      if (
        window.performance &&
        window.performance.getEntriesByType("navigation") &&
        window.performance.getEntriesByType("navigation")[0] &&
        window.performance.getEntriesByType("navigation")[0].type ===
        "reload"
      ) {
        if (
          Object.keys(URL_FILTER).includes(
            props.history.location.pathname
          )
        ) {
          props.setSearchFilterAction(
            URL_FILTER[props.history.location.pathname].key,
            URL_FILTER[props.history.location.pathname].filter
          );
        }
        if ((!localStorage.getItem("state")
          || !JSON.parse(localStorage.getItem("state")).loginReducer.loggedInUserInfo.userId) && props.history.location.pathname !== ROUTES.LOGIN) {
          props.history.push("/ingreso");
        }
      }
    }
    initializeInterceptors(props.history, this.logoutAction);
  }

  logoutAction = (callback) => {
    this.props.logoutAction(callback);
  };
  componentDidMount() {
    ChangeLanguage(APP_LANGUAGES.SPANISH);
  }

  render() {
    return (
      <div className='app'>
        <div className='mainContent'>{this.props.children}</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({}); //NOSONAR
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { logoutAction, setSearchFilterAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(App));

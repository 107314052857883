import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Styles
import "./Layout.scss";

//Components
import Header from "../Header/HeaderContainer";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/SidebarContainer";

class Layout extends React.Component {
  render() {
    return (
      <div className='layout'>
        <Header />
        <Sidebar />
        <div className='subComponent'>{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); //NOSONAR

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import { callAPI } from "../../config/AxiosTemplate";

// Constants
import { API } from "../../constants/APIConstants";

export const getAllCITransferUnPaidRecordsAction = (
  data,
  callback
) => {
  callAPI(
    "GetAllCITransferRecords",
    API.getAllCITransferRecords,
    "POST",
    data,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const markInvoicesAsPaidAction = (data, callback) => {
  callAPI(
    "markInvoicesAsPaid",
    API.markInvoicesAsPaid,
    "PATCH",
    data,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getTotalPaidAndUnpaidAmountsAction = (
  dates,
  callback
) => {
  let requestString = dates.fd + "&td=" + dates.td;
  callAPI(
    "getTotalPaidAndUnpaidAmounts",
    API.getTotalPaidAndUnpaidAmounts.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};
export const generateReportAction = (
  data,
  downloadFile,
  callback
) => {
  callAPI(
    "generateReport",
    API.generateReport,
    "POST",
    data,
    (apiResponse) => {
      callback(apiResponse);
    },
    downloadFile
  );
};


// Strings
import { Strings } from "../../resources/i18n/i18n";

export const CLIENT_PORTAL_COLUMNS = [
    {
        key: "identificationType",
        name: Strings("identificationType"),
        minWidth: 100,
    },
    {
        key: "identificationNumber",
        name: Strings("identificationNumber"),
        minWidth: 100,
    },
    {
        key: "clientId",
        name: Strings("clientId"),
        minWidth: 80,
    },
    {
        key: "customerName",
        name: Strings("customerName"),
        minWidth: 100,
    },
    {
        key: "minimartDetails",
        name: Strings("MatricesID"),
        minWidth: 80,
    },
    {
        key: "isClientBlacklisted",
        name: Strings("clientBlacklisted"),
        minWidth: 80,
    },
];

export const CLIENT_STATE = {
    blacklisted: "BLACKLISTED",
    deactivated: "DEACTIVATED",
    blacklistedAndDeactivated: "BL_DEACT",
    other: "OTHER"
}
import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import CensusWorkloadManagement from "../CensusWorkloadManagement/CensusWorkloadManagement";
import withHOC from "../../HOC/HOC";

//Actions
import {
  getUserListAction,
  getWorkloadUserListAction,
  getPositionListAction,
  getZoneListAction,
  getAssignedUserListAction,
  saveAssignmentAction,
} from "./Actions";
import { setSearchFilterAction } from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import {
  SEARCH_FILTER_PAGES,
  MINIMART_LIST_FILTER,
  WORKLOAD_MANAGEMENT_FILTER,
} from "../../constants/appConstants";
import { USER_ROLES } from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

class CensusWorkloadManagementContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      errorMessage: props.errorMessage,
      showToast: false,
      level: "",
      userList: [],
      numberOfPages: 1,
      workLoadUserList: [],
      positionList: [],
      zoneList: [],
      mZoneList: [],
      filters: null,
    };
    this.isRowClick = false;
  }

  componentDidMount() {
    this.props.getBranchList();
    this.getPositionList({ branch: "", role: "" });
    this.getZoneList("");
    this.getUserList({
      branch: "",
      zone: "",
      mzone: "",
      position: "",
      role: "",
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  componentWillUnmount() {
    if (!this.isRowClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT,
        WORKLOAD_MANAGEMENT_FILTER
      );
    }
  }

  /**
   * Seachfilter API handling
   * @param {*} request
   */
  handleSearchFilter = (request) => {
    this.setState({ showLoader: true, filters: { ...request } });
    getWorkloadUserListAction({ ...request }, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          workLoadUserList: response.data.data.rp,
          numberOfPages: response.data.data.np,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
          workLoadUserList: [],
          numberOfPages: 0,
        });
      }
    });
  };

  /**
   * Get active user list API handling
   * @param {*} request
   */
  getUserList = (request) => {
    this.setState({ showLoader: true });
    getUserListAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let userList = response.data.data.map((user) => {
          return { label: user.un, value: user.uid };
        });
        userList.unshift({
          value: "",
          label: Strings("username"),
        });
        this.setState({
          showLoader: false,
          userList,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
          userList: [],
        });
      }
    });
  };

  /**
   * Get active and inactive assign user API handling
   * @param {*} request
   * @param {*} successCallback
   * @param {*} errorCallback
   */
  getAssignUserList = (request, successCallback, errorCallback) => {
    this.setState({ showLoader: true });
    getAssignedUserListAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let userList = response.data.data.map((user) => {
          return {
            label: user.un,
            value: user.uid,
            ia: request.uid ? user.ia : false,
          };
        });
        if (request.role === "SALES_PERSON" && request.uid) {
          userList.unshift({
            value: "None",
            label: Strings("None"),
          });
        }
        userList.unshift({
          value: "",
          label: Strings("username"),
        });
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
          },
          () => {
            successCallback(userList);
          }
        );
      } else {
        this.setState(
          {
            errorMessage: response.error.message,
            showLoader: false,
          },
          () => {
            errorCallback(response.error.message);
          }
        );
      }
    });
  };

  /**
   * Get position list of branch API handling
   * @param {*} branchId
   */
  getPositionList = (request) => {
    this.setState({ showLoader: true });
    getPositionListAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let positionList = response.data.data.map((position) => {
          return { label: position.pstn, value: position.pstid };
        });
        positionList.unshift({
          value: "",
          label: Strings("SelectPosition"),
        });
        this.setState({
          showLoader: false,
          positionList,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
          positionList: [],
        });
      }
    });
  };

  /**
   * Get zone list of position API handling
   * @param {*} positionId
   */
  getZoneList = (positionId) => {
    this.setState({ showLoader: true });
    getZoneListAction(positionId, (response) => {
      //NOSONAR
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let zoneList = [];
        let mZoneList = [];
        response.data.data.forEach((element) => {
          element.zones.forEach((zone) => {
            zoneList.push({ name: zone });
          });
        });
        response.data.data.forEach((element) => {
          element.mzones.forEach((zone) => {
            mZoneList.push({ name: zone });
          });
        });
        zoneList.sort((zoneFirst, zoneSecond) =>
          zoneFirst.name > zoneSecond.name
            ? 1
            : zoneSecond.name > zoneFirst.name //NOSONAR
            ? -1
            : 0
        );
        mZoneList.sort((zoneFirst, zoneSecond) =>
          zoneFirst.name > zoneSecond.name
            ? 1
            : zoneSecond.name > zoneFirst.name //NOSONAR
            ? -1
            : 0
        );
        zoneList.unshift({ name: Strings("Zone") });
        mZoneList.unshift({ name: Strings("mzone") });
        this.setState({
          showLoader: false,
          zoneList,
          mZoneList,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
          zoneList: [],
          mZoneList: [],
        });
      }
    });
  };

  /**
   * Save workload managment assignment API handling
   * @param {*} request
   * @param {*} successCallBack
   */
  onSaveClick = (request, getUserListRequest, successCallBack) => {
    saveAssignmentAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
            toastMessage: "assignmentDoneSuccessfully",
            showToast: true,
            level: "success",
          },
          () => {
            this.handleSearchFilter(this.state.filters);
            getUserListRequest &&
              this.getUserList(getUserListRequest);
            successCallBack();
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  /**
   * Change route on minimart count click
   * @param {*} row
   */
  onMinimartCountClick = (row) => {
    this.isRowClick = true;
    let minimartSearchFilter = { ...MINIMART_LIST_FILTER };
    if (row.rl === USER_ROLES.salesPerson) {
      minimartSearchFilter.zones = row.zn;
    } else if (row.rl === USER_ROLES.messenger) {
      minimartSearchFilter.messengerZones = row.zn;
    }
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.MINIMART_LIST,
      minimartSearchFilter
    );
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT,
      {
        br: this.state.filters.search.bid,
        pid: this.state.filters.search.pstid,
        zn: this.state.filters.search.zn,
        mzn: this.state.filters.search.mzn,
        rl: this.state.filters.search.rl,
        uid: this.state.filters.search.uid,
        limit: this.state.filters.limit,
        offset: this.state.filters.offset,
      },
      SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT
    );
    this.props.history.push(ROUTES.MINIMART_MANAGEMENT);
  };

  render() {
    return (
      <div>
        {this.state.showToast && (
          <UserNotification
            userNotificationObj={{
              message: Strings(this.state.toastMessage),
              level: this.state.level,
            }}
          />
        )}
        {this.state.showLoader && (
          <LoadingModal showLoadingModal={this.state.showLoader} />
        )}
        <Row className='heading'>{Strings("WorkLoadManagement")}</Row>
        <CensusWorkloadManagement
          branches={this.props.branches}
          getBranchList={this.props.getBranchList}
          numberOfPages={this.state.numberOfPages}
          rows={this.state.workLoadUserList}
          userList={this.state.userList}
          getUserList={this.getUserList}
          handleSearchFilter={this.handleSearchFilter}
          positionList={this.state.positionList}
          getPositionList={this.getPositionList}
          onSaveClick={this.onSaveClick}
          getZoneList={this.getZoneList}
          zoneList={this.state.zoneList}
          mZoneList={this.state.mZoneList}
          getAssignUserList={this.getAssignUserList}
          onMinimartCountClick={this.onMinimartCountClick}
          searchFilter={this.props.searchFilter}
          errorMessage={this.state.errorMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchFilter:
    state.searchFilterReducer.workloadManagementSearchFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setSearchFilterAction }, dispatch);
};

const CensusWorkloadManagementWrapper = withHOC(
  CensusWorkloadManagementContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CensusWorkloadManagementWrapper);

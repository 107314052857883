import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// String
import { Strings } from "../../resources/i18n/i18n";

// Components
import LoanHistory from "./LoanHistory";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import Utility from "../../utils/Utility";

// Action
import { getLoansHistoryBySearchFilterAction } from "../LoanHistory/LoanHistoryAction";
import { setAnalystLoanDetailsIDAction } from "../LoanDetails/LoanDetailsAction";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import { setUserAction } from "../UserList/UserListAction";
import { setSearchFilterAction } from "../../constants/Action";

// Constants
import {
  USER_ROLES,
  SEARCH_FILTER_PAGES,
  LOAN_HISTORY_FILTER,
} from "../../constants/appConstants";
import { DEFAULT_PAGINATION_RECORDS_COUNT } from "../CustomUIComponents/Pagination/PaginationConstants";
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";

// Styles
import { Row } from "react-bootstrap";

class LoanHistoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      isHistory: true,
      numberOfPages: DEFAULT_PAGINATION_RECORDS_COUNT,
      showLoadingModal: false,
      isAccountsOfficer:
        props.loggedInUser.role === USER_ROLES.accountsOfficer,
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    (this.props.loggedInUser.role === USER_ROLES.analystModerator ||
      this.props.loggedInUser.role ===
      USER_ROLES.operationsModerator) &&
      this.getUserList({
        filterRole:
          this.props.loggedInUser.role === USER_ROLES.analystModerator
            ? USER_ROLES.analyst
            : USER_ROLES.operationsOfficer,
      });
    this.props.clearDailyStatusDateAction();
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_HISTORY,
        { ...LOAN_HISTORY_FILTER }
      );
    }
  }

  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
      searchFilter: {
        mid: searchFilterObject.search.matricesId,
        code: searchFilterObject.search.minimartCode,
        appid: searchFilterObject.search.applicationId,
        mn: searchFilterObject.search.minimartName,
        fd: searchFilterObject.search.fromDate,
        td: searchFilterObject.search.toDate,
        an: searchFilterObject.search.userName,
        st: searchFilterObject.search.status,
        ty: searchFilterObject.search.loanType,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    let isAnalyst =
      this.props.loggedInUser.role === USER_ROLES.analyst;
    let isOperationsOfficer =
      this.props.loggedInUser.role === USER_ROLES.operationsOfficer;
    let isOperationsModerator =
      this.props.loggedInUser.role === USER_ROLES.operationsModerator;
    let isAnalystModerator =
      this.props.loggedInUser.role === USER_ROLES.analystModerator;
    let isAuditor =
      this.props.loggedInUser.role === USER_ROLES.auditor;
    let isCustomerService =
      this.props.loggedInUser.role === USER_ROLES.customerService;
    let isOperationsManager =
      this.props.loggedInUser.role === USER_ROLES.operationsManager;
    let issalesManager =
      this.props.loggedInUser.role === USER_ROLES.salesManager;
    getLoansHistoryBySearchFilterAction(
      searchFilterObject,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (response.data.data.loans === null) {
            this.setState({
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
              loans: response.data.data.loansAnalystModerator,
            });
          } else {
            this.setState({
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
              loans: response.data.data.loans,
            });
          }
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoadingModal: false,
          });
        }
      },
      isAnalyst,
      isOperationsOfficer,
      isOperationsModerator,
      this.state.isAccountsOfficer,
      isAnalystModerator,
      isAuditor,
      isCustomerService,
      isOperationsManager,
      issalesManager,
    );
  };

  setLoanDetailsID = (loanDetails) => {
    this.setState({ showLoadingModal: true });
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.LOAN_HISTORY,
      this.state.searchFilter,
      SEARCH_FILTER_PAGES.LOAN_HISTORY
    );
    this.props.setAnalystLoanDetailsIDAction(loanDetails, true);
    this.props.loggedInUser.role !== USER_ROLES.analyst &&
      this.props.loggedInUser.role !== USER_ROLES.analystModerator &&
      this.props.loggedInUser.role !== USER_ROLES.operationsOfficer &&
      this.props.loggedInUser.role !== USER_ROLES.operationsModerator &&
      this.props.loggedInUser.role !== USER_ROLES.auditor &&
      this.props.loggedInUser.role !== USER_ROLES.customerService &&
      this.props.loggedInUser.role !== USER_ROLES.operationsManager &&
      this.props.loggedInUser.role !== USER_ROLES.salesManager &&
      !this.state.isAccountsOfficer
      ? this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS)
      : this.props.history.push({
        pathname: ROUTES.ANALYST_LOAN_VIEW,
        state: { isHistory: true },
      });
  };

  /**
   * This function will give analyst names
   * @param {searchFilterObject} searchFilterObject
   */
  getUserList = (searchFilterObject) => {
    this.setState({ showLoader: true });
    const stateObject = Utility.getUserList(
      searchFilterObject,
      this.props.setUserAction
    );
    this.setState(stateObject);
  };

  render() {
    let userList = [];
    if (
      this.props.loggedInUser.role === USER_ROLES.analystModerator ||
      this.props.loggedInUser.role === USER_ROLES.operationsModerator
    ) {
      userList = Utility.getAllUserList(
        this.props.users,
        this.props.loggedInUser.role === USER_ROLES.analystModerator
          ? Strings("analystName")
          : Strings("operationOfficerName")
      );
    }
    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        <Row className='heading'>{Strings("loanHistory")} </Row>
        <LoanHistory
          loans={this.state.loans}
          numberOfPages={this.state.numberOfPages}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          setLoanDetailsID={this.setLoanDetailsID}
          loggedInUser={this.props.loggedInUser}
          isHistory={this.state.isHistory}
          userList={userList}
          searchFilter={this.props.searchFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
  users: state.userListReducer.users.users,
  searchFilter: state.searchFilterReducer.loanHistorySearchFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setAnalystLoanDetailsIDAction,
      clearDailyStatusDateAction,
      setUserAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanHistoryContainer);

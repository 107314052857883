import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import {
  SET_APPROVEMINIMART_TAB,
  SET_REMINDER_MINIMART_COUNT,
} from "../../redux/constants";

export const getMinimartRequestAction = (requestData, callback) => {
  callAPI(
    "getMinimartRequests",
    API.getMinimartApprovalRequests,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getMinimartReminderAction = (requestData, callback) => {
  callAPI(
    "getMinimartReminders",
    API.getMinimartApprovalReminders,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

//Get list of new CI minimarts
export const getCIMinimartRequestAction = (requestData, callback) => {
  requestData.oft -= 1;
  callAPI(
    "getCIApprovalMinimarts",
    API.getCIApprovalMinimarts,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

//Get details for pop up modal when CI minimart card is clicked
export const getCIMinimartDetailsAction = (requestData, callback) => {
  callAPI(
    "setCIMinimartApproval",
    API.setCIMinimartApproval,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

//Select branch and approve CI minimart
export const approveCIMinimartAction = (requestData, callback) => {
  callAPI(
    "setCIMinimartApproval",
    API.setCIMinimartApproval,
    "PATCH",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAssignedMinimartUserListAction = (
  requestData,
  callback
  ) => {
  callAPI(
    "getAssignedMinimartUserList",
    API.getAssignedMinimartUserList.concat(
      "br=" +
      requestData.br +
      "&zn=" +
      requestData.zn +
      "&mzn=" +
      requestData.mzn +
      "&vd=" +
      requestData.vd
    ),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const approvalMinimartTabAction = (index) => (dispatch) => {
  dispatch({ type: SET_APPROVEMINIMART_TAB, index: index });
};

export const setReminderMinimartCount = (count) => (dispatch) => {
  dispatch({ type: SET_REMINDER_MINIMART_COUNT, count: count });
};

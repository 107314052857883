// Constants
import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getConfigurationBySearchFilterAction = (
  requestDataObj,

  callback
) => {
  let requestData = {
    type: requestDataObj.type,
    version: requestDataObj.version
      ? requestDataObj.version
      : undefined,
    state: requestDataObj.status ? requestDataObj.status : undefined,
    limit: requestDataObj.limit ? requestDataObj.limit : undefined,
    offset: requestDataObj.offset - 1,
  };
  callAPI(
    "getConfigurationBySearchFilter",
    API.getConfigurationBySearchFilter,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

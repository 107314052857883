import { Strings } from "../../resources/i18n/i18n";

export const REMITTANCE_TABLE_FIELDS = [
  {
    key: "rid",
    name: Strings("remittanceID"),
    minWidth: 100,
  },
  {
    key: "amt",
    name: Strings("remittanceAmount"),
    minWidth: 150,
  },
  {
    key: "rs",
    name: Strings("remittanceStatus"),
    minWidth: 150,
  },
  {
    key: "mid",
    name: Strings("MatricesID"),
    minWidth: 100,
  },
  {
    key: "aid",
    name: Strings("applicationId"),
    minWidth: 100,
  },
  {
    key: "rdr",
    name: Strings("remittanceDeliveryReceipt"),
    minWidth: 150,
  },
  {
    key: "ps",
    name: Strings("paymentStatus"),
    minWidth: 150,
  },
  {
    key: "rrd",
    name: Strings("remittanceRaisedDate"),
    minWidth: 150,
  },
  {
    key: "rdd",
    name: Strings("remittanceDeliveredDate"),
    minWidth: 150,
  },
];

export const REMITTANCE_STATUS = [
  {
    key: 0,
    value: "",
    label: Strings("selectRemittanceStatus"),
  },
  {
    key: 1,
    value: "IN_PROCESS",
    label: Strings("IN_PROCESS"),
  },
  {
    key: 2,
    value: "IN_ROUTE",
    label: Strings("IN_ROUTE"),
  },
  {
    key: 3,
    value: "FAILED",
    label: Strings("FAILED"),
  },
  {
    key: 4,
    value: "CANCELLED",
    label: Strings("CANCELLED"),
  },
  {
    key: 5,
    value: "DELIVERED",
    label: Strings("DELIVERED"),
  },
];

export const PAYMENT_STATUS = [
  {
    key: 0,
    value: "UNPAID",
    label: Strings("UNPAID"),
  },
  {
    key: 1,
    value: "PAID",
    label: Strings("PAID"),
  },
];

export const DOWNLOAD_URL =
  "/remittance/payment/list/download?fileType=";

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// String
import { Strings } from "../../resources/i18n/i18n";

// Components
import LoanList from "./LoanList";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import withHOC from "../../HOC/HOC";

// Action
import { getLoansBySearchFilterAction } from "../MinimartDetails/MiniMartDetailsAction";
import { setLoanDetailsIDAction } from "../LoanDetails/LoanDetailsAction";
import { getLoanInitiatedFromAction } from "../LoanDetails/LoanDetailsAction";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import {
  setUserAction,
  getUserByBranch,
} from "../UserList/UserListAction";
import { setSearchFilterAction } from "../../constants/Action";

// Constants
import {
  USER_ROLES,
  SEARCH_FILTER_PAGES,
  LOAN_PORTFOLIO_FILTER,
} from "../../constants/appConstants";
import { DEFAULT_PAGINATION_RECORDS_COUNT } from "../CustomUIComponents/Pagination/PaginationConstants";
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";

// Styles
import { Row } from "react-bootstrap";
import "./LoanList.scss";
import { getClientStatesAction } from "../ClientPortal/ClientPortalAction";

class LoanListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      numberOfPages: DEFAULT_PAGINATION_RECORDS_COUNT,
      showLoadingModal: false,
      isAccountsOfficer:
        props.loggedInUser.role === USER_ROLES.accountsOfficer,
      users: [],
      clientStates: ["selectClientState"],
      loanInitiatedFrom: ["loanFrom"],
      errorMessage: props.errorMessage,
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.props.clearDailyStatusDateAction();
    this.props.getBranchList();
    this.getUserList(USER_ROLES.salesPerson);
    this.getClientStates();
    this.getLoanInitiatedFrom();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_PORTFOLIO,
        { ...LOAN_PORTFOLIO_FILTER }
      );
    }
  }

  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
      searchFilter: {
        mid: searchFilterObject.search.matricesId,
        code: searchFilterObject.search.minimartCode,
        appid: searchFilterObject.search.applicationId,
        lid: searchFilterObject.search.loanId,
        fd: searchFilterObject.search.fromDate,
        td: searchFilterObject.search.toDate,
        mn: searchFilterObject.search.minimartName,
        ty: searchFilterObject.search.loanType,
        st: searchFilterObject.search.status,
        ct: searchFilterObject.search.category,
        br: searchFilterObject.search.branch,
        sun: searchFilterObject.search.salesPerson,
        cct: searchFilterObject.search.conciliationType,
        at: searchFilterObject.search.approvalType,
        cs: searchFilterObject.search.clientState,
        lf: searchFilterObject.search.loanFrom,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    getLoansBySearchFilterAction(searchFilterObject, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (response.data.data.loans.length === 0) {
          this.setState({
            loans: [],
            numberOfPages: response.data.data.numberOfPages,
            showLoadingModal: false,
            errorMessage: "",
          });
        } else {
          this.setState({
            loans: response.data.data.loans,
            numberOfPages: response.data.data.numberOfPages,
            showLoadingModal: false,
            errorMessage: "",
          });
        }
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
        });
      }
    });
  };

  setLoanDetailsID = (loanDetails) => {
    this.setState({ showLoadingModal: true });
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.LOAN_PORTFOLIO,
      this.state.searchFilter,
      SEARCH_FILTER_PAGES.LOAN_PORTFOLIO
    );
    this.props.setLoanDetailsIDAction(loanDetails);
    this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
  };

  getUserList = (role, branchId = "") => {
    let users = [];
    const request = {
      role: role,
      branchId: branchId,
    };
    this.setState({ showLoader: true });
    getUserByBranch(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        response.data.data.forEach((object) => {
          object.us.forEach((user) => {
            user.value = user.userId;
            let count = users.reduce(function (n, val) {
              return n + (val.name === user.name);
            }, 0);
            if (count === 0) users.push(user);
          });
        });

        this.setState({
          users,

          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  getClientStates = () => {
    this.setState({
      showLoader: true,
    });
    getClientStatesAction((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          clientStates: [...this.state.clientStates, ...response.data.data.clientState],
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
        });
      }
    });
  }
  getLoanInitiatedFrom = () => {
    this.setState({
      showLoader: true,
    });
    getLoanInitiatedFromAction((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          loanInitiatedFrom: [...this.state.loanInitiatedFrom, ...response.data.data.initiatedFrom],
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
        });
      }
    });
  }
  render() {
    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        <Row className='heading'>
          {Strings("LoanPortFolioManagement")}{" "}
        </Row>
        <LoanList
          loans={this.state.loans}
          numberOfPages={this.state.numberOfPages}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          setLoanDetailsID={this.setLoanDetailsID}
          loggedInUser={this.props.loggedInUser}
          branches={this.props.branches}
          userList={this.state.users}
          getUserList={this.getUserList}
          searchFilter={this.props.loanPortfolioSearchFilter}
          clientStates={this.state.clientStates}
          loanInitiatedFrom={this.state.loanInitiatedFrom}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); //NOSONAR

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoanDetailsIDAction,
      clearDailyStatusDateAction,
      setUserAction,
      getUserByBranch,
      setSearchFilterAction,
    },
    dispatch
  );
};

const LoanListWrapper = withHOC(LoanListContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanListWrapper);

import React from "react";
import { Row, Col } from "react-bootstrap";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import "./MultiCheckBoxAreaComponent.scss";

class MultiCheckBoxAreaComponent extends React.Component {
  renderCheckBoxComponent = (branch) => {
    let selectedBranch = branch.id ? branch.id : branch;
    let keyId = Math.random() + branch;
    return (
      <Col
        md={6}
        key={keyId}
        title={branch.name ? branch.name : branch}
      >
        <CheckboxComponent
          label={branch.name ? branch.name : branch}
          onChange={
            !this.props.disabled
              ? () => this.props.handleCheckboxChange(selectedBranch)
              : () => {
                  //This is intensional
                }
          }
          labelClass={this.props.className}
          checked={
            this.props.selectedValues
              ? this.props.selectedValues?.includes(selectedBranch)
              : false
          }
          disabled={this.props.disabled}
          title={branch.name ? branch.name : branch}
        />
      </Col>
    );
  };

  render() {
    return (
      <div className='multiCheckBoxAreaComponent'>
        <Row>
          <div className='textAreaCheckBox'>
            {this.props.branches &&
              this.props.branches.length > 0 && (
                <Row
                  className={
                    this.props.disabled ? " cursorDisabled " : ""
                  }
                >
                  {this.props.branches.map((branch, index) => {
                    return this.renderCheckBoxComponent(branch);
                  })}
                </Row>
              )}
          </div>
        </Row>
      </div>
    );
  }
}

export default MultiCheckBoxAreaComponent;

import React, { Component } from "react";
import Spinner from 'react-bootstrap/Spinner';

import "./LoadingModal.scss";

export default class LoadingModal extends Component {
  render() {
    return (
      this.props.showLoadingModal && (
        <div className="loader-container">
          <Spinner animation="border" role="status" variant="primary" >
            <span className="sr-only">Loading...</span>
          </Spinner></div>
      )
    );
  }
}

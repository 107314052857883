import React from "react";
import { Col, Row } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import _ from "lodash";

// Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";

// Constants
import {
  GET_DEFAULT_DROPDOWN_SELECTION,
  DEFAULT_USER_SELECTION,
  GET_DEFAULT_BRANCH_SELECTION,
  DEFAULT_BRANCH_SELECTION,
  USER_STATUS,
  USER_AVAILABILITY_STATUS,
} from "./UserListConstants";
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  GET_DEFAULT_ZONE_SELECTION,
  DEFAULT_ZONE,
  DEFAULT_MESSENGER_ZONE,
  GET_DEFAULT_MESSENGER_ZONE_SELECTION,
} from "../BranchList/BranchListConstants";
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  USER_ROLES,
  DEFAULT_VALUES,
} from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./UserList.scss";


class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchUsername: props.searchFilter.un,
      filterRole:
        this.props.loggedInUser.role ===
          USER_ROLES.operationsModerator
          ? "OPERATIONS_OFFICER"
          : props.searchFilter.rl,
      filterBranch: props.searchFilter.br,
      zone: props.searchFilter.zn,
      mZone: props.searchFilter.mzn,
      showLoader: false,
      userRoles: props.userRoles,
      branches: props.branches,
      recordsPerPage: props.searchFilter.limit,
      activePage: props.searchFilter.offset,
      zones: props.zones,
      mZones: props.mZones,
      active: props.searchFilter.active,
      available: props.searchFilter.available,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let derivedState = { ...state };

    if (props.userRoles !== state.userRoles) {
      if (
        state.userRoles &&
        state.userRoles[0].id !== DEFAULT_USER_SELECTION
      ) {
        let userRolesDropdown = props.userRoles;
        userRolesDropdown.unshift(GET_DEFAULT_DROPDOWN_SELECTION());
        derivedState = {
          ...derivedState,
          userRoles: userRolesDropdown,
        };
      }
    }
    return derivedState;
  }

  componentDidMount() {
    this.props.getZonesByBranch();
    this.props.getUserList({
      searchUsername: this.state.searchUsername
        ? this.state.searchUsername
        : undefined,
      filterRole:
        this.state.filterRole !==
          GET_DEFAULT_DROPDOWN_SELECTION().role
          ? this.state.filterRole
          : undefined,
      branchId:
        this.state.filterBranch !== GET_DEFAULT_BRANCH_SELECTION().id
          ? this.state.filterBranch
          : undefined,
      zone:
        this.state.zone !== GET_DEFAULT_ZONE_SELECTION().id
          ? this.state.zone
          : undefined,
      mZone:
        this.state.mZone !== GET_DEFAULT_MESSENGER_ZONE_SELECTION().id
          ? this.state.mZone
          : undefined,
      mZones:
        this.state.mZones !== GET_DEFAULT_ZONE_SELECTION().id
          ? this.state.mZones
          : undefined,
      active: this.state.active !== ""
        ? this.state.active
        : undefined,
      available: this.state.available !== ""
        ? this.state.available
        : undefined,
      limit: this.state.recordsPerPage,
      offset: this.state.activePage,
    });
    if (
      this.state.userRoles &&
      this.state.userRoles[0].id !== DEFAULT_USER_SELECTION
    ) {
      let userRolesDropdown = this.props.userRoles;
      userRolesDropdown.unshift(GET_DEFAULT_DROPDOWN_SELECTION());
      this.setState({ userRoles: userRolesDropdown });
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }
  }

  /**
   * This function will be called on change of user name in search
   */
  handleOnChangeSearchName = (event) => {
    this.setState({
      searchUsername: event.target.value,
    });
  };


  handleOnChangeFilterBranch = (event) => {
    let value =
      event.target.value !== DEFAULT_BRANCH_SELECTION
        ? event.target.value
        : "";

    this.setState(
      {
        filterBranch: value,
        zone: "",
        mZone: "",
      },
      () => {
        this.props.getZonesByBranch(this.state.filterBranch);
      }
    );
  };

  handleSelectionChange = (event) => {
    let value =
      !DEFAULT_VALUES.includes(event.target.value)
        ? event.target.value
        : "";
    let name = event.target.name;
    if (name === "active" && value === "false") {
      this.setState({ available: "" })
    }
    this.setState({ [name]: value });
  }

  /**
   * This function will be called on clicked of filter/search
   */
  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleOnClickSearchFilter(
      {
        searchUsername: this.state.searchUsername
          ? this.state.searchUsername
          : undefined,
        filterRole:
          this.state.filterRole !==
            GET_DEFAULT_DROPDOWN_SELECTION().role
            ? this.state.filterRole
            : undefined,
        branchId:
          this.state.filterBranch !==
            GET_DEFAULT_BRANCH_SELECTION().id
            ? this.state.filterBranch
            : undefined,
        zone:
          this.state.zone !== DEFAULT_ZONE && this.state.zone !== ""
            ? this.state.zone
            : undefined,
        messengerZone:
          this.state.mZone !== DEFAULT_MESSENGER_ZONE &&
            this.state.mZone !== ""
            ? this.state.mZone
            : undefined,
        active:
          this.state.active !== ""
            ? this.state.active
            : undefined,
        available:
          this.state.available !== ""
            ? this.state.available
            : undefined,
        limit: this.state.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.activePage,
      }
    );
  };

  /**
   * This function will be called on reset button for filter
   */
  handleOnClickResetFilter = () => {
    this.setState(
      {
        filterRole: GET_DEFAULT_DROPDOWN_SELECTION().role,
        filterBranch: "",
        searchUsername: "",
        zone: "",
        mZone: "",
        active: "",
        available: "",
      },
      () => {
        this.props.getZonesByBranch();
        this.handleOnClickSearchFilter(true, true);
      }
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        recordsPerPage: pageLength,
        activePage: page,
      },
      () => {
        this.handleOnClickSearchFilter(false);
      }
    );
  };

  renderSearchFilter = () => {
    let DEFAULT_DROPDOWN_SELECTION = GET_DEFAULT_DROPDOWN_SELECTION();
    let userRoles =
      this.state.userRoles &&
      this.state.userRoles.map((singleUser) => {
        return singleUser.id === DEFAULT_USER_SELECTION
          ? {
            label: DEFAULT_DROPDOWN_SELECTION.role,
            value: DEFAULT_DROPDOWN_SELECTION.id,
          }
          : {
            label: Strings(singleUser.role),
            value: singleUser.role,
          };
      });
    let branches =
      this.state.branches?.map((singleBranch) => {
        return {
          label: singleBranch.name,
          value: singleBranch.id,
        };
      });
    let zones =
      this.state.zones &&
      this.state.zones.map((singleZone) => {
        return singleZone.name === "Zona"
          ? {
            label: GET_DEFAULT_ZONE_SELECTION().name,
            name: GET_DEFAULT_ZONE_SELECTION().name,
          }
          : {
            label: singleZone.name,
            name: singleZone.name,
          };
      });

    let mZones =
      this.state.mZones?.map((singleZone) => {
        return singleZone.name === "Zona"
          ? {
            label: GET_DEFAULT_ZONE_SELECTION().name,
            name: GET_DEFAULT_ZONE_SELECTION().name,
          }
          : {
            label: singleZone.name,
            name: singleZone.name,
          };
      });

    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("username"),
        handleOnChange: this.handleOnChangeSearchName,
        Value: this.state.searchUsername,
        fieldname: SEARCH_FILTER_FIELDS.USER_NAME,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeFilterBranch,
        Value: this.state.filterBranch,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: branches,
        disabled:
          this.props.loggedInUser.role ===
          USER_ROLES.operationsModerator,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSelectionChange,
        Value: this.state.zone,
        fieldname: SEARCH_FILTER_FIELDS.ZONE,
        name: "zone",
        filterDropdownList: zones,
        disabled:
          this.props.loggedInUser.role ===
          USER_ROLES.operationsModerator,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSelectionChange,
        Value: this.state.mZone,
        fieldname: SEARCH_FILTER_FIELDS.MZONE,
        name: "mZone",
        filterDropdownList: mZones,
        disabled:
          this.props.loggedInUser.role ===
          USER_ROLES.operationsModerator,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSelectionChange,
        Value: this.state.filterRole,
        fieldname: SEARCH_FILTER_FIELDS.ROLE,
        filterDropdownList: userRoles,
        name: "filterRole",
        disabled:
          this.props.loggedInUser.role ===
          USER_ROLES.operationsModerator,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSelectionChange,
        Value: this.state.active,
        fieldname: SEARCH_FILTER_FIELDS.ACTIVE,
        name: "active",
        filterDropdownList: USER_STATUS,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSelectionChange,
        Value: this.state.available,
        fieldname: SEARCH_FILTER_FIELDS.AVAILABLE,
        name: "available",
        filterDropdownList: USER_AVAILABILITY_STATUS,
        paddingTop: true,
        disabled: this.state.active === "false"
      },
    ];
    return (
      <SearchFilter
        filters={searchFilters}
        addButton={true}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleAddUser={this.props.handleAddUser}
        buttonText={Strings("AddUser")}
        buttonWidth={12}
        paddingLeft={3}
        paddingTop={2}
        removeLeftPadding={true}
      />
    );
  };

  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer
          component={Paper}
          className='d-flex justify-content-center'
        >
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key === "rating"
                          ? "text-center px-2 header"
                          : "px-2 header"
                      }
                      title={column.name}
                    >
                      <div
                        className={
                          column.key === "branch" ||
                            column.key === "zone"
                            ? "tableCell"
                            : ""
                        }
                      >
                        {column.name}
                      </div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.userPreservedData.id}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => callBack(row)}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"px-2"}
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ minWidth: column.minWidth }}
                    >
                      <div
                        className={
                          column.key === "branch" ||
                            column.key === "zone"
                            ? "tableCell"
                            : ""
                        }
                      >
                        {row[column.key]}
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    return (
      <div className='userList'>
        <Row>
          <span className='heading'>
            {" "}
            {Strings("UserManagement")}
          </span>
        </Row>
        <div className='ListContainer'>
          {this.renderSearchFilter()}
          <Row>
            <Col md={12} className=' errorText'>
              {this.state.errorMessage}
            </Col>
          </Row>
          {/* Library component to display grid. */}

          {this.props.rowsCount !== 0 ? (
            <div className='userListTable mt-4'>
              {this.renderCustomDataGrid(
                this.props.columns,
                this.props.rows,
                this.props.onRowClick
              )}
              <Col md={12} className='noPadding alignPagination'>
                <Pagination
                  activePage={this.state.activePage}
                  recordsPerPage={this.state.recordsPerPage}
                  numberOfPages={this.props.numberOfPages}
                  onPageChange={this.onPageChange}
                  dropup={true}
                />
              </Col>
            </div>
          ) : (
            <div className='noRecordsFound'>
              {Strings("NoRecordsFound")}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default UserList;

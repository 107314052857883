import React from "react";
import { Row } from "react-bootstrap";
import { Strings } from "../../resources/i18n/i18n";

import "./UpComing.scss";

class UpComing extends React.Component {
  render() {
    return (
      <div className='upComing'>
        <Row>{Strings("ComingSoon")}</Row>
      </div>
    );
  }
}

export default UpComing;

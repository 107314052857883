import React, { Component } from "react";

import { Strings } from "../../../resources/i18n/i18n";

import "./InputModal.scss";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

import Utility from "../../../utils/Utility";
import { Col } from "react-bootstrap";

import { WEIGHT, MODAL_TYPE } from "../../../constants/appConstants";

import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";

class InputModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessage: null,
      version: props.confVersion,
    };
  }

  //Change visibility of modal according to props
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {
        open: nextProps.open,
        errorMessage: null,
        value: null,
      };
    }
    return {};
  }

  //to close modal
  closeModal = () => {
    this.props.toggleModal();
  };

  handleUserInput = (event) => {
    if (this.props.type === MODAL_TYPE.VERSION) {
      this.setState({
        [event.target.name]: event.target.value,
        errorMessage: null,
      });
    } else {
      this.setState({
        value: event.target.value.trim(),
        errorMessage: null,
      });
    }
  };

  allowIfNumber = (event) => {
    if (!Utility.checkIfNumber(event)) {
      event.preventDefault();
    }
  };

  allowIfFloat = (event) => {
    if (!Utility.checkIfFloat(event)) {
      event.preventDefault();
    }
  };

  handleVersionChange = () => {
    if (!this.state.version || this.state.version.length > 10) {
      this.setState({
        errorMessage: Strings("ErrorVersionCannotBeEmpty"),
      });
    } else {
      this.props.handleChangeValue(
        this.props.type,
        this.state.version,
        this.state.description
      );
    }
  };

  renderAddType = () => {
    if (!this.state.value || this.state.value.length > 100) {
      this.setState({
        errorMessage: Strings("errorInvalidTextValue"),
      });
    } else {
      this.props.handleChangeValue(this.props.type, this.state.value);
    }
  };

  renderWeightChange = () => {
    let value = parseInt(this.state.value, 10);
    if (value <= 100 && value >= 0) {
      this.props.handleChangeValue(this.props.type, this.state.value);
    } else {
      this.setState({
        errorMessage: Strings("errorInvalidValue"),
      });
    }
  };
  renderInterestChange = () => {
    let value = parseFloat(parseFloat(this.state.value).toFixed(2));
    if (value <= 100 && value >= 0) {
      this.props.handleChangeValue(this.props.type, this.state.value);
    } else {
      this.setState({
        errorMessage: Strings("errorInvalidValue"),
      });
    }
  };

  handleChangeValue = () => {
    //NOSONAR
    if (this.props.type === MODAL_TYPE.VERSION) {
      this.handleVersionChange();
    } else if (this.props.type === MODAL_TYPE.ADD_TYPE) {
      this.renderAddType();
    } else if (this.state.value) {
      if (this.props.type === WEIGHT) {
        this.renderWeightChange();
      } else if (this.props.type === MODAL_TYPE.INTEREST) {
        this.renderInterestChange();
      } else {
        if (
          this.state.value === "" ||
          this.state.value.length > 100
        ) {
          this.setState({
            errorMessage: Strings("errorInvalidTextValue"),
          });
        } else {
          this.props.handleChangeValue(
            this.props.type,
            this.state.value
          );
        }
      }
    } else if (
      this.props.type === MODAL_TYPE.INTEREST &&
      this.state.value === ""
    ) {
      this.setState({ errorMessage: Strings("errorInvalidValue") });
    } else if (
      this.props.type === MODAL_TYPE.SOURCE_OF_FINANCE &&
      (this.state.value === "" || this.state.value === null)
    ) {
      this.setState({ errorMessage: Strings("ErrorEmptyValue") });
    } else if (this.state.value === "" || this.state.value === null) {
      this.setState({ errorMessage: Strings("ErrorEmptyValue") });
    } else {
      this.props.handleChangeValue(
        this.props.type,
        this.props.modalValue
      );
    }
  };

  renderModalButtons = () => {
    return (
      <Col md={12}>
        <Button
          className='buttonBackground primaryModalButton buttonMinWidth'
          onClick={() => this.handleChangeValue()}
        >
          {Strings("Set")}
        </Button>

        <Button
          className={
            " primaryModalButton buttonMinWidth marginCancelButton buttonBorder"
          }
          onClick={this.closeModal}
        >
          {Strings("Cancel")}
        </Button>
      </Col>
    );
  };

  renderModalInputs = () => {
    if (this.props.type === WEIGHT) {
      return (
        <TextFieldComponent
          type='number'
          defaultValue={this.props.modalValue}
          min={0}
          max={100}
          handleChange={this.handleUserInput}
          onKeyDown={this.allowIfNumber}
          autoFocus
          className='modalInput'
          placeholder={this.props.placeHolder}
        />
      );
    } else if (this.props.type === MODAL_TYPE.VERSION) {
      return (
        <>
          <TextFieldComponent
            type='text'
            defaultValue={this.props.confVersion}
            handleChange={this.handleUserInput}
            onKeyDown={this.allowIfFloat}
            maxLength={10}
            multiple
            autoFocus
            className='modalInput'
            placeholder={"Version number *"}
            name='version'
          />
          <div className='inputFields'>
            <textarea
              className={"w-100"}
              defaultValue={this.props.confDescription}
              id='description'
              type='text'
              placeholder={Strings("Description")}
              maxLength={150}
              cols={45}
              rows={3}
              onChange={this.handleUserInput}
              name='description'
            />
          </div>
        </>
      );
    } else {
      return (
        <TextFieldComponent
          type='text'
          defaultValue={this.props.modalValue}
          handleChange={this.handleUserInput}
          maxLength={100}
          multiple
          autoFocus
          className='modalInput'
          placeholderTop={
            this.props.type === MODAL_TYPE.SOURCE_OF_FINANCE
              ? Strings("name")
              : ""
          }
          CustomTextField={
            this.props.type === MODAL_TYPE.SOURCE_OF_FINANCE
              ? MODAL_TYPE.SOURCE_OF_FINANCE
              : ""
          }
          placeholder={this.props.placeHolder}
        />
      );
    }
  };

  render() {
    return (
      <div>
        {/* general modal */}
        <Modal
          // Change visibility of modal based on props.
          open={this.state.open}
          onClose={this.closeModal}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          classNames={{ modal: "customModal" }}
          showCloseIcon={false}
          center
        >
          <Row className='inputModal'>
            <Col md={12} className='headerModal'>
              {this.props.modalHeader}
            </Col>
            <Col md={12} className='modalContent'>
              <Row className='userInput '>
                {this.state.errorMessage && (
                  <Col
                    md={12}
                    style={{ textAlign: "center" }}
                    className='errors'
                  >
                    {this.state.errorMessage}
                  </Col>
                )}
                <Col md={12}>
                  <div className='inputFields sourceOfFinance'>
                    {this.renderModalInputs()}
                  </div>
                </Col>
              </Row>

              <Row className='modalButton'>
                {this.renderModalButtons()}
              </Row>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

InputModal.propTypes = {
  modalHeader: PropTypes.string,
  toggleModal: PropTypes.func,
};

export default InputModal;

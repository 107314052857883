import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// Components
import Utility from "../../utils/Utility";
import CIWireTransfer from "../CIWireTransfer/CIWireTransfer";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

//Actions
import {
  getAllCITransferUnPaidRecordsAction,
  markInvoicesAsPaidAction,
  getTotalPaidAndUnpaidAmountsAction,
  generateReportAction,
} from "./CIWireTransferAction";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  DOWNLOAD_FILE_TYPE,
  CI_BANK_TRANSFER_INVOICE_TYPE,
} from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./CIWireTransfer.scss";

class CIWireTransferContainer extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);

    this.state = {
      unPaidUsers: [],
      paidUsers: [],
      showLoader: false,
      errorMessage: "",
      numberOfPages: 1,
      paidNumberofPages: 1,
      paidAmount: 0,
      unPaidAmount: 0,
      dummySearchObject: null,
      isError: false,
    };
  }

  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState(
      {
        showLoader: true,
        dummySearchObject: searchFilterObject,
      },
      () => {
        getTotalPaidAndUnpaidAmountsAction(
          this.state.dummySearchObject.sc,
          (Response) => {
            if (Response.status === OPERATION_STATUS.SUCCESS) {
              this.setState({
                paidAmount: Response.data.data.pAmt,
                unPaidAmount: Response.data.data.uAmt,
                showLoader: false,
              });
            } else {
              this.setState({
                errorMessage: Response.error.message,
                showLoader: false,
              });
            }
          }
        );
      }
    );
    getAllCITransferUnPaidRecordsAction(
      searchFilterObject,
      (response) => {
        this.setState({ showLoader: false });
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (
            searchFilterObject.sc.st ===
            CI_BANK_TRANSFER_INVOICE_TYPE.UNPAID
          ) {
            this.setState({
              unPaidUsers: response.data.data.list,
              numberOfPages: response.data.data.numberOfPages,
              showLoader: false,
            });
          } else {
            this.setState({
              paidUsers: response.data.data.list,
              paidNumberofPages: response.data.data.numberOfPages,
              showLoader: false,
            });
          }
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoader: false,
          });
        }
      }
    );
  };

  markInvoicesAsPaid = (InvoiceArray) => {
    this.setState({
      showLoader: true,
      isError: false,
      level: "",
      errorMessage: "",
    });
    markInvoicesAsPaidAction(InvoiceArray, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            errorMessage: "",
            showLoader: false,
          },
          () => {
            this.handleOnClickSearchFilter(
              this.state.dummySearchObject
            );
          }
        );
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
          isError: true,
          level: "error",
        });
      }
    });
  };
  generateReport = (fromDate, toDate, status) => {
    let generateReportFilter = {
      sc: {
        fd: fromDate,
        td: toDate,
        st: status,
      },
    };
    this.setState({ showLoader: true, isError: false });
    generateReportAction(generateReportFilter, true, (response) => {
      if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
        const url = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = url;
        const filename = `${Strings(status)}_${Strings(
          "ReportFileName"
        )}${Utility.getReportFormattedDateString(
          Utility.convertDateIntoMiliSeconds(new Date())
        )}${Utility.getFileType(DOWNLOAD_FILE_TYPE.XLS)}`; //or any other file extension
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        this.setState({
          errorMessage: "",
          showLoader: false,
          isError: false,
        });
        link.click();
      } else {
        this.setState({
          showLoader: false,
          isError: true,
          errorMessage: Strings("NoRecordsFound"),
        });
      }
    });
  };
  render() {
    const { unPaidUsers, numberOfPages, paidUsers } = this.state;
    return (
      <div className='ciwiretransferDetails'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isError && (
          <UserNotification
            key='Error'
            userNotificationObj={{
              message: this.state.errorMessage,
              level: "error",
            }}
          />
        )}
        <Row className='heading'>
          {Strings("CIWireTransferTitle")}
        </Row>
        <CIWireTransfer
          unPaidUsers={unPaidUsers}
          paidUsers={paidUsers}
          numberOfPages={numberOfPages}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          paidAmount={this.state.paidAmount}
          unPaidAmount={this.state.unPaidAmount}
          errorMessage={this.state.errorMessage}
          markInvoicesAsPaid={this.markInvoicesAsPaid}
          paidNumberofPages={this.state.paidNumberofPages}
          generateReport={this.generateReport}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); //NOSONAR

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CIWireTransferContainer);

import React from "react";

// Assets

// Styles
import "./ProductCatalog.scss";
import { Form } from "react-bootstrap";

const Chips = (props) => {
  const { onChipClick, content, chipId, className, disabled = false, toggle = false, title = "" } = props;
  return (
    <div className={className + " d-flex justify-content-between"}>
      <div className='chipContent' title={content}>
        {content}
      </div>
      {toggle
        ? <div onClick={props.disabled ? () => { } : () => onChipClick(chipId)}>
          <Form.Check
            checked={chipId.ia}
            type="switch"
            id={chipId.ai}
            title={title}
            disabled={props.disabled}
          />
        </div>
        : <i
          onClick={() => onChipClick(chipId)}
          className={disabled ? "" : 'closeChip fa fa-pencil'}
          alt='x'
        />}
    </div>
  );
};

export default Chips;

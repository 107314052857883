// Constants
import { API } from "../../constants/APIConstants";

import { callAPI } from "../../config/AxiosTemplate";
import {
  SET_SALESPERSON_EFFICIENCY_DATA,
  SET_SALESPERSON_SEARCHFILTER_DATA,
} from "../../redux/constants";

//replace with API call with date range
export const getSalespersonEfficiencyPiechartDataAction = (
  requestData,
  callback
) => {
  let requestString =
    "?fd=" + requestData.fromDate + "&td=" + requestData.toDate;
  if (requestData.name) {
    requestString += "&sn=" + requestData.name;
  }
  if (requestData.branchId) {
    requestString += "&brn=" + requestData.branchId;
  }
  if (requestData.zone) {
    requestString += "&zn=" + requestData.zone;
  }
  if (requestData.grade) {
    requestString += "&gr=" + requestData.grade;
  }
  callAPI(
    "getSalespersonEfficiencyPiechartData",
    API.getSalespersonEfficiencyPiechartData.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getSalespersonEfficiencyTableDataAction = (
  requestData,
  callback
) => {
  if (requestData.offset) {
    requestData.offset -= 1;
  }
  let requestString =
    "?fd=" +
    requestData.fromDate +
    "&td=" +
    requestData.toDate +
    "&limit=" +
    requestData.limit +
    "&offset=" +
    requestData.offset;
  if (requestData.name) {
    requestString += "&sn=" + requestData.name;
  }
  if (requestData.branchId) {
    requestString += "&brn=" + requestData.branchId;
  }
  if (requestData.zone) {
    requestString += "&zn=" + requestData.zone;
  }
  if (requestData.grade) {
    requestString += "&gr=" + requestData.grade;
  }
  callAPI(
    "getSalespersonEfficiencyTableData",
    API.getSalespersonEfficiencyTableData.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setSalesPersonFiltersAction =
  (searchFilter) => (dispatch) => {
    dispatch({
      type: SET_SALESPERSON_SEARCHFILTER_DATA,
      data: searchFilter,
    });
  };

export const setSalesPersonDataAction =
  (selectedSalespersonData) => (dispatch) => {
    dispatch({
      type: SET_SALESPERSON_EFFICIENCY_DATA,
      data: selectedSalespersonData,
    });
  };

// Constants
import {
  SET_MESSENGER_ACTION,
  SET_SALES__PERSON_ACTION,
  SET_DATE_FOR_SALES__PERSON_ACTION,
  SET_DATE_FOR_MESSENGER_ACTION,
  CLEAR_DAILYSTATUS_DATE,
} from "../../redux/constants";

const INITIAL_STATE = {
  salesPerson: {},
  messenger: null,
  salesPersonDate: "",
  messengerDate: "",
};

export default function DailyStatusReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SALES__PERSON_ACTION:
      return {
        ...state,
        salesPerson: action.salesPerson,
      };
    case SET_MESSENGER_ACTION:
      return {
        ...state,
        messenger: action.messenger,
      };
    case SET_DATE_FOR_SALES__PERSON_ACTION:
      return {
        ...state,
        salesPersonDate: action.salesPersonDate,
      };
    case SET_DATE_FOR_MESSENGER_ACTION:
      return {
        ...state,
        messengerDate: action.messengerDate,
      };
    case CLEAR_DAILYSTATUS_DATE:
      return {
        ...state,
        salesPersonDate: "",
        messengerDate: "",
      };
    default:
      return state;
  }
}

import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";

// String
import { Strings } from "../../resources/i18n/i18n";

// Component
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

// Constant
import {
  GENERAL_LOAN_STATUS_LEGEND,
  DEFAULT_LOAN_STATUS,
  DEFAULT_LOAN_STATUS_SELECTION,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  STATUS_FOR_LOANS,
  LOAN_TYPE,
  CATEGORY,
  DEFAULT_LOAN_TYPE,
  DEFAULT_CATEGORY_TYPE,
  LOAN_CATEGORY_LEGEND,
  USER_ROLES,
  DISBURSED,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DEFAULT_BRANCH_SELECTION,
  DEFAULT_SALES_PERSON,
  DEFAULT_SALES_PERSON_SELECTION,
  DEFAULT_CONCILIATION_TYPE,
  CONCILIATION_TYPE_LIST,
  APPROVAL_TYPE_LIST,
  DEFAULT_APPROVAL_TYPE,
  DEFAULT_VALUES,
  DEFAULT_LOAN_FROM
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

// Style
import "./LoanList.scss";

class LoanList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LoanFilters: {
        minimartCode: props.searchFilter.code,
        applicationId: props.searchFilter.appid,
        loanId: props.searchFilter.lid,
        matricesId: props.searchFilter.mid,
        minimartName: props.searchFilter.mn,
        status: props.searchFilter.st,
        category: props.searchFilter.ct,
        loanType: props.searchFilter.ty,
        branch: props.searchFilter.br,
        salesPerson: props.searchFilter.sun,
        fromDate: props.searchFilter.fd,
        toDate: props.searchFilter.td,
        conciliationType: props.searchFilter.cct,
        approvalType: props.searchFilter.at,
        clientState: props.searchFilter.cs,
        loanFrom: props.searchFilter.lf
      },
      branches: props.branches,
      userList: props.userList,
      numberOfPages: props.numberOfPages,
      activePage: props.searchFilter.offset,
      recordsPerPage: props.searchFilter.limit,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (!_.isEqual(prevProps.userList, this.props.userList)) {
      this.setState({ userList: this.props.userList });
    }
  }

  handleSearchCICodeChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        minimartCode: event.target.value,
      },
    });
  };

  handleSearchIdChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        applicationId: event.target.value,
      },
    });
  };

  handleSearchLoanIdChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        loanId: event.target.value,
      },
    });
  };

  handleSearchMatricesIdChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        matricesId: event.target.value,
      },
    });
  };

  handleSearchNameChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        minimartName: event.target.value,
      },
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.LoanFilters.toDate)) {
          DateValue = this.state.LoanFilters.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.LoanFilters.fromDate)) {
          DateValue =
            moment(dateMoment).diff(
              this.state.LoanFilters.fromDate,
              "days"
            ) === 0
              ? this.state.LoanFilters.fromDate
              : this.state.LoanFilters.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({
      LoanFilters: { ...this.state.LoanFilters, [key]: DateValue },
    });
  };

  handleChangeStatusFilter = (event) => {
    if (event.target.value !== DEFAULT_LOAN_STATUS_SELECTION.value) {
      this.setState({
        LoanFilters: {
          ...this.state.LoanFilters,
          status: event.target.value,
          category:
            event.target.value !== DISBURSED
              ? DEFAULT_CATEGORY_TYPE
              : this.state.LoanFilters.category,
        },
      });
    } else {
      this.setState({
        LoanFilters: {
          ...this.state.LoanFilters,
          status: "",
          category: DEFAULT_CATEGORY_TYPE,
        },
      });
    }
  };

  handleBranchSelection = (event) => {
    this.setState(
      {
        LoanFilters: {
          ...this.state.LoanFilters,
          branch:
            event.target.value !== DEFAULT_BRANCH_SELECTION
              ? event.target.value
              : undefined,
        },
      },
      () => {
        this.props.getUserList(
          USER_ROLES.salesPerson,
          this.state.LoanFilters.branch
        );
      }
    );
  };

  handleSalesPersonChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        salesPerson: event.target.value,
      },
    });
  };

  handleChangeTypeFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        loanType: event.target.value,
      },
    });
  };

  handleChangeCategoryFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        category: event.target.value,
      },
    });
  };
  handleChangeFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleApprovalInputChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        approvalType:
          event.target.value === DEFAULT_APPROVAL_TYPE
            ? ""
            : event.target.value === "true",
      },
    });
  };


  handleClientStateChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        clientState:
          DEFAULT_VALUES.includes(event.target.value) ? ""
            : event.target.value,
      },
    });

  }
  handleLoanFromChange = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        loanFrom:
          DEFAULT_VALUES.includes(event.target.value) ? ""
            : event.target.value,
      },
    });

  }

  handleOnClickResetFilter = () => {
    this.setState(
      {
        LoanFilters: {
          minimartCode: "",
          applicationId: "",
          loanId: "",
          matricesId: "",
          minimartName: "",
          status: DEFAULT_LOAN_STATUS.value,
          loanType: DEFAULT_LOAN_TYPE,
          category: DEFAULT_CATEGORY_TYPE,
          clientState: "",
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
          branch: DEFAULT_BRANCH_SELECTION,
          salesPerson: DEFAULT_SALES_PERSON_SELECTION,
          conciliationType: "",
          approvalType: DEFAULT_APPROVAL_TYPE,
          activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
          recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
          loanFrom: DEFAULT_LOAN_FROM,
        },
      },
      () => {
        this.props.getUserList(USER_ROLES.salesPerson);
        this.handleOnClickSearchFilter(true);
      }
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleOnClickSearchFilter({
      search: {
        minimartCode:
          this.state.LoanFilters.minimartCode !== ""
            ? this.state.LoanFilters.minimartCode
            : undefined,
        applicationId:
          this.state.LoanFilters.applicationId !== ""
            ? this.state.LoanFilters.applicationId
            : undefined,
        loanId:
          this.state.LoanFilters.loanId !== ""
            ? this.state.LoanFilters.loanId
            : undefined,
        matricesId:
          this.state.LoanFilters.matricesId !== ""
            ? this.state.LoanFilters.matricesId
            : undefined,
        minimartName:
          this.state.LoanFilters.minimartName !== ""
            ? this.state.LoanFilters.minimartName
            : undefined,
        status:
          this.state.LoanFilters.status !== DEFAULT_LOAN_STATUS
            ? this.state.LoanFilters.status
            : undefined,
        loanType:
          this.state.LoanFilters.loanType !== DEFAULT_LOAN_TYPE
            ? this.state.LoanFilters.loanType
            : undefined,
        category:
          this.state.LoanFilters.category !== DEFAULT_CATEGORY_TYPE
            ? this.state.LoanFilters.category
            : undefined,
        branch:
          this.state.LoanFilters.branch !== DEFAULT_BRANCH_SELECTION
            ? this.state.LoanFilters.branch
            : undefined,
        salesPerson:
          this.state.LoanFilters.salesPerson !==
            DEFAULT_SALES_PERSON_SELECTION
            ? this.state.LoanFilters.salesPerson
            : undefined,
        conciliationType:
          this.state.LoanFilters.conciliationType !==
            DEFAULT_CONCILIATION_TYPE
            ? this.state.LoanFilters.conciliationType
            : undefined,
        approvalType:
          this.state.LoanFilters.approvalType ===
            DEFAULT_APPROVAL_TYPE ||
            !this.state.LoanFilters.approvalType
            ? undefined
            : this.state.LoanFilters.approvalType === true,
        clientState:
          this.state.LoanFilters.clientState !== "selectClientState" &&
            this.state.LoanFilters.clientState !== ""
            ? this.state.LoanFilters.clientState
            : undefined,
        fromDate: this.state.LoanFilters.fromDate,
        toDate: this.state.LoanFilters.toDate,
        loanFrom: this.state.LoanFilters.loanFrom ===
          DEFAULT_LOAN_FROM ||
          !this.state.LoanFilters.loanFrom
          ? undefined
          : this.state.LoanFilters.loanFrom
      },
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  //render
  renderSearchFilter = () => {
    let userList = [DEFAULT_SALES_PERSON(), ...this.state.userList];
    let LoanFilters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.LoanFilters.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.LoanFilters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("MatricesID"),
        handleOnChange: this.handleSearchMatricesIdChange,
        Value: this.state.LoanFilters.matricesId,
        fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
        column: 2,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("minimartCode"),
        handleOnChange: this.handleSearchCICodeChange,
        Value: this.state.LoanFilters.minimartCode,
        fieldname: SEARCH_FILTER_FIELDS.CODE,
        column: 2,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("applicationId"),
        handleOnChange: this.handleSearchIdChange,
        Value: this.state.LoanFilters.applicationId,
        fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID,
        column: 2,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("loanId"),
        handleOnChange: this.handleSearchLoanIdChange,
        Value: this.state.LoanFilters.loanId,
        fieldname: SEARCH_FILTER_FIELDS.LOAN_ID,
        column: 2,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("MiniMartname"),
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.LoanFilters.minimartName,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
        paddingTop: true,
        column: 2,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeTypeFilter,
        Value: this.state.LoanFilters.loanType,
        fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
        filterDropdownList: LOAN_TYPE,
        paddingTop: true,
        column: 2,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeStatusFilter,
        Value: this.state.LoanFilters.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: STATUS_FOR_LOANS,
        paddingTop: true,
      },

      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        disabled: this.state.LoanFilters.status !== DISBURSED,
        handleOnChange: this.handleChangeCategoryFilter,
        Value: this.state.LoanFilters.category,
        fieldname: SEARCH_FILTER_FIELDS.CATEGORY,
        column: 2,
        filterDropdownList: CATEGORY,
        paddingTop: true,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleBranchSelection,
        Value: this.state.LoanFilters.branch,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.state.branches,
        paddingTop: true,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSalesPersonChange,
        Value: this.state.LoanFilters.salesPerson,
        fieldname: SEARCH_FILTER_FIELDS.SALES_PERSON,
        filterDropdownList: userList,
        paddingTop: true,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeFilter,
        Value: this.state.LoanFilters.conciliationType,
        fieldname: SEARCH_FILTER_FIELDS.CONCILIATION_TYPE,
        filterDropdownList: CONCILIATION_TYPE_LIST,
        paddingTop: true,
        name: "conciliationType",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleApprovalInputChange,
        Value: this.state.LoanFilters.approvalType,
        fieldname: SEARCH_FILTER_FIELDS.APPROVAL_TYPE,
        filterDropdownList: APPROVAL_TYPE_LIST,
        paddingTop: true,
        name: "approvalType",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleClientStateChange,
        Value: this.state.LoanFilters.clientState,
        fieldname: SEARCH_FILTER_FIELDS.CLIENT_STATE,
        filterDropdownList: this.props.clientStates,
        paddingTop: true,
        name: "clientState",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleLoanFromChange,
        Value: this.state.LoanFilters.loanFrom,
        fieldname: SEARCH_FILTER_FIELDS.LOAN_FROM,
        filterDropdownList: this.props.loanInitiatedFrom,
        paddingTop: true,
        name: "loanFrom",
      },
    ];
    return (
      <SearchFilter
        filters={LoanFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingRight={0}
        paddingTop={2}
        removeLeftPadding={true}
      />
    );
  };
  render() {
    return (
      <div className='loanList container-fluid ListContainer'>
        {this.renderSearchFilter()}

        <CustomerTypeLegend
          heading={Strings("ApplicationStatuses")}
          arrayOfTypes={GENERAL_LOAN_STATUS_LEGEND}
          paddingRight={4}
        />

        <CustomerTypeLegend
          heading={Strings("LoanCategories")}
          arrayOfTypes={LOAN_CATEGORY_LEGEND}
          paddingRight={4}
        />

        {this.props.loans.length > 0 ? (
          <Row className='cardAlign'>
            <Col md={12} className='w-100 pr-0'>
              <Row className='w-100'>
                {this.props.loans.map((card) => {
                  return (
                    <CustomCard
                      card={card}
                      cardType='loan'
                      key={Math.random()}
                      handleCardClick={this.props.setLoanDetailsID}
                    />
                  );
                })}
              </Row>
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </Row>
        ) : (
          <div className='noRecordsFound noBranchRecords'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default LoanList;

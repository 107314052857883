import React from "react";
import { Row, Button, Col } from "react-bootstrap";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CheckboxComponent from "../CustomUIComponents/CheckboxComponent/CheckboxComponent";

//Constants
import {
  SEARCH_FILTER_TYPE,
  INVOICE_DATE_FORMAT,
  SEARCH_FILTER_PLACE_HOLDER,
  SEARCH_FILTER_FIELDS,
  DOWNLOAD_FILE_TYPE,
  CI_BANK_TRANSFER_INVOICE_TYPE,
} from "../../constants/appConstants";
import {
  REMITTANCE_TABLE_FIELDS,
  REMITTANCE_STATUS,
  PAYMENT_STATUS,
  DOWNLOAD_URL,
} from "./Constants";
import {
  DEFAULT_PAGINATION_ACTIVE_PAGE,
  DEFAULT_PAGINATION_RECORDS_COUNT,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Utility
import Utility from "../../utils/Utility";

//Assets
import CSVdownload from "../../resources/images/ic_download_csv.svg";
import PDFdownload from "../../resources/images/ic_download_pdf.svg";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./RemittancePayment.scss";

//Assets
import downloadIcon from "../../resources/images/RemittanceDeliveryRecipietDownload.svg";

class RemittancePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        remittanceDate: new Date(),
        paymentStatus: PAYMENT_STATUS[0].value,
        remittanceStatus: "",
        remittanceMinimumAmount: "",
        remittanceMaximumAmount: "",
      },
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      selectedOptions: [],
      errorMessage: props.errorMessage,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      this.handleOnClickSearchFilter
    );
  };

  submitInputs = () => {
    if (this.state.selectedOptions.length > 0) {
      this.props.submitInputs({
        ids: this.state.selectedOptions,
        st: "PAID",
      });
    } else {
      this.setState({
        errorMessage: Strings("pleaseSelectCheckboxesBeforeSubmit"),
      });
    }
  };

  clearSelection = () => {
    this.setState({
      selectedOptions: [],
      errorMessage: "",
    });
  };

  remittanceAmountValidation = () => {
    if (
      this.state.filters.remittanceMaximumAmount &&
      this.state.filters.remittanceMinimumAmount
    ) {
      if (
        parseFloat(this.state.filters.remittanceMaximumAmount) <
        parseFloat(this.state.filters.remittanceMinimumAmount)
      ) {
        this.setState({
          errorMessage: Strings("minMaxError"),
        });
      } else return true;
    } else if (
      this.state.filters.remittanceMaximumAmount &&
      this.state.filters.remittanceMinimumAmount === ""
    ) {
      this.setState({
        errorMessage: Strings("remittanceMinAmountError"),
      });
    } else if (
      this.state.filters.remittanceMaximumAmount === "" &&
      this.state.filters.remittanceMinimumAmount
    ) {
      this.setState({
        errorMessage: Strings("remittanceMaxAmountError"),
      });
    } else return true;
    return false;
  };

  handleOnClickSearchFilter = (isDefaultOffset = false) => {
    //NOSONAR
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    if (this.remittanceAmountValidation()) {
      let requestData = {
        search: {
          rd: this.state.filters.remittanceDate
            ? this.state.filters.remittanceDate
            : undefined,
          rs:
            this.state.filters.remittanceStatus &&
            this.state.filters.remittanceStatus !==
              Strings("selectRemittanceStatus")
              ? this.state.filters.remittanceStatus
              : undefined,
          minAmt: this.state.filters.remittanceMinimumAmount
            ? this.state.filters.remittanceMinimumAmount
            : undefined,
          maxAmt: this.state.filters.remittanceMaximumAmount
            ? this.state.filters.remittanceMaximumAmount
            : undefined,
          ps:
            this.state.filters.paymentStatus &&
            this.state.filters.paymentStatus !==
              Strings("selectPaymentStatus")
              ? this.state.filters.paymentStatus
              : undefined,
        },
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
          : this.state.activePage - 1,
        limit: this.state.recordsPerPage,
      };
      this.props.handleOnClickSearchFilter(requestData);
    }
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        filters: {
          remittanceDate: new Date(),
          paymentStatus: PAYMENT_STATUS[0].value,
          remittanceStatus: "",
          remittanceMaximumAmount: "",
          remittanceMinimumAmount: "",
        },
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        errorMessage: "",
      },
      this.handleOnClickSearchFilter
    );
  };

  handleInputChange = (event) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [event.target.name]: event.target.value,
      },
      errorMessage: "",
    });
  };

  handleDateChange = (dateMoment) => {
    let DateValue;
    if (dateMoment.isAfter(new Date())) {
      DateValue = this.state.filters.remittanceDate;
    } else {
      DateValue = dateMoment;
    }
    this.setState({
      filters: {
        ...this.state.filters,
        remittanceDate: DateValue,
      },
      errorMessage: "",
    });
  };

  renderSearchFilter = () => {
    let searchFilterList = [
      {
        type: SEARCH_FILTER_TYPE.SINGLE_DATE,
        // column: 6,
        placeholder: SEARCH_FILTER_PLACE_HOLDER.SELECT_DATE,
        handleCalenderChange: this.handleDateChange,
        Value: new Date(this.state.filters.remittanceDate),
        dateFormat: INVOICE_DATE_FORMAT,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleInputChange,
        Value: this.state.filters.remittanceStatus,
        fieldname: SEARCH_FILTER_FIELDS.REMITTANCE_STATUS,
        name: SEARCH_FILTER_FIELDS.REMITTANCE_STATUS,
        filterDropdownList: REMITTANCE_STATUS,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleInputChange,
        Value: this.state.filters.paymentStatus,
        fieldname: SEARCH_FILTER_FIELDS.PAYMENT_STATUS,
        filterDropdownList: PAYMENT_STATUS,
        name: SEARCH_FILTER_FIELDS.PAYMENT_STATUS,
      },
      {
        type: SEARCH_FILTER_TYPE.AMOUNT,
        searchPlaceHolder: Strings("remittanceMinimumAmount"),
        Value: this.state.filters.remittanceMinimumAmount,
        handleOnChange: this.handleInputChange,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
        fieldname: "remittanceMinimumAmount",
        name: "remittanceMinimumAmount",
      },
      {
        type: SEARCH_FILTER_TYPE.AMOUNT,
        searchPlaceHolder: Strings("remittanceMaximumAmount"),
        Value: this.state.filters.remittanceMaximumAmount,
        handleOnChange: this.handleInputChange,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
        fieldname: "remittanceMaximumAmount",
        name: "remittanceMaximumAmount",
      },
    ];
    return (
      <SearchFilter
        filters={searchFilterList}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingRight={0}
      />
    );
  };

  onChangeDailyCheckbox = (checkboxValue) => {
    let selectedOptions = this.state.selectedOptions;
    let indexOfOption = selectedOptions.indexOf(checkboxValue.rid);
    if (indexOfOption === -1) {
      selectedOptions.push(checkboxValue.rid);
    } else {
      selectedOptions.splice(indexOfOption, 1);
    }
    this.setState({ selectedOptions, errorMessage: "" });
  };

  onHeaderChecknoxValueChange = () => {
    const deliveredLength = this.props.remittancePaymentData.filter(
      (rem) => rem.rs === "DELIVERED"
    ).length;
    let selectedOptions =
      this.state.selectedOptions.length > 0 &&
      this.state.selectedOptions.length !== deliveredLength
        ? []
        : [...this.state.selectedOptions];
    this.props.remittancePaymentData.forEach((rem) => {
      if (rem.rs === "DELIVERED") {
        const indexOfOption = selectedOptions.indexOf(rem.rid);
        if (indexOfOption === -1) {
          selectedOptions.push(rem.rid);
        } else {
          selectedOptions.splice(indexOfOption, 1);
        }
      }
    });
    this.setState({ selectedOptions, errorMessage: "" });
  };

  renderTableData = (row, key) => {
    //NOSONAR
    switch (key) {
      case "ps":
        return row["rs"] !== "DELIVERED" ? (
          Strings("NA")
        ) : (
          <div className='d-flex justify-content-center w-100'>
            <CheckboxComponent
              onChange={() => this.onChangeDailyCheckbox(row)}
              checked={
                row[key] === CI_BANK_TRANSFER_INVOICE_TYPE.PAID //NOSONAR
                  ? true
                  : this.state.selectedOptions.includes(row.rid) //NOSONAR
                  ? true
                  : false
              }
              disabled={
                row[key] === CI_BANK_TRANSFER_INVOICE_TYPE.PAID
              }
            />
          </div>
        );

      case "amt":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "rdd":
      case "rrd":
        return row[key]
          ? moment.utc(row[key]).format(INVOICE_DATE_FORMAT)
          : Strings("NA");

      case "rdr":
        return (
          <div className='icon'>
            {row.rdr ? (
              <img
                src={downloadIcon}
                alt='delivered'
                onClick={() => this.openReceipt(row.rdr)}
              />
            ) : (
              Strings("NA")
            )}
          </div>
        );
      default:
        return row[key] ? Strings(row[key]) : Strings("NA");
    }
  };

  openReceipt = (link) => {
    const s3rUl = Utility.getDecryptedData(link);
    window.open(s3rUl);
  };

  renderTableHeader = (column) => {
    if (
      column.key === "ps" &&
      this.props.remittancePaymentData.length > 0 &&
      this.props.remittancePaymentData[0].ps !==
        CI_BANK_TRANSFER_INVOICE_TYPE.PAID
    ) {
      return (
        <>
          <span>{Strings(column.name)}</span>
          <CheckboxComponent
            className='justify-content-center'
            onChange={this.onHeaderChecknoxValueChange}
            checked={
              this.state.selectedOptions.length ===
              this.props.remittancePaymentData.filter(
                (rem) => rem.rs === "DELIVERED"
              ).length
            }
            disabled={
              this.state.filters.paymentStatus ===
              CI_BANK_TRANSFER_INVOICE_TYPE.PAID
            }
          />
        </>
      );
    } else {
      return column.name;
    }
  };

  renderTable = () => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='mb-3'>
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {REMITTANCE_TABLE_FIELDS.length > 0 &&
                  REMITTANCE_TABLE_FIELDS.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className='text-center px-2 header'
                      title={column.name}
                    >
                      <div>{this.renderTableHeader(column)}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.remittancePaymentData.map(
                (row, rowIndex) => (
                  <StyledTableRow key={rowIndex + row.rid} hover>
                    {REMITTANCE_TABLE_FIELDS.map((column) => {
                      const renderValue = this.renderTableData(
                        row,
                        column.key
                      );
                      return (
                        <StyledTableCell
                          key={column.key}
                          className='text-center px-2'
                          component='th'
                          scope='row'
                          title={
                            column.key === "ps"
                              ? row["rs"] !== "DELIVERED" // NOSONAR
                                ? renderValue
                                : Strings(row[column.key])
                              : column.key === "rdr" // NOSONAR
                              ? ""
                              : renderValue
                          }
                          style={{ minWidth: column.minWidth }}
                        >
                          {renderValue}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  downloadFile = (fileType) => {
    if (this.remittanceAmountValidation()) {
      let searchFilterObject = {
        search: {
          rd: this.state.filters.remittanceDate
            ? Utility.convertDateIntoMiliSeconds(
                this.state.filters.remittanceDate
              )
            : undefined,
          rs:
            this.state.filters.remittanceStatus &&
            this.state.filters.remittanceStatus !==
              Strings("selectRemittanceStatus")
              ? this.state.filters.remittanceStatus
              : undefined,
          minAmt: this.state.filters.remittanceMinimumAmount
            ? this.state.filters.remittanceMinimumAmount
            : undefined,
          maxAmt: this.state.filters.remittanceMaximumAmount
            ? this.state.filters.remittanceMaximumAmount
            : undefined,
          ps:
            this.state.filters.paymentStatus &&
            this.state.filters.paymentStatus !==
              Strings("selectPaymentStatus")
              ? this.state.filters.paymentStatus
              : undefined,
        },
      };
      this.props.handleDownloadFile(
        DOWNLOAD_URL + fileType,
        searchFilterObject,
        fileType
      );
    }
  };

  renderDownloadButtons = () => {
    return (
      <React.Fragment>
        {" "}
        <div>
          <Button
            className='downloadButtons mr-3'
            onClick={() => {
              this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
            }}
            disabled={this.props.remittancePaymentData.length === 0}
          >
            <img src={PDFdownload} alt='' />
          </Button>
          <Button
            className='downloadButtons'
            onClick={() => {
              this.downloadFile(DOWNLOAD_FILE_TYPE.CSV);
            }}
            disabled={this.props.remittancePaymentData.length === 0}
          >
            <img src={CSVdownload} alt='' />
          </Button>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className='remittancePayment container-fluid paper viewCIWireTransferContainer'>
        <Row className=''>
          <Col md={10} className='px-0'>
            {this.renderSearchFilter()}
          </Col>
          <Col
            md={2}
            className='py-2 px-0 d-flex justify-content-end pull-right'
          >
            {" "}
            {this.renderDownloadButtons()}
          </Col>
        </Row>
        {this.state.errorMessage !== "" ? (
          <Row className='errors pl-0'>{this.state.errorMessage}</Row>
        ) : null}

        <hr className='fullWidth' />
        {this.props.remittancePaymentData.length > 0 ? (
          <React.Fragment>
            {this.renderTable()}
            <Row className='d-flex justify-content-end pull-right'>
              <Button
                className='buttonBorder mr-2'
                onClick={this.clearSelection}
              >
                {Strings("Clear")}
              </Button>
              <Button
                className='buttonBackground'
                onClick={this.submitInputs}
              >
                {Strings("Submit")}
              </Button>
            </Row>
            <Pagination
              activePage={this.state.activePage}
              recordsPerPage={this.state.recordsPerPage}
              numberOfPages={this.props.numberOfPages}
              onPageChange={this.onPageChange}
              dropup={true}
            />
          </React.Fragment>
        ) : (
          <div className='noRecordsFound noBranchRecords'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default RemittancePayment;

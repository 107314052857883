import React from 'react'
import { Col, Form } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Components
import InformativeModal from '../CustomUIComponents/InformativeModal/InformativeModal';
import CedulaChangeModal from './CedulaChangeModal';

//Constants
import { CLIENT_PORTAL_COLUMNS, CLIENT_STATE } from './Constants';

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Assets
import editNote from "../../resources/images/ic_edit.svg";

class ClientPortal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            blackListed: false,
            identificationNumber: "",
            cedulaEdit: false,
            currentClient: {},
            changedClientInfo: {},
            minimartDetails: [],
            selectedMatricesIds: []
        }
    }

    renderTableHeader = (columns, StyledTableCell) => {
        return <TableRow>
            {columns.length > 0 &&
                columns.map((column) => (
                    <StyledTableCell
                        key={column.key}
                        className={
                            column.key === "rating"
                                ? "text-center px-2 header"
                                : "px-2 header"
                        }
                        title={column.name}
                    >
                        <div>
                            {column.name}
                        </div>
                    </StyledTableCell>
                ))}
        </TableRow>
    }

    handleCheck = (event, row) => {
        if (event.target.checked) {
            this.props.getActiveLoans(row.identificationNumber, () => {
                this.setState({
                    showModal: true,
                    identificationNumber: row.identificationNumber,
                    clientState: true
                })
            })
        }
        else {
            this.setState({
                showModal: true,
                identificationNumber: row.identificationNumber,
                clientState: false
            })
        }

    }

    showCedulaEditModal = (row, minimartDetails) => {
        this.setState({
            cedulaEdit: !this.state.cedulaEdit,
            currentClient: row,
            changedClientInfo: row,
            clientState: row["clientState"],
            minimartDetails,
        })
    }

    closeCedulaEditModal = () => {
        this.setState({
            cedulaEdit: false,
            currentClient: {},
            changedClientInfo: {},
            minimartDetails: {},
            errorMessage: "",
            selectedMatricesIds: [],
            clientState: ""
        })
    }

    renderModal = () => {
        let commonMinimartInfo = this.state.clientState === true
            ? Strings("confimationWarningToBlacklist")
            : Strings("confimationWarningToRemoveFromBlacklist");

        return <InformativeModal
            openModal={this.state.showModal}
            modalHeader={Strings("clientStatusChange")}
            commonMinimartInfo={commonMinimartInfo}
            minimartInfo={this.props.minimartInfo}
            handleOkClick={(notesData) => {
                this.props.changeClientStatus(
                    this.state.identificationNumber,
                    this.state.clientState,
                    notesData,
                    this.toggleModal
                )
            }}
            closeModal={this.toggleModal}
        />
    }

    toggleModal = () => {
        this.setState({ showModal: false })
    }



    renderTableData = (row, column) => {
        if (column.key === "isClientBlacklisted") {
            return <Form.Check
                checked={
                    row.clientState === CLIENT_STATE.blacklisted
                    || row.clientState === CLIENT_STATE.blacklistedAndDeactivated
                }
                type="switch"
                id={row.identificationNumber}
                onChange={(event) => this.handleCheck(event, row)}
            />
        } else if (column.key === "identificationNumber") {
            return <div>{Strings(row[column.key])}
                <span className={"pencil mx-1 p-1"}>
                    <img //NOSONAR
                        src={editNote}
                        className={"editIcon m-0"}
                        alt={"edit"}
                        title={Strings("editCedula")}
                        onClick={() => this.showCedulaEditModal(row, row["minimartDetails"])}
                    />
                </span>
            </div>
        }
        else if (column.key === "minimartDetails") {
            return row[column.key] && row[column.key].map(minimart => {//NOSONAR
                return <span //NOSONAR
                    className='textColor url'
                    key={minimart.id}
                    onClick={() => this.props.handleMinimartClick(minimart.id)}>
                    {minimart.id}
                </span>
            })
        } else if (column.key === "clientId") {
            return <div className='d-flex'>
                <Col md={6}>{Strings(row[column.key])}</Col>
                <Col md={6}>
                    {(row["clientState"] === CLIENT_STATE.deactivated
                        || row["clientState"] === CLIENT_STATE.blacklistedAndDeactivated
                    ) && <i
                            className="fa fa-user-times errorText"
                            aria-hidden="true"
                            title={Strings(row["clientState"])}
                        />}
                </Col>
            </div>
        } else {
            return Strings(row[column.key]);
        }
    }


    renderTableBody = (rows, columns, StyledTableRow, StyledTableCell) => {
        return (
            rows.map((row) => (
                <StyledTableRow
                    key={row.identificationNumber}
                    hover
                    style={{ cursor: "pointer" }}
                >
                    {columns.map((column) => (
                        <StyledTableCell
                            key={column.key}
                            className={"px-2"}
                            component='th'
                            scope='row'
                            style={{ minWidth: column.minWidth }}
                        >
                            <div>
                                {this.renderTableData(row, column)}
                            </div>
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            ))
        )
    }


    renderCustomDataGrid = (columns, rows) => {
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head} `]: {
                backgroundColor: theme.palette.divider,
                color: theme.palette.common.black,
            },
            [`&.${tableCellClasses.body} `]: {
                fontSize: 14,
            },
        }));

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.action.hover,
            },
            // hide last border
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        }));

        return (
            <div className='d-flex justify-content-center mx-3 mb-3'>
                <TableContainer
                    component={Paper}
                    className='d-flex justify-content-center'
                >
                    <Table aria-label='customized table'>
                        <TableHead>
                            {this.renderTableHeader(columns, StyledTableCell)}
                        </TableHead>
                        <TableBody>
                            {this.renderTableBody(rows, columns, StyledTableRow, StyledTableCell)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };

    handleEdit = (event) => {
        this.setState({
            changedClientInfo: {
                ...this.state.changedClientInfo,
                [event.target.name]: event.target.value,
            },
            errorMessage: ""
        })
    }

    selectMatricesId = (matricesId) => {
        let selectedMatricesIds = [...this.state.selectedMatricesIds];
        if (selectedMatricesIds.includes(matricesId)) {
            selectedMatricesIds.splice(selectedMatricesIds.indexOf(matricesId), 1)
        } else {
            selectedMatricesIds.push(matricesId);
        }
        this.setState({
            selectedMatricesIds,
            errorMessage: ""
        })
    }

    onSaveChangedClientInfo = () => {
        let errorMessage = "";
        if (this.state.currentClient.identificationNumber === this.state.changedClientInfo.identificationNumber) {
            errorMessage = Strings("sameCedulaError");
        } else if (this.state.selectedMatricesIds.length === 0
            || this.state.changedClientInfo.customerName === "") {
            errorMessage = Strings("EmptyFieldsError");
        } else if (this.state.changedClientInfo.identificationNumber.length !== 11) {
            errorMessage = Strings("CedulaIdMustBeElevenDigits");
        } else {
            let request = {
                cid: this.state.currentClient.identificationNumber,
                cn: this.state.currentClient.customerName,
                nid: this.state.changedClientInfo.identificationNumber,
                nn: this.state.changedClientInfo.customerName,
                mids: this.state.selectedMatricesIds
            }
            this.props.updateCedula(request, this.closeCedulaEditModal);
        }
        this.setState({ errorMessage })
    }

    renderCedulaEditModal = () => {
        let warningText = "";
        if (this.state.clientState === CLIENT_STATE.blacklisted) {
            warningText = Strings("OWNER_OF_MINIMART_IS_BLACKLISTED")
        }
        return (
            <CedulaChangeModal
                open={this.state.cedulaEdit}
                closeModal={this.closeCedulaEditModal}
                modalCloseOnEsc={false}
                modalCloseOnOverlayClick={false}
                modalShowCloseIcon={false}
                headerText={Strings("editCedula")}
                modalButtonCancel={{
                    text: Strings("Cancel"),
                    onClick: this.closeCedulaEditModal,
                }}
                modalButtonOk={{
                    text: Strings("Submit"),
                    onClick: this.onSaveChangedClientInfo,
                }}
                handleEdit={this.handleEdit}
                changedClientInfo={this.state.changedClientInfo}
                minimartDetails={this.state.minimartDetails}
                selectedMatricesIds={this.state.selectedMatricesIds}
                selectMatricesId={this.selectMatricesId}
                warningText={warningText}
                errorMessage={this.state.errorMessage}
            />
        )
    }


    render() {
        return (
            <>
                {this.state.showModal && this.renderModal()}
                {this.state.cedulaEdit && this.state.minimartDetails && this.renderCedulaEditModal()}
                {this.props.data &&
                    <div className='mt-3'>
                        {this.renderCustomDataGrid(CLIENT_PORTAL_COLUMNS, this.props.data)}
                    </div>
                }
            </>
        )
    }

}

export default ClientPortal;
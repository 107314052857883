import React from "react";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./Header.scss";

//UI Components
import Col from "react-bootstrap/Col";
import { Dropdown, Row } from "react-bootstrap";

//Images
import ProfileImage from "../../resources/images/ic_user.svg";
import logo from "../../resources/images/Logo-Matrices.png";
import profile from "../../resources/images/ic_profile.svg";
import lock from "../../resources/images/ic_change_password.svg";
import logOut from "../../resources/images/ic_signout.svg";


class Header extends React.Component {
  getUsername = () => {
    let username = "";
    let loggedInUserInfo = this.props.loggedInUserInfo;
    if (loggedInUserInfo) {
      username =
        loggedInUserInfo.firstName + " " + loggedInUserInfo.lastName;
    }
    return username;
  };

  getUserRole = () => {
    let userRole = "";
    let loggedInUserInfo = this.props.loggedInUserInfo;
    if (loggedInUserInfo) {
      userRole = Strings(loggedInUserInfo.role);
    }
    return userRole;
  };

  handleLogout = () => {
    this.props.handleLogout();
  };

  handleProfileClick = () => {
    this.props.handleProfileClick();
  };

  clickChangePassword = () => {
    this.props.handleChangePasswordClick();
  };

  render() {
    return (
      <Row className='noPadding Header m-0'>
        <Col md={{ span: 2, offset: 1 }} className='noPadding'>
          <img src={logo} alt='' className='quisqueyaLogo' />
        </Col>
        <Col md={2} className='noPadding branchSelection' />
        <Col
          md={{ span: 4, offset: 3 }}
          className='profileDropdown pull-right'
        >
          <Col md={12} className='noPadding'>
            <Dropdown alignRight>
              <Dropdown.Toggle variant='link' id='dropdown-basic' />
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey='1'
                  onClick={this.handleProfileClick}
                >
                  <img src={profile} alt='' />
                  &nbsp; {Strings("Profile")}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='2'
                  onClick={this.clickChangePassword}
                >
                  <img src={lock} alt='' />
                  &nbsp; {Strings("ChangePassword")}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='3'
                  onClick={this.handleLogout}
                >
                  <img src={logOut} alt='' />
                  &nbsp; {Strings("Logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <span className='pull-right dropdownName noPadding'>
              <div className='profileName'>{this.getUsername()}</div>
              <div className='profileSubName'>
                <div>{this.getUserRole()}</div>
              </div>
            </span>
            <span className='pull-right profileImageSection'>
              <img
                src={
                  this.props.profilePic === null
                    ? ProfileImage
                    : this.props.profilePic
                }
                alt=''
                className='profileImg'
                onError={(error) => {
                  error.target.onError = null;
                  error.target.src = ProfileImage;
                }}
              />
            </span>
          </Col>
        </Col>
      </Row>
    );
  }
}

export default Header;

import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import InvoiceList from "./InvoiceList";

//Constants
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  CIWIRE_TRANSFER_MANAGEMENT_TABS,
  DEFAULT_DATE_FORMAT,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  CI_BANK_TRANSFER_INVOICE_TYPE,
} from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

class CIWireTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: new Date(),
      toDate: new Date(),
      status: CIWIRE_TRANSFER_MANAGEMENT_TABS[0].value,
      activeTab: CIWIRE_TRANSFER_MANAGEMENT_TABS[0],
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      AssignRepaymentPagination: {
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.errorMessage !== "" && state.errorMessage === "") {
      return {
        errorMessage: props.errorMessage,
      };
    }
    return null;
  }
  handleTabs = (index) => {
    this.setState(
      {
        activeTab: CIWIRE_TRANSFER_MANAGEMENT_TABS[index],
        status: CIWIRE_TRANSFER_MANAGEMENT_TABS[index].value,
      },
      () => {
        this.handleOnClickSearchFilter(true);
      }
    );
  };

  handleOnClickSearchFilter = (defaultOffset) => {
    defaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    let offset;
    if (defaultOffset) offset = DEFAULT_PAGINATION_ACTIVE_PAGE - 1;
    else if (this.state.activeTab.key === 1)
      offset = this.state.activePage - 1;
    else offset = this.state.AssignRepaymentPagination.activePage - 1;

    this.props.handleOnClickSearchFilter({
      sc: {
        st: this.state.status,
        fd: Utility.convertDateIntoMiliSeconds(this.state.fromDate),
        td: Utility.convertDateIntoMiliSeconds(this.state.toDate),
      },
      limit:
        this.state.activeTab.key === 1
          ? this.state.recordsPerPage
          : this.state.AssignRepaymentPagination.recordsPerPage,
      offset: offset,
    });
  };

  handleResetValues = () => {
    this.setState(
      {
        fromDate: new Date(),
        toDate: new Date(),
      },
      () => {
        this.handleOnClickSearchFilter(true);
      }
    );
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.fromDate)) {
          DateValue =
            moment(dateMoment).diff(this.state.fromDate, "days") === 0
              ? this.state.fromDate
              : this.state.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({
      [key]: DateValue,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    if (this.state.activeTab.key === 1) {
      this.setState(
        {
          activePage: page,
          recordsPerPage: pageLength,
        },
        () => {
          this.handleOnClickSearchFilter(false);
        }
      );
    } else {
      this.setState(
        {
          AssignRepaymentPagination: {
            activePage: page,
            recordsPerPage: pageLength,
          },
        },
        () => {
          this.handleOnClickSearchFilter(false);
        }
      );
    }
  };

  submitInputs = (paidInvoices) => {
    this.props.markInvoicesAsPaid({
      ids: paidInvoices,
      st: CI_BANK_TRANSFER_INVOICE_TYPE.PAID,
    });
  };
  generateReport = () => {
    this.props.generateReport(
      Utility.convertDateIntoMiliSeconds(this.state.fromDate),
      Utility.convertDateIntoMiliSeconds(this.state.toDate),
      this.state.status
    );
  };

  renderSearchFilter = () => {
    let LoanFilters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        fromDate: this.state.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
    ];
    return (
      <SearchFilter
        filters={LoanFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleResetValues}
        paddingRight={0}
      />
    );
  };

  renderUnpaidInvoice = (unPaidUsers, numberOfPages) => {
    return (
      <InvoiceList
        users={unPaidUsers}
        numberOfPages={numberOfPages}
        AssignRepaymentPagination={
          this.state.AssignRepaymentPagination
        }
        onPageChange={this.onPageChange}
        submitInputs={this.submitInputs}
        errorMessage={this.state.errorMessage}
        type={"unpaidInvoice"}
      />
    );
  };

  renderPaidInvoice = (paidUsers) => {
    return (
      <InvoiceList
        users={paidUsers}
        onPageChange={this.onPageChange}
        activePage={this.state.activePage}
        numberOfPages={this.props.numberOfPages}
        recordsPerPage={this.state.recordsPerPage}
        errorMessage={this.state.errorMessage}
        paidNumberofPages={this.props.paidNumberofPages}
        type={"paidInvoice"}
      />
    );
  };

  render() {
    const { unPaidUsers, numberOfPages, paidUsers } = this.props;

    return (
      <div className='ciwiretransfer container-fluid'>
        <div className='headerText'></div>
        <Row className='paper viewCIWireTransferContainer'>
          {this.renderSearchFilter()}
          <hr className='fullWidth' />
          {/* amount row start */}
          <Row className='fullWidth'>
            <Col md={6}>
              <label className='ci-label subHeaderText'>
                {Strings("TotalUnPaidAmount") + " ($)"} : &nbsp;
                {Utility.getCurrencyRepresentationOfAmount(
                  Utility.getDecimalNumberUptoDigit(
                    this.props.unPaidAmount,
                    2
                  )
                )}
              </label>
            </Col>
            <Col md={6}>
              <label className='ci-label subHeaderText'>
                {Strings("TotalPaidAmount") + " ($)"} :{" "}
                {Utility.getCurrencyRepresentationOfAmount(
                  Utility.getDecimalNumberUptoDigit(
                    this.props.paidAmount,
                    2
                  )
                )}
              </label>
            </Col>
          </Row>
          <Row>
            <Col md={12} className=' errorText'>
              {this.state.errorMessage}
            </Col>
          </Row>
          {/* amount row end*/}
          {/* status tab start */}
          <div className='statusTabs'>
            <div className='textLabels downloadReport'>
              <label
                className='textLabels textBorder'
                onClick={this.generateReport}
              >
                {Strings("GenerateReport")}
              </label>
            </div>

            <Tabs
              selectedIndex={this.state.activeTab.key}
              onSelect={this.handleTabs}
            >
              <TabList>
                {CIWIRE_TRANSFER_MANAGEMENT_TABS.map((tab) => {
                  return <Tab key={tab.key}> {tab.label}</Tab>;
                })}
              </TabList>
              <TabPanel>
                {this.renderUnpaidInvoice(unPaidUsers, numberOfPages)}
              </TabPanel>
              <TabPanel>{this.renderPaidInvoice(paidUsers)}</TabPanel>
            </Tabs>
          </div>

          {/* status tab end */}
        </Row>
      </div>
    );
  }
}

export default CIWireTransfer;

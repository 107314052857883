import { Strings } from "../../resources/i18n/i18n";

export const DEFAULT_VERSION_SELECTION = "selectVersion";

export const DEFAULT_STATUS = "SelectStatus";
export const GET_CONFIGURATION_STATUS_DROPDOWN = () => [
  {
    key: 0,
    label: Strings(DEFAULT_STATUS),
    value: DEFAULT_STATUS,
  },
  {
    key: 1,
    label: Strings("Active"),
    value: "ACTIVE",
  },
  {
    key: 2,
    label: Strings("Inactive"),
    value: "INACTIVE",
  },
  {
    key: 3,
    label: Strings("Borrador"),
    value: "DRAFTED",
  },
];

export const GET_CONFIGURATION_TABLE_COLUMNS = () => [
  {
    key: "version",
    name: Strings("Version"),
  },
  {
    key: "description",
    name: Strings("Description"),
  },
  {
    key: "state",
    name: Strings("State"),
  },
  {
    key: "user",
    name: Strings("User"),
  },
  {
    key: "lastModified",
    name: Strings("LastModified"),
  },
];

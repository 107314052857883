import { Strings } from "../../resources/i18n/i18n";
export const SALES_PERSON_DATA_KEYS = [
  {
    key: "ts",
    name: Strings("Date"),
    minWidth: 120,
  },
  {
    key: "mid",
    name: Strings("MatricesID"),
    minWidth: 50,
  },
  {
    key: "code",
    name: Strings("CIID"),
    minWidth: 150,
  },
  {
    key: "mn",
    name: Strings("minimartName"),
    minWidth: 250,
  },
  {
    key: "appid",
    name: Strings("applicationId"),
    minWidth: 50,
  },
  {
    key: "ty",
    name: Strings("loanType"),
    minWidth: 200,
  },
  {
    key: "st",
    name: Strings("LoanStatus"),
    minWidth: 100,
  },

  {
    key: "amt",
    name: Strings("loanAmount") + " ($)",
    minWidth: 80,
  },
];

export const SEARCH_FIELD_NAME = {
  MATRICES_ID: "matricesId",
  MINIMART_CODE: "minimartCode",
  APPLICATION_ID: "applicationId",
  MINIMART_NAME: "minimartName",
  LOAN_TYPE: "loanType",
  STATUS: "status",
};

export const DOWNLOAD_URL =
  "/getDeatailedSalesPersonStats/download?fileType=";
export const DEFAULT_LOAN_STATUS = "selectLoanStatus";

export const DEFAULT_LOAN_STATUS_SELECTION = {
  key: 0,
  value: "selectLoanStatus",
  label: Strings(DEFAULT_LOAN_STATUS),
};

// Constants
import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
//Utility
import Utility from "../../utils/Utility";

export const getInvoiceBySearchFilterAction = (
  requestData,
  callback
) => {
  if (requestData.fromDate !== undefined) {
    requestData.fromDate = Utility.convertDateIntoMiliSeconds(
      requestData.fromDate
    );
  }
  if (requestData.toDate !== undefined) {
    requestData.toDate = Utility.convertDateIntoMiliSeconds(
      requestData.toDate
    );
  }

  callAPI(
    "getInvoiceBySearchFilter",
    API.getInvoicesFromFilesBySearchFilter,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

//Components
import MessengerList from "./MessengerList";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

//Strings
import { Strings } from "../../resources/i18n/i18n";
import Utility from "../../utils/Utility";
//Actions
import {
  getAssignedMessengersListAction,
  setMessengerIdAction,
  setDateForMessengerAction,
} from "../DailyStatus/DailyStatusActions";
import { getUserProfilePicUrlAction } from "../ProfileDetails/ProfileDetailsActions";
import { setSearchFilterAction } from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  SEARCH_FILTER_PAGES,
  COLLECTION_VERIFICATION_FILTER,
} from "../../constants/appConstants";

// Assets
import ProfileImage from "../../resources/images/ic_user.svg";

class MessengerListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      showLoader: false,
      messengerInfos: [],
      usersResponseData: [],
      numberOfPages: 1,
      PersonFilters: {
        branchId: "",
        name: "",
        rating: "",
        date: new Date(props.searchFilter.dt) || new Date(),
        limit: props.searchFilter.limit,
        offset: props.searchFilter.offset,
      },
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(this.state.PersonFilters);
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.COLLECTION_VERIFICATION,
        { ...COLLECTION_VERIFICATION_FILTER }
      );
    }
  }

  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        dt: searchFilterObject.date,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    getAssignedMessengersListAction(
      searchFilterObject,
      (responseData) => {
        if (responseData.status === OPERATION_STATUS.SUCCESS) {
          if (
            responseData.data.data &&
            responseData.data.data.messengersList.length === 0
          ) {
            this.setState({
              messengerInfos: [],
              numberOfPages: responseData.data.data.numberOfPages,
              errorMessage: "",
              showLoader: false,
            });
          } else {
            this.setState({
              numberOfPages: responseData.data.data.numberOfPages,
              errorMessage: "",
            });
            this.getAllUserListProfilePic(
              responseData.data.data.messengersList
            );
          }
        } else {
          this.setState({
            errorMessage: responseData.error.message,
            showLoader: false,
          });
        }
      }
    );
  };

  setMessengerId = (Messenger) => {
    this.props.setMessengerIdAction(Messenger);
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.COLLECTION_VERIFICATION,
      this.state.searchFilter,
      SEARCH_FILTER_PAGES.COLLECTION_VERIFICATION
    );
  };

  setDateForMessenger = (messengerDate) => {
    this.props.setDateForMessengerAction(messengerDate);
  };

  //Get all users profile pics
  getAllUserListProfilePic = (usersResponseData) => {
    if (usersResponseData) {
      let profilePic = [];
      usersResponseData.map((users) => {
        this.props.getUserProfilePicUrlAction(
          users.userName,
          (response) => {
            if (
              response.status === OPERATION_STATUS.SUCCESS &&
              response.data.data.s3Url
            ) {
              profilePic.push({
                userName: users.userName,
                image: Utility.getDecryptedData(
                  response.data.data.s3Url
                ),
              });
            } else {
              profilePic.push({
                userName: users.userName,
                image: ProfileImage,
              });
            }
            this.setState(
              {
                usersProfilePic: profilePic,
              },
              () => {
                this.callCreateUsers(usersResponseData);
              }
            );
          }
        );
        return null;
      });
    }
  };

  callCreateUsers = (usersResponseData) => {
    this.setState({ usersResponseData: usersResponseData });
    if (this.state.usersResponseData) {
      this.createRows(usersResponseData);
    }
  };

  createRows = (responseData) => {
    //Row create for each element in response data array
    let rows = [],
      userData = responseData;
    _.forEach(userData, (userDetails) => {
      let userImage =
        this.getProfilePic(userDetails.userName) || ProfileImage;
      userDetails.profileUrl = userImage;
      delete userDetails.branchName;
      delete userDetails.ef;
      rows.push(userDetails);
    });
    this.setState({ messengerInfos: rows, showLoader: false });
  };

  getProfilePic = (username) => {
    let image = "";
    this.state.usersProfilePic &&
      this.state.usersProfilePic.map((users) => {
        if (users.userName === username) {
          image = users.image;
        }
        return null;
      });
    return image;
  };

  render() {
    return (
      <div className='minimartandUsers'>
        <span className='heading'>{Strings("MessengersList")}</span>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <MessengerList
          setMessengerId={this.setMessengerId}
          setDateForMessenger={this.setDateForMessenger}
          messengerInfos={this.state.messengerInfos}
          loggedInUser={this.props.loggedInUser}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          numberOfPages={this.state.numberOfPages}
          PersonFilters={this.state.PersonFilters}
          searchFilter={this.props.searchFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchFilter:
    state.searchFilterReducer.collectionVerificationSearchFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setMessengerIdAction,
      setDateForMessengerAction,
      getUserProfilePicUrlAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessengerListContainer);

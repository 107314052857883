import React from "react";
import { Row } from "react-bootstrap";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import SupplierCatalog from "./SupplierCatalog";
import withHOC from "../../HOC/HOC";

//Actions
import { addSupplierAction, supplierSearchFilterAction, updateSupplierAction } from "./SupplierCatalogAction"
import { bankDetailsSearchFilter, getAllAccountTypes } from "../BankConf/BankConfAction";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { USER_ROLES } from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";


class SupplierCatalogContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeAccountTypes: [],
            showLoader: false,
            message: "",
            level: "",
            toShowToast: false,
            suppliersList: [],
            bankDetails: [],
            numberOfPages: 1,
            errorMessage: "",
            readOnly:
                props.loggedInUser.role === USER_ROLES.analyst ||
                props.loggedInUser.role === USER_ROLES.operationsOfficer ||
                props.loggedInUser.role === USER_ROLES.operationsModerator ||
                props.loggedInUser.role === USER_ROLES.analystModerator ||
                props.loggedInUser.role === USER_ROLES.collectionsSupervisor ||
                props.loggedInUser.role === USER_ROLES.administrativeManager ||
                props.loggedInUser.role === USER_ROLES.customerService ||
                props.loggedInUser.role === USER_ROLES.salesManager ||
                props.loggedInUser.role === USER_ROLES.collectionsManager ||
                props.loggedInUser.role === USER_ROLES.technology,
        };
    }

    componentDidMount() {
        this.getAllAccountTypes(true);
        this.props.getBranchList();
        this.bankDetailsSearchFilter();
    }

    getAllAccountTypes = (ia = undefined) => {
        this.setState({
            showLoader: true,
        });

        let request = { ia: ia }

        getAllAccountTypes(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    activeAccountTypes: response.data.data,
                });
            } else {
                this.setState({
                    showLoader: false,
                    errorMessage: response.error.message,
                });
            }
        });
    }

    handleOnClickSearchFilter = (request) => {
        this.setState({
            showLoader: true,
        });
        supplierSearchFilterAction({ ...request }, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    suppliersList: response.data.data.li,
                    errorMessage: "",
                    numberOfPages: response.data.data.np,
                    showLoader: false,
                });
            } else {
                this.setState({
                    errorMessage: response.error.message,
                    showLoader: false,
                    suppliersList: [],
                    numberOfPages: 0,
                });
            }
        });
    };

    addSupplier = (request, callback) => {
        this.setState({
            showLoader: true,
            toShowToast: false,
            level: "",
            message: "",
        });
        addSupplierAction(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState(
                    {
                        errorMessage: "",
                        showLoader: false,
                        toShowToast: true,
                        message: Strings("supplierAddedSuccessfully"),
                        level: "success",
                    },
                    callback
                );
            } else {
                this.setState({
                    showLoader: false,
                    toShowToast: true,
                    message: Strings(response.error.message),
                    level: "error",
                });
            }
        });
    };
    updateSupplier = (request, callback) => {
        this.setState({
            showLoader: true,
            toShowToast: false,
            level: "",
            message: "",
        });
        updateSupplierAction(request, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState(
                    {
                        errorMessage: "",
                        showLoader: false,
                        toShowToast: true,
                        message: Strings("supplierUpdatedSuccessfully"),
                        level: "success",
                    },
                    callback
                );
            } else {
                this.setState({
                    showLoader: false,
                    toShowToast: true,
                    message: Strings(response.error.message),
                    level: "error",
                });
            }
        });
    };

    bankDetailsSearchFilter = () => {
        let searchFilterObject = {
            limit: 1000,
            offset: 0
        }
        bankDetailsSearchFilter(searchFilterObject, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    numberOfPages: response.data.data.np,
                    bankDetails: response.data.data.li,
                });
            } else {
                this.setState({
                    showLoader: false,
                    errorMessage: response.error.message,
                });
            }
        });
    }

    render() {
        return (
            <div>
                <Row className='heading'>{Strings("SupplierCatalog")}</Row>
                {this.state.toShowToast && (
                    <UserNotification
                        key={this.state.message}
                        userNotificationObj={{
                            message: this.state.message,
                            level: this.state.level,
                        }}
                    />
                )}
                <LoadingModal showLoadingModal={this.state.showLoader} />
                <SupplierCatalog
                    activeAccountTypes={this.state.activeAccountTypes}
                    branches={this.props.branches}
                    suppliersList={this.state.suppliersList}
                    numberOfPages={this.state.numberOfPages}
                    errorMessage={this.state.errorMessage}
                    handleOnClickSearchFilter={this.handleOnClickSearchFilter}
                    updateSupplier={this.updateSupplier}
                    addSupplier={this.addSupplier}
                    bankDetails={this.state.bankDetails}
                    readOnly={this.state.readOnly}
                />
            </div>
        );
    }
}

const SupplierCatalogContainerWrapper = withHOC(SupplierCatalogContainer);

export default SupplierCatalogContainerWrapper;
module.exports = {
  colors: {
    backgroundColor1: "#16314f",
    backgroundColor2: "#ececec",
    backgroundColor3: "#ffffff",
    backgroundColor4: "#f8f8f8",
    backgroundColor5: "#7F7F7F",
    backgroundColor6: "#5f7278",
    backgroundColor7: "#cbeda4",
    backgroundColor8: "#f0bc0c",

    textColor1: "#444444",
    textColor2: "#354052",
    textColor3: "#FFFFFF",
    textColor4: "#686868",
    textColor5: "#7C7C7C",
    textColor6: "#676767",
    textColor7: "#E16767",
    textColor8: "#E4C366",
    textColor9: "#FFFFFF",
    textColor10: "#5f7278",
    textColor11: "#a3a3a3",
    textColor12: "#797979",
    textColor13: "#3e3e3e",
    textColor14: "#44883d",
    textColor15: "#1616ff",

    piechartColor1: "#44883d",
    piechartColor2: "#7ca53f",
    piechartColor3: "#a8c665",
    piechartColor4: "#cad66e",
    piechartColor5: "#dc4d3d",
    piechartColor6: "#0477b3",
    piechartColor7: "#f4a055",
    piechartColor8: "#f97600",
    piechartColor9: "#dea209",
    piechartColor10: "#ded604",
    piechartColor11: "#afce04",
    piechartColor12: "#4ac100",
    piechartColor13: "#248e12",
    piechartColor14: "#3078db",
    piechartColor15: "#838bd5",
    piechartColor16: "#9361f5",
    piechartColor17: "#9e36d6",
    piechartColor18: "#dc1087",
    piechartColor19: "#f90600",
    piechartColor20: "#c74ed5",
    piechartColor21: "#f27300",
    piechartColor22: "#E2E8B3",
    piechartColor23: "#0062a3",
    piechartColor24: "#59c9d5",
    piechartColor25: "#a8b2b2",
    piechartColor26: "#efefef",
    piechartColor27: "yellow",
    piechartColor28: "brown",
    piechartColor29: "#2094a7",
    piechartColor30: "#886c64",
    piechartColor31: "#19c5c5",
    piechartColor32: "#6720b3",

    //businessType
    piechartColor33: "#4AB9F9",
    piechartColor34: "#4BAAFB",
    piechartColor35: "#4C9CFA",
    piechartColor36: "#4D8DFA",
    piechartColor37: "#4F7FFB",
    piechartColor38: "#5070FB",
    piechartColor39: "#5163FA",
    piechartColor40: "#7055FC",
    piechartColor41: "#7F56FC",
    piechartColor42: "#8F57FD",
    piechartColor43: "#9E58FD",
    piechartColor44: "#AE5AFD",
    piechartColor45: "#BD5BFE",
    piechartColor46: "#CC5CFE",
    piechartColor47: "#DA5DFE",
    piechartColor48: "#CE58F1",
    piechartColor49: "#C453E5",
    piechartColor50: "#B94FD8",
    piechartColor51: "#AE4ACB",
    piechartColor52: "#8D3CA5",
    piechartColor53: "#77338B",
    piechartColor54: "#7BE838",
    piechartColor55: "#A3BE0B",

    fieldBorderColor: "#979797",
    fieldBackgroundColor: "#D8D8D8",

    borderColor: "#ececec",
    borderColor2: "#e0e0e0",
    borderColor3: "#d2dae1",
    tabColor: "#D5D5D5",

    warningColor: "#f2c500",
    successColor: "#1fce6d",
    dangerColor: "#FF6A6E",
    errorColor: "#e94b35",

    buttonPrimary: "#13A3AB",
    hoverPrimary: "#B71C1C",

    tableHeaderBackground: "#51696A",
    tableCellLight: "#FFFFFF",
    tableCellDark: "#FFFFFF",
    tableTextColor: "#000000",

    barChartColor1: "#F47A89",
    barChartColor2: "#EDB862",
    barChartColor3: "#D5CD6D",
    barChartColor4: "#88B3E6",
    barChartColor5: "#C493EF",
    barChartColor6: "#9C0B0B",
    barChartColor7: "#c4b91c",
    barChartColor8: "#adb87a",
    barChartColor9: "#d18e75",

    cardBorderColor: "#17b824",
    cardReminderBorderColor: "#e94d4d",
    factoringCardBorderColor: "#ded604",
    creditScoreColor: {
      green: "#0aab3e",
      yellow: "#c5b80a",
      red: "#e6265b",
    },

    legendColor1: "#dc4d3d",
    legendColor2: "#80b561",
    legendColor3: "#f5a05c",
    legendColor4: "#0076b0",
    legendColor5: "#797979",

    webCollectionColor: "#f56d33",
  },
};

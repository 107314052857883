import {
  SET_MINIMART_DETAILS_ACTION,
  SET_APPROVE_MINIMART_DETAILS_ACTION,
  SET_APPROVEMINIMART_TAB,
  SET_REMINDER_MINIMART_COUNT,
  CLEAR_MINIMART_ACTION,
  SET_MINIMART_DETAILS_ACTIVE_TAB,
  SET_IS_NEW_MINIMART,
} from "../../redux/constants";

const INITIAL_STATE = {
  singleMiniMart: null,
  singleApproveMinimart: null,
  isHistory: false,
  selectedApproveMinimartTab: 0,
  reminderMinimartsCount: 0,
  activeTab: null,
  isNewMinimart: false,
};

export default function MinimartListReducer(state = INITIAL_STATE, action) {
  //NOSONAR -default parameter is required first in this case
  switch (action.type) {
    case SET_MINIMART_DETAILS_ACTION:
      return {
        ...state,
        singleMiniMart: action.singleMiniMart,
      };
    case SET_APPROVE_MINIMART_DETAILS_ACTION:
      return {
        ...state,
        singleApproveMinimart: action.singleMiniMart,
        isHistory: action.isHistory,
      };
    case SET_APPROVEMINIMART_TAB:
      return {
        ...state,
        selectedApproveMinimartTab: action.index,
      };
    case SET_REMINDER_MINIMART_COUNT:
      return {
        ...state,
        reminderMinimartsCount: action.count,
      };
    case CLEAR_MINIMART_ACTION:
      return {
        ...state,
        singleMiniMart: INITIAL_STATE.singleMiniMart,
        singleApproveMinimart: INITIAL_STATE.singleApproveMinimart,
        isHistory: false,
        selectedApproveMinimartTab: 0,
      };
    case SET_MINIMART_DETAILS_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case SET_IS_NEW_MINIMART:
      return {
        ...state,
        isNewMinimart: action.isNewMinimart,
      };
    default:
      return state;
  }
}

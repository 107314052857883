import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";
import { SET_IS_NEW_MINIMART } from "../../redux/constants";

export const downloadMinimartListReportAction = (
  searchFilterObject,
  fileType,
  callback
) => {
  //NOSONAR
  let downloadFile = true;
  let requestData =
    Object.keys(searchFilterObject).length >= 1
      ? {
          search: {
            minimartName:
              searchFilterObject.minimartName &&
              searchFilterObject.minimartName !== ""
                ? searchFilterObject.minimartName
                : undefined,
            zone:
              searchFilterObject.zone &&
              searchFilterObject.zone !== ""
                ? searchFilterObject.zone
                : undefined,
            ciId:
              searchFilterObject.ciId &&
              searchFilterObject.ciId !== ""
                ? searchFilterObject.ciId
                : undefined,
            type:
              searchFilterObject.type &&
              searchFilterObject.type !== ""
                ? searchFilterObject.type
                : undefined,
            grade:
              searchFilterObject.grade &&
              searchFilterObject.grade !== ""
                ? searchFilterObject.grade
                : undefined,
          },
        }
      : {
          search: {},
        };
  callAPI(
    "downloadMinimartListReport",
    API.downloadMinimartListReport + fileType,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    },
    downloadFile
  );
};

export const setIsNewMinimartAction =
  (isNewMinimart) => (dispatch) => {
    dispatch({
      type: SET_IS_NEW_MINIMART,
      isNewMinimart,
    });
  };

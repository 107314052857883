import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";
import {
  SET_LOAN_DETAILS_ACTION,
  SET_ANALYST_LOAN_DETAILS_ACTION,
  SET_EMI_CONFIGURATION_ACTION,
} from "../../redux/constants";

//action with dispatch for storing data in reducer
export const setLoanDetailsIDAction = (loanDetails) => (dispatch) => {
  dispatch({
    type: SET_LOAN_DETAILS_ACTION,
    loanDetails: loanDetails,
  });
};

export const setAnalystLoanDetailsIDAction =
  (loanDetails, isHistory = false) =>
    (dispatch) => {
      dispatch({
        type: SET_ANALYST_LOAN_DETAILS_ACTION,
        loanDetails: loanDetails,
        isHistory: isHistory,
      });
    };

export const setEmiConfiguration = (levelConfig, loanConfig) => (dispatch) => {
  dispatch({
    type: SET_EMI_CONFIGURATION_ACTION,
    levelConfig: levelConfig,
    loanConfig: loanConfig,
  });
}

export const getLoanDetailsByIdAction = (
  requestData
) => {
  let requestStringUrl = API.getLoanDetailsById.concat(
    requestData.applicationId
  );

  if (requestData.loanId) {
    requestStringUrl =
      requestStringUrl + "&loanId=" + requestData.loanId;
  }
  if (
    requestData.isAnalyst ||
    requestData.isAnalystModerator ||
    ((requestData.isOperationsModerator || requestData.isOperationsManager) &&
      requestData.isAnalystDashboard)
  ) {
    let subString = requestData.applicationId + "/details";
    requestStringUrl = API.getAnalystloanDetails.concat(subString);
  }

  if (requestData.isOperationOfficer) {
    let subString = requestData.applicationId + "/details";
    requestStringUrl =
      API.getLoanDetailsForOperationOfficer.concat(subString);
  }

  if (requestData.isAccountingOfficer) {
    let subString = requestData.applicationId + "/details";
    requestStringUrl =
      API.getLoanDetailsForAccountingOfficer.concat(subString);
  }

  callAPI(
    "getLoanDetailsById",
    requestStringUrl,
    "GET",
    {},
    (apiResponse) => {
      requestData.callback(apiResponse);
    }
  );
};

export const getAmortizationsTableAction = (
  requestData,
  callback
) => {
  let requestString =
    "?loanId=" +
    requestData.loanId +
    "&inl=" +
    requestData.inl +
    "&limit=" +
    requestData.limit +
    "&offset=" +
    requestData.offset;
  callAPI(
    "getAmortizationsTable",
    API.getAmortizationsTable.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getNegotiationRequestAction = (loanId, callback) => {
  callAPI(
    "getNegotiationRequest",
    API.getNegotiationRequest.concat(loanId),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

/* #region  Take Off Late Fees */

export const requestForTakeOffLateFeesAction = (
  request,
  isTakeOffLateFeeApply,
  callback
) => {
  callAPI(
    "requestForTakeOffLateFees",
    isTakeOffLateFeeApply
      ? API.requestForTakeOffLateFeesApply
      : API.requestForTakeOffLateFees,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const approveOrRejectRequestForTakeOffLateFeesAction = (
  request,
  callback
) => {
  callAPI(
    "approveOrRejectRequestForTakeOffLateFees",
    API.approveOrRejectRequestForTakeOffLateFees,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

/* #endregion */

/* #region  Assign Repayment */

export const requestForAssignRepaymentAction = (
  request,
  isNewAssignRepaymentReq,
  callback
) => {
  callAPI(
    "requestForAssignRepayment",
    isNewAssignRepaymentReq
      ? API.requestForAssignNewPlanApply
      : API.requestForAssignRepayment,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const approveOrRejectRequestForAssignRepaymentAction = (
  request,
  callback
) => {
  callAPI(
    "approveOrRejectRequestForAssignRepayment",
    API.approveOrRejectRequestForAssignRepayment,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const viewPreviousRequestAction = (request, callback) => {
  let requestString = request.loanId + "/" + request.type;
  callAPI(
    "viewPreviousRequest",
    API.getNegotiationRequest.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getDenialReasonsAction = (request, callback) => {
  let requestString =
    request.lid + "&limit=" + request.lt + "&offset=" + request.oft;
  callAPI(
    "GetDenialResons",
    API.getDenialReasons.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const sendUpdatedMinimartDetailsAction = (
  request,
  callback
) => {
  callAPI(
    "sendUpdatedMinimartDetails",
    API.sendUpdatedMinimartDetails,
    "PATCH",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};


export const editScalaMinimartRequest = (reqObj, callback) => {
  callAPI(
    "editScalaMinimartRequest",
    API.editScalaMinimartRequest,
    "PATCH",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const changeCollectionDaysAction = (request, callback) => {
  callAPI(
    "changeCollectionDays",
    API.changeCollectionDays,
    "PATCH",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const loanCategoriesDetailsAction = (request, callback) => {
  callAPI(
    "loanCategoriesDetails",
    API.loanCategoriesDetails,
    "PATCH",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getLoanAmountPaidDivisionAction = (
  requestParam,
  callback
) => {
  const requestURI =
    API.getLoanAmountPaidDivision +
    "?ix=" +
    requestParam.ix +
    "&lid=" +
    requestParam.lid +
    "&ic=" +
    requestParam.ic +
    "&inl=" +
    requestParam.inl +
    "&dd=" +
    requestParam.dd;
  callAPI(
    "getLoanAmountPaidDivision",
    requestURI,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const closeLoanApplicationAction = (request, callback) => {
  callAPI(
    "closeLoanApplication",
    API.closeLoanApplication,
    "POST",
    request,
    (apiResponse) => callback(apiResponse)
  );
};

export const getLoanDetailsDownloadedAction = (
  downloadUrl,
  requestData,
  callback
) => {
  let requestUrl = downloadUrl;
  callAPI(
    "getLoanDetailsDownloadedAction",
    API.getReports.concat(requestUrl),
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    },
    true
  );
};

export const addEMINoteAction = (noteObject, callBack) => {
  callAPI(
    "addEMINote",
    API.addEMINote,
    "POST",
    noteObject,
    (apiResponse) => {
      callBack(apiResponse);
    }
  );
};

export const getAllowedFrequencyAction = (request, callBack) => {
  let requestParams =
    API.getAllowedFrequency +
    request.level +
    "&amt=" +
    request.loanApprovedAmount;
  callAPI(
    "getAllowedFrequency",
    requestParams,
    "GET",
    {},
    (apiResponse) => {
      callBack(apiResponse);
    }
  );
};

export const getLoanInitiatedFromAction = (
  callback
) => {
  callAPI(
    "getInitiatedFrom",
    API.initiatedFrom,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};
/* #endregion */

import React from "react";
import { Row, Col } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import _ from "lodash";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SupplierCatalogModal from "./SupplierCatalogModal";

//Constants
import {
    DEFAULT_PAGINATION_RECORDS_COUNT,
    DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
    SEARCH_FILTER_TYPE,
    SEARCH_FILTER_FIELDS,
    FILE_CONFIGURATIONS,
    MOTORCYCLE_FILE_SIZE,
    DEFAULT_VALUES
} from "../../constants/appConstants";
import { RNC_VERIFICATION, STATUS_SELECTION } from "../MotorcycleCatalog/Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

export default class SupplierCatalog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
            activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
            supplierFilters: {},
            showsupplierDetails: false,
            selectedSupplier: {},
            errorMessage: "",
            avatar: null,
        };
    }

    componentDidMount() {
        this.handleOnClickSearchFilter();
    }

    handleOnClickSearchFilter = (isDefaultOffset = false) => {
        isDefaultOffset &&
            this.setState({
                activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
            });
        let isActive = undefined
        let isVerified = undefined
        if (this.state.supplierFilters.ia === "ACTIVE") {
            isActive = true;
        } else if (this.state.supplierFilters.ia === "INACTIVE") {
            isActive = false
        }
        if (this.state.supplierFilters.ve === "VERIFIED") {
            isVerified = true;
        } else if (this.state.supplierFilters.ve === "UNVERIFIED") {
            isVerified = false
        }

        let searchFilterObject = {
            bn: this.state.supplierFilters.bn || undefined,
            ai: this.state.supplierFilters.ai || undefined,
            ia: isActive,
            bid: this.state.supplierFilters.bid || undefined,
            ve: isVerified,
            limit: this.state.recordsPerPage,
            offset: this.state.activePage - 1
        }
        this.props.handleOnClickSearchFilter(searchFilterObject);
    };
    handleOnClickResetFilter = () => {
        this.setState(
            {
                recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
                activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
                supplierFilters: { bn: "", ai: "", ia: "", bid: "", ve: "" },
            },
            this.handleOnClickSearchFilter
        );
    };

    handleInputChnage = (event) => {
        let name = event.target.name;
        let value = DEFAULT_VALUES.includes(event.target.value) ? "" : event.target.value;
        this.setState({
            supplierFilters: {
                ...this.state.supplierFilters,
                [name]: name === "bn" ? value.toUpperCase() : value,
            },
            errorMessage: "",
        });
    };

    renderSearchFilter = () => {
        let branches =
            this.props.branches?.map((singleBranch) => {
                return {
                    label: singleBranch.name,
                    value: singleBranch.id,
                };
            });
        let activeAccountTypes = [...this.props.activeAccountTypes];
        let bankDetails = [];
        if (this.state.supplierFilters.bn) {
            bankDetails = this.props.bankDetails.filter((bankDetail) => this.state.supplierFilters.bn === bankDetail.bn)
            activeAccountTypes = bankDetails[0].ad;
        }
        if (this.props.activeAccountTypes.length !== 0 && activeAccountTypes[0].at !== Strings("accountTypes")) {
            activeAccountTypes.unshift({ ai: "", at: Strings("accountTypes") })
        }
        let bankNames = this.props.bankDetails.map((bankDetail) => bankDetail.bn);
        if (bankNames.length !== 0 && !bankNames.includes("bankNames")) {
            bankNames.unshift("bankNames")
        }
        let supplierFilters = [
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleInputChnage,
                Value: this.state.supplierFilters.bn,
                name: "bn",
                searchPlaceHolder: Strings("bankName"),
                fieldname: SEARCH_FILTER_FIELDS.BANK_NAME,
                filterDropdownList: bankNames,
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleInputChnage,
                Value: this.state.supplierFilters.ai,
                fieldname: SEARCH_FILTER_FIELDS.ACCOUNT_TYPE,
                filterDropdownList: activeAccountTypes,
                name: "ai",
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                searchPlaceHolder: Strings("status"),
                handleOnChange: this.handleInputChnage,
                Value: this.state.supplierFilters.ia,
                filterDropdownList: STATUS_SELECTION,
                fieldname: SEARCH_FILTER_FIELDS.STATUS,
                name: "ia",
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleInputChnage,
                Value: this.state.supplierFilters.bid,
                fieldname: SEARCH_FILTER_FIELDS.BRANCH,
                filterDropdownList: branches,
                name: "bid"
            },
            {
                type: SEARCH_FILTER_TYPE.SELECTION,
                handleOnChange: this.handleInputChnage,
                Value: this.state.supplierFilters.ve,
                fieldname: SEARCH_FILTER_FIELDS.RNC_VERIFICATION,
                filterDropdownList: RNC_VERIFICATION,
                name: "ve"
            },
        ];
        return (
            <SearchFilter
                filters={supplierFilters}
                handleOnClickSearchFilter={this.handleOnClickSearchFilter}
                handleOnClickResetFilter={this.handleOnClickResetFilter}
                removeLeftPadding={true}
                addButton={!this.props.readOnly && true}
                handleAddUser={this.onAddsupplierClick}
                buttonText={Strings("addSupplier")}
                paddingLeft={3}
                buttonWidth={" pt-2"}
            />
        );
    };

    onPageChange = ({ page, pageLength }) => {
        this.setState(
            {
                activePage: page,
                recordsPerPage: pageLength,
            },
            this.handleOnClickSearchFilter
        );
    };

    onsupplierClick = (selectedSupplier) => {
        this.setState({
            showsupplierDetails: true,
            selectedSupplier,
        });
    };

    onAddsupplierClick = () => {
        this.setState({
            showsupplierDetails: true,
            selectedSupplier: {},
            errorMessage: "",
        });
    };

    closeModal = () => {
        this.setState({
            showsupplierDetails: false,
            errorMessage: "",
            selectedSupplier: {

            },
        });
    };

    handleUploadPhoto = (event) => {
        let refThis = this;
        let photo = event.target.files[0];
        if (photo) {
            let fileExtension = Utility.getFileExtension(photo);
            let validFile = true;
            if (
                !_.includes(
                    FILE_CONFIGURATIONS.allowedImageExtensions,
                    fileExtension
                )
            ) {
                validFile = false;
                this.setState({
                    errorMessage: Strings("fileExtension"),
                });
            }
            if (validFile && photo.size > MOTORCYCLE_FILE_SIZE) {
                validFile = false;

                this.setState({
                    errorMessage: Strings("sizeIsTooLarge"),
                });
            }
            if (validFile) {
                try {
                    Resizer.imageFileResizer(
                        event.target.files[0],
                        400,
                        400,
                        fileExtension,
                        50,
                        0,
                        (uri) => {
                            try {
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                    refThis.setState({
                                        avatar: reader.result,
                                    });
                                };
                                reader.readAsDataURL(uri);
                                this.setState({
                                    selectedSupplier: {
                                        ...this.state.selectedSupplier,
                                        img: uri,
                                    },
                                    errorMessage: "",
                                });
                            } catch (err) {
                                this.setState({
                                    errorMessage: Strings("imageUploadFailed"),
                                });
                            }
                        },
                        "file"
                    );
                } catch (err) {
                    this.setState({
                        errorMessage: Strings("imageUploadFailed"),
                    });
                }
            }
        }
    };
    handleRemoveImage = () => {
        this.setState({
            avatar: null,
            selectedSupplier: {
                ...this.state.selectedSupplier,
                img: null,
            },
            errorMessage: "",
        });
    };


    validsupplier = () => {
        if (_.isEmpty(this.state.selectedSupplier)) {
            this.setState({ errorMessage: Strings("EmptyFieldsError") });
            return false;
        } else if (!this.state.selectedSupplier?.rnc
            || this.state.selectedSupplier.rnc.trim().length < 3) {
            this.setState({ errorMessage: Strings("RNC_NOT_FOUND") });
            return false;
        } else if (!this.state.selectedSupplier?.businessName
            || this.state.selectedSupplier.businessName.trim().length < 3) {
            this.setState({ errorMessage: Strings("businessNameNotValid") });
            return false;
        } else if (!this.state.selectedSupplier?.phoneNumber
            || this.state.selectedSupplier.phoneNumber.trim().length !== 10) {
            this.setState({ errorMessage: Strings("phoneNumberNotValid") });
            return false;
        } else if (!this.state.selectedSupplier?.contact
            || this.state.selectedSupplier.contact.trim().length < 3) {
            this.setState({ errorMessage: Strings("contactNotValid") });
            return false;
        } else if (!this.state.selectedSupplier?.branchIds
            || this.state.selectedSupplier.branchIds.length === 0) {
            this.setState({ errorMessage: Strings("ErrorBranchNotFound") });
            return false;
        } else if (!this.state.selectedSupplier?.bankName) {
            this.setState({ errorMessage: Strings("bankNameNotValid") });
            return false;
        } else if (!this.state.selectedSupplier?.accountType) {
            this.setState({ errorMessage: Strings("accountTypeNotValid") });
            return false;
        } else if (!this.state.selectedSupplier?.accountNumber
            || this.state.selectedSupplier.accountNumber.trim().length < 6) {
            this.setState({ errorMessage: Strings("accountNumberNotValid") });
            return false;
        } else if (!this.state.selectedSupplier?.isActive) {
            this.setState({ errorMessage: Strings("statusNotValid") });
            return false;
        } else if (!this.state.selectedSupplier?.img) {
            this.setState({ errorMessage: Strings("PleaseAddImage") });
            return false;
        } else {
            this.setState({ errorMessage: "" })
            return true;
        }
    };

    onSaveClick = () => {
        if (this.validsupplier()) {
            let request = { ...this.state.selectedSupplier };
            if (Object.prototype.toString.call(request.img) ===
                "[object String]") {
                delete request.img
            }
            if (request.supplierId === null || request.supplierId === undefined) {
                // Add supplier product
                this.props.addSupplier(request, () => {
                    this.closeModal();
                    this.handleOnClickResetFilter();
                });
            } else {
                // Edit/Update supplier product

                this.props.updateSupplier(request, () => {
                    this.closeModal();
                    this.handleOnClickResetFilter();
                });
            }
        }
    };

    handleModalInputChange = (event) => {
        this.setState({
            selectedSupplier: {
                ...this.state.selectedSupplier,
                [event.target.name]: DEFAULT_VALUES.includes(event.target.value) ? "" : event.target.value
            },
            errorMessage: ""
        })
    }

    renderSupplierCatalogModal = () => {
        let activeAccountTypes = [...this.props.activeAccountTypes];
        if (this.props.activeAccountTypes.length !== 0 && activeAccountTypes[0].at !== Strings("accountTypes")) {
            activeAccountTypes.unshift({ ai: "", at: Strings("accountTypes") })
        }

        return (
            <SupplierCatalogModal
                modalOpen={this.state.showsupplierDetails}
                headerText={
                    this.state.selectedSupplier.supplierId
                        ? "updateSupplier"
                        : "addSupplier"
                }
                errorMessage={this.state.errorMessage}
                supplier={this.state.selectedSupplier}
                avatar={this.state.avatar}
                onSaveClick={this.onSaveClick}
                handleModalClose={this.closeModal}
                handleInputChnage={this.handleModalInputChange}
                handleUploadPhoto={this.handleUploadPhoto}
                handleRemoveImage={this.handleRemoveImage}
                branches={this.props.branches}
                bankDetails={this.props.bankDetails}
                activeAccountTypes={activeAccountTypes}
                readOnly={this.props.readOnly}
            />
        );
    };

    render() {
        return (
            <div className='ListContainer'>
                {this.state.showsupplierDetails &&
                    this.renderSupplierCatalogModal()}
                {this.renderSearchFilter()}
                <Row>
                    <Col md={12} className=' errorText'>
                        {this.props.errorMessage}
                    </Col>
                </Row>
                {this.props.suppliersList &&
                    this.props.suppliersList.length !== 0 ? (
                    <Row className='cardAlign'>
                        <Col md={12} className='w-100 pr-0'>
                            <Row className='w-100'>
                                {this.props.suppliersList.map((supplier) => {
                                    return (
                                        <CustomCard
                                            card={supplier}
                                            key={supplier.supplierId}
                                            cardType='supplier'
                                            isMessenger={false}
                                            handleCardClick={this.onsupplierClick}
                                        />
                                    );
                                })}
                            </Row>
                        </Col>
                        <Col md={12} className='pt-3'>
                            <Pagination
                                activePage={this.state.activePage}
                                recordsPerPage={this.state.recordsPerPage}
                                numberOfPages={this.props.numberOfPages}
                                onPageChange={this.onPageChange}
                                dropup={true}
                            />
                        </Col>
                    </Row>
                ) : (
                    <div className='noRecordsFound'>
                        {Strings("NoRecordsFound")}
                    </div>
                )}
            </div>
        );
    }
}

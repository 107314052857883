import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import CiInterface from "./CiInterface";

//Actions
import {
  setFileDetailsAction,
  getAllPreviouslyUploadedFilesAction,
} from "./CIInterfaceActions";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import { setSearchFilterAction } from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import { GET_CI_FILE_COLUMNS } from "./CIConstants";
import {
  CONFIGURATION_DATE_TIME_FORMAT,
  SEARCH_FILTER_PAGES,
  CI_INTERFACE_FILTER,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./CiInterface.scss";

class CiInterfaceContainer extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this._columns = GET_CI_FILE_COLUMNS();
    this.state = {
      filename: "",
      fileType: "",
      showLoader: false,
      isUploadSuccess: false,
      isScheduled: false,
      isFileDelete: false,
      rows: [],
      errorMessage: "",
      previouslyUploadedFiles: [],
      showListModal: false,
      showProcessModal: false,
      showNodataModal: false,
      docsStatuses: "",
      documentStatus: [],
      isLoading: false,
    };
    this.isRowClick = false;
  }

  componentDidMount() {
    this.props.clearDailyStatusDateAction();
    this.initErrorStates();
  }

  componentWillUnmount() {
    if (!this.isRowClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.CI_INTERFACE,
        { ...CI_INTERFACE_FILTER }
      );
    }
  }
  /* #endregion */

  /* #region  Methods */

  initErrorStates = () => {
    this.setState({
      errorMessage: "",
      isScheduled: false,
    });
  };
  setFileDetails = (File) => {
    this.props.setFileDetailsAction(File);
    this.props.history.push(ROUTES.FILE_CONTENT);
  };

  /* #endregion */

  /* #region  Actions */
  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        name: searchFilterObject.name,
        status: searchFilterObject.status,
        processedAt: searchFilterObject.processedAt,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    getAllPreviouslyUploadedFilesAction(
      searchFilterObject,
      (responsedata) => {
        if (responsedata.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            numberOfPages: responsedata.data.data.numberOfPages,
            showLoader: false,
            errorMessage: "",
          });
          this.createRows(responsedata.data.data.cidocumentDetails);
        } else {
          this.setState({
            errorMessage: responsedata.error.message,

            showLoader: false,
          });
        }
      }
    );
  };

  /* #endregion */

  /* #region  Row Methods of Grid */

  createRows = (responseData) => {
    //Row create for each element in response data array
    let rows = [],
      FileData = responseData;

    _.forEach(FileData, (fileDetails) => {
      rows.push({
        name: fileDetails.name,
        status: Strings(fileDetails.status),
        uploadedAt: moment(fileDetails.createdDate).format(
          CONFIGURATION_DATE_TIME_FORMAT
        ),
        processedAt: fileDetails.processedAt
          ? moment(fileDetails.processedAt).format(
              CONFIGURATION_DATE_TIME_FORMAT
            )
          : Strings("NA"),
        filePreservedData: fileDetails,
      });
    });
    this.setState({ rows: rows });
  };

  callbackOnRowClick = (row) => {
    if (row !== undefined) {
      this.setFileDetails(this.state.rows[row].filePreservedData);
      this.isRowClick = true;
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.CI_INTERFACE,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.CI_INTERFACE
      );
    }
  };

  renderUserNotification = (key, message, level) => {
    return (
      <UserNotification
        key={key}
        userNotificationObj={{
          message: { message },
          level: { level },
        }}
      />
    );
  };

  /* #endregion */

  render() {
    return (
      <div>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isScheduled &&
          this.state.errorMessage === "" &&
          this.renderUserNotification(
            "ScheduledSuccessFully",
            Strings("ScheduledSuccessFully"),
            "success"
          )}
        {this.state.isUploadSuccess &&
          this.renderUserNotification(
            "CSVFileUploadedSuccessfully",
            Strings("CSVFileUploadedSuccessfully"),
            "success"
          )}
        {this.state.isUploadError &&
          this.renderUserNotification(
            "CSVFileUploadError",
            Strings("CSVFileUploadError"),
            "error"
          )}
        {this.state.isFileDelete &&
          this.renderUserNotification(
            "DeleteFileSuccess",
            Strings("DeleteFileSuccess"),
            "success"
          )}
        <CiInterface
          rows={this.state.rows}
          uploadFile={this.uploadCSVFile}
          errorMessage={this.state.errorMessage}
          initErrorStates={this.initErrorStates}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          numberOfPages={this.state.numberOfPages}
          onRowClick={this.callbackOnRowClick}
          columns={this._columns}
          rowsCount={this.state.rows.length}
          rowHeight={60}
          headerRowHeight={40}
          previouslyUploadedFiles={this.state.previouslyUploadedFiles}
          searchFilter={this.props.searchFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  branches: state.branchListReducer.branches,
  searchFilter: state.searchFilterReducer.ciSearchFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setFileDetailsAction,
      clearDailyStatusDateAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CiInterfaceContainer);

//Libraries
import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fileDownload from "js-file-download";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import RemittancePayment from "./RemittancePayment";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

//Actions
import { dowloadedAction } from "../../constants/Action";
import {
  getRemittancePaymentAction,
  markPaidRemittanceAction,
} from "./RemittancePaymentAction";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

class RemittancePaymentContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      errorMessage: "",
      isError: false,
      remittancePaymentData: [],
    };
  }

  submitInputs = (requestData) => {
    markPaidRemittanceAction(requestData, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            errorMessage: "",
            showLoader: false,
          },
          () => {
            this.handleOnClickSearchFilter(
              this.state.prevRequestData
            );
          }
        );
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
          isError: true,
        });
      }
    });
  };
  handleOnClickSearchFilter = (requestData) => {
    this.setState({
      showLoader: true,
      prevRequestData: requestData,
    });
    getRemittancePaymentAction(requestData, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          remittancePaymentData: response.data.data.rpl,
          numberOfPages: response.data.data.np,
          showLoader: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  downloadReports = (url, searchFilterObject, fileType) => {
    this.setState({ showLoader: true, isError: false });
    dowloadedAction(
      url,
      searchFilterObject,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
            fileDownload(
              response.data,
              Strings("REMITTANCE_PAYMENT") +
                "-" +
                Utility.getReportFormattedDateString(
                  Utility.convertDateIntoMiliSeconds(new Date())
                ) +
                Utility.getFileType(fileType)
            );
            this.setState({
              errorMessage: "",
              showLoader: false,
              isError: false,
            });
          } else {
            this.setState({
              showLoader: false,
              isError: true,
              errorMessage: Strings("NoRecordsFound"),
            });
          }
        } else {
          this.setState({
            showLoader: false,
            errorMessage: response.error.message,
          });
        }
      },
      "RemittancePayment"
    );
  };

  render() {
    return (
      <div>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isError && (
          <UserNotification
            key='Error'
            userNotificationObj={{
              message: this.state.errorMessage,
              level: "error",
            }}
          />
        )}
        <Row className='heading'>{Strings("REMITTANCE_PAYMENT")}</Row>
        <RemittancePayment
          numberOfPages={this.state.numberOfPages}
          remittancePaymentData={this.state.remittancePaymentData}
          submitInputs={this.submitInputs}
          errorMessage={this.state.errorMessage}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleDownloadFile={this.downloadReports}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); //NOSONAR

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ dowloadedAction }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemittancePaymentContainer);

import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

import { OPERATIONS_OFFICER_ACTION } from "./Constants";
export const getCreditScoreOfMinimartAction = (
  requestData,
  callback
) => {
  callAPI(
    "getCreditScoreOfMinimartForAnalyst",
    API.getCreditScoreForAnalyst.replace("<minimartId>", requestData),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getCredoCreditScoreAction = (requestData, callback) => {
  callAPI(
    "getCredoCreditScoreAction",
    API.getCredoCreditScore.replace("loanId", requestData),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateLoanApplicationStatusAction = (
  requestData,
  callBack,
  isAnalyst = false,
  isOperationsOfficer = false,
  isAccountsOfficer = false,
  isAnalystModerator = false,
  isOperationsModerator = false,
  isOperationsManager = false,
) => {
  let requestString = "";
  if (isAnalyst || isAnalystModerator) {
    requestString = API.updateLoanApplicationStatusForAnalyst;
  }
  if (isOperationsOfficer || isOperationsModerator || isOperationsManager) {
    requestString =
      requestData.status === OPERATIONS_OFFICER_ACTION.DISBURSE
        ? API.updateLoanStatusForDisburment
        : API.updateLoanStatusForOperationsOfficer;
  }
  if (isAccountsOfficer) {
    requestString = API.updateLoanApplicationStatusForAccountsOfficer;
  }
  callAPI(
    "updateLoanApplicationStatus",
    requestString,
    requestData.status === OPERATIONS_OFFICER_ACTION.DISBURSE
      ? "PATCH"
      : "POST",
    requestData,
    (apiResponse) => {
      callBack(apiResponse);
    }
  );
};

export const calculateCreditScoreAction = (request, callBack) => {
  callAPI(
    "calculateCreditScore",
    API.calculateCreditScore,
    "POST",
    request,
    (apiResponse) => {
      callBack(apiResponse);
    }
  );
};

export const AddNoteAction = (noteObject, callBack) => {
  callAPI(
    "addNote",
    API.addNote,
    "POST",
    noteObject,
    (apiResponse) => {
      callBack(apiResponse);
    }
  );
};

export const deleteNoteAction = (noteId, callback) => {
  callAPI(
    "deleteNote",
    API.deleteNote.replace("<noteId>", noteId),
    "DELETE",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getNoteAction = (noteId, callback) => {
  callAPI(
    "getNoteWithId",
    API.getNoteWithId.replace("<id>", noteId),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateNoteAction = (noteObject, callback) => {
  callAPI(
    "updateNote",
    API.updateNote.replace("<id>", noteObject.noteId),
    "PUT",
    { note: noteObject.note },
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAnalystDraftedInputAction = (
  applicationId,
  callback
) => {
  let requestString = "";
  requestString = API.getAnalystDraftedInput.concat(applicationId);
  callAPI(
    "getUserDraftedInput",
    requestString,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getDraftedNegotiationAction = (
  applicationId,
  callback
) => {
  callAPI(
    "getDraftedNegotiation",
    API.getDraftedNegotiation.concat(applicationId),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getEmiDurationAction = (requestObject, callback) => {
  let requestString =
    requestObject.minimartId +
    "&loanType=" +
    requestObject.loanType +
    "&amount=" +
    requestObject.amount +
    "&term=" +
    requestObject.term +
    "&appId=" +
    requestObject.applicationId +
    "&collectionDays=" + requestObject.collectionDays;
  if (requestObject.frequency) {
    requestString += "&frequency=" +
      requestObject.frequency
  }

  callAPI(
    "getEmiDuration",
    API.getEmiDuration.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateLoanNegotiationAndTermsAction = (
  NegotiationObject,
  callback
) => {
  callAPI(
    "updateLoanNegotiationAndTerms",
    API.updateLoanNegotiationAndTerms,
    "PATCH",
    NegotiationObject,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateOperationsOfficerInputsAction = (
  request,
  callback
) => {
  callAPI(
    "updateOperationsOfficerInputs",
    API.updateOperationsOfficerInputs,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateAccountingOfficerInputAction = (
  request,
  callback
) => {
  callAPI(
    "updateAccountingOfficerInput",
    API.updateAccountingOfficerInput,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getOperationsOfficerDraftedInputAction = (
  applicationId,
  callback
) => {
  let requestString = "";
  requestString =
    API.getDraftedInputsForOperationsOfficer.concat(applicationId);

  callAPI(
    "getDraftedInputsForOperationsOfficer",
    requestString,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAccountingOfficersDraftedInputAction = (
  applicationId,
  callback
) => {
  let requestString = "";
  requestString =
    API.getAccountingDraftedInputs.concat(applicationId);
  callAPI(
    "getAccountingOfficersDraftedInput",
    requestString,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getApprovalNotesAction = (applicationId, callback) => {
  callAPI(
    "getApprovalNotes",
    API.getApprovalNotes.concat(applicationId),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const deleteEvidenceDocumentAction = (
  documentId,
  callback
) => {
  callAPI(
    "deleteEvidenceDocumentAction",
    API.deleteEvidenceDocument.concat("?documentId=" + documentId),
    "DELETE",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getConfigTermEmi = (type, callback) => {
  callAPI(
    "getConfigTermEmiAction",
    API.getConfigTermEmi.concat("?type=" + type),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const downloadDownpaymentReceiptAction = (
  recepitId,
  downloadFile,
  callback
) => {
  callAPI(
    "downloadDownPaymentRecepit",
    API.downloadDownPaymentRecepit.concat(`${recepitId}/download`),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    },
    downloadFile
  );
};

export const onSaveConciliationAction = (request, callback) => {
  callAPI(
    "onSaveConciliationAction",
    API.saveConciliation,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateOfficerInputAction = (request, callback) => {
  callAPI(
    "updateOfficerInput",
    API.updateOfficerInput,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const uploadTransferRecieptAction = (request, callback) => {
  let requestInfo = new FormData();
  requestInfo.append("applicationId", request.applicationId);
  requestInfo.append("receipt", request.receipt);
  requestInfo.append("fileType", request.fileType);
  callAPI(
    "uploadTransferReciept",
    API.uploadTransferReciept,
    "POST",
    requestInfo,
    (apiResponse) => {
      callback(apiResponse);
    }, false, true
  );
};


// get dropdown values for loan application form 
export const getDropdownValuesForLoanApplicationFormAction = (
  minimartId,
  callback
) => {
  callAPI(
    "getDropdownValuesForLoanApplicationForm",
    API.getDropdownValuesForLoanApplicationForm.concat(minimartId),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

//get dropdown values for supplier details
export const getDropdownValuesForSupplierFormAction = (
  callback
) => {
  callAPI(
    "getSupplierConfigurationValues",
    API.getSupplierConfigurationValues,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

//Update loan application form by analyst
export const updateLoanApplicationAction = (request, callback) => {
  callAPI(
    "updateLoanApplication",
    API.updateLoanApplication,
    "PUT",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

//Get Loan List for Conciliation
export const getLoanDetailsForConciliationAction = (request, callback) => {
  let requestString = "";
  requestString =
    API.getLoanDetailsForConciliation.concat(request.minimartId + "&newAppId=" + request.applicationId);
  callAPI(
    "getLoanDetailsForConciliation",
    requestString,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
}

//Get Possible Collection cycles on the basis of minimart id and frequency
export const getPossibleCollectionCycleAction = (request, callback) => {
  let requestString = "fr=" + request.fr + "&mid=" + request.mid;
  if (request.frs) {
    requestString = requestString + "&frs=" + request.frs
  }
  callAPI(
    "getPossibleCollectionCycle",
    API.getPossibleCollectionCycle.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
} 

export const getDisbursementStatusAction = (request, callback) => {
  callAPI(
    "getDisbursementStatus",
    API.getDisbursementStatus.concat(request),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
}
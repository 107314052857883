import React from "react";
import { Row, Col } from "react-bootstrap";
import Rater from "react-rater";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

//components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";

//Constants
import {
  DEFAULT_DATE_FORMAT,
  TABLE_COLUMNS,
  LIST_WORK_HISTORY,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  INVOICE_DATE_FORMAT,
  MINIMART_RATING,
} from "../../constants/appConstants";
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./WorkHistory.scss";

class WorkHistory extends React.Component {
  constructor(props) {
    super(props);
    this._columns = TABLE_COLUMNS[this.props.user];
    this.state = {
      userName: this.props.searchFilters?.userName || "",
      fromDate: this.props.searchFilters?.fromDate || new Date(),
      toDate: this.props.searchFilters?.toDate || new Date(),
      apiUrl: LIST_WORK_HISTORY[this.props.user].url,
      activePage: this.props.searchFilters.offset,
      recordsPerPage: this.props.searchFilters.limit,
    };
  }

  componentDidMount() {
    this.handleClickSearchFilter(false);
  }

  handleSearchUserNameChange = (event) => {
    this.setState({
      userName: event.target.value,
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.fromDate)) {
          DateValue =
            moment(dateMoment).diff(this.state.fromDate, "days") === 0
              ? this.state.fromDate
              : this.state.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        if (dateMoment.isAfter(new Date())) {
          DateValue = new Date();
        }
        break;
      default:
    }
    this.setState({
      [key]: DateValue,
    });
  };

  handleClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleSearchFilter(this.state.apiUrl, {
      name:
        this.state.userName !== "" ? this.state.userName : undefined,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleClickSearchFilter(false)
    );
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        userName: "",
        fromDate: new Date(),
        toDate: new Date(),
      },
      () => {
        this.handleClickSearchFilter(true);
      }
    );
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "pRat":
        return (
          <Rater
            title={row[key]}
            rating={row[key]}
            total={5}
            interactive={false}
          />
        );
      case "ts":
        return moment(row[key]).format(INVOICE_DATE_FORMAT);
      case "amtd":
        return row["amtd"] + "/" + row["pa"];
      case "mRat":
        return row[key] ? MINIMART_RATING[row[key]] : "--";
      case "ple":
        return parseFloat(row[key]).toFixed(2);
      case "crat":
        return parseFloat(row[key]).toFixed(2);
      default:
        return row[key] ? Strings(row[key]) : Strings("NA");
    }
  };

  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer
          component={Paper}
          className='d-flex justify-content-center'
        >
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key === "un"
                          ? "text-left header px-2"
                          : "text-center header px-2"
                      }
                      title={column.name}
                    >
                      <div>{column.name}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.userPreservedData.id}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    callBack(
                      row,
                      this.state.userName,
                      this.state.fromDate,
                      this.state.toDate
                    )
                  }
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.key === "un"
                          ? "text-left px-2"
                          : "text-center px-2"
                      }
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ width: column.minWidth }}
                    >
                      <div>
                        {this.renderTableData(row, column.key)}
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    let WorkHistorySearchFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("username"),
        handleOnChange: this.handleSearchUserNameChange,
        Value: this.state.userName,
        fieldname: SEARCH_FILTER_FIELDS.USER_NAME,
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
    ];
    return (
      <div>
        <Row className='pt-3 alignItemCenter'></Row>
        <SearchFilter
          filters={WorkHistorySearchFilters}
          handleOnClickSearchFilter={this.handleClickSearchFilter}
          handleOnClickResetFilter={this.handleOnClickResetFilter}
          paddingLeft={true}
        />
        <Row className='errors pl-3'>
          {this.props.errorMessage !== "" &&
            Strings(this.props.errorMessage)}
        </Row>
        {this.props.rowsCount !== 0 ? (
          <div className='branchListTable noPadding'>
            {this.renderCustomDataGrid(
              this._columns,
              this.props.rows,
              this.props.onRowClick
            )}
            <Col md={12} className='noPadding'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </div>
        ) : (
          <div className='noRecordsFound'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default WorkHistory;

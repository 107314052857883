import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import LoginReducer from "../components/Login/LoginReducer";
import SidebarReducer from "../components/Sidebar/SidebarReducer";
import UserListReducer from "../components/UserList/UserListReducer";
import BranchListReducer from "../components/BranchList/BranchListReducer";
import ciReducer from "../components/CiInterface/CiReducer";
import MinimartListReducer from "../components/MinimartList/MinimartListReducer";
import LoanDetailsReducer from "../components/LoanDetails/LoanDetailsReducer";
import DailyStatusReducer from "../components/DailyStatus/DailyStatusReducer";
import CustomConfigurationReducer from "../components/CustomConfiguration/CustomConfigurationReducer";
import workHistoryReducer from "../components/WorkHistory/WorkHistoryReducer";
import ReportsReducer from "../components/GeneralReports/ReportsReducer";
import CIWireTransferReducer from "../components/CIWireTransfer/CIWireTransferReducer";
import CommissionReportReducer from "../components/CommissionReport/CommissionReportReducer";
import EfficiencyDashboardReducer from "../components/EfficiencyDashboard/EfficiencyDashboardReducer";
import SearchFilterReducer from "./SearchFilterReducer";
import SignUpRequestReducer from "../components/MTPSignupRequest/SignUpRequestReducer";
import PromotionsApprovalReducer from "../components/MTPPromotionsApproval/PromotionsApprovalReducer";
import RewardApprovalReducer from "../components/RewardApproval/RewardApprovalReducer";

export default combineReducers({
  routing: routerReducer,
  searchFilterReducer: SearchFilterReducer,
  loginReducer: LoginReducer,
  sidebarReducer: SidebarReducer,
  userListReducer: UserListReducer,
  loanDetailsReducer: LoanDetailsReducer,
  branchListReducer: BranchListReducer,
  ciReducer: ciReducer,
  minimartListReducer: MinimartListReducer,
  DailyStatusReducer: DailyStatusReducer,
  customConfigurationReducer: CustomConfigurationReducer,
  workHistoryReducer: workHistoryReducer,
  ReportsReducer: ReportsReducer,
  cIWireTransferReducer: CIWireTransferReducer,
  CommissionReportReducer: CommissionReportReducer,
  efficiencyDashboardReducer: EfficiencyDashboardReducer,
  signUpRequestReducer: SignUpRequestReducer,
  promotionsApprovalReducer: PromotionsApprovalReducer,
  rewardApprovalReducer: RewardApprovalReducer,
});

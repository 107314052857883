import React, { Component } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Strings } from "../../resources/i18n/i18n";
import NumberFormat from "react-number-format";

import PropTypes from "prop-types";

import { Row, Button, Table, Col } from "react-bootstrap";
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

import addUser from "../../resources/images/ic_add_user_blue.svg";
import deleteRange from "../../resources/images/ic_notes_minus_blue.svg";
import {
  MODAL_TYPE,
  SEARCH_FILTER_TYPE,
  CONFIGURATION_MAPPING_FIELDS,
} from "../../constants/appConstants";

import {
  TEMPLATES,
  CONFIGURATION_EDIT_FIELD_KEYS,
} from "./Constants";

import _ from "lodash";

import "./TemplateModal.scss";

import Utility from "../../utils/Utility";

class TemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      subtypeName: "",
      mapping: SEARCH_FILTER_TYPE.SELECT,
      template: "",
      rangeData: [],
      errorMessage: "",
    };
  }

  //Change visibility of modal according to props

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.subtypeName &&
      prevState.subtypeName === "" &&
      nextProps.mapping &&
      prevState.mapping === SEARCH_FILTER_TYPE.SELECT &&
      nextProps.template &&
      prevState.template === "" &&
      nextProps.rangeData &&
      prevState.rangeData.length === 0
    ) {
      return {
        open: nextProps.open,
        subtypeName: nextProps.subtypeName,
        mapping: nextProps.mapping,
        template: nextProps.template,
        rangeData: _.cloneDeep(nextProps.rangeData),
      };
    }
    return {
      open: nextProps.open,
    };
  }

  //to close modal
  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  /**
   * Sets subtype name
   */
  handleOnChangesubtypeName = (event) => {
    this.setState({
      subtypeName: event.target.value,
      errorMessage: "",
    });
  };

  /**
   * sets selected mapping and adds 1 default row in data
   */
  handleOnChangeMapping = (event) => {
    if (event.target.value !== SEARCH_FILTER_TYPE.SELECT) {
      let mappingObject = _.find(this.props.mappings, (mapping) => {
        return mapping.key === event.target.value;
      });
      let templateData = {};
      let rangeData = [];
      if (mappingObject) {
        switch (mappingObject.template) {
          case TEMPLATES.RANGE:
            templateData = {
              start: 0,
              end: -1,
              weight: 0,
            };
            rangeData.push(templateData);
            break;
          case TEMPLATES.MULTI_VALUE:
            if (
              event.target.value ===
                CONFIGURATION_MAPPING_FIELDS.COUNTRY ||
              event.target.value ===
                CONFIGURATION_MAPPING_FIELDS.EMPLOYMENT_SITUATION
            ) {
              templateData = {
                name: "Select",
                weight: 0,
              };
            } else {
              templateData = {
                name: "",
                weight: 0,
              };
            }
            rangeData.push(templateData);
            break;
          case TEMPLATES.YES_NO:
            rangeData.push(
              { name: "YES", weight: 1 },
              { name: "NO", weight: 0 }
            );
            break;
          default:
            break;
        }

        this.setState({
          mapping: event.target.value,
          template: mappingObject.template,
          rangeData: rangeData,
          errorMessage: "",
        });
      } else {
        this.setState({
          mapping: SEARCH_FILTER_TYPE.SELECT,
          template: null,
          rangeData: [],
          errorMessage: "",
        });
      }
    } else {
      this.setState({
        mapping: SEARCH_FILTER_TYPE.SELECT,
        template: null,
        rangeData: [],
        errorMessage: "",
      });
    }
  };

  /**
   * sets data values except NATIONALITY and INCOME_SOURCE mapping
   */
  handleEditValue = (index, key, event) => {
    let rangeData = this.state.rangeData;
    if (
      key !== CONFIGURATION_EDIT_FIELD_KEYS.NAME &&
      event.target.value &&
      key !== CONFIGURATION_EDIT_FIELD_KEYS.WEIGHT
    ) {
      rangeData[index][key] = Utility.getIntegerValue(
        event.target.value
      );
    } else if (
      key === CONFIGURATION_EDIT_FIELD_KEYS.WEIGHT &&
      event.target.value &&
      !event.target.value.endsWith(".")
    ) {
      rangeData[index][key] = parseFloat(event.target.value);
    } else {
      rangeData[index][key] = event.target.value;
    }
    this.setState({
      rangeData: rangeData,
      errorMessage: "",
    });
  };

  /**
   * Checks whether entered data is valid or not
   */

  conditionForWeightData = () => {
    return _.find(this.state.rangeData, (rangeObject) => {
      return (
        rangeObject.weight.toString().endsWith(".") ||
        rangeObject.weight > 100
      );
    });
  };

  isDataValid = () => {
    //NOSONAR
    let valid = true;
    let errorMessage = "";
    if (this.state.mapping === SEARCH_FILTER_TYPE.SELECT) {
      valid = false;
      errorMessage = Strings("errorPleaseSelectVariable");
    } else if (
      this.state.subtypeName === "" ||
      !this.state.template
    ) {
      valid = false;
      errorMessage = Strings("errorEmptyValue");
    }

    //error checking for Range template
    else if (this.state.template === TEMPLATES.RANGE) {
      if (
        _.find(this.state.rangeData, (rangeObject) => {
          return (
            rangeObject.start === "" ||
            rangeObject.end === "" ||
            rangeObject.weight === ""
          );
        })
      ) {
        valid = false;
        errorMessage = Strings("errorEmptyValue");
      } else if (this.conditionForWeightData()) {
        valid = false;
        errorMessage = Strings("errorInvalidInput");
      } else if (
        _.find(this.state.rangeData, (rangeObject) => {
          return (
            rangeObject.start >= rangeObject.end &&
            rangeObject.end !== -1
          );
        })
      ) {
        valid = false;
        errorMessage = Strings(
          "errorStartValueShouldBeLessThanEndValue"
        );
      } else if (
        !Utility.checkIfCreditScoreRangeIsContinuous(
          this.state.rangeData
        )
      ) {
        valid = false;
        errorMessage = Strings("invalidRange");
      }
    } else if (this.state.template === TEMPLATES.MULTI_VALUE) {
      if (
        _.find(this.state.rangeData, (rangeObject) => {
          return rangeObject.name === "" || rangeObject.weight === "";
        })
      ) {
        valid = false;
        errorMessage = Strings("errorEmptyValue");
      } else if (this.conditionForWeightData()) {
        valid = false;
        errorMessage = Strings("errorInvalidInput");
      } else if (
        this.checkIsSelectedMappingIsNationality() ||
        this.checkIsSelectedMappingIsIncomeSource()
      ) {
        let valueArr = this.state.rangeData.map(function (item) {
          return item.name;
        });
        //Check for multiple rows with same selection
        let isDuplicate = valueArr.some(function (item, idx) {
          // eslint-disable-next-line eqeqeq
          return valueArr.indexOf(item) !== idx;
        });
        if (
          _.find(this.state.rangeData, (rangeObject) => {
            return rangeObject.name === "Select";
          })
        ) {
          valid = false;
          errorMessage = Strings("errorPleaseSelectTheValue");
        } else if (isDuplicate) {
          valid = false;
          errorMessage = Strings("errorDuplicateSelectionNotAllowed");
        }
      }
    }
    this.setState({
      errorMessage: errorMessage,
    });
    return valid;
  };

  /**
   * Handles on click of set
   */
  handleOnClickSet = () => {
    if (this.isDataValid()) {
      this.setState(
        {
          errorMessage: "",
        },
        () => {
          if (this.props.modalType === MODAL_TYPE.EDIT) {
            this.props.modalButtonOk.onClick(
              this.props.subTypeIndex,
              this.state.subtypeName,
              this.state.rangeData,
              this.state.mapping,
              this.state.template
            );
          } else {
            this.props.modalButtonOk.onClick(
              this.state.subtypeName,
              this.state.rangeData,
              this.state.mapping,
              this.state.template
            );
          }
        }
      );
    }
  };

  /**
   * Checks whether selected mapping is NATIONALITY or not
   */
  checkIsSelectedMappingIsNationality = () => {
    return (
      this.state.mapping === CONFIGURATION_MAPPING_FIELDS.COUNTRY
    );
  };

  /**
   * Checks whether selected mapping is INCOME_SOURCE
   */
  checkIsSelectedMappingIsIncomeSource = () => {
    return (
      this.state.mapping ===
      CONFIGURATION_MAPPING_FIELDS.EMPLOYMENT_SITUATION
    );
  };

  /**
   * Adds empty row according to selected template
   */
  addRange = () => {
    let newRangeObject = {};
    let rangeData = this.state.rangeData;
    switch (this.state.template) {
      case TEMPLATES.RANGE:
        newRangeObject = {
          start: 0,
          end: 0,
          weight: 0,
        };
        break;
      case TEMPLATES.MULTI_VALUE:
        if (
          this.checkIsSelectedMappingIsNationality() ||
          this.checkIsSelectedMappingIsIncomeSource()
        ) {
          newRangeObject = {
            name: "Select",
            weight: 0,
          };
        } else {
          newRangeObject = {
            name: "",
            weight: 0,
          };
        }

        break;
      default:
        newRangeObject = {};
        break;
    }
    if (this.state.rangeData.length - 1 < 0) {
      rangeData.splice(0, 0, newRangeObject);
    } else if (
      this.checkIsSelectedMappingIsNationality() ||
      this.checkIsSelectedMappingIsIncomeSource()
    ) {
      //Adding row at last position
      rangeData.splice(
        this.state.rangeData.length,
        0,
        newRangeObject
      );
    } else {
      //Adding row in second last position
      rangeData.splice(
        this.state.rangeData.length - 1,
        0,
        newRangeObject
      );
    }
    this.setState({
      rangeData: rangeData,
    });
  };

  /**
   * Deletes data row
   */

  deleteRange = (index) => {
    let rangeData = this.state.rangeData;
    rangeData.splice(index, 1);
    this.setState({
      rangeData: rangeData,
    });
  };

  /**
   * sets name of on change of country/employment situation dropdown
   */
  onChangeSelection = (index, value) => {
    const rangeData = this.state.rangeData;
    rangeData[index].name = value;
    this.setState({
      rangeData: rangeData,
    });
  };

  /**
   * Renders dropdown for INCOME_SOURCE mapping
   */
  renderIncomeSourceOptionsList = (index) => {
    return (
      <TextFieldComponent
        id='name'
        type='text'
        className='noMargin'
        placeholder={Strings("BranchName")}
        handleChange={(event) => {
          this.onChangeSelection(index, event.target.value);
        }}
        value={this.state.cachedBranch.name}
        disabled={this.state.disableInput}
      ></TextFieldComponent>
    );
  };

  renderInputForWeight = (range, index) => {
    return (
      <NumberFormat
        onValueChange={(event) => {
          this.handleEditValue(
            index,
            CONFIGURATION_EDIT_FIELD_KEYS.WEIGHT,
            { target: { value: event.value } }
          );
        }}
        value={range.weight}
        type='text'
        decimalScale='2'
        isAllowed={({ floatValue }) =>
          floatValue <= 1.0 && floatValue >= 0.0
        }
        allowNegative={false}
      />
    );
  };

  renderTemplatesRange = () => {
    return (
      <Table responsive striped bordered condensed='true' hover>
        <thead>
          <tr>
            <th>{Strings("start")} &nbsp;&gt;=</th>
            <th>{Strings("end")} &nbsp;&lt;=</th>
            <th>{Strings("weight")}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.rangeData.length > 0 &&
            this.state.rangeData.map((range, index) => {
              return (
                <tr key={index}>
                  <td>
                    {
                      <input
                        type='text'
                        value={range.start}
                        onChange={(event) => {
                          this.handleEditValue(
                            index,
                            CONFIGURATION_EDIT_FIELD_KEYS.START,
                            event
                          );
                        }}
                        onKeyDown={Utility.allowOnlyNumber}
                      />
                    }
                  </td>
                  <td>
                    <input
                      type='text'
                      value={range.end === -1 ? "-" : range.end}
                      onChange={(event) => {
                        this.handleEditValue(
                          index,
                          CONFIGURATION_EDIT_FIELD_KEYS.END,
                          event
                        );
                      }}
                      onKeyDown={Utility.allowOnlyNumber}
                      disabled={range.end === -1}
                    />
                  </td>
                  <td>{this.renderInputForWeight(range, index)}</td>
                  {index !== this.state.rangeData.length - 1 ? (
                    <td>
                      <img
                        className='deleteIcon'
                        src={deleteRange}
                        onClick={() => this.deleteRange(index)}
                        alt='delete'
                      />
                    </td>
                  ) : (
                    <td width='35px' />
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  renderTemplatesMultiValue = () => {
    return (
      <Table responsive striped bordered condensed hover>
        <thead>
          <tr>
            <th>{Strings("name")}</th>
            <th>{Strings("weight")}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.rangeData.length > 0 &&
            this.state.rangeData.map((range, index) => {
              return (
                <tr key={index}>
                  <td>
                    {this.checkIsSelectedMappingIsIncomeSource() ? (
                      this.renderIncomeSourceOptionsList(index)
                    ) : (
                      <input
                        type='text'
                        value={range.name}
                        onChange={(event) => {
                          this.handleEditValue(
                            index,
                            CONFIGURATION_EDIT_FIELD_KEYS.NAME,
                            event
                          );
                        }}
                      />
                    )}
                  </td>
                  <td>{this.renderInputForWeight(range, index)}</td>
                  {index !== this.state.rangeData.length - 1 ? (
                    <td>
                      <img
                        className='deleteIcon'
                        src={deleteRange}
                        onClick={() => this.deleteRange(index)}
                        alt='delete'
                      />
                    </td>
                  ) : (
                    <td width='35px' />
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  renderTemplatesYesNo = () => {
    return (
      <Table responsive striped bordered condensed='true' hover>
        <thead>
          <tr>
            <th>{Strings("name")}</th>
            <th>{Strings("weight")}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.rangeData.length > 0 &&
            this.state.rangeData.map((range, index) => {
              return (
                <tr key={index}>
                  <td>
                    <input
                      type='text'
                      value={range.name}
                      disabled={true}
                    />
                  </td>
                  <td>{this.renderInputForWeight(range, index)}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  renderModalButtons = () => {
    return (
      <Row className='modalButton'>
        {this.props.Editable && this.props.modalButtonOk && (
          <Button
            className='buttonBackground button1 buttonMinWidth'
            onClick={this.handleOnClickSet}
          >
            {Strings(this.props.modalButtonOk.id)}
          </Button>
        )}

        {this.props.modalButtonCancel && (
          <Button
            className={
              " button1 buttonMinWidth marginCancelButton " +
              this.props.modalButtonCancelStyle
            }
            onClick={this.props.modalButtonCancel.onClick}
          >
            {Strings(this.props.modalButtonCancel.id)}
          </Button>
        )}
      </Row>
    );
  };

  renderModalBody = (mappingList) => {
    return (
      <Row>
        <Row className='headerModal'>
          {Strings("editSubtype")}
        </Row>
        <Row className='modalContent w-100'>
          {this.state.errorMessage && (
            <Row className='errors'>{this.state.errorMessage}</Row>
          )}

          <TextFieldComponent
            id='subtypeName'
            type='text'
            placeholder='Nombres'
            handleChange={this.handleOnChangesubtypeName}
            maxLength={100}
            value={this.state.subtypeName}
            className='mb-2'
          />

          {this.props.Editable && (
            <TextFieldComponent
              type='select'
              handleChange={this.handleOnChangeMapping}
              value={this.state.mapping}
              placeholder='select'
            >
              {mappingList.map((mapping) => {
                return (
                  <option key={mapping} value={mapping}>
                    {Strings(mapping)}
                  </option>
                );
              })}
            </TextFieldComponent>
          )}
          <Col md={12} className='pl-0'>
            <div className='templateName'>
              {Strings(this.state.template)}
            </div>
          </Col>
          <Col md={12} className='template pl-0'>
            <Row
              className='templateContent pt-3'
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {this.state.template === TEMPLATES.RANGE &&
                this.renderTemplatesRange()}
              {this.state.template === TEMPLATES.MULTI_VALUE && (
                <div>{this.renderTemplatesMultiValue()}</div>
              )}
              {this.state.template === TEMPLATES.YES_NO && (
                <div>{this.renderTemplatesYesNo()}</div>
              )}
            </Row>
            {this.state.template !== TEMPLATES.YES_NO &&
              this.state.mapping !== SEARCH_FILTER_TYPE.SELECT && (
                <Row>
                  <img
                    src={addUser}
                    alt='add'
                    onClick={this.addRange}
                    className='addRange'
                  />
                </Row>
              )}
          </Col>

          {this.renderModalButtons()}
        </Row>
      </Row>
    );
  };

  render() {
    const mappingList = Object.keys(this.props.mappingsList);
    return (
      <div>
        {/* general modal */}
        <Modal
          // Change visibility of modal based on props.
          open={this.state.open}
          onClose={this.closeModal}
          modalCloseOnEsc={false}
          modalCloseOnOverlayClick={false}
          showCloseIcon={false}
          classNames={{ modal: "templateModal" }}
          center
        >
          {this.renderModalBody(mappingList)}
        </Modal>
      </div>
    );
  }
}

TemplateModal.propTypes = {
  modalType: PropTypes.string,
  modalImage: PropTypes.string,
  modalText1Id: PropTypes.string,
  modalText2Id: PropTypes.string,
  modalButtonOk: PropTypes.object,
  modalButtonCancel: PropTypes.object,
  modalButtonCancelStyle: PropTypes.string,
  userIdInput: PropTypes.bool,
};

TemplateModal.defaultProps = {
  modalButtonCancelStyle: "buttonBorder",
};

export default TemplateModal;

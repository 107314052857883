// Constants
import { SET_CI_WIRE_ACTION } from "../../redux/constants";

const INITIAL_STATE = {
  userRoles: null,
  users: null,
};

export default function CIWireTransferReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CI_WIRE_ACTION:
      return "";

    default:
      return state;
  }
}

import React from "react";
import moment from "moment";
import { Row, Col, Button } from "react-bootstrap";

//Component
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";

//Action

//Constants
import {
  MINIMART_RISK_DROPDOWN,
  SELECT_CLASSIFICATION,
  NOTES_TYPE,
} from "./Constants";
import {
  CONFIGURATION_DATE_TIME_FORMAT,
  ADD,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

// Styles
import "./AnalystApproval.scss";

//Assets
import addNotes from "../../resources/images/ic_add_note.svg";
import editNote from "../../resources/images/ic_edit.svg";
import deleteNote from "../../resources/images/ic_cross_blue.svg";

const RenderNote = (props) => {
  return (
    <div className='noteSection'>
      <span className='subHeading'>{Strings("Notes")}</span>
      <span className='paddingLeft subText'>
        {moment(props.ca).format(CONFIGURATION_DATE_TIME_FORMAT)}
      </span>
      <div className='spaceForIcons'>
        {props.isAnalyst && !props.isHistory && (
          <img
            src={deleteNote}
            className={
              props.isAnalyst
                ? "NotesIcon cursorPointer  pull-right"
                : "NotesIcon pull-right"
            }
            alt={"delete"}
            onClick={
              props.isAnalyst
                ? () => {
                  props.deleteNote(props.id, props.noteType);
                }
                : () => {
                  //This is intensional
                }
            }
          />
        )}
        {props.isAnalyst && !props.isHistory && (
          <img
            src={editNote}
            className={
              props.isAnalyst
                ? "NotesIcon cursorPointer  pull-right"
                : "NotesIcon pull-right"
            }
            alt={"edit"}
            onClick={
              props.isAnalyst
                ? () => {
                  props.openEditNoteModal(
                    props.noteType,
                    true,
                    props.note,
                    props.id
                  );
                }
                : () => {
                  //This is intensional
                }
            }
          />
        )}
      </div>
      <div className='subText scrollAuto'>{props.note}</div>
    </div>
  );
};

class AnalystInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileAnalysis: { risk: "" },
      regulatorsAnalysis: {
        classification: "",
        worstArrears: "",
        largestLoanAmount: "",
      },
      refAnalysis: {
        commercialRefVerified: "",
        personalRefVerified: "",
        neighbourRefVerified: "",
      },
      noteType: "",
      notesModal: false,
      editNote: false,
      note: "",
      errorMessage: "",
    };
  }

  static getDerivedStateFromProps(props, state) {//NOSONAR
    let derivedState = { ...state };
    if (props.errorMessage !== state.errorMessage) {
      derivedState = {
        ...derivedState,
        errorMessage: props.errorMessage,
      };
    }
    if (props.analystPrefilledData) {
      let propsAnalystData = props.analystPrefilledData;
      derivedState = {
        ...derivedState,
        profileAnalysis:
          propsAnalystData.profileAnalysis &&
            state.profileAnalysis.risk === ""
            ? propsAnalystData.profileAnalysis
            : state.profileAnalysis,
      };
      if (propsAnalystData.refAnalysis) {
        let propsrefData = propsAnalystData.refAnalysis;
        let stateRefData = state.refAnalysis;

        derivedState = {
          ...derivedState,
          refAnalysis: {
            personalRefVerified:
              stateRefData.personalRefVerified === ""
                ? propsrefData.personalRefVerified
                : stateRefData.personalRefVerified,
            commercialRefVerified:
              stateRefData.commercialRefVerified === ""
                ? propsrefData.commercialRefVerified
                : stateRefData.commercialRefVerified,
            neighbourRefVerified:
              stateRefData.neighbourRefVerified === ""
                ? propsrefData.neighbourRefVerified
                : stateRefData.neighbourRefVerified,
          },
        };
      }
      if (
        propsAnalystData.regulatorsAnalysis &&
        props.toUpdateNegotiation
      ) {
        let propsRegulatorData = propsAnalystData.regulatorsAnalysis;
        let stateRegulatorData = state.regulatorsAnalysis;
        derivedState = {
          ...derivedState,
          regulatorsAnalysis: {
            classification:
              stateRegulatorData.classification === ""
                ? propsRegulatorData.classification
                : stateRegulatorData.classification,

            worstArrears:
              stateRegulatorData.worstArrears === ""
                ? propsRegulatorData.worstArrears
                : stateRegulatorData.worstArrears,
            largestLoanAmount:
              stateRegulatorData.largestLoanAmount === ""
                ? propsRegulatorData.largestLoanAmount
                : stateRegulatorData.largestLoanAmount,
          },
        };
      }
    }
    return derivedState;
  }

  onChangeInput = (event, mainKey, key) => {
    let value = event.target.value;
    this.setState(
      {
        [mainKey]: { ...this.state[mainKey], [key]: value },
      },
      () => {
        this.props.resetError();
      }
    );
  };

  onCheckReference = (key, value) => {
    this.setState(
      {
        refAnalysis: {
          ...this.state.refAnalysis,
          [key]: value,
        },
      },
      () => {
        this.props.resetError();
      }
    );
  };

  calculateCreditScore = () => {
    let refAnalysis = { ...this.state.refAnalysis };
    if (refAnalysis.personalRefVerified === "") {
      refAnalysis.personalRefVerified = false;
    }
    if (refAnalysis.commercialRefVerified === "") {
      refAnalysis.commercialRefVerified = false;
    }
    if (refAnalysis.neighbourRefVerified === "") {
      refAnalysis.neighbourRefVerified = false;
    }
    let requestObjecct = {
      refAnalysis,
      profileAnalysis: { ...this.state.profileAnalysis },
      regulatorsAnalysis: { ...this.state.regulatorsAnalysis },
    };
    this.props.calculateCreditScore(requestObjecct);
  };

  toggleModal = (
    type = null,
    editedNote = false,
    note = null,
    noteId = null
  ) => {
    if (type && editedNote === ADD) {
      this.setState({
        noteType: type,
        notesModal: !this.state.notesModal,
      });
    } else if (type && editedNote) {
      this.setState({
        noteType: type,
        notesModal: !this.state.notesModal,
        editNote: editedNote,
        note: note,
        noteId: noteId,
      });
    } else {
      this.setState({
        notesModal: !this.state.notesModal,
        editNote: false,
      });
    }
  };

  addNote = (note = null, noteType = null) => {
    this.toggleModal();
    this.props.addNote({ note: note, type: noteType });
  };

  editNote = (note, type) => {
    this.toggleModal();
    this.props.editNote(note, this.state.noteId, type);
  };

  renderReferenceInput = (name, label, labelCol, inputCol) => {
    return (
      <Row>
        <Col md={labelCol} className='pl-0 pr-0'>
          <span className='radioText'>
            {Strings(label)}
            <span className='requiredFields'>*</span>
          </span>
        </Col>
        <Col md={inputCol} className='p-0'>
          <Row
            className='customInputButtons'
            role='radiogroup'
            aria-labelledby='group_label_2'
          >
            <div>
              <input
                type='radio'
                checked={this.state.refAnalysis[name]}
                name={name}
                onChange={() => this.onCheckReference(name, true)}
                disabled={this.props.readOnly}
              />
              <label className='radioLabel'>{Strings("Yes")}</label>
              <input
                type='radio'
                checked={!this.state.refAnalysis[name]}
                name={name}
                onChange={() => this.onCheckReference(name, false)}
                disabled={this.props.readOnly}
              />
              <label className='radioLabel'>{Strings("No")}</label>
            </div>
          </Row>
        </Col>
      </Row>
    );
  };

  renderAnalystInput = () => {
    //NOSONAR
    return (
      <div className='analystInput'>
        {(this.props.isAnalyst
          || this.props.isAnalystModerator) && (
            <div className='errors'>
              {this.state.errorMessage
                ? this.state.errorMessage
                : "     "}
            </div>
          )}
        <div className='subHeadingText paddingBottom1'>
          {Strings("references")}
          <span className='requiredFields'>*</span>
        </div>
        <Row>
          <Col md={6} className='pl-0'>
            {this.renderReferenceInput(
              "personalRefVerified",
              "arePersonalReferencesSatisfactory",
              7,
              4
            )}
          </Col>

          <Col md={5} className='noPadding'>
            {this.renderReferenceInput(
              "commercialRefVerified",
              "areCommercialReferencesSatisfactory",
              9,
              3
            )}
          </Col>
          <Col md={1} className='pr-0'>
            {this.props.isAnalyst &&
              !this.props.isHistory &&
              !(
                this.props.isWrongLoanCombination ||
                this.props.isMultipleActiveLoans
              ) && (
                <div className='pull-right'>
                  <img
                    src={addNotes}
                    alt={"addNotes"}
                    className={
                      this.props.isAnalyst
                        ? "addNotesIcon cursorPointer"
                        : "addNotesIcon"
                    }
                    onClick={
                      this.props.isAnalyst
                        ? () =>
                          this.toggleModal(
                            NOTES_TYPE.ANALYST_INPUT_REFERENCE,
                            ADD
                          )
                        : () => {
                          //This is intensional
                        }
                    }
                  />
                  <span className='noteText'>{Strings("Notes")}</span>
                </div>
              )}
          </Col>
          <Col md={6} className='pl-0'>
            {this.renderReferenceInput(
              "neighbourRefVerified",
              "areNeighbourReferencesSatisfactory",
              7,
              3
            )}
          </Col>
        </Row>
        {this.props.referenceNotes.length > 0 &&
          this.props.referenceNotes.map((Note) => {
            return (
              <RenderNote
                {...Note}
                noteType={NOTES_TYPE.ANALYST_INPUT_REFERENCE}
                deleteNote={this.props.deleteNote}
                openEditNoteModal={this.toggleModal}
                isAnalyst={this.props.isAnalyst}
                isHistory={this.props.isHistory}
                key={Note.id}
              />
            );
          })}
        <hr />
        <div className='subHeadingText paddingBottom1'>
          {Strings("finacialRiskAssessment")}{" "}
          <span className='requiredFields'>*</span>
        </div>
        <Row className='pb-2'>
          <Col md={3} className='pl-0'>
            <TextFieldComponent
              type='select'
              handleChange={(event) =>
                this.onChangeInput(event, "profileAnalysis", "risk")
              }
              value={this.state.profileAnalysis.risk}
              disabled={this.props.readOnly}
            >
              {MINIMART_RISK_DROPDOWN.map((risk) => {
                return (
                  <option value={risk.value} key={risk.value}>
                    {risk.label}
                  </option>
                );
              })}
            </TextFieldComponent>
          </Col>
          <Col md={{ span: 2, offset: 7 }} className='pr-0'>
            {this.props.isAnalyst &&
              !this.props.isHistory &&
              !(
                this.props.isWrongLoanCombination ||
                this.props.isMultipleActiveLoans
              ) && (
                <div className='pull-right'>
                  <img
                    src={addNotes}
                    alt={"addNotes"}
                    className={
                      this.props.isAnalyst
                        ? "addNotesIcon cursorPointer"
                        : "addNotesIcon"
                    }
                    onClick={
                      this.props.isAnalyst
                        ? () =>
                          this.toggleModal(
                            NOTES_TYPE.ANALYST_INPUT_RISK,
                            ADD
                          )
                        : () => {
                          //This is intensional
                        }
                    }
                  />
                  <span className='noteText'>{Strings("Notes")}</span>
                </div>
              )}
          </Col>
        </Row>
        {this.props.riskNotes.length > 0 &&
          this.props.riskNotes.map((Note) => {
            return (
              <RenderNote
                {...Note}
                noteType={NOTES_TYPE.ANALYST_INPUT_RISK}
                deleteNote={this.props.deleteNote}
                openEditNoteModal={this.toggleModal}
                isAnalyst={this.props.isAnalyst}
                isHistory={this.props.isHistory}
                key={Note.id}
              />
            );
          })}
        <hr />
        <div className='subHeadingText paddingBottom1'>
          {Strings("Regulators")}{" "}
          <span className='requiredFields'>*</span>
        </div>
        <Row className='pt-2 pb-2'>
          <Col md={3} className='pl-0'>
            <TextFieldComponent
              type='select'
              handleChange={(event) =>
                this.onChangeInput(
                  event,
                  "regulatorsAnalysis",
                  "classification"
                )
              }
              value={this.state.regulatorsAnalysis.classification}
              placeholder={Strings("EnterClassification")}
              placeholderTopClass='inputPlaceholderTop'
              disabled={this.props.readOnly}
            >
              {SELECT_CLASSIFICATION.map((classification) => {
                return (
                  <option
                    key={classification.key}
                    value={classification.value}
                  >
                    {classification.label}
                  </option>
                );
              })}
            </TextFieldComponent>
          </Col>
          <Col md={3}>
            <TextFieldComponent
              type='number'
              handleChange={(event) =>
                this.onChangeInput(
                  event,
                  "regulatorsAnalysis",
                  "worstArrears"
                )
              }
              value={this.state.regulatorsAnalysis.worstArrears}
              placeholder={Strings("EnterWorstArrearDays")}
              placeholderClass='inputPlaceholderTop'
              onKeyDown={Utility.allowOnlyNumber}
              disabled={this.props.readOnly}
            />
          </Col>
          <Col md={3}>
            <TextFieldComponent
              type='number'
              handleChange={(event) =>
                this.onChangeInput(
                  event,
                  "regulatorsAnalysis",
                  "largestLoanAmount"
                )
              }
              value={this.state.regulatorsAnalysis.largestLoanAmount}
              placeholder={Strings("EnterLargestLaonAmount")}
              placeholderTopClass='inputPlaceholderTop'
              onKeyDown={Utility.allowOnlyNumber}
              disabled={this.props.readOnly}
            />
          </Col>
          <Col md={{ span: 1, offset: 2 }} className='pr-0'>
            {this.props.isAnalyst &&
              !this.props.isHistory &&
              !(
                this.props.isWrongLoanCombination ||
                this.props.isMultipleActiveLoans
              ) && (
                <div className='pull-right'>
                  <img
                    src={addNotes}
                    alt={"addNotes"}
                    className={
                      this.props.isAnalyst
                        ? "addNotesIcon cursorPointer"
                        : "addNotesIcon"
                    }
                    onClick={
                      this.props.isAnalyst
                        ? () =>
                          this.toggleModal(
                            NOTES_TYPE.ANALYST_INPUT_REGULATOR,
                            ADD
                          )
                        : () => {
                          //This is intensional
                        }
                    }
                  />
                  <span className='noteText'>{Strings("Notes")}</span>
                </div>
              )}
          </Col>
        </Row>
        {this.props.regulatorsNotes.length > 0 &&
          this.props.regulatorsNotes.map((Note) => {
            return (
              <RenderNote
                {...Note}
                noteType={NOTES_TYPE.ANALYST_INPUT_REGULATOR}
                deleteNote={this.props.deleteNote}
                openEditNoteModal={this.toggleModal}
                isAnalyst={this.props.isAnalyst}
                isHistory={this.props.isHistory}
                key={Note.id}
              />
            );
          })}
        <hr />
        <Row>
          {(this.props.isAnalyst || this.props.isAnalystModerator)
            && !this.props.isHistory && (
              <Button
                className='buttonBackground'
                onClick={this.calculateCreditScore}
                disabled={
                  this.props.isWrongLoanCombination ||
                  this.props.isMultipleActiveLoans
                }
              >
                {Strings("validateCreditScore")}
              </Button>
            )}
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.notesModal && (
          <NotesModal
            open={this.state.notesModal}
            modalHeader={Strings("Notes")}
            toggleModal={this.toggleModal}
            noteType={this.state.noteType}
            note={this.state.note}
            isEditNote={this.state.editNote}
            editNote={this.editNote}
            addNote={
              this.state.editNote ? this.editNote : this.addNote
            }
            isNotesCompulsory={true}
            maxLength={500}
            errorMessage=''
          />
        )}
        {this.renderAnalystInput()}
      </div>
    );
  }
}

export default AnalystInput;

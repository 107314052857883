import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getEditMinimartRequest = (reqObj, callback) => {
  callAPI(
    "getEditMinimartRequest",
    API.getEditMinimartRequest,
    "GET",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateMinimartRequest = (reqObj, callback) => {
  callAPI(
    "updateMinimartRequest",
    API.updateMinimartRequest,
    "PATCH",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};


export const downloadPhotosAction = (id, callback) => {
  callAPI(
    "downloadPhotos",
    API.downloadPhotos.concat(id),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const cedulaHistoryAction = (id, callback) => {
  callAPI(
    "cedulaHistory",
    API.cedulaHistory.concat(id),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

//Components
import SinglePersonWorkHistory from "./SinglePersonWorkHistory";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

//Constants
import { SINGLE_USER_WORK_HISTORY } from "../../constants/appConstants";
import { getWorkHistoryDataAction } from "../WorkHistory/WorkHistoryAction";
import { OPERATION_STATUS } from "../../config/axios.init";

//Utility
import Utility from "../../utils/Utility";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./SinglePersonWorkHistory.scss";
import moment from "moment";
import { INVOICE_DATE_FORMAT } from "../../constants/appConstants";

class SinglePersonWorkHistoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.userId,
      keysArray: SINGLE_USER_WORK_HISTORY[this.props.user].keysArray,
      apiUrl: SINGLE_USER_WORK_HISTORY[this.props.user].url,
      rows: [],
      numberOfPages: 1,
      errorMessage: "",
      showLoader: false,
    };
  }

  handleSearchFilter = (requestUrl, searchFilterObject) => {
    this.setState({ showLoader: true });

    getWorkHistoryDataAction(
      requestUrl,
      searchFilterObject,
      (responsedata) => {
        if (responsedata.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            numberOfPages: responsedata.data.data.np,
            showLoader: false,
            errorMessage: "",
          });
          this.createRows(responsedata.data.data.wh);
        } else {
          this.setState({
            errorMessage: responsedata.error.message,
            showLoader: false,
          });
        }
      }
    );
  };

  createRows = (responseData) => {
    //Row create for each element in response data array
    let rows = [];

    _.forEach(responseData, (historyDetails) => {
      let tempObj = {};
      this.state.keysArray.forEach((key) => {
        if (Array.isArray(key) && key.length > 0) {
          this.assignAmountKey(tempObj, key, historyDetails);
        } else {
          if (historyDetails[key] !== null) {
            if (key === "category") {
              tempObj[key] = Strings(historyDetails[key]);
            } else if (key === "ts") {
              tempObj[key] = moment(historyDetails[key]).format(
                INVOICE_DATE_FORMAT
              );
            } else {
              tempObj[key] = historyDetails[key];
            }
          } else {
            tempObj[key] = Strings("NA");
          }
        }
      });
      rows.push(tempObj);
    });
    this.setState({ rows: rows });
  };

  assignAmountKey = (tempObj, key, historyDetails) => {
    tempObj[key[0]] = key[2].isAmount
      ? Utility.getCurrencyRepresentationOfAmount(
          historyDetails[key[0]]
        ) +
        " / " +
        Utility.getCurrencyRepresentationOfAmount(
          historyDetails[key[1]]
        )
      : historyDetails[key[0]] + " / " + historyDetails[key[1]];
  };

  render() {
    return (
      <div className='singlePersonWorkHistory'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <SinglePersonWorkHistory
          user={this.props.user}
          rows={this.state.rows}
          numberOfPages={this.state.numberOfPages}
          columns={this._columns}
          rowsCount={this.state.rows.length}
          rowHeight={60}
          headerRowHeight={50}
          handleSearchFilter={this.handleSearchFilter}
          userId={this.state.userId}
          searchFilters={this.props.searchFilters}
          goBack={this.props.goBack}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchFilters: state.searchFilterReducer.workHistorySearchFilter,
  userId: state.workHistoryReducer.userId,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SinglePersonWorkHistoryContainer);

//Components
import React, { Component } from "react";
import EditMinimartRequest from "./EditMinimartRequest";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import { Row } from "react-bootstrap";
import withHOC from "../../HOC/HOC";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { REQUEST_STATUS } from "../../constants/appConstants";

//Actions
import {
  getEditMinimartRequest,
  updateMinimartRequest,
  downloadPhotosAction,
  cedulaHistoryAction,
} from "./EditMinimartRequestAction";

// String
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

class EditMinimartRequestContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      showLoadingModal: false,
      numberOfPages: 0,
      isSuccess: false,
      successMessage: "",
      level: "",
      cedulaHistory: [],
    };
  }

  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
    });
    getEditMinimartRequest(searchFilterObject, (responsedata) => {
      if (responsedata.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          numberOfPages: responsedata.data.data.np,
          rows: responsedata.data.data.rq,
          errorMessage: "",
          showLoadingModal: false,
        });
      } else {
        this.setState({
          errorMessage: responsedata.error.message,
          rows: [],
          showLoadingModal: false,
        });
      }
    });
  };

  updateMinimartRequest = (reqObj, toggleModal, CallBack) => {
    this.setState({
      showLoadingModal: true,
      isSuccess: false,
      successMessage: "",
      level: "",
    });
    updateMinimartRequest(reqObj, (responsedata) => {
      if (responsedata.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            isSuccess: true,
            successMessage:
              reqObj.st === REQUEST_STATUS.approved
                ? Strings("RequestApprovedSuccessfully")
                : Strings("RequestRejectedSuccessfully"),
            level: "success",
          },
          () => {
            toggleModal();
            CallBack();
          }
        );
      } else {
        this.setState(
          {
            errorMessage: responsedata.error.message,
            showLoadingModal: false,
            isSuccess: true,
            successMessage: Strings(responsedata.error.message),
            level: "error",
          },
          toggleModal
        );
      }
    });
  };

  downloadPhoto = (frontPhoto) => {
    this.setState({
      showLoadingModal: true,
    });
    downloadPhotosAction(frontPhoto, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        const frontPhotoUrl = Utility.getDecryptedData(
          response.data.data.url
        );
        window.open(frontPhotoUrl);
        this.setState({
          showLoadingModal: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
        });
      }
    });
  };

  handleViewCedulaHistory = (request, callBack) => {
    this.setState({
      showLoadingModal: true,
    });
    cedulaHistoryAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoadingModal: false,
            cedulaHistory: response.data.data,
          },
          callBack
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoadingModal: false,
          cedulaHistory: [],
        });
      }
    });
  };

  render() {
    return (
      <>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        <Row className='heading'>
          {Strings("MTPEditMinimrtRequests")}
        </Row>

        {this.state.isSuccess && (
          <UserNotification
            key='Success'
            userNotificationObj={{
              message: Strings(this.state.successMessage),
              level: this.state.level,
            }}
          />
        )}
        <EditMinimartRequest
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          rows={this.state.rows}
          updateMinimartRequest={this.updateMinimartRequest}
          errorMessage={this.state.errorMessage}
          loggedInUser={this.props.loggedInUser}

          //Pagination
          numberOfPages={this.state.numberOfPages}
          downloadPhoto={this.downloadPhoto}
          cedulaHistory={this.state.cedulaHistory}
          handleViewCedulaHistory={this.handleViewCedulaHistory}
        />
      </>
    );
  }
}

const EditMinimartRequestWrapper = withHOC(EditMinimartRequestContainer);

export default EditMinimartRequestWrapper;


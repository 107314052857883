// Strings
import { Strings } from "../../resources/i18n/i18n";
export const TABLE_HEADER_LIST = [
  {
    key: "bn",
    name: Strings("Branch"),
  },
  {
    key: "pstn",
    name: Strings("position"),
  },
  {
    key: "rl",
    name: Strings("Role"),
  },
  {
    key: "zn",
    name: Strings("Zones"),
  },
  {
    key: "un",
    name: Strings("username"),
  },
  {
    key: "mmc",
    name: Strings("minimarts"),
  },
  { key: "action", name: "action" },
];

export const USER_TYPE = [
  { name: Strings("username") + " " + Strings("type"), value: "" },
  { name: Strings("SALES_PERSON"), value: "SALES_PERSON" },
  { name: Strings("MESSENGER"), value: "MESSENGER" },
];

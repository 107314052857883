// Constants
import { SET_CURRENT_FEATURE_ACTION } from "../../redux/constants";

const INITIAL_STATE = {
  selectedFeature: {
    activeIcon: "",
    featureName: "",
    icon: "",
    isActive: false,
    title: "",
    to: "",
  },
};

export default function SidebarReducer(state = INITIAL_STATE, action) {// NOSONAR
  switch (action.type) {
    case SET_CURRENT_FEATURE_ACTION:
      return {
        ...state,
        selectedFeature: action.feature,
      };
    default:
      return state;
  }
}

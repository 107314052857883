import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import ApprovalMinimart from "./ApprovalMinimart";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

// Action
import {
  getApproveMinimartDataAction,
  getApproveMinimartNotesAction,
  updateMinimartDetailsAction,
  approveMinimartAction,
  operationsApproveMinimartAction,
} from "./ApprovalMinimartAction";
import {
  getNoteAction,
  AddNoteAction,
} from "../AnalystApproval/AnalystApprovalAction";
import { getBranchDetailsAction } from "../BranchList/BranchListAction";
import { getMinimartBusinessTypes } from "../SuperDashBoard/SuperDashBoardAction";
import { setSearchFilterAction } from "../../constants/Action";

// String
import { Strings } from "../../resources/i18n/i18n";

// Constants
import { ROUTES } from "../../constants/routeConstants";
import {
  USER_ROLES,
  REQUEST_STATUS,
  SEARCH_FILTER_PAGES,
  MINIMART_HISTORY_FILTER,
} from "../../constants/appConstants";
import { OPERATION_STATUS } from "../../config/axios.init";
import { NOTES_TYPE } from "../AnalystApproval/Constants";

// Utility
import Utility from "../../utils/Utility";

class ApprovalMinimartContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuccess: false,
      errorMessage: "",
      showLoader: false,
      minimart: props.singleApproveMinimart,
      branches: props.branches,
      isAnalyst: props.loggedInUser.role === USER_ROLES.analyst,
      isAnalystModerator:
        props.loggedInUser.role === USER_ROLES.analystModerator,
      isOperationsOfficer:
        props.loggedInUser.role === USER_ROLES.operationsOfficer,
      isOperationsModerator:
        props.loggedInUser.role === USER_ROLES.operationsModerator,
      notes: {
        nts: "",
        vd: "",
      },
      successToastMessage: "",
      businessTypeList: [
        {
          key: 0,
          value: "",
          label: Strings("BusinessType"),
        },
      ],
    };
  }

  componentDidMount() {
    this.getBranchList();
    this.getSingleMinimartData(
      this.props.singleApproveMinimart?.tmid
    );
    if (this.state.isAnalyst) {
      this.getMinimartBusinessTypes();
    }
    if (
      this.props.isHistory ||
      this.state.isOperationsOfficer ||
      this.state.isOperationsModerator
    ) {
      this.getApproveMinimartNotes(
        this.props.singleApproveMinimart?.tmid
      );
    }
  }

  componentWillUnmount() {
    if (
      this.props.searchPage ===
        SEARCH_FILTER_PAGES.MINIMART_HISTORY &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.MINIMART_HISTORY,
        { ...MINIMART_HISTORY_FILTER }
      );
    }
  }

  getBranchList = () => {
    this.setState({ showLoader: true });
    this.props.getBranchDetailsAction((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          branches: response.data.data.branches,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  getMinimartBusinessTypes = () => {
    this.setState({ showLoader: true });
    getMinimartBusinessTypes((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let list = Utility.createBusinessTypesList(
          response.data.data.bty
        );
        this.setState({
          businessTypeList: list,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  showToastMessage = (status) => {
    this.setState(
      {
        showLoader: false,
        errorMessage: "",
        isSuccess: true,
        successToastMessage: Strings(`${status}_minimart`),
        level: "success",
      },
      () => {
        setTimeout(() => {
          this.props.history.push(ROUTES.MINIMART_APPROVAL_REQUESTS);
        }, 1000);
      }
    );
  };

  approveMinimart = (requestData) => {
    approveMinimartAction(requestData, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        if (requestData.st === REQUEST_STATUS.approved) {
          this.showToastMessage(REQUEST_STATUS.approved);
        } else if (requestData.st === REQUEST_STATUS.rejected) {
          this.showToastMessage(REQUEST_STATUS.rejected);
        }
      } else {
        this.setState({
          showLoader: false,
          isSuccess: true,
          successToastMessage: Strings("SorryAnErrorHasOccurred"),
          level: "error",
          errorMessage: responseData.error.message,
        });
      }
    });
  };

  operationsApproveMinimart = (requestData) => {
    operationsApproveMinimartAction(requestData, (responseData) => {
      //NOSONAR
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        if (requestData.st === REQUEST_STATUS.approved) {
          this.showToastMessage(REQUEST_STATUS.approved);
        } else if (requestData.st === REQUEST_STATUS.rejected) {
          this.showToastMessage(REQUEST_STATUS.rejected);
        }
      } else {
        this.setState({
          showLoader: false,
          isSuccess: true,
          successToastMessage: Strings("SorryAnErrorHasOccurred"),
          level: "error",
          errorMessage: responseData.error.message,
        });
      }
    });
  };

  addNote = (requestData) => {
    this.setState({ showLoader: true });
    const noteRequest = {
      refId: requestData.tmid,
      note: requestData.note,
      type: NOTES_TYPE.ANALYST_MINIMART_APPROVE,
    };
    const request = {
      tmid: requestData.tmid,
      vd: requestData.vd,
      st: requestData.type,
    };
    (this.state.isOperationsOfficer ||
      this.state.isOperationsModerator) &&
      this.operationsApproveMinimart(request);
    this.state.isAnalyst &&
      AddNoteAction(noteRequest, (responseData) => {
        if (responseData.status === OPERATION_STATUS.SUCCESS) {
          let nts = [];
          nts.push(responseData.data.data.id);
          request["nts"] = nts;
          this.approveMinimart(request);
        } else {
          this.setState({
            showLoadingModal: false,
            errorMessage: responseData.error.message,
          });
        }
      });
  };

  getSingleMinimartData = (minimartId) => {
    this.setState({ showLoader: true });
    getApproveMinimartDataAction(
      { tmid: minimartId },
      (responseData) => {
        if (responseData.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            minimart: responseData.data.data,
            showLoader: false,
          });
        } else {
          this.setState({
            errorMessage: responseData.error.message,
            showLoader: false,
          });
        }
      }
    );
  };

  getNotes = (noteId) => {
    getNoteAction(noteId, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          notes: {
            ...this.state.notes,
            nts: responseData.data.data.note,
          },
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: responseData.error.message,
          showLoader: false,
        });
      }
    });
  };

  getApproveMinimartNotes = (minimartId) => {
    this.setState({ showLoader: true, errorMessage: "" });
    getApproveMinimartNotesAction(
      { tmid: minimartId },
      (responseData) => {
        if (responseData.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            notes: {
              vd: responseData.data.data.vd,
            },
            showLoader: false,
          });
          this.getNotes(responseData.data.data.nts[0]);
        } else {
          this.setState({
            errorMessage: responseData.error.message,
            showLoader: false,
          });
        }
      }
    );
  };

  updateMinimartDetails = (requestData, callback) => {
    updateMinimartDetailsAction(requestData, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        callback();
        this.getSingleMinimartData(responseData.data.data.tmid);
      } else {
        this.setState({
          errorMessage: responseData.error.message,
          showLoader: false,
        });
      }
    });
  };

  render() {
    return (
      <div>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isSuccess && (
          <UserNotification
            key='Success'
            userNotificationObj={{
              message: this.state.successToastMessage,
              level: this.state.level,
            }}
          />
        )}

        {this.state.minimart?.tmid && (
          <ApprovalMinimart
            minimart={this.state.minimart}
            isHistory={this.props.isHistory}
            isAnalyst={this.state.isAnalyst}
            isOperationsOfficer={this.state.isOperationsOfficer}
            isOperationsModerator={this.state.isOperationsModerator}
            isAnalystModerator={this.state.isAnalystModerator}
            notes={this.state.notes}
            branches={this.state.branches}
            updateMinimartDetails={this.updateMinimartDetails}
            addNote={this.addNote}
            businessTypeList={this.state.businessTypeList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
  singleApproveMinimart:
    state.minimartListReducer.singleApproveMinimart,
  isHistory: state.minimartListReducer.isHistory,
  branches: state.branchListReducer.branchDetails.branches,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBranchDetailsAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalMinimartContainer);

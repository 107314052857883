import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import ChangePassword from "./ChangePassword";
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

//Constants
import { ROUTES } from "../../constants/routeConstants";
import { OPERATION_STATUS } from "../../config/axios.init";
import SideBarMenu from "../Sidebar/sidebarConstants";

//Actions
import { changePasswordAction } from "./ChangePasswordAction";
import { logoutAction } from "../Login/LoginAction";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Assets
import ChangePasswordImage from "../../resources/images/ic_password_change.svg";

export class ChangePasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChangePasswordSuccess: false,
      errorMessage: "",
      showLoader: false,
    };
  }

  handleLogoutAfterChangePassword = () => {
    this.props.logoutAction((apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        if (!localStorage.getItem("state") || !JSON.parse(localStorage.getItem("state")).loginReducer.loggedInUserInfo.userId) {
          this.props.history.push(ROUTES.LOGIN);
        }
      }
    });
  };

  goToPreviousPage = () => {
    let sidebarItems = [];
    sidebarItems =
      this.props.loggedInUserInfo &&
      SideBarMenu(Object.keys(this.props.loggedInUserInfo.features));
    sidebarItems && sidebarItems.sort((a, b) => a.key - b.key);
    this.props.history.push(sidebarItems[0].to);
  };

  handleChangePasswordSubmit = (oldPassword, newPassword) => {
    let requestData = { oldPassword, newPassword };
    this.setState({ showLoader: true, errorMessage: "" });

    changePasswordAction(requestData, (responseStatus) => {
      if (responseStatus === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          isChangePasswordSuccess: true,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: Strings("ErrorInvalidOldPassword"),
          showLoader: false,
        });
      }
    });
  };

  render() {
    return (
      <div>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isChangePasswordSuccess && (
          <UserModal
            open={true}
            modalCloseOnEsc={false}
            modalCloseOnOverlayClick={false}
            modalShowCloseIcon={false}
            headerText={Strings("PasswordChanged")}
            modalImage={ChangePasswordImage}
            modalText1={Strings("YourPasswordHasBeen")}
            modalText2={Strings("ChangedSuccessfully")}
            modalButtonOk={{
              text: Strings("LoginAgain"),
              onClick: this.handleLogoutAfterChangePassword,
            }}
            showHr={true}
            modalButtonCancel={null}
          />
        )}
        <ChangePassword
          goToPreviousPage={this.goToPreviousPage}
          handleChangePasswordSubmit={this.handleChangePasswordSubmit}
          errorMessage={this.state.errorMessage}
        />
      </div>
    );
  }
}

ChangePasswordContainer.propTypes = {
  aString: PropTypes.string,
  anObject: PropTypes.object,
  aFunc: PropTypes.func,
  aBool: PropTypes.bool,
  anArray: PropTypes.array,
};

const mapStateToProps = (state) => ({
  loggedInUserInfo: state.loginReducer.loggedInUserInfo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logoutAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer);

import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Component
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";

//Action

//Constants
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  COMMISSION_REPORT_DOWNLOAD,
  COMMISSION_REPORT_API_LIMIT_PARAM,
} from "../Comission/Constants";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  COMMISSION_USER_ROLE,
  DOWNLOAD_FILE_TYPE,
  INVOICE_DATE_FORMAT,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./CommissionReport.scss";

//Assets
import PDFdownload from "../../resources/images/ic_download_pdf.svg";

class CommissionReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordsPerPage: props.searchFilter.limit,
      activePage: props.searchFilter.offset,
      CommissionFilters: {
        fromDate: props.searchFilter.fd,
        toDate: props.searchFilter.td,
      },
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.getCommissionsHeader({
      sc: {
        fd: Utility.convertDateIntoMiliSeconds(
          this.state.CommissionFilters.fromDate
        ),
        td: Utility.convertDateIntoMiliSeconds(
          this.state.CommissionFilters.toDate
        ),
        id: this.props.user.id,
      },
    });
    this.props.handleSearchFilter({
      sc: {
        fd: Utility.convertDateIntoMiliSeconds(
          this.state.CommissionFilters.fromDate
        ),
        td: Utility.convertDateIntoMiliSeconds(
          this.state.CommissionFilters.toDate
        ),
        id: this.props.user.id,
      },
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  handleOnClickResetFilter = () => {
    let tempObj = {
      fromDate: DEFAULT_DURATION.fromDate,
      toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
    };
    this.setState({ CommissionFilters: { ...tempObj } }, () => {
      this.handleOnClickSearchFilter(true);
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.CommissionFilters.toDate)) {
          DateValue = this.state.CommissionFilters.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (
          dateMoment.isBefore(this.state.CommissionFilters.fromDate)
        ) {
          DateValue =
            moment(dateMoment).diff(
              this.state.CommissionFilters.fromDate,
              "days"
            ) === 0
              ? this.state.CommissionFilters.fromDate
              : this.state.CommissionFilters.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({
      CommissionFilters: {
        ...this.state.CommissionFilters,
        [key]: DateValue,
      },
    });
  };

  downloadFile = (filetype) => {
    const requestData = {
      sc: {
        fd: Utility.convertDateIntoMiliSeconds(
          this.state.CommissionFilters.fromDate
        ),
        td: Utility.convertDateIntoMiliSeconds(
          this.state.CommissionFilters.toDate
        ),
        id: this.props.user.id,
      },
      limit: COMMISSION_REPORT_API_LIMIT_PARAM,
      offset: 0,
    };
    this.props.downloadReports(
      COMMISSION_REPORT_DOWNLOAD[this.props.userRole][1],
      requestData,
      filetype
    );
  };

  renderSearchFilter = () => {
    let commissionFilters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.CommissionFilters.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.CommissionFilters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
    ];
    return (
      <SearchFilter
        className='pt-3'
        filters={commissionFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={true}
      />
    );
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "dt":
        return moment(row[key]).format(INVOICE_DATE_FORMAT);
      case "glc":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "gcc":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "atbc":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "ac":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      default:
        return Strings(row[key]);
    }
  };

  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer component={Paper} className='w-75'>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"text-center px-2 header"}
                      title={column.label}
                    >
                      <div>{column.name}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.id}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => callBack(row)}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"text-center px-2"}
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ width: column.minWidth }}
                    >
                      <div>
                        {this.renderTableData(row, column.key)}
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  renderPagination = () => {
    return (
      <Pagination
        activePage={this.state.activePage}
        recordsPerPage={this.state.recordsPerPage}
        numberOfPages={this.props.numberOfPages}
        onPageChange={this.onPageChange}
        dropup={true}
      />
    );
  };

  renderPDFButton = () => {
    return (
      <div>
        <Button
          className='buttonBackground mr-3'
          onClick={() => {
            this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
          }}
        >
          <img src={PDFdownload} alt='' />
        </Button>
      </div>
    );
  };

  renderHeader = () => {
    return (
      this.props.header && (
        <Row className='pt-2 mb-3 pl-3'>
          <div className='pr-5'>
            {" "}
            <span className='subHeaderText'>
              {Strings("username")}
            </span>{" "}
            &nbsp;:&nbsp;
            <span className='detailText boldText'>
              {this.props.header.un}
            </span>
          </div>
          <div className='pr-5'>
            {" "}
            <span className='subHeaderText'>
              {this.props.userRole === COMMISSION_USER_ROLE.SALES ||
              this.props.userRole ===
                COMMISSION_USER_ROLE.SALES_MODERATOR
                ? Strings("loansAndInvoicesCount")
                : Strings("AssignedAndCollectedAmount")}
            </span>
            &nbsp;:&nbsp;
            <span className='detailText boldText'>
              {this.props.userRole === COMMISSION_USER_ROLE.SALES ||
              this.props.userRole ===
                COMMISSION_USER_ROLE.SALES_MODERATOR
                ? `${this.props.header.gl}/${this.props.header.aw}`
                : `${Utility.getCurrencyRepresentationOfAmount(
                    this.props.header.ac
                  )}/${Utility.getCurrencyRepresentationOfAmount(
                    this.props.header.atbc
                  )}`}
            </span>
          </div>
          <div>
            <span className='subHeaderText'>
              {Strings("CommissionGeneratedAndPaid")}
            </span>
            &nbsp;:&nbsp;
            <span className='detailText boldText'>
              {Utility.getCurrencyRepresentationOfAmount(
                this.props.header.pc
              )}
              /
              {Utility.getCurrencyRepresentationOfAmount(
                this.props.header.gc
              )}
            </span>
          </div>
        </Row>
      )
    );
  };

  renderBackButton = (className) => {
    return (
      <div className={`pull-left pb-2 ${className}`}>
        <Button
          className='buttonBorder'
          onClick={this.props.handleBackButtonClick}
        >
          {Strings("Back").toUpperCase()}
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div className='commissionReport ListContainer container-fluid'>
        <Row>
          <Col md={11} className='pl-0'>
            {this.renderSearchFilter()}
          </Col>
          <Col
            md={1}
            className='d-flex justify-content-end alignDownloadButtons pt-3 pr-0'
          >
            {this.renderPDFButton()}
          </Col>
        </Row>
        <Row className='pt-3'>
          <Col md={12} className=' errorText'>
            {this.props.errorMessage}
          </Col>
        </Row>
        <hr className='dividerHr' />
        {this.renderHeader()}
        {this.props.rowsCount !== 0 ? (
          <div className='userListTable'>
            {this.renderCustomDataGrid(
              this.props.columns,
              this.props.rows,
              this.props.onRowClick
            )}
            <Col md={12} className='noPadding alignPagination'>
              {this.renderBackButton("pt-3 pl-3")}
              {this.renderPagination()}
            </Col>
          </div>
        ) : (
          <div>
            <div className='row'>
              <div className='col-md-10 noRecordsFound'>
                {Strings("NoRecordsFound")}
              </div>
            </div>
            <div className='row'>
              {this.renderBackButton("col-md-2 pl-0")}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CommissionReport;

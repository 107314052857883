import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
export const getProductCatalogAction = (requestData, callback) => {
  callAPI(
    "getProductCatalog",
    API.getProductCatalog,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAllCategoriesAction = (callback) => {
  callAPI(
    "getAllCategories",
    API.getAllCategories,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateProductAction = (data, callback) => {
  callAPI(
    "updateProduct",
    API.updateProduct.concat(data.id),
    "PUT",
    data,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateProductCategoryAction = (data, callback) => {
  callAPI(
    "updateProductCategory",
    API.updateProductCategory.concat(data.id),
    "PUT",
    { category: data.category },
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const addProductAction = (data, callback) => {
  callAPI(
    "addProduct",
    API.addProduct,
    "POST",
    data,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const addProductCategoryAction = (data, callback) => {
  callAPI(
    "addProductCategory",
    API.addProductCategory,
    "POST",
    data,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getProductByIdAction = (id, callback) => {
  callAPI(
    "getProductById",
    API.getProductById,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getCategoryByIdAction = (id, callback) => {
  callAPI(
    "getCategoryById",
    API.getCategoryById,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

//React components
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

//Components
import ApprovalDashboard from "./ApprovalDashboard";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import withHOC from "../../HOC/HOC";

//Actions
import {
  getLoansForLateFeesAction,
  getLoansForAssignRepaymentAction,
  // getHigherCreditLimitRequestsAction,
} from "../ApprovalDashboard/ApprovalDashboardAction";
import { setUserAction } from "../UserList/UserListAction";
import { setLoanDetailsIDAction } from "../LoanDetails/LoanDetailsAction";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import { setMiniMartDetailsAction } from "../CiInterface/CIInterfaceActions";
import { setSearchFilterAction } from "../../constants/Action";
import {
  setMinimartDetailsActiveTab,
} from "../MinimartDetails/MiniMartDetailsAction";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  LOAN_CARD_TYPE,
  SEARCH_FILTER_PAGES,
  APPROVAL_DASHBOARD_FILTER,
} from "../../constants/appConstants";
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";
import { ROUTES } from "../../constants/routeConstants";

//Utility
import Utility from "../../utils/Utility";

class ApprovalDashboardContainer extends React.Component {
  /* #region  LifeCycle Methods */
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: props.errorMessage,
      showLoader: false,
      users: [],
      lateFeesLoans: [],
      assignPlanLoans: [],
      branches: props.branches,
      minimarts: [],
      numberOfPages: DEFAULT_PAGINATION_ACTIVE_PAGE,
      repaymentNumberOfPages: DEFAULT_PAGINATION_ACTIVE_PAGE,
      PersonFilters: {
        search: {
          loanId: "",
          minimartName: "",
          branchId: "",
          category: "",
          loanType: "",
          collectionOfficer: "",
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
        },
      },
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.props.clearDailyStatusDateAction();
    this.props.getBranchList();
    this.props.getZonesByBranch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD,
        {
          ...APPROVAL_DASHBOARD_FILTER,
        }
      );
    }
  }

  /* #endregion */

  /* #region  Actions */

  getUserList = (searchFilterObject) => {
    this.setState({ showLoader: true });
    const stateObject = Utility.getUserList(
      searchFilterObject,
      this.props.setUserAction
    );
    this.setState(stateObject);
  };

  getLoansRespose = (response, key) => {
    let setStateObject = {
      showLoader: false,
      errorMessage: "",
    };
    if (response.status === OPERATION_STATUS.SUCCESS) {
      if (response.data.data.loans.length === 0) {
        Object.assign(setStateObject, {
          [key]: [],
        });
        setStateObject.numberOfPages =
          response.data.data.numberOfPages;
      } else {
        Object.assign(setStateObject, {
          [key]: response.data.data.loans,
        });
        setStateObject.numberOfPages =
          response.data.data.numberOfPages;
      }
    } else {
      setStateObject.errorMessage = response.error.message;
    }
    this.setState(setStateObject);
  };

  handleTakeOffLateFeeFilter = (
    searchFilterObject
  ) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        fd: searchFilterObject.search.fromDate,
        td: searchFilterObject.search.toDate,
        ty: searchFilterObject.search.loanType,
        ct: searchFilterObject.search.category,
        lid: searchFilterObject.search.loanId,
        un: searchFilterObject.search.requester,
        br: searchFilterObject.search.branchId,
        zn: searchFilterObject.search.zone,
        mzn: searchFilterObject.search.mZone,
        mn: searchFilterObject.search.minimartName,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    getLoansForLateFeesAction(searchFilterObject, (response) => {
      this.getLoansRespose(
        response,
        LOAN_CARD_TYPE.LATE_FEES_LOANS,
      );
    });
  };

  handleAssignRepaymentFilter = (
    searchFilterObject,
  ) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        fd: searchFilterObject.search.fromDate,
        td: searchFilterObject.search.toDate,
        ty: searchFilterObject.search.loanType,
        ct: searchFilterObject.search.category,
        lid: searchFilterObject.search.loanId,
        un: searchFilterObject.search.requester,
        br: searchFilterObject.search.branchId,
        zn: searchFilterObject.search.zone,
        mzn: searchFilterObject.search.mZone,
        mn: searchFilterObject.search.minimartName,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    getLoansForAssignRepaymentAction(
      searchFilterObject,
      (response) => {
        this.getLoansRespose(
          response,
          LOAN_CARD_TYPE.ASSIGN_PLAN_LOANS,
        );
      }
    );
  };

  setLoanDetailsID = (loanDetails, activeTab) => {
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD,
      this.state.searchFilter,
      SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD
    );
    this.props.setMinimartDetailsActiveTab(activeTab);
    this.props.setLoanDetailsIDAction(loanDetails);
    this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
  };

  setMiniMartDetails = (minimart) => {
    this.isCardClick = true;
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD,
      this.state.searchFilter,
      SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD
    );
    this.props.setMiniMartDetailsAction(minimart);
    this.props.history.push(ROUTES.MINIMART_DETAILS);
  };
  /* #endregion */

  render() {
    return (
      <div className='dailyStatus'>
        <span className='heading'>
          {Strings("ApprovalDashboard")}
        </span>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <ApprovalDashboard
          getZonesByBranch={this.props.getZonesByBranch}
          loans={this.state.lateFeesLoans}
          assignPlanLoans={this.state.assignPlanLoans}
          users={this.props.users}
          branches={this.props.branches}
          zones={this.props.zones}
          mZones={this.props.mZones}
          handleTakeOffLateFeeFilter={this.handleTakeOffLateFeeFilter}
          handleAssignRepaymentFilter={
            this.handleAssignRepaymentFilter
          }
          repaymentNumberOfPages={this.state.repaymentNumberOfPages}
          numberOfPages={this.state.numberOfPages}
          loggedInUser={this.props.loggedInUser}
          setLoanDetailsID={this.setLoanDetailsID}
          getUserList={this.getUserList}
          minimarts={this.state.minimarts}
          setMiniMartDetails={this.setMiniMartDetails}
          creditLimitNumberPages={this.state.creditLimitNumberPages}
          searchFilter={this.props.approvalDashboardSearchFilter}
          setSearchFilterAction={this.props.setSearchFilterAction}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  salesPersonDate: state.DailyStatusReducer.salesPersonDate,
  messengerDate: state.DailyStatusReducer.messengerDate,
  users: state.userListReducer.users.users,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoanDetailsIDAction,
      setMiniMartDetailsAction,
      clearDailyStatusDateAction,
      setUserAction,
      setSearchFilterAction,
      setMinimartDetailsActiveTab
    },
    dispatch
  );
};

const ApprovalDashboardWrapper = withHOC(ApprovalDashboardContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalDashboardWrapper);

import React from "react";
import { Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";

//Constants
import { CI_FILE_STATUS, DEFAULT_FILE_STATUS } from "./CIConstants";
import {
  TYPE_OF_CUSTOMER,
  DEFAULT_TYPE_OF_CUSTOMER_SELECTION,
  INVOICE_DATE_FORMAT,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  SEARCH_FILTER_PLACE_HOLDER,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./CiInterface.scss";

class CiInterface extends React.Component {
  /* #region  LifeCycle Methods  */

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      fileName: props.searchFilter.name || "",
      fileStatus: props.searchFilter.status || "",
      Date: props.searchFilter.processedAt
        ? new Date(props.searchFilter.processedAt)
        : undefined,
      recordsPerPage: props.searchFilter.limit,
      activePage: props.searchFilter.offset,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  /* #endregion */

  /* #region  Events */

  handleSearchFileNameChange = (event) => {
    this.setState({ fileName: event.target.value });
  };

  handleFileStatusChange = (event) => {
    if (event.target.value !== Strings("selectFileStatus")) {
      this.setState({
        fileStatus: event.target.value,
      });
    } else {
      this.setState({
        fileStatus: "",
      });
    }
  };

  handleDateChange = (value) => {
    this.setState({ Date: new Date(value) });
  };

  /* #endregion */

  /* #region  Pagination */

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  /* #endregion */

  /* #region  Search Filter  */

  resetSearchValues = () => {
    this.setState(
      {
        Date: undefined,
        fileStatus: "",
        fileName: "",
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      },
      () => {
        this.handleOnClickSearchFilter(true);
      }
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleOnClickSearchFilter({
      name:
        this.state.fileName !== "" ? this.state.fileName : undefined,
      status:
        this.state.fileStatus !== DEFAULT_FILE_STATUS &&
        this.state.fileStatus.length > 0
          ? this.state.fileStatus
          : undefined,
      processedAt:
        this.state.Date !== undefined
          ? Utility.convertDateIntoMiliSeconds(this.state.Date)
          : undefined,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  /* #endregion */

  renderSearchFilter = () => {
    let minimartFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("fileName"),
        handleOnChange: this.handleSearchFileNameChange,
        Value: this.state.fileName,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleFileStatusChange,
        Value: this.state.fileStatus,
        fieldname: SEARCH_FILTER_FIELDS.GRADE,
        filterDropdownList: CI_FILE_STATUS,
      },
      {
        type: SEARCH_FILTER_TYPE.SINGLE_DATE,
        placeholder: SEARCH_FILTER_PLACE_HOLDER.SELECT_DATE,
        handleCalenderChange: this.handleDateChange,
        Value: this.state.Date,
        dateFormat: INVOICE_DATE_FORMAT,
      },
    ];
    return (
      <SearchFilter
        filters={minimartFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.resetSearchValues}
        paddingLeft={true}
      />
    );
  };

  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer component={Paper} className='w-75'>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"text-center header px-0"}
                      title={column.name}
                    >
                      <div>{column.name}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => callBack(rowIndex)}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"text-center px-0"}
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ width: column.minWidth }}
                    >
                      <div>{row[column.key]}</div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  renderPagination = () => {
    return (
      <Pagination
        activePage={this.state.activePage}
        recordsPerPage={this.state.recordsPerPage}
        numberOfPages={this.props.numberOfPages}
        onPageChange={this.onPageChange}
        dropup={true}
      />
    );
  };

  render() {
    TYPE_OF_CUSTOMER[0].value !== "" &&
      TYPE_OF_CUSTOMER.unshift(DEFAULT_TYPE_OF_CUSTOMER_SELECTION);
    return (
      <div>
        <Row>
          <span className='heading'>{Strings("CIInterface")}</span>
        </Row>
        <div className='ciInterface'>
          <div className='cards'>
            <Row className='filters'>{this.renderSearchFilter()}</Row>
            <Row className='errors pl-3'>
              {this.props.errorMessage !== "" &&
                Strings(this.props.errorMessage)}
            </Row>

            {this.props.rowsCount !== 0 ? (
              <div className='branchListTable noPadding'>
                {this.renderCustomDataGrid(
                  this.props.columns,
                  this.props.rows,
                  this.props.onRowClick
                )}
                <Col md={12} className='noPadding'>
                  {this.renderPagination()}
                </Col>
              </div>
            ) : (
              <div className='noRecordsFound'>
                {Strings("NoRecordsFound")}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CiInterface;

import { Strings } from "../../resources/i18n/i18n";

export const DEFAULT_BRANCH_SELECTION = "selectBranch";

export const DEFAULT_STATUS = "SelectBranchStatus";
export const GET_BRANCH_STATUS_DROPDOWN = () => [
  {
    key: 0,
    label: Strings(DEFAULT_STATUS),
    value: DEFAULT_STATUS,
  },
  {
    key: 1,
    label: Strings("Active"),
    value: "true",
  },
];
export const DEFAULT_ZONE = Strings("selectZone");
export const DEFAULT_MESSENGER_ZONE = Strings("mzone");

export const GET_DEFAULT_ZONE_SELECTION = () => ({
  name: DEFAULT_ZONE,
  active: "true",
});

export const GET_DEFAULT_MESSENGER_ZONE_SELECTION = () => ({
  name: DEFAULT_MESSENGER_ZONE,
  active: "true",
});

export const defaultObjForAddOrUpdateBranch = {
  id: 0,
  active: "",
  name: "",
  address: "",
  phone: "",
  zone: "",
};

export const GET_BRANCH_TABLE_COLUMNS = () => [
  {
    key: "name",
    name: Strings("Name"),
    minWidth: 100,
  },
  {
    key: "address",
    name: Strings("Address"),
    minWidth: 100,
  },
  {
    key: "phone",
    name: Strings("PhoneNumber"),
    minWidth: 100,
  },
  {
    key: "active",
    name: Strings("Status"),
    minWidth: 80,
  },
];
export const STATE_VARIABLES = {
  CREATE_SUCCESS: "createSuccess",
  UPDATE_SUCCESS: "updateSuccess",
};

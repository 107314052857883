import axios from "axios";

// Constants
import { OPERATION_STATUS, API_ERROR_CODE } from "./axios.init";
import { getErrorInfo, getAPIErrorInfo } from "./errorLookup";

//Anonymous APIs
// const anonymousApis = [API.login, API.forgotPassword];

/**
 *  Action to handle actual API call
 */

export const callAPI = (
  action,
  url,
  httpMethod,
  requestBody,
  callback,
  downloadFile = false, isFormData = false
) => {
  callActualAPI(
    action,
    {
      //baseURL: REACT_APP_BASE_API_URL,
      url: url,
      method: httpMethod,
      data: httpMethod !== "GET" ? requestBody : null,
      params: httpMethod === "GET" ? requestBody : null,
      withCredentials: true,
      headers: {},
    },
    callback,
    downloadFile, isFormData
  );
};

const callActualAPI = (
  action,
  apiConfig,
  callback,
  downloadFile = false,
  isFormData = false
) => {
  console.log(
    action + " Request: ",
    apiConfig,
    "\ndownloadFile:",
    downloadFile
  );

  /* headers */
  let contentType = isFormData ? "multipart/form-data" : "application/json";
  let responseType = downloadFile ? "blob" : "";

  if (action === "Login") {
    contentType = "application/x-www-form-urlencoded";
  }
  if (action === "Login" || action === "ForgotPassword") {
    apiConfig.headers = {
      "Access-Type": "49bc833c-1ea3-4839-98ef-6275e76a53a6",
      "Content-Type": contentType,
    };
  } else {
    apiConfig.headers = {
      "Content-Type": contentType,
    };
  }

  apiConfig["responseType"] =
    responseType !== "" ? responseType : undefined;

  axios(apiConfig)
    .then((response) => {
      console.log(action + " Response: ", response);
      handleSuccess(response.data, function (apiResponse) {
        callback(apiResponse);
      });
    })
    .catch((error) => {
      console.log(action + " Error: ", error);
      handleFailure(error, function (operationInfo) {
        callback(operationInfo);
      });
    });
};

// const isNotAnonymousApi = url => {
//   return anonymousApis.indexOf(url) === -1;
// };

const handleSuccess = (data, callback) => {
  if (
    data.status &&
    data.status !== API_ERROR_CODE.SUCCESS
  ) {
    let error = getAPIErrorInfo({
      code: data.status,
      message: data.msg ? data.msg : "",
      data: data.data
    });
    callback({
      status: OPERATION_STATUS.FAILURE,
      data: {},
      error: error,
    });
  } else {
    callback({
      status: OPERATION_STATUS.SUCCESS,
      data: data,
      error: {},
    });
  }
};

const handleFailure = (error, callback) => {
  console.log("handleFailure", error.response);
  if (error.response) {
    console.log(error.response.status);
  }
  if (!error.response) {
    callback({
      status: OPERATION_STATUS.FAILURE,
      data: {},
      error: getErrorInfo(OPERATION_STATUS.NETWORK_NOT_REACHABLE),
    });
  } else if (error.response.status === API_ERROR_CODE.UNAUTHORIZED) {
    if (error.response) {
      callback({
        status: OPERATION_STATUS.FAILURE,
        data: {},
        error: getAPIErrorInfo(
          API_ERROR_CODE.ACCOUNT_LOCKED_ERROR ===
            error.response.data.status
            ? { code: API_ERROR_CODE.ACCOUNT_LOCKED_ERROR }
            : { code: API_ERROR_CODE.INVALID_LOGIN_CREDENTIALS }
        ),
      });
    }
  } else if (
    error.response.data.error &&
    error.response.data.error.code
  ) {
    callback({
      status: OPERATION_STATUS.FAILURE,
      data: {},
      error: getAPIErrorInfo(error.response.data.error),
    });
  } else {
    callback({
      status: OPERATION_STATUS.FAILURE,
      data: {},
      error: getAPIErrorInfo({ code: error.response.status }),
    });
  }
};

import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Component
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";

//Action

//Constants
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { LOAN_TYPE } from "./Constants";
import {
  COMMISSION_REPORT_DOWNLOAD,
  COMMISSION_REPORT_API_LIMIT_PARAM,
} from "../Comission/Constants";
import {
  INVOICE_DATE_FORMAT,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  COMMISSION_USER_ROLE,
  DOWNLOAD_FILE_TYPE,
  TYPE,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./DetailCommissionReport.scss";

//Assets
import PDFdownload from "../../resources/images/ic_download_pdf.svg";

class DetailCommissionReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      offset: DEFAULT_PAGINATION_ACTIVE_PAGE,
      type: "type",
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  handleChangeTypeFilter = (event) => {
    this.setState({ type: event.target.value });
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    const dateObject = new Date(this.props.dateData.dt).getTime();
    this.props.getCommissionsHeader({
      sc: {
        fd: dateObject,
        td: dateObject,
        tp: this.state.type !== TYPE ? this.state.type : undefined,
        id: this.props.dateData.id,
      },
    });

    this.props.handleSearchFilter({
      sc: {
        fd: dateObject,
        td: dateObject,
        tp: this.state.type !== TYPE ? this.state.type : undefined,
        id: this.props.dateData.id,
      },
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
        : this.state.activePage - 1,
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  handleOnClickResetFilter = () => {
    this.setState({ type: TYPE }, () => {
      this.handleOnClickSearchFilter(true);
    });
  };

  downloadFile = (filetype) => {
    const dateObject = new Date(this.props.dateData.dt).getTime();
    let sc = {
      fd: dateObject,
      td: dateObject,
      id: this.props.dateData.id,
    };
    const requestData = {
      sc,
      limit: COMMISSION_REPORT_API_LIMIT_PARAM,
      offset: 0,
    };

    if (
      this.props.userRole === COMMISSION_USER_ROLE.SALES ||
      this.props.userRole === COMMISSION_USER_ROLE.SALES_MODERATOR
    ) {
      sc.tp = this.state.type !== TYPE ? this.state.type : null;
    }

    this.props.downloadReports(
      COMMISSION_REPORT_DOWNLOAD[this.props.userRole][2],
      requestData,
      filetype
    );
  };

  //render
  renderDownloadPDF = (offset, span, paddingTop) => {
    return (
      <Col
        md={{ span, offset }}
        className={`d-flex justify-content-end alignDownloadButtons pr-0 pt-${paddingTop}`}
      >
        <div>
          <Button
            className='buttonBackground mr-3'
            onClick={() => {
              this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
            }}
          >
            <img src={PDFdownload} alt='' />
          </Button>
        </div>
      </Col>
    );
  };

  renderSearchFilter = () => {
    let commissionFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeTypeFilter,
        Value: this.state.type,
        fieldname: SEARCH_FILTER_FIELDS.TYPE,
        filterDropdownList: LOAN_TYPE,
        column: 2,
      },
    ];
    return (
      <Row>
        <Col md={11} className='pl-0'>
          <SearchFilter
            filters={commissionFilters}
            handleOnClickSearchFilter={this.handleOnClickSearchFilter}
            handleOnClickResetFilter={this.handleOnClickResetFilter}
            paddingLeft={true}
          />
        </Col>
        {this.renderDownloadPDF(0, 1, 2)}
      </Row>
    );
  };

  renderHeaderColumns = (index) => {
    let colLabel = "";
    let colValue = "";

    if (index === 1) {
      colLabel = "username";
      colValue = this.props.header.un;
    } else if (index === 2) {
      colLabel =
        this.props.userRole === COMMISSION_USER_ROLE.SALES ||
        this.props.userRole === COMMISSION_USER_ROLE.SALES_MODERATOR
          ? "loansAndInvoicesCount"
          : "AssignedAndCollectedAmount";
      colValue =
        this.props.userRole === COMMISSION_USER_ROLE.SALES ||
        this.props.userRole === COMMISSION_USER_ROLE.SALES_MODERATOR
          ? `${this.props.header.gl}/${this.props.header.aw}`
          : `${Utility.getCurrencyRepresentationOfAmount(
              this.props.header.ac
            )}/${Utility.getCurrencyRepresentationOfAmount(
              this.props.header.atbc
            )}`;
    } else if (index === 3) {
      colLabel = "Date";
      colValue = moment(this.props.header.dt).format(
        INVOICE_DATE_FORMAT
      );
    } else if (index === 4) {
      colLabel = "generatedLoanCommission";
      colValue = Utility.getCurrencyRepresentationOfAmount(
        this.props.header.glc
      );
    } else if (index === 5) {
      colLabel = "generatedCollectionCommission";
      colValue = Utility.getCurrencyRepresentationOfAmount(
        this.props.header.gcc
      );
    }

    return (
      <div
        className={`${index === 4 || index === 5 ? "" : "pr-5"} pt-2`}
      >
        {" "}
        <span className='subHeaderText'>
          {Strings(colLabel)}
          {index === 4 || index === 5 ? " ( $ )" : ""}
        </span>
        &nbsp;:&nbsp;
        <span className='detailText boldText'>{colValue}</span>
      </div>
    );
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "amt":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case "cm":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      default:
        return row[key] ? Strings(row[key]) : "--";
    }
  };

  renderTableClass = (key) => {
    if (
      key === "mid" ||
      key === "cd" ||
      key === "lid" ||
      key === "rf" ||
      key === "amt" ||
      key === "cm"
    ) {
      return "text-center px-2";
    } else {
      return "text-left px-2";
    }
  };

  renderCustomDataGrid = (columns, rows) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer
          component={Paper}
          className='d-flex justify-content-center'
        >
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={`${this.renderTableClass(
                        column.key
                      )} header`}
                      title={column.label}
                    >
                      <div>{column.name}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.mid}
                  hover
                  style={{ cursor: "pointer" }}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={`${this.renderTableClass(
                        column.key
                      )}`}
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ width: column.minWidth }}
                    >
                      <div>
                        {this.renderTableData(row, column.key)}
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  renderDataTable = () => {
    return (
      <div className='userListTable'>
        {this.renderCustomDataGrid(
          this.props.columns,
          this.props.rows
        )}
        <Col md={12} className='noPadding alignPagination'>
          <div className='pull-left pt-2 pl-3 pb-2'>
            <Button
              className='buttonBorder'
              onClick={this.props.handleBackButtonClick}
            >
              {Strings("Back").toUpperCase()}
            </Button>
          </div>
          <Pagination
            activePage={this.state.activePage}
            recordsPerPage={this.state.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.onPageChange}
            dropup={true}
          />
        </Col>
      </div>
    );
  };

  renderNoDataTable = () => {
    return (
      <div>
        <div className='row'>
          <div className='col-md-10 noRecordsFound'>
            {Strings("NoRecordsFound")}
          </div>
        </div>
        <div className='row'>
          <div className='pull-left pb-2 col-md-2 pl-0'>
            <Button
              className='buttonBorder'
              onClick={this.props.handleBackButtonClick}
            >
              {Strings("Back").toUpperCase()}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className='detailCommissionReport ListContainer container-fluid'>
        {(this.props.userRole === COMMISSION_USER_ROLE.SALES ||
          this.props.userRole ===
            COMMISSION_USER_ROLE.SALES_MODERATOR) &&
          this.renderSearchFilter()}

        <Row>
          <Col md={12} className='errorText'>
            {this.props.errorMessage}
          </Col>
        </Row>

        {(this.props.userRole === COMMISSION_USER_ROLE.SALES ||
          this.props.userRole ===
            COMMISSION_USER_ROLE.SALES_MODERATOR) && (
          <hr className='dividerHr' />
        )}

        {this.props.header && this.props.header.un && (
          <Row className='pt-2 pl-3 mb-2'>
            <Col md={3}>{this.renderHeaderColumns(1)}</Col>
            <Col md={5}>{this.renderHeaderColumns(2)}</Col>
            <Col md={3}>{this.renderHeaderColumns(3)}</Col>
            {this.props.userRole !== COMMISSION_USER_ROLE.SALES &&
              this.props.userRole !==
                COMMISSION_USER_ROLE.SALES_MODERATOR &&
              this.renderDownloadPDF(0, 1, 0)}
            {this.props.userRole ===
              COMMISSION_USER_ROLE.SALES_MODERATOR && (
              <Col md={4}>{this.renderHeaderColumns(4)}</Col>
            )}
            {this.props.userRole ===
              COMMISSION_USER_ROLE.SALES_MODERATOR && (
              <Col md={4}>{this.renderHeaderColumns(5)}</Col>
            )}
          </Row>
        )}
        {this.props.rowsCount !== 0
          ? this.renderDataTable()
          : this.renderNoDataTable()}
      </div>
    );
  }
}

export default DetailCommissionReport;

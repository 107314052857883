import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";
//Utility
import Utility from "../../utils/Utility";

export const getRemittancePaymentAction = (request, callback) => {
  request.search.rd = Utility.convertDateIntoMiliSeconds(
    request.search.rd
  );
  callAPI(
    "getRemittancePaymentAction",
    API.getRemittancePayment,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const markPaidRemittanceAction = (request, callback) => {
  callAPI(
    "markPaidRemittanceAction",
    API.markPaidRemittance,
    "PATCH",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

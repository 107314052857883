import { Component } from "react";

//Action
import { getFileDataAction } from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";

//Utils
import Utility from "../../utils/Utility";

export default class DeactivateAccount extends Component {

    componentDidMount() {
        getFileDataAction("ACCOUNT_DEACTIVATION", (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                const s3Url = Utility.getDecryptedData(response.data.data.url)
                window.location.href = s3Url;
            }
        });
    }

    render() {
        return null;
    }
}
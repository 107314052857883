import React from "react";
import { Row, Col, Button, Collapse } from "react-bootstrap";
import moment from "moment";
import Rater from "react-rater";
import _ from "lodash";

//Components
import SearchFilter from "../CustomUIComponents/ReportFilters/ReportFilters";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomTableCard from "../CustomUIComponents/CustomTableCard/CustomTableCard";

//Contants
import {
  FIRST_LEVEL_TABLE_COLUMNS,
  FIRST_LEVEL_SUM_COLUMNS,
  LIST_FIRST_REPORTS,
  ARRAY_OF_ZEROS,
  DEFAULT_SELECTION,
} from "../GeneralReports/Constants";
import { GET_DEFAULT_ZONE_SELECTION } from "../BranchList/BranchListConstants";
import {
  DEFAULT_DATE_FORMAT,
  LOAN_TYPE,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DOWNLOAD_FILE_TYPE,
  REPORTS_TAB_KEY_CONST,
  LOAN_PORTFOLIO_INVOICE_DATE_TYPE,
  LOAN_PORTFOLIO_LOAN_DATE_TYPE,
  DEFAULT_DURATION,
  MINIMART_RATING,
  INVOICE_DATE_FORMAT,
  MINIMART_GRADE,
  REPORTS_INTERNAL_TABS,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_ACTIVE_PAGE,
  DEFAULT_PAGINATION_RECORDS_COUNT,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./FirstLevelReports.scss";

//Assets
import pageDividerOpened from "../../resources/images/ic_page_divider_arrow.svg";
import pageDividerClosed from "../../resources/images/ic_page_divider_arrow_close.svg";
import CSVdownload from "../../resources/images/ic_download_csv.svg";
import PDFdownload from "../../resources/images/ic_download_pdf.svg";
import { GET_DEFAULT_MESSENGER_ZONE_SELECTION } from "../BranchList/BranchListConstants";

class FirstLevelReports extends React.Component {
  constructor(props) {
    super(props);
    this._columns = FIRST_LEVEL_TABLE_COLUMNS[this.props.user];
    this.__Sumcolumns = FIRST_LEVEL_SUM_COLUMNS[this.props.user];
    this.state = {
      user: props.user,
      isFilterDivExpanded: true,
      fromDate: props.searchFilter.fd,
      toDate: props.searchFilter.td,
      apiUrl: LIST_FIRST_REPORTS[this.props.user].url,
      sumUrl: LIST_FIRST_REPORTS[this.props.user].sumUrl,
      downLoadUrl: LIST_FIRST_REPORTS[this.props.user].downloadUrl,
      LOAN_PORTFOLIO: {
        toInvoiceDate: props.searchFilter.itd,
        fromInvoiceDate: props.searchFilter.ifd,
        fromLoanDate: props.searchFilter.fd,
        toLoanDate: props.searchFilter.td,
        branch: props.searchFilter.br,
        zone: props.searchFilter.zn,
        messengerZone: props.searchFilter.mzn,
        type: props.searchFilter.ty,
        status: "",
        fromInvoiceCount: props.searchFilter.fic,
        toInvoiceCount: props.searchFilter.tic,
        fromLoanCount: props.searchFilter.flc,
        toLoanCount: props.searchFilter.tlc,
      },
      SALES: {
        name: props.searchFilter.un,
        FromLoanAmount: "",
        toLoanAmount: "",
      },
      SPECIAL_SALES: {
        name: props.searchFilter.un,
        FromLoanAmount: "",
        toLoanAmount: "",
      },
      COLLECTIONS: {
        name: props.searchFilter.un,
        lowerAmount: props.searchFilter.la,
        upperAmount: props.searchFilter.ua,
      },
      WEB_COLLECTIONS: {
        lowerAmount: props.searchFilter.la,
        upperAmount: props.searchFilter.ua,
      },
      WALLET: {
        fromLoanDate: props.searchFilter.fd,
        toLoanDate: props.searchFilter.td,
        matricesId: props.searchFilter.mid,
        ciCode: props.searchFilter.ciid,
        minimartName: props.searchFilter.mn,
        grade: props.searchFilter.gr,
      },
      activePage: props.searchFilter.offset,
      recordsPerPage: props.searchFilter.limit,
      zones: props.zones,
      mZones: props.mZones,
      branches: props.branches,
      errorMessage: "",
      activeTab: 0
    };
  }

  componentDidMount() {
    if (this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO) {
      this.props.getZonesByBranch(this.state.LOAN_PORTFOLIO.branch);
      this.props.getBranchList();
    }
    this.handleClickSearchFilter(false);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }
  }

  handleSearchChange = (key, event) => {
    let value = ARRAY_OF_ZEROS.includes(key)
      ? parseInt(event.target.value)
      : event.target.value;
    if (DEFAULT_SELECTION.includes(value)) {
      value = "";
    }
    this.setState({
      [this.state.user]: {
        ...this.state[this.state.user],
        [key]: value,
      },
    });
  };

  handleGradeChange = (key, event) => {
    //NOSONAR
    if (event.target.value !== Strings("selectMiniMartGrade")) {
      this.setState({
        WALLET: { ...this.state.WALLET, grade: event.target.value },
      });
    } else {
      this.setState({
        WALLET: { ...this.state.WALLET, grade: "" },
      });
    }
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.fromDate)) {
          DateValue = this.state.fromDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        if (dateMoment.isAfter(new Date())) {
          DateValue = new Date();
        }
        break;
      default:
    }
    this.setState({
      [key]: DateValue,
    });
  };

  handleLoanDateChange = (key) => (dateMoment) => {
    //NOSONAR
    let DateValue = "";
    switch (key) {
      case LOAN_PORTFOLIO_LOAN_DATE_TYPE.FROM_LOANINVOICE_DATE:
        if (
          dateMoment.isAfter(this.state[this.props.user].toLoanDate)
        ) {
          DateValue = this.state.LOAN_PORTFOLIO.fromLoanDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case LOAN_PORTFOLIO_LOAN_DATE_TYPE.TO_LOAN_DATE:
        if (
          dateMoment.isBefore(
            this.state[this.props.user].fromLoanDate
          )
        ) {
          DateValue =
            moment(dateMoment).diff(
              this.state[this.props.user].fromLoanDate,
              "days"
            ) === 0
              ? this.state[this.props.user].fromLoanDate
              : this.state[this.props.user].toLoanDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case LOAN_PORTFOLIO_INVOICE_DATE_TYPE.FROM_INVOICE_DATE:
        if (
          dateMoment.isAfter(this.state.LOAN_PORTFOLIO.toInvoiceDate)
        ) {
          DateValue = this.state.LOAN_PORTFOLIO.fromInvoiceDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case LOAN_PORTFOLIO_INVOICE_DATE_TYPE.TO_INVOICE_DATE:
        if (
          dateMoment.isBefore(
            this.state.LOAN_PORTFOLIO.fromInvoiceDate
          ) &&
          this.state.LOAN_PORTFOLIO.fromInvoiceDate !== undefined
        ) {
          DateValue = this.state.LOAN_PORTFOLIO.toInvoiceDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }

        break;
      default:
    }
    this.setState({
      [this.props.user]: {
        ...this.state[this.props.user],
        [key]: new Date(DateValue),
      },
    });
  };

  handleClickSearchFilter = (isDefaultOffset) => {
    //NOSONAR
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    let search = { ...this.state[this.state.user] };
    if (this.validRange(search)) {
      Object.keys(search).forEach((key) => {
        if (search[key] === "" || search[key] === 0) {
          delete search[key];
        }
      });
      const key1 =
        this.state.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
          ? LOAN_PORTFOLIO_INVOICE_DATE_TYPE.FROM_INVOICE_DATE
          : DATE_TYPE.FROM_DATE;
      const key2 =
        this.state.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
          ? LOAN_PORTFOLIO_INVOICE_DATE_TYPE.TO_INVOICE_DATE
          : DATE_TYPE.TO_DATE;

      let pagination = {};
      if (
        this.state.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO ||
        this.state.user === REPORTS_TAB_KEY_CONST.WALLET
      ) {
        pagination = {
          limit: this.state.recordsPerPage,
          offset: isDefaultOffset
            ? DEFAULT_PAGINATION_ACTIVE_PAGE
            : this.state.activePage,
        };
      } else {
        search = {
          ...search,
          [key1]: this.state.fromDate,
          [key2]: this.state.toDate,
        };
        pagination = {
          limit: this.state.recordsPerPage,
          offset: isDefaultOffset
            ? DEFAULT_PAGINATION_ACTIVE_PAGE
            : this.state.activePage,
        };
      }
      this.props.handleSearchFilter(
        this.state.apiUrl,
        this.state.sumUrl,
        { ...search },
        { ...pagination },
      );
    }
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleClickSearchFilter(false)
    );
  };

  handleOnClickResetFilter = () => {
    let keys = Object.keys({ ...this.state[this.state.user] });
    let tempObj = {};
    keys.map((key) => {
      if (
        key === LOAN_PORTFOLIO_LOAN_DATE_TYPE.FROM_LOANINVOICE_DATE
      ) {
        tempObj[key] = DEFAULT_DURATION.fromDate;
      } else if (key === LOAN_PORTFOLIO_LOAN_DATE_TYPE.TO_LOAN_DATE) {
        tempObj[key] = moment(new Date()).format(DEFAULT_DATE_FORMAT);
      } else if (
        key === LOAN_PORTFOLIO_INVOICE_DATE_TYPE.FROM_INVOICE_DATE ||
        key === LOAN_PORTFOLIO_INVOICE_DATE_TYPE.TO_INVOICE_DATE
      ) {
        tempObj[key] = undefined;
      } else {
        tempObj[key] = "";
      }
      return tempObj;
    });
    this.setState(
      {
        fromDate: DEFAULT_DURATION.fromDate,
        toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
        [this.state.user]: { ...tempObj },
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
      () => {
        this.props.getZonesByBranch();
        this.handleClickSearchFilter(true);
      }
    );
  };

  handleBranchChange = (key, event) => {
    //NOSONAR
    let value = event.target.value;
    if (DEFAULT_SELECTION.includes(value)) {
      value = "";
    }
    this.setState(
      {
        [this.state.user]: {
          ...this.state[this.state.user],
          branch: value,
          zone: "",
        },
      },
      () => {
        this.props.getZonesByBranch(this.state.LOAN_PORTFOLIO.branch);
      }
    );
  };

  validRange = (searchObject) => {
    let key = this.state.user;
    let errorMessage = "";
    switch (key) {
      case REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO:
        if (
          searchObject.toInvoiceCount > 0 &&
          searchObject.fromInvoiceCount > searchObject.toInvoiceCount
        ) {
          errorMessage = Strings("FromCountMustBeLess");
        }
        if (
          searchObject.toLoanCount > 0 &&
          searchObject.fromLoanCount > searchObject.toLoanCount
        ) {
          errorMessage = Strings("FromCountMustBeLess");
        }
        break;
      case REPORTS_TAB_KEY_CONST.SALES:
        if (
          searchObject.toLoanAmount > 0 &&
          searchObject.FromLoanAmount > searchObject.toLoanAmount
        ) {
          errorMessage = Strings("FromAmountMustBeLess");
        }

        break;
      case REPORTS_TAB_KEY_CONST.COLLECTIONS:
        if (
          searchObject.upperAmount > 0 &&
          searchObject.lowerAmount > searchObject.upperAmount
        ) {
          errorMessage = Strings("FromAmountMustBeLess");
        }
        break;
      case REPORTS_TAB_KEY_CONST.WEB_COLLECTIONS:
        if (
          searchObject.upperAmount > 0 &&
          searchObject.lowerAmount > searchObject.upperAmount
        ) {
          errorMessage = Strings("FromAmountMustBeLess");
        }
        break;

      default:
        break;
    }
    this.setState({ errorMessage: errorMessage });
    return errorMessage === "";
  };

  toggleCollpasibleView = () => {
    this.setState({
      isFilterDivExpanded: !this.state.isFilterDivExpanded,
    });
  };

  downloadFile = (filetype) => {
    //NOSONAR
    let searchObject = this.state[this.state.user];
    if (this.validRange(searchObject)) {
      Object.keys(searchObject).forEach((key) => {
        if (searchObject[key] === "" || searchObject[key] === 0) {
          delete searchObject[key];
        }
      });
      let subTab =
        this.state.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO ||
        this.state.user === REPORTS_TAB_KEY_CONST.WALLET;
      const key1 =
        this.state.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
          ? LOAN_PORTFOLIO_INVOICE_DATE_TYPE.FROM_INVOICE_DATE
          : DATE_TYPE.FROM_DATE;
      const key2 =
        this.state.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
          ? LOAN_PORTFOLIO_INVOICE_DATE_TYPE.TO_INVOICE_DATE
          : DATE_TYPE.TO_DATE;
      let searchFilterObject = {};
      if (subTab) {
        searchFilterObject = {
          search: {
            ...searchObject,
            file: filetype,
          },
        };
      } else {
        searchFilterObject = {
          search: {
            ...searchObject,
            [key1]: this.state.fromDate,
            [key2]: this.state.toDate,
            file: filetype,
          },
        };
      }

      this.props.downloadReports(
        this.state.downLoadUrl,
        searchFilterObject,
        moment(
          subTab ? searchObject.toLoanDate : this.state.toDate
        ).diff(
          subTab ? searchObject.fromLoanDate : this.state.fromDate,
          "days"
        ) <= this.props.maxDays
      );
    }
  };

  renderDownloadButtons = () => {
    return (
      <React.Fragment>
        {" "}
        <div>
          <Button
            className='downloadButtons mr-3'
            onClick={() => {
              this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
            }}
          >
            <img src={PDFdownload} alt='' />
          </Button>
          <Button
            className='downloadButtons'
            onClick={() => {
              this.downloadFile(DOWNLOAD_FILE_TYPE.CSV);
            }}
          >
            <img src={CSVdownload} alt='' />
          </Button>
        </div>
      </React.Fragment>
    );
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "pRat":
        return (
          <Rater
            title={row[key]}
            rating={row[key]}
            total={5}
            interactive={false}
          />
        );
      case "ts":
        return moment(row[key]).format(INVOICE_DATE_FORMAT);

      case "mRat":
        return row[key] ? MINIMART_RATING[row[key]] : "--";

      default:
        return row[key];
    }
  };

  renderCustomDataGrid = (columns, rows, isSummary, callBack) => {
    return (
      <div
        className={
          isSummary ||
            this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
            ? "mx-5 w-75"
            : isSummary || 
              this.props.user === REPORTS_TAB_KEY_CONST.WEB_COLLECTIONS
              ? "mx-auto w-50"
              : "mx-5 "
        }
      >
        <CustomTableCard
          rows={rows}
          columns={columns}
          isSummary={isSummary}
          callBack={callBack}
        />
      </div>
    );
  };

  renderDataGrid = () => {
    return (
      <Collapse in={this.state.isFilterDivExpanded}>
        <div className='branchListTable noPadding d-flex justify-content-center'>
          {this.state.isFilterDivExpanded &&
            this.renderCustomDataGrid(
              this.__Sumcolumns,
              this.props.sumRows,
              true
            )}
        </div>
      </Collapse>
    );
  };

  renderSearchFilter = () => {

    let zones =
      this.state.zones?.map((singleZone) => {
        return singleZone.name === "Zona"
          ? {
            label: GET_DEFAULT_ZONE_SELECTION().name,
            name: GET_DEFAULT_ZONE_SELECTION().name,
          }
          : {
            label: singleZone.name,
            name: singleZone.name,
          };
      });
    let mZones =
      this.state.mZones?.map((singleZone) => {
        return singleZone.name === Strings("mzone")
          ? {
            label: GET_DEFAULT_MESSENGER_ZONE_SELECTION().name,
            name: GET_DEFAULT_MESSENGER_ZONE_SELECTION().name,
          }
          : {
            label: singleZone.name,
            name: singleZone.name,
          };
      });
    let FirstLevelReportsSearchFilters = {
      LOAN_PORTFOLIO: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.LOAN_PORTFOLIO.fromInvoiceDate,
          searchPlaceHolder: Strings("InvoiceDate"),
          handleCalenderChange: this.handleLoanDateChange,
          toDate: this.state.LOAN_PORTFOLIO.toInvoiceDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
          fromDateKey:
            LOAN_PORTFOLIO_INVOICE_DATE_TYPE.FROM_INVOICE_DATE,
          toDateKey: LOAN_PORTFOLIO_INVOICE_DATE_TYPE.TO_INVOICE_DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.LOAN_PORTFOLIO.fromLoanDate,
          fromDateKey:
            LOAN_PORTFOLIO_LOAN_DATE_TYPE.FROM_LOANINVOICE_DATE,
          toDateKey: LOAN_PORTFOLIO_LOAN_DATE_TYPE.TO_LOAN_DATE,
          searchPlaceHolder: Strings("LoanDate"),
          handleCalenderChange: this.handleLoanDateChange,
          toDate: this.state.LOAN_PORTFOLIO.toLoanDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.DOUBLE_INPUT,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          searchPlaceHolder: Strings("loanCount"),
          handleOnChange: this.handleSearchChange,
          Value1: this.state.LOAN_PORTFOLIO.fromLoanCount,
          key: "fromLoanCount",
          searchPlaceHolder1: Strings("fromLoanCount"),
          searchPlaceHolder2: Strings("toLoanCount"),
          Value2: this.state.LOAN_PORTFOLIO.toLoanCount,
          key1: "toLoanCount",
        },
        {
          type: SEARCH_FILTER_TYPE.DOUBLE_INPUT,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          searchPlaceHolder: Strings("InvoiceCount"),
          handleOnChange: this.handleSearchChange,
          Value1: this.state.LOAN_PORTFOLIO.fromInvoiceCount,
          key: "fromInvoiceCount",
          searchPlaceHolder1: Strings("fromInvoiceCount"),
          searchPlaceHolder2: Strings("toInvoiceCount"),
          Value2: this.state.LOAN_PORTFOLIO.toInvoiceCount,
          key1: "toInvoiceCount",
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("Branch"),
          handleOnChange: this.handleBranchChange,
          Value: this.state.LOAN_PORTFOLIO.branch,
          key: "branch",
          filterDropdownList: this.state.branches,
        },
        {
          type: "selection",
          searchPlaceHolder: Strings("Zone"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.zone,
          key: "zone",
          name: "zone",
          filterDropdownList: zones,
        },
        {
          type: "selection",
          searchPlaceHolder: Strings("mzone"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.messengerZone,
          key: "messengerZone",
          name: "messengerZone",
          filterDropdownList: mZones,
        },

        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("type"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.LOAN_PORTFOLIO.type,
          key: "type",
          filterDropdownList: LOAN_TYPE,
        },
      ],
      SALES: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          searchPlaceHolder: Strings("Date"),
          fromDate: this.state.fromDate,
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("SALES_PERSON"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.SALES.name,
          key: "name",
        },
      ],
      SPECIAL_SALES: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          searchPlaceHolder: Strings("Date"),
          fromDate: this.state.fromDate,
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("SALES_PERSON"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.SPECIAL_SALES.name,
          key: "name",
        },
      ],
      COLLECTIONS: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.fromDate,
          searchPlaceHolder: Strings("Date"),
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("Messenger"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.COLLECTIONS.name,
          key: "name",
        },
        {
          type: SEARCH_FILTER_TYPE.DOUBLE_INPUT,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          searchPlaceHolder: Strings("collectedAmount") + " ($)",
          handleOnChange: this.handleSearchChange,
          Value1: this.state.COLLECTIONS.lowerAmount,
          key: "lowerAmount",
          searchPlaceHolder1: Strings("lowerAmount"),
          searchPlaceHolder2: Strings("upperAmount"),
          Value2: this.state.COLLECTIONS.upperAmount,
          key1: "upperAmount",
        },
      ],
      WEB_COLLECTIONS: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.fromDate,
          searchPlaceHolder: Strings("Date"),
          handleCalenderChange: this.handleDateChange,
          toDate: this.state.toDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.DOUBLE_INPUT,
          inputType: SEARCH_FILTER_TYPE.NUMBER,
          searchPlaceHolder: Strings("collectedAmount") + " ($)",
          handleOnChange: this.handleSearchChange,
          Value1: this.state.WEB_COLLECTIONS.lowerAmount,
          key: "lowerAmount",
          searchPlaceHolder1: Strings("lowerAmount"),
          searchPlaceHolder2: Strings("upperAmount"),
          Value2: this.state.WEB_COLLECTIONS.upperAmount,
          key1: "upperAmount",
        },
      ],
      WALLET: [
        {
          type: SEARCH_FILTER_TYPE.DATE,
          fromDate: this.state.WALLET.fromLoanDate,
          fromDateKey:
            LOAN_PORTFOLIO_LOAN_DATE_TYPE.FROM_LOANINVOICE_DATE,
          toDateKey: LOAN_PORTFOLIO_LOAN_DATE_TYPE.TO_LOAN_DATE,
          searchPlaceHolder: Strings("LoanDate"),
          handleCalenderChange: this.handleLoanDateChange,
          toDate: this.state.WALLET.toLoanDate,
          fieldname: SEARCH_FILTER_FIELDS.DATE,
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("MatricesID"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.WALLET.matricesId,
          key: "matricesId",
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("CIID"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.WALLET.ciCode,
          key: "ciCode",
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: Strings("minimartName"),
          handleOnChange: this.handleSearchChange,
          Value: this.state.WALLET.minimartName,
          key: "minimartName",
        },
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          searchPlaceHolder: Strings("MinimartGrade"),
          handleOnChange: this.handleGradeChange,
          Value: this.state.WALLET.grade,
          fieldname: SEARCH_FILTER_FIELDS.GRADE,
          filterDropdownList: MINIMART_GRADE,
          key: "grade",
        },
      ],
    };
    return (
      <SearchFilter
        filters={FirstLevelReportsSearchFilters[this.props.user]}
        handleOnClickSearchFilter={this.handleClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={true}
        addButton={true}
        buttonText={Strings("Descargar")}
        handleAddUser={this.downloadReports}
        buttonWidth={
          FirstLevelReportsSearchFilters[
          `${this.props.user}_buttonWidth`
          ]
        }
      />
    );
  };

  renderDetailReportDataGrid = () => {
    return (
      <>
        <Row className='pl-3 heading'>{Strings("ReportDetails")}</Row>
        <div
          className={
            this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
              ? "branchListTable noPadding d-flex justify-content-center"
              : "branchListTable noPadding"
          }
        >
          {this.renderCustomDataGrid(
            this._columns,
            this.props.rows,
            false,
            this.props.onRowClick
          )}
        </div>
        <Col md={12} className='noPadding'>
          <Pagination
            activePage={this.state.activePage}
            recordsPerPage={this.state.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.onPageChange}
            dropup={true}
          />
        </Col>
      </>
    );
  };

  selectedTabClass = () => {
    if (this.state.activeTab === 0) {
      return "selectedButtonLeft";
    } else if (this.state.activeTab === REPORTS_INTERNAL_TABS[this.props.user].length - 1) {
      return "selectedButtonRight";
    }
  };

  unSelectedTabClass = () => {
    if (this.state.activeTab === 0) {
      return "unselectedButtonRight";
    } else if (this.state.activeTab === REPORTS_INTERNAL_TABS[this.props.user].length - 1) {
      return "unSelectedButtonLeft";
    }
  }

  onClicOfTab = (tabIndex) => {
    this.setState({
      activeTab: tabIndex,
    }, () => {
      this.state.activeTab === 0
        ? this.props.goToAllLoanReports(
          this.props.user ===
          REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
        )
        : this.props.goToSingleLoanReports(
          this.props.user ===
          REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
        )
    })
  }

  renderTabsAndDownloadButtonsRow = () => {
    return (
      <Row className='pt-2 alignItemCenter'>
        <Col md={4}>
          {REPORTS_INTERNAL_TABS[this.props.user].map((singleTab, tabIndex) => {
            return <Button
              key={singleTab}
              className={tabIndex === this.state.activeTab
                ? this.selectedTabClass()
                : this.unSelectedTabClass()}
              onClick={() => this.onClicOfTab(tabIndex)}
            >
              {Strings(singleTab)}
            </Button>
          })}
        </Col>
        <Col
          md={{ span: 2, offset: 6 }}
          className='pull-right alignDownloadButtons'
        >
          {this.renderDownloadButtons()}
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div>
        {this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO ||
          this.props.user === REPORTS_TAB_KEY_CONST.WALLET ? (
          this.renderTabsAndDownloadButtonsRow()
        ) : (
          <Row className='pt-2 alignItemCenter'>
            <Col
              md={{ span: 2, offset: 10 }}
              className='pull-right alignDownloadButtons'
            >
              {this.renderDownloadButtons()}
            </Col>
          </Row>
        )
        }
        {
          this.state.errorMessage !== "" && (
            <Row className='errors pl-3'>
              {this.state.errorMessage !== "" &&
                Strings(this.state.errorMessage)}
            </Row>
          )
        }
        {this.renderSearchFilter()}
        {
          this.props.errorMessage !== "" && (
            <Row className='errors pl-3'>
              {this.props.errorMessage !== ""
                ? Strings(this.props.errorMessage)
                : ""}
            </Row>
          )
        }
        {
          this.props.sumRows.length !== 0 && (
            <div>
              <Row className='pl-3 heading'>{Strings("Summary")}</Row>
              {this.renderDataGrid()}
              <Row className='collapseContainer mt-2'>
                <img
                  src={
                    this.state.isFilterDivExpanded
                      ? pageDividerOpened
                      : pageDividerClosed
                  }
                  alt='img'
                  className='collapseIcon pull-right'
                  onClick={this.toggleCollpasibleView}
                />
                <hr className='newCollapseHr' />
              </Row>
            </div>
          )
        }
        {
          this.props.rowsCount !== 0 ? (
            this.renderDetailReportDataGrid()
          ) : (
            <div className='noRecordsFound'>
              {Strings("NoRecordsFound")}
            </div>
          )
        }
      </div >
    );
  }
}

export default FirstLevelReports;

//Libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fileDownload from "js-file-download";
import { Row } from "react-bootstrap";

//Components
import EfficiencyDashboard from "./EfficiencyDashboard";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import withHOC from "../../HOC/HOC";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { Strings } from "../../resources/i18n/i18n";
import {
  SEARCH_FILTER_PAGES,
  SPED_L1_FILTER,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";
import { SALESPERSON_EFFICIENCY_SUMMARY_COLUMNS } from "./constants";

//Actions
import {
  dowloadedAction,
  setSearchFilterAction,
} from "../../constants/Action";

import {
  getSalespersonEfficiencyPiechartDataAction,
  getSalespersonEfficiencyTableDataAction,
  setSalesPersonFiltersAction,
  setSalesPersonDataAction,
} from "./EfficiencyDashboardActions";
import { getUserProfilePicUrlAction } from "../ProfileDetails/ProfileDetailsActions";

//Utilities
import Utility from "../../utils/Utility";
//Stylesheets

class EfficiencyDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.APICount = 0;
    this.state = {
      isError: false,
      errorMessage: props.errorMessage,
      branchId: "",
      pieChartStats: {},
      rows: [],
      showLoader: false,
    };
    this.isCardClick = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(SEARCH_FILTER_PAGES.SPED_L1, {
        ...SPED_L1_FILTER,
      });
    }
  }

  // #region filter fields
  handleOnClickSearchFilter = (reqObj) => {
    this.getTableData(reqObj);
    this.getPiechartData(reqObj);
  };

  // #endregion

  // #region visual representation
  getPiechartData = (searchFilterObject) => {
    this.APICount += 1;
    this.setState({ showLoader: true });
    getSalespersonEfficiencyPiechartDataAction(
      searchFilterObject,
      (responseData) => {
        this.APICount -= 1;
        if (responseData.status === OPERATION_STATUS.SUCCESS) {
          let pieChartStats = responseData.data.data;
          pieChartStats.tobeVisited =
            pieChartStats.totalAssignedMinimarts -
            pieChartStats.visitedMinimarts;
          this.setState({
            pieChartStats: pieChartStats,
            errorMessage: "",
            showLoader: this.APICount !== 0,
          });
        } else {
          this.setState({
            errorMessage: responseData.error.message,
            showLoader: this.APICount !== 0,
          });
        }
      }
    );
  };

  // #endregion

  // #region table data
  getTableData = (searchFilterObject) => {
    this.APICount += 1;
    this.setState({
      showLoader: true,
      searchFilter: {
        fd: searchFilterObject.fromDate,
        td: searchFilterObject.toDate,
        un: searchFilterObject.name,
        br: searchFilterObject.branchId,
        gr: searchFilterObject.grade,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    getSalespersonEfficiencyTableDataAction(
      searchFilterObject,
      (responseData) => {
        this.APICount -= 1;
        if (responseData.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            numberOfPages: responseData.data.data.numberOfPages,
            rows: responseData.data.data.salesPersonEfficiencyInfo,
            errorMessage: "",
            showLoader: this.APICount !== 0,
          });
        } else {
          this.setState({
            errorMessage: responseData.error.message,
            rows: [],
          });
        }
      }
    );
  };

  callbackOnRowClick = (index, row, filter) => {
    if (row) {
      this.isCardClick = true;
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SPED_L1,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.SPED_L1
      );
      this.props.setSalesPersonFiltersAction(filter);
      this.props.setSalesPersonDataAction(this.state.rows[index]);
      this.props.history.push(ROUTES.SINGLE_SALES_PERSON);
    }
  };

  handleDownloadFile = (url, fileData, type) => {
    this.setState({ showLoader: true, isError: false });
    fileData.search.limit > 0
      ? dowloadedAction(
          url,
          fileData,
          (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
              if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
                fileDownload(
                  response.data,
                  Strings("SalespersonEfficicencyReport") +
                    Utility.getReportFormattedDateString(
                      Utility.convertDateIntoMiliSeconds(new Date())
                    ) +
                    Utility.getFileType(type)
                );
                this.setState({
                  errorMessage: "",
                  showLoader: false,
                  isError: false,
                });
              } else {
                this.setState({
                  showLoader: false,
                  isError: true,
                  errorMessage: Strings("NoRecordsFound"),
                });
              }
            } else {
              this.setState({
                showLoader: false,
                errorMessage: response.error.message,
              });
            }
          },
          "SalespersonEfficiencySummary"
        )
      : this.setState({
          showLoader: false,
          isError: true,
          errorMessage: Strings("NoData"),
        });
  };

  // #endregion

  render() {
    return (
      <div>
        <Row className='heading'>
          {Strings("SalespersonEfficiencyDashboard")}
        </Row>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isError && (
          <UserNotification
            key='Error'
            userNotificationObj={{
              message: Strings(this.state.errorMessage),
              level: "error",
            }}
          />
        )}
        <EfficiencyDashboard
          branches={this.props.branches}
          pieChartStats={this.state.pieChartStats}
          salesPersonInfos={this.state.salesPersonInfos}
          getPiechartData={this.getPiechartData}
          numberOfPages={this.state.numberOfPages}
          columns={SALESPERSON_EFFICIENCY_SUMMARY_COLUMNS}
          rowsCount={this.state.rows?.length || 0}
          onRowClick={this.callbackOnRowClick}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleDownloadFile={this.handleDownloadFile}
          searchFilter={this.props.spedL1SearchFilter}
          getBranchList={this.props.getBranchList}
          rows={this.state.rows}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSalesPersonFiltersAction,
      setSalesPersonDataAction,
      getUserProfilePicUrlAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

const EfficiencyDashboardWrapper = withHOC(
  EfficiencyDashboardContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EfficiencyDashboardWrapper);

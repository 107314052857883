//libraries
import React, { Component } from "react";
//components
import Products from "./Products.js";
import { Row } from "react-bootstrap";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
//actions
import { getProductListAction } from "./ProductsActions";

//constants
import { Strings } from "../../resources/i18n/i18n";
import { OPERATION_STATUS } from "../../config/axios.init";

//utilities NOSONAR
//stylesheets NOSONAR

class ProductsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      errorMessage: "",
      toShowToast: false,
      level: null,
      toastMessage: "",
      allProductsList: [],
      showLoadingModal: false,
    };
  }

  componentDidMount() {
    this.getProductList({}, true);
  }

  getProductList = (reqObj, componentMountCall = false) => {
    this.setState({
      showLoadingModal: true,
    });
    if (reqObj.pn === "") {
      reqObj.pn = null;
    }
    getProductListAction(reqObj, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (componentMountCall) {
          let allProductsList = [
            { id: 0, value: "", label: Strings("ProductName") },
          ];
          //set filter dropdown list
          let data = response.data.data.rp;
          data.forEach((product) => {
            allProductsList.push({
              id: product.id,
              value: product.pn,
              label: Strings(product.pn),
            });
          });
          this.setState({
            allProductsList,
            productList: response.data.data.rp,
            errorMessage: "",
            showLoadingModal: false,
          });
        } else
          this.setState({
            productList: response.data.data.rp,
            errorMessage: "",
            showLoadingModal: false,
          });
      } else {
        this.setState({
          errorMessage: response.error.errorMessage,
          toShowToast: true,
          level: "error",
          toastMessage: Strings("SorryAnErrorHasOccurred"),
          showLoadingModal: false,
        });
      }
    });
  };

  /* This code is currently inaccessible as edit feature is on hold
  updateProduct = (reqObj) => {
    updateProductAction(reqObj, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          toShowToast: true,
          level: "success",
          toastMessage: Strings("DataUpdatedSuccessfully"),
        });
        this.getProductList({});
      } else {
        this.setState({
          errorMessage: response.error.errorMessage,
          toShowToast: true,
          level: "error",
          toastMessage: Strings("SorryAnErrorHasOccurred"),
        });
      }
    });
  };*/

  handleOnClickSearchFilter = (reqObj) => {
    this.getProductList(reqObj);
  };

  render() {
    return (
      <>
        {/* spinner */}
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        <Row className='heading'>{Strings("Products")}</Row>
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.toastMessage}
            userNotificationObj={{
              message: this.state.toastMessage,
              level: this.state.level,
            }}
          />
        )}
        <Products
          productList={this.state.productList}
          errorMessage={this.state.errorMessage}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          updateProduct={this.updateProduct}
          allProductsList={this.state.allProductsList}
        />
      </>
    );
  }
}

export default ProductsContainer;

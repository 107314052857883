//Libraries
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { withRouter } from "react-router-dom";

//Components
import WorkHistory from "./WorkHistory";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

//Constants
import {
  LIST_WORK_HISTORY,
  SPECIAL_MESSENGER_PRESENTATION,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  SPECIAL,
  SEARCH_FILTER_PAGES,
  WORK_HISTORY_FILTER,
} from "../../constants/appConstants";
import { OPERATION_STATUS } from "../../config/axios.init";

//Actions
import {
  setUserIdAction,
  getWorkHistoryDataAction,
} from "../WorkHistory/WorkHistoryAction";
import { setSearchFilterAction } from "../../constants/Action";
//Utilities
import Utility from "../../utils/Utility";

//Stylesheet
import "./WorkHistory.scss";

class WorkHistoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keysArray: LIST_WORK_HISTORY[this.props.user].keysArray,
      apiUrl: LIST_WORK_HISTORY[this.props.user].url,
      rows: [],
      numberOfPages: 1,
      errorMessage: "",
      showLoader: false,
    };
    this.isRowClick = false;
  }

  componentWillUnmount() {
    if (!this.isRowClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.WORK_HISTORY,
        {
          ...WORK_HISTORY_FILTER,
        }
      );
    }
  }

  handleSearchFilter = (requestUrl, searchFilterObject) => {
    this.setState({
      showLoader: true,
      searchFilters: {
        userName: searchFilterObject.name,
        fromDate: searchFilterObject.fromDate,
        toDate: searchFilterObject.toDate,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    getWorkHistoryDataAction(
      requestUrl,
      searchFilterObject,
      (responsedata) => {
        if (responsedata.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            numberOfPages: responsedata.data.data.np,
            showLoader: false,
            errorMessage: "",
          });
          this.createRows(responsedata.data.data.wh);
        } else {
          this.setState({
            errorMessage: responsedata.error.message,
            showLoader: false,
          });
        }
      }
    );
  };

  createRows = (responseData) => {
    //Row create for each element in response data array
    // Use constant instead of hardcoded values.
    let rows = [];

    _.forEach(responseData, (historyDetails) => {
      let tempObj = {};
      this.state.keysArray.forEach((key) => {
        if (Array.isArray(key) && key.length > 0) {
          tempObj[key[0]] = historyDetails[key[0]];
          if (key[2].isAmount) {
            tempObj[key[0]] =
              Utility.getCurrencyRepresentationOfAmount(
                historyDetails[key[0]]
              ) +
              " / " +
              Utility.getCurrencyRepresentationOfAmount(
                historyDetails[key[1]]
              );
          } else if (historyDetails[key[1]] === SPECIAL) {
            tempObj[key[0]] = (
              <div
                title={
                  historyDetails[key[0]] +
                  "\n" +
                  SPECIAL_MESSENGER_PRESENTATION
                }
              >
                {SHORT_SPECIAL_MESSENGER_PRESENTATION +
                  " " +
                  historyDetails[key[0]]}
              </div>
            );
          }
        } else if (key === "userPreservedData") {
          tempObj[key] = historyDetails;
        } else if (key === "ple" || key === "crat") {
          tempObj[key] = Utility.getDecimalNumberUptoDigit(
            historyDetails[key],
            2
          );
        } else {
          tempObj[key] = historyDetails[key];
        }
      });
      rows.push(tempObj);
    });
    this.setState({ rows: rows });
  };

  callbackOnRowClick = (row) => {
    if (row !== undefined) {
      this.isRowClick = true;
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.WORK_HISTORY,
        {
          ...this.state.searchFilters,
          selectedUserName: row.userPreservedData.un,
        },
        SEARCH_FILTER_PAGES.WORK_HISTORY
      );
      this.props.setUserIdAction(row.userPreservedData.id);
      this.props.callbackOnRowClick(row);
    }
  };

  render() {
    return (
      <div className='workHistory'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <WorkHistory
          user={this.props.user}
          rows={this.state.rows}
          numberOfPages={this.state.numberOfPages}
          onRowClick={this.callbackOnRowClick}
          columns={this._columns}
          rowsCount={this.state.rows.length}
          rowHeight={60}
          headerRowHeight={
            this.props.user === "SALES_PERSON" ? 80 : 55
          }
          handleSearchFilter={this.handleSearchFilter}
          errorMessage={this.state.errorMessage}
          searchFilters={this.props.searchFilters}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  branches: state.branchListReducer.branchDetails.branches,
  salesPerson: state.DailyStatusReducer.salesPerson,
  messenger: state.DailyStatusReducer.messenger,
  searchFilters: state.searchFilterReducer.workHistorySearchFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUserIdAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WorkHistoryContainer));

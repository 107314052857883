import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

//Custom Components
import CustomToolTip from "./CustomToolTip";

// Utils
import Utility from "../../../utils/Utility";

//Styles
import "./BarGraphComponent.scss";

class BarGraphComponent extends React.Component {
  renderColorfulLegendText(value, entry) {
    const { color } = entry;
    return <span style={{ color }}>{value}</span>;
  }
  render() {
    return (
      <div
        className={
          this.props.height
            ? "barGraphComponent completeHeight"
            : "barGraphComponent"
        }
      >
        <ResponsiveContainer>
          <BarChart
            width={100}
            height={this.props.height ? this.props.height : 100}
            data={this.props.data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='10 10' />
            <XAxis dataKey={"month"} interval={0} minTickGap={1} />
            <YAxis
              dataKey={"count"}
              tickFormatter={Utility.numberConverter}
            ></YAxis>
            <Tooltip content={<CustomToolTip />} />
            <Bar
              dataKey='count'
              stackId='a'
              fill={this.props.colorOfBar}
              barSize={15}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default BarGraphComponent;

import React from "react";
import { Row, Col, Button } from "react-bootstrap";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./ChangePassword.scss";

// Components
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      oldPassword: "",
      errorMessage: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
    };
  }

  /**
   * Set username in state
   */
  onChangeOldPassword = (event) => {
    this.setState({
      oldPassword: event.target.value,
      errorMessage: "",
    });
  };

  /**
   * Set password in state
   */
  onChangePassword = (event) => {
    this.setState({
      password: event.target.value,
      errorMessage: "",
    });
  };

  /**
   * Set confirm password in state
   */
  onChangeConfirmPassword = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      errorMessage: "",
    });
  };

  /**
   * Validate password values
   */
  validatePasswordDetails = () => {
    let error = true;
    let errorMessage = "";
    if (
      !this.state.password ||
      !this.state.oldPassword ||
      !this.state.confirmPassword
    ) {
      error = false;
      errorMessage = Strings("EmptyFieldsError");
    } else if (this.state.password !== this.state.confirmPassword) {
      error = false;
      errorMessage = Strings(
        "ErrorNewPasswordAndConfirmPasswordAreNotSame"
      );
    } else if (this.state.password === this.state.oldPassword) {
      error = false;
      errorMessage = Strings("oldPasswordSameAsNew");
    }
    this.setState({
      errorMessage: errorMessage,
    });
    return error;
  };

  /**
   *Handle Submit by passing username and password
   */
  handlePasswordChange = () => {
    if (this.validatePasswordDetails()) {
      this.props.handleChangePasswordSubmit(
        this.state.oldPassword,
        this.state.password
      );
    }
  };
  handleCancelClick = () => {
    this.props.goToPreviousPage();
  };


  showOrHidePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  render() {
    return (
      <Row className='changePassword'>
        <Col md={5} className='changePasswordPanel'>
          <Row className='changePasswordTitle'>
            {Strings("ChangePassword")}
          </Row>
          <Row noGutters className='mainPanel'>
            <div className='errors heightMaxContent'>
              {this.state.errorMessage === ""
                ? this.props.errorMessage
                : this.state.errorMessage}
            </div>
            <Col md={12}>
              <TextFieldComponent
                id='oldPassword'
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder={Strings("OldPassword")}
                handleChange={this.onChangeOldPassword}
                showPasswordIcon={true}
                showOrHidePassword={this.showOrHidePassword}
              />
            </Col>
            <Col md={12}>
              <TextFieldComponent
                id='newPassword'
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder={Strings("NewPassword")}
                handleChange={this.onChangePassword}
                showPasswordIcon={true}
                showOrHidePassword={this.showOrHidePassword}
              />
            </Col>
            <Col md={12}>
              <TextFieldComponent
                id='confirmNewPassword'
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder={Strings("ConfirmPassword")}
                handleChange={this.onChangeConfirmPassword}
                showPasswordIcon={true}
                showOrHidePassword={this.showOrHidePassword}
              />
            </Col>
          </Row>
          {/* <hr className="horizontalLine" /> */}
          <div>
            <Button
              className='loginButton buttonBackground pull-right'
              onClick={this.handlePasswordChange}
            >
              {Strings("Submit")}
            </Button>
            <Button
              className='buttonBorder pull-right extraMarginRight'
              onClick={this.handleCancelClick}
            >
              {Strings("Cancel")}
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ChangePassword;

import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import { SET_PROMOTION } from "../../redux/constants";

export const getPromotionInterestListAction = (request, callback) => {
  request.mid = request.mid === "" ? null : request.mid;
  request.cid = request.cid === "" ? null : request.cid;
  request.mn = request.mn === "" ? null : request.mn;
  request.prty = request.prty === "" ? null : request.prty;
  request.prlt = request.prlt === "" ? null : request.prlt;
  request.prbty = request.prbty === "" ? null : request.prbty;
  request.st = request.st === "" ? null : request.st;
  request.oft -= 1;
  callAPI(
    "geMTPPromotionInterest",
    API.geMTPPromotionInterest,
    "POST",
    { ...request },
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setPromotionAction = (promotion) => (dispatch) => {
  dispatch({
    type: SET_PROMOTION,
    data: promotion,
  });
};

export const getUserByBranch = (request, callback) => {
  callAPI(
    "getUserByBranch",
    API.getUserByBranch.concat(`?rl=${request.role}`),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

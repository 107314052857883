import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

// Components
import BranchList from "./BranchList";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

// Actions
import {
  getBranchBySearchFilterAction,
  getBranchDetailsAction,
  setBranchDetailsAction,
  clearBranchDetailsAction,
  getAllZonesAction,
  getZonesUnderSelectedBranchAction,
} from "./BranchListAction";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import { setSearchFilterAction } from "../../constants/Action";

// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import {
  GET_BRANCH_TABLE_COLUMNS,
  DEFAULT_ZONE,
  GET_DEFAULT_ZONE_SELECTION,
  DEFAULT_MESSENGER_ZONE,
  GET_DEFAULT_MESSENGER_ZONE_SELECTION,
} from "./BranchListConstants";
import {
  SEARCH_FILTER_PAGES,
  BRANCH_LIST_FILTER,
} from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./BranchList.scss";

class BranchListContainer extends React.Component {
  constructor(props) {
    super(props);
    //Columns are created with specific names and key
    this._columns = GET_BRANCH_TABLE_COLUMNS();
    //Initially no rows are presents
    this.state = {
      rows: [],
      branchResponseData: null,
      showLoader: false,
      errorMessage: "",
      showNotification: false,
      zones: [],
      mZones: [],
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.props.clearDailyStatusDateAction();
    this.getZones();
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.BRANCH_LIST,
        { ...BRANCH_LIST_FILTER }
      );
    }
  }

  static getDerivedStateFromProps(state, props) {
    if (
      state.zones &&
      // state.zones.length > 0 &&
      state.zones[0].name !== DEFAULT_ZONE
    ) {
      let zonesDropdown = props.zones;
      zonesDropdown.unshift(GET_DEFAULT_ZONE_SELECTION());
      return { zones: zonesDropdown };
    }
    if (
      state.mZones &&
      // state.zones.length > 0 &&
      state.mZones[0].name !== DEFAULT_MESSENGER_ZONE
    ) {
      let zonesDropdown = props.mZones;
      zonesDropdown.unshift(GET_DEFAULT_MESSENGER_ZONE_SELECTION());
      return { zones: zonesDropdown };
    }
    return null;
  }

  initErrorStates = () => {
    this.setState({ errorMessage: "" });
  };

  /**
   * This function will call list API.
   */

  getBranchList = () => {
    this.setState({ showLoader: true });
    this.props.getBranchDetailsAction((response) => {
      this.setState({ showLoader: false });
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.initErrorStates();
        this.setState({
          numberOfPages: response.data.data.numberOfPages,
        });
        this.createRows(response.data.data.branches);
      } else {
        this.setState({
          errorMessage: response.error.message,
        });
      }
    }, true);
  };

  getZones = (branchId) => {
    this.setState({ showLoader: true });
    this.props.getZonesUnderSelectedBranchAction(
      branchId,
      (Response) => {
        let responseData = Response.data.data;
        let zones = [];
        let mZones = [];

        if (responseData) {
          for (let i = 0; i < responseData.length; i++) {
            for (let j = 0; j < responseData[i].zones.length; j++) {
              let temp = responseData[i].zones[j];

              zones.push(temp);
            }
          }
          for (let i = 0; i < responseData.length; i++) {
            for (let j = 0; j < responseData[i].mzones.length; j++) {
              let temp = responseData[i].mzones[j];

              mZones.push(temp);
            }
          }
        }
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            errorMessage: "",
            zones: zones,
            mZones: mZones,
            showLoader: false,
          });
        } else {
          this.setState({
            showLoader: false,
            errorMessage: Response.error.message,
          });
        }
      }
    );
  };

  /**
   * This function will be called when user(admin) clicks on search/filter/reset
   * if search and filter are empty, it will call list API else search filter API
   */
  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        brnm: searchFilterObject.searchBranchName,
        br: searchFilterObject.active,
        zn: searchFilterObject.zone,
        mzn: searchFilterObject.messengerZone,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });

    this.props.getBranchBySearchFilterAction(
      searchFilterObject,
      (response) => {
        this.setState({ showLoader: false });
        if (response.status === OPERATION_STATUS.SUCCESS) {
          this.initErrorStates();
          if (
            response.data.data &&
            response.data.data.branches.length === 0
          ) {
            this.setState({
              rows: [],
            });
          } else {
            this.setState({
              numberOfPages: response.data.data.numberOfPages,
            });
            this.createRows(response.data.data.branches);
          }
        } else {
          this.setState({
            errorMessage: response.error.message,
          });
        }
      },
      true
    );
  };

  handleStatus = (status) => {
    if (status) {
      return Strings("Active");
    } else {
      return Strings("Inactive");
    }
  };

  createRows = (responseData) => {
    //Row create for each element in response data array
    let rows = [],
      branchData = responseData;

    _.forEach(branchData, (branchDetails) => {
      let status = this.handleStatus(branchDetails.active);
      rows.push({
        id: branchDetails.id,
        name: branchDetails.name,
        address: branchDetails.address,
        phone: branchDetails.phone,
        active: status,
        branchPreservedData: branchDetails,
      });
    });
    this.setState({ rows: rows });
  };

  //Handle click on row
  callbackOnRowClick = (row) => {
    if (row !== undefined) {
      this.isCardClick = true;
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.BRANCH_LIST,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.BRANCH_LIST
      );
      this.props.setBranchDetailsAction(
        row.branchPreservedData,
        () => {
          this.props.history.push(ROUTES.BRANCH_DETAILS);
        }
      );
    }
  };

  handleAddBranch = () => {
    this.props.clearBranchDetailsAction();
    this.props.history.push(ROUTES.BRANCH_DETAILS);
  };

  render() {
    return (
      <div className='branchList'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.props.history?.location.state && (
          <UserNotification
            key='BranchDeactivatedSuccessfully'
            userNotificationObj={{
              message: Strings("BranchDeactivatedSuccessfully"),
              level: "success",
            }}
          />
        )}
        <BranchList
          columns={this._columns}
          rowsCount={this.state.rows.length}
          onRowClick={this.callbackOnRowClick}
          rowHeight={60}
          headerRowHeight={40}
          errorMessage={this.state.errorMessage}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          numberOfPages={this.state.numberOfPages}
          zones={this.state.zones}
          mZones={this.state.mZones}
          searchFilter={this.props.searchFilter}
          rows={this.state.rows}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchFilter: state.searchFilterReducer.branchListSearchFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBranchBySearchFilterAction,
      setBranchDetailsAction,
      clearBranchDetailsAction,
      getBranchDetailsAction,
      getAllZonesAction,
      getZonesUnderSelectedBranchAction,
      clearDailyStatusDateAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchListContainer);

import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
//Utility
import Utility from "../../utils/Utility";

export const getSingleSalesPerosnLoansAction = (
  searchFilterObject,
  callback
) => {
  let requestString = "";
  if (searchFilterObject.search.fromDate) {
    requestString +=
      "fd=" +
      Utility.convertDateIntoMiliSeconds(
        searchFilterObject.search.fromDate
      );
  }
  if (searchFilterObject.search.toDate) {
    requestString +=
      "&td=" +
      Utility.convertDateIntoMiliSeconds(
        searchFilterObject.search.toDate
      );
  }
  if (searchFilterObject.limit) {
    requestString += "&limit=" + searchFilterObject.limit;
  }
  if (searchFilterObject.offset) {
    requestString += "&offset=" + (searchFilterObject.offset - 1);
  }
  if (searchFilterObject.search.userId) {
    requestString += "&uid=" + searchFilterObject.search.userId;
  }
  if (searchFilterObject.search.category) {
    requestString += "&ct=" + searchFilterObject.search.category;
  }
  if (searchFilterObject.search.provision) {
    requestString += "&pro=" + searchFilterObject.search.provision;
  }
  if (searchFilterObject.search.loanType) {
    requestString += "&ty=" + searchFilterObject.search.loanType;
  }
  if (searchFilterObject.search.matricesId) {
    requestString += "&mid=" + searchFilterObject.search.matricesId;
  }
  if (searchFilterObject.search.minimartCode) {
    requestString +=
      "&code=" + searchFilterObject.search.minimartCode;
  }
  if (searchFilterObject.search.status) {
    requestString += "&st=" + searchFilterObject.search.status;
  }
  if (searchFilterObject.search.applicationId) {
    requestString +=
      "&appid=" + searchFilterObject.search.applicationId;
  }
  if (searchFilterObject.search.minimartName) {
    requestString += "&mn=" + searchFilterObject.search.minimartName;
  }

  callAPI(
    "getSingleSalesPerosnLoansAction",
    API.getDeatailedSalesPersonStats.concat(requestString),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

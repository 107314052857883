import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "../i18n/en";
import es from "../i18n/es";

// ========================================================
// Localisation Setup
// ========================================================

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: en,
    },
    es: {
      translations: es,
    },
  },
  fallbackLng: "en",
  debug: false,
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },
  react: {
    wait: true,
  },
});

export default i18n;

// The method we'll use instead of a regular string
const Strings = (name, params = {}) => {
  return i18n.t(name, params);
};

const ChangeLanguage = (language) => {
  i18n.changeLanguage(language);
};

export { Strings, ChangeLanguage };

import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Strings } from "../../resources/i18n/i18n";

import "./UserWorkHistory.scss";

import UserWorkHistory from "./UserWorkHistory";

class UserWorkHistoryContainer extends React.Component {
  render() {
    return (
      <div className='userWorkHistory'>
        <Row className='heading'>{Strings("WorkHistory")}</Row>
        <UserWorkHistory loggedInUser={this.props.loggedInUser} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserWorkHistoryContainer);

// Constants
import {
  SET_LOGIN_INFO_ACTION,
  SET_USER_PROFILE_ACTION,
  SET_PROFILE_PIC_ACTION,
  CLEAR_LOGIN_INFO_ACTION,
  CLEAR_USER_PROFILE_ACTION,
  UPDATE_PROFILE_INFO_ACTION,
} from "../../redux/constants";

const INITIAL_STATE = {
  loggedInUserInfo: { features: {} },
  loggedInUserProfile: {
    firstName: "",
    lastName: "",
    emailId: "",
  },
};

export default function LoginReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_LOGIN_INFO_ACTION:
      return {
        ...state,
        loggedInUserInfo: action.loggedInUserInfo,
      };
    case SET_USER_PROFILE_ACTION:
      return {
        ...state,
        loggedInUserProfile: action.data,
      };

    case SET_PROFILE_PIC_ACTION:
      return {
        ...state,
        loggedInUserProfile: {
          ...state.loggedInUserProfile,
          profilePic: action.profilePic,
        },
      };

    case CLEAR_LOGIN_INFO_ACTION:
      return {
        ...state,
        loggedInUserInfo: INITIAL_STATE.loggedInUserInfo,
      };

    case CLEAR_USER_PROFILE_ACTION:
      return {
        ...state,
        loggedInUserProfile: INITIAL_STATE.loggedInUserProfile,
      };

    case UPDATE_PROFILE_INFO_ACTION:
      return {
        ...state,
        loggedInUserInfo: {
          ...state.loggedInUserInfo,
          firstName: action.updatedProfileData.firstName,
          lastName: action.updatedProfileData.lastName,
          phone: action.updatedProfileData.phone,
          email: action.updatedProfileData.email,
        },
      };

    default:
      return state;
  }
}

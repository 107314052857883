export const REQUEST_COLUMNS = [
  { key: "mid", name: "minimartId" },
  { key: "ename", name: "minimartName" },
  { key: "code", name: "CIID" },
  { key: "br", name: "Branch" },
  { key: "zn", name: "Zone" },
  { key: "cat", name: "reportedAt" },
  { key: "action", name: "action" },
];

export const EXISTING_MINIMART_DETAILS = [
  { key: "ename", name: "minimartName" },
  { key: "escr", name: "Sector" },
  { key: "esrt", name: "Street" },
  { key: "eno", name: "number" },
  { key: "eref", name: "Reference" },
  { key: "ect", name: "City" },
  { key: "eoid", name: "ownerCedulaNumber" },
  { key: "eaid", name: "adminId" },
  { key: "ephno", name: "PhoneNumber" },
  { key: "ealph", name: "AlternateNumber" },
];

export const NEW_MINIMART_DETAILS = [
  { key: "name", name: "minimartName" },
  { key: "scr", name: "Sector" },
  { key: "srt", name: "Street" },
  { key: "no", name: "number" },
  { key: "ref", name: "Reference" },
  { key: "ct", name: "City" },
  { key: "oid", name: "ownerCedulaNumber" },
  { key: "aid", name: "adminId" },
  { key: "phno", name: "PhoneNumber" },
  { key: "alph", name: "AlternateNumber" },
];

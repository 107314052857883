import React from "react";
import {
  ComposedChart,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

//Custom Components
import CustomBarChartToolTip from "./CustomBarChartToolTip";

// Utils
import Utility from "../../../utils/Utility";

// Style
import "./BarChartComponent.scss";

class BarChartComponent extends React.Component {
  render() {
    return (
      <div className='barChartComponent'>
        <ResponsiveContainer width='100%' aspect={3}>
          <ComposedChart
            width={578}
            height={this.props.height}
            data={this.props.data}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <CartesianGrid strokeDasharray='10 10' />
            <XAxis dataKey='month' minTickGap={1} interval={0}>
              <Label offset={0} position='insideBottom' />
            </XAxis>
            <YAxis
              className='position-relative'
              tickFormatter={Utility.numberConverter}
            ></YAxis>
            {this.props.isFinance ? (
              <Tooltip content={<CustomBarChartToolTip />} />
            ) : (
              <Tooltip />
            )}
            {this.props.barsArray.map((bar) => {
              return (
                <Bar
                  key={bar.keyName}
                  dataKey={bar.keyName}
                  stackId='a'
                  fill={bar.color}
                  barSize={15}
                  // name={bar.keyName}
                />
              );
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default BarChartComponent;

import React from "react";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import MiniMartAndUser from "../CustomUIComponents/MiniMartAndUser/MiniMartAndUser";
import LoanCharts from "../CustomUIComponents/LoanCharts/LoanCharts";
import InvoiceCharts from "../CustomUIComponents/InvoiceCharts/InvoiceCharts";
import PromotionStats from "./PromotionStats";

//Constants
import {
  LOAN_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  CARD_TYPE,
  MINIMART_TYPE,
  USER_ROLES,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./SuperDashBoard.scss";

//Assets
import RatingImage from "../../resources/images/ic_ratings.svg";
import InvoiceImage from "../../resources/images/ic_invoices_dashboard.svg";
import LoanImage from "../../resources/images/ic_loan_dashboard.svg";
import MTP_PROMOTION from "../../resources/images/ic_promotion_ green.svg";
class SuperDashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branches: props.branches,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    //NOSONAR
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
  }

  handleBranchChange = (event) => {
    if (event.target.id === CARD_TYPE.MINIMART) {
      this.props.handleBranchChange(CARD_TYPE.MINIMART, event);
    }
    if (event.target.id === CARD_TYPE.INVOICE) {
      this.props.handleBranchChange(CARD_TYPE.INVOICE, event);
    }
    if (event.target.id === CARD_TYPE.LOAN) {
      this.props.handleBranchChange(CARD_TYPE.LOAN, event);
    }
    if (event.target.id === CARD_TYPE.NEW_MINIMART) {
      this.props.handleBranchChange(CARD_TYPE.NEW_MINIMART, event);
    }
  };

  handleMinimartTypeChange = (event) => {
    this.props.handleMinimartTypeChange(event);
  };

  handleChangeMonthYearSelectionForInvoice = (
    selectedMonthYearObject
  ) => {
    this.props.handleChangeMonthYearSelection(
      selectedMonthYearObject
    );
  };

  handleChangeMonthYearSelectionForLoan = (
    selectedMonthYearObject
  ) => {
    this.props.handleChangeMonthYearForLoanSelection(
      selectedMonthYearObject
    );
  };

  handleTypeChange = (event) => {
    this.props.handleTypeChange(event);
  };

  //render
  renderMinimartFilter = (isNewMinimart = false) => {
    let minimartFilters = [
      {
        id: "supplierType",
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: isNewMinimart
          ? this.props.handleNewMinimartTypeChange
          : this.handleMinimartTypeChange,
        Value: isNewMinimart
          ? this.props.newMinimartsFilters.minimartType
          : this.props.minimartFilters.minimartType,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_TYPES,
        filterDropdownList: MINIMART_TYPE,
        column: 3,
      },
      {
        id: isNewMinimart ? "newMinimart" : "minimart",
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleBranchChange,
        Value: isNewMinimart
          ? this.props.newMinimartsFilters.branchId
          : this.props.minimartFilters.branchId,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.state.branches,
        column: 3,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: isNewMinimart
          ? this.props.handleNewBusinessTypeChange
          : this.props.handleBusinessTypeChange,
        Value: isNewMinimart
          ? this.props.newMinimartsFilters.businessType
          : this.props.minimartFilters.businessType,
        fieldname: SEARCH_FILTER_FIELDS.BUSINESS_TYPE,
        filterDropdownList: this.props.businessTypeList,
        column: 3,
        disabled: !isNewMinimart && this.props.selectedRatingTab.key === 1,
      },
    ];
    return (
      <SearchFilter
        filters={minimartFilters}
        handleOnClickResetFilter={
          isNewMinimart
            ? this.props.resetNewMinimartData
            : this.props.resetMinimartData
        }
        handleOnClickSearchFilter={
          isNewMinimart
            ? this.props.geNewtMiniMartAndUserData
            : this.props.getMiniMartAndUserData
        }
        paddingLeft={true}
        className='pb-2 mb-1'
        buttonSize={true}
      />
    );
  };

  renderInvoiceFilter = () => {
    let invoiceFilters = [
      {
        id: "invoice",
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleBranchChange,
        Value: this.props.invoiceFilters.branchId,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.state.branches,
      },
      {
        type: "MonthYearPicker",
        handleChangeMonthYearSelection:
          this.handleChangeMonthYearSelectionForInvoice,
        countOfMonths: 1,
        closeOnSelect: true,
        Value:
          this.props.invoiceFilters.duration.month +
          "-" +
          this.props.invoiceFilters.duration.year,
        column: 2,
        // key: "invoice"
      },
    ];
    return (
      <SearchFilter
        filters={invoiceFilters}
        handleOnClickResetFilter={this.props.resetInvoiceData}
        handleOnClickSearchFilter={this.props.getInvoiceChartData}
        paddingLeft={true}
        className='pb-2 mb-1'
        buttonSize={true}
      />
    );
  };

  renderLoanFilter = () => {
    let loanFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleTypeChange,
        Value: this.props.loanFilters.loanType,
        fieldname: SEARCH_FILTER_FIELDS.TYPE,
        filterDropdownList: LOAN_TYPE,
        column: 3,
        id: "loan",
      },
      {
        id: "loan",
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleBranchChange,
        Value: this.props.loanFilters.branchId,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.state.branches,
        // key: "loan"
      },
      {
        type: this.props.calender,
        handleChangeMonthYearSelection:
          this.handleChangeMonthYearSelectionForLoan,
        countOfMonths:
          this.props.calender === "MonthYearPicker" ? 1 : 0,
        closeOnSelect: true,
        Value:
          this.props.calender === "MonthYearPicker"
            ? this.props.loanFilters.duration.month +
            "-" +
            this.props.loanFilters.duration.year
            : this.props.loanFilters.duration.year,
        column: 2,
        id: "loan",
      },
    ];
    return (
      <SearchFilter
        filters={loanFilters}
        handleOnClickResetFilter={this.props.resetLoanChartData}
        handleOnClickSearchFilter={this.props.getLoanChartData}
        paddingLeft={true}
        className='pb-2 mb-1'
        buttonSize={true}
      />
    );
  };

  renderPromotionFilter = () => {
    let promotionFilter = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        fromDate: this.props.promotionFilter.fromDate,
        handleCalenderChange: this.props.handleDateChange,
        toDate: this.props.promotionFilter.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
    ];
    return (
      <SearchFilter
        filters={promotionFilter}
        handleOnClickResetFilter={
          this.props.resetPromotionFilterClick
        }
        handleOnClickSearchFilter={
          this.props.searchPromotionFilterClick
        }
        paddingLeft={true}
        className='pb-2 mb-1'
        buttonSize={true}
      />
    );
  };

  renderPromotionStats = () => {
    return (
      <Row className='loanCharts'>
        <Col md={12}>
          <Row className='alignFilter'>
            <Col md={12}>
              <Row>
                <img
                  className='alignImageCenter pb-2'
                  src={MTP_PROMOTION}
                  alt={Strings("Promotions")}
                />

                <Col
                  md={1}
                  className='subHeading justify-content align-center display-flex'
                >
                  {Strings("Promotions")}
                </Col>
                <Col md={10} className='pl-4'>
                  {this.renderPromotionFilter()}
                </Col>
              </Row>
            </Col>
          </Row>
          <hr className='newhr' />
          {this.props.promotionStatData ? (
            <PromotionStats data={this.props.promotionStatData} />
          ) : (
            <div className='noRecordsFound pt-5'>
              {Strings("NoData")}
            </div>
          )}
        </Col>
      </Row>
    );
  };
  render() {
    return (
      <div className='superDashBoard'>
        <Row className='heading'>{Strings("Dashboard")}</Row>
        <Row className='minimartandUsers'>
          <Col className='container-fluid'>
            <Row className='alignFilterRow'>
              <Col md={10}>
                <Row>
                  <img
                    className='alignImageCenter'
                    src={RatingImage}
                    alt={Strings("ratings")}
                  />
                  <div className='subHeading justify-content align-center display-flex pt-2 pl-3'>
                    {Strings("ratings")}
                  </div>
                  <Col md={9}>{this.renderMinimartFilter()}</Col>
                </Row>
              </Col>
            </Row>
            <hr className='newhr' />
            <Row>
              <Col md={12} className='noPadding'>
                {this.props.minimart_userData ? (
                  <MiniMartAndUser
                    data={this.props.minimart_userData}
                    loggedInUser={this.props.loggedInUser}
                    handleRatingTabs={this.props.handleRatingTabs}
                    selectedRatingTab={this.props.selectedRatingTab}
                  />
                ) : (
                  <div className='noRecordsFound pt-5'>
                    {Strings("NoData")}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Prospecto change */}
        <Row className='minimartandUsers mt-3'>
          <Col className='container-fluid'>
            <Row className='alignFilterRow'>
              <Col md={10}>
                <Row>
                  <img
                    className='alignImageCenter'
                    src={RatingImage}
                    alt={Strings("NewMinimart")}
                  />
                  <div className='subHeading justify-content align-center display-flex pt-2 pl-3'>
                    {Strings("NewMinimart")}
                  </div>
                  <Col md={9}>{this.renderMinimartFilter(true)}</Col>
                </Row>
              </Col>
            </Row>
            <hr className='newhr' />
            <Row>
              <Col md={12} className='noPadding'>
                {this.props.new_minimart_userData ? (
                  <MiniMartAndUser
                    data={this.props.new_minimart_userData}
                    loggedInUser={this.props.loggedInUser}
                    handleRatingTabs={this.props.handleRatingTabs}
                    selectedRatingTab={this.props.selectedRatingTab}
                    isProspecto={true}
                  />
                ) : (
                  <div className='noRecordsFound pt-5'>
                    {Strings("NoData")}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {this.props.loggedInUser.role !== USER_ROLES.messengerModerator && (
          <div className='Invoices'>
            <Col md={12} className='container-fluid'>
              <Row className='alignFilter'>
                <Col md={10}>
                  <Row>
                    <img
                      className='alignImageCenter'
                      src={InvoiceImage}
                      alt={Strings("Invoices")}
                    />
                    <Col
                      md={1}
                      className='subHeading justify-content align-center display-flex'
                    >
                      {Strings("Invoices")}
                    </Col>
                    <Col md={10}>{this.renderInvoiceFilter()}</Col>
                  </Row>
                </Col>
              </Row>
              <hr className='newhr' />
              {this.props.invoiceData ? (
                <InvoiceCharts
                  data={this.props.invoiceData}
                  MonthYearStrings={this.props.MonthYearStrings}
                />
              ) : (
                <div className='noRecordsFound pt-5'>
                  {Strings("NoData")}
                </div>
              )}
            </Col>
          </div>
        )}
        <Row className='loanCharts'>
          <Col md={12}>
            <Row className='alignFilter'>
              <Col md={10}>
                <Row>
                  <img
                    className='alignImageCenter'
                    src={LoanImage}
                    alt={Strings("Loans")}
                  />
                  <Col
                    md={1}
                    className='subHeading justify-content align-center display-flex'
                  >
                    {Strings("Loans")}
                  </Col>
                  <Col md={10} className='pl-4'>
                    {this.renderLoanFilter()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Row className='completeWidth'>
            <hr
              className='newhr'
              style={{ height: "100%", width: "100%" }}
            />
          </Row>
          <LoanCharts
            amount={this.props.amount}
            amountToBeCollected={this.props.amountToBeCollected}
            disbursedAmount={this.props.disbursedAmount}
            loanCategories={this.props.loanData}
            loanStatus={this.props.loanApplicationData}
            loanStatusCount={this.props.loanStatusCount}
            loanCategoryCount={this.props.loanCategoryCount}
            activeTab={this.props.activeTab}
            handleTabs={this.props.handleTabs}
            loanBarchartData={this.props.loanBarchartData}
            loggedInUser={this.props.loggedInUser.role}
            provisionBarChartData={this.props.provisionBarChartData}
            provisionPieChartData={this.props.provisionPieChartData}
            provisionCount={this.props.provisionCount}
            denialCount={this.props.denialCount}
            denialBarChartData={this.props.denialBarChartData}
            denialPieChartData={this.props.denialPieChartData}
            financeProfitBarChartData={
              this.props.financeProfitBarChartData
            }
          />
        </Row>

        {(this.props.loggedInUser.role === USER_ROLES.generalManager
          || this.props.loggedInUser.role === USER_ROLES.admin
          || this.props.loggedInUser.role === USER_ROLES.salesModerator) && this.renderPromotionStats()}
      </div>
    );
  }
}

export default SuperDashBoard;

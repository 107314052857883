// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { API } from "../../constants/APIConstants";
import {
  SET_BRANCHES_ACTION,
  SET_DATE_ACTION,
  SET_MINIMART_DETAILS_ACTION,
  SET_FILE_DETAILS_ACTION,
} from "../../redux/constants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

import { callAPI } from "../../config/AxiosTemplate";

/*
 * Actions not using dispatch
 * This will be called directly inside container without mapDispatchToProps
 */

/**
 *  Action to upload csv
 */
export const uploadCSVAction =
  (requestData, callback) => (dispatch) => {
    let request = new FormData();
    request.append("type", requestData.type);
    request.append("subType", requestData.subType);
    request.append("file", requestData.file);
    request.append("fileType", requestData.fileType);
    callAPI(
      "uploadCSV",
      API.uploadCSV,
      "POST",
      request,
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          dispatch({
            type: SET_DATE_ACTION,
            date: apiResponse.data.data.lastModified,
          });
        }
        callback(apiResponse);
      }
      ,false, true
    );
  };

export const getMinimartDataAction = (
  searchFilterObject,
  isNewMinimart,
  callback
) => {
  // NOSONAR
  let requestData =
    Object.keys(searchFilterObject.search).length >= 1
      ? {
        search: {
          minimartName:
            searchFilterObject.search.minimartName &&
              searchFilterObject.search.minimartName !== ""
              ? searchFilterObject.search.minimartName
              : undefined,
          ciId:
            searchFilterObject.search.ciId &&
              searchFilterObject.search.ciId !== ""
              ? searchFilterObject.search.ciId
              : undefined,
          matricesId:
            searchFilterObject.search.matricesId &&
              searchFilterObject.search.matricesId !== ""
              ? searchFilterObject.search.matricesId
              : undefined,
          type:
            searchFilterObject.search.type &&
              searchFilterObject.search.type !== ""
              ? searchFilterObject.search.type
              : undefined,
          zone:
            searchFilterObject.search.zone &&
              searchFilterObject.search.zone !== ""
              ? searchFilterObject.search.zone
              : undefined,
          messengerZone:
            searchFilterObject.search.messengerZone &&
              searchFilterObject.search.messengerZone !== ""
              ? searchFilterObject.search.messengerZone
              : undefined,
          grade:
            searchFilterObject.search.grade &&
              searchFilterObject.search.grade !== ""
              ? searchFilterObject.search.grade
              : undefined,
          supplierType:
            searchFilterObject.search.supplierType &&
              searchFilterObject.search.supplierType !== ""
              ? searchFilterObject.search.supplierType
              : undefined,
          status:
            searchFilterObject.search.minimartStatus &&
              searchFilterObject.search.minimartStatus !== ""
              ? searchFilterObject.search.minimartStatus
              : undefined,
          businessType: searchFilterObject.search.businessType
            ? searchFilterObject.search.businessType
            : undefined,
          registrationStatus: searchFilterObject.search
            .registrationStatus
            ? searchFilterObject.search.registrationStatus
            : undefined,
          level: searchFilterObject.search.level
            ? searchFilterObject.search.level
            : undefined,
          zones: searchFilterObject.search.zones
            ? searchFilterObject.search.zones
            : undefined,
          messengerZones: searchFilterObject.search.messengerZones
            ? searchFilterObject.search.messengerZones
            : undefined,
          cedulaId: searchFilterObject.search.cedulaId
            ? searchFilterObject.search.cedulaId
            : undefined,
          isScalaRegistered: searchFilterObject.search.isScalaRegistered,
        },
        limit: searchFilterObject.pagination.limit
          ? searchFilterObject.pagination.limit
          : DEFAULT_PAGINATION_RECORDS_COUNT,
        offset:
          searchFilterObject.pagination.offset - 1
            ? searchFilterObject.pagination.offset - 1
            : DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
      }
      : {
        search: {},
        limit: searchFilterObject.pagination.limit
          ? searchFilterObject.pagination.limit
          : DEFAULT_PAGINATION_RECORDS_COUNT,
        offset:
          searchFilterObject.pagination.offset - 1
            ? searchFilterObject.pagination.offset - 1
            : DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
      };
  callAPI(
    "getMinimartDataAction",
    isNewMinimart ? API.getNewMinimartData : API.getMinimartData,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

/**
 *  Action to get branch
 */
export const getBranchAction = (callback) => (dispatch) => {
  callAPI("GetBranch", API.getBranch, "POST", {}, (apiResponse) => {
    if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
      dispatch({
        type: SET_BRANCHES_ACTION,
        branchDetails: apiResponse.data.data.branches,
      });
    }
    callback(apiResponse);
  });
};

export const getMinimartListAction = (callback) => {
  callAPI(
    "getAllMiniMArts",
    API.getAllMiniMarts,
    "POST",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setMiniMartDetailsAction = (minimart) => (dispatch) => {
  dispatch({
    type: SET_MINIMART_DETAILS_ACTION,
    singleMiniMart: minimart,
  });
};

export const getAllPreviouslyUploadedFilesAction = (
  requestData,
  callback
) => {
  if (requestData.offset) {
    requestData.offset = requestData.offset - 1;
  }
  callAPI(
    "getAllPreviouslyUploadedFiles",
    API.getAllPreviousFiles,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const deleteFilesAction = (deleteFiles, callback) => {
  let documentIds = deleteFiles;
  callAPI(
    "deleteFilesAction",
    API.deleteFiles,
    "DELETE",
    { documentIds: documentIds },
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const pollFileProcessingStatusAction = (
  fileIdArray,
  callback
) => {
  callAPI(
    "pollFileProcessingStatusApi",
    API.pollFileProcessingStatus,
    "POST",
    { documentIds: fileIdArray },
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setFileDetailsAction = (file) => (dispatch) => {
  dispatch({
    type: SET_FILE_DETAILS_ACTION,
    filePreservedData: file,
  });
};

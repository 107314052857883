import { Strings } from "../../resources/i18n/i18n";

export const COMPLAINT_TABS = [
  {
    key: 0,
    value: Strings("complaintPortal"),
  },
  {
    key: 1,
    value: Strings("complaintHistory"),
  },
];

export const PORTAL_COLUMNS = [
  {
    key: "cmp",
    name: "complaint",
  },
  {
    key: "mn",
    name: "reportedBy",
  },
  {
    key: "cat",
    name: "reportedAt",
  },
  {
    key: "action",
    name: "action",
  },
];

export const PORTAL_SECOND_ROW = [
  { key: "bd", name: "Description" },
  { key: "btn", name: "resolve" },
];

export const HISTORY_COLUMNS = [
  {
    key: "cmp",
    name: "complaint",
  },
  {
    key: "mn",
    name: "reportedBy",
  },
  {
    key: "cat",
    name: "reportedAt",
  },
  {
    key: "rby",
    name: "resolvedBy",
  },
  {
    key: "action",
    name: "action",
  },
];

export const HISTORY_SECOND_ROW = [
  { key: "bd", name: "Description" },
  { key: "nt", name: "Note" },
];

export const COMPLAINTS = [
  Strings("complaint"),
  Strings("COMPLAINT_1"),
  Strings("COMPLAINT_2"),
  Strings("COMPLAINT_3"),
  Strings("COMPLAINT_4"),
  Strings("COMPLAINT_5"),
  Strings("COMPLAINT_6"),
  Strings("COMPLAINT_7"),
  Strings("OTHER"),
];

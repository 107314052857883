import React from 'react'
import { Row, Col } from 'react-bootstrap';
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";

//Strings
import { Strings } from "../../resources/i18n/i18n";

class CommonPanel extends React.Component {
    renderSearchFilter = () => {
        return (
            <SearchFilter
                filters={this.props.searchFilters}
                handleOnClickSearchFilter={this.props.handleOnClickSearchFilter}
                handleOnClickResetFilter={this.props.handleOnClickResetFilter}
                paddingLeft={2}

                addButton={this.props.addButton}
                addAnotherButton={this.props.addAnotherButton}
                buttonText={this.props.buttonText}
                anotherButtonText={this.props.anotherButtonText}
                AnotherButtonClick={this.props.AnotherButtonClick}
                handleAddUser={this.props.handleAddUser}
                spaceBetween={this.props.spaceBetween}
                spaceInmd={this.props.spaceInmd}
            />
        );
    };

    renderPagination = () => {
        return (
            this.props.data?.length > 0 ? (
                <>
                    <Col md={11} className='noPadding alignPagination'>
                        <Pagination
                            activePage={this.props.activePage}
                            recordsPerPage={this.props.recordsPerPage}
                            numberOfPages={this.props.numberOfPages}
                            onPageChange={this.props.onPageChange}
                            dropup={true}
                        />
                    </Col>
                </>
            ) : (
                <div className='noRecordsFound'>
                    {Strings("NoRecordsFound")}
                </div>
            )

        )
    }

    render() {
        return (
            <>
                <Row className='heading'>{Strings(this.props.header)}</Row>
                <div className='paper greyback customTableCard'>
                    {this.renderSearchFilter()}
                    {this.props.children}
                    {this.props.isPagination && this.renderPagination()}
                </div>
            </>
        )
    }
}
export default CommonPanel;
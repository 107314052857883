import { Strings } from "../../resources/i18n/i18n";
export const FIRST_ROW_COLUMNS = [
  { key: "mid", name: "minimartId" },
  { key: "mn", name: "minimartName" },
  { key: "sp", name: "SALES_PERSON" },
  { key: "rs", name: "reason" },
  { key: "action", name: "action" },
];

export const SECOND_ROW_COLUMNS_EXT_FACT_SUPPLIER_DETAILS = [
  { key: "businessName", name: "businessName" },
  { key: "bankName", name: "bankName" },
  { key: "accountType", name: "accountType" },
  { key: "accountNumber", name: "accountNumber" },
];

export const SECOND_ROW_COLUMNS_EXT_FACT_PERSONAL_INFO = [
  { key: "bankName", name: "bankName" },
  { key: "accountType", name: "accountType" },
  { key: "accountNumber", name: "accountNumber" },
];

export const SECOND_ROW_COLUMNS_PRODUCT_MOTORCYCLE = [
  { key: "pn", name: "productName" },
  { key: "amt", name: "amount" },
  { key: "lt", name: "loanType" },
  { key: "btn", name: "dismiss" },
  { key: "term", name: "Term" },
  { key: "rt", name: "rate" },
  { key: "emi", name: "emi" },
  { key: "cd", name: "RequestDate" },
  { key: "nt", name: "Note" },
  { key: "dby", name: "dismissedBy" },
  { key: "dat", name: "dismissedAt" },
];
export const SECOND_ROW_COLUMNS = [
  { key: "amt", name: "amount" },
  { key: "lt", name: "loanType" },
  { key: "term", name: "Term" },
  { key: "rt", name: "rate" },
  { key: "btn", name: "dismiss" },
  { key: "emi", name: "emi" },
  { key: "cd", name: "RequestDate" },
  { key: "nt", name: "Note" },
  { key: "dby", name: "dismissedBy" },
  { key: "dat", name: "dismissedAt" },
];

export const LOAN_STATUSES = {
  PENDING: "PENDING",
  VISITED: "VISITED",
  DISMISSED: "DISMISSED",
  ASSIGNED: "ASSIGNED",
};

export const LOAN_REQUEST_STATUSES = [
  { name: Strings("PENDING"), value: LOAN_STATUSES.PENDING },
  { name: Strings("VISITED"), value: LOAN_STATUSES.VISITED },
  { name: Strings("DISMISSED"), value: LOAN_STATUSES.DISMISSED },
  { name: Strings("ASSIGNED"), value: LOAN_STATUSES.ASSIGNED },
];

import { Strings } from "../../resources/i18n/i18n";
export const LOAN_TYPE = [
  {
    key: 0,
    value: "type",
    label: Strings("Type"),
  },
  {
    key: 1,
    value: "LOAN",
    label: Strings("loan"),
  },
  {
    key: 2,
    value: "COLLECTION",
    label: Strings("Collection"),
  },
];

//React components
import React from "react";
import { Row, Col } from "react-bootstrap";

//Components
import PieChartComponent from "../CustomUIComponents/PieChartComponent/PieChartComponent";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";

//Constants
import {
  RATING_CONSTANTS,
  PIE_CHART_COLORS,
  DAILY_STATUS_MESSENGER_LEGENDS,
  MESSENGER_LOAN_LEGENDS,
  INVOICE_DATE_FORMAT,
  CURRENCY,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  SEARCH_FILTER_PLACE_HOLDER,
  PIE_CHART_COLORS_CONST,
} from "../../constants/appConstants";
import { LEGEND_KEY_CONST } from "./DailyStatusConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Assets
import deposited from "../../resources/images/ic_deposited.svg";
import inTransit from "../../resources/images/ic_in_transit.svg";

//Styles
import "./DailyStatus.scss";

//Utils
import Utility from "../../utils/Utility";

class Messenger extends React.Component {
  renderLegend = (color, amountType, data, index, key) => {
    let colorStyle = {
      backgroundColor: color,
      height: "1.5em",
      width: "1.5em",
    };
    return (
      <Row className='legend' key={index}>
        <div className='pl-0' style={colorStyle} />
        <Col md={6} className='pr-0'>
          <div
            className='branchNameInLegends'
            title={Strings(amountType)}
          >
            {Strings(amountType)}
          </div>
        </Col>
        <Col className='branchNameInLegends px-0 font-weight-bold'>
          {data}
        </Col>
      </Row>
    );
  };

  renderPieChartComponent = (data, colors, total, amount = false) => {
    return (
      <PieChartComponent
        data={data}
        colors={colors}
        index={0}
        innerRadius='65%'
        outerRadius='90%'
        datakey='count'
        height={150}
        showLabel={true}
        total={
          amount
            ? CURRENCY +
              Utility.getCurrencyRepresentationOfAmount(total)
            : total
        }
      />
    );
  };

  renderMinimartSearchFilter = () => {
    let minimartFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("name"),
        handleOnChange: this.props.handleSearchNameChange,
        Value: this.props.salesPersonFilters.name,
        fieldname: SEARCH_FILTER_FIELDS.SALES_PERSON_NAME,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleBranchChange,
        Value: this.props.salesPersonFilters.branchId,
        fieldname: SEARCH_FILTER_FIELDS.BRANCH,
        filterDropdownList: this.props.branches,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleZoneChange,
        Value: this.props.salesPersonFilters.zone,
        fieldname: SEARCH_FILTER_FIELDS.ZONE,
        filterDropdownList: this.props.mZones,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.props.handleSelectedRatingChange,
        Value: this.props.salesPersonFilters.rating,
        fieldname: SEARCH_FILTER_FIELDS.RATING,
        filterDropdownList: RATING_CONSTANTS,
      },
    ];
    return (
      <SearchFilter
        filters={minimartFilters}
        handleOnClickSearchFilter={
          this.props.handleOnClickSearchFilter
        }
        handleOnClickResetFilter={this.props.handleOnClickResetFilter}
        paddingLeft={true}
      />
    );
  };

  renderDateFilter = () => {
    let dateFilters = [
      {
        type: SEARCH_FILTER_TYPE.SINGLE_DATE,
        column: 6,
        placeholder: SEARCH_FILTER_PLACE_HOLDER.SELECT_DATE,
        handleCalenderChange: this.props.handleDateChange,
        Value: this.props.salesPersonFilters.date,
        dateFormat: INVOICE_DATE_FORMAT,
      },
    ];
    return (
      <SearchFilter
        filters={dateFilters}
        handleOnClickSearchFilter={
          this.props.handleMessengerFiltersForCalender
        }
        handleOnClickResetFilter={
          this.props.handleOnClickResetFilterForCalender
        }
        smallButtonSize={true}
      />
    );
  };

  renderDepositRepresentation = () => {
    return (
      <Col md={{ span: 3 }} className='subHeading pt-3'>
        <Row>
          <Col md={2}>
            <img src={deposited} alt='deposited' />
          </Col>
          <Col md={6} className='pt-2 pb-4'>
            <Row>{Strings("Deposited")}</Row>
            <Row>
              {" "}
              {this.props.otherData !== null &&
                CURRENCY +
                  Utility.getCurrencyRepresentationOfAmount(
                    this.props.otherData.depositedAmount
                  )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <img src={inTransit} alt='inTransit' />
          </Col>
          <Col md={9} className='pt-2 pb-4'>
            <Row>{Strings("InTransit")}</Row>
            <Row>
              {" "}
              {this.props.otherData !== null &&
                CURRENCY +
                  Utility.getCurrencyRepresentationOfAmount(
                    this.props.otherData.amountInstransit
                  )}
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };

  renderDailyStatusLoanGraphic = () => {
    return (
      <React.Fragment>
        <Col md={2} className='pr-0'>
          {this.renderPieChartComponent(
            this.props.MessengerLoanData,
            "messengerDailyStatusLoans",
            this.props.otherData?.totalAssignedMinimarts
          )}
        </Col>
        <Col md={2} className='align-self-center pl-0'>
          {this.props.MessengerLoanData.length > 0 &&
            MESSENGER_LOAN_LEGENDS.map((legend, index) => {
              return this.renderLegend(
                PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST.MESSENGER_DAILY_STATUS_LOANS
                ][index],
                legend,
                this.props.MessengerLoanData[index].count,
                index,
                LEGEND_KEY_CONST.LOANS
              );
            })}
        </Col>
      </React.Fragment>
    );
  };

  renderStatsGraphic = () => {
    return (
      <React.Fragment>
        <Col md={2} className='pr-0'>
          {this.renderPieChartComponent(
            this.props.piechartStats,
            "messengerPiechartStats",
            this.props.otherData?.totalAmount,
            true
          )}
        </Col>
        <Col md={3} className='alignLegends px-0 pt-1'>
          {this.props.piechartStats.length > 0 &&
            DAILY_STATUS_MESSENGER_LEGENDS.map((legend, index) => {
              return this.renderLegend(
                PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST.MESSENGER_PIECHART_STATS
                ][index],
                legend,
                CURRENCY +
                  Utility.getCurrencyRepresentationOfAmount(
                    this.props.piechartStats[index].count
                  ),
                index,
                LEGEND_KEY_CONST.AMOUNT
              );
            })}
          <hr className='divHr' />
          <Row className='pt-2'>
            <Col md={3} className='noPadding'>
              <span className='subHeading'>
                {Strings("extraAmount")}
              </span>
            </Col>
            <Col md={{ span: 4 }} className='px-0'>
              <div className='subHeading'>
                {CURRENCY +
                  (this.props.otherData?.extraAmount
                    ? this.props.otherData?.extraAmount
                    : 0)}
              </div>
            </Col>
          </Row>
        </Col>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className='salesPerson ListContainer'>
        <Row className='pb-2'>
          <Col md={{ span: 4 }} className='pl-4 pt-3'>
            <span className='subHeading'>{Strings("Loans")}</span>
          </Col>
          <Col md={4} className='pt-3'>
            <span className='subHeading'>{Strings("amount")}</span>
          </Col>
          <Col md={4}>{this.renderDateFilter()}</Col>
        </Row>
        <Row>
          {this.renderDailyStatusLoanGraphic()}
          {this.renderStatsGraphic()}
          {this.renderDepositRepresentation()}
        </Row>

        <Row>
          <div className='pl-4'>
            <span className='subHeading'>
              {Strings("MessengersList")}
            </span>
          </div>
        </Row>

        {this.renderMinimartSearchFilter()}

        {this.props.salesPersons.length > 0 ? (
          <Row className='cardAlign'>
            <Col md={12} className='w-100 pr-0'>
              <Row className='w-100'>
                {this.props.salesPersons.map((salesPerson, index) => {
                  return (
                    <CustomCard
                      card={salesPerson}
                      key={index}
                      cardType='Person'
                      handleCardClick={this.props.setMessengerId}
                    />
                  );
                })}
              </Row>
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.props.activePage}
                recordsPerPage={this.props.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.props.onPageChange}
                dropup={true}
              />
            </Col>
          </Row>
        ) : (
          <div className='noRecordsFound noBranchRecords'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default Messenger;

import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
//Utility
import Utility from "../../utils/Utility";

export const getLoansHistoryBySearchFilterAction = (
  searchFilterObject,
  callback,
  isAnalyst = false,
  isOperationsOfficer = false,
  isOperationsModerator = false,
  isAccountingOfficer = false,
  isAnalystModerator = false,
  isAuditor = false,
  isCustomerService = false,
  isOperationsManager = false,
  issalesManager = false,
) => {
  // NOSONAR
  let requestData = {
    loanSearchCriteria: {
      matricesId: searchFilterObject.search.matricesId
        ? searchFilterObject.search.matricesId
        : undefined,
      code: searchFilterObject.search.minimartCode
        ? searchFilterObject.search.minimartCode
        : undefined,
      fromDate: searchFilterObject.search.fromDate
        ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.fromDate
        )
        : undefined,
      toDate: searchFilterObject.search.toDate
        ? Utility.convertDateIntoMiliSeconds(
          searchFilterObject.search.toDate
        )
        : undefined,
      status: searchFilterObject.search.status
        ? searchFilterObject.search.status
        : undefined,
      applicationId: searchFilterObject.search.applicationId
        ? searchFilterObject.search.applicationId
        : undefined,
      minimartName: searchFilterObject.search.minimartName
        ? searchFilterObject.search.minimartName
        : undefined,
      userId: searchFilterObject.search.userName
        ? searchFilterObject.search.userName
        : undefined,
      type: searchFilterObject.search.loanType
        ? searchFilterObject.search.loanType
        : undefined,
      provision: searchFilterObject.search.provision
        ? searchFilterObject.search.provision
        : undefined,
      category:
        searchFilterObject.search.category !== ""
          ? searchFilterObject.search.category
          : undefined,
    },
    limit: searchFilterObject.limit
      ? searchFilterObject.limit
      : DEFAULT_PAGINATION_RECORDS_COUNT,
    offset: searchFilterObject.offset
      ? searchFilterObject.offset - 1
      : DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
  };

  callAPI(
    "getLoansHistoryBySearchFilter",
    isAnalyst || isAnalystModerator
      ? API.getAnalystLoanHistory
      : isOperationsOfficer || isOperationsModerator || // NOSONAR
        isCustomerService ||
        isOperationsManager ||
        issalesManager
        ? API.getOperationsLoanHistory
        : isAccountingOfficer || isAuditor // NOSONAR
          ? API.getAccountingOfficerHistory
          : API.getLoansBySearchFilter,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

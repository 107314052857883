// Constants
import {
  SET_FILE_DETAILS_ACTION,
  CLEAR_CI_REDUCER_ACTION,
} from "../../redux/constants";

const INITIAL_STATE = {
  fileData: null,
};

export default function ciReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FILE_DETAILS_ACTION:
      return {
        ...state,
        fileData: action.filePreservedData,
      };
    case CLEAR_CI_REDUCER_ACTION:
      return { ...state, fileData: INITIAL_STATE.fileData };
    default:
      return state;
  }
}

import React from "react";

// String
import { Strings } from "../../resources/i18n/i18n";

// Style
import { Col, Row, Button, Table } from "react-bootstrap";
import CreditLimitModal from "./creditLimitModal";
import moment from "moment";

// Component
import { INVOICE_DATE_FORMAT } from "../../constants/appConstants";
// Constant

class CreditLimit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      requestData: props.creditLimitData,
      errorMessage: "",
    };
  }
  componentDidMount() {
    this.props.getCreditAgreementList(this.props.matricesId);
  }

  static getDerivedStateFromProps(props, state) {
    let derivedstate = state;
    if (props.creditLimitData !== null) {
      derivedstate = {
        ...derivedstate,
        requestData: props.creditLimitData,
      };
      return derivedstate;
    }
    return derivedstate;
  }

  openModal = () => {
    this.props.getPerticularCreditLimitRequest(this.props.ticketId);
    this.setState({ showModal: true, errorMessage: "" });
  };

  closeModal = () => {
    this.setState({ showModal: false, errorMessage: "" });
  };

  onApprovalNotesChange = (event) => {
    this.setState({
      approveNote: event.target.value,
    });
  };
  handleRequest = (status) => {
    this.props.creditLimitRequest({
      status: status,
      note: this.state.approveNote,
      refId: this.state.requestData.id,
    });
    this.closeModal();
  };

  render() {
    return (
      <div className='creditLimit pt-3'>
        {this.state.showModal && (
          <CreditLimitModal
            open={this.state.showModal}
            modalCloseOnEsc={false}
            modalCloseOnOverlayClick={false}
            modalShowCloseIcon={false}
            headerText={Strings("HigherCreditLimit")}
            modalButtonCancel={{
              text: Strings("Cancel"),
              onClick: this.closeModal,
            }}
            modalButtonApprove={{
              text: Strings("Approve"),
              onClick: this.handleRequest,
            }}
            modalButtonReject={{
              text: Strings("Reject"),
              onClick: this.handleRequest,
            }}
            errorMessage={this.props.errorMessage}
            requestData={this.props.creditLimitData}
            readOnly={this.state.readOnly}
            onApprovalNotesChange={this.onApprovalNotesChange}
          />
        )}
        <Row className='cardAlign'>
          <Col md={6} className='rightSide'>
            <Row className='subHeading pb-2'>
              {Strings("creditLimitAgreement")}
            </Row>
            {this.props.creditAgreementRecords.length > 0 ? (
              <Table hover bordered condesed='true'>
                <thead className='header'>
                  <tr>
                    <td className='limitedCellWidth'>
                      {Strings("documentId")}
                    </td>
                    <td className='textAlignCenter'>
                      {Strings("Date")}
                    </td>
                    <td className='textAlignCenter'>
                      {Strings("action")}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {this.props.creditAgreementRecords.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td className='textAlignCenter alignCellContent'>
                          {row.id}
                        </td>
                        <td className='textAlignCenter alignCellContent'>
                          {moment(row.createdAt).format(
                            INVOICE_DATE_FORMAT
                          )}
                        </td>
                        <td className='alignCellContent textAlignCenter'>
                          <Button
                            className='buttonBackground  bold'
                            onClick={() => {
                              this.props.downloadCreditAgreement(
                                row.id
                              );
                            }}
                          >
                            {Strings("view")}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className='noRecordsFound'>
                {Strings("NoRecordsFound")}
              </div>
            )}
          </Col>
          <Col md={6}>
            <Row className='subHeading'>{Strings("Request")}</Row>
            <Row className='alignButton'>
              {this.props.ticketId && (
                <Button
                  className='buttonBackground pull-right  bold'
                  onClick={this.openModal}
                >
                  {Strings("viewHigherCreditLimitRequest")}
                </Button>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CreditLimit;

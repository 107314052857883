import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getAllAccountTypes = (
    request,
    callback
) => {
    callAPI(
        "getAllAccountTypes",
        API.getAccountTypes,
        "POST",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const addNewAccountType = (
    request,
    callback
) => {
    callAPI(
        "addNewAccountType",
        API.accountType,
        "POST",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const editAccountTypeState = (
    request,
    callback
) => {
    callAPI(
        "editAccountTypeState",
        API.accountTypeState,
        "PATCH",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};


export const bankDetailsSearchFilter = (
    request,
    callback
) => {
    callAPI(
        "bankDetailsSearchFilter",
        API.bankDetails + "/search-filter",
        "POST",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};


export const addNewBank = (
    request,
    callback
) => {
    callAPI(
        "addNewBank",
        API.bankDetails,
        "POST",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const editBank = (
    request,
    callback
) => {
    callAPI(
        "editBank",
        API.bankDetails,
        "PUT",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const editBankState = (
    request,
    callback
) => {
    callAPI(
        "editBankState",
        API.bankState,
        "PATCH",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const getBankListAssociatedToAccount = (
    request, callback
) => {
    callAPI(
        "getBankListAssociatedToAccount",
        API.bankListAssociatedToAccount + "?accountId=" + request,
        "GET",
        {},
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getInvoiceAction = (request, callback) => {
  callAPI(
    "getInvoice",
    API.getInvoice,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const approvedInvoiceAction = (request, callback) => {
  callAPI(
    "approveInvoice",
    API.approveInvoice,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

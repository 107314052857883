import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getProductListAction = (reqObj, callback) => {
  callAPI(
    "getMtpProducts",
    API.getMtpProducts,
    "GET",
    //API accomodates for pagination, it is not included in UI as the system does not have enough products currently
    {
      //search: {
      ...reqObj,
      //},
      lmt: 50,
      oft: 0,
    },
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

/* This code is currently inaccessible as edit feature is on hold
export const updateProductAction = (reqObj, callback) => {
  callAPI(
    "updateMtpProduct",
    API.updateMtpProduct,
    "PATCH",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};
*/

import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fileDownload from "js-file-download";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import SecondLevelReports from "./SecondLevelReports";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import withHOC from "../../HOC/HOC";

//Actions
import {
  dowloadedAction,
  setSearchFilterAction,
  setRowClickAction,
} from "../../constants/Action";
import {
  getFirstLevelReportsDataAction,
  getUserReportsProfileAction,
  setTwoStepAction,
} from "../GeneralReports/GeneralReportsAction";
import { setUserAction } from "../UserList/UserListAction";

// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { LIST_SECOND_REPORTS } from "../GeneralReports/Constants";
import { setLoanDetailsIDAction } from "../LoanDetails/LoanDetailsAction";
import {
  SPECIAL,
  SPECIAL_MESSENGER_PRESENTATION,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  REPORTS_TAB_KEY_CONST,
  SEARCH_FILTER_PAGES,
  FLR_SEARCH_FILTER,
  SLR_SEARCH_FILTER,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

class SecondLevelReportsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isUploadSuccess: false,
      keysArray: LIST_SECOND_REPORTS[this.props.user].keysArray,
      sumKeysArray: LIST_SECOND_REPORTS[this.props.user].sumKeysArray,
      records: [],
      errorMessage: props.errorMessage,
      isError: false,
      level: "",
      rows: [],
      sumRows: [],
      user: null,
      users: [],
      maxDays: 10,
    };
    this.APIProcessingCount = 0;
    this.isCardClick = false;
  }

  componentDidMount() {
    this.props.user !== REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO &&
      this.props.user !== REPORTS_TAB_KEY_CONST.WALLET &&
      this.props.user !== REPORTS_TAB_KEY_CONST.WEB_COLLECTIONS &&
      this.getUserReportsProfile(this.props.reports.un);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  componentWillUnmount() {
    if (
      (this.props.searchPage ===
        SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT ||
        this.props.searchPage ===
        SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT) &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      if (!this.isCardClick) {
        this.props.setSearchFilterAction(
          SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT,
          { ...FLR_SEARCH_FILTER }
        );
      }
    }
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT,
        { ...SLR_SEARCH_FILTER },
        SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT
      );
    }
  }

  getUserReportsProfile = (userName) => {
    this.setState({ showLoader: true });
    getUserReportsProfileAction(userName, (apiResponse) => {
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          user: apiResponse.data.data,
          showLoader: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: apiResponse.error.message,
          showLoader: false,
        });
      }
    });
  };

  initErrorStates = () => {
    this.setState({ errorMessage: "" });
  };

  callbackOnRowClick = (row) => {
    if (row !== undefined) {
      let loanDetails = {
        applicationId: row.userPreservedData.appId,
        loanType: row.userPreservedData.ty,
        loanId: row.userPreservedData.id
          ? row.userPreservedData.id
          : null,
      };
      this.isCardClick = true;
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT
      );
      this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO &&
        this.props.setRowClickAction(
          row.userPreservedData.id,
          SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT
        );
      this.props.setLoanDetailsIDAction(loanDetails);
      this.props.callbackOnRowClick(row);
    }
  };

  setError = (errorMessage) => {
    this.setState(
      {
        showLoader: this.APIProcessingCount !== 0,
        errorMessage: errorMessage,
      }
    );
  };

  getFirstLevelReportsDataCallback = (
    url,
    requestData,
    isSumUrl = false
  ) => {
    getFirstLevelReportsDataAction(url, requestData, (response) => {
      this.APIProcessingCount -= 1;
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (!isSumUrl) {
          this.setState({
            numberOfPages: response.data.data.np,
            errorMessage: "",
            maxDays: response.data.data.md
              ? response.data.data.md
              : 10,
          });
          this.setError("");
          this.createRows(response.data.data.rp, isSumUrl);
        } else {
          this.setError("");
          this.createRows(response.data.data, isSumUrl);
        }
      } else {
        this.setError(response.error.message);
      }
    });
  };

  handleSearchFilter = (
    requestUrl,
    sumUrl,
    searchFilterObject,
    pagination,
  ) => {
    if (this.props.reports !== undefined && this.props.reports) {
      searchFilterObject["id"] = this.props.reports.id;
    }
    this.setState({
      showLoader: true,
      isError: false,
      searchFilter: {
        fd: searchFilterObject.fromDate,
        td: searchFilterObject.toDate,
        br: searchFilterObject.branch,
        zn: searchFilterObject.zone,
        ty: searchFilterObject.type,
        mid: searchFilterObject.matricesId,
        code: searchFilterObject.ciCode,
        pr: searchFilterObject.provision,
        st: searchFilterObject.status,
        lid: searchFilterObject.loanId,
        fad: searchFilterObject.fromAmountDisbursed,
        tad: searchFilterObject.toAmountDisbursed,
        uid: searchFilterObject.userId,
        limit: pagination.limit,
        offset: pagination.offset,
        appId: searchFilterObject.applicationId,
        dal: searchFilterObject.dayInArrears,
      },
    });
    const summarySearchFilter = {
      search: { ...searchFilterObject },
    };
    const loanCountFilter = {
      search: { ...searchFilterObject },
      limit: pagination.limit,
      offset: pagination.offset,
    };
    this.APIProcessingCount += 1;
    this.getFirstLevelReportsDataCallback(
      requestUrl,
      loanCountFilter,
      false
    );
    if (sumUrl) {
      this.APIProcessingCount += 1;
      this.getFirstLevelReportsDataCallback(
        sumUrl,
        summarySearchFilter,
        true
      );
    } else {
      this.setError("");
    }
  };

  createRows = (responseData, isSumUrl) => {
    //Row create for each element in response data array
    let rows = [];
    if (!isSumUrl) {
      _.forEach(responseData, (reportsDetails) => {
        let tempObj = {};
        this.mapStateKeysArray(tempObj, reportsDetails);
        rows.push(tempObj);
      });
      this.setState({ rows: rows });
    } else {
      if (responseData) {
        let tempRows = [];
        tempRows.push(responseData);
        _.forEach(tempRows, (reportsDetails) => {
          let tempObj = {};
          this.mapSumKeysArray(tempObj, reportsDetails);
          rows.push(tempObj);
        });
      }
      this.setState({ sumRows: rows });
    }
  };

  mapStateKeysArray = (tempObj, reportsDetails) => {
    this.state.keysArray.forEach((key) => {
      //NOSONAR
      if (Array.isArray(key) && key.length > 0) {
        if (key.length > 3) {
          tempObj[key[0]] =
            Utility.getCurrencyRepresentationOfAmount(
              reportsDetails[key[0]]
            ) +
            " / " +
            Utility.getCurrencyRepresentationOfAmount(
              reportsDetails[key[1]]
            ) +
            " / " +
            Utility.getCurrencyRepresentationOfAmount(
              reportsDetails[key[2]]
            );
        } else if (key.length === 3) {
          if (key[2].isAmount) {
            tempObj[key[0]] =
              Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[0]]
              ) +
              " / " +
              Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[1]]
              );
          } else if (reportsDetails[key[1]] === SPECIAL) {
            tempObj[key[0]] = (
              <div
                title={
                  reportsDetails[key[0]] +
                  " " +
                  SPECIAL_MESSENGER_PRESENTATION
                }
              >
                {SHORT_SPECIAL_MESSENGER_PRESENTATION +
                  " " +
                  reportsDetails[key[0]]}{" "}
              </div>
            );
          } else {
            tempObj[key[0]] = reportsDetails[key[0]];
          }
        } else {
          tempObj[key[0]] = key[1].isAmount
            ? Utility.getCurrencyRepresentationOfAmount(
              reportsDetails[key[0]]
            )
            : this.renderReportDetails(reportsDetails, key);
        }
      } else if (key === "userPreservedData") {
        tempObj[key] = reportsDetails;
      } else if (key === "ae") {
        tempObj[key] = reportsDetails[key]
          ? "$" + Utility.getCurrencyRepresentationOfAmount(reportsDetails[key])
          : Strings("NA");
      } else {
        tempObj[key] = reportsDetails[key];
      }
      if (tempObj[key] === undefined || tempObj[key] === null) {
        tempObj[key] = Strings("NA");
      }
      if (tempObj["ms"] === undefined || tempObj["ms"] === null) {
        tempObj["ms"] = Strings("NA");
      }
    });
  };
  mapSumKeysArray = (tempObj, reportsDetails) => {
    this.state.sumKeysArray.forEach((key) => {
      if (Array.isArray(key) && key.length > 0) {
        if (key.length > 3) {
          tempObj[key[0]] =
            Utility.getCurrencyRepresentationOfAmount(
              reportsDetails[key[0]]
            ) +
            " / " +
            Utility.getCurrencyRepresentationOfAmount(
              reportsDetails[key[1]]
            ) +
            " / " +
            Utility.getCurrencyRepresentationOfAmount(
              reportsDetails[key[2]]
            );
        } else {
          tempObj[key[0]] = key[2].isAmount
            ? Utility.getCurrencyRepresentationOfAmount(
              reportsDetails[key[0]]
            ) +
            " / " +
            Utility.getCurrencyRepresentationOfAmount(
              reportsDetails[key[1]]
            )
            : reportsDetails[key[0]] + " / " + reportsDetails[key[1]];
        }
      } else {
        tempObj[key] = reportsDetails[key];
      }
    });
  };

  setLoanDetails = (loanDetails) => {
    this.props.setLoanDetailsIDAction(loanDetails);
  };

  /**
   * Method to download second level reports
   * @param {*} url
   * @param {*} searchFilterObject
   */
  downloadReports = (url, searchFilterObject, isDownloadFile) => {
    if (this.props.reports !== undefined && this.props.reports) {
      searchFilterObject["search"]["id"] = this.props.reports.id;
    }
    this.setState({ showLoader: true, isError: false });
    dowloadedAction(
      url,
      searchFilterObject,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (!isDownloadFile) {
            this.setState({
              showLoader: false,
              isError: true,
              errorMessage: Strings("pdfDownload"),
              level: "success",
            });
          } else if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
            fileDownload(
              response.data,
              Strings(this.props.user) +
              "-" +
              Utility.getReportFormattedDateString(
                Utility.convertDateIntoMiliSeconds(new Date())
              ) +
              Utility.getFileType(searchFilterObject.search.file)
            );
            this.setState({
              errorMessage: "",
              showLoader: false,
              isError: false,
            });
          } else {
            this.setState({
              showLoader: false,
              isError: true,
              errorMessage: Strings("NoRecordsFound"),
              level: "error",
            });
          }
        } else {
          this.setState({
            showLoader: false,
            errorMessage: response.error.message,
          });
        }
      },
      "Reports",
      isDownloadFile
    );
  };

  handleReportRowClick = (row) => {
    (this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO ||
      this.props.user === REPORTS_TAB_KEY_CONST.WALLET) &&
      this.callbackOnRowClick(row);
  };

  handleSetLoanDetails = () => {
    return this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO ||
      this.props.user === REPORTS_TAB_KEY_CONST.WALLET
      ? this.setLoanDetails
      : () => {
        /*This is intentional*/
      };
  };

  getUserList = (searchFilterObject) => {
    this.setState({ showLoader: true });
    const stateObject = Utility.getUserList(
      searchFilterObject,
      this.props.setUserAction
    );
    this.setState(stateObject);
  };

  doNothing = () => {
    // This is intentional
  };
  render() {
    let onRowClick = this.doNothing;
    let setLoanDetails = this.setLoanDetails;
    if (
      this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO ||
      this.props.user === REPORTS_TAB_KEY_CONST.WALLET
    ) {
      onRowClick = this.callbackOnRowClick;
      setLoanDetails = this.setLoanDetails;
    }
    return (
      <div className='firstLevelReports'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isError && (
          <UserNotification
            // key='Error'
            userNotificationObj={{
              message: Strings(this.state.errorMessage),
              level: this.state.level,
            }}
          />
        )}
        <SecondLevelReports
          user={this.props.user}
          zones={this.props.zones}
          mZones={this.props.mZones}
          handleSearchFilter={this.handleSearchFilter}
          rows={this.state.rows}
          onRowClick={onRowClick}
          columns={this._columns}
          rowsCount={this.state.rows.length}
          rowHeight={60}
          headerRowHeight={55}
          setLoanDetails={setLoanDetails}
          goToAllLoanReports={this.props.goToAllLoanReports}
          goToSingleLoanReports={this.props.goToSingleLoanReports}
          userInfo={this.state.user}
          branches={this.props.branches}
          numberOfPages={this.state.numberOfPages}
          getBranchList={this.props.getBranchList}
          goBack={this.props.goBack}
          getZonesByBranch={this.props.getZonesByBranch}
          reports={this.props.reports}
          downloadReports={this.downloadReports}
          setTwoStepAction={this.props.setTwoStepAction}
          isTwoStepAction={this.props.isTwoStepAction}
          getUserList={this.getUserList}
          users={this.props.users}
          searchFilter={this.props.slrSearchFilter}
          flrSearchFilter={this.props.flrSearchFilter}
          sumRows={this.state.sumRows}
          loan={this.props.loan}
          setRowClickAction={this.props.setRowClickAction}
          searchPage={this.props.searchPage}
          maxDays={this.state.maxDays}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.ReportsReducer.reports,
  isTwoStepAction: state.ReportsReducer.isTwoStepAction,
  users: state.userListReducer.users.users,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
  flrSearchFilter: state.searchFilterReducer.flrSearchFilter,
  loan: state.searchFilterReducer.loan,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoanDetailsIDAction,
      setTwoStepAction,
      setUserAction,
      setSearchFilterAction,
      setRowClickAction,
    },
    dispatch
  );
};

const SecondLevelReportsWrapper = withHOC(
  SecondLevelReportsContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondLevelReportsWrapper);

import React from "react";
import { Col, Row } from "react-bootstrap";

//Strings
import { Strings } from "../../../resources/i18n/i18n";
// Utils
import Utility from "../../../utils/Utility";

const CustomBarChartToolTip = ({ active, payload, label }) => {
  if (active && payload && payload.length > 0) {
    return (
      <Row className='customBarToolTip'>
        <Col md={12}>{label}</Col>
        {payload.map((legend) => {
          return (
            <Col md={12} className={legend.name} key={legend.name}>
              {Strings(legend.name) + " ($)"} :{" "}
              {Utility.getCurrencyRepresentationOfAmount(
                legend.value
              )}
            </Col>
          );
        })}
      </Row>
    );
  }

  return null;
};

export default CustomBarChartToolTip;

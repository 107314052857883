import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fileDownload from "js-file-download";

//Components
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import FirstLevelReports from "./FirstLevelReports";
import withHOC from "../../HOC/HOC";

//Actions
import {
  getFirstLevelReportsDataAction,
  SetReportsDetailsAction,
} from "../GeneralReports/GeneralReportsAction";
import {
  dowloadedAction,
  setSearchFilterAction,
} from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { LIST_FIRST_REPORTS } from "../GeneralReports/Constants";
import {
  ZONE_ACCESS_USER,
  SPECIAL_MESSENGER_PRESENTATION,
  SPECIAL,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  REPORTS_TAB_KEY_CONST,
  SEARCH_FILTER_PAGES,
  FLR_SEARCH_FILTER,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./FirstLevelReports.scss";

class FirstLevelReportsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isUploadSuccess: false,
      keysArray: LIST_FIRST_REPORTS[this.props.user].keysArray,
      sumKeysArray: LIST_FIRST_REPORTS[this.props.user].sumKeysArray,
      records: [],
      errorMessage: props.errorMessage,
      isError: false,
      level: "",
      rows: [],
      sumRows: [],
      filters: null,
      LOAN_PORTFOLIO: {
        zone: "",
        invoiceCount: "",
        loanCount: "",
      },
      SALES: {
        salesPerson: "",
        loanAmount: "",
      },
      COLLECTIONS: {
        messenger: "",
        amount: "",
      },
      maxDays: 10,
    };
    this.APIProcessingCount = 0;
    this.isCardClick = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT,
        { ...FLR_SEARCH_FILTER }
      );
    }
  }

  initErrorStates = () => {
    this.setState({ errorMessage: "" });
  };

  callbackOnRowClick = (row) => {
    if (row !== undefined) {
      this.isCardClick = true;
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT
      );
      this.props.SetReportsDetailsAction(row.userPreservedData ? row.userPreservedData : row);
      this.props.callbackOnRowClick(row);
    }
  };

  setError = (errorMessage) => {
    this.setState(
      {
        showLoader: this.APIProcessingCount !== 0,
        errorMessage: errorMessage,
      }
    );
  };

  getFirstLevelReportsDataCallback = (
    url,
    requestData,
    isSumUrl = false
  ) => {
    getFirstLevelReportsDataAction(url, requestData, (response) => {
      this.APIProcessingCount -= 1;
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (!isSumUrl) {
          this.setState({
            numberOfPages: response.data.data.np,
            errorMessage: "",
            maxDays: response.data.data.md
              ? response.data.data.md
              : 10,
          });
          this.setError("");
          this.createRows(response.data.data.rp, isSumUrl);
        } else {
          this.setError("");
          this.createRows(response.data.data, isSumUrl);
        }
      } else {
        this.setError(response.error.message);
      }
    });
  };

  handleSearchFilter = (
    requestUrl,
    requestSumUrl,
    searchFilterObject,
    pagination,
  ) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        ifd: searchFilterObject.fromInvoiceDate,
        itd: searchFilterObject.toInvoiceDate,
        fic: searchFilterObject.fromInvoiceCount,
        tic: searchFilterObject.toInvoiceCount,
        flc: searchFilterObject.fromLoanCount,
        tlc: searchFilterObject.toLoanCount,
        br: searchFilterObject.branch,
        zn: searchFilterObject.zone,
        ty: searchFilterObject.type,
        fd: searchFilterObject.fromLoanDate
          ? searchFilterObject.fromLoanDate
          : searchFilterObject.fromDate,
        td: searchFilterObject.toLoanDate
          ? searchFilterObject.toLoanDate
          : searchFilterObject.toDate,
        limit: pagination.limit,
        offset: pagination.offset,
        un: searchFilterObject.name,
        la: searchFilterObject.lowerAmount,
        ua: searchFilterObject.upperAmount,
        mid: searchFilterObject.matricesId,
        ciid: searchFilterObject.ciCode,
        mn: searchFilterObject.minimartName,
        gr: searchFilterObject.grade,
      },
    });
    const summarySearchFilter = { search: { ...searchFilterObject } };
    const loanCountFilter = {
      search: { ...searchFilterObject },
      limit: pagination.limit,
      offset: pagination.offset,
    };
    this.APIProcessingCount += 1;
    this.getFirstLevelReportsDataCallback(
      requestUrl,
      loanCountFilter,
      false
    );
    this.APIProcessingCount += 1;
    this.getFirstLevelReportsDataCallback(
      requestSumUrl,
      summarySearchFilter,
      true
    );
  };

  createRows = (responseData, isSumUrl) => {
    //Row create for each element in response data array
    let rows = [];
    if (!isSumUrl) {
      this.createResponseRows(responseData, rows);
    } else {
      if (responseData) {
        let tempRows = [];
        tempRows.push(responseData);
        this.createSumRows(tempRows, rows);
      } else {
        this.setState({ sumRows: [] });
      }
    }
  };

  createResponseRows = (responseData, rows) => {
    _.forEach(responseData, (reportsDetails) => {
      //NOSONAR
      let tempObj = {};
      this.state.keysArray.forEach((key) => {
        if (Array.isArray(key) && key.length > 0) {
          if (key.length === 4) {
            tempObj[key[0]] = key[3].isAmount
              ? Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[0]]
              ) +
              " / " +
              Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[1]]
              ) +
              " / " +
              Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[2]]
              )
              : this.renderReportDetails(reportsDetails, key);
          } else if (key.length === 3) {
            tempObj[key[0]] = key[2].isAmount
              ? Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[0]]
              ) +
              " / " +
              Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[1]]
              )
              : this.renderReportDetails(reportsDetails, key);
          } else {
            tempObj[key[0]] = key[1].isAmount
              ? Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[0]]
              )
              : this.renderReportDetails(reportsDetails, key);
          }
        } else if (key === "userPreservedData") {
          tempObj[key] = reportsDetails;
        } else {
          tempObj[key] = reportsDetails[key];
        }
      });
      rows.push(tempObj);
    });
    this.setState({ rows: rows });
  };
  createSumRows = (tempRows, rows) => {
    _.forEach(tempRows, (reportsDetails) => {
      //NOSONAR
      let tempObj = {};
      this.state.sumKeysArray.forEach((key) => {
        if (Array.isArray(key) && key.length > 0) {
          if (key.length === 4) {
            tempObj[key[0]] = key[3].isAmount
              ? Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[0]]
              ) +
              " / " +
              Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[1]]
              ) +
              " / " +
              Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[2]]
              )
              : this.renderReportDetails(reportsDetails, key);
          } else if (key.length === 3) {
            tempObj[key[0]] = key[2].isAmount
              ? Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[0]]
              ) +
              " / " +
              Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[1]]
              )
              : this.renderReportDetails(reportsDetails, key);
          } else {
            tempObj[key[0]] = key[1].isAmount
              ? Utility.getCurrencyRepresentationOfAmount(
                reportsDetails[key[0]]
              )
              : this.renderReportDetails(reportsDetails, key);
          }
        } else {
          tempObj[key] = reportsDetails[key];
        }
      });
      rows.push(tempObj);
    });

    this.setState({ sumRows: rows });
  };

  /**
   * Method to download First Level reprots
   * @param {*} url
   * @param {*} searchFilterObject
   */
  downloadReports = (url, searchFilterObject, isDownloadFile) => {
    this.setState({ showLoader: true, isError: false });
    dowloadedAction(
      url,
      searchFilterObject,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (!isDownloadFile) {
            this.setState({
              showLoader: false,
              isError: true,
              errorMessage: Strings("pdfDownload"),
              level: "success",
            });
          } else if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
            fileDownload(
              response.data,
              Strings(this.props.user) +
              "-" +
              Utility.getReportFormattedDateString(
                Utility.convertDateIntoMiliSeconds(new Date())
              ) +
              Utility.getFileType(searchFilterObject.search.file)
            );
            this.setState({
              errorMessage: "",
              showLoader: false,
              isError: false,
            });
          } else {
            this.setState({
              showLoader: false,
              isError: true,
              errorMessage: Strings("NoRecordsFound"),
              level: "error",
            });
          }
        } else {
          this.setState({
            showLoader: false,
            errorMessage: response.error.message,
          });
        }
      },
      "Reports",
      isDownloadFile
    );
  };

  //render
  renderFirstLevelReports = (
    rowHeight,
    headerRowHeight,
    minWidth
  ) => {
    return ZONE_ACCESS_USER.includes(this.props.loggedInUser.role) &&
      this.props.zones &&
      this.props.zones.length >= 1 && this.props.mZones &&
      this.props.mZones.length >= 1 ? (
      <FirstLevelReports
        user={this.props.user}
        zones={this.props.zones}
        mZones={this.props.mZones}
        handleSearchFilter={this.handleSearchFilter}
        rows={this.state.rows}
        onRowClick={this.callbackOnRowClick}
        columns={this._columns}
        rowsCount={this.state.rows.length}
        rowHeight={rowHeight}
        headerRowHeight={headerRowHeight}
        minWidth={minWidth}
        goToAllLoanReports={this.props.goToAllLoanReports}
        goToSingleLoanReports={this.props.goToSingleLoanReports}
        branches={this.props.branches}
        numberOfPages={this.state.numberOfPages}
        getBranchList={this.props.getBranchList}
        getZonesByBranch={this.props.getZonesByBranch}
        downloadReports={this.downloadReports}
        filters={this.props.filters}
        searchFilter={this.props.flrSearchFilter}
        sumRows={this.state.sumRows}
        maxDays={this.state.maxDays}
      />
    ) : (
      <FirstLevelReports
        user={this.props.user}
        zones={this.props.zones}
        mZones={this.props.mZones}
        handleSearchFilter={this.handleSearchFilter}
        rows={this.state.rows}
        onRowClick={this.callbackOnRowClick}
        columns={this._columns}
        rowsCount={this.state.rows.length}
        rowHeight={rowHeight}
        headerRowHeight={headerRowHeight}
        goToAllLoanReports={this.props.goToAllLoanReports}
        goToSingleLoanReports={this.props.goToSingleLoanReports}
        branches={this.props.branches}
        numberOfPages={this.state.numberOfPages}
        getBranchList={this.props.getBranchList}
        getZonesByBranch={this.props.getZonesByBranch}
        downloadReports={this.downloadReports}
        searchFilter={this.props.flrSearchFilter}
        sumRows={this.state.sumRows}
        maxDays={this.state.maxDays}
      />
    );
  };

  renderReportDetails = (reportsDetails, key) => {
    return reportsDetails[key[1]] === SPECIAL ? (
      <span
        title={
          reportsDetails[key[0]] +
          " " +
          SPECIAL_MESSENGER_PRESENTATION
        }
      >
        {SHORT_SPECIAL_MESSENGER_PRESENTATION +
          " " +
          reportsDetails[key[0]]}{" "}
      </span>
    ) : (
      reportsDetails[key[0]]
    );
  };

  getHeaderRowHeight = () => {
    return this.props.user !== REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO
      ? 80
      : 56;
  };

  render() {
    return (
      <div className='firstLevelReports'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        {this.state.isError && (
          <UserNotification
            // key='Error'
            userNotificationObj={{
              message: Strings(this.state.errorMessage),
              level: this.state.level,
            }}
          />
        )}
        {this.renderFirstLevelReports(
          60,
          this.getHeaderRowHeight(),
          this.props.user === REPORTS_TAB_KEY_CONST.LOAN_PORTFOLIO &&
          700
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.ReportsReducer.filters,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      SetReportsDetailsAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

const FirstLevelReportsWrapper = withHOC(FirstLevelReportsContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstLevelReportsWrapper);

//Libraries
import React, { Component } from "react";
//Components
import MotorcycleCatalogueModal from "./MotorcycleCatalogueModal";

class MotorcycleCatalogueModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: true,
    };
  }

  handleModalClose = () => {
    this.setState(
      {
        modalOpen: false,
      },
      () => {
        this.props.closeModal();
      }
    );
  };

  render() {
    return (
      <MotorcycleCatalogueModal
        id={this.props.id}
        category={this.props.category}
        name={this.props.name}
        model={this.props.model}
        status={this.props.status}
        cost={this.props.cost}
        photo={this.props.photo}
        desc={this.props.desc}
        bid={this.props.bid}
        avatar={this.props.avatar}
        categoryList={this.props.categoryList}
        statusList={this.props.statusList}
        modalOpen={this.state.modalOpen}
        handleModalClose={this.handleModalClose}
        handleChange={this.props.handleChange}
        handleUploadPhoto={this.props.handleUploadPhoto}
        handleSaveClick={this.props.handleSaveClick}
        readOnly={this.props.readOnly}
        headerText={this.props.headerText}
        errorMessage={this.props.errorMessage}
        removeImage={this.props.removeImage}
        branches={this.props.branches}
        MOTORCYCLE={this.props.MOTORCYCLE}
      />
    );
  }
}
export default MotorcycleCatalogueModalContainer;

import { Strings } from "../../../resources/i18n/i18n";
import { colors } from "../../../resources/styles/themes";

export const POSITION = {
  LEFT: "left",
  RIGHT: "right",
};
export const MINIMART_CARD = ["id", "code", "name", "zone","messengerZone"];
export const NEW_MINIMART_CARD = ["code", "name", "zone","messengerZone"];

export const CI_MINIMART_CARD = [
  { key: "mn", title: Strings("MiniMartname") },
  { key: "cd", title: Strings("ciId") },
];

export const SIGNUP_REQUEST_CARDS = [
  { key: "mn", title: Strings("MiniMartname") },
  { key: "cdid", title: Strings("ownerCedulaNumber") },
];

export const PROMOTION_APPROVAL_CARDS = [
  { key: "prbty", title: Strings("benefitType") },
  { key: "mid", title: Strings("MatricesID") },
  { key: "mn", title: Strings("MiniMartname") },
  { key: "prt", title: Strings("promotionName") },
  { key: "un", title: Strings("username") },
];
export const ANALYST_PROMOTION_APPROVAL_CARDS = [
  { key: "prbty", title: Strings("benefitType") },
  { key: "mid", title: Strings("MatricesID") },
  { key: "mn", title: Strings("MiniMartname") },
  { key: "prt", title: Strings("promotionName") },
];

export const PROMOTION_REQUEST_TYPE_LEGEND = {
  APPROVED: {
    key: "APPROVED",
    color: colors.legendColor2,
    value: "APPROVED",
  },
  REJECTED: {
    key: "REJECTED",
    color: colors.legendColor1,
    value: "REJECTED",
  },
  PENDING: {
    key: "PENDING",
    color: colors.legendColor3,
    value: "PENDING",
  },
};

export const REWARD_APPROVAL_CARD = [
  { key: "rpn", title: Strings("ProductName") },
  { key: "rpm", title: Strings("ProductModel") },
  { key: "mn", title: Strings("MiniMartname") },
  { key: "rp", title: Strings("rewardPoints") },
  { key: "oon", title: Strings("operationOfficerName") },
];

export const REWARD_HISTORYL_CARD = [
  { key: "rpn", title: Strings("ProductName") },
  { key: "rpm", title: Strings("ProductModel") },
  { key: "rp", title: Strings("rewardPoints") },
  { key: "ret", title: Strings("rewardType") },
  { key: "rr", title: Strings("rewardReason") },
  { key: "rdt", title: Strings("Date") },
];

export const REWARD_TYPE_LEGEND = {
  SPENT: {
    key: 0,
    value: "SPENT",
    color: colors.legendColor3,
  },
  EARNED: {
    key: 1,
    value: "EARNED",
    color: colors.legendColor2,
  },
};

export const INVOICE_STATUS_LEGEND = {
  APPROVED: {
    key: "APPROVED",
    color: colors.legendColor2,
    value: "APPROVED",
  },
  REJECT: {
    key: "REJECT",
    color: colors.legendColor1,
    value: "REJECT",
  },
  PENDING: {
    key: "PENDING",
    color: colors.legendColor3,
    value: "PENDING",
  },
  ASSIGNED: {
    key: "ASSIGNED",
    color: colors.legendColor5,
    value: "ASSIGNED",
  },
  NEW: {
    key: "NEW",
    color: colors.legendColor5,
    value: "NEW",
  },
  ACTIVE: {
    key: "ACTIVE",
    color: colors.legendColor5,
    value: "ACTIVE",
  },
  IN_PROGRESS: {
    key: "IN_PROGRESS",
    color: colors.legendColor5,
    value: "IN_PROGRESS",
  },
  REJECTED: {
    key: "REJECTED",
    color: colors.legendColor5,
    value: "REJECTED",
  },
  EXPIRED: {
    key: "EXPIRED",
    color: colors.legendColor5,
    value: "IN_PROGRESS",
  },
  NO_ACTION: {
    key: "NO_ACTION",
    color: colors.legendColor5,
    value: "NO_ACTION",
  },
  READY_TO_DISBURSE: {
    key: "READY_TO_DISBURSE",
    color: colors.legendColor5,
    value: "READY_TO_DISBURSE",
  },
};

//libraries
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import _ from "lodash";

// Components
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CustomerTypeLegend from "../CustomUIComponents/CustomerTypeLegend/CustomerTypeLegend";

// Constants
import {
  MINIMART_MANAGEMENT_TYPE_OF_CUSTOMER,
  DEFAULT_TYPE_OF_CUSTOMER_SELECTION,
  MINIMART_GRADE,
  MINIMART_MANAGEMENT_TYPE_LEGEND,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  MINIMART_TYPE,
  MINIMART_STATUSES,
  MINIMART_REGISTRATION_STATUS,
  MINIMART_LEVELS,
} from "../../constants/appConstants";
import {
  GET_DEFAULT_ZONE_SELECTION,
  DEFAULT_ZONE,
  DEFAULT_MESSENGER_ZONE,
} from "../BranchList/BranchListConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

import { IS_SCALA_REGISTERED, MINIMART_REPORTS_DOWNLAOD } from "./Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./MinimartList.scss";

//Assets
import CSVdownload from "../../resources/images/ic_download_csv.svg";
import PDFdownload from "../../resources/images/ic_download_pdf.svg";

class MinimartList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      errorMessage: "",
      zones: this.props.zones,
      mZones: this.props.mZones,
      ciId: this.props.searchFilter.code,
      matricesId: this.props.searchFilter.mid,
      level: this.props.searchFilter.lvl,
      zone: this.props.searchFilter.zn,
      messengerZone: this.props.searchFilter.mzn,
      grade: this.props.searchFilter.gr,
      typeOfCustomer: this.props.searchFilter.toc,
      minimartName: this.props.searchFilter.mn,
      supplierType: this.props.searchFilter.mst,
      businessType: this.props.searchFilter.bt,
      minimartStatus: this.props.searchFilter.st,
      registrationStatus: this.props.searchFilter.rs,
      cedulaId: this.props.searchFilter.oc,
      recordsPerPage: this.props.searchFilter.limit,
      activePage: this.props.searchFilter.offset,
      isNewMinimart: this.props.isNewMinimart,
      zonelist: this.props.searchFilter.zones,
      messengerZones: this.props.searchFilter.messengerZones,
      isScalaRegistered: this.props.searchFilter.isr,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }
    if (prevProps.isNewMinimart !== this.props.isNewMinimart) {
      this.setState(
        {
          ciId: this.props.searchFilter.code,
          matricesId: this.props.searchFilter.mid,
          level: this.props.searchFilter.lvl,
          zone: this.props.searchFilter.zn,
          messengerZone: this.props.searchFilter.mzn,
          grade: this.props.searchFilter.gr,
          typeOfCustomer: this.props.searchFilter.toc,
          minimartName: this.props.searchFilter.mn,
          supplierType: this.props.searchFilter.mst,
          businessType: this.props.searchFilter.bt,
          minimartStatus: this.props.searchFilter.st,
          registrationStatus: this.props.searchFilter.rs,
          cedulaId: this.props.searchFilter.oc,
          recordsPerPage: this.props.searchFilter.limit,
          activePage: this.props.searchFilter.offset,
          isNewMinimart: this.props.isNewMinimart,
          zonelist: this.props.searchFilter.zones,
          isScalaRegistered: this.props.searchFilter.isr,
        },
        () => {
          this.handleOnClickSearchFilter(false);
        }
      );
    }
  }

  handleSearchNameChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSearchCICodeChange = (event) => {
    this.setState({ ciId: event.target.value });
  };

  handlesupplierTypeChange = (event) => {
    this.setState({
      supplierType: event.target.value,
    });
  };

  handleStatusChange = (event) => {
    this.setState({
      minimartStatus:
        event.target.value !== Strings("SelectStatus")
          ? event.target.value
          : "",
    });
  };

  handleSearchMatricesIdChange = (event) => {
    this.setState({ matricesId: event.target.value });
  };

  handleLevelChange = (event) => {
    this.setState({
      level:
        event.target.value === Strings("minimartLevel")
          ? ""
          : event.target.value,
    });
  };

  handleGradeChange = (event) => {
    if (event.target.value !== Strings("selectMiniMartGrade")) {
      this.setState({ grade: event.target.value });
    } else {
      this.setState({
        grade: "",
      });
    }
  };

  handleSelectedZoneChange = (event) => {
    if (
      event.target.value !== DEFAULT_ZONE ||
      event.target.value !== DEFAULT_MESSENGER_ZONE
    ) {
      this.setState({
        [event.target.name]: event.target.value,
        zonelist: [],
      });
    } else {
      this.setState({ [event.target.name]: "", zonelist: [] });
    }
  };

  handleTypeOfCustomerChange = (event) => {
    if (
      event.target.value !== DEFAULT_TYPE_OF_CUSTOMER_SELECTION.label
    ) {
      this.setState({
        typeOfCustomer: event.target.value,
      });
    } else {
      this.setState({ typeOfCustomer: "" });
    }
  };

  handleBusinessTypeChange = (event) => {
    this.setState({
      businessType:
        event.target.value !== Strings("BusinessType")
          ? event.target.value
          : "",
    });
  };

  handleRegistrationStatusChange = (event) => {
    this.setState({
      registrationStatus:
        event.target.value !== Strings("registrationStatus")
          ? event.target.value
          : "",
    });
  };

  handleScalaRegistrationFlag = (event) => {
    this.setState({
      isScalaRegistered:
        event.target.value !== "AllMinimarts"
          ? event.target.value
          : null,
    });

  }

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  resetSearchValues = () => {
    this.setState(
      {
        ciId: "",
        matricesId: "",
        level: "",
        zone: "",
        messengerZone: "",
        grade: "",
        typeOfCustomer: "",
        minimartName: "",
        supplierType: "ALL",
        businessType: "",
        minimartStatus: "",
        registrationStatus: "",
        cedulaId: "",
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        zonelist: [],
        isScalaRegistered: null
      },
      () => {
        this.handleOnClickSearchFilter(true);
      }
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleOnClickSearchFilter({
      search: {
        minimartName: this.state.minimartName,
        zone:
          this.state.zone !== DEFAULT_ZONE ? this.state.zone : null,
        messengerZone:
          this.state.messengerZone !== DEFAULT_MESSENGER_ZONE
            ? this.state.messengerZone
            : null,
        ciId: this.state.ciId,
        matricesId:
          this.state.matricesId !== ""
            ? this.state.matricesId
            : undefined,
        level: this.state.level !== "" ? this.state.level : undefined,
        type: this.state.typeOfCustomer,
        supplierType: this.state.supplierType,
        grade: this.state.grade,
        businessType: this.state.businessType,
        minimartStatus: this.state.minimartStatus,
        registrationStatus: this.state.registrationStatus,
        cedulaId: this.state.cedulaId,
        zones:
          (this.state.zonelist && this.state.zonelist.length === 0) ||
            this.state.zonelist === null
            ? undefined
            : this.state.zonelist,
        messengerZones:
          (this.state.messengerZones && this.state.messengerZones.length === 0) ||
            this.state.messengerZones === null
            ? undefined
            : this.state.messengerZones,
        isScalaRegistered: this.state.isScalaRegistered || undefined,
      },
      pagination: {
        limit: this.state.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.activePage,
      },
    });
  };

  setMiniMartDetails = (minimart) => {
    this.props.setMiniMartDetails(minimart);
  };

  downloadFile = (filetype) => {
    const requestData = {
      search: {
        ciId: this.state.ciId || undefined,
        matricesId:
          this.state.matricesId || undefined,
        minimartName: this.state.minimartName || undefined,
        grade: this.state.grade || undefined,
        zone: this.state.zone || undefined,
        messengerZone:
          this.state.messengerZone || undefined,
        type: this.state.typeOfCustomer || undefined,
        supplierType: this.state.supplierType || undefined,
        businessType: this.state.businessType || undefined,
        status: this.state.minimartStatus || undefined,
        registrationStatus: this.state.registrationStatus || undefined,
        zones: this.state.zonelist.length !== 0 ? this.state.zonelist : undefined,
        level: this.state.level || undefined,
        cedulaId: this.state.cedulaId || undefined,
        isScalaRegistered: this.state.isScalaRegistered || undefined,

      },
    };
    this.props.downloadReports(
      (this.props.isNewMinimart
        ? MINIMART_REPORTS_DOWNLAOD.newMinimartDownloadUrl
        : MINIMART_REPORTS_DOWNLAOD.downloadUrl) + filetype,
      requestData,
      filetype
    );
  };

  renderSearchFilter = () => {
    let typeOfCustomer = [...MINIMART_MANAGEMENT_TYPE_OF_CUSTOMER];
    typeOfCustomer[0].value !== "" &&
      typeOfCustomer.unshift(DEFAULT_TYPE_OF_CUSTOMER_SELECTION);

    let zones = this.state.zones?.map((singleZone) => {
      return singleZone.name === DEFAULT_ZONE
        ? {
          label: GET_DEFAULT_ZONE_SELECTION().name,
          name: GET_DEFAULT_ZONE_SELECTION().name,
        }
        : {
          label: singleZone.name,
          name: singleZone.name,
        };
    });
    let mZones = this.state.mZones?.map((singleZone) => {
      return singleZone.name === DEFAULT_ZONE
        ? {
          label: GET_DEFAULT_ZONE_SELECTION().name,
          name: GET_DEFAULT_ZONE_SELECTION().name,
        }
        : {
          label: singleZone.name,
          name: singleZone.name,
        };
    });
    let minimartFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("MatricesID"),
        handleOnChange: this.handleSearchMatricesIdChange,
        Value: this.state.matricesId,
        fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
        fieldType: "number"
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("code"),
        handleOnChange: this.handleSearchCICodeChange,
        Value: this.state.ciId,
        fieldname: SEARCH_FILTER_FIELDS.CI_CODE,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("name"),
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.minimartName,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
        name: "minimartName",
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("ownerCedulaNumber"),
        handleOnChange: this.handleSearchNameChange,
        Value: this.state.cedulaId,
        fieldname: SEARCH_FILTER_FIELDS.CEDULA_ID,
        name: "cedulaId",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSelectedZoneChange,
        Value: this.state.zone,
        fieldname: SEARCH_FILTER_FIELDS.ZONE,
        name: "zone",
        filterDropdownList: zones,
        paddingTop: this.state.isNewMinimart ? 0 : 2,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleSelectedZoneChange,
        Value: this.state.messengerZone,
        fieldname: SEARCH_FILTER_FIELDS.MZONE,
        name: "messengerZone",
        filterDropdownList: mZones,
        paddingTop: this.state.isNewMinimart ? 0 : 2,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handlesupplierTypeChange,
        Value: this.state.supplierType,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_TYPES,
        filterDropdownList: MINIMART_TYPE,
        paddingTop: 2,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleStatusChange,
        Value: this.state.minimartStatus,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_STATUSES,
        filterDropdownList: MINIMART_STATUSES,
        paddingTop: 2,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleBusinessTypeChange,
        Value: this.state.businessType,
        fieldname: SEARCH_FILTER_FIELDS.BUSINESS_TYPE,
        filterDropdownList: this.props.businessTypeList,
        paddingTop: 2,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleScalaRegistrationFlag,
        Value: this.state.isScalaRegistered,
        fieldname: SEARCH_FILTER_FIELDS.IS_SCALA_REGISTERED,
        filterDropdownList: IS_SCALA_REGISTERED,
        paddingTop: 2,
      },
    ];
    if (!this.state.isNewMinimart) {
      minimartFilters.splice(4, 0, {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleLevelChange,
        Value: this.state.level,
        fieldname: SEARCH_FILTER_FIELDS.LEVEL,
        filterDropdownList: MINIMART_LEVELS,
      });
      minimartFilters.splice(5, 0, {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleGradeChange,
        Value: this.state.grade,
        fieldname: SEARCH_FILTER_FIELDS.GRADE,
        filterDropdownList: MINIMART_GRADE,
      });
      minimartFilters.splice(7, 0, {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleTypeOfCustomerChange,
        Value: this.state.typeOfCustomer,
        fieldname: SEARCH_FILTER_FIELDS.TYPEOFCUSTOMER,
        filterDropdownList: typeOfCustomer,
        paddingTop: 2,
      });
    } else {
      minimartFilters.push({
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleRegistrationStatusChange,
        Value: this.state.registrationStatus,
        fieldname: SEARCH_FILTER_FIELDS.REGISTRATION_STATUS,
        filterDropdownList: MINIMART_REGISTRATION_STATUS,
        paddingTop: 2,
      });
    }
    return (
      <>
        <Row className='filters'>
          <SearchFilter
            filters={minimartFilters}
            handleOnClickSearchFilter={this.handleOnClickSearchFilter}
            handleOnClickResetFilter={this.resetSearchValues}
            paddingLeft={3}
            paddingTop={2}
            removeLeftPadding={true}
          />
        </Row>
        {this.props.errorMessage !== "" ? (
          <Row className='errors'>
            {Strings(this.props.errorMessage)}
          </Row>
        ) : null}
        {this.state.zonelist && this.state.zonelist.length > 1 && (
          <Row className='pt-2'>
            <Col md={12} className='warningText'>
              <span>
                {Strings("searchedZones") +
                  this.state.zonelist.join(", ")}
              </span>
            </Col>
          </Row>
        )}
      </>
    );
  };

  renderMinimartCards = () => {
    return this.props.minimarts.length >= 1 ? (
      <Row className='cardAlign'>
        <Col md={12} className='w-100 pr-0'>
          <Row className='w-100'>
            {this.props.minimarts.map((minimart) => {
              return (
                <CustomCard
                  card={minimart}
                  key={Math.random()}
                  cardType='minimart'
                  isMessenger={false}
                  handleCardClick={this.setMiniMartDetails}
                  isNewMinimart={this.state.isNewMinimart}
                />
              );
            })}
          </Row>
        </Col>
        <Col md={12} className='pt-3'>
          <Pagination
            activePage={this.state.activePage}
            recordsPerPage={this.state.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.onPageChange}
            dropup={true}
          />
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound'>
        {Strings("NoRecordsFound")}
      </div>
    );
  };

  renderLegends = () => {
    return (
      <Row className='d-flex justify-content-between pt-3'>
        {!this.state.isNewMinimart && (
          <CustomerTypeLegend
            arrayOfTypes={MINIMART_MANAGEMENT_TYPE_LEGEND}
            paddingLeft={4}
          />
        )}
        <Col
          md={{ span: 2, offset: this.state.isNewMinimart ? 10 : 0 }}
          className='alignDownloadButtons'
        >
          {" "}
          <div>
            <Button
              className='downloadButtons mr-3'
              onClick={() => {
                this.downloadFile("PDF");
              }}
            >
              <img src={PDFdownload} alt='' />
            </Button>
            <Button
              className='downloadButtons'
              onClick={() => {
                this.downloadFile("CSV");
              }}
            >
              <img src={CSVdownload} alt='' />
            </Button>
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div>
        <Row className='heading'>
          {Strings("minimartManagement")}-{Strings("list")}
        </Row>
        <div className='minimartList'>
          <div className='cards'>
            {this.renderSearchFilter()}
            {this.renderLegends()}
            {this.renderMinimartCards()}
            {/* Pagination */}
          </div>
        </div>
      </div>
    );
  }
}

export default MinimartList;

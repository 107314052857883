import React from "react";

// Strings
import { Strings } from "../../resources/i18n/i18n";

const renderFormatter = (info, data) => {
  return (
    <>
      <span className='titleText'>
        {Strings(info.label)} &nbsp;:&nbsp;
      </span>
      <span className={`detailText ${info.className}`}>
        {data[info.key] ? Strings(data[info.key]) : Strings("NA")}
      </span>
    </>
  );
};

export const REWARD_DETAILS_KEYS = [
  {
    key: "rpn",
    label: "ProductName",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "rpm",
    label: "ProductModel",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "rp",
    label: "rewardPoints",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "rps",
    label: "status",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "rpd",
    label: "Description",
    className: "address",
    formmater: renderFormatter,
  },
];

export const ASSOCIATION_DETAILS_KEY = [
  {
    key: "sm",
    label: "SALES_MODERATOR",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "sp",
    label: "AssociatedSalesPerson",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "mm",
    label: "AssociatedModerator",
    className: "",
    formmater: renderFormatter,
  },

  {
    key: "msg",
    label: "AssociatedMessenger",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "co",
    label: "CollectionsOfficer",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "cm",
    label: "COLLECTIONS_SUPERVISOR",
    className: "",
    formmater: renderFormatter,
  },
  {
    key: "oo",
    label: "operationOfficerName",
    className: "",
    formmater: renderFormatter,
  },
];

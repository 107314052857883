import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";

export const supplierSearchFilterAction = (request, callback) => {
    callAPI(
        "supplierSearchFilter",
        API.supplierSearchFilter,
        "POST",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const supplierSearchFilterForParticularMinimartAction = (request, callback) => {
    callAPI(
        "supplierSearchFilterForParticularMinimart",
        API.supplierSearchFilterForParticularMinimart,
        "POST",
        request,
        (apiResponse) => {
            callback(apiResponse);
        }
    );
};

export const addSupplierAction = (requestData, callback) => {
    let requestInfo = new FormData();

    requestInfo.append("rnc", requestData.rnc.trim());
    requestInfo.append("businessName", requestData.businessName.trim());
    requestInfo.append("contact", requestData.contact);
    requestInfo.append("phoneNumber", requestData.phoneNumber);
    requestInfo.append("bankName", requestData.bankName);
    requestInfo.append("accountNumber", requestData.accountNumber);
    requestInfo.append("accountType", requestData.accountType);
    requestInfo.append("file", requestData.img);
    requestInfo.append("branchIds", requestData.branchIds);
    requestInfo.append("isActive", requestData.isActive);

    callAPI(
        "addSupplier",
        API.supplier,
        "POST",
        requestInfo,
        (apiResponse) => {
            callback(apiResponse);
        }, false, true
    );
};

export const updateSupplierAction = (requestData, callback) => {
    let requestInfo = new FormData();

    requestInfo.append("supplierId", requestData.supplierId);
    requestInfo.append("rnc", requestData.rnc);
    requestInfo.append("businessName", requestData.businessName);
    requestInfo.append("contact", requestData.contact);
    requestInfo.append("phoneNumber", requestData.phoneNumber);
    requestInfo.append("bankName", requestData.bankName);
    requestInfo.append("accountNumber", requestData.accountNumber);
    requestInfo.append("accountType", requestData.accountType);
    requestData.img && requestInfo.append("file", requestData.img);
    requestInfo.append("branchIds", requestData.branchIds);
    requestInfo.append("isActive", requestData.isActive);

    callAPI(
        "updateSupplier",
        API.supplier,
        "PUT",
        requestInfo,
        (apiResponse) => {
            callback(apiResponse);
        }, false, true
    );
};

import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Component
import Comission from "./Comission";

//Action
import { setSearchFilterAction } from "../../constants/Action";

//Constants
import { Strings } from "../../resources/i18n/i18n";

//Strings

//Utility

//Styles
import "./Comission.scss";

//Assets

class ComissionContainer extends React.Component {
  render() {
    return (
      <div className='comission'>
        <Row className='heading'>{Strings("comission")}</Row>
        <Comission
          loggedInUserRole={this.props.loggedInUser.role}
          setSearchFilterAction={this.props.setSearchFilterAction}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setSearchFilterAction }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComissionContainer);

//React components
import React from "react";
// eslint-disable-next-line
import { Route, Switch, withRouter } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import _ from "lodash";

//Components
import SalesPerson from "./SalesPerson";
import Messenger from "./Messenger";
import PersonDailyDetails from "./PersonDailyDetails";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Constants
import {
  DAILY_STATUS_TABS,
  USER_ROLES,
  MESSENGER_TAB,
  SEARCH_FILTER_PAGES,
  DAILY_STATUS_FILTER,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";
import {
  DEFAULT_BRANCH_SELECTION,
  GET_DEFAULT_BRANCH_SELECTION,
} from "../UserList/UserListConstants";
import {
  DEFAULT_PAGINATION_ACTIVE_PAGE,
  DEFAULT_PAGINATION_RECORDS_COUNT,
} from "../CustomUIComponents/Pagination/PaginationConstants";

import {
  GET_DEFAULT_ZONE_SELECTION,
  DEFAULT_ZONE,
} from "../BranchList/BranchListConstants";

//Styles
import "./DailyStatus.scss";

class DailyStatus extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this.state = {
      salesPersonFilters: {
        name: props.searchFilter.un,
        rating: props.searchFilter.rat,
        branchId: props.searchFilter.br,
        zone: props.searchFilter.zn,
        date: new Date(props.searchFilter.td),
      },
      messengerFilters: {
        name: props.searchFilter.un,
        rating: props.searchFilter.rat,
        branchId: props.searchFilter.br,
        zone: props.searchFilter.zn,
        date: new Date(props.searchFilter.td),
      },
      messengerPagination: {
        recordsPerPage: props.searchFilter.limit,
        activePage: props.searchFilter.offset,
      },
      branches: props.branches,
      zones: props.zones,
      mZones: props.mZones,
      recordsPerPage: props.searchFilter.limit,
      activePage: props.searchFilter.offset,
      salesDetailstab: false,
      messengerDetails: false,
      Tabs:
        props.loggedInUserRole === USER_ROLES.messengerModerator ||
          props.loggedInUserRole === USER_ROLES.collectionOfficer ||
          props.loggedInUserRole === USER_ROLES.accountingOfficer ||
          props.loggedInUserRole === USER_ROLES.auditor
          || props.loggedInUserRole === USER_ROLES.collectionsManager
          || props.loggedInUserRole === USER_ROLES.collectionsSupervisor
          || props.loggedInUserRole === USER_ROLES.administrativeManager
          ? MESSENGER_TAB
          : DAILY_STATUS_TABS,
      activeTab:
        props.loggedInUserRole === USER_ROLES.messengerModerator ||
          props.loggedInUserRole === USER_ROLES.collectionOfficer ||
          props.loggedInUserRole === USER_ROLES.accountingOfficer ||
          props.loggedInUserRole === USER_ROLES.auditor
          || props.loggedInUserRole === USER_ROLES.collectionsManager
          || props.loggedInUserRole === USER_ROLES.collectionsSupervisor
          || props.loggedInUserRole === USER_ROLES.administrativeManager
          ? MESSENGER_TAB[0]
          : DAILY_STATUS_TABS[0],
    };
  }

  componentDidMount() {
    if (
      this.state.activeTab.value === DAILY_STATUS_TABS[0].value &&
      this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
      this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.auditor &&
      this.props.loggedInUserRole !== USER_ROLES.collectionsManager
      && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUserRole !== USER_ROLES.administrativeManager &&
      this.props.history.location.pathname === ROUTES.DAILY_STATUS
    ) {
      this.getSalesPersonTabContent();
    }
    if (
      this.state.activeTab.value === DAILY_STATUS_TABS[1].value &&
      this.props.loggedInUserRole !== USER_ROLES.salesModerator &&
      (this.props.history.location.pathname ===
        ROUTES.DAILY_STATUS_MESSENGER ||
        this.props.history.location.pathname === ROUTES.DAILY_STATUS)
    ) {
      this.getMessengerTabContent();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //NOSONAR
    let derivedState = {};
    if (
      nextProps.history.location.pathname ===
      ROUTES.DAILY_STATUS_MESSENGER ||
      nextProps.history.location.pathname ===
      ROUTES.MESSENGER_DAILY_STATUS_DETAILS
    ) {
      derivedState = {
        ...derivedState,
        activeTab: DAILY_STATUS_TABS[1],
      };
    }
    if (
      nextProps.loggedInUserRole !== USER_ROLES.auditor &&
      (nextProps.history.location.pathname === ROUTES.DAILY_STATUS ||
        nextProps.history.location.pathname ===
        ROUTES.SALES_PERSON_DAILY_STATUS_DETAILS)
    ) {
      derivedState = {
        ...derivedState,
        activeTab: DAILY_STATUS_TABS[0],
      };
    }
    if (
      nextProps.history.location.pathname ===
      ROUTES.DAILY_STATUS_MODERATOR
    ) {
      derivedState = {
        ...derivedState,
        activeTab: DAILY_STATUS_TABS[2],
      };
    }
    if (
      nextProps.loggedInUserRole === USER_ROLES.messengerModerator ||
      nextProps.loggedInUserRole === USER_ROLES.collectionOfficer ||
      nextProps.loggedInUserRole === USER_ROLES.accountingOfficer ||
      nextProps.loggedInUserRole === USER_ROLES.auditor ||
      nextProps.loggedInUserRole === USER_ROLES.collectionsManager
      || nextProps.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || nextProps.loggedInUserRole === USER_ROLES.administrativeManager
    ) {
      if (
        nextProps.history.location.pathname ===
        ROUTES.DAILY_STATUS_MESSENGER ||
        nextProps.history.location.pathname ===
        ROUTES.MESSENGER_DAILY_STATUS_DETAILS
      ) {
        derivedState = {
          ...derivedState,
          activeTab: MESSENGER_TAB[0],
        };
      }
    }
    return derivedState;
  }

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }

    if (
      this.props.loggedInUserRole &&
      prevProps.messengerDate !== this.props.messengerDate &&
      this.props.messengerDate === ""
    ) {
      this.setState(
        {
          messengerFilters: {
            ...this.state.messengerFilters,
            date: new Date(),
          },
        },
        () => {
          if (
            this.props.loggedInUserRole !== USER_ROLES.salesModerator
          ) {
            this.getMessengerTabContent();
          }
        }
      );
    }

    if (!_.isEqual(prevProps.searchFilter, this.props.searchFilter)) {
      if (this.state.activeTab.key === 0 &&
        this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
        this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
        this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
        this.props.loggedInUserRole !== USER_ROLES.auditor &&
        this.props.loggedInUserRole !== USER_ROLES.collectionsManager
        && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
        && this.props.loggedInUserRole !== USER_ROLES.administrativeManager) {
        this.setState(
          {
            salesPersonFilters: {
              name: this.props.searchFilter.un,
              rating: this.props.searchFilter.rat,
              branchId: this.props.searchFilter.br,
              zone: this.props.searchFilter.zn,
              date: this.props.searchFilter.td,
            },
            recordsPerPage: this.props.searchFilter.limit,
            activePage: this.props.searchFilter.offset,
          },
          () => this.getSalesPersonTabContent()
        );
      } else if (this.state.activeTab.key === 1 ||
        this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
        this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
        this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
        this.props.loggedInUserRole === USER_ROLES.auditor
        || this.props.loggedInUserRole === USER_ROLES.collectionsManager
        || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
        || this.props.loggedInUserRole === USER_ROLES.administrativeManager) {
        this.setState(
          {
            messengerFilters: {
              name: this.props.searchFilter.un,
              rating: this.props.searchFilter.rat,
              branchId: this.props.searchFilter.br,
              zone: this.props.searchFilter.zn,
              date: this.props.searchFilter.td,
            },
            recordsPerPage: this.props.searchFilter.limit,
            activePage: this.props.searchFilter.offset,
          },
          () => this.getMessengerTabContent()
        );
      }
    }
  };

  /* #endregion */

  /* #region  Control Events */

  handleSearchNameChange = (event) => {
    if (
      this.state.activeTab.key === 0 &&
      this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
      this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.auditor &&
      this.props.loggedInUserRole !== USER_ROLES.collectionsManager
      && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUserRole !== USER_ROLES.administrativeManager
    ) {
      this.setState({
        salesPersonFilters: {
          ...this.state.salesPersonFilters,
          name: event.target.value,
        },
      });
    } else if (
      this.state.activeTab.key === 1 ||
      this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
      this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
      this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
      this.props.loggedInUserRole === USER_ROLES.auditor
      || this.props.loggedInUserRole === USER_ROLES.collectionsManager
      || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || this.props.loggedInUserRole === USER_ROLES.administrativeManager
    ) {
      this.setState({
        messengerFilters: {
          ...this.state.messengerFilters,
          name: event.target.value,
        },
      });
    }
  };

  handleSelectedRatingChange = (event) => {
    if (
      this.state.activeTab.key === 0 &&
      this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
      this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.auditor &&
      this.props.loggedInUserRole !== USER_ROLES.collectionsManager
      && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUserRole !== USER_ROLES.administrativeManager
    ) {
      if (event.target.value !== Strings("selectRatings")) {
        this.setState({
          salesPersonFilters: {
            ...this.state.salesPersonFilters,
            rating: event.target.value,
          },
        });
      } else {
        this.setState({
          salesPersonFilters: {
            ...this.state.salesPersonFilters,
            rating: "",
          },
        });
      }
    } else if (
      this.state.activeTab.key === 1 ||
      this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
      this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
      this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
      this.props.loggedInUserRole === USER_ROLES.auditor ||
      this.props.loggedInUserRole === USER_ROLES.collectionsManager
      || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || this.props.loggedInUserRole === USER_ROLES.administrativeManager
    ) {
      if (event.target.value !== Strings("selectRatings")) {
        this.setState({
          messengerFilters: {
            ...this.state.messengerFilters,
            rating: event.target.value,
          },
        });
      } else {
        this.setState({
          messengerFilters: {
            ...this.state.messengerFilters,
            rating: "",
          },
        });
      }
    }
  };

  handleBranchChange = (event) => {
    //Need to optimize this function code
    if (
      this.state.activeTab.key === 0 &&
      this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
      this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.auditor &&
      this.props.loggedInUserRole !== USER_ROLES.collectionsManager
      && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUserRole !== USER_ROLES.administrativeManager
    ) {
      this.setState(
        {
          salesPersonFilters: {
            ...this.state.salesPersonFilters,
            branchId: event.target.value,
            zone: "",
          },
        },
        () => {
          let branchId = this.state.salesPersonFilters.branchId;

          this.props.getZonesByBranch(
            branchId !== DEFAULT_BRANCH_SELECTION ? branchId : ""
          );
        }
      );
    } else if (
      this.state.activeTab.key === 1 ||
      this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
      this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
      this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
      this.props.loggedInUserRole === USER_ROLES.auditor ||
      this.props.loggedInUserRole === USER_ROLES.collectionsManager
      || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || this.props.loggedInUserRole === USER_ROLES.administrativeManager
    ) {
      this.setState(
        {
          messengerFilters: {
            ...this.state.messengerFilters,
            branchId: event.target.value,
            zone: "",
          },
        },
        () => {
          let branchId = this.state.messengerFilters.branchId;

          this.props.getZonesByBranch(
            branchId !== DEFAULT_BRANCH_SELECTION ? branchId : ""
          );
        }
      );
    }
  };
  handleZoneChange = (event) => {
    if (
      this.state.activeTab.key === 0 &&
      this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
      this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.auditor &&
      this.props.loggedInUserRole !== USER_ROLES.collectionsManager
      && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUserRole !== USER_ROLES.administrativeManager
    ) {
      this.setState({
        salesPersonFilters: {
          ...this.state.salesPersonFilters,
          zone: event.target.value,
        },
      });
    } else if (
      this.state.activeTab.key === 1 ||
      this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
      this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
      this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
      this.props.loggedInUserRole === USER_ROLES.auditor
      || this.props.loggedInUserRole === USER_ROLES.collectionsManager
      || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || this.props.loggedInUserRole === USER_ROLES.administrativeManager
    ) {
      this.setState({
        messengerFilters: {
          ...this.state.messengerFilters,
          zone: event.target.value,
        },
      });
    }
  };

  handleDateChange = (dateMoment) => {
    let DateValue;
    if (
      this.state.activeTab.key === 0 &&
      this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
      this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.auditor
      && this.props.loggedInUserRole !== USER_ROLES.collectionsManager
      && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUserRole !== USER_ROLES.administrativeManager

    ) {
      if (dateMoment.isAfter(new Date())) {
        DateValue = this.state.salesPersonFilters.date;
      } else {
        DateValue = dateMoment;
      }
      this.setState({
        salesPersonFilters: {
          ...this.state.salesPersonFilters,
          date: DateValue,
        },
      });
    } else if (
      this.state.activeTab.key === 1 ||
      this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
      this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
      this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
      this.props.loggedInUserRole === USER_ROLES.auditor
      || this.props.loggedInUserRole === USER_ROLES.collectionsManager
      || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || this.props.loggedInUserRole === USER_ROLES.administrativeManager
    ) {
      if (dateMoment.isAfter(new Date())) {
        DateValue = this.state.messengerFilters.date;
      } else {
        DateValue = dateMoment;
      }
      this.setState({
        messengerFilters: {
          ...this.state.messengerFilters,
          date: DateValue,
        },
      });
    }
  };

  handleBackButtonClick = () => {
    if (this.state.activeTab.key === 0 &&
      this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
      this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.auditor
      && this.props.loggedInUserRole !== USER_ROLES.collectionsManager
      && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUserRole !== USER_ROLES.administrativeManager) {
      this.getSalesPersonTabContent();
    } else if (this.state.activeTab.key === 1 ||
      this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
      this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
      this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
      this.props.loggedInUserRole === USER_ROLES.auditor
      || this.props.loggedInUserRole === USER_ROLES.collectionsManager
      || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || this.props.loggedInUserRole === USER_ROLES.administrativeManager) {
      this.getMessengerTabContent();
    }
    this.props.history.goBack();
  };

  /* #endregion */

  /* #region  Search Filter */

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleOnClickSearchFilter(
      {
        name: this.state.salesPersonFilters.name,
        branchId:
          this.state.salesPersonFilters.branchId !==
            DEFAULT_BRANCH_SELECTION
            ? this.state.salesPersonFilters.branchId
            : "",
        zone:
          this.state.salesPersonFilters.zone !== DEFAULT_ZONE
            ? this.state.salesPersonFilters.zone
            : "",
        date: this.state.salesPersonFilters.date,
        rating: this.state.salesPersonFilters.rating,
        limit: this.state.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.activePage,
      }
    );
  };

  handleOnClickSearchFilterForCalender = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleOnClickSearchFilter({
      date: this.state.salesPersonFilters.date,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
    this.props.getPiechartDataForAllSalesPersons({
      date: this.state.salesPersonFilters.date,
    });
  };

  handleMessengerFiltersForCalender = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleMessengerSearchFilter({
      date: this.state.messengerFilters.date,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
    this.props.getPiechartDataForAllMesengers({
      date: this.state.messengerFilters.date,
    });
  };

  handleOnClickResetFilterForCalender = () => {
    if (
      this.state.activeTab.key === 0 &&
      this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
      this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.auditor &&
      this.props.loggedInUserRole !== USER_ROLES.collectionsManager
      && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUserRole !== USER_ROLES.administrativeManager
    ) {
      this.setState(
        {
          salesPersonFilters: {
            name: this.state.salesPersonFilters.name,
            branchId: this.state.salesPersonFilters.branchId,
            rating: this.state.salesPersonFilters.rating,
            date: new Date(),
          },
        },
        () => {
          this.handleOnClickSearchFilterForCalender(true);
        }
      );
    } else if (
      this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
      this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
      this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
      this.props.loggedInUserRole === USER_ROLES.auditor ||
      this.props.loggedInUserRole === USER_ROLES.collectionsManager
      || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || this.props.loggedInUserRole === USER_ROLES.administrativeManager ||
      this.state.activeTab.key === 1
    ) {
      this.setState(
        {
          messengerFilters: {
            name: this.state.messengerFilters.name,
            branchId: this.state.messengerFilters.branchId,
            rating: this.state.messengerFilters.rating,
            date: new Date(),
          },
        },
        () => this.handleMessengerFiltersForCalender(true)
      );
    }
  };

  handleMessengerFilters = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({
        messengerPagination: {
          ...this.state.messengerPagination,
          activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        },
      });

    this.props.handleMessengerSearchFilter(
      {
        name: this.state.messengerFilters.name,
        branchId:
          this.state.messengerFilters.branchId !==
            DEFAULT_BRANCH_SELECTION
            ? this.state.messengerFilters.branchId
            : "",
        zone:
          this.state.messengerFilters.zone !== DEFAULT_ZONE
            ? this.state.messengerFilters.zone
            : "",
        date: this.state.messengerFilters.date,
        rating: this.state.messengerFilters.rating,
        limit: this.state.messengerPagination.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.messengerPagination.activePage,
      }
    );
  };

  resetSearchValues = () => {
    if (
      this.state.activeTab.key === 0 &&
      this.props.loggedInUserRole !== USER_ROLES.messengerModerator &&
      this.props.loggedInUserRole !== USER_ROLES.collectionOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.accountingOfficer &&
      this.props.loggedInUserRole !== USER_ROLES.auditor
      && this.props.loggedInUserRole !== USER_ROLES.collectionsManager
      && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
      && this.props.loggedInUserRole !== USER_ROLES.administrativeManager
    ) {
      this.setState(
        {
          salesPersonFilters: {
            name: "",
            rating: "",
            branchId: "",
            date: this.state.salesPersonFilters.date,
          },
        },
        () => {
          this.handleOnClickSearchFilter(true, true);
          this.props.getZonesByBranch();
        }
      );
    } else if (
      this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
      this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
      this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
      this.props.loggedInUserRole === USER_ROLES.auditor
      || this.props.loggedInUserRole === USER_ROLES.collectionsManager
      || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || this.props.loggedInUserRole === USER_ROLES.administrativeManager ||
      this.state.activeTab.key === 1
    ) {
      this.setState(
        {
          messengerFilters: {
            name: "",
            rating: "",
            branchId: "",
            date: this.state.messengerFilters.date,
          },
        },
        () => {
          this.handleMessengerFilters(true, true);
          this.props.getZonesByBranch();
        }
      );
    }
  };

  /* #endregion */

  /* #region  Pagination Methods */

  onPageChange = ({ page, pageLength }) => {
    if (
      this.state.activeTab.key === 1 ||
      this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
      this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
      this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
      this.props.loggedInUserRole === USER_ROLES.auditor
      || this.props.loggedInUserRole === USER_ROLES.collectionsManager
      || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
      || this.props.loggedInUserRole === USER_ROLES.administrativeManager
    ) {
      this.setState(
        {
          messengerPagination: {
            activePage: page,
            recordsPerPage: pageLength,
          },
        },
        () => this.handleMessengerFilters(false)
      );
    } else {
      this.setState(
        {
          activePage: page,
          recordsPerPage: pageLength,
        },
        () => this.handleOnClickSearchFilter(false)
      );
    }
  };

  /* #endregion */

  /* #region  Tab Methods*/

  handleTabs = (index) => {
    this.setState({
      salesDetailstab: false,
      messengerDetails: false,
    });
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.DAILY_STATUS,
      { ...DAILY_STATUS_FILTER }
    );
    this.setState(
      {
        activeTab: DAILY_STATUS_TABS[index],
        salesPersonFilters: {
          name: "",
          rating: "",
          branchId: "",
          date: new Date(),
        },
        messengerFilters: {
          name: "",
          rating: "",
          branchId: "",
          date: new Date(),
        },
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      },
      () => {
        if (index === 0) {
          if (
            this.props.loggedInUserRole !== USER_ROLES.messengerModerator
            && this.props.loggedInUserRole !== USER_ROLES.collectionOfficer
            && this.props.loggedInUserRole !== USER_ROLES.accountingOfficer
            && this.props.loggedInUserRole !== USER_ROLES.auditor
            && this.props.loggedInUserRole !== USER_ROLES.collectionsManager
            && this.props.loggedInUserRole !== USER_ROLES.collectionsSupervisor
            && this.props.loggedInUserRole !== USER_ROLES.administrativeManager
          ) {
            this.getSalesPersonTabContent();
            this.props.history.push(ROUTES.DAILY_STATUS);
          } else {
            this.getMessengerTabContent();
            this.props.history.push(ROUTES.DAILY_STATUS_MESSENGER);
          }
        } else if (index === 1) {
          this.getMessengerTabContent();
          this.props.history.push(ROUTES.DAILY_STATUS_MESSENGER);
        } else if (index === 2) {
          this.props.history.push(ROUTES.DAILY_STATUS_MODERATOR);
        }
      }
    );
  };

  getSalesPersonTabContent = () => {
    this.handleOnClickSearchFilter(false);
    this.props.getPiechartDataForAllSalesPersons({
      date: this.state.salesPersonFilters.date,
    });
  };

  getMessengerTabContent = () => {
    this.handleMessengerFilters(false);
    this.props.getPiechartDataForAllMesengers({
      date: this.state.messengerFilters.date,
    });
  };

  /* #endregion */

  /* #region  Setters */

  setSalesPersonID = (salesPerson) => {
    this.setState({ salesDetailstab: true });
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.DAILY_STATUS,
      {
        un: this.state.salesPersonFilters.name,
        br: this.state.salesPersonFilters.branchId,
        zn: this.state.salesPersonFilters.zone,
        td: this.state.salesPersonFilters.date,
        rat: this.state.salesPersonFilters.rating,
        limit: this.state.recordsPerPage,
        offset: this.state.activePage,
      },
      SEARCH_FILTER_PAGES.DAILY_STATUS
    );
    this.props.setSalesPersonID(salesPerson);
    this.props.setDateForSalesPerson(
      this.state.salesPersonFilters.date
    );
    this.props.history.push(ROUTES.SALES_PERSON_DAILY_STATUS_DETAILS);
  };

  setMessengerId = (messenger) => {
    this.setState({ messengerDetails: true });
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.DAILY_STATUS,
      {
        un: this.state.messengerFilters.name,
        br: this.state.messengerFilters.branchId,
        zn: this.state.messengerFilters.zone,
        td: this.state.messengerFilters.date,
        rat: this.state.messengerFilters.rating,
        limit: this.state.messengerPagination.recordsPerPage,
        offset: this.state.messengerPagination.activePage,
      },
      SEARCH_FILTER_PAGES.DAILY_STATUS
    );
    this.props.setMessengerId(messenger);
    this.props.setDateForMessenger(this.state.messengerFilters.date);
    this.props.history.push(ROUTES.MESSENGER_DAILY_STATUS_DETAILS);
  };

  /* #endregion */

  //render
  renderSalesPerson = (branches) => {
    let zones =
      this.state.zones.length === 0
        ? [
          {
            label: GET_DEFAULT_ZONE_SELECTION().name,
            name: GET_DEFAULT_ZONE_SELECTION().name,
          },
        ]
        : this.state.zones?.map((singleZone) => {
          return {
            label: singleZone.name,
            name: singleZone.name,
          };
        });
    return (
      <SalesPerson
        salesPersonFilters={this.state.salesPersonFilters}
        piechartStats={this.props.piechartStats}
        salesPersons={this.props.salesPersonInfos}
        branches={branches}
        recordsPerPage={this.state.recordsPerPage}
        activePage={this.state.activePage}
        numberOfPages={this.props.numberOfPages}
        onPageChange={this.onPageChange}
        handleBranchChange={this.handleBranchChange}
        handleDateChange={this.handleDateChange}
        handleSelectedRatingChange={this.handleSelectedRatingChange}
        handleSearchNameChange={this.handleSearchNameChange}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickSearchFilterForCalender={
          this.handleOnClickSearchFilterForCalender
        }
        handleOnClickResetFilter={this.resetSearchValues}
        handleOnClickResetFilterForCalender={
          this.handleOnClickResetFilterForCalender
        }
        setSalesPersonID={this.setSalesPersonID}
        setMiniMartDetails={this.props.setMiniMartDetails}
        getZonesByBranch={this.props.getZonesByBranch}
        zones={zones}
        handleZoneChange={this.handleZoneChange}
      />
    );
  };

  renderPersonDailyDetails = (role, date) => {
    return (
      <PersonDailyDetails
        loggedInUserRole={this.props.loggedInUserRole}
        piechartStats={this.props.piechartStats}
        setMiniMartDetails={this.props.setMiniMartDetails}
        renderPerson={role}
        date={date}
        handleBackButtonClick={this.handleBackButtonClick}
      />
    );
  };
  renderMessenger = (branches) => {
    let mZones =
      this.state.mZones.length === 0
        ? [
          {
            label: GET_DEFAULT_ZONE_SELECTION().name,
            name: GET_DEFAULT_ZONE_SELECTION().name,
          },
        ]
        : this.state.mZones?.map((singleZone) => {
          //NOSONAR
          return {
            label: singleZone.name,
            name: singleZone.name,
          };
        });

    return (
      <Messenger
        salesPersonFilters={this.state.messengerFilters}
        piechartStats={this.props.messengerPiechartStats}
        MessengerLoanData={this.props.MessengerLoanData}
        salesPersons={this.props.messengerInfos}
        branches={branches}
        recordsPerPage={this.state.messengerPagination.recordsPerPage}
        activePage={this.state.messengerPagination.activePage}
        numberOfPages={this.props.messengerNumberOfPages}
        onPageChange={this.onPageChange}
        handleBranchChange={this.handleBranchChange}
        handleDateChange={this.handleDateChange}
        handleSelectedRatingChange={this.handleSelectedRatingChange}
        handleSearchNameChange={this.handleSearchNameChange}
        handleOnClickSearchFilter={this.handleMessengerFilters}
        handleMessengerFiltersForCalender={
          this.handleMessengerFiltersForCalender
        }
        handleOnClickResetFilter={this.resetSearchValues}
        handleOnClickResetFilterForCalender={
          this.handleOnClickResetFilterForCalender
        }
        setMessengerId={this.setMessengerId}
        setMiniMartDetails={this.props.setMiniMartDetails}
        otherData={this.props.otherData}
        getZonesByBranch={this.props.getZonesByBranch}
        mZones={mZones}
        handleZoneChange={this.handleZoneChange}
      />
    );
  };

  renderSalesPersonTabPanel = (branches) => {
    return (
      <Switch>
        <Route
          exact
          path={ROUTES.DAILY_STATUS}
          render={() => this.renderSalesPerson(branches)}
        />
        <Route
          exact
          path={ROUTES.SALES_PERSON_DAILY_STATUS_DETAILS}
          render={() =>
            this.renderPersonDailyDetails(
              USER_ROLES.salesPerson,
              this.state.salesPersonFilters.date
            )
          }
        />
      </Switch>
    );
  };
  render() {
    window.onhashchange = this.checkRoutes;
    let branches =
      this.state.branches.length === 0
        ? [
          {
            label: GET_DEFAULT_BRANCH_SELECTION().name,
            name: GET_DEFAULT_BRANCH_SELECTION().name,
          },
        ]
        : this.state.branches.map((singleBranch) => {
          return {
            label: singleBranch.name,
            value: singleBranch.id,
          };
        });

    return (
      <div className='dailyStatus'>
        {(this.props.loggedInUserRole === USER_ROLES.admin ||
          this.props.loggedInUserRole === USER_ROLES.generalManager ||
          this.props.loggedInUserRole === USER_ROLES.operationsOfficer ||
          this.props.loggedInUserRole === USER_ROLES.operationsModerator ||
          this.props.loggedInUserRole === USER_ROLES.customerService
          || this.props.loggedInUserRole === USER_ROLES.operationsManager
          || this.props.loggedInUserRole === USER_ROLES.technology) && (
            <Tabs
              selectedIndex={this.state.activeTab.key}
              onSelect={this.handleTabs}
            >
              <TabList>
                {DAILY_STATUS_TABS.map((item) => (
                  <Tab key={item.key}>{item.value}</Tab>
                ))}
              </TabList>
              <TabPanel>
                {this.renderSalesPersonTabPanel(branches)}
              </TabPanel>
              <TabPanel>
                <Switch>
                  <Route
                    exact
                    path={ROUTES.DAILY_STATUS_MESSENGER}
                    render={() => this.renderMessenger(branches)}
                  />
                  <Route
                    exact
                    path={ROUTES.MESSENGER_DAILY_STATUS_DETAILS}
                    render={() =>
                      this.renderPersonDailyDetails(
                        USER_ROLES.messenger,
                        this.state.messengerFilters.date
                      )
                    }
                  />
                </Switch>
              </TabPanel>
            </Tabs>
          )}
        {(this.props.loggedInUserRole === USER_ROLES.salesModerator
          || this.props.loggedInUserRole === USER_ROLES.salesManager) && (
            <Tabs
              selectedIndex={this.state.activeTab.key}
              onSelect={this.handleTabs}
            >
              <TabList>
                <Tab key={DAILY_STATUS_TABS[0].key}>
                  {DAILY_STATUS_TABS[0].value}
                </Tab>
              </TabList>
              <TabPanel>
                {this.renderSalesPersonTabPanel(branches)}
              </TabPanel>
            </Tabs>
          )}
        {(this.props.loggedInUserRole === USER_ROLES.messengerModerator ||
          this.props.loggedInUserRole === USER_ROLES.collectionOfficer ||
          this.props.loggedInUserRole === USER_ROLES.accountingOfficer ||
          this.props.loggedInUserRole === USER_ROLES.auditor ||
          this.props.loggedInUserRole === USER_ROLES.collectionsManager
          || this.props.loggedInUserRole === USER_ROLES.collectionsSupervisor
          || this.props.loggedInUserRole === USER_ROLES.administrativeManager) && (
            <Tabs
              selectedIndex={this.state.activeTab.key}
              onSelect={this.handleTabs}
            >
              <TabList>
                <Tab key={DAILY_STATUS_TABS[1].key}>
                  {DAILY_STATUS_TABS[1].value}
                </Tab>
              </TabList>
              <TabPanel>
                <Switch>
                  <Route
                    exact
                    path={[
                      ROUTES.DAILY_STATUS_MESSENGER,
                      ROUTES.DAILY_STATUS,
                    ]}
                    render={() => this.renderMessenger(branches)}
                  />
                  <Route
                    exact
                    path={ROUTES.MESSENGER_DAILY_STATUS_DETAILS}
                    render={() =>
                      //NOSONAR
                      this.renderPersonDailyDetails(
                        USER_ROLES.messenger,
                        this.state.messengerFilters.date
                      )
                    }
                  />
                </Switch>
              </TabPanel>
            </Tabs>
          )}
      </div>
    );
  }
}

export default withRouter(DailyStatus);

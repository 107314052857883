import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";

export const getMotorcycleCategoriesAction = (callback) => {
  callAPI(
    "getMotorcycleCategories",
    API.getMotorcycleCatalogue,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getMotorcycleCatalogAction = (requestData, callback) => {
  callAPI(
    "getMotorcycleCatalog",
    API.getMotorcycleCatalog,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const addNewMotorcycleAction = (requestData, callback) => {
  let requestInfo = new FormData();

  requestInfo.append("category", requestData.category);
  requestInfo.append("motorcycleName", requestData.motorcycleName);
  requestInfo.append("model", requestData.model);
  requestInfo.append("status", requestData.status);
  requestInfo.append("cost", requestData.cost);
  requestData.file && requestInfo.append("file", requestData.file);
  requestData.description &&
    requestInfo.append("description", requestData.description);
  requestInfo.append("branchIds", requestData.branchIds || []);

  callAPI(
    "addNewMotorcycle",
    API.addNewMotorcycle,
    "POST",
    requestInfo,
    (apiResponse) => {
      callback(apiResponse);
    }, false, true
  );
};

export const editMotorcycleAction = (requestData, id, callback) => {
  let requestInfo = new FormData();

  requestInfo.append("category", requestData.category || "");
  requestInfo.append(
    "motorcycleName",
    requestData.motorcycleName || ""
  );
  requestInfo.append("model", requestData.model || "");
  requestInfo.append("status", requestData.status || "");
  requestInfo.append("cost", requestData.cost || "");
  requestData.file && requestInfo.append("file", requestData.file);
  requestInfo.append("description", requestData.description || "");
  requestInfo.append("branchIds", requestData.branchIds || []);

  callAPI(
    "editMotorcycle",
    API.addNewMotorcycle.concat(`/${id}`),
    "PUT",
    requestInfo,
    (apiResponse) => {
      callback(apiResponse);
    }, false, true
  ); //handle 6001
};

export const addMotorcycleCategoryAction = (data, callback) => {
  callAPI(
    "addMotorcycleCategory",
    API.addMotorcycleCategory,
    "POST",
    data,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateMotorcycleCategoryAction = (data, callback) => {
  callAPI(
    "updateProductCategory",
    API.updateMotorcycleCategory.concat(data.id),
    "PUT",
    { category: data.category },
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

// Constants
import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getUserListAction = (request, callback) => {
  let requestData = {
    bid: request.branch ? request.branch : undefined,
    zn: request.zone ? request.zone : undefined,
    pstid: request.position ? request.position : undefined,
    rl: request.role ? request.role : undefined,
  };

  callAPI(
    "getUserList",
    API.getCensusUserList,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getWorkloadUserListAction = (request, callback) => {
  request.offset -= 1;
  callAPI(
    "getWorkloadUserList",
    API.getCensusWorkloadList,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getPositionListAction = (request, callback) => {
  let requestData = {
    bid: request.branch ? request.branch : undefined,
    rl: request.role ? request.role : undefined,
  };
  callAPI(
    "getPositionList",
    API.getCensusPositionList,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getZoneListAction = (request, callback) => {
  let url = "";
  if (request.positionId) {
    url = `?pstid=${request.positionId}`;
  } else if (request.branch) {
    url = `?branchIds=${request.branch}`;
  }
  callAPI(
    " getZoneList",
    API.getCensusZoneList + url,
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAssignedUserListAction = (request, callback) => {
  let requestData = {
    br: request.branch,
    rl: request.role,
    uid: request.uid,
  };

  callAPI(
    "getAssignedUserList",
    API.getCensusAssignedUserList,
    "GET",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const saveAssignmentAction = (request, callback) => {
  callAPI(
    "saveAssignment",
    API.saveAssignment,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

import { Strings } from "../../resources/i18n/i18n";

export const LOAN_STATUS_DETAILS_COLUMNS = {
  NOTES: "Notes",
  STATUS: "Status",
  ARREARS_FROM: "ArrearsFrom",
  ARREARS_TO: "ArrearsTo",
};

export const translateStatusConstantToString = (status) => {
  let StatusConstant = {
    ACTIVE: Strings("Active"),
    LEGAL: Strings("Legal"),
    W_OFF: Strings("W_Off"),
    CLOSED: Strings("Closed"),
    DISAPPEARED: Strings("DISAPPEARED"),
  };
  return StatusConstant[status];
};

export const translateStringToStatusConstant = (statusString) => {
  switch (statusString) {
    case Strings("Active"):
      return "ACTIVE";
    case Strings("Legal"):
      return "LEGAL";
    case Strings("W_Off"):
      return "W_OFF";
    case Strings("Closed"):
      return "CLOSED";
    case Strings("DISAPPEARED"):
      return "DISAPPEARED";
    default:
      return "";
  }
};

export const PeriodDropdown = [
  {
    key: 0,
    value: "period",
    label: Strings("period"),
  },
  {
    key: 1,
    value: "AM",
    label: Strings("am"),
  },
  {
    key: 2,
    value: "PM",
    label: Strings("pm"),
  },
];

import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";
import {
  SET_WORK_HISTORY_FILTERS_ACTION,
  SET_USER_ID_ACTION,
} from "../../redux/constants";

//Utility
import Utility from "../../utils/Utility";

//action with dispatch for storing data in reducer

export const setWorkHistoryFiltersAction =
  (filters) => (dispatch) => {
    dispatch({
      type: SET_WORK_HISTORY_FILTERS_ACTION,
      filters: filters,
    });
  };

export const setUserIdAction = (userId) => (dispatch) => {
  dispatch({
    type: SET_USER_ID_ACTION,
    userId: userId,
  });
};

export const getWorkHistoryDataAction = (
  url,
  requestData,
  callback
) => {
  requestData.fromDate = Utility.convertDateIntoMiliSeconds(
    requestData.fromDate
  );
  requestData.toDate = Utility.convertDateIntoMiliSeconds(
    requestData.toDate
  );
  requestData.offset -= 1;

  callAPI(
    "GetWorkHistory",
    API.getUserHistory.concat(url),
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

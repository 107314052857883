import React from "react";

// Components
import ConsolidatedMap from "./ConsolidatedMap";

class ConsolidatedMapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      miniMartData: props.miniMartData,
      singleMinimartData: props.singleMinimartData,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.miniMartData !== state.miniMartData) {
      return {
        miniMartData: props.miniMartData,
      };
    }
    if (props.singleMinimartData !== state.singleMinimartData) {
      return {
        singleMinimartData: props.singleMinimartData,
      };
    }

    return null;
  }

  render() {
    return (
      <ConsolidatedMap
        miniMartData={this.state.miniMartData}
        singleMinimartData={this.state.singleMinimartData}
        getMapData={this.props.getMapData}
      />
    );
  }
}

export default ConsolidatedMapComponent;

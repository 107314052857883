import { Strings } from "../../resources/i18n/i18n";
export const PAID_INVOICE_TABLE = [
  { key: "cd", name: Strings("CIID") },
  { key: "mn", name: Strings("MiniMartname") },
  { key: "nm", name: Strings("InvoiceNumber") },
  { key: "it", name: Strings("InvoiceType") },
  { key: "zo", name: Strings("CIZone") },
  { key: "app", name: Strings("LoanId") },
  { key: "amt", name: Strings("Amount") + " ($)" },
  { key: "dd", name: Strings("DisbursementDate") },
  { key: "st", name: Strings("PAID") },
  { key: "vd", name: Strings("visitDay") },
];

//libraries
import React from "react";
import { connect } from "react-redux";
import "react-rater/lib/react-rater.css";

// Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import withHOC from "../../HOC/HOC";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Constants
import { OPERATION_STATUS } from "../../config/axios.init";

// Actions
import {
  getZonePositionMappingAction,
  getPositionListAction,
  editPositionAction,
  addPositionAction,
  addZoneAction,
} from "./ZonePositionMappingAction";

// Utils

// Styles

//Assets
import ZonePositionMapping from "./ZonePositionMapping";

class ZonePositionMappingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      positionList: [],
      level: "",
      successToastMessage: "",
      toShowToast: false,
      errorMessage: "",
      filters: {},
    };
  }

  componentDidMount() {
    this.props.getBranchList();
    this.props.getZonesByBranch();
  }

  getPositionsList = (searchFilterObject, successCallback) => {
    this.setState({
      showLoader: true,
    });
    getPositionListAction(searchFilterObject, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let positionList = response.data.data;
        positionList.unshift({
          value: "",
          label: Strings("SelectPosition"),
        });
        this.setState(
          {
            errorMessage: "",
            showLoader: false,
          },
          () => {
            successCallback(positionList);
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  addPosition = (request, callBack) => {
    this.setState({
      showLoader: true,
      toShowToast: false,
    });
    addPositionAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            errorMessage: "",
            level: "success",
            showLoader: false,
            toShowToast: true,
            successToastMessage: Strings("addPosition"),
          },
          () => {
            callBack();
            this.handleOnClickSearchFilter(this.state.filters);
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  editPosition = (request, callBack) => {
    this.setState({
      showLoader: true,
      toShowToast: false,
    });
    editPositionAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            errorMessage: "",
            level: "success",
            showLoader: false,
            toShowToast: true,
            successToastMessage: response.data.iacd ?
              Strings("positionChanged") :
              Strings("positionChangedAssignmentsNotDone"),
          },
          () => {
            callBack();
            this.handleOnClickSearchFilter(this.state.filters);
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  addZone = (request, callBack) => {
    this.setState({
      showLoader: true,
      toShowToast: false,
    });
    addZoneAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            errorMessage: "",
            level: "success",
            showLoader: false,
            toShowToast: true,
            successToastMessage: Strings("addedZone"),
          },
          () => {
            callBack();
            this.handleOnClickSearchFilter(this.state.filters);
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  /**
   * This function will be called when user clicks on serach/filter/reset
   * if search and filter is empty, it will call list API else search filter API
   */
  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoader: true,
      filters: { ...searchFilterObject },
    });
    getZonePositionMappingAction(
      { ...searchFilterObject },
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            errorMessage: "",
            showLoader: false,
            rows: response.data.data.zonePositions,
            numberOfPages: response.data.data.numberOfPages,
          });
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoader: false,
            rows: [],
            numberOfPages: 0,
          });
        }
      }
    );
  };

  render() {
    return (
      <div>
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.successToastMessage}
            userNotificationObj={{
              message: this.state.successToastMessage,
              level: this.state.level,
            }}
          />
        )}
        {this.state.showLoader && (
          <LoadingModal showLoadingModal={this.state.showLoader} />
        )}

        <ZonePositionMapping
          rows={this.state.rows}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          branches={this.props.branches}
          getZonesByBranch={this.props.getZonesByBranch}
          zones={this.props.zones}
          mZones={this.props.mZones}
          numberOfPages={this.state.numberOfPages}
          getZonePositionMapping={this.getZonePositionMapping}
          getPositionsList={this.getPositionsList}
          editPosition={this.editPosition}
          addPosition={this.addPosition}
          addZone={this.addZone}
          errorMessage={this.state.errorMessage}
          getBranchList={this.props.getBranchList}
          loggedInUser={this.props.loggedInUser}
        />
      </div>
    );
  }
}

const ZonePositionMappingWrapper = withHOC(
  ZonePositionMappingContainer
);

export default connect()(ZonePositionMappingWrapper);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import RewardDetails from "./RewardDetails";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

//Actions
import {
  getRewardDetailsAction,
  updateRewardStatusAction,
} from "./Actions";
import { setSearchFilterAction } from "../../constants/Action";

// Constant
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  SEARCH_FILTER_PAGES,
  REWARD_APPROVAL_FILTER,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";

//Utility
import Utility from "../../utils/Utility";

class RewardDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      successToastMessage: "",
      level: "",
      toShowToast: false,
      errorMessage: "",
      rewardDetails: null,
    };
  }

  componentDidMount() {
    this.props.reward &&
      this.getRewardDetails(this.props.reward.rrid);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.reward, this.props.reward)) {
      this.props.reward &&
        this.getRewardDetails(this.props.reward.rrid);
    }
  }

  componentWillUnmount() {
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.REWARD_APPROVAL &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.REWARD_APPROVAL,
        {
          ...REWARD_APPROVAL_FILTER,
        }
      );
    }
  }

  getRewardDetails = (rrid) => {
    this.setState({ showLoader: true, errorMessage: "" });
    getRewardDetailsAction(rrid, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        if (responseData.data.data.pd.rpi) {
          responseData.data.data.pd.rpi = Utility.getDecryptedData(
            responseData.data.data.pd.rpi
          );
        }
        this.setState({
          rewardDetails: responseData.data.data,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: responseData.error.message,
          showLoader: false,
          rewardDetails: null,
        });
      }
    });
  };

  updateRewardStatus = (request, toastMessage, successCallback) => {
    this.setState({ showLoader: true });
    updateRewardStatusAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            toShowToast: true,
            successToastMessage: toastMessage,
            level: "success",
          },
          successCallback
        );
        setTimeout(() => {
          this.props.history.push(ROUTES.REWARD_APPROVAL);
        }, 2000);
      } else {
        this.setState({
          errorMessage: response.error.message,
          successToastMessage: response.error.message,
          showLoader: false,
          toShowToast: true,
          level: "error",
        });
      }
    });
  };
  goBack = () => {
    this.props.history.push(ROUTES.REWARD_APPROVAL);
  };

  resetError = () => {
    this.setState({ errorMessage: "" });
  };

  render() {
    return (
      <div>
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.successToastMessage}
            userNotificationObj={{
              message: this.state.successToastMessage,
              level: this.state.level,
            }}
          />
        )}
        {this.state.showLoader && (
          <LoadingModal showLoadingModal={this.state.showLoader} />
        )}
        <RewardDetails
          rewardDetails={this.state.rewardDetails}
          updateRewardStatus={this.updateRewardStatus}
          reward={this.props.reward}
          goBack={this.goBack}
          errorMessage={this.state.errorMessage}
          resetError={this.resetError}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reward: state.rewardApprovalReducer.selectedReward,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setSearchFilterAction }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardDetailsContainer);

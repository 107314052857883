import React, { Component } from "react";
import { Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import ViewLoanApplication from "../AnalystApproval/ViewLoanApplication";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

// Action
import { getLoanApplicationDetailsByIdAction } from "./ViewLoanApplicationAction";
import { setSearchFilterAction } from "../../constants/Action";
import { setMinimartDetailsActiveTab } from "../MinimartDetails/MiniMartDetailsAction";

// Constants
import {
  SEARCH_FILTER_PAGES,
  MINIMART_LIST_FILTER,
  APPROVAL_DASHBOARD_FILTER,
  SPED_L1_FILTER,
  SPED_L2_FILTER,
  LOAN_PORTFOLIO_FILTER,
  FLR_SEARCH_FILTER,
  SLR_SEARCH_FILTER,
  DAILY_STATUS_FILTER,
  PERSON_DAILY_STATUS_FILTER,
  LOAN_DETAIL_FILTER,
  WORKLOAD_MANAGEMENT_FILTER,
  ANALYST_LOAN_DETAIL_FILTER,
} from "../../constants/appConstants";
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

// Utils
import Utility from "../../utils/Utility";

class ViewLoanApplicationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanApplicationDetails: {},
      applicationId: props.selectedLoanDetails.applicationId,
      showLoadingModal: false,
    };
  }

  componentDidMount() {
    //   Call API for getting Data and set into setState as per the initialStateData structure
    this.getLoanApplicationDetailsById(this.state.applicationId);
  }

  componentWillUnmount() {
    //NOSONAR
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.MINIMART_LIST &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.MINIMART_LIST,
        {
          ...MINIMART_LIST_FILTER,
        }
      );
    }

    if (
      this.props.searchPage ===
        SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD,
        {
          ...APPROVAL_DASHBOARD_FILTER,
        }
      );
    }
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.SPED_L2 &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(SEARCH_FILTER_PAGES.SPED_L1, {
        ...SPED_L1_FILTER,
      });
      this.props.setSearchFilterAction(SEARCH_FILTER_PAGES.SPED_L2, {
        ...SPED_L2_FILTER,
      });
    }

    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_PORTFOLIO &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_PORTFOLIO,
        { ...LOAN_PORTFOLIO_FILTER }
      );
    }

    if (
      this.props.searchPage ===
        SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.FIRST_LEVEL_REPORT,
        { ...FLR_SEARCH_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.SECOND_LEVEL_REPORT,
        { ...SLR_SEARCH_FILTER }
      );
    }
    if (
      this.props.searchPage ===
        SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.DAILY_STATUS,
        { ...DAILY_STATUS_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.PERSON_DAILY_STATUS,
        { ...PERSON_DAILY_STATUS_FILTER }
      );
    }
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.LOAN_DETAIL &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setMinimartDetailsActiveTab(null);
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_DETAIL,
        { ...LOAN_DETAIL_FILTER }
      );
    }
    if (
      this.props.searchPage ===
        SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.WORKLOAD_MANAGEMENT,
        { ...WORKLOAD_MANAGEMENT_FILTER }
      );
    }
    if (
      this.props.searchPage ===
        SEARCH_FILTER_PAGES.ANALYST_LOAN_DETAIL &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.ANALYST_LOAN_DETAIL,
        { ...ANALYST_LOAN_DETAIL_FILTER }
      );
    }
  }

  // This func can be used in the future
  // getAgeByDoB = (value) => {
  //   const data = Utility.getDateFromMiliSeconds(value);
  // };

  getLoanApplicationDetailsById = (applicationId) => {
    this.setState({ showLoadingModal: true });
    getLoanApplicationDetailsByIdAction(applicationId, (Response) => {
      if (
        Response.status === OPERATION_STATUS.SUCCESS &&
        Response.data.data
      ) {
        this.setState({
          loanApplicationDetails: Response.data.data,
          errorMessage: "",
          showLoadingModal: false,
        });
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: Response.error.message,
        });
      }
    });
  };

  viewDocument = (documentId) => {
    this.setState({ showLoadingModal: true });
    const stateObject = Utility.viewDocuments(documentId);
    this.setState(stateObject);
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  render() {
    const isLoanPortfolio =
      this.props.history.location.pathname ===
      ROUTES.VIEW_LOAN_APPLICATIONS;
    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        {isLoanPortfolio && (
          <div className='headerText'>
            {Strings("ViewLoanApplication")}
          </div>
        )}
        <ViewLoanApplication
          {...this.state.loanApplicationDetails}
          viewDocument={this.viewDocument}
          applicationId={this.state.applicationId}
          handleBackButtonClick={this.handleBackButtonClick}
          isLoanPortfolio={isLoanPortfolio}
        />
        <Col md={1} className='alignCenter pl-0 pt-2'>
          <Button
            className='buttonBorder'
            onClick={this.handleBackButtonClick}
          >
            {Strings("Back").toUpperCase()}
          </Button>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLoanDetails: state.loanDetailsReducer.loanDetails,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setSearchFilterAction, setMinimartDetailsActiveTab },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewLoanApplicationContainer);

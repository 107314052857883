import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import { Strings } from "../../../resources/i18n/i18n";

class MultipleSelectCheckmarks extends React.Component {
  render() {//NOSONAR
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    let itemList = [...this.props.itemList];
    if (itemList[0].id === this.props.placeHolder) itemList.shift();
    if (itemList[0] === this.props.placeHolder) itemList.shift();

    const handleChange = (event) => {
      if (this.props.PRODUCT) {
        this.props.handleChange("product", "branchIds", event);
      } else if (this.props.MOTORCYCLE) {
        this.props.handleChange("bid", event);
      } else {
        this.props.handleChange(event);
      }
    };

    let notched =
      this.props.NO_TAG_FIELDS ||
        this.props.selectedValues.length === 0
        ? false
        : true;

    return (
      <div className='w-100'>
        <FormControl
          sx={{
            m: 1,
            width: this.props.width ? this.props.width : 300,
            mt: 1,
          }}
          className='mx-0 w-100'
          focused={false}
          size='small'
        >
          {(!this.props.NO_TAG_FIELDS ||
            this.props.selectedValues.length === 0) && (
              <InputLabel id='demo-multiple-checkbox-label'>
                {Strings(this.props.placeHolder)}
              </InputLabel>
            )}
          <Select
            labelId='demo-multiple-checkbox-label'
            id='demo-multiple-checkbox'
            multiple={this.props.multiple}
            value={
              this.props.selectedValues
                ? this.props.selectedValues
                : []
            }
            onChange={handleChange}
            input={
              <OutlinedInput
                notched={notched}
                label={this.props.tag}
              />
            }
            name={this.props.name}
            renderValue={(selected) => {
              let selectedItems = [];
              selected.forEach((select) => {
                let items;
                this.props.itemList.forEach((item) => {
                  if (item.id === select || item === select || item.id === select[0]) {
                    items = item.name || item;
                    return item.name || item;
                  }
                });
                items && selectedItems.push(items);
              });

              return selectedItems.join(", ");
            }}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value=''>
              <em>{Strings(this.props.placeHolder)}</em>
            </MenuItem>
            {itemList.map((name) => {
              let checked;
              if (this.props.selectedValues) {
                if (typeof name === "object") {
                  checked = this.props.selectedValues.indexOf(name.id) > -1
                } else {
                  checked = this.props.selectedValues.indexOf(name) > -1
                }
              } else {
                checked = []
              }
              return <MenuItem
                key={name.id || name}
                value={name.id || name}
                disabled={this.props.disabled}
              >
                <Checkbox
                  checked={checked}
                />
                <ListItemText primary={name.name || name} key={name.id || name} />
              </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default MultipleSelectCheckmarks;

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//components
import MinimartApproval from "./MinimartApproval";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import withHOC from "../../HOC/HOC";

//actions
import { setSearchFilterAction } from "../../constants/Action";
import { getCIMinimartRequestAction } from "./MinimartApprovalAction";
import { setMiniMartDetailsAction } from "../MTPSignupRequest/Actions";

//constants
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  // USER_ROLES,
  SEARCH_FILTER_PAGES,
  CI_MINIMART_FILTER,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";

// Strings

// Utility

class MinimartApprovalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoadingModal: false,
      minimarts: [],
      searchFilter: {},
    };
    this.isCardClick = false;
  }
  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.CI_MINIMART,
        { ...CI_MINIMART_FILTER }
      );
    }
  }

  getCIMinimarts = (request) => {
    this.setState({
      showLoadingModal: true,
      searchFilter: {
        ...request,
      },
    });
    getCIMinimartRequestAction({ ...request }, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          minimarts: response.data.data.mns,
          numberOfPages: response.data.data.nop,
          showLoadingModal: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          minimarts: [],
          errorMessage: response.error.message,
          showLoadingModal: false,
        });
      }
    });
  };
  // #endregion

  setMiniMartDetails = (minimart) => {
    this.isCardClick = true;
    this.props.setMiniMartDetailsAction(minimart);
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.CI_MINIMART,
      { ...this.state.searchFilter },

      SEARCH_FILTER_PAGES.CI_MINIMART
    );
    this.props.history.push(ROUTES.MTP_SIGNUP_REQUEST_DETAILS);
  };

  render() {
    return (
      <div>
        {this.state.showLoadingModal && (
          <LoadingModal
            showLoadingModal={this.state.showLoadingModal}
          />
        )}
        <MinimartApproval
          minimarts={this.state.minimarts}
          handleOnClickCIFilter={this.getCIMinimarts}
          numberOfPages={this.state.numberOfPages}
          searchFilter={this.props.searchFilter}
          setMiniMartDetails={this.setMiniMartDetails}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchFilter: state.searchFilterReducer.ciMinimartSearchFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setMiniMartDetailsAction, setSearchFilterAction },
    dispatch
  );
};
const MinimartApprovalWrapper = withHOC(MinimartApprovalContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinimartApprovalWrapper);

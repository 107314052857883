import { SET_SIGN_UP_MINIMART } from "../../redux/constants";

const INITIAL_STATE = {
  selectedMinimart: { id: "", mn: "", cdid: "", st: "" },
};

export default function SignUpRequestReducer(state = INITIAL_STATE, action) {
  //NOSONAR -default parameter is required first in this case
  switch (
    action.type //NOSONAR
  ) {
    case SET_SIGN_UP_MINIMART:
      return {
        ...state,
        selectedMinimart: action.minimart,
      };
    default:
      return state;
  }
}

// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { API } from "../../constants/APIConstants";
import {
  SET_LOGIN_INFO_ACTION,
  LOGOUT_ACTION,
  CLEAR_LOGIN_INFO_ACTION,
  CLEAR_USER_PROFILE_ACTION,
  CLEAR_USER_DETAILS_ACTION,
  CLEAR_USER_ROLES_ACTION,
  CLEAR_ACTIVE_LOAN_PROVISION_DATA,
  CLEAR_ACTIVE_LOAN_STATUS_DATA,
  CLEAR_ACTIVE_LATE_FEES_DATA,
  CLEAR_CLIENT_LOAN_STATUS,
  CLEAR_BRANCH_DETAILS_ACTION,
  CLEAR_DATA_FOR_EDIT_CONFIGURATION,
  CLEAR_USER_REDUCER_ACTION,
  CLEAR_WORKLOAD_USERS,
  CLEAR_DAILYSTATUS_DATE,
  CLEAR_REPORTS_FILTERS_ACTION,
  CLEAR_EFFICIENCY_ACTION,
  CLEAR_MINIMART_ACTION,
  CLEAR_SEARCH_FILTER_ACTION,
} from "../../redux/constants";
import { callAPI } from "../../config/AxiosTemplate";

/*
 * Actions not using dispatch
 * This will be called directly inside container without mapDispatchToProps
 */

export const forgotPasswordAction = (requestData, callback) => {
  callAPI(
    "ForgotPassword",
    API.forgotPassword,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

/*
 * Actions using dispatch
 * This will be called inside container through props with mapDispatchToProps
 */

/**
 *  Action to handle login
 */
export const loginAction = (requestData, callback) => (dispatch) => {
  callAPI("Login", API.login, "POST", requestData, (apiResponse) => {
    if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
      dispatch({
        type: SET_LOGIN_INFO_ACTION,
        loggedInUserInfo: apiResponse.data,
      });
    }
    callback(apiResponse);
  });
};

export const logoutAction = (callback) => (dispatch) => {
  callAPI("Logout", API.logout, "POST", {}, (apiResponse) => {
    if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
      dispatch({
        type: LOGOUT_ACTION,
      });
      clearReduxDataAction()(dispatch);
    }
    callback(apiResponse);
  });
};

export const setLoginInfoAction = (loginData) => (dispatch) => {
  dispatch({
    type: SET_LOGIN_INFO_ACTION,
    loggedInUserInfo: loginData,
  });
};

export const clearReduxDataAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOGIN_INFO_ACTION,
  });
  dispatch({
    type: CLEAR_USER_PROFILE_ACTION,
  });
  dispatch({
    type: CLEAR_USER_DETAILS_ACTION,
  });
  dispatch({
    type: CLEAR_USER_REDUCER_ACTION,
  });
  dispatch({
    type: CLEAR_USER_ROLES_ACTION,
  });
  dispatch({
    type: CLEAR_BRANCH_DETAILS_ACTION,
  });
  dispatch({
    type: CLEAR_ACTIVE_LOAN_PROVISION_DATA,
  });
  dispatch({
    type: CLEAR_ACTIVE_LOAN_STATUS_DATA,
  });
  dispatch({
    type: CLEAR_ACTIVE_LATE_FEES_DATA,
  });
  dispatch({
    type: CLEAR_CLIENT_LOAN_STATUS,
  });
  dispatch({
    type: CLEAR_DATA_FOR_EDIT_CONFIGURATION,
  });
  dispatch({
    type: CLEAR_WORKLOAD_USERS,
  });
  dispatch({
    type: CLEAR_DAILYSTATUS_DATE,
  });
  dispatch({
    type: CLEAR_REPORTS_FILTERS_ACTION,
  });
  dispatch({
    type: CLEAR_EFFICIENCY_ACTION,
  });
  dispatch({
    type: CLEAR_MINIMART_ACTION,
  });
  dispatch({
    type: CLEAR_SEARCH_FILTER_ACTION,
  });
};

import React from "react";
import { Col, Button, Row } from "react-bootstrap";
import { Strings } from "../../resources/i18n/i18n";
import NumberFormat from "react-number-format";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import _ from "lodash";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";

//Actions
import { DEFAULT_BRANCH_SELECTION } from "../UserList/UserListConstants";

//Constants
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  TABLE_COLUMN,
  COMMISSION_REPORT_DOWNLOAD,
  COMMISSION_REPORT_API_LIMIT_PARAM,
} from "../Comission/Constants";
import {
  SPECIAL_MESSENGER_PRESENTATION,
  SPECIAL,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  COMMISSION_USER_ROLE,
  DOWNLOAD_FILE_TYPE,
} from "../../constants/appConstants";
import { DEFAULT_SELECTION } from "../GeneralReports/Constants";
import { DEFAULT_ZONE } from "../BranchList/BranchListConstants";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./SalesPersonComission.scss";

//Assets
import PDFdownload from "../../resources/images/ic_download_pdf.svg";
import { DEFAULT_MESSENGER_ZONE } from "../BranchList/BranchListConstants";

class SalesPersonComission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branches: props.branches,
      Filters: {
        branchId: props.searchFilter.br,
        zone: props.searchFilter.zn,
        mZone: props.searchFilter.mzn,
        name: props.searchFilter.un,
      },
      amountToPay: [],
      recordsPerPage: props.searchFilter.limit,
      activePage: props.searchFilter.offset,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let derivedState = {};
    if (props.data) {
      if (state.amountToPay.length < props.data.length) {
        let amountInState = [];
        let i;
        for (i = 0; i < props.data.length; i++) {
          amountInState[i] = "";
        }
        derivedState = {
          ...derivedState,
          amountToPay: amountInState,
        };
      }
    }

    return derivedState;
  }

  handleSearchChange = (key, event) => {
    let value = event.target.value;
    let stateObject = {
      Filters: { ...this.state.Filters, [key]: value },
    };
    if (DEFAULT_SELECTION.includes(value)) {
      stateObject = {
        Filters: { ...this.state.Filters, [key]: "", zone: "", mZone: "" },
      };
    }
    this.setState(stateObject, () => {
      if (key === "branchId") {
        let branchId = this.state.Filters.branchId;
        this.props.getZonesByBranch(
          branchId !== DEFAULT_BRANCH_SELECTION ? branchId : ""
        );
      }
    });
  };

  handleZoneChange = (key, event) => {
    let value = event.target.value;
    if (DEFAULT_SELECTION.includes(value)) {
      value = "";
    }
    this.setState({
      Filters: { ...this.state.Filters, [key]: value },
    });
  };

  handleAmountChange = (event, index) => {
    let amountToState = this.state.amountToPay;
    amountToState[index] = event.target.value;
    this.setState({
      amountToPay: amountToState,
      errorMessage: "",
    });
  };

  handleOnClickPay = (row, index) => {
    let value = this.state.amountToPay[index];
    let replaceStateArray = [...this.state.amountToPay];
    replaceStateArray[index] = "";
    // eslint-disable-next-line eqeqeq
    if (value !== 0 && value !== "" && value <= row.uAmt) {
      this.props.payCommissionForSpecificUser(
        {
          paidAmount: value,
          userId: row.id,
        },
        () => {
          this.handleOnClickSearchFilter(false);
          this.setState({
            amountToPay: replaceStateArray,
          });
        }
      );
    } else {
      value = Number(value);
      if (value === 0) {
        this.setState({ errorMessage: Strings("zeroNotAllowed") });
      }

      if (value > row.uAmt) {
        this.setState({
          errorMessage: Strings(
            "amountToPayMustBeLessOrEqualToUnpaid"
          ),
        });
      }
    }
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleSearchFilter(
      {
        branchId:
          this.props.user !== COMMISSION_USER_ROLE.SALES_MODERATOR &&
            this.props.user !==
            COMMISSION_USER_ROLE.MESSENGER_MODERATOR &&
            this.state.Filters.branchId !== ""
            ? this.state.Filters.branchId
            : undefined,
        name:
          this.state.Filters.name !== ""
            ? this.state.Filters.name
            : undefined,
        zone:
          this.props.user !== COMMISSION_USER_ROLE.SALES_MODERATOR &&
            this.props.user !==
            COMMISSION_USER_ROLE.MESSENGER_MODERATOR &&
            this.state.Filters.zone !== ""
            ? this.state.Filters.zone
            : undefined,
        limit: this.state.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.activePage,
      },

    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  handleOnClickResetFilter = () => {
    let tempObj = {
      branchId: "",
      name: "",
      zone: "",
    };
    this.setState({ Filters: { ...tempObj } }, () => {
      this.props.getZonesByBranch();
      this.handleOnClickSearchFilter(true);
    });
  };

  gotoCommissionReport = (user) => {
    this.props.gotoCommissionReport({
      ...user,
      userRole: this.props.user,
    });
  };

  downloadFile = (filetype) => {
    const requestData = {
      branchId:
        this.state.Filters.branchId !== ""
          ? this.state.Filters.branchId
          : null,
      name:
        this.state.Filters.name !== ""
          ? this.state.Filters.name
          : null,
      zone:
        this.state.Filters.zone !== ""
          ? this.state.Filters.zone
          : null,
      limit: COMMISSION_REPORT_API_LIMIT_PARAM,
      offset: 0,
    };
    this.props.downloadReports(
      COMMISSION_REPORT_DOWNLOAD[this.props.user][0],
      requestData,
      filetype
    );
  };

  renderNumberFormatCustom = React.forwardRef((props,ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        type='text'
        decimalScale='2'
        isNumericString
        allowNegative={false}
      />
    );
  });

  renderTableData = (column, row, iterator) => {
    if (
      column.keyValue &&
      column.keyValue !== "un" &&
      column.keyValue !== "input"
    ) {
      return Utility.getCurrencyRepresentationOfAmount(
        Utility.getDecimalNumberUptoDigit(row[column.keyValue], 2)
      );
    } else if (column.keyValue === "input") {
      return (
        <Row>
          <Col md={8} className='pr-0'>
            <TextField
              placeholder={Strings("toBePaid")}
              value={this.state.amountToPay[iterator]}
              onChange={(event) =>
                this.handleAmountChange(event, iterator)
              }
              name='numberformat'
              id='formatted-numberformat-input'
              InputProps={{
                inputComponent: this.renderNumberFormatCustom,
              }}
              variant='standard'
            />
          </Col>
          <Col md={3}>
            <Button
              className='addOrUpdateUserTopButtons alignText'
              onClick={() => this.handleOnClickPay(row, iterator)}
              disabled={row.uAmt === 0}
            >
              <div title={Strings("pay")}>{Strings("pay")}</div>
            </Button>
          </Col>
        </Row>
      );
    } else if (
      column.keyValue === "un" &&
      column.subKey &&
      row[column.subKey] === SPECIAL
    ) {
      return (
        <div
          title={
            row[column.keyValue] +
            "\n" +
            SPECIAL_MESSENGER_PRESENTATION
          }
        >
          {SHORT_SPECIAL_MESSENGER_PRESENTATION +
            " " +
            row[column.keyValue]}
        </div>
      );
    } else {
      return row[column.keyValue];
    }
  };

  renderSearchFilters = () => {
    let placeholderText = "";
    if (this.props.user === COMMISSION_USER_ROLE.SALES) {
      placeholderText = Strings("SALES_PERSON");
    } else if (this.props.user === COMMISSION_USER_ROLE.MESSENGER) {
      placeholderText = Strings("MESSENGER");
    } else if (
      this.props.user === COMMISSION_USER_ROLE.SALES_MODERATOR
    ) {
      placeholderText = Strings("SALES_MODERATOR");
    } else {
      placeholderText = Strings("MESSENGER_MODERATOR");
    }

    let comissionFilters = [];

    if (
      this.props.user === COMMISSION_USER_ROLE.SALES_MODERATOR ||
      this.props.user === COMMISSION_USER_ROLE.MESSENGER_MODERATOR
    ) {
      comissionFilters = [
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: placeholderText,
          handleOnChange: this.handleSearchChange,
          Value: this.state.Filters.name,
          key: "name",
        },
      ];
    }
    else if (this.props.user === COMMISSION_USER_ROLE.SALES) {
      let zones = this.props.zones;
      let branchZones = [];
      branchZones.push({ label: DEFAULT_ZONE, value: DEFAULT_ZONE });
      for (let i = 1; i < zones.length; i++)
        branchZones.push({
          label: zones[i].name,
          name: zones[i].name,
        });
      comissionFilters = [
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleSearchChange,
          Value: this.state.Filters.branchId,
          fieldname: SEARCH_FILTER_FIELDS.BRANCH,
          filterDropdownList: this.state.branches,
          key: "branchId",
        }, {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleZoneChange,
          Value: this.state.Filters.zone,
          fieldname: "zone",
          filterDropdownList: branchZones,
          key: "zone",
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: placeholderText,
          handleOnChange: this.handleSearchChange,
          Value: this.state.Filters.name,
          key: "name",
        },

      ]
    }
    else if (this.props.user === COMMISSION_USER_ROLE.MESSENGER) {
      let mZones = this.props.mZones;
      let branchMZones = [];
      branchMZones.push({ label: DEFAULT_MESSENGER_ZONE, value: DEFAULT_MESSENGER_ZONE });
      for (let i = 1; i < mZones.length; i++)
        branchMZones.push({
          label: mZones[i].name,
          name: mZones[i].name,
        });

      comissionFilters = [
        {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleSearchChange,
          Value: this.state.Filters.branchId,
          fieldname: SEARCH_FILTER_FIELDS.BRANCH,
          filterDropdownList: this.state.branches,
          key: "branchId",
        }, {
          type: SEARCH_FILTER_TYPE.SELECTION,
          handleOnChange: this.handleZoneChange,
          Value: this.state.Filters.mZone,
          fieldname: "mZone",
          filterDropdownList: branchMZones,
          key: "mZone",
        },
        {
          type: SEARCH_FILTER_TYPE.INPUT,
          searchPlaceHolder: placeholderText,
          handleOnChange: this.handleSearchChange,
          Value: this.state.Filters.name,
          key: "name",
        },

      ]

    }
    return (
      <SearchFilter
        filters={comissionFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={true}
      />
    );
  };

  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer component={Paper} className='w-75'>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={
                        column.keyValue === "un"
                          ? "text-left header px-2"
                          : "text-center header px-2"
                      }
                      title={column.label}
                    >
                      <div>{column.label}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow
                  key={row.id}
                  hover
                  style={{ cursor: "pointer" }}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.key}
                      className={
                        column.keyValue === "un"
                          ? "text-left px-3"
                          : column.keyValue === "input" //NOSONAR
                            ? "text-right px-2 py-1"
                            : "text-center px-2"
                      }
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ width: column.minWidth }}
                      onClick={
                        column.keyValue !== "input"
                          ? () =>
                            callBack(
                              row,
                              this.state.userName,
                              this.state.fromDate,
                              this.state.toDate
                            )
                          : () => {
                            //This is intensional
                          }
                      }
                    >
                      <div>
                        {this.renderTableData(column, row, rowIndex)}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    return (
      <div className='salesPersonComission'>
        <Row>
          <Col md={11} className='pl-0'>
            {this.renderSearchFilters()}
          </Col>
          <Col
            md={1}
            className='d-flex justify-content-end alignDownloadButtons pt-2 pl-0'
          >
            <div>
              <Button
                className='buttonBackground'
                onClick={() => {
                  this.downloadFile(DOWNLOAD_FILE_TYPE.PDF);
                }}
              >
                <img src={PDFdownload} alt='' />
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className=' errorText pl-3'>
            {this.state.errorMessage || this.props.errorMessage}
          </Col>
        </Row>
        <Col md={12}>
          {this.props.data &&
            this.props.data.length > 0 &&
            this.props.loggedInUserRole ? (
            <div>
              {this.renderCustomDataGrid(
                TABLE_COLUMN[this.props.loggedInUserRole][
                this.props.user
                ],
                this.props.data,
                this.gotoCommissionReport
              )}

              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </div>
          ) : (
            <div className='noRecordsFound'>
              {Strings("NoRecordsFound")}
            </div>
          )}
        </Col>
      </div>
    );
  }
}

export default SalesPersonComission;

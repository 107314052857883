import { Strings } from "../../resources/i18n/i18n";
const SALES_COMISSION_TABS = {
  key: 0,
  label: Strings("SALES_PERSON"),
  value: "SALES",
  role: "SALES_PERSON",
};
const MESSENGER_COMISSION_TABS = {
  key: 1,
  label: Strings("MESSENGER"),
  value: "MESSENGER",
  role: "MESSENGER",
};
const SALES_MODERATOR_COMISSION_TABS = {
  key: 2,
  label: Strings("SALES_MODERATOR"),
  value: "SALES_MODERATOR",
  role: "SALES_MODERATOR",
};

const MESSENGER_MODERATOR_COMISSION_TABS = {
  key: 3,
  label: Strings("MESSENGER_MODERATOR"),
  value: "MESSENGER_MODERATOR",
  role: "MESSENGER_MODERATOR",
};

export const COMISSION_TABS = {
  ACCOUNTING_OFFICER: [
    SALES_COMISSION_TABS,
    MESSENGER_COMISSION_TABS,
    SALES_MODERATOR_COMISSION_TABS,
    MESSENGER_MODERATOR_COMISSION_TABS,
  ],
  GENERAL_MANAGER: [
    SALES_COMISSION_TABS,
    MESSENGER_COMISSION_TABS,
    SALES_MODERATOR_COMISSION_TABS,
    MESSENGER_MODERATOR_COMISSION_TABS,
  ],
  AUDITOR: [
    SALES_COMISSION_TABS,
    MESSENGER_COMISSION_TABS,
    SALES_MODERATOR_COMISSION_TABS,
    MESSENGER_MODERATOR_COMISSION_TABS,
  ],
};
const SALES_TABLE_COLUMN = [
  {
    key: 0,
    label: Strings("SALES_PERSON"),
    keyValue: "un",
    subKey: "ty",
  },
  {
    key: 1,
    label: Strings("totalAmount") + " ($)",
    keyValue: "amt",
  },
  { key: 2, label: Strings("paid"), keyValue: "pAmt" },
  { key: 3, label: Strings("unpaid"), keyValue: "uAmt" },
];
const MESSENGER_TABLE_COLUMN = [
  {
    key: 0,
    label: Strings("MESSENGER"),
    keyValue: "un",
    subKey: "ty",
  },
  {
    key: 1,
    label: Strings("totalAmount") + " ($)",
    keyValue: "amt",
  },
  { key: 2, label: Strings("paid"), keyValue: "pAmt" },
  { key: 3, label: Strings("unpaid"), keyValue: "uAmt" },
];
const SALES_MODERATOR_TABLE_COLUMN = [
  { key: 0, label: Strings("SALES_MODERATOR"), keyValue: "un" },
  {
    key: 1,
    label: Strings("totalAmount") + " ($)",
    keyValue: "amt",
  },
  { key: 2, label: Strings("paid"), keyValue: "pAmt" },
  { key: 3, label: Strings("unpaid"), keyValue: "uAmt" },
];
const MESSENGER_MODERATOR_TABLE_COLUMN = [
  { key: 0, label: Strings("MESSENGER_MODERATOR"), keyValue: "un" },
  {
    key: 1,
    label: Strings("totalAmount") + " ($)",
    keyValue: "amt",
  },
  { key: 2, label: Strings("paid"), keyValue: "pAmt" },
  { key: 3, label: Strings("unpaid"), keyValue: "uAmt" },
];

export const TABLE_COLUMN = {
  ACCOUNTING_OFFICER: {
    SALES: [
      ...SALES_TABLE_COLUMN,
      { key: 4, label: Strings("payAction"), keyValue: "input" },
    ],
    MESSENGER: [
      ...MESSENGER_TABLE_COLUMN,
      { key: 4, label: Strings("payAction"), keyValue: "input" },
    ],
    SALES_MODERATOR: [
      ...SALES_MODERATOR_TABLE_COLUMN,
      { key: 4, label: Strings("payAction"), keyValue: "input" },
    ],
    MESSENGER_MODERATOR: [
      ...MESSENGER_MODERATOR_TABLE_COLUMN,
      { key: 4, label: Strings("payAction"), keyValue: "input" },
    ],
  },
  GENERAL_MANAGER: {
    SALES: SALES_TABLE_COLUMN,
    MESSENGER: MESSENGER_TABLE_COLUMN,
    SALES_MODERATOR: [...SALES_MODERATOR_TABLE_COLUMN],
    MESSENGER_MODERATOR: [...MESSENGER_MODERATOR_TABLE_COLUMN],
  },
  AUDITOR: {
    SALES: SALES_TABLE_COLUMN,
    MESSENGER: MESSENGER_TABLE_COLUMN,
    SALES_MODERATOR: [...SALES_MODERATOR_TABLE_COLUMN],
    MESSENGER_MODERATOR: [...MESSENGER_MODERATOR_TABLE_COLUMN],
  },
};

export const COMMISSION_REPORT_DOWNLOAD = {
  SALES: [
    "/commission/download?fileType=PDF",
    "/commission/daily/download?fileType=PDF",
    "/commission/detailed/download?fileType=PDF",
  ],
  MESSENGER: [
    "/commission/messenger/download?fileType=PDF",
    "/commission/daily/messenger/download?fileType=PDF",
    "/commission/detailed/messenger/download?fileType=PDF",
  ],
  SALES_MODERATOR: [
    "/commission/download?fileType=PDF",
    "/commission/daily/download?fileType=PDF",
    "/commission/detailed/download?fileType=PDF",
  ],
  MESSENGER_MODERATOR: [
    "/commission/messenger/download?fileType=PDF",
    "/commission/daily/messenger/download?fileType=PDF",
    "/commission/detailed/messenger/download?fileType=PDF",
  ],
};

export const PDF_NAME = {
  SALES: Strings("Sales Commission report"),
  MESSENGER: Strings("Messenger Commission report"),
  SALES_MODERATOR: Strings("Sales Moderator Commission report"),
  MESSENGER_MODERATOR: Strings(
    "Messenger Moderator Commission report"
  ),
};

export const COMMISSION_REPORT_API_LIMIT_PARAM = 100000;

export const COMMISSION_ROUTE = "_COMISSION";

import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";

import { Strings } from "../../../resources/i18n/i18n";

//Utils
import {
  CONFIG_ACTIONS,
  CONFIGURATION_STATUS,
  CONFIGURATION_TYPE,
} from "../../../constants/appConstants";


export default class ConfigurationButtons extends Component {
  handleOnClickAction = (configAction) => {
    this.props.handleOnClickAction(configAction);
  };

  renderActivateButton = () => {
    return (
      <Button
        className='buttonBackground'
        onClick={() =>
          this.props.handleOnClickAction(CONFIG_ACTIONS.activate)
        }
        disabled={
          !this.props.isValid ||
          this.props.state !== CONFIGURATION_STATUS.drafted
        }
      >
        {Strings("Activate").toUpperCase()}
      </Button>
    );
  };

  renderSaveDraftButton = () => {
    return (
      <Button
        className='buttonBackground'
        onClick={() =>
          this.props.handleOnClickAction(CONFIG_ACTIONS.saveAsDraft)
        }
        disabled={!this.props.isValid}
      >
        {Strings("SaveDraft").toUpperCase()}
      </Button>
    );
  };

  renderGoBackButton = () => {
    return (
      <Button
        className='buttonBorder'
        onClick={() =>
          this.props.handleOnClickAction(CONFIG_ACTIONS.goback)
        }
      >
        {Strings("Back").toUpperCase()}
      </Button>
    );
  };

  renderResetButton = () => {
    return (
      <Button
        className='buttonBorder'
        onClick={() =>
          this.props.handleOnClickAction(CONFIG_ACTIONS.reset)
        }
      >
        {Strings("Reset").toUpperCase()}
      </Button>
    );
  };

  renderDeleteDraftButton = () => {
    return (
      <Button
        className='buttonBorder'
        onClick={() =>
          this.props.handleOnClickAction(CONFIG_ACTIONS.deleteDraft)
        }
        disabled={this.props.state !== CONFIGURATION_STATUS.drafted}
      >
        {Strings("DeleteDraft").toUpperCase()}
      </Button>
    );
  };

  render() {
    return (
      <Row>
        <Col md={12}>
          {this.props.type !== CONFIGURATION_TYPE.CREDIT_SCORE &&
            this.props.type !==
              CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE && <hr />}
          <div className='ml-2 buttonsCol pull-right inputFields'>
            {!this.props.readOnly && this.renderActivateButton()}
          </div>
          <div className='ml-2 buttonsCol pull-right inputFields'>
            {!this.props.readOnly && this.renderSaveDraftButton()}
          </div>

          <div className='ml-2 buttonsCol pull-left inputFields'>
            {!this.props.noButtons && this.renderGoBackButton()}
          </div>

          <div className='ml-2 buttonsCol pull-left inputFields'>
            {!this.props.readOnly && this.renderResetButton()}
          </div>
          <div className='ml-2 pull-left inputFields'>
            {!this.props.readOnly && this.renderDeleteDraftButton()}
          </div>
        </Col>
      </Row>
    );
  }
}

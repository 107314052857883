import { SET_CURRENT_FEATURE_ACTION } from "../../redux/constants";

export const setSelectedFeatureAction =
  (feature, callback) => (dispatch) => {
    dispatch({
      type: SET_CURRENT_FEATURE_ACTION,
      feature: feature,
    });
    callback();
  };

import React, { Component } from "react";

import { Strings } from "../../../resources/i18n/i18n";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "../InputModal/InputModal.scss";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Table } from "react-bootstrap";
import Utility from "../../../utils/Utility";

class InformativeModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loanNote: "",
            showErrorMessage: false
        }
    }
    handleEmptyNotes = () => {
        this.setState({
            showErrorMessage: true
        })
    }
    renderModalButtons = () => {
        let isEmptyNotesData = this.state.loanNote.trim().length > 0;
        return (
            <Col md={12}>
                <Button
                    className='buttonBackground primaryModalButton buttonMinWidth'
                    onClick={this.props.modalHeader === Strings("clientStatusChange")
                        ?
                        () => { isEmptyNotesData ? this.props.handleOkClick(this.state.loanNote) : this.handleEmptyNotes() }
                        :
                        () => this.props.handleOkClick()}
                >
                    {Strings("Set")}
                </Button>

                <Button
                    className={
                        " primaryModalButton buttonMinWidth marginCancelButton buttonBorder"
                    }
                    onClick={this.props.closeModal}
                >
                    {Strings("Cancel")}
                </Button>
            </Col>
        );
    };
    getRdtVal = (value) => {
        return value !== null
            ? Utility.getReportFormattedDateString(value)
            : Strings("NotAvailable");
    };
    renderModalCancelButton = () => {
        return (
            <Col>
                <Button
                    className={
                        " primaryModalButton buttonMinWidth marginCancelButton buttonBorder"
                    }
                    onClick={this.props.closeModal}
                >
                    {Strings("Cancel")}
                </Button>
            </Col>
        )
    }
    renderConciliatedLoanDetails = (loanData) => {
        const totalUpc = loanData?.reduce((sum, item) => sum + (item.upc || 0), 0);
        const totalUpi = loanData?.reduce((sum, item) => sum + (item.upi || 0), 0);
        const totalUplf = loanData?.reduce((sum, item) => sum + (item.uplf || 0), 0);
        const showSummary = loanData?.length > 1;
        return (
            <div className="conciliationDetails pt-2 pl-3 pr-3">
                {loanData && <Row className="pt-2 pb-3">
                    <Col md={6}>
                        <span>{Strings("conciliationBy")}</span> : {this.props.conciliatedLoanDetails.cby}
                    </Col>
                    <Col md={6}>
                        <span>{Strings("conciliationDate")}</span> : {this.getRdtVal(this.props.conciliatedLoanDetails.cdt)}
                    </Col>
                </Row>}
                {loanData ?
                    (<Table className="conciliationDetailsTable" striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>{Strings("LoanId")}</th>
                                {loanData?.map((item, index) => (
                                    <th key={index}> {item.lid}</th>
                                ))}
                                {showSummary && <th>{Strings("Summary")}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="label-cell">{Strings("unpaidCapital")}</td>
                                {loanData?.map((item, index) => (
                                    <td key={index}>
                                        {item.upc ? Utility.getCurrencyRepresentationOfAmount(item.upc) : 0}
                                    </td>
                                ))}
                                {showSummary && <td>
                                    {Utility.getCurrencyRepresentationOfAmount(totalUpc)}
                                </td>}
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("unPaidInterest")}</td>
                                {loanData?.map((item, index) => (
                                    <td key={index}>
                                        {item.upi ? Utility.getCurrencyRepresentationOfAmount(item.upi) : 0}
                                    </td>
                                ))}
                                {showSummary && <td>
                                    {Utility.getCurrencyRepresentationOfAmount(totalUpi)}
                                </td>}
                            </tr>
                            <tr>
                                <td className="label-cell">{Strings("unpaidLateFees")}</td>
                                {loanData?.map((item, index) => (
                                    <td key={index}>
                                        {item.uplf ? Utility.getCurrencyRepresentationOfAmount(item.uplf) : 0}
                                    </td>
                                ))}
                                {showSummary && <td>
                                    {Utility.getCurrencyRepresentationOfAmount(totalUplf)}
                                </td>}
                            </tr>
                        </tbody>
                    </Table>) :
                    <div className='noRecordsFound'>
                        {Strings("NoRecordsFound")}
                    </div>}
            </div>
        );
    }
    handleLoanNote = (event) => {

        this.setState({
            loanNote: event.target.value,
            showErrorMessage: false
        });
    };
    showLoanInformation = () => {
        let loanInfolength;
        return (
            <Row className="p-0 loanInformation" style={{ overflow: "auto", maxHeight: "23em" }}>

                {this.props.minimartInfo.map((minimart) => {
                    loanInfolength = minimart.loanInfo.length
                    return (
                        <React.Fragment key={minimart.minimartId}>
                            <Col md={12} key={minimart.minimartId} className="pr-0 pl-0 pt-2 ">
                                <span className='titleText'>
                                    {Strings("MatricesID")} &nbsp;:&nbsp;
                                </span>
                                <span className='detailText'>
                                    {minimart.minimartId}
                                </span>
                            </Col>
                            <Col md={12} className="pb-3 pr-0 pl-0">
                                <span className='titleText'>
                                    {Strings("applicationId(S)")} &nbsp;:&nbsp;
                                </span>
                                {minimart.loanInfo.map((loan, index) => (
                                    <span key={loan.applicationId} className='detailText'>
                                        {loan.applicationId + `  (${Strings(loan.status)})`}
                                        {index < loanInfolength - 1 && ', '}
                                    </span>
                                ))}
                            </Col>

                        </React.Fragment>
                    )
                })}
            </Row>);
    }
    showNotes = () => {
        return (
            <Row className='pt-2 pr-2 pl-2 pb-2'>
                <div className='loanNoteInputField '>
                    <textarea
                        placeholder={Strings("NoteForBlackListingCedula")}
                        className='topMargin form-control noteTextArea'
                        onChange={this.handleLoanNote}
                        value={this.state.loanNote}
                        cols={100}
                        rows={3}
                        maxLength={500}
                    />
                </div>
            </Row>
        );
    }
    render() {
        let showLoanInfo = this.props?.minimartInfo?.length > 0
        let isClientInfoScreen = this.props.modalHeader === Strings("clientStatusChange")
        let conciliatedLoanDetailsInfoScreen = this.props.modalHeader === Strings("conciliatedLoanDetailsFor") ? true : false
        return (
            <div>
                {/* general modal */}
                <Modal
                    // Change visibility of modal based on props.
                    open={this.props.openModal}
                    onClose={this.props.closeModal}
                    closeOnEsc={false}
                    closeOnOverlayClick={false}
                    classNames={{
                        modal: conciliatedLoanDetailsInfoScreen ? "conciliationDetailsModal" : "customModal"
                    }}
                    showCloseIcon={false}
                    center
                >
                    <Row className='inputModal'>
                        <Col md={12} className='headerModal'>
                            {this.props.modalHeader}
                        </Col>
                        <Col md={12} className='modalContent'>
                            {showLoanInfo &&
                                <Row className='warningText pb-3 pt-3'>
                                    {Strings("activeLoansList")}
                                </Row>}
                            {showLoanInfo && this.showLoanInformation()}
                            {this.props.data?.split(/\n/).map((line) => {
                                return <div key={line}>{line}</div>
                            })}
                            {isClientInfoScreen && <Row className="message blackListQuestion pb-2 mt-0 pt-2">{this.props.commonMinimartInfo}</Row>}
                            {isClientInfoScreen && this.state.showErrorMessage && <Row className="errorMessage pb-2">
                                {Strings("errorEmptyNote")}</Row>}
                            {isClientInfoScreen ? this.showNotes() : null}
                            {conciliatedLoanDetailsInfoScreen && this.renderConciliatedLoanDetails(this.props.conciliatedLoanDetails?.cldd)}
                            {conciliatedLoanDetailsInfoScreen
                                ? <Row className={"modalButton"}>
                                    {this.renderModalCancelButton()}
                                </Row>
                                : <Row className={`modalButton ${isClientInfoScreen && "blackListModalbutton"}`}>
                                    {this.renderModalButtons()}
                                </Row>}

                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}


export default InformativeModal;

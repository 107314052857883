import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  SET_USER_PROFILE_ACTION,
  SET_PROFILE_PIC_ACTION,
  UPDATE_PROFILE_INFO_ACTION,
} from "../../redux/constants";
import Utility from "../../utils/Utility";
import { USER_ROLES } from "../../constants/appConstants";

/*
 * Actions not using dispatch
 * This will be called directly inside container without mapDispatchToProps
 */

export const updateUserProfileAction = (userProfile, callback) => {
  let requestData = {
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    phone: userProfile.phone,
    email: userProfile.email,
    photo: userProfile.photo,
    role: userProfile.role,
    username: userProfile.username,
    branches: userProfile.branchIds,
    reportingToUserId: userProfile.reportingToUserId,
    type: userProfile.type,
    aub: userProfile.aub,
    zones: userProfile.zones,
    collectionOfficer: userProfile.collectionOfficer
      ? userProfile.collectionOfficer
      : null,
    position: userProfile.position ? userProfile.position : null,
    conciliationAccess: userProfile.conciliationAccess,
  };
  callAPI(
    "UpdateUserProfile",
    API.updateUserProfile.replace("username", userProfile.username),
    "PUT",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const uploadProfilePicAction = (profilePicData, callback) => {
  let requestInfo = new FormData();
  requestInfo.append("username", profilePicData.username);
  requestInfo.append("file", profilePicData.file);
  callAPI(
    "UploadProfilePic",
    API.uploadProfilePic,
    "POST",
    requestInfo,
    (apiResponse) => {
      callback(apiResponse);
    }, false, true
  );
};

/**
 *  Action to create new user
 */
export const createUserAction = (userProfile, callback) => {
  let branch = userProfile.branch;
  branch.forEach((singleBranch, index) => {
    if (
      (typeof singleBranch === typeof "" &&
        singleBranch.length > 1) ||
      singleBranch === null
    ) {
      delete branch[index];
    }
  });
  let requestData = {
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    phone: userProfile.phone,
    email: userProfile.email,
    photo: userProfile.photo,
    role: userProfile.role,
    username: userProfile.username,
    branches: branch,
    reportingToUserId: userProfile.reportingToUserId,
    type: userProfile.type,
    aub: userProfile.aub,
    zones: userProfile.zones,
    collectionOfficer: userProfile.collectionOfficer
      ? userProfile.collectionOfficer
      : null,
    position: userProfile.position ? userProfile.position : null,
    conciliationAccess: userProfile.conciliationAccess,
  };
  callAPI(
    "CreateUser",
    API.createUser,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

/**
 *  Action to deactivate user
 */
export const deactivateUserAction = (username, callback) => {
  callAPI(
    "DeactivateUser",
    API.deactivateUser + username,
    "DELETE",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

/*
 * Actions using dispatch
 * This will be called inside container through props with mapDispatchToProps
 */

export const getUserProfileAction =
  (userName, callback) => (dispatch) => {
    callAPI(
      "GetUserProfile",
      API.getUserProfile.replace("username", userName),
      "GET",
      null,
      (apiResponse) => {
        dispatch({
          type: SET_USER_PROFILE_ACTION,
          data: apiResponse.data.data,
        });
        callback(apiResponse);
      }
    );
  };

export const getUserProfilePicUrlAction =
  (userName, callback, isLoggedinUser) => (dispatch) => {
    callAPI(
      "GetProfilePicUrl",
      API.getProfilePicUrl.replace("username", userName),
      "GET",
      null,
      (apiResponse) => {
        if (
          apiResponse.status === OPERATION_STATUS.SUCCESS &&
          isLoggedinUser
        ) {
          let s3Url = null;
          if (apiResponse.data.data.s3Url) {
            s3Url = Utility.getDecryptedData(
              apiResponse.data.data.s3Url
            );
          }
          dispatch({
            type: SET_PROFILE_PIC_ACTION,
            profilePic: s3Url,
          });
        }
        callback(apiResponse);
      }
    );
  };

export const getReportingUsersAction = (requestData, callback) => {
  let requestString = "?role=" + requestData.Role;
  if (requestData.branches.length > 0) {
    requestString =
      requestString + "&branchIds=" + requestData.branches;
  }
  callAPI(
    "getReportingUsers",
    API.getReportingUsers.replace("/role", requestString),
    "GET",
    null,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getCollectionOfficersAction = (
  requestData,
  callback
) => {
  let requestString = "?role=" + USER_ROLES.collectionsOfficer;
  if (requestData.branches.length > 0) {
    requestString =
      requestString + "&branchIds=" + requestData.branches;
  }
  callAPI(
    "getReportingUsers",
    API.getReportingUsers.replace("/role", requestString),
    "GET",
    null,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getPositionsAction = (requestData, callback) => {
  let requestString = "?role=" + requestData.Role;
  if (requestData.branches.length > 0) {
    requestString =
      requestString + "&branchId=" + requestData.branches;
  }
  callAPI(
    "getPositions",
    API.getPositions.concat(requestString),
    "GET",
    null,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const updateProfileInfoAction =
  (updatedProfileData) => (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_INFO_ACTION,
      updatedProfileData,
    });
  };

export const getDeactivationAndChangeRoleStatusofUser = (requestData, callback) => {
  let requestString = "tbdid=" + requestData;

  callAPI(
    "getDeactivationAndChangeRoleStatus",
    API.deactivationAndChangeRoleStatus.concat(requestString),
    "GET",
    null,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getAvailabilityStatusOfUser = (requestData, callback) => {
  let requestString = "?oui=" + requestData;

  callAPI(
    "getAvailabilityStatusOfUser",
    API.availabilityStatus.concat(requestString),
    "GET",
    null,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const getSameRoleUserList = (requestData, callback) => {
  let requestString = "?uid=" + requestData;

  callAPI(
    "getSameRoleUserList",
    API.sameRoleUserList.concat(requestString),
    "GET",
    null,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const workloadDistribution = (requestData, callback) => {
  callAPI(
    "workloadDistribution",
    API.workloadDistribution,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const workloadDistributionForUnavailableUsers = (requestData, callback) => {
  callAPI(
    "workloadDistributionForUnavailableUsers",
    API.workloadDistribution + "/availability",
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const workloadDistributionForChangeRole = (requestData, callback) => {
  callAPI(
    "workloadDistributionForChangeRole",
    API.workloadDistribution + "/change-role",
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const changeAvailabilityStatus = (requestData, callback) => {
  callAPI(
    "changeAvailabilityStatus",
    API.changeAvailabilityStatus,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const changeRole = (requestData, callback) => {
  callAPI(
    "changeRole",
    API.changeRole,
    "POST",
    requestData,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};
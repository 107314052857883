import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import PromotionDetails from "./PromotionDetails";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";

//Action
import {
  getPromotionDetailsAction,
  updateAnalysisPromotionStatusAction,
} from "./PromotionDetailsAction";
import { getLoansBySearchFilterAction } from "../MinimartDetails/MiniMartDetailsAction";
import { setSearchFilterAction } from "../../constants/Action";
import { setLoanDetailsIDAction } from "../LoanDetails/LoanDetailsAction";
import {
  getNoteAction,
  AddNoteAction,
} from "../AnalystApproval/AnalystApprovalAction";

// Constant
import {
  SEARCH_FILTER_PAGES,
  PROMOTION_APPROVAL_FILTER,
  PROMOTION_LOAN_DETAIL_FILTER,
} from "../../constants/appConstants";
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";

// String
import { Strings } from "../../resources/i18n/i18n";

class PromotionDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      promotionDetails: null,
      loans: [],
      numberOfPages: 0,
      note: "",
      toShowToast: false,
      successToastMessage: "",
      level: "",
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.getPromotionDetails(this.props.singlePromotion.prrid);
  }

  componentWillUnmount() {
    if (
      this.props.searchPage ===
        SEARCH_FILTER_PAGES.PROMOTION_APPROVAL &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.PROMOTION_APPROVAL,
        {
          ...PROMOTION_APPROVAL_FILTER,
        }
      );
    }
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.PROMOTION_LOAN_DETAIL,
        { ...PROMOTION_LOAN_DETAIL_FILTER }
      );
    }
  }

  getNote = (noteId) => {
    this.setState({ showLoader: true });
    getNoteAction(noteId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          note: response.data.data,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoader: false,
          errorMessage: response.error.message,
        });
      }
    });
  };

  addNote = (request, successCallback, errorCallback) => {
    this.setState({ showLoader: true });
    AddNoteAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
          },
          () => successCallback(response.data.data.id)
        );
      } else {
        this.setState(
          {
            showLoader: false,
          },
          () => {
            this.scrollToTop();
            errorCallback(response.error.message);
          }
        );
      }
    });
  };

  handleOnClickSearchFilter = (request) => {
    this.setState({
      showLoadingModal: true,
      errorMessage: "",
      searchFilter: {
        st: request.search.status,
        ct: request.search.category,
        fd: request.search.fromDate,
        td: request.search.toDate,
        //mid: request.search.matricesId,
        limit: request.limit,
        offset: request.offset,
      },
    });
    getLoansBySearchFilterAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (response.data.data.loans.length === 0) {
          this.setState({
            loans: [],
            numberOfPages: response.data.data.numberOfPages,
            showLoader: false,
            errorMessage: "",
          });
        } else {
          this.setState({
            loans: response.data.data.loans,
            numberOfPages: response.data.data.numberOfPages,
            showLoader: false,
            errorMessage: "",
          });
        }
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  getPromotionDetails = (promotionId) => {
    this.setState({ showLoader: true });
    getPromotionDetailsAction(promotionId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            promotionDetails: response.data.data,
            showLoader: false,
          },
          () => {
            response.data.data.pd.status === "REJECTED" &&
              response.data.data.pd.note &&
              this.getNote(response.data.data.pd.note);
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          promotionDetails: null,
          showLoader: false,
        });
      }
    });
  };

  setLoanDetailsID = (loanDetails) => {
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.PROMOTION_LOAN_DETAIL,
      this.state.searchFilter,
      SEARCH_FILTER_PAGES.PROMOTION_LOAN_DETAIL
    );
    this.props.setLoanDetailsIDAction(loanDetails);
    this.props.history.push(ROUTES.LOAN_APPLICATION_DETAILS);
  };

  updateAnalysisPromotionStatus = (
    request,
    toastMessage,
    successCallback,
    errorCallback
  ) => {
    this.setState({ showLoader: true });
    updateAnalysisPromotionStatusAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            toShowToast: true,
            successToastMessage: Strings(toastMessage),
            level: "success",
          },
          successCallback
        );
        setTimeout(() => {
          this.props.history.push(ROUTES.MTP_PROMOTIONS_APPROVAL);
        }, 2000);
      } else {
        this.setState(
          {
            errorMessage: response.error.message,
            successToastMessage: response.error.message,
            showLoader: false,
            toShowToast: true,
            level: "error",
          },
          () => {
            errorCallback(response.error.message);
          }
        );
      }
    });
  };

  goBack = () => {
    this.props.history.push(ROUTES.MTP_PROMOTIONS_APPROVAL);
  };

  render() {
    return (
      <div>
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.successToastMessage}
            userNotificationObj={{
              message: this.state.successToastMessage,
              level: this.state.level,
            }}
          />
        )}
        {this.state.showLoader && (
          <LoadingModal showLoadingModal={this.state.showLoader} />
        )}
        <PromotionDetails
          promotionDetails={this.state.promotionDetails}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          loans={this.state.loans}
          numberOfPages={this.state.numberOfPages}
          setLoanDetailsID={this.setLoanDetailsID}
          searchFilter={this.props.searchFilter}
          updateAnalysisPromotionStatus={
            this.updateAnalysisPromotionStatus
          }
          singlePromotion={this.props.singlePromotion}
          addNote={this.addNote}
          goBack={this.goBack}
          note={this.state.note}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  singlePromotion: state.promotionsApprovalReducer.singlePromotion,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
  searchFilter: state.searchFilterReducer.promotionLoanDetailFilter,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setSearchFilterAction, setLoanDetailsIDAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionDetailsContainer);

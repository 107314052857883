import React, { Component } from "react";
import { Col, Row, Collapse } from "react-bootstrap";
import CardActions from "@mui/material/CardActions";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import _ from "lodash";
//Custom components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";

//constants
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DATE_TYPE,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  LOAN_TYPE,
  DEFAULT_SALES_PERSON,
  INVOICE_DATE_FORMAT,
  DEFAULT_LOAN_TYPE,
  CURRENCY,
  USER_ROLES,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  FIRST_ROW_COLUMNS,
  SECOND_ROW_COLUMNS,
  LOAN_REQUEST_STATUSES,
  LOAN_STATUSES,
  SECOND_ROW_COLUMNS_PRODUCT_MOTORCYCLE,
  SECOND_ROW_COLUMNS_EXT_FACT_PERSONAL_INFO,
  SECOND_ROW_COLUMNS_EXT_FACT_SUPPLIER_DETAILS,
} from "./Constants.js";
import { LOAN_TYPES } from "../AnalystApproval/Constants.js";

//Utility
import Utility from "../../utils/Utility";

//Strings
import { Strings } from "../../resources/i18n/i18n";
class LoanRequests extends Component {
  constructor(props) {
    super(props);
    this.columns = FIRST_ROW_COLUMNS;
    this.secondRow = SECOND_ROW_COLUMNS;
    this.state = {
      filters: {
        from: DEFAULT_DURATION.fromDate,
        to: DEFAULT_DURATION.toDate,
        status: LOAN_REQUEST_STATUSES[0].value,
        loanType: "",
        salesPerson: "",
      },
      userList: props.userList,
      expanded: [],
      confirmationModalInformation: {
        show: false,
      },
      notesModal: false,

      //Pagination
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.userList, this.props.userList)) {
      this.setState({ userList: this.props.userList });
    }
    if (!_.isEqual(prevProps.rows, this.props.rows)) {
      this.setState({ rows: this.props.rows });
    }
  }

  handleSearchDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.filters.to)) {
          DateValue = this.state.filters.from;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        this.setState({
          filters: {
            ...this.state.filters,
            from: DateValue,
          },
        });
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.filters.from)) {
          DateValue = this.state.filters.to;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        if (dateMoment.isAfter(new Date())) {
          DateValue = new Date();
        }
        this.setState({
          filters: {
            ...this.state.filters,
            to: DateValue,
          },
        });
        break;
      default:
    }
  };

  handleOnClickSearchFilter = (isDefaultOffset = true) => {
    isDefaultOffset &&
      this.setState({
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        expanded: [],
      });
    let searchFilterObject;
    searchFilterObject = {
      st: this.state.filters.status,
      lt:
        this.state.filters.loanType &&
          this.state.filters.loanType !== DEFAULT_LOAN_TYPE
          ? this.state.filters.loanType
          : undefined,
      fd: this.state.filters.from
        ? Utility.convertDateIntoMiliSeconds(this.state.filters.from)
        : "",
      td: this.state.filters.to
        ? Utility.convertDateIntoMiliSeconds(this.state.filters.to)
        : "",
      sp: this.state.filters.salesPerson
        ? this.state.filters.salesPerson
        : undefined,
      lmt: this.state.recordsPerPage,
      off: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
        : this.state.activePage - 1,
    };

    this.props.handleOnClickSearchFilter(searchFilterObject);
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        filters: {
          from: DEFAULT_DURATION.fromDate,
          to: DEFAULT_DURATION.toDate,
          status: LOAN_REQUEST_STATUSES[0].value,
          loanType: "",
          salesPerson: "",
        },
        expanded: [],
        userList: this.props.userList,

        //Pagination
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      },

      this.handleOnClickSearchFilter
    );
  };

  handleOnChangeSelection = (event) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [event.target.name]: event.target.value,
      },
    });
  };

  renderSearchFilter = () => {
    let userList = [DEFAULT_SALES_PERSON(), ...this.state.userList];

    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeSelection,
        Value: this.state.filters.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: LOAN_REQUEST_STATUSES,
        name: "status",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeSelection,
        Value: this.state.filters.loanType,
        fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
        filterDropdownList: LOAN_TYPE,
        name: "loanType",
      },

      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleSearchDateChange,
        fromDate: this.state.filters.from,
        handleCalenderChange: this.handleSearchDateChange,
        toDate: this.state.filters.to,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeSelection,
        Value: this.state.filters.salesPerson,
        fieldname: SEARCH_FILTER_FIELDS.SALES_PERSON,
        filterDropdownList: userList,
        name: "SALES_PERSON",
      },
    ];
    return (
      <SearchFilter
        filters={searchFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={2}
      />
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  handleExpandClick = (id) => {
    let expandIndexId = this.state.expanded;
    const index = expandIndexId.indexOf(id);
    expandIndexId.includes(id)
      ? expandIndexId.splice(index, 1)
      : expandIndexId.push(id);
    this.setState({
      expanded: expandIndexId,
    });
  };

  toggleModal = () => {
    this.setState(
      {
        note: "",
        notesModal: !this.state.notesModal,
      },
      () => {
        this.handleOnClickSearchFilter(false);
      }
    );
  };

  addNote = (note) => {
    this.setState(
      {
        note: note,
      },
      () => {
        this.props.updateLoanRequest(
          {
            note: this.state.note,
            id: this.state.reqId,
            mid: this.state.minimartId,
            rt: "DISMISSED",
          },
          this.toggleModal
        );
      }
    );
  };

  onDaySelection = (dayObject) => {
    let date = Utility.daySelectionForSellerAssignment(dayObject.day)
    this.setState({ date });
  };

  renderConfirmationModal = () => {
    return (
      <UserModal
        open={this.state.confirmationModalInformation.show}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={this.state.confirmationModalInformation.type}
        modalText1={this.state.confirmationModalInformation.text}
        modalButtonOk={{
          text: Strings("Set"),
          onClick:
            this.state.confirmationModalInformation.onClickOkCallback,
        }}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick:
            this.state.confirmationModalInformation
              .onClickCancelCallback,
        }}
        showHr={true}
        handleRadioChange={this.onDaySelection}
        note={Strings("visitNote")}
      />
    );
  };

  closeConfirmationModal = () => {
    this.setState({
      confirmationModalInformation: {
        show: false,
      },
    });
  };

  handleYesClick = (minimartId, reqId) => {
    this.closeConfirmationModal();
    this.props.updateLoanRequest(
      {
        mid: minimartId,
        id: reqId,
        ad: Utility.convertDateIntoMiliSeconds(this.state.date),
        rt: "ASSIGNED",
      },
      this.handleOnClickSearchFilter
    );
  };

  assignSalesPerson = (minimartId, reqId) => {
    return this.setState({
      confirmationModalInformation: {
        type: Strings("salesPersonAssignment"),
        show: true,
        text: Strings("assignSalesPerson"),
        onClickOkCallback: () =>
          this.handleYesClick(minimartId, reqId),
        onClickCancelCallback: this.closeConfirmationModal,
      },
    });
  };

  onDeleteClick = (minimartId, reqId) => {
    this.setState({
      notesModal: true,
      reqId: reqId,
      minimartId: minimartId,
    });
  };

  buttonCondition = (row, loan) => {
    if (
      row["st"] === LOAN_STATUSES.VISITED ||
      row["st"] === LOAN_STATUSES.ASSIGNED ||
      row["st"] === LOAN_STATUSES.PENDING
    ) {
      return (
        <Row>
          <Col
            className={this.props.loggedInUser.role === USER_ROLES.salesManager
              ? 'cursorDisabled px-0'
              : 'cursorPointer px-0'}
            onClick={this.props.loggedInUser.role === USER_ROLES.salesManager
              ? () => { }
              : () => this.onDeleteClick(row.mid, loan.id)}
          >
            {this.props.loggedInUser.role === USER_ROLES.salesManager
              ? <i
                className='px-1 fa fa-ban'
                aria-hidden='true'
                title='Eliminar Subtipo'
              />
              : <i
                className='px-1 fa fa-trash'
                aria-hidden='true'
                title='Eliminar Subtipo'
              />}
            <span className={this.props.loggedInUser.role === USER_ROLES.salesManager
              ? "content url"
              : "content url textColor"}>
              {Strings("Delete")}
            </span>
          </Col>
        </Row>
      );
    } else if (row["st"] === LOAN_STATUSES.DISMISSED) {
      return null;
    }
  };

  assignButton = (row) => {
    const ExpandMore = styled((props) => {
      const { expand, ...other } = props;
      return <IconButton {...other} />;
    })(({ theme, expand }) => ({
      transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    }));
    return (
      <Row>
        {row["st"] === LOAN_STATUSES.PENDING ? (
          <Col
            className={this.props.loggedInUser.role === USER_ROLES.salesManager
              ? 'cursorDisabled px-0 d-flex justify-content-end align-center'
              : 'cursorPointer px-0 d-flex justify-content-end align-center'}
            onClick={this.props.loggedInUser.role === USER_ROLES.salesManager
              ? () => { }
              : () => this.assignSalesPerson(row.mid, row.id)}
            md={10}
            title={Strings("resolveLoanRequestNotAllowed")}
          >
            {this.props.loggedInUser.role !== USER_ROLES.salesManager
              ? <i className='px-1 fa fa-user ' />
              : <i className='px-1 fa fa-ban ' />}

            <span className={this.props.loggedInUser.role === USER_ROLES.salesManager
              ? "content url disabled"
              : "content url textColor"}>
              {Strings("salesPersonAssignment")}
            </span>
          </Col>
        ) : null}
        <Col
          key={Math.random()}
          md={
            row["st"] === LOAN_STATUSES.PENDING
              ? { span: 2 }
              : { span: 2, offset: 10 }
          }
        >
          <ExpandMore
            expand={this.state.expanded.includes(row.mid)}
            onClick={() => this.handleExpandClick(row.mid)}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Col>
      </Row>
    );
  };

  collapseSection = (loan, mid, row) => {
    if (loan.lt === LOAN_TYPES.CONSUMER || loan.lt === LOAN_TYPES.MOTORCYCLE_LOAN) {
      this.secondRow = SECOND_ROW_COLUMNS_PRODUCT_MOTORCYCLE
    } else {
      this.secondRow = SECOND_ROW_COLUMNS
    }
    return (
      <Collapse
        key={Math.random()}
        in={this.state.expanded.includes(mid)}
        timeout={200}
        unmountOnExit
      >
        <CardContent className='pt-0 arrowPointer tableRow'>
          <hr className={"my-2"} />
          <Row>
            {this.secondRow.map((column) => {
              let expandedData;
              if (column.key === "amt" || column.key === "emi") {
                expandedData =
                  CURRENCY +
                  Utility.getCurrencyRepresentationOfAmount(
                    loan[column.key]
                  );
              } else if (
                column.key === "cd" ||
                column.key === "dat"
              ) {
                expandedData =
                  loan[column.key] &&
                  moment(loan[column.key]).format(
                    INVOICE_DATE_FORMAT
                  );
              } else {
                expandedData = Strings(loan[column.key]);
              }

              let span;
              if (
                column.key === "lt" ||
                column.key === "btn" ||
                column.key === "cd" ||
                column.key === "dat") {
                span = 3;
              } else if (column.key === "nt") {
                span = 7;
              } else if (column.key === "pn") {
                span = 4;
              } else {
                span = 2;
              }
              return (
                column.key !== "btn" && loan[column.key] ? <Col
                  md={span}
                  key={Math.random()}
                  className={"text-left py-1"}
                >
                  <span className={"label"}>
                    {Strings(column.name)}
                    {" : "}
                  </span>
                  <span className={"content"}>
                    {Strings(expandedData)}
                  </span>
                </Col>
                  : column.key === "btn" && <Col
                    md={span}
                    key={Math.random()}
                    className={
                      "text-center py-1"
                    }
                  >
                    <span className={"content"}>
                      {this.buttonCondition(row, loan)}
                    </span>
                  </Col>
              );
            })}
          </Row>
          {loan.lt === LOAN_TYPES.EXTERNAL_FACTORING && <>
            <Col md={12} className="headerContent mt-2">{Strings("supplierInfo")}</Col>
            <Row>
              <Col md={3}>
                <span className={"label"}>
                  {Strings("sdt")}
                  {" : "}
                </span>
                <span className={"content"}>
                  {Strings(loan.si.sdt)}
                </span>
              </Col>
              <Col md={3}>
                <span className={"label"}>
                  {Strings("stat")}
                  {" : "}
                </span>
                <span className={"content"}>
                  {Strings(loan.si.stat)}
                </span>
              </Col>
            </Row>
            {loan.si.sd && <Row>
              {
                SECOND_ROW_COLUMNS_EXT_FACT_SUPPLIER_DETAILS.map((supplierInfo) => {
                  return <Col md={3}>
                    <span className={"label"}>
                      {Strings(supplierInfo.name)}
                      {" : "}
                    </span>
                    <span className={"content"}>
                      {Strings(loan.si.sd[supplierInfo.key])}
                    </span>
                  </Col>
                })
              }
            </Row>}
            {loan.si.ainfo && <Row className="mt-2">
              <Col md={12} className="headerContent">{Strings("bankDetails")}</Col>
              {SECOND_ROW_COLUMNS_EXT_FACT_PERSONAL_INFO.map((supplierInfo) => {
                return <Col md={3}>
                  <span className={"label"}>
                    {Strings(supplierInfo.name)}
                    {" : "}
                  </span>
                  <span className={"content"}>
                    {Strings(loan.si.ainfo[supplierInfo.key])}
                  </span>
                </Col>
              })}
            </Row>}
          </>
          }
        </CardContent>
      </Collapse >
    );
  };

  renderLoanRequestList = (rows, columns) => {
    return (
      <div className='mx-5'>
        <Card className={"mt-3 headerRow py-2"}>
          <CardActions disableSpacing>
            {columns.map((column) => {
              return (
                <Col
                  md={column.key === "mn" ? 3 : 2}
                  className={
                    column.key === "action"
                      ? "text-right"
                      : "text-center"
                  }
                  key={Strings(column.name)}
                >
                  <Typography className={"headerContent"}>
                    {Strings(column.name)}
                  </Typography>
                </Col>
              );
            })}
          </CardActions>
        </Card>
        {rows.map((row) => {
          return (
            <Card
              key={Math.random()}
              className={
                this.state.expanded.includes(row.mid)
                  ? "highlighted tableRow py-1"
                  : "tableRow py-1"
              }
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <CardActions>
                {columns.map((column) => {
                  return (
                    <Col
                      md={
                        column.key === "mn" || column.key === "action"
                          ? 3
                          : 2
                      }
                      className={"text-center arrowPointer ml-0"}
                      title={Strings(column.name)}
                      key={Math.random()}
                    >
                      <Typography className={"content"}>
                        {column.key === "action"
                          ? this.assignButton(row)
                          : Strings(row[column.key])}
                      </Typography>
                    </Col>
                  );
                })}
              </CardActions>
              {row.lns.map((loan) => {
                return this.collapseSection(loan, row.mid, row);
              })}
            </Card>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.confirmationModalInformation.show &&
          this.renderConfirmationModal()}
        {this.state.notesModal && (
          <NotesModal
            open={this.state.notesModal}
            note={this.state.note}
            modalHeader={Strings("Notes")}
            toggleModal={this.toggleModal}
            isNotesCompulsory={true}
            maxLength={500}
            addNote={this.addNote}
            noteType={this.state.noteType}
          />
        )}
        <Row className='heading'>{Strings("MTPLoanRequests")}</Row>

        <div className='paper greyback customTableCard'>
          {this.renderSearchFilter()}
          {this.state.rows?.length > 0 ? (
            <>
              {this.renderLoanRequestList(
                this.state.rows,
                this.columns
              )}
              <Col md={11} className='noPadding alignPagination'>
                <Pagination
                  activePage={this.state.activePage}
                  recordsPerPage={this.state.recordsPerPage}
                  numberOfPages={this.props.numberOfPages}
                  onPageChange={this.onPageChange}
                  dropup={true}
                />
              </Col>
            </>
          ) : (
            <div className='noRecordsFound'>
              {Strings("NoRecordsFound")}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default LoanRequests;

export const DATA_TYPE = {
  FLOAT: "float",
  REQUESTED: "requested",
  HOURS: "hours",
  MINUTES: "minutes",
  NUMBER: "number",
  AMOUNT: "amount",
  INTEGER: "int",
  TEXT: "text",
  SELECT: "select",
  BOOLEAN: "boolean"
};

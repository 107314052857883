import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";
import { SET_REWARD } from "../../redux/constants";

//Utility
import Utility from "../../utils/Utility";

export const getApprovalRewardListAction = (request, callback) => {
  request.oft -= 1;
  request.fd = Utility.convertDateIntoMiliSeconds(request.fd);
  request.td = Utility.convertDateIntoMiliSeconds(request.td);
  callAPI(
    "getApprovalRewardListAction",
    API.getApprovalRewardList,
    "GET",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const setRewardDetailsAction = (reward) => (dispatch) => {
  dispatch({
    type: SET_REWARD,
    reward,
  });
};

import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import PieChartComponent from "../CustomUIComponents/PieChartComponent/PieChartComponent";

//Constants
import {
  DEFAULT_DATE_FORMAT,
  LOAN_TYPE,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DOWNLOAD_FILE_TYPE,
  PIE_CHART_COLORS,
  DAILY_STATUS_SALES_PERSON_LEGENDS,
  CURRENCY,
  PIE_CHART_COLORS_CONST,
  DEFAULT_LOAN_TYPE,
  DEFAULT_LOAN_STATUS_SELECTION,
  DEFAULT_LOAN_STATUS,
  DEFAULT_DURATION,
  SPED_STATUS_FOR_LOANS,
  INVOICE_DATE_FORMAT,
  SEARCH_FILTER_PAGES,
} from "../../constants/appConstants";
import { LEGEND_KEY_CONST } from "../DailyStatus/DailyStatusConstants";
import { SEARCH_FIELD_NAME, DOWNLOAD_URL } from "./Constants";
import { SPED_LOAN_LEGEND } from "../EfficiencyDashboard/constants";
import {
  DEFAULT_PAGINATION_ACTIVE_PAGE,
  DEFAULT_PAGINATION_RECORDS_COUNT,
} from "../CustomUIComponents/Pagination/PaginationConstants";

//Utility
import Utility from "../../utils/Utility";

//Assets
import CSVdownload from "../../resources/images/ic_download_csv.svg";
import PDFdownload from "../../resources/images/ic_download_pdf.svg";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./SingleSalesPerson.scss";

class SingleSalesPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      errorMessage: "",
      rows: [],
      activePage: props.searchFilters.offset,
      recordsPerPage: props.searchFilters.limit,
      loanFilters: {
        minimartCode: props.searchFilters.code,
        applicationId: props.searchFilters.appid,
        matricesId: props.searchFilters.mid,
        minimartName: props.searchFilters.mn,
        status: props.searchFilters.st,
        loanType: props.searchFilters.ty,
        fromDate:
          this.props.searchPage === SEARCH_FILTER_PAGES.SPED_L1
            ? props.edfSearchFilter.fd
            : props.searchFilters.fd,
        toDate:
          this.props.searchPage === SEARCH_FILTER_PAGES.SPED_L1
            ? props.edfSearchFilter.td
            : props.searchFilters.td,
      },
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.loanFilters.toDate)) {
          DateValue = this.state.loanFilters.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.loanFilters.fromDate)) {
          DateValue =
            moment(dateMoment).diff(
              this.state.loanFilters.fromDate,
              "days"
            ) === 0
              ? this.state.loanFilters.fromDate
              : this.state.loanFilters.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        if (dateMoment.isAfter(new Date())) {
          DateValue = new Date();
        }
        break;
      default:
    }
    this.setState({
      loanFilters: { ...this.state.loanFilters, [key]: DateValue },
    });
  };

  onPageChange = (pageNumber) => {
    this.setState(
      {
        activePage: pageNumber.page,
        recordsPerPage: pageNumber.pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  handleInputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      loanFilters: {
        ...this.state.loanFilters,
        [name]: value,
        errorMessage: "",
      },
    });
  };

  handleChangeStatusFilter = (event) => {
    if (event.target.value !== DEFAULT_LOAN_STATUS_SELECTION.value) {
      this.setState({
        loanFilters: {
          ...this.state.loanFilters,
          status: event.target.value,
        },
      });
    } else {
      this.setState({
        loanFilters: {
          ...this.state.loanFilters,
          status: "",
        },
      });
    }
  };

  handleOnClickResetFilter = () => {
    this.setState(
      {
        loanFilters: {
          minimartCode: "",
          applicationId: "",
          matricesId: "",
          minimartName: "",
          analystName: "",
          status: DEFAULT_LOAN_STATUS.value,
          loanType: DEFAULT_LOAN_TYPE,
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
          recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        },
      },
      () => {
        this.handleOnClickSearchFilter(false);
      }
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleOnClickSearchFilter({
      search: {
        minimartCode:
          this.state.loanFilters.minimartCode !== ""
            ? this.state.loanFilters.minimartCode
            : undefined,
        applicationId:
          this.state.loanFilters.applicationId !== ""
            ? this.state.loanFilters.applicationId
            : undefined,
        matricesId:
          this.state.loanFilters.matricesId !== ""
            ? this.state.loanFilters.matricesId
            : undefined,
        minimartName:
          this.state.loanFilters.minimartName !== ""
            ? this.state.loanFilters.minimartName
            : undefined,
        status:
          this.state.loanFilters.status !== DEFAULT_LOAN_STATUS
            ? this.state.loanFilters.status
            : undefined,
        loanType:
          this.state.loanFilters.loanType !== DEFAULT_LOAN_TYPE
            ? this.state.loanFilters.loanType
            : undefined,
        fromDate: this.state.loanFilters.fromDate,
        toDate: this.state.loanFilters.toDate,
        userId: this.props.selectedSalespersonDetails?.uid,
      },
      pagination: {
        limit: this.state.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.activePage,
      },
    });
  };

  handleDownloadFile = (fileType) => {
    let searchFilterObject = {
      search: {
        code:
          this.state.loanFilters.minimartCode !== ""
            ? this.state.loanFilters.minimartCode
            : undefined,
        appid:
          this.state.loanFilters.applicationId !== ""
            ? this.state.loanFilters.applicationId
            : undefined,
        mid:
          this.state.loanFilters.matricesId !== ""
            ? this.state.loanFilters.matricesId
            : undefined,
        mn:
          this.state.loanFilters.minimartName !== ""
            ? this.state.loanFilters.minimartName
            : undefined,
        st:
          this.state.loanFilters.status !== DEFAULT_LOAN_STATUS
            ? this.state.loanFilters.status
            : undefined,
        ty:
          this.state.loanFilters.loanType !== DEFAULT_LOAN_TYPE
            ? this.state.loanFilters.loanType
            : undefined,
        fd: Utility.convertDateIntoMiliSeconds(
          this.state.loanFilters.fromDate
        ),
        td: Utility.convertDateIntoMiliSeconds(
          this.state.loanFilters.toDate
        ),
        uid: this.props.selectedSalespersonDetails?.uid,
        limit: this.state.recordsPerPage * this.props.numberOfPages,
        offset: 0,
      },
    };
    this.props.handleDownloadFile(
      DOWNLOAD_URL + fileType,
      searchFilterObject,
      fileType
    );
  };

  renderHeader = (props) => {
    return (
      <>
        <Row className='headerContainer'>
          <Col
            className='headerDetailsContainer'
            md={{ span: 11 }}
            lg={{ span: 11 }}
          >
            <Row>
              <Col md={3} className='alignCenter'>
                <span className='titleText'>
                  {Strings("Name")} &nbsp;:&nbsp;
                </span>
                &nbsp;&nbsp;
                <span className='colorText'>
                  {this.props.selectedSalespersonDetails?.fn +
                    " " +
                    this.props.selectedSalespersonDetails?.ln}
                </span>
              </Col>
              <Col md={5} className='alignCenter'>
                <span className='titleText'>
                  {Strings("Branch")}&nbsp;:&nbsp;
                </span>
                &nbsp;&nbsp;
                <span className='colorText'>
                  {this.props.selectedSalespersonDetails?.brn}
                </span>
              </Col>
              <Col md={4} className='alignCenter'>
                <span className='titleText'>
                  {Strings("disbursementEfficiency")}&nbsp;:&nbsp;
                </span>
                &nbsp;&nbsp;
                <span className='colorText'>
                  {Utility.getDecimalNumberUptoDigit(
                    this.props.selectedSalespersonDetails?.ple,
                    2
                  )}
                </span>
              </Col>
              <Col md={3} className='pr-0'>
                <Row className='px-0 w-100'>
                  <Col md={2} className='px-0 titleText'>
                    {Strings("Zones")}
                  </Col>
                  <span className='px-0 titleText'>
                    {" "}
                    &nbsp;:&nbsp;
                  </span>
                  <Col className='pr-0'>
                    <span className='colorText text-wrap'>
                      {this.props.selectedSalespersonDetails?.zn
                        ?.length !== 0
                        ? this.props.selectedSalespersonDetails?.zn?.map(
                            (zone) => {
                              return <>{zone + "  "}</>;
                            }
                          )
                        : Strings("NA")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md={5} className='alignCenter pr-0'>
                <Row className='w-100'>
                  <Col md={6} className='titleText text-wrap px-0'>
                    <span>{Strings("activeMinimart")}</span>
                  </Col>
                  <span className='px-0 titleText'>
                    {" "}
                    &nbsp;:&nbsp;
                  </span>
                  <Col md={5} className=' pr-0'>
                    <span className='colorText pl-0'>
                      {this.props.selectedSalespersonDetails?.amtd +
                        " / " +
                        this.props.selectedSalespersonDetails?.pa}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md={4} className='alignCenter'>
                <span className='titleText'>
                  {Strings("conversionRate")}&nbsp;:&nbsp;
                </span>
                &nbsp;&nbsp;
                <span className='colorText'>
                  {Utility.getDecimalNumberUptoDigit(
                    this.props.selectedSalespersonDetails?.crat,
                    2
                  )}
                </span>
              </Col>
            </Row>
          </Col>
          <Col
            className='pl-0 headerButtonContainer'
            md={{ span: 2 }}
            lg={{ span: 2 }}
          >
            <div className='pull-right'>
              <Button
                className='downloadButtons mr-3'
                onClick={() => {
                  this.handleDownloadFile(DOWNLOAD_FILE_TYPE.PDF);
                }}
              >
                <img src={PDFdownload} alt='' />
              </Button>
              <Button
                className='downloadButtons'
                onClick={() => {
                  this.handleDownloadFile(DOWNLOAD_FILE_TYPE.CSV);
                }}
              >
                <img src={CSVdownload} alt='' />
              </Button>
            </div>
          </Col>
        </Row>
        <hr className='mx-2' />
      </>
    );
  };

  renderSearchFilter = () => {
    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        fromDate: this.state.loanFilters.fromDate,
        searchPlaceHolder: Strings("Date"),
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.loanFilters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("MatricesID"),
        handleOnChange: this.handleInputChange,
        Value: this.state.loanFilters.matricesId,
        fieldname: SEARCH_FILTER_FIELDS.MATRICES_ID,
        column: 2,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
        name: SEARCH_FIELD_NAME.MATRICES_ID,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("minimartCode"),
        handleOnChange: this.handleInputChange,
        Value: this.state.loanFilters.minimartCode,
        fieldname: SEARCH_FILTER_FIELDS.CODE,
        column: 2,
        name: SEARCH_FIELD_NAME.MINIMART_CODE,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("applicationId"),
        handleOnChange: this.handleInputChange,
        Value: this.state.loanFilters.applicationId,
        fieldname: SEARCH_FILTER_FIELDS.APPLICATION_ID,
        column: 2,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
        name: SEARCH_FIELD_NAME.APPLICATION_ID,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("MiniMartname"),
        handleOnChange: this.handleInputChange,
        Value: this.state.loanFilters.minimartName,
        fieldname: SEARCH_FILTER_FIELDS.MINIMART_NAME,
        column: 2,
        name: SEARCH_FIELD_NAME.MINIMART_NAME,
      },

      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleInputChange,
        Value: this.state.loanFilters.loanType,
        fieldname: SEARCH_FILTER_FIELDS.LOAN_TYPE,
        filterDropdownList: LOAN_TYPE,
        paddingTop: true,
        column: 2,
        name: SEARCH_FIELD_NAME.LOAN_TYPE,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeStatusFilter,
        Value: this.state.loanFilters.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: SPED_STATUS_FOR_LOANS,
        paddingTop: true,
        name: SEARCH_FIELD_NAME.STATUS,
      },
    ];
    return (
      <Row>
        <Col md={12}>
          <SearchFilter
            filters={searchFilters}
            handleOnClickSearchFilter={this.handleOnClickSearchFilter}
            handleOnClickResetFilter={this.handleOnClickResetFilter}
            paddingTop={2}
            removeLeftPadding={true}
          />
        </Col>
      </Row>
    );
  };

  renderLegend = (color, grade, data, isMessenger, index, key) => {
    let colorStyle = {
      backgroundColor: color,
      height: "1.5em",
      width: "1.5em",
    };
    return !isMessenger ? (
      <Row className='legend' key={Math.random()}>
        <div className='pl-0' style={colorStyle} />
        <div
          className='pl-2 branchNameInLegends textWrap'
          title={Strings(grade)}
        >
          {Strings(grade)} &nbsp;&nbsp;
        </div>
        <div className='branchNameInLegends font-weight-bold'>
          {data}
        </div>
      </Row>
    ) : (
      <Row className='legend' key={grade + data + index}>
        <div className='pl-0' style={colorStyle} />
        <Col md={6} className='pr-0'>
          <div className='branchNameInLegends' title={Strings(grade)}>
            {Strings(grade)} &nbsp;&nbsp;
          </div>
        </Col>
        <div className='branchNameInLegends font-weight-bold'>
          {data}
        </div>
      </Row>
    );
  };

  renderPiechart = () => {
    let tobevisitedminimarts =
      this.props.piechartStats.totalAssignedMinimarts -
      this.props.piechartStats.visitedMinimarts;

    let minimartPiechartData = [
      { count: this.props.piechartStats.visitedMinimarts },
      { count: tobevisitedminimarts },
    ];

    let loanPiechartData = [
      { count: this.props.piechartStats.createdLoanCount },
      { count: this.props.piechartStats.disbursedLoanCount },
      { count: this.props.piechartStats.deniedLoans },
      { count: this.props.piechartStats.pendingLoans },
    ];
    return (
      <>
        <Row className=' pt-4  pb-2'>
          <Col md={4}>
            <span className='subHeading'>{Strings("minimarts")}</span>
          </Col>
          <Col md={6}>
            <span className='subHeading'>{Strings("Loans")}</span>
          </Col>
        </Row>
        <Row>
          <Col md={2} className=' pr-0'>
            <PieChartComponent
              data={minimartPiechartData}
              colors='salesPersonDailyStatus'
              index={0}
              innerRadius='60%'
              outerRadius='90%'
              datakey='count'
              height={150}
              showLabel={true}
              total={
                this.props.piechartStats.totalAssignedMinimarts !==
                undefined
                  ? this.props.piechartStats.totalAssignedMinimarts
                  : 0
              }
              // moreWidth={true}
            />
          </Col>
          <Col md={2} className='alignLegends pl-0'>
            {DAILY_STATUS_SALES_PERSON_LEGENDS.map(
              (legend, index) => {
                return this.renderLegend(
                  PIE_CHART_COLORS[
                    PIE_CHART_COLORS_CONST.SALES_PERSON_DAILY_STATUS
                  ][index],
                  legend.key,
                  minimartPiechartData[index].count,
                  false,
                  index
                );
              }
            )}
          </Col>
          <Col md={2} className=' pr-0'>
            <PieChartComponent
              data={loanPiechartData}
              colors='salesPersonDailyStatusForLoans'
              index={0}
              innerRadius='60%'
              outerRadius='90%'
              datakey='count'
              height={150}
              showLabel={true}
              total={
                this.props.piechartStats.totalLoans !== undefined
                  ? this.props.piechartStats.totalLoans
                  : 0
              }
              // moreWidth={true}
            />
          </Col>
          <Col md={2} className='alignLegends pl-0'>
            {SPED_LOAN_LEGEND.map((legend, index) => {
              return this.renderLegend(
                PIE_CHART_COLORS[
                  PIE_CHART_COLORS_CONST
                    .SALES_PERSON_DAILY_STATUS_FOR_LOANS
                ][index],
                legend,
                loanPiechartData[index].count,
                false,
                index,
                LEGEND_KEY_CONST.SALES_LOANS
              );
            })}
          </Col>
          <Col md={3} className='alignLegends pl-5 pr-0'>
            <Row className='legend'>
              <div className='dailyStatusNameInLegends'>
                {Strings("inProcess")}
                {CURRENCY}
                {this.props.piechartStats.createdLoanAmount
                  ? Utility.getCurrencyRepresentationOfAmount(
                      this.props.piechartStats.createdLoanAmount
                    )
                  : 0}
              </div>
            </Row>
            <Row className='legend'>
              <div className='dailyStatusNameInLegends'>
                {Strings("disbursed")}
                {CURRENCY}
                {this.props.piechartStats.disbursedLoanAmount
                  ? Utility.getCurrencyRepresentationOfAmount(
                      this.props.piechartStats.disbursedLoanAmount
                    )
                  : 0}
              </div>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "ts":
        return Strings(moment(row[key]).format(INVOICE_DATE_FORMAT));
      case "amt":
        return row[key]
          ? Utility.getCurrencyRepresentationOfAmount(row[key])
          : 0;
      default:
        return row[key] ? Strings(row[key]) : Strings("NA");
    }
  };

  renderTableClass = (key) => {
    if (key === "appid" || key === "amt") {
      return "text-center px-2";
    } else {
      return "text-left px-2";
    }
  };

  renderCustomDataGrid = (columns, rows, callBack) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={`${this.renderTableClass(
                        column.key
                      )} header`}
                      title={column.name}
                    >
                      {column.name}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.mid}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => callBack(row)}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={`${this.renderTableClass(
                        column.key
                      )}`}
                      component='th'
                      scope='row'
                      title={row[column.key]}
                      style={{ minWidth: column.minWidth }}
                      onClick={() =>
                        callBack(rowIndex, row, this.state.filters)
                      }
                    >
                      {this.renderTableData(row, column.key)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render() {
    return (
      <div className='singleSalesPerson py-3'>
        {this.renderHeader(this.props.userInfo)}
        {this.renderSearchFilter()}
        {this.state.errorMessage !== "" && (
          <Row className='errors pl-3 pt-2'>
            {this.state.errorMessage !== "" &&
              Strings(this.state.errorMessage)}
          </Row>
        )}
        {this.renderPiechart()}
        {this.props.rowsCount !== 0 ? (
          <div className='branchListTable noPadding pt-4'>
            {this.renderCustomDataGrid(
              this.props.columns,
              this.props.rows,
              this.props.onRowClick
            )}
          </div>
        ) : (
          <div className='noRecordsFound'>
            {Strings("NoRecordsFound")}
          </div>
        )}
        <Row>
          <Col md={1} className='pl-2 pt-3 pb-1'>
            <Button
              className='buttonBorder'
              onClick={this.props.goBack}
            >
              {Strings("Back").toUpperCase()}
            </Button>
          </Col>
          {this.props.rowsCount !== 0 && (
            <Col md={11} className='noPadding'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default SingleSalesPerson;

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

// Components
import Sidebar from "./Sidebar";

//Action
import { setSearchFilterAction } from "../../constants/Action";
import { setSelectedFeatureAction } from "./SidebarActions";

class SidebarContainer extends Component {
  render() {
    return (
      <Sidebar
        loggedInUserInfo={this.props.loggedInUserInfo}
        location={this.props.location}
        history={this.props.history}
        selectedFeature={this.props.selectedFeature}
        setSearchFilterAction={this.props.setSearchFilterAction}
        setSelectedFeatureAction={this.props.setSelectedFeatureAction}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUserInfo: state.loginReducer.loggedInUserInfo,
  selectedFeature: state.sidebarReducer.selectedFeature,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setSearchFilterAction, setSelectedFeatureAction },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarContainer)
);

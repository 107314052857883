import { API } from "../../constants/APIConstants";
import { callAPI } from "../../config/AxiosTemplate";

export const getComplaintPortalAction = (reqObj, callback) => {
  callAPI(
    "getComplaintPortal",
    API.getComplaintPortal,
    "GET",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

export const resolveComplaintAction = (reqObj, callback) => {
  callAPI(
    "resolveComplaint",
    API.resolveComplaint,
    "PATCH",
    reqObj,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};

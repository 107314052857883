import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";

// Component
import EditableModal from "../LoanDetails/EditableModal";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";
import { ROUTES } from "../../constants/routeConstants";

// Action

// Constants
import {
  INVOICE_DATE_FORMAT,
  HISTORY_MINIMART_STATUS_ON_CARD,
  APPROVED_MINIMART_ACTION,
  STATUS_FOR_HISTORY_MINIMART,
  REQUEST_STATUS,
} from "../../constants/appConstants";

// String
import { Strings } from "../../resources/i18n/i18n";

// Assests
import Defaultminimart from "../../resources/images/ic_mini_market_new.svg";
import editNote from "../../resources/images/ic_edit.svg";

//Styles
import "./ApprovalMinimart.scss";
import "../../resources/styles/globalStyles.scss";

class ApprovalMinimart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      notesModal: false,
      noteType: "",
      minimart: props.minimart,
      editDetailIndex: 0,
      editMinimart: {},
    };
  }

  componentDidUpdate(prevProp) {
    if (!_.isEqual(prevProp.minimart, this.props.minimart)) {
      this.setState({ minimart: this.props.minimart });
    }
    if (
      this.state.minimart.st === REQUEST_STATUS.approved &&
      !this.props.isHistory
    ) {
      this.props.history.push(ROUTES.MINIMART_APPROVAL_REQUESTS);
    }
  }

  showEditableFields = (index) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      editMinimart: { ...this.state.minimart },
      editDetailIndex: index,
      errorMessage: "",
    }));
  };

  renderDetailsHeader = (headerText, index) => {
    return (
      <Row
        className={`subHeadingText pb-1 ${index === 0 ? "" : "pt-3"}`}
      >
        {headerText}
        {!this.props.isHistory &&
        (this.props.isAnalyst ||
          ((this.props.isOperationsOfficer ||
            this.props.isOperationsModerator) &&
            index === 0)) ? (
          <div>
            <img
              src={editNote}
              className={"editIcon ml-1 pb-1"}
              alt={"edit"}
              title={Strings("EditableFileds")}
              onClick={() => {
                this.showEditableFields(index);
              }}
            />
          </div>
        ) : null}
      </Row>
    );
  };

  renderMinimartDetails = () => {
    let branchName = Strings("NA");
    if (this.state.minimart && this.props.branches) {
      const index = this.props.branches?.findIndex(
        (branch) => branch.id === this.state.minimart.br
      );
      if (index !== -1) {
        branchName = this.props.branches[index].name;
      }
    }
    return (
      <>
        {this.renderDetailsHeader(Strings("minimartDetailsText"), 0)}
        <Row>
          <Col md={4} className='noPadding'>
            <div className=''>
              <span className='titleText'>
                {Strings("MiniMarketName")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.mn
                  ? this.state.minimart.mn
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("associatedBranch")}
              </span>
              &nbsp;:&nbsp;
              <label className='addressOrName'>{branchName}</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='noPadding'>
            <div className=''>
              <span className='titleText'>
                {Strings("BusinessType")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.bty
                  ? this.state.minimart.bty
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>{Strings("Address")}</span>
              &nbsp;:&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.add
                  ? this.state.minimart.add
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>{Strings("City")}</span>
              &nbsp;:&nbsp;
              <label className='noMargin addressOrName'>
                {this.state.minimart.ct
                  ? this.state.minimart.ct
                  : Strings("NA")}
              </label>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  renderOwnerDetails = () => {
    return (
      <>
        {this.renderDetailsHeader(Strings("ownerDetails"), 1)}
        <Row>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("ownerName")}
              </span>
              &nbsp;:&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.on
                  ? this.state.minimart.on
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("adminName")}
              </span>
              &nbsp;:&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.an
                  ? this.state.minimart.an
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("PhoneNumber")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.tp
                  ? this.state.minimart.tp
                  : Strings("NA")}
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("AlternateNumber")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.atp
                  ? this.state.minimart.atp
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("ownerCedulaNumber")}
              </span>{" "}
              :&nbsp;
              <label className='noMargin breakWord addressOrName'>
                {this.state.minimart.oc
                  ? this.state.minimart.oc
                  : Strings("NA")}
              </label>
            </div>
          </Col>

          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>{Strings("email")}</span>
              &nbsp;:&nbsp;
              <label className='addressOrName'>
                {this.state.minimart.eml
                  ? this.state.minimart.eml
                  : Strings("NA")}
              </label>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  renderApproveDetails = () => {
    return (
      <>
        <Row className='subHeadingText pb-1 pt-3'>
          {Strings("approveDetails")}
        </Row>
        <Row>
          <Col md={4} className='noPadding flexibleWraaper'>
            <div>
              <span className='titleText'>
                {Strings("AssociatedSalesPerson")}
              </span>
              &nbsp;:&nbsp;
              <label className='addressOrName'>
                {this.state.minimart.sp
                  ? this.state.minimart.sp
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4} className='noPadding'>
            <div>
              <span className='titleText'>
                {Strings("minimartRaisedDate")}
              </span>
              &nbsp;:&nbsp;
              <label className='addressOrName'>
                {this.state.minimart.ron
                  ? moment(this.state.minimart.ron).format(
                      INVOICE_DATE_FORMAT
                    )
                  : Strings("NA")}
              </label>
            </div>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row>
          {(this.props.isAnalystModerator ||
            this.props.isOperationsOfficer ||
            this.props.isOperationsModerator ||
            this.props.isHistory) && (
            <Col md={4} className='noPadding'>
              <div>
                <span className='titleText'>
                  {Strings("ANALYST")}
                </span>
                &nbsp;:&nbsp;
                <label className='addressOrName'>
                  {this.state.minimart.cby
                    ? this.state.minimart.cby
                    : Strings("NA")}
                </label>
              </div>
            </Col>
          )}
          {(this.props.isOperationsOfficer ||
            this.props.isHistory ||
            this.props.isOperationsModerator) &&
            this.renderConfirmedOn()}

          {(this.props.isHistory ||
            this.props.isOperationsOfficer ||
            this.props.isOperationsModerator) && (
            <Col md={4} className='noPadding'>
              <div className=' flexibleWraaper contentWidth '>
                <span className='titleText'>{Strings("Notes")}</span>
                &nbsp;:&nbsp;
                <label className='addressOrName'>
                  {this.props.minimart?.nt
                    ? this.props.minimart.nt
                    : Strings("NA")}
                </label>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {this.props.isHistory && this.renderApprovedBy()}
          {this.props.isHistory && this.renderApproveRejectDate()}
          {(this.props.isOperationsOfficer ||
            this.props.isHistory ||
            this.props.isOperationsModerator) &&
            this.renderVisitDay()}
        </Row>
      </>
    );
  };
  renderVisitDay = () => {
    if (
      this.props.minimart.st === STATUS_FOR_HISTORY_MINIMART[1].value
    ) {
      return (
        <Col md={4} className='flexibleWraaper noPadding'>
          <div>
            <span className='titleText'>{Strings("visitDay")}</span>
            &nbsp;:&nbsp;
            <label className='addressOrName'>
              {this.props.minimart?.vd
                ? this.props.minimart?.vd
                : Strings("NA")}
            </label>
          </div>
        </Col>
      );
    }
  };

  renderApprovedBy = () => {
    if (
      this.props.minimart.st === STATUS_FOR_HISTORY_MINIMART[1].value
    ) {
      return (
        <Col md={4} className='noPadding'>
          <div className=' flexibleWraaper contentWidth '>
            <span className='titleText'>{Strings("approvedBy")}</span>
            &nbsp;:&nbsp;
            <label className='addressOrName'>
              {this.props.minimart?.aa
                ? this.props.minimart.aa
                : Strings("NA")}
            </label>
          </div>
        </Col>
      );
    }
  };

  renderConfirmedOn = () => {
    if (
      this.props.minimart.st ===
        STATUS_FOR_HISTORY_MINIMART[3].value ||
      this.props.minimart.st === STATUS_FOR_HISTORY_MINIMART[1].value
    ) {
      let status;
      status = Strings("confirmedOn");
      let date = this.state.minimart.con
        ? moment(this.state.minimart.con).format(INVOICE_DATE_FORMAT)
        : Strings("NA");
      return this.props.isHistory ||
        this.props.isOperationsOfficer ||
        this.props.isOperationsModerator ? (
        <Col md={4} className='noPadding'>
          <div>
            <span className='titleText'>{status}</span>
            &nbsp;:&nbsp;
            <label className='addressOrName'>{date}</label>
          </div>
        </Col>
      ) : null;
    }
  };

  renderApproveRejectDate = () => {
    let status;
    if (
      this.props.minimart.st === STATUS_FOR_HISTORY_MINIMART[1].value
    ) {
      status = Strings("approvedRequest");
      let date = this.state.minimart.aon
        ? moment(this.state.minimart.aon).format(INVOICE_DATE_FORMAT)
        : Strings("NA");
      return (
        <Col md={4} className='noPadding'>
          <div>
            <span className='titleText'>{status}</span>
            &nbsp;:&nbsp;
            <label className='addressOrName'>{date}</label>
          </div>
        </Col>
      );
    } else if (
      this.props.minimart.st === STATUS_FOR_HISTORY_MINIMART[2].value
    ) {
      status = Strings("rejectedRequest");
      let date = this.state.minimart.con
        ? moment(this.state.minimart.con).format(INVOICE_DATE_FORMAT)
        : Strings("NA");
      return (
        <Col md={4} className='noPadding'>
          <div>
            <span className='titleText'>{status}</span>
            &nbsp;:&nbsp;
            <label className='addressOrName'>{date}</label>
          </div>
        </Col>
      );
    }
  };

  onChange = (event) => {
    let value;
    if (event.target.value === Strings("BusinessType")) {
      value = "";
    } else {
      value = event.target.value;
    }
    this.setState({
      editMinimart: {
        ...this.state.editMinimart,
        [event?.target.name]: value,
      },
      errorMessage: "",
    });
  };

  checkValid = () => {
    let valid = false;
    if (
      (!this.props.isOperationsOfficer &&
        !this.props.isOperationsModerator &&
        this.state.editDetailIndex === 0 &&
        this.state.editMinimart.mn !== "" &&
        this.state.editMinimart.add !== "" &&
        this.state.editMinimart.bty !== "" &&
        this.state.editMinimart.ct !== "") ||
      ((this.props.isOperationsOfficer ||
        this.props.isOperationsModerator) &&
        this.state.editDetailIndex === 0 &&
        this.state.editMinimart.br !== "") ||
      (this.state.editDetailIndex === 1 &&
        this.state.editMinimart.on !== "" &&
        this.state.editMinimart.rm !== "" &&
        this.state.editMinimart.tp &&
        this.state.editMinimart.tp.length === 10 &&
        this.state.editMinimart.atp &&
        this.state.editMinimart.atp.length === 10 &&
        this.state.editMinimart.oc !== "" &&
        this.state.editMinimart.oc.length === 11)
    ) {
      valid = true;
    }
    return valid;
  };

  onSubmitMinimartDetails = () => {
    if (this.checkValid()) {
      this.props.updateMinimartDetails(
        this.state.editMinimart,
        () => {
          this.setState({ showModal: false });
        }
      );
    } else {
      if (
        this.state.editMinimart.br !== "" ||
        this.state.editMinimart.bty !== "" ||
        this.state.editMinimart.mn !== "" ||
        this.state.editMinimart.add !== "" ||
        this.state.editMinimart.ct !== "" ||
        this.state.editMinimart.on !== "" ||
        this.state.editMinimart.rm !== "" ||
        this.state.editMinimart.tp ||
        this.state.editMinimart.tp.length === 10 ||
        this.state.editMinimart.atp ||
        this.state.editMinimart.atp.length === 10 ||
        this.state.editMinimart.oc !== "" ||
        this.state.editMinimart.oc.length === 11
      ) {
        this.setState({
          errorMessage: Strings("pleaseEnterRequiredFields"),
        });
      }
      if (
        isNaN(this.state.editMinimart.atp) ||
        this.state.editMinimart.atp.length !== 10
      ) {
        this.setState({
          errorMessage: Strings("MobileNumberMustBeTenDigits"),
        });
      }
      if (
        isNaN(this.state.editMinimart.tp) ||
        this.state.editMinimart.tp.length !== 10
      ) {
        this.setState({
          errorMessage: Strings("MobileNumberMustBeTenDigits"),
        });
      }
      if (
        isNaN(this.state.editMinimart.oc) ||
        this.state.editMinimart.oc.length !== 11
      ) {
        this.setState({
          errorMessage: Strings("CedulaIdMustBeElevenDigits"),
        });
      }
    }
  };

  renderEditableModal = () => {
    let editableData = [];
    if (
      !this.props.isOperationsOfficer &&
      !this.props.isOperationsModerator &&
      this.state.editDetailIndex === 0
    ) {
      editableData = [
        {
          title: Strings("MiniMarketName"),
          value: this.state.editMinimart.mn,
          isMandatoryField: true,
          name: "mn",
          type: "text",
        },

        {
          title: Strings("Address"),
          value: this.state.editMinimart.add,
          isMandatoryField: true,
          name: "add",
          type: "text",
        },
        {
          title: Strings("BusinessType"),
          value: this.state.editMinimart.bty,
          isMandatoryField: true,
          name: "bty",
          dropDownList: this.props.businessTypeList,
          type: "select",
          isSelection: true,
        },
        {
          title: Strings("City"),
          value: this.state.editMinimart.ct,
          isMandatoryField: true,
          name: "ct",
          type: "text",
        },
      ];
    } else if (this.state.editDetailIndex === 1) {
      editableData = [
        {
          title: Strings("ownerName"),
          value: this.state.editMinimart.on,
          isMandatoryField: true,
          name: "on",
          type: "text",
        },
        {
          title: Strings("adminName"),
          value: this.state.editMinimart.an,
          isMandatoryField: false,
          name: "an",
          type: "text",
        },
        {
          title: Strings("PhoneNumber"),
          value: this.state.editMinimart.tp,
          isMandatoryField: true,
          name: "tp",
          type: "number",
        },
        {
          title: Strings("AlternateNumber"),
          value: this.state.editMinimart.atp,
          isMandatoryField: true,
          name: "atp",
          type: "number",
        },
        {
          title: Strings("ownerCedulaNumber"),
          value: this.state.editMinimart.oc,
          isMandatoryField: true,
          name: "oc",
          type: "number",
        },
        {
          title: Strings("email"),
          value: this.state.editMinimart.eml,
          isMandatoryField: false,
          name: "eml",
          type: "text",
        },
      ];
    } else if (
      (this.props.isOperationsOfficer ||
        this.props.isOperationsModerator) &&
      this.state.editDetailIndex === 0
    ) {
      editableData = [
        {
          title: Strings("associatedBranch"),
          value: this.state.editMinimart.br,
          isMandatoryField: true,
          name: "br",
          dropDownList: this.props.branches,
          type: "select",
          isSelection: true,
        },
      ];
    }
    return (
      <EditableModal
        open={this.state.showModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={
          this.state.editDetailIndex === 1
            ? Strings("EditOwnerDetails")
            : Strings("EditMinimartDetails")
        }
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.showEditableFields,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.SubmitMinimartDetails,
        }}
        onChange={this.onChange}
        editableData={editableData}
        SubmitMinimartDetails={this.onSubmitMinimartDetails}
        errorMessage={this.state.errorMessage}
        isApproveMinimart={true}
      />
    );
  };

  toggleModal = (type = "") => {
    this.setState({
      noteType: type,
      notesModal: !this.state.notesModal,
    });
  };

  addNote = (notes, vd = "") => {
    this.props.addNote({
      tmid: this.state.minimart.tmid,
      type: this.state.noteType,
      vd: vd,
      note: notes,
    });
    this.toggleModal();
  };

  renderNotesModal = () => {
    return (
      <NotesModal
        open={this.state.notesModal}
        modalHeader={
          this.props.isAnalyst
            ? Strings("Notes")
            : Strings("visitDays")
        }
        toggleModal={this.toggleModal}
        noteType={this.state.noteType}
        isOperationsOfficer={this.props.isOperationsOfficer}
        isOperationsModerator={this.props.isOperationsModerator}
        addNote={this.addNote}
        isEditNote={false}
        isNotesCompulsory={
          !this.props.isOperationsOfficer &&
          !this.props.isOperationsModerator
        }
        isApproveMinimart={true}
        isApproveClicked={
          this.state.noteType === APPROVED_MINIMART_ACTION.APPROVED
        }
        maxLength={500}
      />
    );
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  render() {
    const index = HISTORY_MINIMART_STATUS_ON_CARD.findIndex(
      (legendType) => {
        return (
          legendType.key.toUpperCase() === this.state.minimart.st
        );
      }
    );
    return (
      <div>
        {this.state.notesModal && this.renderNotesModal()}
        {this.state.showModal && this.renderEditableModal()}
        <Row className='heading'>
          {Strings("minimartManagement")}-{Strings("details")}
        </Row>
        <div className='minimartDetails container-fluid ListContainer'>
          <Row className='primaryDetails'>
            <Col md={1} className='noPadding'>
              <div className='imageplaceholder justify-center display-flex'>
                <img src={Defaultminimart} alt='minimart' />
              </div>
              <div
                className='gradeText justify-center display-flex pt-3 text-center'
                title={Strings("MinimartStatus")}
              >
                <span
                  title={Strings("MinimartStatus")}
                  style={{
                    color:
                      HISTORY_MINIMART_STATUS_ON_CARD[index].color,
                  }}
                >
                  {Strings(this.state.minimart.st)}
                </span>
              </div>
            </Col>
            <Col md={11}>
              {this.renderMinimartDetails()}
              {this.renderOwnerDetails()}
              {this.renderApproveDetails()}
            </Col>
          </Row>

          <>
            <hr />
            <Row className='pb-2'>
              <Col>
                <Button
                  className='buttonBorder'
                  onClick={this.handleBackButtonClick}
                >
                  {Strings("Back")}
                </Button>
              </Col>
              {!this.props.isHistory &&
                (this.props.isAnalyst ||
                  this.props.isOperationsOfficer ||
                  this.props.isOperationsModerator) && (
                  <React.Fragment>
                    <Col md={{ span: 1 }}>
                      <Button
                        className='capitalText buttonBackground pull-right'
                        onClick={() =>
                          this.toggleModal(
                            APPROVED_MINIMART_ACTION.APPROVED
                          )
                        }
                      >
                        {Strings("approve")}
                      </Button>
                    </Col>
                    {!this.props.isOperationsOfficer &&
                      !this.props.isOperationsModerator && (
                        <Col md={1}>
                          <Button
                            className='capitalText buttonBorder  pull-left'
                            onClick={() =>
                              this.toggleModal(
                                APPROVED_MINIMART_ACTION.REJECTED
                              )
                            }
                          >
                            {Strings("reject")}
                          </Button>
                        </Col>
                      )}
                  </React.Fragment>
                )}
            </Row>
          </>
        </div>
      </div>
    );
  }
}

export default withRouter(ApprovalMinimart);

import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import fileDownload from "js-file-download";

//Component
import CommissionReport from "./CommissionReport";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

//Action
import {
  saveDateForCommissionAction,
  getSecondLevelCommissionHeaderAction,
  getSecondLevelCommissionReportAction,
} from "../SalesPersonComission/CommissionsAction";
import {
  dowloadedAction,
  setSearchFilterAction,
} from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { TABLE_COLUMN } from "./Constants";
import { ROUTES } from "../../constants/routeConstants";
import { PDF_NAME } from "../Comission/Constants";
import {
  SEARCH_FILTER_PAGES,
  COMMISSION_FILTER,
  COMMISSION_REPORT_FILTER,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./CommissionReport.scss";

//Assets

class CommissionReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      header: null,
      numberOfPages: 1,
      errorMessage: "",
    };
  }

  componentWillUnmount() {
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.COMMISSION &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.COMMISSION,
        { ...COMMISSION_FILTER }
      );
    }
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.COMMISSION_REPORT,
        { ...COMMISSION_REPORT_FILTER },
        SEARCH_FILTER_PAGES.COMMISSION
      );
    }
  }

  gotoThirdCommissionReport = (row) => {
    if (row !== undefined) {
      this.isCardClick = true;
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.COMMISSION_REPORT,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.COMMISSION_REPORT
      );
      this.props.saveDateForCommissionAction(row);
      this.props.history.push(ROUTES.DETAIL_COMMISSION_REPORT);
    }
  };

  getCommissionsHeader = (searchFilterObject) => {
    this.setState({ showLoader: true });
    getSecondLevelCommissionHeaderAction(
      searchFilterObject,
      (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            header: Response.data.data,
            showLoader: false,
            errorMessage: "",
          });
        } else {
          this.setState({
            showLoader: false,
            errorMessage: Response.error.message,
            header: null,
          });
        }
      }
    );
  };

  getSecondLevelCommissionReport = (searchFilter) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        fd: searchFilter.sc.fd,
        td: searchFilter.sc.td,
        limit: searchFilter.limit,
        offset: searchFilter.offset,
      },
    });
    getSecondLevelCommissionReportAction(searchFilter, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          rows: Response.data.data.dc,
          numberOfPages: Response.data.data.np,
          showLoader: false,
          errorMessage: "",
        });
      } else {
        this.setState({
          showLoader: false,
          errorMessage: Response.error.message,
        });
      }
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  /**
   * Method to download Sales or Messenger commission reports
   * @param {*} url
   * @param {*} requestData
   * @param {*} filetype
   */
  downloadReports = (url, requestData, filetype) => {
    this.setState({ showLoader: true, isError: false });
    dowloadedAction(
      url,
      requestData,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (Utility.checkBlobTypeForFileDownload(response.data.type)) {
            fileDownload(
              response.data,
              PDF_NAME[this.props.user.userRole] +
                Utility.getFileType(filetype)
            );
            this.setState({
              errorMessage: "",
              showLoader: false,
              isError: false,
            });
          } else {
            this.setState({
              showLoader: false,
              isError: true,
              errorMessage: Strings("NoRecordsFound"),
            });
          }
        } else {
          this.setState({
            showLoader: false,
            errorMessage: response.error.message,
          });
        }
      },
      "ComissionReports"
    );
  };

  render() {
    return (
      <div className='commissionReport'>
        <Row className='heading'>{Strings("commissionReport")} </Row>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <CommissionReport
          user={this.props.user}
          data={this.state.rows}
          columns={TABLE_COLUMN[this.props.user.userRole]}
          rowsCount={this.state.rows.length}
          onRowClick={this.gotoThirdCommissionReport}
          rowHeight={60}
          headerRowHeight={50}
          getCommissionsHeader={this.getCommissionsHeader}
          handleSearchFilter={this.getSecondLevelCommissionReport}
          header={this.state.header}
          rows={this.state.rows}
          handleBackButtonClick={this.goBack}
          errorMessage={this.state.errorMessage}
          numberOfPages={this.state.numberOfPages}
          userRole={this.props.user.userRole}
          downloadReports={this.downloadReports}
          searchFilter={this.props.searchFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.CommissionReportReducer.commissionUser,
  searchFilter:
    state.searchFilterReducer.commissionReportSearchFilter,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { saveDateForCommissionAction, setSearchFilterAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CommissionReportContainer));

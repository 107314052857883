import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Styles

class UserNotification extends React.Component {
  constructor(props) {
    super(props);
    this.props.userNotificationObj &&
      toast[this.props.userNotificationObj.level](
        this.props.userNotificationObj.message
      );
  }
  render() {
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }
}

export default UserNotification;

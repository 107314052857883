import { Strings } from "../../resources/i18n/i18n";
export const PROMO_FIELDS = {
  TITLE: "prt",
  DESC: "prdes",
  GRADE: "prgn",
  DISCOUNT: "prdis",
  PRODUCT_NAME: "prlt",
  LEVEL: "prln",
  REWARD_PTS: "prrp",
  ACTIVATION: "pradt",
  EXPIRY: "predt",
  REWARD_MIN: "prminrp",
  REWARD_MAX: "prmanrp",
  TYPE: "prty",
  BEENFIT_TYPE: "prbty",
  MIN_LOAN_AMT: "prlma",
};

export const RESET_FIELDS = {
  prid: null,
  prt: "",
  prln: "",
  prgn: "",
  prrp: 0,
  prdis: 0,
  prlt: "",
  prdes: "",
  pradt: "",
  predt: "",
  file: "",
  prminrp: 0,
  prmanrp: 0,
  prty: "",
  prbty: "",
  prlma: 0,
};

export const ERROR_FIELDS = {
  prid: false,
  prt: false,
  prln: false,
  prgn: false,
  prrp: false,
  prdis: false,
  prlt: false,
  prdes: false,
  pradt: false,
  predt: false,
  file: false,
  prminrp: false,
  prmanrp: false,
  prty: false,
  prbty: false,
  prlma: false,
};

export const STATUS_LIST = [
  {
    key: 0,
    value: "",
    label: Strings("promotionStatus"),
  },
  {
    key: 1,
    value: "ACTIVE",
    label: Strings("P_ACTIVE"),
  },
  {
    key: 2,
    value: "NEW",
    label: Strings("P_NEW"),
  },
  {
    key: 3,
    value: "EXPIRED",
    label: Strings("P_EXPIRED"),
  },
  {
    key: 4,
    value: "INACTIVE",
    label: Strings("P_INACTIVE"),
  },
];

export const PROMOTION_TYPE = [
  {
    key: 0,
    value: "",
    label: Strings("promotionType"),
  },
  {
    key: 1,
    value: "INTERNAL",
    label: Strings("INTERNAL"),
  },
  {
    key: 2,
    value: "EXTERNAL",
    label: Strings("EXTERNAL"),
  },
];

export const BEENFIT_TYPE = [
  {
    key: 0,
    value: "",
    label: Strings("benefitType"),
  },
  {
    key: 1,
    value: "DISCOUNT",
    label: Strings("DISCOUNT"),
  },
  {
    key: 2,
    value: "INSTALLMENT",
    label: Strings("Installments"),
  },
  // {
  //   key: 3,
  //   value: "REWARDS",
  //   label: Strings("rewardPoints"),
  // },
];

export const DEFAULT_LIST = [
  Strings("benefitType"),
  Strings("promotionType"),
  Strings("promotionStatus"),
  Strings("productType"),
];

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import PromotionsApproval from "./PromotionsApproval";

//Actions
import {
  getPromotionInterestListAction,
  setPromotionAction,
  getUserByBranch,
} from "./PromotionsApprovalAction";
import { getProductListAction } from "../MTPPromotions/PromotionsActions";
import { setIsNewMinimartAction } from "../MinimartList/MinimartListActions";
import { setSearchFilterAction } from "../../constants/Action";

//Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import {
  SEARCH_FILTER_PAGES,
  PROMOTION_APPROVAL_FILTER,
  USER_ROLES,
  MINIMART_LIST_FILTER,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

class PromotionsApprovalConatiner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      promotionList: [],
      productList: [],
      errorMessage: "",
      numberOfPages: 0,
      userList: [],
      searchFilter: {},
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    this.getProductList();
    if (
      this.props.loggedInUser.role === USER_ROLES.generalManager ||
      this.props.loggedInUser.role === USER_ROLES.admin
    ) {
      this.getUserList({
        role: USER_ROLES.analyst,
      });
    }
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.PROMOTION_APPROVAL,
        { ...PROMOTION_APPROVAL_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.MINIMART_LIST,
        { ...MINIMART_LIST_FILTER }
      );
    }
  }

  getPromotionInterest = (request) => {
    this.setState({
      showLoader: true,
      searchFilter: {
        fd: request.fd,
        td: request.td,
        mid: request.mid,
        mn: request.mn,
        code: request.cid,
        prty: request.prty,
        prbty: request.prbty,
        st: request.st,
        prlt: request.ptlt,
        limit: request.lmt,
        offset: request.oft,
      },
    });

    getPromotionInterestListAction({ ...request }, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          promotionList: response.data.data.ls,
          errorMessage: "",
          numberOfPages: response.data.data.nop,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.errorMessage,
          showLoader: false,
          promotionList: [],
          numberOfPages: 0,
        });
      }
    });
  };

  //get product list for edit promotions dropdown
  getProductList = () => {
    this.setState({
      showLoader: true,
    });
    getProductListAction({}, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          productList: response.data.data,
          errorMessage: "",
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.errorMessage,
          showLoader: false,
          productList: [],
        });
      }
    });
  };

  promotionCardClick = (promotion) => {
    this.isCardClick = true;
    this.props.setPromotionAction(promotion);
    this.props.setSearchFilterAction(
      SEARCH_FILTER_PAGES.PROMOTION_APPROVAL,
      { ...this.state.searchFilter },
      SEARCH_FILTER_PAGES.PROMOTION_APPROVAL
    );
    this.props.history.push(ROUTES.MTP_PROMOTION_DETAILS);
  };

  getUserList = (request) => {
    this.setState({ showLoader: true });
    getUserByBranch(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let userList = [
          {
            value: "",
            label: Strings("username"),
          },
        ];
        response.data.data[0].us.forEach((user) => {
          user.value = user.userId;
          userList.push(user);
        });

        this.setState({
          showLoader: false,
          userList,
          errorMessage: "",
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
          userList: [],
        });
      }
    });
  };

  render() {
    return (
      <div>
        {this.state.showLoader && (
          <LoadingModal showLoadingModal={this.state.showLoader} />
        )}
        <PromotionsApproval
          searchFilter={this.props.searchFilter}
          promotionList={this.state.promotionList}
          promotionCardClick={this.promotionCardClick}
          errorMessage={this.state.errorMessage}
          numberOfPages={this.state.numberOfPages}
          handleOnClickSearchFilter={this.getPromotionInterest}
          productList={this.state.productList}
          userList={this.state.userList}
          minimartDetails={this.props.minimartDetails}
          loggedInUser={this.props.loggedInUser}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchFilter: state.searchFilterReducer.promotionApprovalFilter,
  loggedInUser: state.loginReducer.loggedInUserInfo,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPromotionAction,
      setIsNewMinimartAction,
      setSearchFilterAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionsApprovalConatiner);

import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import Pagination from "../CustomUIComponents/Pagination/Pagination";

//Constants
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  DATE_TYPE,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { SIGNUP_REQUEST_STATUS_LIST } from "./Constants";
import { STATUS_LIST } from "../MTPSignupRequestDetails/Constants";
// String
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

class SignupRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        from: props.searchFilter.fd,
        to: props.searchFilter.td,
        status: props.searchFilter.st,
      },
      activePage: props.searchFilter.offset,
      recordsPerPage: props.searchFilter.limit,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter();
  }

  handleOnClickResetFilter = () => {
    this.setState(
      {
        filters: {
          from: DEFAULT_DURATION.fromDate,
          to: DEFAULT_DURATION.toDate,
          status: STATUS_LIST.PENDING,
        },
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
      this.handleOnClickSearchFilter
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset = false) => {
    isDefaultOffset &&
      this.setState({
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      });
    this.props.handleOnClickSearchFilter({
      fd: this.state.filters.from
        ? Utility.convertDateIntoMiliSeconds(this.state.filters.from)
        : undefined,
      td: this.state.filters.to
        ? Utility.convertDateIntoMiliSeconds(this.state.filters.to)
        : undefined,
      st: this.state.filters.status,
      lmt: this.state.recordsPerPage,
      oft: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  handleChange = (event) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSearchDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.filters.to)) {
          DateValue = this.state.filters.from;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        this.setState({
          filters: {
            ...this.state.filters,
            from: DateValue,
          },
        });
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state.filters.from)) {
          DateValue =
            moment(dateMoment).diff(
              this.state.filters.from,
              "days"
            ) === 0
              ? this.state.filters.from
              : this.state.filters.to;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        this.setState({
          filters: {
            ...this.state.filters,
            to: DateValue,
          },
        });
        break;
      default:
    }
  };
  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      this.handleOnClickSearchFilter
    );
  };

  renderSearchFilter = () => {
    let searchFilters = [
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleSearchDateChange,
        fromDate: this.state.filters.from,
        handleCalenderChange: this.handleSearchDateChange,
        toDate: this.state.filters.to,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
        column: 4,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChange,
        Value: this.state.filters.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: SIGNUP_REQUEST_STATUS_LIST,
        name: "status",
      },
    ];
    return (
      <SearchFilter
        filters={searchFilters}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetFilter}
        paddingLeft={2}
      />
    );
  };

  setMiniMartDetails = (minimart) => {
    this.props.setMiniMartDetails(minimart);
  };

  renderCards = () => {
    return this.props.minimartList.length > 0 ? (
      <Row className='cardAlign'>
        <Col md={12} className='w-100 pr-0'>
          <Row className='w-100'>
            {this.props.minimartList.map((minimart) => {
              return (
                <CustomCard
                  card={minimart}
                  cardType='REQUEST'
                  key={Math.random()}
                  handleCardClick={this.setMiniMartDetails}
                />
              );
            })}{" "}
          </Row>
        </Col>
        <Col md={12} className='pt-3'>
          <Pagination
            activePage={this.state.activePage}
            recordsPerPage={this.state.recordsPerPage}
            numberOfPages={this.props.numberOfPages}
            onPageChange={this.onPageChange}
            dropup={true}
          />
        </Col>
      </Row>
    ) : (
      <div className='noRecordsFound noBranchRecords'>
        {Strings("NoRecordsFound")}
      </div>
    );
  };

  render() {
    return (
      <>
        <Row className='heading'>{Strings("MTPSignupRequests")}</Row>
        <div className='paper greyback customTableCard'>
          {this.renderSearchFilter()}
          <Row>
            <Col md={12} className=' errorText'>
              {this.props.errorMessage}
            </Col>
          </Row>
          {this.renderCards()}
        </div>
      </>
    );
  }
}

export default SignupRequest;
